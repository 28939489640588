import { allClasses } from '../../services/utilities/array';

export const LoadingIndicator = () => {
  const dotClassName =
    'w-4 h-4 bg-main rounded-full border-4 border-main-highlight border-solid mr-1';

  return (
    <div className='flex' aria-label='Loading'>
      <div className={allClasses(dotClassName, 'animate-bounce')} />
      <div className={allClasses(dotClassName, 'animate-bounce200')} />
      <div className={allClasses(dotClassName, 'animate-bounce400')} />
    </div>
  );
};
