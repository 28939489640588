import { reportAppError } from './reporting';

export * from './useErrorHandler';
export * from './useErrorHandlerContextFactory.mock';

export const handleErrorSilently = (error: any) => {
  if (!error) return;

  console.error(error);
  if (process.env.NODE_ENV === 'production') reportAppError(error);
};
