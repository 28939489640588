import { useTestPauseModalContext } from '../../../../services/record/useTestPauseModal';

import { PausedTestModal } from '../../modals/PausedTestModal';

interface PausedTestContainerProps {
  onPressGiveUp: () => void;
}

export const PausedTestContainer = ({ onPressGiveUp }: PausedTestContainerProps) => {
  const { isPaused, closePauseModal: closeModal } = useTestPauseModalContext.useContext();

  const onGiveUp = () => {
    closeModal();
    onPressGiveUp();
  };

  return <PausedTestModal isOpen={isPaused} onClose={closeModal} onGiveUp={onGiveUp} />;
};
