import { useTranslationContext } from '../../../services/translation';
import { useShowInstructionsContext } from '../../services/userTest';

import { Button } from '../../../components';

import { InfoIcon } from '../../assets/icons';
import { InfoCreatedWith } from '../info';

interface TestNavbarLeftButtonProps {
  isLast?: boolean;
  canSkip: boolean;
  nextSection: () => void;
  availableInstructions?: boolean;
  canSeeInstructions?: boolean;
  pauseVideo?: () => void;
  resumeVideo?: () => void;
}

export const TestNavbarLeftButton = ({
  isLast,
  canSkip,
  nextSection,
  availableInstructions,
  canSeeInstructions,
}: TestNavbarLeftButtonProps) => {
  const { t } = useTranslationContext.useContext();

  const { isVisibleInstructionsAgain, showInstructions, hideInstructions } =
    useShowInstructionsContext.useContext();

  if (isLast) return <InfoCreatedWith />;

  const { disabled, onClick } = (() => {
    if (availableInstructions)
      return {
        disabled: !canSeeInstructions,
        onClick: () => {
          if (isVisibleInstructionsAgain) {
            hideInstructions();
          } else {
            showInstructions();
          }
        },
      };
    if (canSkip) return { disabled: false, onClick: nextSection };

    return { disabled: undefined, onClick: undefined };
  })();

  if (!onClick) return null;

  return availableInstructions ? (
    <Button className='relative button__ghost px-2' {...{ disabled, onClick }}>
      {isVisibleInstructionsAgain && (
        <div className='absolute inset-0 opacity bg-main-highlight opacity-20' />
      )}
      <InfoIcon className='icon__small' />
    </Button>
  ) : (
    <Button className='button__ghost' {...{ disabled, onClick }}>
      <span className='px-5'>{t('common.skip')}</span>
    </Button>
  );
};
