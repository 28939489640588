import { useCallback, useMemo } from 'react';

import { useTranslationContext } from '../translation';
import { useAlertsContext } from '../alerts';
import { createContextService } from '../context';
import { mapErrorToTranslationKeys } from './utils';
import { reportAppError } from './reporting';

interface ErrorHandler {
  translateErrorKeys(errorKeys: string | string[]): string[];
  handleError(error: any, message?: string): void;
}

export const useErrorHandler = (): ErrorHandler => {
  const { t } = useTranslationContext.useContext();
  const { addErrorAlert } = useAlertsContext.useContext();

  const translateErrorKeys = useCallback(
    (errorKeys: string[]): string[] =>
      Array.isArray(errorKeys) ? errorKeys.map((key) => t(key)) : [t(errorKeys)],
    [t]
  );

  const handleError = useCallback(
    (err: any, message = '') => {
      reportAppError(err);

      if (message) return addErrorAlert(message);

      const translationKeys = mapErrorToTranslationKeys(err);
      const errorMessages = translateErrorKeys(translationKeys);

      errorMessages.forEach((message) => addErrorAlert(message));
    },
    [addErrorAlert, translateErrorKeys]
  );

  return useMemo(
    () => ({
      translateErrorKeys,
      handleError,
    }),
    [handleError, translateErrorKeys]
  );
};

export const useErrorHandlerContext = createContextService(useErrorHandler);
