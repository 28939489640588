import React from 'react';

import { allClasses } from '../../../../services/utilities/array';

import styles from './CollaboratorRolesDropdown.module.css';

export interface Option {
  value: string;
  label: string;
  subLabel?: string;
  onSelectNewOption?: () => void;
  className?: string;
  disabled?: boolean;
  hasSeparatorLine?: boolean;
  isSelected?: boolean;
}

interface CollaboratorRolesDropdownProps {
  rolesDropdown: { x: number; y: number; height: number; triggerer: HTMLElement } | undefined;
  isPending: boolean;
  options: Option[];
  close: () => void;
}

interface CollaboratorRolesDropdownItemProps {
  className?: string;
  isSelected?: boolean;
  itemType: 'actions' | 'invitation';
  label: string;
  subLabel?: string;
  onSelectNewOption?: () => void;
}

const CollaboratorRolesDropdownItem = ({
  className,
  isSelected,
  itemType,
  label,
  subLabel,
  onSelectNewOption,
}: CollaboratorRolesDropdownItemProps) => {
  return (
    <a
      className={allClasses(
        styles.dropdown_item,
        itemType === 'actions' ? styles.dropdown_item_actions : styles.dropdown_item_invitation,
        isSelected ? 'bg-main-90' : 'bg-main',
        className
      )}
      onClick={() => {
        if (!isSelected) onSelectNewOption?.();
      }}>
      {label}
      {subLabel && <span className={styles.dropdown_item_sublabel}>{subLabel}</span>}
    </a>
  );
};

export const CollaboratorRolesDropdown = ({
  rolesDropdown,
  isPending,
  options,
  close,
}: CollaboratorRolesDropdownProps) => {
  if (!rolesDropdown) return null;

  const bodyRect = document.body.getBoundingClientRect();
  const elemRect = rolesDropdown.triggerer.getBoundingClientRect();
  const offsetY = elemRect.top - bodyRect.top + rolesDropdown.height;
  const offsetX = bodyRect.right - elemRect.right;

  return (
    <div
      style={{
        position: 'fixed',
        top: offsetY,
        right: offsetX,
      }}
      className={styles.dropdown_wrapper}
      onClick={close}>
      {isPending
        ? options.map(({ onSelectNewOption, value, className, label }) => (
            <CollaboratorRolesDropdownItem
              key={value}
              itemType='invitation'
              {...{ className, value, onSelectNewOption, label }}
            />
          ))
        : options.map(
            ({
              onSelectNewOption,
              subLabel,
              value,
              className,
              label,
              hasSeparatorLine,
              isSelected,
            }) => (
              <React.Fragment key={value}>
                {hasSeparatorLine && <hr className={styles.dropdown_item_separator_line} />}
                <CollaboratorRolesDropdownItem
                  key={value}
                  itemType='actions'
                  {...{ className, subLabel, onSelectNewOption, label, isSelected }}
                />
              </React.Fragment>
            )
          )}
    </div>
  );
};
