import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export type DynamicInput<V> = { key: string; value: V };

export interface DynamicInputsProps<V> {
  initialDefaultValues?: V[];
  inputPrefix: string;
  initialValues: V[] | undefined;
  defaultValue: V;
  maxCount?: number;
}

export interface DynamicInputsService<V> {
  inputs: DynamicInput<V>[];
  prefixedInputs: Record<string, V>;
  autoFocusInputKey: React.MutableRefObject<string>;
  saveInput: (index: number, value: V) => void;
  insertInput: (index: number) => void;
  removeInput: (index: number, shouldChangeFocus?: boolean) => void;
  focusInput: (inputKey: string | undefined) => void;
  editInputCount: (
    e: KeyboardEvent,
    details: { index: number; isRemovable: boolean; onRemove?: () => void }
  ) => void;
}

export const dynamicInputInitialValuePrefix = 'default_';

export const useDynamicInputs = <V>({
  initialDefaultValues,
  inputPrefix,
  initialValues,
  defaultValue,
  maxCount,
}: DynamicInputsProps<V>): DynamicInputsService<V> => {
  const initialInputsRef = useRef<DynamicInput<V>[]>([]);
  const initialInputs = useMemo(
    () =>
      initialValues?.length
        ? initialValues.map((value, index) => ({
            key: `${inputPrefix}${dynamicInputInitialValuePrefix}${index}`,
            value,
          }))
        : (initialDefaultValues ? initialDefaultValues : [defaultValue]).map((value, index) => ({
            key: `${inputPrefix}${dynamicInputInitialValuePrefix}${index}`,
            value: value,
          })),
    [defaultValue, initialDefaultValues, initialValues, inputPrefix]
  );

  const autoFocusInputKey = useRef<string>('');
  const [inputs, setInputs] = useState<DynamicInput<V>[]>(initialInputs);

  const setInputInFocus = useCallback((newFocusedInputKey: string) => {
    autoFocusInputKey.current = newFocusedInputKey;
  }, []);

  const prefixedInputs = useMemo(
    () =>
      inputs?.reduce(
        (obj, item: DynamicInput<V>) => ({
          ...obj,
          [item.key]: item.value,
        }),
        {} as Record<string, V>
      ),
    [inputs]
  );

  const saveInput = useCallback((index: number, value: V) => {
    setInputs((prev) => {
      const update = [...prev];
      const input = update[index];
      input.value = value;

      return update;
    });
  }, []);

  const insertInput = (index: number) => {
    if (maxCount && inputs.length >= maxCount) return;

    setInputs((prev) => {
      const newStepKey = `${inputPrefix}${index}_${Date.now().toString()}`;

      //  Focus next input in the list
      setInputInFocus(newStepKey);

      prev.splice(index, 0, { key: newStepKey, value: defaultValue });
      return [...prev];
    });
  };

  const removeInput = (index: number, shouldChangeFocus = true) => {
    setInputs((prev) => {
      //  Focus previous input in the list

      if (shouldChangeFocus) {
        const previousInput = prev[index - 1]?.key;
        setInputInFocus(previousInput || '');
      }

      prev.splice(index, 1);
      return [...prev];
    });
  };

  const editInputCount = (
    e: KeyboardEvent,
    details: { index: number; isRemovable: boolean; onRemove?: () => void }
  ) => {
    const value = (e.target as any).value;
    const { index, isRemovable, onRemove } = details;

    if (e.key === 'Enter') return insertInput(index + 1);
    if (e.key === 'Backspace' && !value && isRemovable) {
      e.preventDefault();
      removeInput(index);
      return onRemove?.();
    }
  };

  useEffect(() => {
    const differentInputCount = initialInputs.length !== initialInputsRef.current.length;
    const changedInitialInputs = !!initialInputs.filter(({ value }, index) => {
      const alreadyExists = initialInputsRef.current[index];
      const isDifferent =
        JSON.stringify(value) !== JSON.stringify(initialInputsRef.current[index]?.value);
      return !alreadyExists || (alreadyExists && isDifferent);
    }).length;

    if (!changedInitialInputs && !differentInputCount) return;

    initialInputsRef.current = initialInputs;

    let focusedInputNewKey: string | undefined = '';
    setInputs((prev) => {
      // Maintain autofocus on input remounts
      const focusedInputIndex = prev.findIndex((input) => input.key === autoFocusInputKey.current);
      focusedInputNewKey = initialInputs[focusedInputIndex]?.key;

      return initialInputs;
    });
    setInputInFocus(focusedInputNewKey || '');
  }, [setInputInFocus, initialInputs]);

  const focusInput = useCallback(
    (inputKey: string | undefined) => {
      setInputs((prev) => {
        const input = prev.find((i) => i.key === inputKey);
        setInputInFocus(input && inputKey ? inputKey : '');

        return [...prev];
      });
    },
    [setInputInFocus]
  );

  return {
    inputs,
    prefixedInputs,
    autoFocusInputKey,
    saveInput,
    insertInput,
    removeInput,
    editInputCount,
    focusInput,
  };
};
