import { TouchEventHandler, useMemo, useRef, useState } from 'react';

import { useTranslationContext } from '../../../../../../services/translation';
import {
  DiscoveryPreferenceAnswer,
  DiscoverySectionPreferenceOutput,
  DiscoverySectionType,
  SectionStorageMultipleFile,
} from '../../../../../services/api-iteration1';
import { allClasses } from '../../../../../../services/utilities/array';
import { getKeyForCondition } from '../../../../../../services/utilities/object';
import { getStoragePublicFileUrl } from '../../../../../services/api-cloud-storage';
import {
  isTemporaryFile,
  temporaryFileId,
} from '../../../../../services/api-iteration1/projects/api-models/sections/utils';

import { useToggle } from '../../../../../../components';

import { MaximizeIcon, PlaceholderSmallIcon } from '../../../../../assets/icons';
import { TextAnswerBlock } from './TextAnswerBlock';
import { getSectionPreferenceTestFiles } from '../../utils';
import { PreferenceTestImageModal } from '../../../../modals/PreferenceTestImageModal';

import styles from '../ViewSection.module.css';

interface ViewPreferenceTestProps {
  preferenceSection: DiscoverySectionPreferenceOutput;
  isEmbeddedIframe?: boolean;
  answer: DiscoveryPreferenceAnswer | undefined;
  onSetAnswer?: (answer: Omit<DiscoveryPreferenceAnswer, 'duration'>) => void;
}

export const ViewPreferenceTest = ({
  preferenceSection,
  answer,
  onSetAnswer,
  isEmbeddedIframe,
}: ViewPreferenceTestProps) => {
  const { t } = useTranslationContext.useContext();

  const {
    isOpen: isPreferenceTestImageModalVisible,
    open: openPreferenceTestImageModal,
    close: closePreferenceTestImageModal,
  } = useToggle();
  const [expandedImage, setExpandedImage] = useState<{ id: string; index: number }>();

  const lastTappedImage = useRef({ index: -1, lastTapTime: 0 });
  const currentImageTap = useRef({ started: false, moved: false });

  const {
    files: apiFiles,
    title,
    others: { type, description, texts },
  } = preferenceSection;

  const { files } = useMemo(
    () => getSectionPreferenceTestFiles(apiFiles as SectionStorageMultipleFile[]),
    [apiFiles]
  );

  const saveAnswer = (index: number) => {
    onSetAnswer?.({
      type: DiscoverySectionType.Preference,
      choiceType: type,
      choice: index,
    });
  };

  const openImageModal = (image: string, index: number) => {
    openPreferenceTestImageModal();
    setExpandedImage({
      id: image,
      index: index + 1,
    });
  };

  const openFullscreenOnMobile = (
    e: Parameters<TouchEventHandler<HTMLImageElement>>[0],
    index: number
  ) => {
    e.preventDefault();

    if (currentImageTap.current.started && !currentImageTap.current.moved) saveAnswer(index);
    currentImageTap.current = { started: false, moved: false };

    const tapThreshold = 200;

    function openImageFullScreen(imageElement: HTMLImageElement) {
      if (imageElement.requestFullscreen) {
        imageElement.requestFullscreen();
      } else if ((imageElement as any).mozRequestFullScreen) {
        // Firefox
        (imageElement as any).mozRequestFullScreen();
      } else if ((imageElement as any).webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        (imageElement as any).webkitRequestFullscreen();
      } else if ((imageElement as any).msRequestFullscreen) {
        // IE/Edge
        (imageElement as any).msRequestFullscreen();
      } else if ((imageElement as any).webkitEnterFullscreen) {
        // iOS Safari
        (imageElement as any).webkitEnterFullscreen();
      }
    }

    const currentTime = Date.now();
    const timeDiff = currentTime - lastTappedImage.current.lastTapTime;
    const sameTappedImage = lastTappedImage.current.index === index;

    if (sameTappedImage && timeDiff < tapThreshold && timeDiff > 0) {
      openImageFullScreen(e.currentTarget);
    }

    lastTappedImage.current = { index, lastTapTime: currentTime };
  };

  return (
    <div className={allClasses(styles.view_section_wrapper_no_size_limit, 'max-w-7xl')}>
      <div className='w-full'>
        <h6 className='inline-block w-full text-xl text-main-contrast font-bold leading-30 mb-2.5 break-all'>
          {title}
        </h6>
        {description && (
          <p className='inline-block w-full text-lg text-main-contrast font-normal leading-normal'>
            {description}
          </p>
        )}
        {type === 'text' && texts ? (
          <div
            className={allClasses(
              'mt-10 grid gap-5 grid-cols-1',
              getKeyForCondition({
                'sm:grid-cols-3': texts.length === 3,
                'sm:grid-cols-2': texts.length % 2 === 0,
              })
            )}>
            {texts.map((text, index) => {
              const isActive = answer?.choice === index;

              return (
                <TextAnswerBlock
                  key={`${text.context}_${text.indicator}_${index}`}
                  onClick={() => saveAnswer(index)}
                  text={text.context}
                  {...{ index, isActive }}
                />
              );
            })}
          </div>
        ) : type === 'image' &&
          files.filter((file) => file.id.includes(temporaryFileId)).length < files.length ? (
          <div className={allClasses('mt-10 grid gap-5 grid-cols-1 sm:grid-cols-2')}>
            {files?.map((image, index) => {
              const isPlaceholder = isTemporaryFile(image.id);
              const isActive = answer?.choice === index;

              return (
                <div
                  key={`${image.id}_${image.indexId}`}
                  className={allClasses(
                    styles.option_wrapper,
                    isActive ? styles.option_wrapper_active : undefined,
                    answer?.choice !== undefined && !isActive
                      ? styles.option_wrapper_inactive
                      : undefined,
                    !isPlaceholder ? styles.option_wrapper_hoverable : undefined
                  )}
                  onClick={() => saveAnswer(index)}>
                  {isPlaceholder ? (
                    <div
                      className={allClasses('w-full h-full', files.length === 1 && 'min-h-22')}
                    />
                  ) : (
                    <img
                      onTouchEnd={(e) => openFullscreenOnMobile(e, index)}
                      onTouchStart={() => (currentImageTap.current.started = true)}
                      onTouchMove={() => (currentImageTap.current.moved = true)}
                      src={getStoragePublicFileUrl(files[index].id)}
                      className={allClasses('w-full h-full rounded object-cover')}
                    />
                  )}
                  <div className={styles.option_wrapper_overlay_wrapper}>
                    <MaximizeIcon
                      className='icon__small absolute bottom-5 right-5 icon-main'
                      onClick={(e) => {
                        e.stopPropagation();
                        openImageModal(files[index].id, index);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div
            className={
              isEmbeddedIframe
                ? styles.view_section_wrapper_with_top_margin_embedded
                : styles.view_section_wrapper_with_top_margin
            }>
            <div className='flex justify-center items-center bg-main-10 p-10 rounded-lg'>
              <PlaceholderSmallIcon className='w-10 h-10' />
            </div>
            <p className='h5 text-main-contrast mt-10'>
              {t('components.projects.sections.five-seconds.view.no-preview.title')}
            </p>
            <p className='body-l text-main-contrast mt-2'>
              {t('components.projects.sections.five-seconds.view.no-preview.subtitle')}
            </p>
          </div>
        )}
      </div>
      {isPreferenceTestImageModalVisible && (
        <PreferenceTestImageModal
          isOpen
          closeModal={closePreferenceTestImageModal}
          image={expandedImage}
        />
      )}
    </div>
  );
};
