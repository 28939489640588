import { ReactNode, useRef } from 'react';
import { Dialog } from '@headlessui/react';

import { allClasses } from '../../services/utilities/array';

interface ModalProps {
  backButton?: React.ReactNode;
  className?: string;
  closeIconClassName?: string;
  closeModalComponent: React.ReactNode;
  headerClassName?: string;
  headerTitleComponent?: React.ReactNode;
  containerClassName?: string;
  refInitialFocus?: React.MutableRefObject<HTMLElement | null>;
  isOpen: boolean;
  onClose: () => void;
  submitButton?: React.ReactNode;
  title?: string;
  variant: 'regular' | 'full-page' | 'no-scroll-full-page';
  children?: ReactNode;
}

export const Modal = ({
  variant,
  isOpen,
  className,
  headerClassName,
  closeModalComponent,
  backButton,
  headerTitleComponent,
  children,
  onClose,
  closeIconClassName,
  containerClassName,
  refInitialFocus,
}: ModalProps) => {
  const closeButtonRef = useRef(null);

  return isOpen ? (
    <Dialog
      initialFocus={refInitialFocus || closeButtonRef}
      open={true}
      onClose={onClose}
      onClick={(e: any) => e.stopPropagation()}
      className={allClasses(
        'absolute z-50 inset-0 overflow-y-auto overflow-x-hidden min-h-full',
        containerClassName
      )}>
      <div className='flex items-center justify-center min-h-full relative'>
        <Dialog.Overlay className='absolute inset-0 bg-main-contrast opacity-80 -z-10' />

        <div
          className={allClasses(
            'flex flex-col bg-main mx-auto z-10',
            variant === 'regular'
              ? 'rounded-lg w-[600px] max-w-[90%]'
              : variant === 'full-page'
              ? 'w-full min-h-screen rounded-none'
              : 'w-full h-screen rounded-none',
            className
          )}>
          <div
            className={allClasses(
              'flex flex-row justify-between relative w-full py-2',
              variant !== 'regular' && 'h-20',
              headerClassName
            )}>
            {backButton ? (
              <span className='flex p-2.5 focus:outline-none items-center'>{backButton}</span>
            ) : (
              <div />
            )}
            {headerTitleComponent || <div />}
            <button
              ref={closeButtonRef}
              onClick={onClose}
              className={allClasses('flex p-2.5 focus:outline-none', closeIconClassName)}>
              {closeModalComponent}
            </button>
          </div>
          {children}
        </div>
      </div>
    </Dialog>
  ) : null;
};
