import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom';

import { useUserCollaboratorsContext } from '../../services/collaborators';
import { useLocation } from '../../../services/router';

import { routes } from './routes';
import { Page404 } from '../404';
import { FinishSignUp } from '../FinishSignUp';
import { ForgotPassword } from '../ForgotPassword';
import { Profile } from '../Profile';
import { Folder } from '../Folder';
import { Projects } from '../Projects';
import { ResetPassword } from '../ResetPassword';
import { SignIn } from '../SignIn';
import { SignUp } from '../SignUp';
import { ViewDiscovery } from '../ViewDiscovery';
import { Discovery } from '../Discovery';
import { StartDiscoveryTesting } from '../StartDiscoveryTesting';
import { UserTest } from '../UserTest';
import { StartUserTestTesting } from '../StartUserTestTesting';
import { ViewUserTest } from '../ViewUserTest';
import { UserTestVersion } from '../UserTestVersion';
import { VerifyAccountEmail } from '../VerifyAccountEmail';
import { ConfirmEmail } from '../ConfirmEmail';

export const AppRoutes = () => {
  return (
    <Router>
      <AllRoutes />
    </Router>
  );
};

export const AllRoutes = () => {
  const { search } = useLocation();

  return (
    <Routes>
      <Route path={routes.home} element={<Navigate to={`${routes.projects}${search}`} replace />} />

      <Route path={routes.signIn} element={<SignIn />} />

      <Route path={routes.signUp} element={<SignUp />} />

      <Route path={routes.resetPassword} element={<ResetPassword />} />

      <Route path={routes.forgotPassword} element={<ForgotPassword />} />

      <Route path={routes.verifyAccountEmail} element={<VerifyAccountEmail />} />

      <Route path={routes.confirmEmail} element={<ConfirmEmail />} />

      {/** Testing routes */}
      <Route path={routes.testing.discovery} element={<ViewDiscovery />} />

      <Route path={routes.finishSignUp} element={<FinishSignUp />} />

      {/** Testing routes */}
      <Route path={routes.testing.discovery} element={<ViewDiscovery />} />

      <Route path={routes.testing.userTest} element={<ViewUserTest />} />

      {/** Authenticated user routes */}

      <Route
        element={
          <useUserCollaboratorsContext.ProvideContext>
            <Outlet />
          </useUserCollaboratorsContext.ProvideContext>
        }>
        <Route path={`${routes.profile}/*`} element={<Profile />} />

        <Route path={routes.projects} element={<Projects />} />

        <Route path={routes.folder} element={<Folder />} />

        <Route path={routes.discovery} element={<Discovery />}>
          <Route path=':tab'>
            <Route path=':tabSectionId' element={<Discovery />} />
          </Route>
        </Route>

        <Route path={routes.userTest} element={<UserTest />} />

        <Route path={routes.start_testing.discovery} element={<StartDiscoveryTesting />} />

        <Route path={routes.start_testing.userTest} element={<StartUserTestTesting />} />

        <Route path={routes.userTestVersion} element={<UserTestVersion />}>
          <Route path=':tab'>
            <Route path=':tabSectionId' element={<UserTestVersion />} />
          </Route>
        </Route>
      </Route>

      <Route path='*' element={<Page404 />} />
    </Routes>
  );
};
