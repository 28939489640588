import { useTranslationContext } from '../../../../services/translation';
import { useViewDiscovery } from '../../../services/discovery/useViewDiscovery';
import { useShowInstructionsContext } from '../../../services/userTest';
import {
  DefaultSectionType,
  DiscoveryVersionOutput,
  isImageFiveSeconds,
  isUsabilitySection,
} from '../../../services/api-iteration1';
import { useTestPauseModalContext } from '../../../../services/record/useTestPauseModal';

import { NoNavbarLayout } from '../../../components/layout';
import { ProgressLine } from '../../../../components/progress-line/ProgressLine';

import { NotFoundLayout } from '../../layout/NotFoundLayout';
import { TestNavbarLeftButton, TestNavbarRightButton } from '../../buttons';
import { UnavailableDiscoveryFlow } from './UnavailableDiscoveryFlow';
import { SectionView } from '../sections/view';
import { PausedTestContainer } from '../userTest/PausedTestContainer';
import { testGiveUpReasons } from '../../../config';

interface ViewDiscoveryFlowProps {
  discoveryId: string;
  versionId: string;
  inPreviewVersion?: DiscoveryVersionOutput;
  isEmbeddedIframe?: boolean;
}

export { ViewDiscoveryFlowContainer as ViewDiscoveryFlow };

const ViewDiscoveryFlowContainer = (props: ViewDiscoveryFlowProps) => {
  return (
    <useShowInstructionsContext.ProvideContext>
      <useTestPauseModalContext.ProvideContext>
        <ViewDiscoveryFlow {...props} />
      </useTestPauseModalContext.ProvideContext>
    </useShowInstructionsContext.ProvideContext>
  );
};

const ViewDiscoveryFlow = ({
  discoveryId,
  versionId,
  inPreviewVersion,
  isEmbeddedIframe,
}: ViewDiscoveryFlowProps) => {
  const { t } = useTranslationContext.useContext();
  const { isVisibleInstructionsAgain } = useShowInstructionsContext.useContext();

  const {
    currentSection,
    isLoading,
    isFirst,
    isLast,
    nextSection,
    sectionAnswer,
    setSectionAnswer,
    startDiscoveryFlow,
    canContinue,
    giveUpTesting,
    canSkip,
    isDone,
    isDraft,
    error,
    totalAnswerableSections,
    currentSectionScreenIndex,
    availableInstructions,
    isAnswerableScreen,
    continueFlow,
    submitAndContinueFlow,
    debouncedSubmitAnswer,
    immediatelySubmitAnswer,
    canSeeInstructions,
    skippingSectionInProgress,
  } = useViewDiscovery({
    discoveryId,
    versionId,
    inPreviewVersion,
  });

  const inPreview = !!inPreviewVersion;
  const inTestingMode = !inPreview;
  const automaticSpacing =
    !isEmbeddedIframe &&
    (isImageFiveSeconds(currentSection) ||
      !(isUsabilitySection(currentSection) && isAnswerableScreen) ||
      isVisibleInstructionsAgain);

  if (inTestingMode && isDraft) return <NotFoundLayout />;

  const showProgress =
    currentSection &&
    currentSection.type !== DefaultSectionType.Welcome &&
    currentSection.type !== DefaultSectionType.ThankYou;

  const currentProgress = ((currentSection?.index || 0) / totalAnswerableSections) * 100;

  return (
    <NoNavbarLayout
      analyticsName={inPreview ? 'Preview Discovery' : 'Test Discovery'}
      title={t('pages.discovery.view')}
      header={showProgress && <ProgressLine completed={currentProgress} />}
      footerLeftComponent={
        !isFirst && (
          <TestNavbarLeftButton
            {...{ isLast, canSkip, nextSection, canSeeInstructions }}
            availableInstructions={availableInstructions}
          />
        )
      }
      footerRightComponent={
        !isFirst && (
          <TestNavbarRightButton
            actionType='continue'
            onClickContinue={submitAndContinueFlow}
            {...{ isLast, canContinue }}
          />
        )
      }
      isLoading={isLoading || skippingSectionInProgress}
      error={error}
      availableOnMobile>
      {isDone ? (
        <UnavailableDiscoveryFlow />
      ) : (
        <div className='w-full flex flex-col items-center justify-start'>
          <SectionView
            interactive
            automaticSpacing={automaticSpacing}
            section={currentSection}
            answer={sectionAnswer}
            onStart={startDiscoveryFlow}
            onSetAnswer={setSectionAnswer}
            onFinishAnswerScreen={continueFlow}
            onIntermediateAnswer={inTestingMode ? debouncedSubmitAnswer : undefined}
            onSaveAnswerImmediately={inTestingMode ? immediatelySubmitAnswer : undefined}
            {...{ nextSection, currentSectionScreenIndex, isEmbeddedIframe }}
          />
          <PausedTestContainer
            onPressGiveUp={() => inTestingMode && giveUpTesting(testGiveUpReasons.pause_quit)}
          />
        </div>
      )}
    </NoNavbarLayout>
  );
};
