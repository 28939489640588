import { createContextService } from '../../../services/context';

import { useToggle } from '../../../components';

const usePreviewModal = () => {
  const {
    isOpen: isOpenPreviewModal,
    open: openPreviewModal,
    close: closePreviewModal,
  } = useToggle();

  return {
    isOpenPreviewModal,
    openPreviewModal,
    closePreviewModal,
  };
};

export const usePreviewModalContext = createContextService(usePreviewModal);
