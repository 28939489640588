import { InputHTMLAttributes } from 'react';

import { allClasses } from '../../services/utilities/array';

import styles from './Toggle.module.css';

interface ToggleFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  circleClassName: string;
  sliderClassName: string;
  text?: string;
  isChecked: boolean;
}

export const Toggle = ({
  circleClassName,
  sliderClassName,
  text,
  id,
  isChecked,
  ...otherProps
}: ToggleFieldProps) => {
  return (
    <label htmlFor={id} className='flex items-center cursor-pointer'>
      <div className='relative items-center'>
        <input
          id={id}
          {...otherProps}
          type='checkbox'
          className='hidden'
          onChange={otherProps.onChange}
          onBlur={otherProps.onBlur}
          checked={isChecked}
        />
        <div
          className={allClasses(
            'toggle__line w-8 h-5 rounded-10 relative',
            isChecked ? 'bg-main-contrast' : 'bg-neutral-20',
            sliderClassName
          )}></div>
        <div
          className={allClasses(
            circleClassName,
            styles.toggle_dot,
            `w-3 h-3 ml-1 bg-main rounded-full absolute top-1/2 flex transition duration-300 ease-in-out transform ${
              isChecked && 'translate-x-full mr-1'
            }`
          )}></div>
      </div>
      {text && <div className='ml-3 bg-main font-medium'>{text}</div>}
    </label>
  );
};
