import { useState } from 'react';

import { useTranslationContext } from '../../../services/translation';
import { PaymentPlanOutput } from '../../services/api-iteration1/billing/api-models';
import { getStripePaymentErrorMessage } from '../../services/billing/payment';
import { useErrorHandlerContext } from '../../../services/error-handling';

import { usePaymentContext, usePaymentInstance } from '../../../components';

import { PaymentButton } from './PaymentButton';
import { routes } from '../../pages/routing/routes';

interface PaySubscriptionButtonProps {
  disabled: boolean;
  plan: PaymentPlanOutput;
  onSuccess: () => void;
}

export const PaySubscriptionButton = ({ disabled, onSuccess }: PaySubscriptionButtonProps) => {
  const { t } = useTranslationContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();

  const paymentInstance = usePaymentInstance();
  const paymentElements = usePaymentContext();

  const [errorMessage, setErrorMessage] = useState<string>();

  const paySubscription = async () => {
    if (!paymentInstance || !paymentElements) return;

    try {
      const { error } = await paymentInstance.confirmPayment({
        elements: paymentElements,
        confirmParams: {
          return_url: window.location.origin + routes.billing,
        },
        redirect: 'if_required',
      });

      if (error) return setErrorMessage(getStripePaymentErrorMessage(error, t));

      onSuccess();
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <PaymentButton
      disabled={disabled}
      onClick={paySubscription}
      text={t('pages.account.tabs.plan-billing.order-summary.pay')}
      errorMessage={errorMessage}
    />
  );
};
