import { createContextService } from '../../../services/context';

import { useToggle } from '../../../components';

const useShowInstructions = () => {
  const {
    isOpen: isVisibleInstructionsAgain,
    open: showInstructions,
    close: hideInstructions,
    toggleCount: toggleInstructionsCount,
  } = useToggle();

  return {
    isVisibleInstructionsAgain,
    showInstructions,
    hideInstructions,
    toggleInstructionsCount,
  };
};

export const useShowInstructionsContext = createContextService(useShowInstructions);
