import { useMemo, useState } from 'react';

import { useTranslationContext } from '../../../../../services/translation';
import { allClasses } from '../../../../../services/utilities/array';
import { useErrorHandlerContext } from '../../../../../services/error-handling';
import { useSectionsListContext } from '../../../../services/sections/useSectionsList';
import {
  DiscoverySectionInput,
  Frame,
  isTemporarySection,
  SectionFileRemovalDetails,
  SectionFileUploadDetails,
  UsabilitySectionFiveSeconds,
  UsabilitySectionInput,
  UsabilitySectionType,
} from '../../../../services/api-iteration1';
import { useActionInProgressContext } from '../../../../../services/progress';
import {
  getStoragePublicFileUrl,
  imageFileSizeLimit,
} from '../../../../services/api-cloud-storage';
import { FigmaDesignPrototypeVersion } from '../../../../services/api-iteration1/design-platforms';
import { composeValidators, isMoreThan, isNumber } from '../../../../services/validation';
import { StorageFileType } from '../../../../services/api-cloud-storage/api-models';

import {
  Button,
  Form,
  FormError,
  FormField,
  ToggleField,
  UploadImage,
  useToggle,
} from '../../../../../components';
import { AccessoryInput } from '../../../../../components/form/field-components/accessory-input';
import { RadioGroup } from '../../../../../components/radio-group/RadioGroup';

import {
  EditIcon,
  FiveSecondsIcon,
  MinusSignIcon,
  PlaceholderSmallIcon,
  PlusSignIcon,
  PrototypeIcon,
} from '../../../../assets/icons';
import { SelectSectionItem } from '../../../containers';
import { ChangeStartingFrameModal } from '../../..';

import styles from './Section.module.css';

interface SectionFormValues {
  required?: boolean;
  hasImage: boolean;
  seconds: number;
  type: string;
  frame?: Frame;
}

type EditSection<T> = (
  sectionId: string,
  updates: T,
  shouldUpdate?: boolean
) => Promise<void> | undefined;

interface SectionFiveSecondsProps {
  section: UsabilitySectionFiveSeconds;
  index: number;
  isInvalid: boolean;
  editSection: EditSection<DiscoverySectionInput> | EditSection<UsabilitySectionInput>;
  uploadSectionFile: (sectionId: string, fileDetails: SectionFileUploadDetails) => Promise<void>;
  removeSectionFile: (sectionId: string, fileDetails: SectionFileRemovalDetails) => Promise<void>;
  removeSection: (sectionId: string) => Promise<void> | undefined;
  duplicateSection: ((sectionId: string) => Promise<void> | undefined) | (() => void);
  projectType: 'discovery' | 'userTest';
  designPrototype?: FigmaDesignPrototypeVersion;
}

export const SectionFiveSeconds = ({
  section,
  isInvalid,
  index,
  editSection,
  uploadSectionFile,
  removeSectionFile,
  removeSection,
  duplicateSection,
  projectType,
  designPrototype,
}: SectionFiveSecondsProps) => {
  const { t } = useTranslationContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();
  const { reflectUploadingStatus } = useActionInProgressContext.useContext();
  const {
    inFocusSectionId,
    setSectionInFocus,
    resetSectionInFocus: collapseSection,
  } = useSectionsListContext.useContext();

  const { _id, title, files, others, required } = section;
  const { seconds, type } = others;

  const image = files.find((f) => f.type === StorageFileType.FiveSecondsImage);
  const imageUrl = image && getStoragePublicFileUrl(image.id);
  const frame = others.type === 'prototype' ? others.frame : undefined;
  const currentFrame =
    designPrototype?.frames.find((item) => item.id === frame?.id) || designPrototype?.frames[0];

  const [hasImage, setHasImage] = useState(!!image);
  const {
    isOpen: isOpenChangeFrameModal,
    open: openChangeFrameModal,
    close: closeChangeFrameModal,
  } = useToggle();

  const showUploadImage = projectType === 'discovery' || type === 'image';
  const showPrototypeScreen = projectType === 'userTest' && type === 'prototype';

  const isLoading = isTemporarySection(_id);
  const isExpanded = inFocusSectionId === _id && !isLoading;
  const expandSection = () => setSectionInFocus(_id);

  const updateSectionValues = (values: SectionFormValues) => {
    if (!_id) return;

    const shouldRemoveSectionImage = !values.hasImage && image;
    if (shouldRemoveSectionImage)
      removeSectionFile(_id, { id: image.id, type: StorageFileType.FiveSecondsImage }).catch(
        handleError
      );

    const updates = {
      title: t('components.projects.sections.field-types.5seconds'),
      others: {
        seconds: parseInt(values.seconds.toString(), 10),
        type: values.type,
        ...(values.type === 'prototype' && values.frame
          ? {
              frame: values.frame,
            }
          : {
              frame: {
                id: designPrototype?.frames[0].id || '',
                index: designPrototype?.frames[0].index || 0,
              },
            }),
      },
      required: values.required,
      ...(shouldRemoveSectionImage ? { files: files.filter((f) => f.id !== image.id) } : {}),
    };

    editSection(_id, updates, !isTemporarySection(_id));
  };

  const initialValues = useMemo(
    () => ({
      type: type,
      title: title || t('components.projects.sections.field-types-descriptions.5seconds'),
      required: !!required,
      hasImage: !!image || hasImage,
      seconds: seconds,
      frame: frame,
    }),
    [type, title, t, required, image, hasImage, seconds, frame]
  );

  return (
    <SelectSectionItem
      alreadyAdded
      draggableItemId={_id}
      title={t('components.projects.sections.field-types.5seconds')}
      description={t('components.projects.sections.field-types-descriptions.5seconds')}
      icon={<FiveSecondsIcon className='icon__small' />}
      onClick={isExpanded ? collapseSection : expandSection}
      type={UsabilitySectionType.FiveSeconds}
      onDelete={(e) => {
        e.stopPropagation();
        removeSection(_id);
      }}
      onDuplicate={(e) => {
        e.stopPropagation();
        duplicateSection?.(_id);
      }}
      showRequiredIcon={required}
      {...{ isExpanded, isInvalid, isLoading, index }}>
      <>
        <hr className={allClasses(styles.section_hr, 'w-11/12')} />
        <Form
          validateOnMount
          enableReinitialize
          initialValues={initialValues}
          onChange={updateSectionValues}
          className='px-5'>
          {({ status, values, errors, touched, submitChange }) => (
            <>
              <FormError {...{ status }} />
              <>
                {projectType === 'userTest' && (
                  <div className='mb-5'>
                    <FormField
                      component={RadioGroup}
                      label={`${t('components.projects.sections.selection-type')}*`}
                      name='type'
                      options={[
                        {
                          label: t('common.image'),
                          value: 'image',
                          icons: {
                            selected: (
                              <PlaceholderSmallIcon className='icon__small icon-main-contrast icon-stroke-polyline-main-contrast' />
                            ),
                            notSelected: <PlaceholderSmallIcon className='icon__small ' />,
                          },
                        },
                        {
                          label: t('common.prototype'),
                          value: 'prototype',
                          icons: {
                            selected: (
                              <PrototypeIcon className='icon__small icon-main-contrast icon-circle-main-contrast' />
                            ),
                            notSelected: <PrototypeIcon className='icon__small' />,
                          },
                        },
                      ]}
                      checkedWrapperClassName='border-main-highlight'
                      checkedTextClassName='text-main-contrast'
                      labelClassName={styles.section_label}
                      checked={values.type}
                      wrapperClassName='grid-cols-2'
                    />
                  </div>
                )}
                {showUploadImage && (
                  <>
                    <p className='text-main-contrast leading-5'>
                      {t('components.projects.sections.five-seconds.edit.image.title')}*
                    </p>
                    <span className='mb-[8px] text-xs text-neutral-70'>
                      {t('components.projects.sections.five-seconds.edit.image.detail')}
                    </span>
                    <UploadImage
                      excludeFromForm
                      required
                      fileSizeLimit={imageFileSizeLimit}
                      title={t('components.upload.image.title')}
                      subtitle={t('components.upload.image.subtitle')}
                      placeholderImgSrc={imageUrl}
                      upload={(image) =>
                        uploadSectionFile(_id, {
                          file: image,
                          type: StorageFileType.FiveSecondsImage,
                        })
                      }
                      onUploadError={handleError}
                      remove={async () => {
                        if (!image) return;
                        await removeSectionFile(_id, {
                          id: image.id,
                          type: StorageFileType.FiveSecondsImage,
                        });
                      }}
                      onRemoveSuccess={() => setHasImage(false)}
                      onRemoveError={handleError}
                      onLoadingStatusChange={reflectUploadingStatus}
                      className='mb-4'
                      fileName={image?.fileName}
                    />
                  </>
                )}
                {showPrototypeScreen && (
                  <>
                    <div className='mb-4'>
                      <p>{t('common.screen')}*</p>
                      <span className='mb-[8px] text-xs text-neutral-70'>
                        {t('components.projects.sections.five-seconds.edit.prototype.detail')}
                      </span>
                      <div className='relative p-5 border-2 border-solid rounded-lg mb-5 border-main-10'>
                        <div className='w-full flex flex-row items-center justify-start flex-wrap'>
                          <div
                            className={allClasses(
                              'mr-1.5 relative cursor-pointer m-1',
                              styles.screen_selected_frame
                            )}>
                            <img
                              src={
                                currentFrame?.image &&
                                getStoragePublicFileUrl(currentFrame.image.id)
                              }
                              className='h-full w-full border-2 border-main-10 border-solid rounded-sm'
                            />
                          </div>
                          <span className='ml-5'>{currentFrame?.name}</span>
                        </div>
                        <div className='absolute top-1/2 right-5 -translate-y-1/2 cursor-pointer'>
                          <EditIcon
                            onClick={() => openChangeFrameModal()}
                            className='icon__small ml-2 cursor-pointer icon-hover-main-contrast'
                          />
                        </div>
                      </div>
                    </div>
                    {isOpenChangeFrameModal && (
                      <ChangeStartingFrameModal
                        isOpen={isOpenChangeFrameModal}
                        designPrototype={designPrototype}
                        startingPointNodeId={currentFrame?.id}
                        pathIndex={currentFrame?.index || 0}
                        onClose={closeChangeFrameModal}
                        onSelect={(_, frameId: string) => {
                          closeChangeFrameModal();
                          setTimeout(
                            () =>
                              submitChange({
                                ...values,
                                frame: {
                                  id: frameId,
                                  index:
                                    designPrototype?.frames.find((item) => item.id === frameId)
                                      ?.index || 0,
                                },
                              }),
                            1000
                          );
                        }}
                        modalTitle={t(
                          'components.projects.sections.five-seconds.edit.prototype.modal-title'
                        )}
                      />
                    )}
                  </>
                )}
              </>
              <FormField
                component={AccessoryInput}
                type='number'
                name='seconds'
                placeholder='0'
                label={`${t('components.projects.sections.five-seconds.edit.time-limit')}*`}
                validate={composeValidators(isNumber, isMoreThan(5))}
                prefix={
                  <Button
                    className={
                      values.seconds <= 5 ? 'cursor-not-allowed opacity-100' : 'cursor-pointer'
                    }
                    disabled={values.seconds <= 5}
                    onClick={() => {
                      if (values.seconds > 5) {
                        submitChange({ ...values, seconds: values.seconds - 1 });
                      }
                    }}>
                    <MinusSignIcon
                      className={values.seconds > 5 ? 'icon-main-contrast' : undefined}
                    />
                  </Button>
                }
                suffix={
                  <Button
                    onClick={() => {
                      submitChange({ ...values, seconds: values.seconds + 1 });
                    }}>
                    <PlusSignIcon />
                  </Button>
                }
                disabled
                otherInfo={<p className='mt-2 text-xs text-neutral'>{t('common.seconds')}</p>}
                containerClassName='relative flex flex-row flex-1 bg-main border-2 rounded-lg border-main-10 border-solid py-3 px-5 focus:border-main-highlight focus:outline-none hover:border-main-highlight'
                inputClassName={allClasses(
                  'relative bg-main text-main-contrast text-lg font-normal leading-normal text-center w-full h-full disabled:bg-main focus:outline-none',
                  errors.seconds && touched.seconds && styles.section_input__invalid
                )}
                labelClassName={styles.section_label}
              />
              <hr className={styles.section_hr} />
              <div className={allClasses(styles.section_toggle_wrapper, 'mb-5 py-2.5')}>
                <span>{t('components.projects.sections.required-section')}</span>
                <FormField
                  component={ToggleField}
                  name='required'
                  sliderClassName={styles.section_toggle_slider_wrapper}
                  circleClassName={styles.section_toggle_slider_circle}
                />
              </div>
            </>
          )}
        </Form>
      </>
    </SelectSectionItem>
  );
};
