import { useTranslationContext } from '../../../../services/translation';
import { allClasses } from '../../../../services/utilities/array';

interface ActionMessageFillProps {
  messageType: 'change-start-frame' | 'click-prototype' | undefined;
  onClick?: () => void;
}

const ActionMessageFill = ({ messageType, onClick }: ActionMessageFillProps) => {
  const { t } = useTranslationContext.useContext();

  const handleClickOnText: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    onClick?.();
  };

  return messageType ? (
    <div className='flex flex-col absolute inset-0 text-center justify-center cursor-default'>
      <div className='z-10'>
        {t(
          `components.projects.sections.single-goal.edit.expected-path.messages.${messageType}.part-one`
        )}
      </div>
      <div
        className={allClasses('z-10', onClick && 'text-info underline cursor-pointer')}
        onClick={messageType === 'change-start-frame' ? handleClickOnText : undefined}>
        {t(
          `components.projects.sections.single-goal.edit.expected-path.messages.${messageType}.part-two`
        )}
      </div>
      <div className='absolute inset-0 bg-main opacity-90 z-0' />
    </div>
  ) : null;
};

export default ActionMessageFill;
