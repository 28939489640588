import { Helmet, HelmetData } from 'react-helmet-async';

interface HeadProps {
  title: string;
}

const helmetData = new HelmetData({});

export const Head = ({ title }: HeadProps) => {
  return (
    <Helmet helmetData={helmetData}>
      <title>{title}</title>
      <meta charSet='utf-8' />
      <meta name='viewport' content='initial-scale=1.0, width=device-width' />
    </Helmet>
  );
};
