import { BillingPeriod, PaymentPlanOutput } from '../../services/api-iteration1/billing/api-models';
import { useInvoice } from '../../services/api-iteration1/billing';

import { PaymentSummaryCard } from './PaymentSummaryCard';

interface InvoicePaymentSummaryCardProps {
  invoiceId: string;
  plan: PaymentPlanOutput;
  billingPeriod: BillingPeriod;
  button: (disabled: boolean) => React.ReactNode;
}

export const InvoicePaymentSummaryCard = ({
  invoiceId,
  plan,
  billingPeriod,
  button,
}: InvoicePaymentSummaryCardProps) => {
  const planPrice = plan.price.find((p) => p.recurring?.interval === billingPeriod);

  const { data: invoice, isValidating } = useInvoice(invoiceId);

  if (!planPrice?.value || !planPrice?.recurring) return null;

  return (
    <PaymentSummaryCard
      invoice={invoice}
      isLoading={isValidating}
      {...{
        plan,
        planPrice,
        billingPeriod,
        button,
      }}
    />
  );
};
