import { useMemo, useState } from 'react';

interface SearchService<T> {
  filteredResults: T[];
  searchTerm: string | undefined;
  setSearchTerm: (searchTerm: string | undefined) => void;
}

interface SearchProps<T> {
  fieldNames: (keyof T)[];
  items: T[];
}

export const useSearch = <T>({ fieldNames, items }: SearchProps<T>): SearchService<T> => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

  const stringifiedFieldNames = fieldNames.join(',');

  const filteredResults = useMemo(() => {
    const fieldNames = stringifiedFieldNames.split(',') as (keyof T)[];
    if (!searchTerm) return items;

    return items.reduce((result, item) => {
      for (let i = 0; i < fieldNames.length; i++) {
        const currentFieldName = fieldNames[i];
        const isMatch = String(item[currentFieldName])
          .toLowerCase()
          .includes(searchTerm?.toLowerCase());

        if (isMatch) return [...result, item];
      }

      return result;
    }, [] as any[]);
  }, [items, searchTerm, stringifiedFieldNames]);

  return {
    filteredResults,
    searchTerm,
    setSearchTerm,
  };
};
