import { useMemo } from 'react';

import { useTranslationContext } from '../../../../services/translation';
import { useCurrentUserContext } from '../../../services/current-user';
import {
  composeValidators,
  isRequired,
  validateEmail,
  isValidPassword,
  isValidText,
} from '../../../services/validation';
import { useErrorHandlerContext } from '../../../../services/error-handling';
import { allClasses } from '../../../../services/utilities/array';
import { useQueryParams } from '../../../../services/router';

import { AuthError } from './components/AuthError';
import {
  Button,
  CheckboxField,
  createFormSubmitHandler,
  Form,
  FormError,
  FormField,
  Navlink,
} from '../../../../components';
import { SeparatorLine } from '../../../../components/separator-line/SeparatorLine';

import { GoogleDarkIcon, Iteration1FullLogo } from '../../../assets/icons';
import { routes, websiteRoutes } from '../../../pages/routing/routes';

export const SignUpForm = () => {
  const { t } = useTranslationContext.useContext();
  const {
    params: { email },
  } = useQueryParams<{ email: string | undefined }>();
  const { signUpWithEmail, signInWithGoogle } = useCurrentUserContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();

  const initialUserValues = useMemo(
    () => ({
      email: email || '',
      password: '',
      firstName: '',
      lastName: '',
      termsConsent: false,
      privacyConsent: false,
    }),
    [email]
  );

  const signUp = createFormSubmitHandler<typeof initialUserValues>({
    tryAction: async ({ email, password, firstName, lastName, ...terms }) => {
      return signUpWithEmail(email, password, {
        ...terms,
        firstName,
        lastName,
      });
    },
  });

  return (
    <div className='flex flex-col w-full max-w-sm mt-32 mb-16 mx-4'>
      <div className='mb-10'>
        <Iteration1FullLogo />
      </div>
      <h3 className='mb-1 text-2xl text-main-contrast font-bold leading-8'>
        {t('pages.sign-up.welcome')}
      </h3>
      <p className='mb-10 text-base text-neutral-65 leading-5'>{t('pages.sign-up.subtitle')}</p>
      <Button
        className='w-full flex flex-row items-center justify-center py-3.5 border-2 border-main-highlight rounded-lg hover:enabled:bg-main-highlight'
        onClick={() => signInWithGoogle().catch(handleError)}>
        <GoogleDarkIcon />
        <span className='ml-5 text-base text-main-contrast leading-5 font-medium'>
          {t('pages.sign-up.google-sign-up')}
        </span>
      </Button>
      <div className='my-6'>
        <SeparatorLine content={t('common.or')} />
      </div>
      <Form className='flex flex-col' initialValues={initialUserValues} onSubmit={signUp}>
        {({ status, setStatus, isValidForSubmit, isSubmitting, errors }) => (
          <>
            <FormError {...{ status }} />
            <AuthError
              handleErrorCode={(errorCode) =>
                setStatus({ state: 'error', messageKeys: [errorCode] })
              }
            />
            <div className='mb-6 flex flex-col items-start justify-between sm:flex-row'>
              <FormField
                type='text'
                name='firstName'
                placeholder={t('form.fields.first-name')}
                label={t('form.fields.first-name')}
                validate={composeValidators(isValidText, isRequired)}
                fieldClassName='w-full relative mb-6 sm:w-1/2 sm:mr-5 sm:mb-0'
                inputClassName='py-3 px-4 text-lg font-normal leading-normal text-main-contrast border-main-10 border-2 border-solid placeholder-neutral rounded-lg cursor-pointer hover:border-main-highlight focus:border-main-highlight focus:outline-none'
                labelClassName='text-sm text-main-contrast font-bold'
              />
              <FormField
                type='text'
                name='lastName'
                placeholder={t('form.fields.last-name')}
                label={t('form.fields.last-name')}
                validate={composeValidators(isValidText, isRequired)}
                fieldClassName='w-full relative sm:w-1/2'
                inputClassName='py-3 px-4 text-lg font-normal leading-normal text-main-contrast border-main-10 border-2 border-solid placeholder-neutral rounded-lg cursor-pointer hover:border-main-highlight focus:border-main-highlight focus:outline-none'
                labelClassName='text-sm text-main-contrast font-bold'
              />
            </div>
            <FormField
              name='email'
              type='email'
              placeholder={t('form.fields.email-address')}
              label={t('form.fields.email-address')}
              validate={validateEmail}
              fieldClassName='mb-6 relative'
              inputClassName='py-3 px-4 text-lg font-normal leading-normal text-main-contrast border-main-10 border-2 border-solid placeholder-neutral rounded-lg cursor-pointer hover:border-main-highlight focus:border-main-highlight focus:outline-none'
              labelClassName='text-sm text-main-contrast font-bold'
            />
            <FormField
              type='password'
              name='password'
              placeholder={t('form.fields.password')}
              label={t('form.fields.password')}
              validate={isValidPassword}
              fieldClassName='mb-6 relative'
              inputClassName='py-3 px-4 text-lg font-normal leading-normal text-main-contrast border-main-10 border-2 border-solid placeholder-neutral rounded-lg cursor-pointer hover:border-main-highlight focus:border-main-highlight focus:outline-none'
              labelClassName='text-sm text-main-contrast font-bold'
            />
            <div
              className={allClasses(
                'flex flex-row items-center relative mb-6',
                errors.privacyConsent && 'mb-8'
              )}>
              <FormField
                component={CheckboxField}
                type='checkbox'
                id='privacyConsent'
                name='privacyConsent'
                validate={isRequired}
                fieldClassName='w-4 h-4 mr-2'
                errorClassName='absolute mt-0.5 text-left'
                inputClassName='w-5 mr-2 cursor-pointer'
                labelClassName='text-sm text-neutral-65 cursor-pointer'
                inputContainerClassName='flex flex-row-reverse justify-end items-center'
              />
              <>
                {t('form.fields.privacy.part1')}
                <a
                  href={websiteRoutes.privacy}
                  className='text-main-contrast underline pl-0.5 hover:text-info'>
                  {t('form.fields.privacy.part2')}
                </a>
              </>
            </div>
            <div
              className={allClasses(
                'flex flex-row items-center relative mb-6',
                errors.termsConsent && 'mb-8'
              )}>
              <FormField
                component={CheckboxField}
                type='checkbox'
                id='termsConsent'
                name='termsConsent'
                validate={isRequired}
                fieldClassName='w-4 h-4 mr-2'
                errorClassName='absolute mt-0.5 text-left'
                inputClassName='w-5 mr-2 cursor-pointer'
                labelClassName='text-sm text-neutral-65 cursor-pointer'
                inputContainerClassName='flex flex-row-reverse justify-end items-center'
              />
              <>
                {t('form.fields.terms.part1')}
                <a
                  href={websiteRoutes.terms}
                  className='text-main-contrast underline pl-0.5 hover:text-info'>
                  {t('form.fields.terms.part2')}
                </a>
              </>
            </div>
            <Button
              loading={isSubmitting}
              className='button__filled w-full justify-center h-12'
              type='submit'
              disabled={!isValidForSubmit}>
              {t('common.auth.sign-up')}
            </Button>
          </>
        )}
      </Form>
      <div className='mt-10 text-base text-main-contrast font-medium leading-5'>
        {t('pages.sign-up.already-have-account')}
        <Navlink
          to={routes.signIn}
          className='ml-2 text-info border-b-default border-main-70 border-solid hover:text-info-70'>
          {t('common.auth.sign-in')}
        </Navlink>
      </div>
    </div>
  );
};
