import { useErrorHandlerContext } from '../../../services/error-handling';
import { InvoiceOutput } from '../../services/api-iteration1/billing/api-models';
import { getStripePaymentErrorMessage } from '../../services/billing/payment';
import { useTranslationContext } from '../../../services/translation';
import { payInvoice } from '../../services/api-iteration1/billing';

import { Button, usePaymentContext, usePaymentInstance } from '../../../components';

import { routes } from '../../pages/routing/routes';

interface PayInvoiceButtonProps {
  invoice: InvoiceOutput;
  onRequiresNewPaymentMethod: (params: { clientSecret: string; invoiceId: string }) => void;
}

export const PayInvoiceButton = ({
  invoice,
  onRequiresNewPaymentMethod,
}: PayInvoiceButtonProps) => {
  const { t } = useTranslationContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();

  const paymentInstance = usePaymentInstance();
  const paymentElements = usePaymentContext();

  const paySelectedInvoice = async (invoice: InvoiceOutput) => {
    if (
      !paymentInstance ||
      !paymentElements ||
      ['canceled', 'processing', 'succeeded', null].includes(invoice.lastPaymentIntentStatus)
    )
      return;

    if (
      invoice.paymentIntentClientSecret &&
      invoice.lastPaymentIntentStatus === 'requires_payment_method'
    )
      return onRequiresNewPaymentMethod({
        clientSecret: invoice.paymentIntentClientSecret,
        invoiceId: invoice.id,
      });

    try {
      if (
        invoice.paymentIntentClientSecret &&
        invoice.lastPaymentIntentStatus &&
        ['requires_action', 'requires_confirmation'].includes(invoice.lastPaymentIntentStatus)
      ) {
        const { error } = await paymentInstance.confirmPayment({
          elements: paymentElements,
          confirmParams: {
            return_url: window.location.origin + routes.billing,
          },
          redirect: 'if_required',
        });

        if (error) {
          const errorMessage = getStripePaymentErrorMessage(error, t);
          handleError(error, errorMessage);
        }
        return;
      }

      await payInvoice(invoice.id);
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <Button className='button__ghost w-fit' onClick={() => paySelectedInvoice(invoice)}>
      {t('pages.account.tabs.plan-billing.billing-history.invoice.pay')}
    </Button>
  );
};
