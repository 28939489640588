import { useEffect } from 'react';

import { useLocation, useRouter } from '../../../../services/router';
import { useCurrentUserContext } from '../../../services/current-user';

import { AccessViewProps } from '../../../../components/layout/utilities/layout';
import { LoadingOrContent } from '../../../../components/layout/LoadingOrContent';

import { routes } from '../../../pages/routing/routes';

export const AccessView = ({
  requiresAuthentication = false,
  canAccessPage = true,
  redirectUrl = '/',
  isLoading,
  error,
  children,
}: AccessViewProps) => {
  const { push } = useRouter();
  const location = useLocation();
  const { isLoadingUser, isAuthenticated, socialAuthError, currentUser, clearSocialAuthError } =
    useCurrentUserContext.useContext();

  const isFinishedSignUp =
    !!currentUser?.firstName && !!currentUser?.privacyConsent && !!currentUser?.termsConsent;
  const shouldBeAuthenticated = requiresAuthentication && !isAuthenticated;
  const shouldConfirmEmail =
    requiresAuthentication && isAuthenticated && !!currentUser?.requiresEmailConfirmation;
  const accessNotAllowed = isLoadingUser || shouldBeAuthenticated || !canAccessPage;

  useEffect(() => {
    const notSignInPage = location.pathname !== routes.signIn;

    if (socialAuthError && notSignInPage) clearSocialAuthError();
  }, [clearSocialAuthError, location.pathname, socialAuthError]);

  useEffect(() => {
    if (isLoadingUser) return;

    if (shouldBeAuthenticated) push(routes.signIn + location.search);
    else if (isAuthenticated && !isFinishedSignUp) push(routes.finishSignUp + location.search);
    else if (shouldConfirmEmail) push(routes.verifyAccountEmail + location.search);
    else if (!canAccessPage) push(redirectUrl + location.search);
  }, [
    canAccessPage,
    isAuthenticated,
    isFinishedSignUp,
    isLoadingUser,
    location.search,
    push,
    redirectUrl,
    shouldBeAuthenticated,
    shouldConfirmEmail,
  ]);

  return (
    <LoadingOrContent isFullscreen isLoading={isLoading || accessNotAllowed} {...{ error }}>
      {children}
    </LoadingOrContent>
  );
};
