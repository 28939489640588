import { ReactNode } from 'react';

interface AnalyticsStatisticProps {
  title: string;
  isLast?: boolean;
  children?: ReactNode;
}

export const AnalyticsStatistic = ({ title, isLast, children }: AnalyticsStatisticProps) => {
  return (
    <>
      <div className='mb-6 xl:mb-0 xl:col-span-2 flex justify-center'>
        <div className='flex flex-col items-start flex-nowrap'>
          <p className='text-sm text-main-contrast leading-5 mb-2 whitespace-nowrap'>{title}</p>
          <div className='whitespace-nowrap'>{children}</div>
        </div>
      </div>
      {!isLast && (
        <div className='hidden xl:flex justify-center w-full xl:col-span-1 h-full'>
          <div className='h-full bg-main-10 w-0.5' />
        </div>
      )}
    </>
  );
};
