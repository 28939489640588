export enum PrototypeEvents {
  ChangeFrameByHotspotClick = 'change-frame-by-hotspot-click',
}

export const prototypeEventHandlers = () => {
  const triggers = {
    changeFrameByHotspotClick: (targetFrameId: string) => {
      const event = new CustomEvent<{ targetFrameId: string }>(
        PrototypeEvents.ChangeFrameByHotspotClick,
        {
          detail: { targetFrameId },
        }
      );

      dispatchEvent(event);
    },
  };

  const listeners = {
    onChangeFrameByHotspotClick: (
      callback: (e: CustomEvent<{ targetFrameId: string }>) => void
    ) => {
      const handler = (e: any) => callback(e);

      addEventListener(PrototypeEvents.ChangeFrameByHotspotClick, handler);

      return () => removeEventListener(PrototypeEvents.ChangeFrameByHotspotClick as any, handler);
    },
  };

  return {
    triggers,
    listeners,
  };
};
