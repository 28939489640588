import { useCallback, useMemo } from 'react';

import { FolderChildType } from '../../../services/api-iteration1';
import { useResourceCollaborators } from '../../../services/api-iteration1/collaborators';
import { Collaborator } from '../../../services/api-iteration1/collaborators/api-models';
import { useUserCollaboratorsContext } from '../../../services/collaborators';
import { getSortedCollaborators } from '../../../services/collaborators/utils';
import { useSearch } from '../../../services/filters/useSearch';

interface CurrentCollaboratorsService {
  sortedCollaborators: Collaborator[];
  projectCollaborators: Collaborator[];
  searchTerm: string | undefined;
  searchResults: Collaborator[];
  mutateCollaboratorsLocally: (
    collaboration: {
      collaboratorId: string;
      resourceId?: string;
      resourceType?: FolderChildType;
    },
    updates: Partial<Collaborator> | null
  ) => void;
  setSearchTerm: (searchTerm: string | undefined) => void;
  revalidateCollaborators: () => void;
  revalidateProjectCollaborators: () => void;
}

interface useCurrentCollaboratorsProps {
  resourceType: FolderChildType;
  resourceId: string;
}

export const useResourceCollaboratorsList = ({
  resourceId,
  resourceType,
}: useCurrentCollaboratorsProps): CurrentCollaboratorsService => {
  const { currentUserCollaborators, mutateLocallyCurrentUserCollaborators, revalidate } =
    useUserCollaboratorsContext.useContext();

  const {
    data: projectCollaborators,
    mutate,
    revalidate: revalidateProjectCollaborators,
  } = useResourceCollaborators(resourceType, resourceId, {
    revalidateOnFocus: false,
  });

  const collaboratorsList = useMemo(() => {
    return [...(projectCollaborators || []), ...(currentUserCollaborators || [])].reduce<
      Collaborator[]
    >((result, collaborator) => {
      const isAlreadyInList = result.find((collab) => collab.id === collaborator.id);
      return isAlreadyInList ? result : [...result, collaborator];
    }, []);
  }, [currentUserCollaborators, projectCollaborators]);

  const {
    filteredResults: searchResults,
    searchTerm,
    setSearchTerm,
  } = useSearch({
    fieldNames: ['firstName', 'lastName', 'email'],
    items: collaboratorsList,
  });

  const { sortableCollaborators } = useMemo(
    () =>
      getSortedCollaborators(
        {
          id: resourceId,
          type: resourceType,
        },
        searchResults
      ),
    [searchResults, resourceId, resourceType]
  );

  const mutateCollaboratorsLocally = useCallback(
    async (
      collaboration: {
        collaboratorId: string;
        resourceId?: string;
        resourceType?: FolderChildType;
      },
      updates: Partial<Collaborator> | null
    ) => {
      mutateLocallyCurrentUserCollaborators(collaboration, updates);
      mutate((prev) => {
        if (!prev) return;

        const data: Collaborator[] = prev.data;

        const { collaboratorId } = collaboration;

        const shouldRemoveCollaborator = !updates;
        if (shouldRemoveCollaborator) {
          const updatedCollaboratorsList = data.filter(
            (collaborator) => collaborator.id !== collaboratorId
          );

          return {
            ...prev,
            data: updatedCollaboratorsList,
          };
        }

        const collaboratorToUpdate = data.find(
          (collaborator) => collaborator.id === collaboratorId
        );

        if (!collaboratorToUpdate) {
          const updatedCollaboratorsList = [...data, updates];

          return { ...prev, data: updatedCollaboratorsList as Collaborator[] };
        } else {
          const updatedCollaboratorsList = data.map((collaborator: Collaborator) =>
            collaborator.id === collaboratorId
              ? {
                  ...collaborator,
                  ...updates,
                }
              : collaborator
          );

          return { ...prev, data: updatedCollaboratorsList };
        }
      }, false);
    },
    [mutate, mutateLocallyCurrentUserCollaborators]
  );

  return {
    sortedCollaborators: sortableCollaborators,
    projectCollaborators: projectCollaborators || [],
    searchTerm,
    searchResults,
    mutateCollaboratorsLocally,
    setSearchTerm,
    revalidateCollaborators: revalidate,
    revalidateProjectCollaborators,
  };
};
