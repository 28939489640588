import { useState } from 'react';

import { useTranslationContext } from '../../../../../services/translation';
import { allClasses } from '../../../../../services/utilities/array';
import { StorageFileType } from '../../../../services/api-cloud-storage/api-models';
import {
  DiscoveryLegalAnswer,
  DiscoverySectionLegalOutput,
  DiscoverySectionType,
} from '../../../../services/api-iteration1';
import { getStoragePublicFileUrl } from '../../../../services/api-cloud-storage';

import { SectionTypeLabel } from './SectionTypeLabel';
import { CheckboxIcon, ExternalLinkIcon, UploadDocumentIcon } from '../../../../assets/icons';

import styles from './ViewSection.module.css';

interface ViewLegalProps {
  legalSection: DiscoverySectionLegalOutput;
  answer: DiscoveryLegalAnswer | undefined;
  onSetAnswer?: (answer: Omit<DiscoveryLegalAnswer, 'duration'>) => void;
  interactive?: boolean;
}

export const ViewLegal = ({ legalSection, answer, onSetAnswer, interactive }: ViewLegalProps) => {
  const { t } = useTranslationContext.useContext();

  const [isViewed, setIsViewed] = useState<boolean>(false);
  const { title, files, others } = legalSection;
  const { description, message } = others;

  const sectionDocument = files.find((f) => f.type === StorageFileType.LegalDocument);
  const sectionDocumentName = sectionDocument?.fileName;
  const isActive = answer?.agreed;

  const openDocument = () => {
    setIsViewed(true);
  };

  return (
    <div className={allClasses(styles.view_section_wrapper, 'max-w-2xl')}>
      {interactive && (
        <SectionTypeLabel label={t('components.projects.sections.question')} className='w-full' />
      )}
      <h6 className='inline-block w-full text-xl text-main-contrast font-bold leading-30 mb-2.5 break-all'>
        {title}
      </h6>
      {description && (
        <p className='inline-block w-full text-lg text-main-contrast font-normal leading-normal'>
          {description}
        </p>
      )}
      {sectionDocument && (
        <div className='flex justify-between items-center w-full rounded-lg border-2 border-solid border-main-10 p-5 mt-7'>
          <div className='flex items-center justify-center'>
            <div className='bg-main-10 p-2.5 rounded mr-5'>
              <UploadDocumentIcon className='w-10 h-10' />
            </div>
            <span>{sectionDocumentName}</span>
          </div>
          <a href={getStoragePublicFileUrl(sectionDocument.id)} target='_blank' rel='noreferrer'>
            <ExternalLinkIcon
              className='icon-main-contrast icon-stroke-polyline-main-contrast'
              onClick={openDocument}
            />
          </a>
        </div>
      )}
      <div className='flex flex-row justify-start mt-7 w-full'>
        <div
          onClick={() =>
            onSetAnswer?.({
              agreed: !isActive,
              viewed: isViewed,
              type: DiscoverySectionType.Legal,
            })
          }
          className={allClasses(
            'relative flex items-center p-3 mb-3 border-2 border-solid rounded-lg w-full',
            isActive ? 'border-main-highlight' : 'border-main-10',
            onSetAnswer && 'cursor-pointer'
          )}>
          {isActive && <div className='absolute inset-0 bg-main-highlight opacity-10 z-0' />}
          <CheckboxIcon
            className={allClasses(
              'z-10 mr-4',
              isActive && 'icon-main-contrast icon-fill-main-contrast'
            )}
          />
          <p className='text-xl text-main-contrast font-bold'>{message}</p>
        </div>
      </div>
    </div>
  );
};
