interface VersionCardBodyProps {
  title: string;
}

export const VersionCardBody = ({ title }: VersionCardBodyProps) => {
  return (
    <p className='text-main-contrast text-xl bold font-bold overflow-hidden text-ellipsis mt-5 mb-auto'>
      {title}
    </p>
  );
};
