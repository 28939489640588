export const endpoints = {
  v1: {
    responses: (discoveryId: string, versionId: string) =>
      `/v1/discoveries/${discoveryId}/versions/${versionId}/responses`,
    response: (discoveryId: string, versionId: string, responseId: string) =>
      `/v1/discoveries/${discoveryId}/versions/${versionId}/responses/${responseId}`,
    giveUpResponse: (discoveryId: string, versionId: string, responseId: string) =>
      `/v1/discoveries/${discoveryId}/versions/${versionId}/responses/${responseId}/give-up`,
  },
};
