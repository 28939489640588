import { PropsWithChildren } from 'react';

import { allClasses } from '../../services/utilities/array';

import { ContentOrError } from './ContentOrError';
import { LoadingIndicator } from '../loading-indicator';

interface LoadingOrContentProps {
  isLoading: boolean;
  error?: any;
  className?: string;
  isFullscreen?: boolean;
}

export const LoadingOrContent = ({
  isLoading,
  error,
  className,
  isFullscreen,
  children,
}: PropsWithChildren<LoadingOrContentProps>) => {
  return isLoading ? (
    <div
      className={allClasses(
        'w-full h-full flex justify-center items-center',
        isFullscreen && 'h-screen',
        className
      )}>
      <LoadingIndicator />
    </div>
  ) : (
    <ContentOrError {...{ error }}>{children}</ContentOrError>
  );
};
