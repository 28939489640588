import { useRouteParams } from '../../services/router';
import { VersionStatus } from '../services/api-iteration1';
import { useCurrentUserTestVersionContext } from '../services/userTest';

import { withAuth } from '../components';

import { EditUserTest } from './EditUserTest';
import { UserTestResults } from './UserTestResults';

type UserTestParams = {
  userTestId: string;
  versionId: string;
};

export const UserTestVersion = withAuth(() => {
  const { userTestId, versionId } = useRouteParams<UserTestParams>();

  return (
    <useCurrentUserTestVersionContext.ProvideContext
      userTestId={userTestId}
      userTestVersionId={versionId}>
      <UserTestVersionPage />
    </useCurrentUserTestVersionContext.ProvideContext>
  );
});

export const UserTestVersionPage = () => {
  const { userTestVersion } = useCurrentUserTestVersionContext.useContext();
  const { status } = userTestVersion || {};

  return status === VersionStatus.Draft ? <EditUserTest /> : <UserTestResults />;
};
