import { DiscoveryOpenWordAnswer } from '../../../../services/api-iteration1';

import { AnalyticsSectionAnswerEmptyState } from '../../../empty-states';
import { AnalyticsOpenWordAnswerCard } from '../../../cards';

interface ViewAnalyticsOpenWordChoiceAnswerProps {
  answer?: DiscoveryOpenWordAnswer;
  updatedAt: string;
}

export const ViewAnalyticsOpenWordChoiceAnswer = ({
  answer,
  updatedAt,
}: ViewAnalyticsOpenWordChoiceAnswerProps) => {
  return answer ? (
    <div className='flex flex-col w-full max-w-5xl items-start'>
      <AnalyticsOpenWordAnswerCard isLast content={answer.answer} index={0} date={updatedAt} />
    </div>
  ) : (
    <AnalyticsSectionAnswerEmptyState />
  );
};
