import { FetchOptions } from '../../../../../../services/api/resource-fetch-factory';
import { DeviceDetails } from '../../../../../../services/device';

import { QuestionAnswer } from '../..';
import { StorageFile } from '../../../../api-cloud-storage/api-models';
import { apiIteration1, useIteration1Resource } from '../../../config';
import {
  CreateUserTestVersionResponseOutput,
  UserTestFeedback,
  UserTestVersionResponseOutput,
} from './api-models';
import { endpoints } from './endpoints';

export * from './api-models';

export const useUserTestResponses = (
  userTestId: string,
  versionId: string,
  options?: FetchOptions
) => {
  return useIteration1Resource<UserTestVersionResponseOutput[]>(
    endpoints.v1.responses(userTestId, versionId),
    options
  );
};

export const createNewUserTestResponse = async (
  userTestId: string,
  versionId: string,
  newResponse: {
    userId?: string;
    totalQuestions: number;
    device: DeviceDetails;
  }
): Promise<CreateUserTestVersionResponseOutput> => {
  const response = await apiIteration1.post<CreateUserTestVersionResponseOutput>(
    endpoints.v1.responses(userTestId, versionId),
    newResponse
  );

  return response.data;
};

export const addUserTestResponseAnswer = async (
  userTestId: string,
  versionId: string,
  responseId: string,
  newResponse: {
    sectionId?: string;
    answer: QuestionAnswer;
  }
) => apiIteration1.post(endpoints.v1.response(userTestId, versionId, responseId), newResponse);

export const giveUpUserTestResponseAnswer = async (
  userTestId: string,
  versionId: string,
  responseId: string,
  reason: string
) =>
  apiIteration1.post(endpoints.v1.response_give_up(userTestId, versionId, responseId), { reason });

export const sendFeedbackUserTestResponseAnswer = async (
  userTestId: string,
  versionId: string,
  responseId: string,
  sectionId: string,
  feedback: Omit<UserTestFeedback, 'sectionId'>
) =>
  apiIteration1.patch(
    endpoints.v1.response_section_feedback(userTestId, versionId, responseId, sectionId),
    {
      feedback,
    }
  );

export const uploadUserTestResponseSectionVideo = async (
  userTestId: string,
  versionId: string,
  sectionId: string,
  responseId: string,
  video: Blob
): Promise<StorageFile> => {
  const formData = new FormData();
  formData.append('video', video);

  const result = await apiIteration1.patch<StorageFile>(
    endpoints.v1.response_upload_section_video(userTestId, versionId, sectionId, responseId),
    formData
  );

  return result.data;
};
