import { StorageFileType } from '../../../services/api-cloud-storage/api-models';
import {
  createIndexedTemporaryFile,
  SectionStorageMultipleFile,
} from '../../../services/api-iteration1';

interface SectionPreferenceTestFiles {
  files: SectionStorageMultipleFile[];
  defaultNewFile: SectionStorageMultipleFile;
}

const getLargestFileIndex = (files: SectionStorageMultipleFile[]) => {
  let largestFileIndex = 0;

  files.forEach((file) => {
    if (Number(file.indexId) > largestFileIndex) largestFileIndex = Number(file.indexId);
  });

  return largestFileIndex;
};

export const getSectionPreferenceTestFiles = (
  files: SectionStorageMultipleFile[]
): SectionPreferenceTestFiles => {
  // TODO: this does not have a state, so when we remove the last file if previous file is temporary, it will be removed as well (because of the largest index calculus)
  // TODO: in order to fix this, we need to take the previous state into account, which means this function should be send to useDynamicInputs as prop and consume previous state
  const largestIndex = getLargestFileIndex(files);

  const initialFilesCount = largestIndex + 1 > 2 ? largestIndex + 1 : 2;
  const newFileIndex = String(initialFilesCount);
  const defaultNewFile = {
    id: createIndexedTemporaryFile(newFileIndex),
    indexId: newFileIndex,
    name: '',
    type: StorageFileType.SectionMultipleImages,
  };

  const initialDefaultFiles = [...new Array(initialFilesCount)].map((_, index) => ({
    ...defaultNewFile,
    id: createIndexedTemporaryFile(String(index)),
    indexId: String(index),
  }));

  const mixedFiles = initialDefaultFiles
    .map((defaultFile, index) => {
      const uploadedFileForIndex = files.find((file) => file.indexId === String(index));

      return uploadedFileForIndex ? uploadedFileForIndex : defaultFile;
    })
    .sort((a, b) => {
      return a.indexId < b.indexId ? -1 : 1;
    });

  return { files: mixedFiles, defaultNewFile };
};
