import { useTranslationContext } from '../../../services/translation';
import { allClasses } from '../../../services/utilities/array';

import { Button, useToggle } from '../../../components';

import { GiveUpModal } from '../modals/GiveUpModal';
import { InfoLearnMore } from '../info';

interface TestNavbarRightButtonProps {
  isLast?: boolean;
  canContinue: boolean;
  canGiveUp?: boolean;
  actionType: 'continue' | 'give-up';
  onClickContinue: (answer: any) => Promise<void>;
  onClickGiveUp?: (reason: string) => void;
}

export const TestNavbarRightButton = ({
  isLast,
  actionType,
  canContinue,
  canGiveUp,
  onClickContinue,
  onClickGiveUp,
}: TestNavbarRightButtonProps) => {
  const { t } = useTranslationContext.useContext();

  const {
    isOpen: isGiveUpModalVisible,
    open: openGiveUpModal,
    close: closeGiveUpModal,
  } = useToggle();

  if (isLast) return <InfoLearnMore />;

  const {
    disabled,
    onClick,
    textKey,
    className,
  }: {
    disabled?: boolean;
    onClick?: any;
    textKey?: string;
    className?: string;
  } = (() => {
    switch (actionType) {
      case 'continue':
        return {
          disabled: !canContinue,
          onClick: onClickContinue,
          textKey: 'common.continue',
          className: 'button__filled',
        };
      case 'give-up':
        return {
          disabled: !canGiveUp,
          onClick: openGiveUpModal,
          textKey: 'common.give-up',
          className: 'button__ghost',
        };
      default:
        return {};
    }
  })();

  return (
    <>
      {textKey && (
        <Button className={allClasses(className, 'px-10')} {...{ disabled, onClick }}>
          {t(textKey)}
        </Button>
      )}
      {onClickGiveUp && (
        <GiveUpModal
          isOpen={isGiveUpModalVisible}
          closeModal={closeGiveUpModal}
          giveUp={onClickGiveUp}
        />
      )}
    </>
  );
};
