import { StorageFile } from '../../api-cloud-storage/api-models';

export enum DesignPlatform {
  Invision = 'invision',
  Sketch = 'sketch',
  Figma = 'figma',
  AdobeXd = 'adobexd',
  Marvel = 'marvel',
}

export interface DesignPrototypeVersion {
  version: string;
  type: DesignPlatform;
  device: 'mobile' | 'desktop';
  frames: { id: string; image?: StorageFile }[];
  [key: string]: any;
}
