import { MouseEventHandler, ReactNode } from 'react';

import { allClasses } from '../../../services/utilities/array';
import { useTranslationContext } from '../../../services/translation';

import { DraggableHandleProps, DraggableItem, DraggableViewProps } from '../../../components';
import { Tooltip } from '../../../components/tooltip/Tooltip';
import { Toggle } from '../../../components/toggle';

import { DeleteIcon, DuplicateIcon, RequiredIcon } from '../../assets/icons';

import styles from './SelectSectionItem.module.css';

type SelectSectionItemProps = Omit<Item, 'dragViewProps' | 'dragHandleProps'> & {
  children?: ReactNode;
} & (
    | {
        index: number;
        draggableItemId: string;
      }
    | {
        index?: never;
        draggableItemId?: never;
      }
  );

export const SelectSectionItem = ({
  index = -1,
  draggableItemId,
  children,
  ...itemProps
}: SelectSectionItemProps) => {
  if (!draggableItemId) return <Item {...itemProps}>{children}</Item>;

  return (
    <DraggableItem id={draggableItemId} {...{ index }}>
      {(dragViewProps, dragHandleProps) => (
        <Item
          {...itemProps}
          {...{
            dragViewProps,
            dragHandleProps,
          }}>
          {children}
        </Item>
      )}
    </DraggableItem>
  );
};

interface Item {
  dragViewProps?: DraggableViewProps;
  dragHandleProps?: DraggableHandleProps | undefined;
  alreadyAdded?: boolean;
  description?: string | React.ReactNode;
  icon: React.ReactNode;
  disabled?: boolean;
  onClick: MouseEventHandler<HTMLDivElement>;
  title: string;
  type?: string;
  isExpanded?: boolean;
  isInvalid?: boolean;
  isLoading?: boolean;
  containerClassName?: string;
  onDelete?: (e: any) => void;
  onDuplicate?: (e: any) => void;
  onDisable?: (disabled?: boolean) => void;
  showRequiredIcon?: boolean;
  outcomeBullet?: React.ReactNode;
  childrenClassName?: string;
  loading?: boolean;
  children?: ReactNode;
}

const Item = ({
  dragViewProps,
  dragHandleProps,
  alreadyAdded,
  isExpanded,
  isInvalid,
  isLoading,
  containerClassName,
  disabled,
  onClick,
  type,
  title,
  icon,
  description,
  children,
  onDelete,
  onDuplicate,
  onDisable,
  showRequiredIcon,
  outcomeBullet,
  childrenClassName,
  loading,
}: Item) => {
  const { t } = useTranslationContext.useContext();

  return (
    <div
      {...dragViewProps}
      {...dragHandleProps}
      className={allClasses(
        'cursor-pointer w-full bg-main',
        alreadyAdded && 'mb-5 border-2 border-solid border-main-10 rounded-lg',
        (isExpanded || isLoading) && 'border-main-highlight',
        isInvalid && !isExpanded && !isLoading && 'border-danger',
        containerClassName,
        styles.section_item__wrapper,
        loading && 'opacity-50 pointer-events-none',
        'hover:border-main-highlight',
        isExpanded || alreadyAdded ? 'hover:border-main-highlight' : 'hover:bg-main-10'
      )}>
      <div
        className={allClasses(
          'flex flex-row',
          !alreadyAdded && 'border-b border-main-10 border-solid',
          'relative py-5 px-[1.125rem]',
          childrenClassName
        )}
        onClick={onClick}
        data-heading-type={type}>
        {outcomeBullet}
        <span>
          <span className='w-5 h-5'>{icon}</span>
          {showRequiredIcon && (
            <span className='flex mt-2'>
              <Tooltip
                content={t('components.projects.sections.required-section')}
                direction='right'>
                <RequiredIcon className='icon__small' />
              </Tooltip>
            </span>
          )}
        </span>
        <div className='ml-5'>
          <p
            className={allClasses(
              'text-base text-main-contrast leading-5 font-bold pr-4 overflow-hidden w-60 text-ellipsis break-all whitespace-nowrap',
              description && 'mb-2'
            )}>
            {title}
          </p>
          <p className='text-base text-neutral-70 leading-5 m-0'>{description}</p>
          {onDisable && (
            <span className={styles.section_item__visible_actions}>
              <div className='flex flex-row justify-between items-center text-base text-main-contrast leading-5'>
                <span className='flex items-end text-neutral-70 text-xs leading-none font-normal mr-2'>
                  {disabled ? 'Hidden' : 'Displayed'}
                </span>
                <Toggle
                  sliderClassName={styles.section_toggle_slider_wrapper}
                  circleClassName={styles.section_toggle_slider_circle}
                  isChecked={!disabled}
                  onChange={(e) => {
                    onDisable(!e.target.checked);
                  }}
                />
              </div>
            </span>
          )}
          {alreadyAdded && type && (
            <span className={styles.section_item__actions}>
              <DuplicateIcon
                className='icon-hover-main-contrast cursor-pointer'
                onClick={(e) => onDuplicate?.(e)}
              />
              <DeleteIcon
                className='icon-hover-main-contrast cursor-pointer'
                onClick={(e) => onDelete?.(e)}
              />
            </span>
          )}
        </div>
      </div>
      {isExpanded ? children : null}
    </div>
  );
};
