import { getCountry } from '../../../../services/countries';

import { Item } from '../../../../components';

export const countryTaxCodes: Record<string, Item[]> = {
  au: [
    { value: 'au_abn', label: 'Australian Business Number (AU ABN)' },
    { value: 'au_arn', label: 'Australian Taxation Office Reference Number' },
  ],
  eu: [{ value: 'eu_vat', label: 'European VAT number' }],
  br: [
    { value: 'br_cnpj', label: 'Brazilian CNPJ number' },
    { value: 'br_cpf', label: 'Brazilian CPF number' },
  ],
  ca: [
    { value: 'ca_bn', label: 'Canadian BN' },
    { value: 'ca_gst_hst', label: 'Canadian GST/HST number' },
    { value: 'ca_pst_bc', label: 'Canadian PST number (British Columbia)' },
    { value: 'ca_pst_mb', label: 'Canadian PST number (Manitoba)' },
    { value: 'ca_pst_sk', label: '	Canadian PST number (Saskatchewan)' },
    { value: 'ca_qst', label: 'Canadian QST number (Québec)' },
  ],
  cl: [{ value: 'cl_tin', label: 'Chilean TIN' }],
  ge: [{ value: 'ge_vat', label: 'Georgian VAT' }],
  hk: [{ value: 'hk_br', label: 'Hong Kong BR number' }],
  is: [{ value: 'is_vat', label: 'Icelandic VAT' }],
  in: [{ value: 'in_gst', label: 'Indian GST number' }],
  id: [{ value: 'id_npwp', label: 'Indonesian NPWP number' }],
  il: [{ value: 'il_vat', label: 'Israel VAT' }],
  jp: [
    { value: 'jp_cn', label: 'Japanese Corporate Number (*Hōjin Bangō*)' },
    { value: 'jp_rn', label: 'Japanese Registered Foreign Businesses Registration Number' },
  ],
  li: [{ value: 'li_uid', label: 'Liechtensteinian UID number' }],
  my: [
    { value: 'my_frp', label: 'Malaysian FRP number' },
    { value: 'my_itn', label: 'Malaysian ITN' },
    { value: 'my_sst', label: 'Malaysian SST number' },
  ],
  mx: [{ value: 'mx_rfc', label: 'Mexican RFC number' }],
  nz: [{ value: 'nz_gst', label: 'New Zealand GST number' }],
  no: [{ value: 'no_vat', label: 'Norwegian VAT number' }],
  ru: [
    { value: 'ru_inn', label: 'Russian INN' },
    { value: 'ru_kpp', label: '	Russian KPP' },
  ],
  sa: [{ value: 'sa_vat', label: 'Saudi Arabia VAT' }],
  sg: [
    { value: 'sg_gst', label: 'Singaporean GST' },
    { value: 'sg_uen', label: 'Singaporean UEN' },
  ],
  za: [{ value: 'za_vat', label: 'South African VAT number' }],
  kr: [{ value: 'kr_brn', label: 'Korean BRN' }],
  es: [{ value: 'es_cif', label: 'Spanish CIF number' }],
  ch: [{ value: 'ch_vat', label: 'Switzerland VAT number' }],
  tw: [{ value: 'tw_vat', label: 'Taiwanese VAT' }],
  th: [{ value: 'th_vat', label: 'Thai VAT' }],
  ua: [{ value: 'ua_vat', label: 'Ukrainian VAT' }],
  ae: [{ value: 'ae_trn', label: 'United Arab Emirates TRN' }],
  gb: [{ value: 'gb_vat', label: 'United Kingdom VAT number' }],
  us: [{ value: 'us_ein', label: 'United States EIN' }],
};

export const getCountryTaxCodes = (countryName: string): Item[] | undefined => {
  const country = getCountry(countryName);

  const countryCodes =
    (country.code && countryTaxCodes[country.code.toLowerCase()]) ||
    (country.countryDetails && countryTaxCodes[country.countryDetails.continent.toLowerCase()]);

  return countryCodes;
};
