import { allClasses } from '../../../services/utilities/array';

interface DiscountTagProps {
  text: string;
  className?: string;
}

export const DiscountTag = ({ text, className }: DiscountTagProps) => {
  return (
    <div
      className={allClasses(
        'bg-neutral-25 h-max rounded-lg px-2 py-1 text-info text-xs',
        className
      )}>
      {text}
    </div>
  );
};
