export const endpoints = {
  v1: {
    invoicesRecent: '/v1/billing/invoices/recent',
    paymentPlans: '/v1/billing/plans',
    billingRecord: '/v1/billing/',
    previewSubscriptionPriceChange: (priceId: string) =>
      `/v1/billing/subscriptions/current/prices/${priceId}/preview`,
    currentSubscription: '/v1/billing/subscriptions/current',
    reactivateCurrentSubscription: '/v1/billing/subscriptions/current/reactivate',
    paymentSetupIntents: '/v1/billing/payments/setup-intents',
    paymentSetupIntent: (id: string) => `/v1/billing/payments/setup-intents/${id}`,
    invoice: (id: string) => `/v1/billing/invoices/${id}`,
    promoCode: (name: string) => `/v1/billing/payments/discounts/promocodes/${name}`,
  },
};
