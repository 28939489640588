import { UserTestVersionOutput } from '../../services/api-iteration1/projects/userTest/versions';

import { CardList } from '../../../components';

import { UserTestVersionCard } from '../cards/UserTestVersionCard';

interface UserTestVersionsListProps {
  versions: UserTestVersionOutput[];
  userTestId: string;
  revalidate: () => void;
}

export const UserTestVersionsList = ({
  versions,
  userTestId,
  revalidate,
}: UserTestVersionsListProps) => {
  const sortItemsByDate = versions.sort(
    (item1, item2) => new Date(item2.updatedAt).getTime() - new Date(item1.updatedAt).getTime()
  );

  return (
    <CardList>
      {sortItemsByDate.map((version) => (
        <UserTestVersionCard
          key={version.id}
          userTestVersion={version}
          {...{
            revalidate,
            userTestId,
          }}
        />
      ))}
    </CardList>
  );
};
