import { useState } from 'react';

import {
  isSubscriptionUnavailable,
  isExpiredPaymentMethod,
} from '../../../services/api-iteration1/billing';
import { useCurrentUserContext } from '../../../services/current-user';
import {
  BillingOutput,
  BillingPeriod,
  BillingUpdateInput,
  PaymentPlanOutput,
} from '../../../services/api-iteration1/billing/api-models';
import { useQueryParams } from '../../../../services/router';

import { MultiStepService, PaymentContext } from '../../../../components';

import { SaveSubscriptionButton, PaySubscriptionButton } from '../../buttons';
import { CheckoutContainer } from '../../containers';
import { BillingInfoForm, PaymentMethodForm } from '../../forms';
import { CheckoutSteps } from '../../config';

interface CheckoutProps {
  plan: PaymentPlanOutput;
  billingPeriod: BillingPeriod;
  billingRecord: BillingOutput | undefined;
  onChangePaymentMethod?: (newPaymentMethod: BillingOutput['paymentMethod']) => void;
  stepTo: MultiStepService<
    CheckoutSteps,
    { clientSecret: string; invoiceId: string } | undefined
  >['stepTo'];
  isStep: MultiStepService<
    CheckoutSteps,
    { clientSecret: string; invoiceId: string } | undefined
  >['isStep'];
  stepContext: { clientSecret: string; invoiceId: string } | undefined;
}

export const Checkout = ({
  plan,
  billingPeriod,
  billingRecord,
  stepTo,
  isStep,
  onChangePaymentMethod,
  stepContext,
}: CheckoutProps) => {
  const { currentUser, reloadUser } = useCurrentUserContext.useContext();
  const { setQueryParams } = useQueryParams();

  const [isValidForSubmitPaymentMethod, setIsValidForSubmitPaymentMethod] =
    useState<boolean>(false);

  const [billingInfoForm, setBillingInfoForm] = useState<{
    values: BillingUpdateInput;
    isValid: boolean;
  }>();

  const invalidCase = isStep('payment') && !stepContext?.clientSecret;
  if (invalidCase) {
    stepTo('exit');
    return null;
  }

  const unavailableCurrentSubscription = isSubscriptionUnavailable(currentUser?.subscription);

  const checkoutBillingInfo = billingInfoForm?.isValid
    ? billingInfoForm.values
    : billingRecord?.address
    ? { address: billingRecord.address, company: billingRecord.company }
    : null;

  const disableUpdateSubscription = billingInfoForm
    ? !billingInfoForm.isValid
    : !billingRecord?.address || !billingRecord.company;

  const exitAfterPlanChange = () => {
    // wait for the subscription updates to be reflected
    setTimeout(() => reloadUser(), 2000);

    stepTo('exit');
    setQueryParams({ planName: plan.name, planWasChanged: String(true) });
  };

  return isStep('billing') ? (
    <CheckoutContainer
      billingInfo={checkoutBillingInfo}
      invoiceId={undefined}
      button={(disabled) => (
        <PaymentContext>
          <SaveSubscriptionButton
            hasCurrentSubscription={!unavailableCurrentSubscription}
            billingInfo={billingInfoForm?.values}
            disabled={
              disabled ||
              !billingRecord?.paymentMethod ||
              isExpiredPaymentMethod(billingRecord.paymentMethod) ||
              disableUpdateSubscription
            }
            onSuccess={exitAfterPlanChange}
            {...{ plan, billingPeriod, stepTo }}
          />
        </PaymentContext>
      )}
      {...{ plan, billingPeriod, billingRecord, isStep }}>
      <div className='flex-col w-96 mr-10 h-full overflow-scroll scroll-container pb-64'>
        <BillingInfoForm
          containsPaymentMethod
          initialBillingInfo={{
            address: billingRecord?.address,
            company: billingRecord?.company || {},
          }}
          onChange={(values, isValid) => setBillingInfoForm({ values, isValid })}
          onChangePaymentMethod={onChangePaymentMethod}
          {...{ billingRecord }}
        />
      </div>
    </CheckoutContainer>
  ) : isStep('payment') && stepContext?.clientSecret ? (
    <PaymentContext options={{ clientSecret: stepContext.clientSecret }}>
      <CheckoutContainer
        billingInfo={checkoutBillingInfo}
        invoiceId={stepContext?.invoiceId}
        button={(disabled) => (
          <PaySubscriptionButton
            disabled={disabled || !isValidForSubmitPaymentMethod}
            onSuccess={exitAfterPlanChange}
            {...{ plan }}
          />
        )}
        {...{ plan, billingPeriod, billingRecord, isStep }}>
        <div className='flex-col mr-10'>
          <PaymentMethodForm
            issueActive
            className='mb-8'
            onStatusChange={setIsValidForSubmitPaymentMethod}
          />
        </div>
      </CheckoutContainer>
    </PaymentContext>
  ) : null;
};
