import { FormikErrors } from 'formik';

import { mapErrorToTranslationKeys } from '../../../services/error-handling/utils';
import { ApiValidationError } from '../../../services/error-handling/errors/ApiValidationError';
import { handleErrorSilently } from '../../../services/error-handling';

import { FormActions } from '..';

export type VoidAsyncFormFunction<Values> = (
  values: Values,
  formActions: FormActions<Values>
) => void | Promise<any>;

interface CreateSubmitHandlerProps<Values> {
  tryAction: VoidAsyncFormFunction<Values>;
  onSuccess?: (
    result: any,
    values: Values,
    formActions: FormActions<Values>
  ) => void | Promise<any>;
  onError?: (err: any, formActions: FormActions<Values>) => void;
  resetFormOnSubmit?: boolean;
}

export const createFormSubmitHandler = <Values = any>({
  tryAction,
  onSuccess,
  onError,
  resetFormOnSubmit = true,
}: CreateSubmitHandlerProps<Values>): VoidAsyncFormFunction<Values> => {
  return async function submitHandler(values, formActions) {
    try {
      formActions.setStatus(undefined);
      const trimValues = Object.entries(values).reduce(
        (result, [key, value]) =>
          typeof value === 'string'
            ? { ...result, [key]: value.trim() }
            : { ...result, [key]: value },
        {} as Values
      );

      const result = await tryAction(trimValues, formActions);

      if (resetFormOnSubmit) formActions.resetForm();
      if (onSuccess) onSuccess(result, values, formActions);
    } catch (err) {
      handleErrorSilently(err);

      if (onError) onError(err, formActions);

      const formFieldErrors =
        ApiValidationError.contains(err) &&
        (ApiValidationError.getErrors(err) as FormikErrors<any>);

      if (formFieldErrors) return formActions.setErrors(formFieldErrors);

      const errorMessages = mapErrorToTranslationKeys(err);

      formActions.setStatus({
        state: 'error',
        messageKeys: errorMessages,
      });
    }
  };
};
