import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { MyProjectPermission } from '@iteration1/permission-validators';

import { useTranslationContext } from '../../services/translation';
import { allClasses } from '../../services/utilities/array';
import { useCurrentUserContext } from '../services/current-user';
import { usePaymentRedirectConfirmationContext } from '../services/billing/usePaymentRedirectConfirmation';
import { usePermissionsContext } from '../services/authorization';
import { useProjectsCount } from '../services/api-iteration1';

import { MainLayout, withAuth, ProfileSettings, DeleteAccountModal } from '../components';
import { PasswordSettings } from '../components/views/settings/PasswordSettings';
import { BillingSettings } from '../components/views/settings/BillingSettings';
import { CollaboratorsSettings } from '../components/views/settings/CollaboratorsSettings';
import { Button, Navlink, useToggle } from '../../components';

import {
  CollaboratorsIcon,
  DeleteIcon,
  NotificationsIconOn,
  PasswordIconOn,
  PlanBillingIcon,
  ProfileIconOn,
} from '../assets/icons';
import { routes } from './routing/routes';

export const Profile = withAuth(() => {
  const { t } = useTranslationContext.useContext();
  const { pathname } = useLocation();
  const { currentUser } = useCurrentUserContext.useContext();
  const { checkPermission } = usePermissionsContext.useContext();

  const { data: projectsCount } = useProjectsCount();

  const {
    isOpen: isVisibleDeleteAccountModal,
    open: openDeleteAccountModal,
    close: closeDeleteAccountModal,
  } = useToggle();

  const tabs = useMemo(
    () => [
      {
        name: t('pages.account.tabs.profile.name'),
        route: routes.profile,
        description: t('pages.account.tabs.profile.description'),
        hasAccess: true,
        content: () =>
          !!currentUser && (
            <ProfileSettings
              icon={<ProfileIconOn />}
              title={t('pages.account.tabs.profile.name')}
            />
          ),
        icon: <ProfileIconOn />,
      },
      {
        name: t('pages.account.tabs.password.name'),
        route: routes.password,
        description: t('pages.account.tabs.password.description'),
        hasAccess: !currentUser?.usedSocialAuth,
        content: () =>
          !!currentUser && (
            <PasswordSettings
              icon={<PasswordIconOn />}
              title={t('pages.account.tabs.password.name')}
            />
          ),
        icon: <PasswordIconOn />,
      },
      {
        name: t('pages.account.tabs.notifications.name'),
        description: t('pages.account.tabs.notifications.description'),
        hasAccess: false,
        content: () => !!currentUser && t('pages.account.tabs.notifications.name'),
        icon: <NotificationsIconOn />,
      },
      {
        name: t('pages.account.tabs.plan-billing.name'),
        route: routes.billing,
        description: t('pages.account.tabs.plan-billing.description'),
        hasAccess: true,
        content: () =>
          !!currentUser && (
            <BillingSettings
              icon={<PlanBillingIcon />}
              title={t('pages.account.tabs.plan-billing.name')}
            />
          ),
        icon: <PlanBillingIcon />,
      },
      {
        name: t('pages.account.tabs.collaborators.name'),
        route: routes.collaborators,
        description: t('pages.account.tabs.collaborators.description'),
        hasAccess: true,
        content: () =>
          !!currentUser && (
            <CollaboratorsSettings
              icon={<CollaboratorsIcon className='icon-circle-main-contrast icon-main-contrast' />}
              title={t('pages.account.tabs.collaborators.name')}
            />
          ),
        icon: <CollaboratorsIcon className='icon-circle-main-contrast icon-main-contrast' />,
      },
    ],
    [currentUser, t]
  );

  const currentTab = tabs.find((tab) => tab.route === pathname);

  const allowCreateProject = checkPermission(MyProjectPermission.create, {
    count: projectsCount || 0,
  });

  return (
    <usePaymentRedirectConfirmationContext.ProvideContext>
      <MainLayout
        analyticsName='Profile'
        title={t('pages.account.title', { tab: currentTab?.name || '' })}
        isPlanLimitReached={!allowCreateProject}
        availableOnMobile={false}>
        <div className='flex flex-col h-full mt-28'>
          <div className='mx-auto w-full flex flex-row xl:pl-2'>
            <div className='max-w-22 w-1/2 min-w-min mr-5 pr-2'>
              <ul key='profile-tabs'>
                {tabs.map(
                  (tab) =>
                    tab.hasAccess &&
                    tab.route && (
                      <div
                        key={tab.name}
                        className={allClasses(
                          'w-full mb-5 cursor-pointer bg-main border-2 border-solid border-main-10 rounded-lg hover:border-main-highlight',
                          tab.name === currentTab?.name && 'border-main-highlight'
                        )}>
                        <Navlink to={tab.route} className='flex flex-row p-5'>
                          <span className='w-5 h-5'>{tab.icon}</span>
                          <div className='ml-5'>
                            <p className='text-base text-main-contrast leading-5 font-bold mb-2'>
                              {tab.name}
                            </p>
                            <p className='text-base text-neutral-70 leading-5 m-0'>
                              {tab.description}
                            </p>
                          </div>
                        </Navlink>
                      </div>
                    )
                )}
                <Button
                  className='flex flex-row items-center py-2.5 px-5 pr-10 mt-5 text-danger-60 hover:enabled:text-danger-20'
                  onClick={() => openDeleteAccountModal()}>
                  <DeleteIcon className='icon-danger icon-hover-danger-20 mr-5' />
                  <span className='text-base leading-5 font-medium'>
                    {t('pages.account.tabs.profile.buttons.delete-account')}
                  </span>
                </Button>
              </ul>
            </div>
            <>{currentTab?.content()}</>
          </div>
        </div>
        {isVisibleDeleteAccountModal && (
          <DeleteAccountModal isOpen close={closeDeleteAccountModal} />
        )}
      </MainLayout>
    </usePaymentRedirectConfirmationContext.ProvideContext>
  );
});
