import { useState } from 'react';

import { useTranslationContext } from '../../../services/translation';
import { getPromoCode } from '../../services/api-iteration1/billing';
import { PromoCodeOutput } from '../../services/api-iteration1/billing/api-models';
import { ApiServerError } from '../../../services/error-handling/errors/ApiServerError';

import { Button, Icon, useToggle } from '../../../components';

import { DeleteIcon } from '../../assets/icons';
import { Price } from '../formatters';

interface PromoCodeButtonProps {
  isDiscountable: boolean;
  billedAmount: number | undefined;
  promoCode: PromoCodeOutput | undefined;
  setPromoCode: ((promoCode: PromoCodeOutput | undefined) => void) | undefined;
  value: number;
  isLoading: boolean;
}
export const PromoCodeButton = ({
  isDiscountable,
  billedAmount = 0,
  promoCode,
  setPromoCode,
  value,
  isLoading,
}: PromoCodeButtonProps) => {
  const { t } = useTranslationContext.useContext();

  const { isOpen, toggle } = useToggle(!!promoCode?.id);

  const [{ promoCodeName, errorMessage }, setPromo] = useState<{
    promoCodeName: string;
    errorMessage: string;
  }>({ promoCodeName: '', errorMessage: '' });

  const addCoupon = async () => {
    if (!promoCodeName) return;
    try {
      const promoCode = await getPromoCode(promoCodeName, billedAmount);

      setPromoCode?.(promoCode);
    } catch (err) {
      const errorMessage = ApiServerError.contains(err) ? ApiServerError.getError(err) : 'error';
      setPromo({ promoCodeName: '', errorMessage });
    }
  };

  const removeCoupon = () => {
    setPromo({ promoCodeName: '', errorMessage: '' });
    setPromoCode?.(undefined);
  };

  if (!isDiscountable) return null;

  return value ? (
    <div className='flex text-billing mt-5'>
      <div className='flex flex-1'>
        <span className='font-medium mr-3'>{promoCodeName}</span>
        {!!promoCode && <Icon Svg={DeleteIcon} className='icon-billing' onClick={removeCoupon} />}
      </div>
      <Price value={-value} precision={2} isLoading={isLoading} />
    </div>
  ) : !!setPromoCode ? (
    <div className='mt-5'>
      <span
        className='font-medium text-base text-billing-20 border-b border-b-billing-20 cursor-pointer'
        onClick={toggle}>
        {t('pages.account.tabs.plan-billing.order-summary.coupon.title')}
      </span>
      {isOpen && (
        <div>
          <div className='flex h-10 mt-5'>
            <input
              className='input-main flex-1'
              placeholder={t('pages.account.tabs.plan-billing.order-summary.coupon.placeholder')}
              value={promoCodeName}
              onKeyDown={(e) => {
                if (e.key === 'Enter') return addCoupon();
              }}
              onChange={(e) => {
                setPromo({ promoCodeName: e.target.value, errorMessage: '' });
              }}
              disabled={!!promoCode}
            />
            <Button
              className='button__base bg-billing hover:enabled:bg-billing-20 text-white flex-1 justify-center ml-2'
              disabledClassName='bg-billing-20'
              disabled={!promoCodeName}
              onClick={addCoupon}>
              {t('pages.account.tabs.plan-billing.order-summary.coupon.button')}
            </Button>
          </div>
          <p className='text-base text-danger mt-2'>{t(errorMessage)}</p>
        </div>
      )}
    </div>
  ) : null;
};
