import { useState } from 'react';

import { createContextService } from '../../../services/context';

import { PromoCodeOutput } from '../api-iteration1/billing/api-models';

const usePromoCode = () => {
  const [promoCode, setPromoCode] = useState<PromoCodeOutput>();

  return { promoCode, setPromoCode };
};

export const usePromoCodeContext = createContextService(usePromoCode);
