import { MutableRefObject, useEffect, useRef, useState } from 'react';

import { debounce } from '../utilities/debounce';

export interface ElementSize {
  borderBoxSize: ReadonlyArray<ResizeObserverSize>;
  contentBoxSize: ReadonlyArray<ResizeObserverSize>;
  contentRect: DOMRectReadOnly;
}

/**
 * Uses the ResizeObserver API to observe changes within the given HTML Element DOM Rect.
 * @param elementRef
 * @param debounceTimeout
 * @returns {undefined}
 */
export const useElementResize = <T extends HTMLElement>(
  elementRef: MutableRefObject<T | null>,
  debounceTimeout = 100
): ElementSize | undefined => {
  const observerRef = useRef<ResizeObserver>();
  const [dimensions, setDimensions] = useState<ElementSize>();

  // creates the observer reference on mount
  useEffect(() => {
    const fn = debounce<ResizeObserverCallback>((entries) => {
      const element = entries[0];
      if (!element) return;

      setDimensions({
        borderBoxSize: element.borderBoxSize || [
          //  Safari only
          {
            inlineSize: (element.target as HTMLElement).offsetWidth,
            blockSize: (element.target as HTMLElement).offsetHeight,
          },
        ],
        contentBoxSize: element.contentBoxSize || [
          //  Safari only
          {
            inlineSize: (element.target as HTMLElement).clientWidth,
            blockSize: (element.target as HTMLElement).clientHeight,
          },
        ],
        contentRect: element.contentRect,
      });
    }, debounceTimeout);

    observerRef.current = new ResizeObserver(fn);

    return () => {
      fn.cancel();
      if (observerRef.current) observerRef.current.disconnect();
    };
  }, [debounceTimeout]);

  useEffect(() => {
    if (elementRef.current) {
      observerRef.current?.observe(elementRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef.current]);

  return dimensions;
};
