import { DiscoveryVersionResponseOutput } from '../../discovery';
import { UserTestVersionResponseOutput } from '../../userTest/responses';

export const getValidResponses = <
  T extends DiscoveryVersionResponseOutput | UserTestVersionResponseOutput
>(
  responses: undefined | T[]
) => {
  const validResponses =
    responses?.filter((item) => Object.keys(item.answers || {}).length > 0) || [];

  const invalidResponsesCount = responses?.length ? responses.length - validResponses.length : 0;

  return { validResponses, invalidResponsesCount };
};
