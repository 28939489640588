export interface Coordinates {
  x: number;
  y: number;
}

export interface CoordinatesInsideContainer extends Coordinates {
  containerWidth: number;
  containerHeight: number;
}

export const isCloseClick = (point: Coordinates, click: Coordinates, pxRadius: number) => {
  const closeOnXAxis = point.x - pxRadius <= click.x && click.x <= point.x + pxRadius;

  const closeOnYAxis = point.y - pxRadius <= click.y && click.y <= point.y + pxRadius;

  return closeOnXAxis && closeOnYAxis;
};

export const getHotspotClickKey = (click: Coordinates) => {
  return `x-${click.x};y-${click.y}`;
};

export const getCoordinatesOfHotspotKey = (key: string) => {
  const [x, y] = key.split(';').map((string) => Number(string.replace('x-', '').replace('y-', '')));

  return { x, y };
};

export const getClicksGroups = (clicks: Coordinates[], pxRadius = 20) => {
  return clicks.reduce((result, click) => {
    const newHotspotClick = getHotspotClickKey(click);

    const existingClickHotspots = Object.keys(result);

    for (const clickKey of existingClickHotspots) {
      const hotspot = getCoordinatesOfHotspotKey(clickKey);

      if (isCloseClick(hotspot, click, pxRadius)) {
        result[clickKey].push(click);
        return result;
      }
    }

    return { ...result, [newHotspotClick]: [click] };
  }, {} as Record<string, Coordinates[]>);
};

export const getClickPosition = <T>(
  parentElId: string,
  e: React.MouseEvent<T, MouseEvent>
): CoordinatesInsideContainer | undefined => {
  const parent = document.getElementById(parentElId);

  if (!parent) return;

  const parentBounds = parent.getBoundingClientRect();

  const borderPx = getComputedStyle(parent).borderWidth;

  const borderAsNumber = Number(borderPx.replace('px', ''));

  return {
    x: e.clientX - parentBounds.left - borderAsNumber,
    y: e.clientY - parentBounds.top - borderAsNumber,
    containerWidth: parent.clientWidth,
    containerHeight: parent.clientHeight,
  };
};
