import { Fragment, useLayoutEffect, useRef, useState } from 'react';

import { useLocation } from '../../services/router';
import { allClasses } from '../../services/utilities/array';
import { useWindowSize } from '../../services/window-size';

import { Navlink, useToggle } from '..';
import { OutsideModalClick } from '../modal';
import { BreadcrumbItem } from './BreadcrumbItem';
import { BreadCrumbListItem, groupBreadcrumbs } from './utils';

interface BreadCrumbProps {
  items: BreadCrumbListItem[];
}

export const Breadcrumbs = ({ items }: BreadCrumbProps) => {
  const { pathname } = useLocation();
  const { width: windowWidth } = useWindowSize();

  const lastItemRef = useRef<HTMLAnchorElement>(null);
  const { isOpen: visibleHiddenBreadcrumbs, toggle } = useToggle();
  const [collapsedItemsCount, setCollapsedItemsCount] = useState<number>(0);

  const breadcrumbs = groupBreadcrumbs(items, collapsedItemsCount);

  const visibleBreadcrumbsCount = breadcrumbs.displayed.filter(
    (breadcrumb) => breadcrumb.label !== '...'
  ).length;

  const isLastShownItem = (index: number) => {
    return index === breadcrumbs.displayed.length - 1;
  };

  useLayoutEffect(() => {
    setCollapsedItemsCount(0);
  }, [pathname]);

  useLayoutEffect(() => {
    if (!lastItemRef.current) return setCollapsedItemsCount(0);

    const overflow = lastItemRef.current.scrollWidth > lastItemRef.current.clientWidth;

    const canBeCollapsedMore = visibleBreadcrumbsCount > 1;
    if (overflow && canBeCollapsedMore) return setCollapsedItemsCount((prev) => prev + 1);
  }, [visibleBreadcrumbsCount, windowWidth]);

  return (
    <div className='relative flex w-full items-center justify-between min-w-0'>
      {breadcrumbs.hidden && (
        <>
          <OutsideModalClick isVisibleModal={visibleHiddenBreadcrumbs} onClick={toggle} />
          <div
            className={allClasses(
              !visibleHiddenBreadcrumbs && 'hidden',
              'absolute top-5 left-5 max-w-22 z-50 mt-7 border-2 border-main-10 border-solid rounded-lg bg-main text-center duration-150 ease-in-out '
            )}>
            <div
              className='p-1 rounded-md bg-main flex flex-col items-center justify-center'
              role='menu'
              aria-orientation='vertical'
              aria-labelledby='hidden-breadcrumbs'>
              {visibleHiddenBreadcrumbs &&
                breadcrumbs.hidden.map((item: BreadCrumbListItem) => (
                  <Navlink
                    key={item.label}
                    className='truncate w-full px-5 py-2.5 text-base text-main-contrast capitalize font-medium leading-5 rounded hover:bg-main-90 transition duration-150 ease-in-out'
                    to={item.url}>
                    {item.label}
                  </Navlink>
                ))}
            </div>
          </div>
        </>
      )}
      <ol className='flex w-full p-4 rounded items-center'>
        {breadcrumbs.displayed.map((breadcrumb, index) => {
          return (
            <Fragment key={breadcrumb.url}>
              <BreadcrumbItem
                ref={isLastShownItem(index) ? lastItemRef : undefined}
                breadcrumb={breadcrumb}
                onToggle={toggle}
                className={
                  isLastShownItem(index)
                    ? 'truncate'
                    : breadcrumb.label === '...' && visibleHiddenBreadcrumbs
                    ? 'bg-main-90 rounded-lg'
                    : undefined
                }
                disabled={isLastShownItem(index)}
              />
              {!isLastShownItem(index) && (
                <span className='font-bold text-neutral text-xl leading-5 px-5'>/</span>
              )}
            </Fragment>
          );
        })}
      </ol>
    </div>
  );
};
