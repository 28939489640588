import { useMemo, useState } from 'react';

import { useTranslationContext } from '../../../../../services/translation';
import { isRequired } from '../../../../services/validation';
import { allClasses } from '../../../../../services/utilities/array';
import { useErrorHandlerContext } from '../../../../../services/error-handling';
import { useSectionsListContext } from '../../../../services/sections/useSectionsList';
import {
  DiscoverySectionInput,
  DiscoverySectionOpenWordChoiceOutput,
  DiscoverySectionType,
  SectionFileRemovalDetails,
  SectionFileUploadDetails,
  isTemporarySection,
} from '../../../../services/api-iteration1';
import { useActionInProgressContext } from '../../../../../services/progress';
import {
  getStoragePublicFileUrl,
  imageFileSizeLimit,
} from '../../../../services/api-cloud-storage';
import { StorageFileType } from '../../../../services/api-cloud-storage/api-models';

import { Form, FormError, FormField, ToggleField, UploadImage } from '../../../../../components';

import { OpenWordChoiceIcon } from '../../../../assets/icons';
import { SelectSectionItem } from '../../../containers';

import styles from './Section.module.css';

interface SectionFormValues {
  title: string;
  required?: boolean;
  description: string;
  hasImage: boolean;
}

interface SectionOpenWordChoiceProps {
  section: DiscoverySectionOpenWordChoiceOutput;
  index: number;
  isInvalid: boolean;
  editSection: (
    sectionId: string,
    updates: DiscoverySectionInput,
    shouldUpdate?: boolean
  ) => Promise<void> | undefined;
  uploadSectionFile: (sectionId: string, fileDetails: SectionFileUploadDetails) => Promise<void>;
  removeSectionFile: (sectionId: string, fileDetails: SectionFileRemovalDetails) => Promise<void>;
  removeSection: (sectionId: string) => Promise<void> | undefined;
  duplicateSection: ((sectionId: string) => Promise<void> | undefined) | (() => void);
}

export const SectionOpenWordChoice = ({
  section,
  isInvalid,
  index,
  editSection,
  uploadSectionFile,
  removeSectionFile,
  removeSection,
  duplicateSection,
}: SectionOpenWordChoiceProps) => {
  const { t } = useTranslationContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();
  const { reflectUploadingStatus } = useActionInProgressContext.useContext();
  const {
    inFocusSectionId,
    setSectionInFocus,
    resetSectionInFocus: collapseSection,
  } = useSectionsListContext.useContext();

  const { title, _id, files, others, required } = section;
  const { description } = others;

  const sectionImage = files.find((f) => f.type === StorageFileType.SectionImage);
  const imageUrl = sectionImage && getStoragePublicFileUrl(sectionImage.id);

  const [hasImage, setHasImage] = useState(!!sectionImage);

  const isLoading = isTemporarySection(_id);
  const isExpanded = inFocusSectionId === _id && !isLoading;
  const expandSection = () => setSectionInFocus(_id);

  const updateSectionValues = (values: SectionFormValues) => {
    if (!_id) return;

    const shouldRemoveSectionImage = !values.hasImage && sectionImage;
    if (shouldRemoveSectionImage)
      removeSectionFile(_id, { id: sectionImage.id, type: StorageFileType.SectionImage }).catch(
        handleError
      );

    const updates = {
      title: values.title,
      others: {
        description: values.description,
        type: 'stars',
      },
      required: values.required,
      ...(shouldRemoveSectionImage ? { files: files.filter((f) => f.id !== sectionImage.id) } : {}),
    };

    editSection(_id, updates, !isTemporarySection(_id));
  };

  const initialValues = useMemo(
    () => ({
      title: title,
      description: description || '',
      required: !!required,
      hasImage: !!sectionImage || hasImage,
    }),
    [description, hasImage, sectionImage, required, title]
  );

  return (
    <SelectSectionItem
      alreadyAdded
      draggableItemId={_id}
      title={title || t('components.projects.sections.section-title-placeholder')}
      description={t('components.projects.sections.field-types.openWordChoice')}
      icon={<OpenWordChoiceIcon className='icon__small' />}
      onClick={isExpanded ? collapseSection : expandSection}
      type={DiscoverySectionType.OpenWordChoice}
      onDelete={(e) => {
        e.stopPropagation();
        removeSection(_id);
      }}
      onDuplicate={(e) => {
        e.stopPropagation();
        duplicateSection?.(_id);
      }}
      showRequiredIcon={required}
      {...{ isExpanded, isInvalid, isLoading, index }}>
      <>
        <hr className={allClasses(styles.section_hr, 'w-11/12')} />
        <Form
          validateOnMount
          enableReinitialize
          initialValues={initialValues}
          onChange={updateSectionValues}
          className='px-5'>
          {({ status, values, errors, touched }) => (
            <>
              <FormError {...{ status }} />
              <FormField
                autoFocus
                type='text'
                name='title'
                label={`${t('components.projects.sections.open-word-choice.edit.title.label')}*`}
                placeholder={t(
                  'components.projects.sections.open-word-choice.edit.title.placeholder'
                )}
                validate={isRequired}
                fieldClassName='mb-5'
                inputClassName={allClasses(
                  styles.section_input,
                  errors.title && touched.title && styles.section_input__invalid
                )}
                labelClassName={styles.section_label}
              />
              <FormField
                type='text'
                name='description'
                label={t('components.projects.sections.open-word-choice.edit.description.label')}
                placeholder={t(
                  'components.projects.sections.open-word-choice.edit.description.placeholder'
                )}
                fieldClassName='mb-5'
                inputClassName={styles.section_input}
                labelClassName={styles.section_label}
              />
              <hr className={styles.section_hr} />
              <div className={allClasses(styles.section_toggle_wrapper, 'mb-5 py-2.5')}>
                <span>{t('components.projects.sections.required-section')}</span>
                <FormField
                  component={ToggleField}
                  name='required'
                  sliderClassName={styles.section_toggle_slider_wrapper}
                  circleClassName={styles.section_toggle_slider_circle}
                />
              </div>
              <div className={allClasses(styles.section_toggle_wrapper, 'mb-5')}>
                <span>{t('components.projects.sections.image')}</span>
                <FormField
                  component={ToggleField}
                  name='hasImage'
                  sliderClassName={styles.section_toggle_slider_wrapper}
                  circleClassName={styles.section_toggle_slider_circle}
                  onChange={() => setHasImage(!values.hasImage)}
                />
              </div>
              {values.hasImage && (
                <UploadImage
                  excludeFromForm
                  fileSizeLimit={imageFileSizeLimit}
                  title={t('components.upload.image.title')}
                  subtitle={t('components.upload.image.subtitle')}
                  placeholderImgSrc={imageUrl}
                  upload={(image) =>
                    uploadSectionFile(_id, { file: image, type: StorageFileType.SectionImage })
                  }
                  onUploadError={handleError}
                  remove={async () => {
                    if (!sectionImage) return;
                    await removeSectionFile(_id, {
                      id: sectionImage.id,
                      type: StorageFileType.SectionImage,
                    });
                  }}
                  onRemoveSuccess={() => setHasImage(true)}
                  onRemoveError={handleError}
                  onLoadingStatusChange={reflectUploadingStatus}
                  className='mb-4'
                  fileName={sectionImage?.fileName}
                />
              )}
            </>
          )}
        </Form>
      </>
    </SelectSectionItem>
  );
};
