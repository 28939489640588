import {
  BillingPeriod,
  BillingUpdateInput,
  PaymentPlanOutput,
} from '../../services/api-iteration1/billing/api-models';
import { usePreviewSubscriptionChange } from '../../services/api-iteration1/billing';
import { usePromoCodeContext } from '../../services/billing/usePromoCode';

import { PaymentSummaryCard } from './PaymentSummaryCard';

interface SubscriptionPaymentSummaryCardProps {
  plan: PaymentPlanOutput;
  billingPeriod: BillingPeriod;
  billingInfo: BillingUpdateInput | null;
  button: (disabled: boolean) => React.ReactNode;
}

export const SubscriptionPaymentSummaryCard = ({
  plan,
  billingPeriod,
  billingInfo,
  button,
}: SubscriptionPaymentSummaryCardProps) => {
  const { promoCode, setPromoCode } = usePromoCodeContext.useContext();

  const planPrice = plan.price.find((p) => p.recurring?.interval === billingPeriod);

  const waitForPreview = !billingInfo;

  const { data: previewInvoice, isValidating } = usePreviewSubscriptionChange(planPrice?.id, {
    waitForFetch: waitForPreview,
    fetchOnlyParams: billingInfo
      ? {
          line1: billingInfo.address.streetLine1,
          city: billingInfo.address.city,
          state: billingInfo.address.state,
          postal_code: billingInfo.address.postalCode,
          country: billingInfo.address.country,
          coupon: promoCode?.coupon.id,
        }
      : {},
  });

  if (!planPrice?.value || !planPrice?.recurring) return null;

  return (
    <PaymentSummaryCard
      invoice={previewInvoice}
      isLoading={isValidating}
      waitForData={waitForPreview}
      {...{
        plan,
        planPrice,
        billingPeriod,
        promoCode,
        setPromoCode,
        button,
      }}
    />
  );
};
