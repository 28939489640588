import { isUndefined } from '../../../services/utilities/value';

import {
  DefaultSectionType,
  DefaultSectionThankYouOutput,
  DefaultSectionWelcomeOutput,
  DiscoverySection,
  UsabilitySection,
  Path,
  Frame,
} from '../api-iteration1';

import { UserTestProjectSection } from '../api-iteration1/projects/userTest/sections/api-models';

export const getSortedSections = (sections: UserTestProjectSection[] | undefined) => {
  if (!sections)
    return { sortableSections: [], welcomeSection: undefined, thankYouSection: undefined };

  const thankYouSection = sections.find(
    (section: UserTestProjectSection) => section.type === DefaultSectionType.ThankYou
  ) as DefaultSectionThankYouOutput | undefined;

  const welcomeSection = sections.find(
    (section: UserTestProjectSection) => section.type === DefaultSectionType.Welcome
  ) as DefaultSectionWelcomeOutput | undefined;

  const sortableSections = sections.filter((section) => !isUndefined(section.index)) as Required<
    DiscoverySection | UsabilitySection
  >[];

  const sortedSections = sortableSections.sort((s1, s2) => s1.index - s2.index);

  return { sortableSections: sortedSections, welcomeSection, thankYouSection };
};

export const getPathFirstFrame = (path: Path): Frame | undefined =>
  path.frames.length ? path.frames[0] : undefined;
