import { allClasses } from '../../../../../../services/utilities/array';
import { DiscoveryRatingScaleAnswer } from '../../../../../services/api-iteration1';

import { renderNumericalRatingScaleLabels } from '../../summaries/utilities';

interface AnalyticsNumericalAnswerProps {
  answer?: DiscoveryRatingScaleAnswer;
  stepsCount?: number;
  startingStep?: number;
  labels?: {
    left: string;
    middle: string;
    right: string;
  };
}

const AnalyticsNumericalAnswer = ({
  answer,
  stepsCount,
  startingStep,
  labels,
}: AnalyticsNumericalAnswerProps) => {
  if (!stepsCount) return null;

  return (
    <>
      {[...Array(stepsCount)].map((_, index) => {
        const value = startingStep === 0 ? index : index + 1;
        const isActive = answer?.answer === value;

        return (
          <div className='w-full' key={value}>
            <div
              className={allClasses(
                'h-[4.5rem] relative flex flex-1 flex-col justify-center items-center border-2 border-solid rounded-lg',
                index === stepsCount - 1 ? 'mr-0' : 'mr-5',
                isActive ? 'border-main-highlight' : 'border-main-10'
              )}>
              {isActive && <div className='absolute inset-0 bg-main-highlight opacity-10 z-0' />}
              <span className='flex flex-col items-center justify-center text-xl text-main-contrast font-bold leading-30'>
                {value}
              </span>
            </div>
            {renderNumericalRatingScaleLabels(value, startingStep, stepsCount, labels)}
          </div>
        );
      })}
    </>
  );
};

export default AnalyticsNumericalAnswer;
