interface PaymentErrorCardProps {
  errorMessage: string;
}

export const PaymentErrorCard = ({ errorMessage }: PaymentErrorCardProps) => {
  return (
    <div
      id='error-message'
      className='text-danger-60 font-bold py-2 mt-4 text-xs bg-danger-10 rounded-md'>
      <p className='mx-2'>{errorMessage}</p>
    </div>
  );
};
