// eslint-disable-next-line import/no-named-as-default
import produce from 'immer';

import { handleErrorSilently } from '../error-handling';
import { FirebaseAuthError, FirebaseAuthUser } from './firebase';

export enum Actions {
  Success,
  Fail,
  Clear,
  ResetError,
}

type AuthAction =
  | {
      type: Actions.Success;
      user: FirebaseAuthUser;
    }
  | {
      type: Actions.Fail;
      error?: FirebaseAuthError | unknown;
    }
  | {
      type: Actions.Clear;
    }
  | {
      type: Actions.ResetError;
    };

export interface AuthResult {
  account: FirebaseAuthUser | null | undefined;
  isLoading: boolean;
  error: FirebaseAuthError | unknown | undefined;
}

export type AuthReducer = (draft: AuthResult, action: AuthAction) => AuthResult;

export const authReducer: AuthReducer = produce((draft: AuthResult, action: AuthAction) => {
  switch (action.type) {
    case Actions.Success:
      draft.account = action.user;
      draft.isLoading = false;
      draft.error = undefined;
      break;
    case Actions.Fail:
      draft.account = null;
      draft.isLoading = false;
      draft.error = action.error;
      handleErrorSilently(action.error);
      break;
    case Actions.Clear:
      draft.isLoading = false;
      draft.account = undefined;
      break;
    case Actions.ResetError:
      draft.error = undefined;
      break;
  }
});
