import React, { ReactNode, useState } from 'react';

import { allClasses } from '../../services/utilities/array';

interface TooltipProps {
  id?: string;
  direction?: 'top' | 'bottom' | 'left' | 'right';
  className?: string;
  containerClassName?: string;
  content: string | React.ReactNode;
  delay?: number;
  children?: ReactNode;
}

export const Tooltip = ({
  direction,
  content,
  delay,
  children,
  className,
  containerClassName,
}: TooltipProps) => {
  let timeout: any;
  const [active, setActive] = useState<boolean>(false);

  const showContent = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 300);
  };

  const hideContent = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className={allClasses('inline-block cursor-pointer', containerClassName || 'relative')}
      onMouseEnter={showContent}
      onMouseLeave={hideContent}>
      {children}
      {active && (
        <div
          className={allClasses(
            'absolute left-1/2 z-10 py-1.5 px-5 text-main bg-main-contrast text-xs leading-none rounded-lg whitespace-nowrap text-center transform -translate-x-1/2 shadow-sm drop-shadow-xl',
            className,
            direction
              ? {
                  top: 'top-[-1.875rem]',
                  bottom: 'bottom-[-1.875rem]',
                  left: 'right-[calc(100%+0.625rem)] left-auto top-1/2 translate-x-0 -translate-y-1/2',
                  right: 'left-[calc(100%+0.625rem)] top-1/2 translate-x-0 -translate-y-1/2',
                }[direction]
              : ''
          )}>
          {content}
        </div>
      )}
    </div>
  );
};
