import { Label } from '../../../sections/view/ViewRatingScale/Label';

export const renderNumericalRatingScaleLabels = (
  index: number,
  startingStep: number | undefined,
  stepsCount: number | undefined,
  labels:
    | {
        left?: string;
        middle?: string;
        right?: string;
      }
    | undefined
) => {
  if (!stepsCount || !labels) return;

  const oddNumberOfSteps = stepsCount % 2 !== 0;

  return (
    <>
      {labels.left && [startingStep === 0 ? 0 : 1].includes(index) && (
        <Label text={labels.left} alignment='left' />
      )}
      {labels.right && (startingStep === 0 ? index + 1 === stepsCount : index === stepsCount) && (
        <Label text={labels.right} alignment='right' />
      )}
      {oddNumberOfSteps
        ? [stepsCount === 5 ? 3 : 5].includes(index) &&
          labels.middle && <Label text={labels.middle} alignment='center' />
        : [stepsCount === 6 ? 2 : 5].includes(index) &&
          labels.middle && (
            <span className='relative left-1/2'>
              <Label text={labels.middle} alignment='center' />
            </span>
          )}
    </>
  );
};

export const renderRatingScaleLabels = (
  index: number,
  labels:
    | {
        left?: string;
        middle?: string;
        right?: string;
      }
    | undefined
) => {
  return (
    <>
      {index === 0
        ? labels?.left && <Label text={labels.left} alignment='left' />
        : index === 2
        ? labels?.middle && <Label text={labels.middle} alignment='center' />
        : index === 4
        ? labels?.right && <Label text={labels.right} alignment='right' />
        : null}
    </>
  );
};
