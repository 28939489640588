import { useTranslationContext } from '../../../services/translation';

import { Iteration1FullLogo, MobileNotAvailableIllustration } from '../../assets/icons';

export const AppNotAvailable = () => {
  const { t } = useTranslationContext.useContext();

  return (
    <div className='flex flex-col items-center justify-start w-screen max-h-screen overflow-hidden'>
      <Iteration1FullLogo className='mt-10' />
      <div className='flex flex-col items-center mt-20'>
        <MobileNotAvailableIllustration className='mb-10' />
        <p className='text-base text-main-contrast leading-5 font-bold mb-2.5'>
          {t('mobile-not-supported.title')}
        </p>
        <p className='text-sm text-main-contrast leading-[1.125rem] text-center max-w-xs'>
          {t('mobile-not-supported.description')}
        </p>
      </div>
    </div>
  );
};
