import {
  DiscoveryPreferenceAnswer,
  DiscoverySectionPreferenceOutput,
} from '../../../../services/api-iteration1';
import { allClasses } from '../../../../../services/utilities/array';
import { getKeyForCondition } from '../../../../../services/utilities/object';
import { getStoragePublicFileUrl } from '../../../../services/api-cloud-storage';

import { Button, useToggle } from '../../../../../components';

import { AnalyticsSectionAnswerEmptyState } from '../../../empty-states';
import { MaximizeIcon } from '../../../../assets/icons';
import { PreferenceTestImageModal } from '../../../modals/PreferenceTestImageModal';

interface AnalyticsPreferenceTestAnswerProps {
  answer?: DiscoveryPreferenceAnswer;
  preferenceSection: DiscoverySectionPreferenceOutput;
}

export const AnalyticsPreferenceTestAnswer = ({
  answer,
  preferenceSection,
}: AnalyticsPreferenceTestAnswerProps) => {
  const {
    files,
    others: { texts, type },
  } = preferenceSection;
  const {
    isOpen: isPreferenceTestImageModalVisible,
    open: openPreferenceTestImageModal,
    close: closePreferenceTestImageModal,
    context: preferenceTestImageContext,
  } = useToggle<{ image: { id: string; index: number } }>();

  const openImageModal = (image: string, index: number) => {
    openPreferenceTestImageModal({
      image: {
        id: image,
        index: index + 1,
      },
    });
  };

  return answer ? (
    <div className='flex flex-col w-full items-center'>
      <>
        {type === 'text' ? (
          <div
            className={allClasses(
              'w-full mt-10 grid gap-5',
              getKeyForCondition({
                'grid-cols-3': texts?.length === 3,
                'grid-cols-2': (texts?.length as number) % 2 === 0,
              })
            )}>
            {texts?.map(({ context }, index) => (
              <div
                key={`${context}-${index}`}
                className={allClasses(
                  'w-full relative p-5 rounded-lg border-2 border-solid text-xl leading-30 text-main-contrast font-bold cursor-pointer',
                  answer.choice === index ? 'border-main-highlight' : 'border-main-10'
                )}>
                {answer.choice === index && (
                  <div className='absolute inset-0 bg-main-highlight opacity-10 z-0' />
                )}
                {context}
              </div>
            ))}
          </div>
        ) : (
          <div className={allClasses('w-full mt-10 grid gap-5 grid-cols-2')}>
            {files?.map((item, index) => (
              <div key={item.id} className={allClasses('rounded-lg relative')}>
                <img
                  src={getStoragePublicFileUrl(files[index].id)}
                  className={allClasses(
                    'border-2 border-solid rounded-lg h-80 object-cover w-full',
                    answer.choice === index
                      ? 'border-main-highlight shadow-default'
                      : 'border-main-10'
                  )}
                />

                <Button
                  className='absolute bottom-5 right-5 bg-main-contrast-10 cursor-pointer rounded-xl p-2'
                  onClick={(e) => {
                    e.stopPropagation();
                    openImageModal(files[index].id, index);
                  }}>
                  <MaximizeIcon className='icon__small icon-main' />
                </Button>
              </div>
            ))}
          </div>
        )}
      </>
      {isPreferenceTestImageModalVisible && (
        <PreferenceTestImageModal
          isOpen
          closeModal={closePreferenceTestImageModal}
          image={preferenceTestImageContext?.image}
        />
      )}
    </div>
  ) : (
    <AnalyticsSectionAnswerEmptyState />
  );
};
