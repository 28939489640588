import { allClasses } from '../../../../../services/utilities/array';
import { getKeyForCondition } from '../../../../../services/utilities/object';
import { VersionStatus } from '../../../../services/api-iteration1/projects';

import { DateFromToday } from '../../../formatters/DateFromToday';

interface VersionCardFooterProps {
  date: Date;
  status: VersionStatus;
  responsesNumber: number;
}

export const VersionCardFooter = ({ date, status, responsesNumber }: VersionCardFooterProps) => {
  const responsesColor = getKeyForCondition({
    success: responsesNumber >= 5,
    warning: responsesNumber >= 3 && responsesNumber < 5,
    danger: responsesNumber < 3,
  });

  return (
    <div className='flex justify-between items-center'>
      <span className='text-neutral-70 text-base font-medium leading-5'>
        <DateFromToday {...{ date }} />
      </span>
      {status !== VersionStatus.Draft && (
        <div className='flex items-center text-neutral-70'>
          <p>Responses:</p>
          <div className='flex ml-2 w-7 h-7 justify-center items-center'>
            <p
              className={allClasses(
                'w-5 h-5 flex justify-center rounded-full leading-5',
                `bg-${responsesColor}-10`,
                responsesColor === 'warning' ? 'text-warning-60' : `text-${responsesColor}`
              )}>
              {responsesNumber}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
