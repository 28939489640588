import { apiIteration1, useIteration1Resource } from '../../config';
import { endpoints } from './endpoints';
import {
  DiscoveryOutput,
  DiscoveryUpdate,
  DiscoveryVersionUpdate,
  NewDiscoveryInput,
} from './api-models';

export * from './api-models';
export * from './versions';
export * from './sections';
export * from './responses';

export const createNewDiscovery = async (newDiscovery: NewDiscoveryInput) =>
  apiIteration1.post(endpoints.v1.discoveries, newDiscovery);

export const useDiscovery = (discoveryId: string) => {
  return useIteration1Resource<DiscoveryOutput>(endpoints.v1.discovery(discoveryId));
};

export const updateDiscovery = async (discoveryId: string, updates: DiscoveryUpdate) => {
  return apiIteration1.patch(endpoints.v1.discovery(discoveryId), updates);
};

export const renameDiscovery = async (
  discoveryId: string,
  versionId: string,
  payload: DiscoveryVersionUpdate
) => {
  return apiIteration1.patch(endpoints.v1.discoveryVersion(discoveryId, versionId), payload);
};

export const deleteDiscovery = async (discoveryId: string) => {
  return apiIteration1.destroy(endpoints.v1.discovery(discoveryId));
};

export const endDiscovery = async (discoveryId: string, versionId: string) => {
  return apiIteration1.patch(endpoints.v1.endDiscovery(discoveryId, versionId));
};
