import { useTranslationContext } from '../../../services/translation';
import { allClasses } from '../../../services/utilities/array';
import { useCopyToClipboard } from '../../services/copy-to-clipboard/useCopyToClipboard';

import { Button } from '../../../components';
import { Tooltip } from '../../../components/tooltip/Tooltip';

import { CheckIconOn, IdeaIcon, InviteTestersIcon, LiveTestIllustration } from '../../assets/icons';
import { EmbedTestButton } from '../buttons';

interface ProjectVersionResultPlaceholderProps {
  testPath: string;
  testUrl: string;
  projectId: string;
  projectName?: string;
}

export const ProjectVersionResultPlaceholder = ({
  testPath,
  testUrl,
  projectId,
  projectName,
}: ProjectVersionResultPlaceholderProps) => {
  const { t } = useTranslationContext.useContext();
  const { copied, copyToClipboard } = useCopyToClipboard();

  return (
    <div className='flex w-full h-full flex-col items-center justify-center text-center'>
      <div className='mb-10'>
        <LiveTestIllustration />
      </div>
      <p className='text-xl text-main-contrast font-bold mb-3'>
        {t('components.projects.discovery.results.placeholder.title')}
      </p>
      <p className='text-lg text-main-contrast leading-6'>
        {t('components.projects.discovery.results.placeholder.subtitle')}
      </p>
      <p className='text-lg text-main-contrast leading-6'>
        {t('components.projects.discovery.results.placeholder.description')}
      </p>
      <p className='flex flex-row mt-6 py-2.5 px-5 bg-info-10 text-info rounded-lg'>
        <IdeaIcon />
        <span className='pl-2.5'>
          {t('components.projects.discovery.results.placeholder.info-block')}
        </span>
      </p>
      <Tooltip
        content={
          <div className='flex flex-row items-center justify-between'>
            <span className='w-2.5 h-2.5 relative inline-block mr-2.5 border-default border-main-highlight border-solid rounded-sm'>
              <span className='w-2.5 h-2.5 inline-block border-default border-main-highlight border-solid rounded-sm absolute right-1 bottom-1'></span>
              <span className='w-2 h-2 absolute top-0 left-0 bg-main-contrast'></span>
            </span>
            {t('components.projects.project-actions.invite-testers-tooltip')}
          </div>
        }
        containerClassName='flex items-center mt-10'
        direction='bottom'>
        <EmbedTestButton
          className='mr-2 px-[1.25rem]'
          testUrl={testUrl}
          testId={projectId}
          testName={projectName}
        />
        <Button
          className={allClasses('button__filled px-[1.25rem]')}
          onClick={() => copyToClipboard(window.location.origin + testPath)}>
          {copied ? (
            <>
              <span className='text-main-contrast leading-5 text-base font-medium pl-5'>
                {t('components.projects.navbar.button.copied')}
              </span>
              <span className='pl-5 inline-block'>
                <CheckIconOn />
              </span>
            </>
          ) : (
            <>
              <InviteTestersIcon />
              <span className='text-main-contrast leading-5 text-base font-medium pl-5'>
                {t('components.projects.project-actions.invite-testers')}
              </span>
            </>
          )}
        </Button>
      </Tooltip>
    </div>
  );
};
