import { FieldAttributes, useField } from 'formik';

import { DropdownSearch } from '../../..';
import { useFormContext } from '../../useForm';

interface Item {
  value: string;
  label: string;
}

interface DropdownSearchFieldProps extends FieldAttributes<any> {
  id: string;
  placeholder: string;
  items: Item[];
  itemClassName: string;
}

export const DropdownSearchField = ({
  items,
  suffix,
  suffixClassName,
  ...otherProps
}: DropdownSearchFieldProps) => {
  const [field, , form] = useField(otherProps.field.name);
  const { values, submitChange } = useFormContext.useContext();

  const selectItem = (item: Item) => {
    form.setValue(item.value, true);
    submitChange({ ...(values as Record<string, unknown>), [otherProps.field.name]: item.value });
  };

  return (
    <DropdownSearch
      {...field}
      {...otherProps}
      selectItem={selectItem}
      onChange={(e) => {
        field.onChange(e);
        otherProps.onChange && otherProps.onChange(e);
      }}
      onBlur={(e) => {
        field.onBlur(e);
        otherProps.onBlur && otherProps.onBlur(e);
      }}
      {...{ items, suffix, suffixClassName }}
    />
  );
};
