import { useState } from 'react';

import { allClasses } from '../../services/utilities/array';

type SVG = React.SVGProps<SVGSVGElement> & { title?: string };

interface IconProps extends SVG {
  Svg: React.FC<SVG>;
  HoverSvg?: React.FC<SVG>;
  className?: string;
}

export const Icon = ({ Svg, HoverSvg, className, ...otherProps }: IconProps) => {
  const [hover, setHover] = useState<boolean>(false);

  const iconClassName = allClasses('cursor-pointer', className);

  return HoverSvg && hover ? (
    <HoverSvg
      className={iconClassName}
      title=''
      {...otherProps}
      onMouseLeave={() => setHover(false)}
    />
  ) : (
    <Svg
      className={iconClassName}
      onMouseEnter={HoverSvg ? () => setHover(true) : undefined}
      title=''
      {...otherProps}
    />
  );
};
