import { useAlertsContext } from '../../../services/alerts';
import { getKeyForCondition } from '../../../services/utilities/object';
import { allClasses } from '../../../services/utilities/array';

import { AlertErrorIcon, CloseIcon, InfoIcon } from '../../assets/icons';

export const Alerts = () => {
  const { alerts, removeErrorAlert, removeWarningAlert, removeSuccessAlert } =
    useAlertsContext.useContext();
  return (
    <div className='fixed z-60 top-16 md:top-auto md:bottom-16 right-0 w-full block sm:w-1/2 lg:w-1/3'>
      {alerts.map((alert) => (
        <div
          key={alert.timestamp}
          className={allClasses(
            'flex flex-row items-start max-w-[380px] w-full fixed bottom-10 p-5 bg-main-contrast rounded-lg right-2 sm:right-10',
            getKeyForCondition({
              'bg-danger border-danger-10': alert.type === 'error',
              'bg-warning border-warning-10': alert.type === 'warning',
              'bg-main-contrast': alert.type === 'success',
              'bg-neutral-30 border-neutral-70': true,
            })
          )}>
          <CloseIcon
            className={allClasses(
              'absolute top-2 right-2 cursor-pointer icon-hover-main',
              getKeyForCondition({
                'icon-neutral-40': alert.type === 'success',
                'icon-warning-60': alert.type === 'warning',
                'icon-danger-30': true,
              })
            )}
            onClick={(e) => {
              e.stopPropagation();
              if (alert.type === 'error') removeErrorAlert(alert.timestamp);
              if (alert.type === 'warning') removeWarningAlert(alert.timestamp);
              if (alert.type === 'success') removeSuccessAlert(alert.timestamp);
            }}
            data-testid='notification-close'
          />
          {alert.type === 'success' ? (
            <InfoIcon className='icon__small icon-main-highlight icon-polyline-main-highlight border border-main-highlight rounded-full' />
          ) : (
            <AlertErrorIcon className='icon-main icon-circle-main icon-polyline-main' />
          )}
          <div className='flex flex-col pl-2.5'>
            <div
              className={allClasses(
                'mb-2.5 text-lg leading-6 font-normal',
                getKeyForCondition({
                  'text-main-highlight': alert.type === 'success',
                  'text-main': true,
                })
              )}>
              {getKeyForCondition({
                [String(alert.title)]: alert.type === 'success',
                Error: true,
              })}
            </div>
            <div
              className={allClasses(
                'max-w-[18rem] text-base leading-5',
                getKeyForCondition({
                  'text-neutral-40': alert.type === 'success',
                  'text-warning-60': alert.type === 'warning',
                  'text-danger-30': true,
                })
              )}>
              {alert.message}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
