import { useTranslationContext } from '../../../services/translation';
import { FolderChildType } from '../../services/api-iteration1';
import { allClasses } from '../../../services/utilities/array';

import { Button } from '../../../components';

import { NewProjectCard } from '../cards/NewProjectCard';
import { Tag } from '../tags/Tag';
import { DiscoveryProjectIllustration, UsabilityProjectIllustration } from '../../assets/icons';

import styles from './Modals.module.css';

interface ProjectTypeModalStep1Props {
  onSelectFlow: (type: FolderChildType.Discovery | FolderChildType.UserTest) => void;
}

export const ProjectTypeModalStep1 = ({ onSelectFlow }: ProjectTypeModalStep1Props) => {
  const { t } = useTranslationContext.useContext();

  const discoveryTags = (
    <div className='my-11 min-h-10'>
      <p className='mb-4 text-neutral-70 text-base font-medium leading-5'>
        {t('components.projects.project-type-modal.great-for')}
      </p>
      <Tag
        name={t('components.projects.project-type-modal.tags.idea-validation')}
        className='text-sm bg-main-10 rounded-lg text-neutral-80 px-2.5 py-2'
        id='discovery-tag-idea-validation'
      />
      <Tag
        name={t('components.projects.project-type-modal.tags.feedback-survey')}
        className='text-sm bg-main-10 rounded-lg text-neutral-80 px-2.5 py-2'
        id='discovery-tag-feedback-survey'
      />
      <Tag
        name={t('components.projects.project-type-modal.tags.satisfaction-survey')}
        className='text-sm bg-main-10 rounded-lg text-neutral-80 px-2.5 py-2'
        id='discovery-tag-satisfaction-survey'
      />
      <Tag
        name={t('components.projects.project-type-modal.tags.concept-validation')}
        className='text-sm bg-main-10 rounded-lg text-neutral-80 px-2.5 py-2'
        id='discovery-tag-concept-validation'
      />
      <Tag
        name={t('components.projects.project-type-modal.tags.content-testing')}
        className='text-sm bg-main-10 rounded-lg text-neutral-80 px-2.5 py-2'
        id='discovery-tag-content-testing'
      />
    </div>
  );

  const userTestTags = (
    <div className='my-11 min-h-10'>
      <p className='mb-4 text-neutral-70 text-base font-medium leading-5'>
        {t('components.projects.project-type-modal.great-for')}
      </p>
      <Tag
        name={t('components.projects.project-type-modal.tags.qualitative-usability-testing')}
        className='text-sm bg-main-10 rounded-lg text-neutral-80 px-2.5 py-2'
        id='discovery-tag-lorem-ipsum'
      />
      <Tag
        name={t('components.projects.project-type-modal.tags.quantitative-usability-testing')}
        className='text-sm bg-main-10 rounded-lg text-neutral-80 px-2.5 py-2'
        id='discovery-tag-dolor-sit'
      />
      <Tag
        name={t('components.projects.project-type-modal.tags.remote-testing')}
        className='text-sm bg-main-10 rounded-lg text-neutral-80 px-2.5 py-2'
        id='discovery-tag-amet-consectetur'
      />
      <Tag
        name={t('components.projects.project-type-modal.tags.in-person-testing')}
        className='text-sm bg-main-10 rounded-lg text-neutral-80 px-2.5 py-2'
        id='discovery-tag-adipiscing'
      />
    </div>
  );

  return (
    <div className='flex flex-col flex-1 justify-center text-center mx-auto container-with-margin'>
      <h2 className={allClasses(styles.title, 'mb-10')}>
        {t('components.projects.project-type-modal.title')}
      </h2>
      <div className='flex flex-col justify-center mx-2 md:flex-row md:mx-10'>
        <NewProjectCard
          thumbnail={<DiscoveryProjectIllustration />}
          title={t('components.projects.title.discovery')}
          content={
            <div>
              <p className={styles.project_item_description}>
                {t('components.projects.project-type-modal.descriptions.discovery')}
              </p>
              {discoveryTags}
            </div>
          }
          learnMoreUrl='/'
          submitButton={
            <div>
              <Button
                className={allClasses('button__ghost', styles.project_item_submit_btn)}
                type='submit'
                onClick={() => onSelectFlow(FolderChildType.Discovery)}>
                <span>{t('components.projects.project-type-modal.create-discovery')}</span>
              </Button>
            </div>
          }
        />
        <NewProjectCard
          thumbnail={<UsabilityProjectIllustration />}
          title={t('components.projects.title.usabilityTesting')}
          content={
            <div>
              <p className={styles.project_item_description}>
                {t('components.projects.project-type-modal.descriptions.user-test')}
              </p>
              {userTestTags}
            </div>
          }
          learnMoreUrl='/'
          submitButton={
            <div>
              <Button
                className={allClasses('button__ghost', styles.project_item_submit_btn)}
                type='submit'
                onClick={() => onSelectFlow(FolderChildType.UserTest)}>
                <span>{t('components.projects.project-type-modal.create-usability-testing')}</span>
              </Button>
            </div>
          }
          isLast
        />
      </div>
    </div>
  );
};
