export * from './inputs';
export * from './sections';

type Validator = (value: any) => string | undefined;

export const validate = (value: any, validators: Validator[]) => {
  for (let i = 0; i < validators.length; i++) {
    const validator = validators[i];
    const validationResult = validator(value);

    if (validationResult) return validationResult;
  }

  return undefined;
};
