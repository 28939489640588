import React from 'react';

import { useTranslationContext } from '../../../services/translation';
import {
  deleteUserTest,
  FolderChildOutput,
  FolderOutput,
  updateUserTest,
} from '../../services/api-iteration1';
import { useErrorHandlerContext } from '../../../services/error-handling';
import { FetchMutator } from '../../../services/api/resource-fetch-factory';

import { Card, useToggle } from '../../../components';
import { ProjectCardActions } from './components/actions/ProjectCardActions';
import { ProjectCardBody } from './components/body/ProjectCardBody';
import { ProjectCardFooter } from './components/footer/ProjectCardFooter';
import { ProjectCardHeader } from './components/header/ProjectCardHeader';
import styles from '../../../components/card/Card.module.css';

import { dynamicRoutes } from '../../pages/routing/routes';
import { UserTestIcon } from '../../assets/icons';

interface UserTestCardProps {
  folderChild: FolderChildOutput;
  revalidate: () => void;
  revalidateProjectsCount?: () => void;
  mutate?: FetchMutator<FolderOutput>;
  mutateProjectsCount?: FetchMutator<number>;
}

export const UserTestCard = ({
  folderChild,
  revalidate,
  revalidateProjectsCount,
  mutate,
  mutateProjectsCount,
}: UserTestCardProps) => {
  const { t } = useTranslationContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();
  const { isOpen: isMenuOpen, toggle: toggleOpenMenu } = useToggle();

  const removeUserTest = async () => {
    if (!folderChild._id) return;

    let mutationPrevData: FolderOutput | undefined;
    mutate?.(
      (prev) => {
        if (!prev) return;

        mutationPrevData = prev.data;

        const remainingChildren = mutationPrevData.children.filter(
          (child) => child._id !== folderChild._id
        );

        return { data: { ...mutationPrevData, children: remainingChildren } };
      },
      { revalidate: false }
    );
    mutateProjectsCount?.((prev) => (prev ? { ...prev, data: prev.data - 1 } : prev));

    try {
      await deleteUserTest(folderChild._id);
      if (!mutationPrevData) {
        revalidate();
        revalidateProjectsCount?.();
      }
    } catch (err) {
      handleError(err);
      if (mutationPrevData) {
        mutate?.({ data: mutationPrevData });
        mutateProjectsCount?.((prev) => (prev ? { ...prev, data: prev.data + 1 } : prev));
      }
    }
  };

  const renameCurrentUserTest = async (name: string) => {
    if (!folderChild._id) return;

    let mutationPrevData: FolderOutput | undefined;
    mutate?.(
      (prev) => {
        if (!prev) return;

        mutationPrevData = prev.data;

        const updatedChildren = mutationPrevData.children.map((child) =>
          child._id !== folderChild._id ? child : { ...child, name }
        );

        return { ...prev, data: { ...mutationPrevData, children: updatedChildren } };
      },
      { revalidate: false }
    );

    try {
      await updateUserTest(folderChild._id, { name });
      if (!mutationPrevData) {
        revalidate();
        revalidateProjectsCount?.();
      }
    } catch (err) {
      handleError(err);
      if (mutationPrevData) mutate?.({ data: mutationPrevData });
    }
  };

  return (
    <Card
      header={
        <div className='flex flex-row justify-between items-center relative'>
          <ProjectCardHeader
            icon={<UserTestIcon />}
            title={t('components.projects.title.usabilityTesting')}
          />
          <ProjectCardActions
            className={styles.card_actions}
            onClick={toggleOpenMenu}
            isMenuOpen={isMenuOpen}
            onRemove={removeUserTest}
            onRename={renameCurrentUserTest}
            projectType='userTest'
          />
        </div>
      }
      body={<ProjectCardBody title={folderChild.name} />}
      footer={
        <ProjectCardFooter
          date={folderChild.updatedAt}
          projectDetails={{
            type: folderChild.type,
            id: folderChild._id,
            collaboratorsCount: folderChild.collaborators.length,
          }}
          revalidateResource={revalidate}
        />
      }
      urlTo={dynamicRoutes.userTest(folderChild._id)}
    />
  );
};
