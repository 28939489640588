import { MutableRefObject, useMemo } from 'react';

import { useTranslationContext } from '../../../../../../services/translation';
import {
  QuestionAnswer,
  UsabilitySectionSingleGoal,
  UsabilitySingleGoalAnswer,
} from '../../../../../services/api-iteration1';
import { useShowInstructionsContext } from '../../../../../services/userTest';
import { allClasses } from '../../../../../../services/utilities/array';
import { UserTestFeedback } from '../../../../../services/api-iteration1/projects/userTest/responses';
import { DesignPrototypeVersion } from '../../../../../services/api-iteration1/design-platforms';
import { usePreloadResources } from '../../../../../../services/preload';

import { SectionCongratulations } from '../SectionCongratulations';
import { SectionInstructions } from '../SectionInstructions';
import { ViewSingleGoalPrototypeTest } from './ViewSingleGoalPrototypeTest';
import { ViewSingleGoalPrototypeEdit } from './ViewSingleGoalPrototypeEdit';
import { getCrossOriginAnonymousImageSource } from '../../../live-prototype/utils';

interface ViewSingleGoalProps {
  designPrototype: DesignPrototypeVersion;
  singleGoalSection: UsabilitySectionSingleGoal;
  currentSectionScreenIndex?: number;
  sectionViewContainerRef: MutableRefObject<HTMLDivElement | null>;
  onIntermediateAnswer?: (
    sectionId: string,
    answer: Omit<UsabilitySingleGoalAnswer, 'duration'>
  ) => void;
  onSaveAnswerImmediately?: (
    sectionId: string,
    answer: Omit<QuestionAnswer, 'duration'>,
    video: Blob | undefined
  ) => Promise<void>;
  onFinishAnswerScreen?: (status?: { restart?: boolean }) => void;
  onSendFeedback?: (feedback: Omit<UserTestFeedback, 'sectionId'>) => void;
}

export const ViewSingleGoal = ({
  designPrototype,
  singleGoalSection,
  currentSectionScreenIndex,
  sectionViewContainerRef,
  onIntermediateAnswer,
  onSaveAnswerImmediately,
  onFinishAnswerScreen,
  onSendFeedback,
}: ViewSingleGoalProps) => {
  const { t } = useTranslationContext.useContext();

  const { isVisibleInstructionsAgain } = useShowInstructionsContext.useContext();

  const { _id, others, designPlatform } = singleGoalSection;
  const { title, description, paths } = others;

  const framesImagesPreloads = useMemo(() => {
    const links = designPrototype.frames.map((frame) =>
      frame?.image
        ? {
            as: 'image',
            rel: 'preload',
            crossOrigin: 'anonymous',
            href: getCrossOriginAnonymousImageSource(frame.image.id),
          }
        : undefined
    );

    return links.filter(Boolean) as NonNullable<typeof links[number]>[];
  }, [designPrototype.frames]);

  usePreloadResources(framesImagesPreloads);

  const sectionInstructions = (
    <SectionInstructions
      id={'single-goal-instructions-view'}
      label={t(`components.projects.sections.single-goal.instructions.title`)}
      info={{
        messages: [
          t('components.projects.sections.instructions.info.hidden-info.message1'),
          t('components.projects.sections.instructions.info.hidden-info.message2'),
        ],
      }}
      {...{ title, description }}
    />
  );

  const sendFeedbackMultipleSimilarClicks = (feedback: Omit<UserTestFeedback, 'sectionId'>) => {
    onSendFeedback?.(feedback);
  };

  return (
    <div className='relative flex flex-1 justify-center w-full'>
      {currentSectionScreenIndex === 0 ? (
        sectionInstructions
      ) : currentSectionScreenIndex === 1 || isVisibleInstructionsAgain ? (
        <>
          <div className={isVisibleInstructionsAgain ? 'hidden' : undefined}>
            <ViewSingleGoalPrototypeTest
              sectionId={_id}
              type={designPlatform}
              onSendFeedbackMultipleClicks={sendFeedbackMultipleSimilarClicks}
              {...{
                paths,
                currentSectionScreenIndex,
                onIntermediateAnswer,
                onSaveAnswerImmediately,
                onFinishAnswerScreen,
                designPrototype,
                sectionViewContainerRef,
              }}
            />
          </div>
          <div className={allClasses('w-full h-full', !isVisibleInstructionsAgain && 'hidden')}>
            {sectionInstructions}
          </div>
        </>
      ) : currentSectionScreenIndex === 2 ? (
        <SectionCongratulations
          title={t('components.projects.sections.congratulations.title')}
          description={t('components.projects.sections.congratulations.completed-goal')}
        />
      ) : (
        <ViewSingleGoalPrototypeEdit type={designPlatform} {...{ designPrototype }} />
      )}
    </div>
  );
};
