import { useCallback } from 'react';

import { useToggle } from '../../components';

import { createContextService } from '../context';
import { useUnmovedMouse } from '../position/useUnmovedMouse';

interface TestPauseModalService {
  isPaused: boolean;
  closePauseModal: () => void;
  openPauseModal: () => void;
  isUnmovedMouse: boolean;
}

const empty = {
  isPaused: false,
  closePauseModal: () => ({}),
  openPauseModal: () => ({}),
  isUnmovedMouse: false,
};

const useTestPauseModal = ({ disabled = false }: { disabled?: boolean }): TestPauseModalService => {
  const { isUnmoved, resetUnmoved } = useUnmovedMouse({ interval: 500, timeLimit: 15000 });
  const {
    isOpen: isPausedTestModalVisible,
    open: openPausedTestModal,
    close: closePausedTestModal,
  } = useToggle();

  const closePauseModal = useCallback(() => {
    closePausedTestModal();
    resetUnmoved();
  }, [closePausedTestModal, resetUnmoved]);

  return disabled
    ? empty
    : {
        isPaused: isPausedTestModalVisible,
        closePauseModal,
        openPauseModal: openPausedTestModal,
        isUnmovedMouse: isUnmoved,
      };
};

export const useTestPauseModalContext = createContextService(useTestPauseModal);
