import { FetchOptions } from '../../../../../../services/api/resource-fetch-factory';
import { createQueryUrl } from '../../../../../../services/api/utils';

import { apiIteration1, usePublicIteration1Resource } from '../../../config';
import {
  NewSectionInput,
  SectionFileRemovalDetails,
  SectionFileUploadDetails,
  SectionStorageFile,
  VersionStatus,
} from '../../api-models';
import { DiscoverySectionInput } from '../../api-models/sections/discovery';
import { DiscoveryProjectSection } from './api-models';
import { endpoints } from './endpoints';

export { endpoints };
export * from './api-models';

export const addDiscoverySection = (
  discoveryId: string,
  versionId: string,
  payload: NewSectionInput
) =>
  apiIteration1.post<DiscoveryProjectSection>(
    endpoints.v1.sections(discoveryId, versionId),
    payload
  );

export const updateDiscoverySection = (
  discoveryId: string,
  versionId: string,
  sectionId: string,
  payload: DiscoverySectionInput
) => apiIteration1.patch(endpoints.v1.section(discoveryId, versionId, sectionId), payload);

export const sortDiscoverySections = (
  discoveryId: string,
  versionId: string,
  orderedSectionIds: string[]
) =>
  apiIteration1.put(endpoints.v1.sort_sections(discoveryId, versionId), {
    order: orderedSectionIds,
  });

export const deleteDiscoverySection = (discoveryId: string, versionId: string, sectionId: string) =>
  apiIteration1.destroy(endpoints.v1.section(discoveryId, versionId, sectionId));

export const duplicateDiscoverySection = (
  discoveryId: string,
  versionId: string,
  sectionId: string
) => apiIteration1.post(endpoints.v1.section_duplicate(discoveryId, versionId, sectionId));

export const useDiscoveryVersionSections = (
  discoveryId: string,
  versionId: string,
  options?: FetchOptions
) => {
  return usePublicIteration1Resource<{
    status: VersionStatus;
    sections: DiscoveryProjectSection[];
  }>(endpoints.v1.sections(discoveryId, versionId), options);
};

export const uploadDiscoveryVersionSectionFile = async (details: {
  discoveryId: string;
  versionId: string;
  sectionId: string;
  fileDetails: SectionFileUploadDetails;
}): Promise<SectionStorageFile> => {
  const formData = new FormData();
  formData.append('file', details.fileDetails.file);
  formData.append('type', details.fileDetails.type);
  if (details.fileDetails.indexId) formData.append('indexId', String(details.fileDetails.indexId));

  const result = await apiIteration1.post<SectionStorageFile>(
    endpoints.v1.section_upload_file(details.discoveryId, details.versionId, details.sectionId),
    formData
  );

  return result.data;
};

export const removeDiscoveryVersionSectionFile = (details: {
  discoveryId: string;
  versionId: string;
  sectionId: string;
  fileDetails: SectionFileRemovalDetails;
}) => {
  const { id: fileId, ...queryParams } = details.fileDetails;

  return apiIteration1.destroy(
    createQueryUrl(
      endpoints.v1.section_remove_file(
        details.discoveryId,
        details.versionId,
        details.sectionId,
        fileId
      ),
      { ...queryParams }
    )
  );
};
