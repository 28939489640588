import { FetchMutator } from '../../../services/api/resource-fetch-factory';
import { createContextService } from '../../../services/context';
import { useErrorHandlerContext } from '../../../services/error-handling';
import { useRouter } from '../../../services/router';

import { dynamicRoutes, routes } from '../../pages/routing/routes';
import { deleteFolder, FolderOutput, renameFolder } from '../api-iteration1';

interface FolderService {
  removeCurrentFolder: (folderId: string) => Promise<void> | undefined;
  renameCurrentFolder: (name: string) => Promise<void> | undefined;
}

interface useFolderProps {
  folder: FolderOutput | undefined;
  mutate: FetchMutator<FolderOutput>;
}

export const useCurrentFolder = ({ folder, mutate }: useFolderProps): FolderService => {
  const router = useRouter();
  const { handleError } = useErrorHandlerContext.useContext();

  const folderId = folder?.id;

  const removeCurrentFolder = async () => {
    if (!folderId) return;

    try {
      const directParent = folder?.parents[folder.parents.length - 1];

      await deleteFolder(folderId);

      const redirectRoute = directParent ? dynamicRoutes.folder(directParent._id) : routes.projects;
      router.replace(redirectRoute);
    } catch (err) {
      handleError(err);
    }
  };

  const renameCurrentFolder = async (name: string) => {
    if (!folderId) return;

    let prevData: FolderOutput | undefined;
    mutate(
      (prev) => {
        if (!prev) return;

        prevData = prev.data;

        return { data: { ...prevData, name } };
      },
      { revalidate: false }
    );

    try {
      await renameFolder(folderId, { name });
    } catch (err) {
      handleError(err);
      if (prevData) mutate({ data: prevData });
    }
  };

  return {
    removeCurrentFolder,
    renameCurrentFolder,
  };
};

export const useCurrentFolderContext = createContextService(useCurrentFolder);
