import html2canvas from 'html2canvas';

import { hiddenMouseEffectClass } from '../../../domain/components/views/sections/view/ViewSingleGoal/useMouseClickVisualEffect';

export class CanvasDrawer {
  public isDrawingHtmlElWithId: string;
  public recordingCanvas: HTMLCanvasElement;
  public canvasContext: CanvasRenderingContext2D;

  constructor(
    recordingCanvasId: string,
    canvasDimensions: { width: number; height: number },
    private onError?: (err: any) => void
  ) {
    this.isDrawingHtmlElWithId = '';
    const recordingCanvas = document.getElementById(recordingCanvasId) as HTMLCanvasElement | null;
    const canvasContext = recordingCanvas?.getContext('2d', { willReadFrequently: true });

    if (!recordingCanvas || !canvasContext) throw Error('Missing recording canvas context');

    //The following code is to set the width and height of the canvas according to the screen resolution to obtain high-definition pictures
    recordingCanvas.width = canvasDimensions.width;
    recordingCanvas.height = canvasDimensions.height;

    this.recordingCanvas = recordingCanvas;
    this.canvasContext = canvasContext;
  }

  get isDrawing() {
    return !!this.isDrawingHtmlElWithId;
  }

  public draw = ({ htmlElementToRecordId }: { htmlElementToRecordId: string }) => {
    if (this.isDrawingHtmlElWithId === htmlElementToRecordId) return;

    const elementToRecord = document.getElementById(htmlElementToRecordId);
    if (!elementToRecord) return;

    this.isDrawingHtmlElWithId = htmlElementToRecordId;

    const looper = () => {
      if (this.isDrawingHtmlElWithId !== htmlElementToRecordId) return;

      const currentElementToRecord = document.getElementById(htmlElementToRecordId);

      if (currentElementToRecord) {
        const changedDimensions =
          this.recordingCanvas.width !== currentElementToRecord.clientWidth ||
          this.recordingCanvas.height !== currentElementToRecord.clientHeight;
        if (changedDimensions) {
          this.recordingCanvas.width = currentElementToRecord.clientWidth;
          this.recordingCanvas.height = currentElementToRecord.clientHeight;
        }
      }

      html2canvas(elementToRecord, {
        allowTaint: true,
        useCORS: true,
        logging: false,
        scale: 1, // Disable auto-scale because it is faulty sometimes
        onclone: (doc) => {
          const mouseEffectDisplays = doc.getElementsByClassName(hiddenMouseEffectClass);

          for (let i = 0; i < mouseEffectDisplays.length; i++) {
            const effect = mouseEffectDisplays[i];
            (effect as HTMLDivElement).style.visibility = 'visible';
          }
        },
        // We need to remove container every time so it does not fill up memory
        removeContainer: true,
      })
        .then((canvas: HTMLCanvasElement) => {
          this.canvasContext.clearRect(0, 0, canvas.width, canvas.height);
          // Because images are downloaded frequently to be drawed, we cache them in the storage API bucket
          this.canvasContext.drawImage(canvas, 0, 0, canvas.width, canvas.height);
        })
        .catch((err) => {
          this.onError?.(err);
        })
        .finally(() => {
          requestAnimationFrame(() => looper());
        });
    };

    looper();
  };

  public stopDrawing = () => {
    this.isDrawingHtmlElWithId = '';
  };
}
