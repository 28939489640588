import { allClasses } from '../../../../../services/utilities/array';
import { StorageFile } from '../../../../services/api-cloud-storage/api-models';
import { getStoragePublicFileUrl } from '../../../../services/api-cloud-storage';

import { PlaceholderBigIcon } from '../../../../assets/icons';

interface SectionImageProps {
  image?: StorageFile;
}

export const SectionImage = ({ image }: SectionImageProps) => {
  const imageUrl = image && getStoragePublicFileUrl(image.id);

  return (
    <div
      className={allClasses(
        'w-full flex justify-center mb-10 h-auto sm:h-90',
        imageUrl ? 'bg-transparent' : 'bg-main-10'
      )}>
      {imageUrl ? (
        <img
          key={image?.id}
          src={imageUrl}
          title={image?.name}
          className='h-auto sm:h-full w-full sm:w-auto object-contain'
        />
      ) : (
        <PlaceholderBigIcon className='w-60 h-60' />
      )}
    </div>
  );
};
