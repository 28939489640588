import { Api } from '../../../services/api';
import { createResourceFetcher } from '../../../services/api/resource-fetch-factory';

export const apiIteration1 = new Api({
  baseURL: process.env.REACT_APP_ITERATION1_API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
});

export const useIteration1Resource = createResourceFetcher({
  get: apiIteration1.get,
  isReadyForRequests: apiIteration1.hasActiveAuth,
}).useResource;

export const usePublicIteration1Resource = createResourceFetcher({
  get: apiIteration1.get,
}).useResource;
