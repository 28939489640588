import { useTranslationContext } from '../../../../../services/translation';
import {
  DiscoveryPreferenceAnswer,
  DiscoverySectionPreferenceOutput,
} from '../../../../services/api-iteration1';
import { getStoragePublicFileUrl } from '../../../../services/api-cloud-storage';

import { Button, LoadingOrContent, useToggle } from '../../../../../components';

import { AnalyticsSectionContainer } from '../../../containers';
import { MaximizeIcon, PreferenceTestIcon } from '../../../../assets/icons';
import { AnalyticsSummaryStatistic } from '../../../headers/AnalyticsSummaryStatistic';
import { AnalyticsPercentage } from '../AnalyticsPercentage';
import { AnalyticsPreferenceTestBlock } from './AnalyticsPreferenceTestBlock';
import { PreferenceTestImageModal } from '../../../modals/PreferenceTestImageModal';

interface AnalyticsPreferenceTestSummaryProps {
  answers: DiscoveryPreferenceAnswer[];
  section: DiscoverySectionPreferenceOutput;
  sectionsCount: number;
  isLoadingResponses: boolean;
}

export const AnalyticsPreferenceTestSummary = ({
  answers,
  section,
  sectionsCount,
  isLoadingResponses,
}: AnalyticsPreferenceTestSummaryProps) => {
  const { t } = useTranslationContext.useContext();

  const {
    isOpen: isPreferenceTestImageModalVisible,
    open: openPreferenceTestImageModal,
    close: closePreferenceTestImageModal,
    context: preferenceTestImageContext,
  } = useToggle<{ image: { id: string; index: number } }>();

  const availableTextChoices: string[] | undefined = section.others.texts?.reduce(
    (result, value) => {
      return [...result, value.context];
    },
    [] as string[]
  );

  const {
    files,
    others: { type },
  } = section;

  const getAnswersNumber = (choice: number) => {
    return answers.filter((item) => item.choice === choice).length;
  };

  const calculatePercentage = (answersNumber: number | undefined) => {
    if (!answersNumber || !answers.length) return 0;

    return Math.round((answersNumber / answers.length) * 100);
  };

  const openImageModal = (image: string, index: number) => {
    openPreferenceTestImageModal({
      image: {
        id: image,
        index: index + 1,
      },
    });
  };

  return (
    <AnalyticsSectionContainer
      contentTitle={section.title}
      description={section.others?.description as string}
      icon={<PreferenceTestIcon />}
      rightSideInformation={
        <AnalyticsSummaryStatistic
          currentSectionIndex={section.index}
          answersCount={answers.length || 1}
          {...{ sectionsCount }}
        />
      }
      title={t('components.projects.sections.field-types.preference')}>
      <LoadingOrContent isLoading={isLoadingResponses} className='pb-12'>
        <div className='w-full mt-20 mb-16'>
          {answers.length ? (
            <div className='flex flex-col justify-between max-w-[780px]'>
              {type === 'text'
                ? availableTextChoices?.map((choice: string, index: number) => {
                    const isLast = index === availableTextChoices.length - 1;
                    const answersNumber = getAnswersNumber(index);
                    const percentage = calculatePercentage(answersNumber);

                    return (
                      <AnalyticsPreferenceTestBlock
                        key={`${choice}-${index}`}
                        {...{ isLast, index, choice }}>
                        <div className='w-full h-16 relative bg-main-10 rounded-lg'>
                          <div
                            style={{
                              width: `${percentage}%`,
                            }}
                            className='h-16 absolute left-0 bottom-0 rounded-lg bg-main-highlight'></div>
                        </div>
                        <div className='w-16 ml-10 text-right'>
                          <AnalyticsPercentage
                            answerPercentage={percentage}
                            {...{ answersNumber }}
                          />
                        </div>
                      </AnalyticsPreferenceTestBlock>
                    );
                  })
                : files.map((file, index) => {
                    const isLast = index === files.length - 1;
                    const answersNumber = getAnswersNumber(index);
                    const percentage = calculatePercentage(answersNumber);

                    return (
                      <AnalyticsPreferenceTestBlock key={file.id} {...{ isLast, index }}>
                        <Button
                          className='relative w-[6.65rem] h-16 flex items-center justify-center'
                          onClick={() => {
                            openImageModal(files[index].id, index);
                          }}>
                          <img
                            src={getStoragePublicFileUrl(file.id)}
                            className='h-16 rounded w-full object-cover cursor-pointer'
                          />
                          <MaximizeIcon className='icon__small icon-main absolute bottom-2 right-2' />
                        </Button>
                        <div className='w-[35rem] h-16 relative bg-main-10 rounded-lg'>
                          <div
                            style={{
                              width: `${percentage}%`,
                            }}
                            className='h-16 absolute left-0 bottom-0 rounded-lg bg-main-highlight'></div>
                        </div>
                        <div className='w-16 text-right'>
                          <AnalyticsPercentage
                            answerPercentage={percentage}
                            {...{ answersNumber }}
                          />
                        </div>
                      </AnalyticsPreferenceTestBlock>
                    );
                  })}
            </div>
          ) : (
            <p className='text-center font-medium text-danger'>
              {t('components.projects.analytics.no-answers')}
            </p>
          )}
        </div>
        {isPreferenceTestImageModalVisible && (
          <PreferenceTestImageModal
            isOpen
            closeModal={closePreferenceTestImageModal}
            image={preferenceTestImageContext?.image}
          />
        )}
      </LoadingOrContent>
    </AnalyticsSectionContainer>
  );
};
