import {
  DiscoveryOpenWordAnswer,
  DiscoverySectionOpenWordChoiceOutput,
} from '../../../../services/api-iteration1';
import { useTranslationContext } from '../../../../../services/translation';

import { LoadingOrContent } from '../../../../../components';

import { AnalyticsOpenWordAnswerCard } from '../../../cards';
import { AnalyticsSectionContainer } from '../../../containers';
import { OpenWordChoiceIcon } from '../../../../assets/icons';
import { FormattedDate } from '../../../formatters';
import { AnalyticsSummaryStatistic } from '../../../headers/AnalyticsSummaryStatistic';

interface AnalyticsOpenWordSummaryProps {
  answers: DiscoveryOpenWordAnswer[];
  section: DiscoverySectionOpenWordChoiceOutput;
  sectionsCount: number;
  isLoadingResponses: boolean;
}

export const AnalyticsOpenWordSummary = ({
  answers,
  section,
  sectionsCount,
  isLoadingResponses,
}: AnalyticsOpenWordSummaryProps) => {
  const { t } = useTranslationContext.useContext();

  return (
    <AnalyticsSectionContainer
      contentTitle={section.title}
      description={section.others?.description as string}
      icon={<OpenWordChoiceIcon />}
      rightSideInformation={
        <AnalyticsSummaryStatistic
          currentSectionIndex={section.index}
          answersCount={answers.length || 1}
          {...{ sectionsCount }}
        />
      }
      title={t('components.projects.sections.field-types.openWordChoice')}>
      <LoadingOrContent isLoading={isLoadingResponses} className='pb-12'>
        <div className='w-full mt-20 mb-16'>
          {answers.length ? (
            answers.map((answer, index) => (
              <AnalyticsOpenWordAnswerCard
                shouldDisplayTesterIndex
                key={`${answer.id}-${index}`}
                content={answer.answer}
                index={index + 1}
                date={<FormattedDate seconds={answer.createdAt} precision='PPPPp' />}
                isLast={index === answers.length - 1}
              />
            ))
          ) : (
            <p className='text-center font-medium text-danger'>
              {t('components.projects.analytics.no-answers')}
            </p>
          )}
        </div>
      </LoadingOrContent>
    </AnalyticsSectionContainer>
  );
};
