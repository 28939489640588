import { differenceInMilliseconds } from 'date-fns';

import {
  FolderChildOutput,
  FolderChildType,
  FolderOutput,
} from '../../services/api-iteration1/folders';
import { FetchMutator } from '../../../services/api/resource-fetch-factory';

import { CardList } from '../../../components';

import { DiscoveryCard, FolderCard, UserTestCard } from '../cards';

interface ProjectListProps {
  items: FolderChildOutput[];
  revalidate: () => void;
  revalidateProjectsCount?: () => void;
  mutate?: FetchMutator<FolderOutput>;
  mutateProjectsCount?: FetchMutator<number>;
}

export const ProjectsList = ({
  items,
  revalidate,
  revalidateProjectsCount,
  mutate,
  mutateProjectsCount,
}: ProjectListProps) => {
  const sortItemsByDate = items.sort((item1, item2) => {
    return differenceInMilliseconds(new Date(item2.updatedAt), new Date(item1.updatedAt));
  });

  return (
    <CardList>
      {sortItemsByDate.map((item, index) => {
        switch (item.type) {
          case FolderChildType.Discovery:
            return (
              <DiscoveryCard
                folderChild={item}
                {...{
                  index,
                  mutate,
                  mutateProjectsCount,
                  revalidate,
                  revalidateProjectsCount,
                }}
                key={item._id}
              />
            );
          case FolderChildType.UserTest:
            return (
              <UserTestCard
                folderChild={item}
                {...{
                  index,
                  mutate,
                  mutateProjectsCount,
                  revalidate,
                  revalidateProjectsCount,
                }}
                key={item._id}
              />
            );
          case FolderChildType.Folder:
            return (
              <FolderCard
                folderChild={item}
                {...{
                  index,
                  mutate,
                  mutateProjectsCount,
                  revalidate,
                  revalidateProjectsCount,
                }}
                key={item._id}
              />
            );
          default:
            return null;
        }
      })}
    </CardList>
  );
};
