import { FetchOptions } from '../../../../../../services/api/resource-fetch-factory';
import { DeviceDetails } from '../../../../../../services/device';

import { apiIteration1, useIteration1Resource } from '../../../config';
import { CreateDiscoveryVersionResponseOutput, DiscoveryVersionResponseOutput } from './api-models';
import { endpoints } from './endpoints';

export * from './api-models';

export const useDiscoveryResponses = (
  discoveryId: string,
  versionId: string,
  options?: FetchOptions
) => {
  return useIteration1Resource<DiscoveryVersionResponseOutput[]>(
    endpoints.v1.responses(discoveryId, versionId),
    options
  );
};

export const createNewDiscoveryResponse = async (
  discoveryId: string,
  versionId: string,
  newResponse: {
    userId?: string;
    totalQuestions: number;
    device: DeviceDetails;
  }
): Promise<CreateDiscoveryVersionResponseOutput> => {
  const response = await apiIteration1.post<CreateDiscoveryVersionResponseOutput>(
    endpoints.v1.responses(discoveryId, versionId),
    newResponse
  );

  return response.data;
};

export const addDiscoveryResponseAnswer = async (
  discoveryId: string,
  versionId: string,
  responseId: string,
  newResponse: {
    sectionId?: string;
    answer: unknown;
  }
) => apiIteration1.post(endpoints.v1.response(discoveryId, versionId, responseId), newResponse);

export const giveUpDiscoveryResponseAnswer = async (
  discoveryId: string,
  versionId: string,
  responseId: string,
  reason: string
) =>
  apiIteration1.post(endpoints.v1.giveUpResponse(discoveryId, versionId, responseId), { reason });
