import React, { ReactNode, SVGProps, useEffect } from 'react';

import { useTranslationContext } from '../../../services/translation';
import { allClasses } from '../../../services/utilities/array';
import { useCurrentUserContext } from '../../services/current-user';
import { usePaymentRedirectConfirmationContext } from '../../services/billing/usePaymentRedirectConfirmation';

import { useToggle } from '../../../components';

import { CheckIconOn, CloseIcon, WarningIcon } from '../../assets/icons';
import { PaymentPlanChangedModal } from '../modals';

export const PaymentStatusCard = () => {
  const { t } = useTranslationContext.useContext();
  const { currentUser } = useCurrentUserContext.useContext();
  const { type, planName, clearRedirectionDetails } =
    usePaymentRedirectConfirmationContext.useContext();
  const {
    isOpen: isOpenSuccessModal,
    open: openSuccessModal,
    close: closeSuccessModal,
  } = useToggle();

  const {
    text,
    Icon,
    iconColorClass,
    bgColorClass,
  }: {
    text?: ReactNode;
    Icon?: React.FC<SVGProps<SVGSVGElement>>;
    iconColorClass?: string;
    bgColorClass?: string;
    textColorClass?: string;
  } = (() => {
    switch (type) {
      case 'success_setup_intent':
        return {
          Icon: CheckIconOn,
          iconColorClass: 'icon-success-20',
          bgColorClass: 'bg-success-10',
          text: (
            <div className='text-success'>
              {t('pages.account.info.payment.method-change-success')}
            </div>
          ),
        };
      case 'failure_setup_intent':
        return {
          Icon: CloseIcon,
          iconColorClass: 'icon-danger',
          bgColorClass: 'bg-danger-10',
          text: (
            <div className='text-danger'>
              {t('pages.account.info.payment.method-change-failure')}
            </div>
          ),
        };
      case 'payment_due':
        return {
          Icon: WarningIcon,
          iconColorClass: 'icon-warning',
          bgColorClass: 'bg-warning-20',
          text: (
            <div className='text-warning-10'>
              {t('pages.account.info.payment.payment-due.part1')}
              <span className='font-bold'>
                {' '}
                {t(
                  `pages.account.tabs.plan-billing.plans.${currentUser?.subscription?.plan.name}.title`
                )}{' '}
              </span>
              {t('pages.account.info.payment.payment-due.part2')}
            </div>
          ),
        };
      default:
        return {};
    }
  })();

  const showPaymentStatusCard = !!text && !!Icon;

  useEffect(() => {
    const showPlanChangedModal =
      ['success_setup_intent', 'success_plan_change'].includes(type) && !!planName;

    if (showPlanChangedModal) openSuccessModal();
  }, [openSuccessModal, planName, type]);

  return (
    <>
      {showPaymentStatusCard && (
        <div className={allClasses('flex items-center my-4 py-2 rounded-md w-max', bgColorClass)}>
          <Icon className={allClasses('ml-3 icon__medium', iconColorClass)} />
          <div className='mx-3 text-base'>{text}</div>
        </div>
      )}

      {isOpenSuccessModal && planName && (
        <PaymentPlanChangedModal
          planName={planName}
          onExit={() => {
            closeSuccessModal();
            clearRedirectionDetails();
          }}
        />
      )}
    </>
  );
};
