import { apiIteration1, useIteration1Resource } from '../config';
import { FolderCreateInput, FolderOutput } from './api-models';
import { endpoints } from './endpoints';

export * from './api-models';

export const useDefaultFolder = () => {
  return useIteration1Resource<FolderOutput>(endpoints.v1.defaultFolder);
};

export const useFolder = (folderId: string) => {
  return useIteration1Resource<FolderOutput>(endpoints.v1.folder(folderId));
};

export const createNewFolder = async (payload: FolderCreateInput): Promise<FolderOutput> => {
  const response = await apiIteration1.post<FolderOutput>(endpoints.v1.defaultFolder, payload);
  return response.data;
};

export const deleteFolder = (folderId: string) =>
  apiIteration1.destroy(endpoints.v1.folder(folderId));

export const renameFolder = async (folderId: string, payload: { name: string }) => {
  return apiIteration1.patch(endpoints.v1.folder(folderId), payload);
};
