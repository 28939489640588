import { useRef } from 'react';

import {
  DiscoverySectionType,
  DiscoverySectionMultipleChoiceOutput,
  DiscoverySectionOpenWordChoiceOutput,
  DiscoverySectionRatingScaleOutput,
  DiscoverySectionYesNoOutput,
  DefaultSectionThankYouOutput,
  DefaultSectionWelcomeOutput,
  DefaultSectionType,
  QuestionAnswer,
  DiscoveryYesNoAnswer,
  DiscoveryMultipleChoiceAnswer,
  DiscoveryRatingScaleAnswer,
  DiscoveryOpenWordAnswer,
  UsabilitySectionType,
  UsabilitySectionSingleGoal,
  UsabilitySectionFiveSeconds,
  UsabilitySectionFirstClick,
  DiscoverySectionPreferenceOutput,
  DiscoveryPreferenceAnswer,
  DiscoverySectionLegalOutput,
  DiscoveryLegalAnswer,
} from '../../../../services/api-iteration1';
import { DiscoveryProjectSection } from '../../../../services/api-iteration1/projects/discovery/sections/api-models';
import { UserTestProjectSection } from '../../../../services/api-iteration1/projects/userTest/sections/api-models';
import { DesignPrototypeVersion } from '../../../../services/api-iteration1/design-platforms';
import { allClasses } from '../../../../../services/utilities/array';

import { ViewWelcomeScreen } from './ViewWelcomeScreen';
import { ViewThankYou } from './ViewThankYou';
import { ViewYesNo } from './ViewYesNo';
import { ViewRatingScale } from './ViewRatingScale';
import { ViewMultipleChoice } from './ViewMultipleChoice';
import { ViewOpenWordChoice } from './ViewOpenWordChoice';
import { ViewSingleGoal } from './ViewSingleGoal';
import { ViewFiveSeconds } from './ViewFiveSeconds';
import { ViewFirstClick } from './ViewFirstClick';
import { ViewPreferenceTest } from './ViewPreferenceTest';
import { ViewLegal } from './ViewLegal';

interface SectionViewProps {
  section: DiscoveryProjectSection | UserTestProjectSection | undefined;
  currentSectionScreenIndex?: number;
  answer?: Omit<QuestionAnswer, 'duration'>;
  onStart?: () => void;
  onSetAnswer?: (answer: Omit<QuestionAnswer, 'duration'>) => void;
  onIntermediateAnswer?: (sectionId: string, answer: Omit<QuestionAnswer, 'duration'>) => void;
  onSaveAnswerImmediately?: (
    sectionId: string,
    answer: Omit<QuestionAnswer, 'duration'>,
    video?: Blob
  ) => Promise<void>;
  onFinishAnswerScreen?: (status?: { restart?: boolean }) => void;
  onSendFeedback?: (feedback: any) => void;
  interactive?: boolean;
  designPrototype?: DesignPrototypeVersion;
  automaticSpacing?: boolean;
  isEmbeddedIframe?: boolean;
}

export const SectionView = ({
  section,
  currentSectionScreenIndex,
  answer,
  onStart,
  onSetAnswer,
  onIntermediateAnswer,
  onSaveAnswerImmediately,
  onFinishAnswerScreen,
  onSendFeedback,
  interactive,
  designPrototype,
  automaticSpacing,
  isEmbeddedIframe,
}: SectionViewProps) => {
  const sectionViewContainerRef = useRef(null);

  const renderSection = () => {
    switch (section?.type) {
      case DiscoverySectionType.YesNo:
        return (
          <ViewYesNo
            key={section._id}
            yesNoSection={section as DiscoverySectionYesNoOutput}
            answer={answer as DiscoveryYesNoAnswer}
            {...{ onSetAnswer, interactive }}
          />
        );
      case DiscoverySectionType.MultipleChoice:
        return (
          <ViewMultipleChoice
            key={section._id}
            multipleChoiceSection={section as DiscoverySectionMultipleChoiceOutput}
            answer={answer as DiscoveryMultipleChoiceAnswer}
            {...{ onSetAnswer, interactive }}
          />
        );
      case DiscoverySectionType.RatingScale:
        return (
          <ViewRatingScale
            key={section._id}
            ratingScaleSection={section as DiscoverySectionRatingScaleOutput}
            answer={answer as DiscoveryRatingScaleAnswer}
            {...{ onSetAnswer, interactive }}
          />
        );
      case DiscoverySectionType.OpenWordChoice:
        return (
          <ViewOpenWordChoice
            key={section._id}
            openWordChoiceSection={section as DiscoverySectionOpenWordChoiceOutput}
            answer={answer as DiscoveryOpenWordAnswer}
            {...{ onSetAnswer, interactive }}
          />
        );
      case UsabilitySectionType.SingleGoal:
        return (
          designPrototype && (
            <ViewSingleGoal
              key={section._id}
              singleGoalSection={section as UsabilitySectionSingleGoal}
              sectionViewContainerRef={sectionViewContainerRef}
              {...{
                designPrototype,
                currentSectionScreenIndex,
                onIntermediateAnswer,
                onSaveAnswerImmediately,
                onFinishAnswerScreen,
                onSendFeedback,
              }}
            />
          )
        );
      case UsabilitySectionType.FiveSeconds:
        return (
          <ViewFiveSeconds
            key={section._id}
            fiveSecondsSection={section as UsabilitySectionFiveSeconds}
            {...{
              interactive,
              currentSectionScreenIndex,
              designPrototype,
              onSaveAnswerImmediately,
              onIntermediateAnswer,
              onFinishAnswerScreen,
              isEmbeddedIframe,
            }}
          />
        );
      case UsabilitySectionType.FirstClick:
        return (
          <ViewFirstClick
            key={section._id}
            firstClickSection={section as UsabilitySectionFirstClick}
            {...{
              interactive,
              currentSectionScreenIndex,
              designPrototype,
              onFinishAnswerScreen,
              onSaveAnswerImmediately,
              isEmbeddedIframe,
            }}
          />
        );
      case DiscoverySectionType.Preference:
        return (
          <ViewPreferenceTest
            key={section._id}
            preferenceSection={section as DiscoverySectionPreferenceOutput}
            answer={answer as DiscoveryPreferenceAnswer}
            {...{ onSetAnswer, isEmbeddedIframe }}
          />
        );
      case DiscoverySectionType.Legal:
        return (
          <ViewLegal
            key={section._id}
            legalSection={section as DiscoverySectionLegalOutput}
            answer={answer as DiscoveryLegalAnswer}
            {...{ onSetAnswer }}
          />
        );
      case DefaultSectionType.Welcome:
        return (
          <ViewWelcomeScreen
            key={section._id}
            welcomeSection={section as DefaultSectionWelcomeOutput}
            {...{ onStart, isEmbeddedIframe }}
          />
        );
      case DefaultSectionType.ThankYou:
        return (
          <ViewThankYou
            key={section._id}
            thankYouSection={section as DefaultSectionThankYouOutput}
            disableInteraction={!onSetAnswer}
            isEmbeddedIframe={isEmbeddedIframe}
          />
        );
      default:
        return null;
    }
  };

  if (!section) return null;

  return (
    <div
      ref={sectionViewContainerRef}
      className={allClasses(
        'scroll-container flex flex-col items-center w-full overflow-y-auto',
        automaticSpacing && 'container-with-margin',
        isEmbeddedIframe && 'mt-4'
      )}>
      {automaticSpacing && <div className='shrink min-h-5 sm:h-28 sm:min-h-0' />}
      {renderSection()}
    </div>
  );
};
