import { useTranslationContext } from '../../../../../services/translation';
import { allClasses } from '../../../../../services/utilities/array';
import {
  DiscoverySectionType,
  DiscoverySectionYesNoOutput,
  DiscoveryYesNoAnswer,
} from '../../../../services/api-iteration1';
import { StorageFileType } from '../../../../services/api-cloud-storage/api-models';

import {
  NoAnswerIcon,
  YesAnswerIcon,
  YesAnswerEmotionsIcon,
  NoAnswerEmotionsIcon,
} from '../../../../assets/icons';
import { SectionImage } from './SectionImage';
import { SectionTypeLabel } from './SectionTypeLabel';

import styles from './ViewSection.module.css';

interface ViewYesNoProps {
  yesNoSection: DiscoverySectionYesNoOutput;
  answer: DiscoveryYesNoAnswer | undefined;
  onSetAnswer?: (answer: Omit<DiscoveryYesNoAnswer, 'duration'>) => void;
  interactive?: boolean;
}

export const ViewYesNo = ({ yesNoSection, answer, onSetAnswer, interactive }: ViewYesNoProps) => {
  const { t } = useTranslationContext.useContext();

  const { title, files, others } = yesNoSection;
  const { type, description } = others;

  const sectionImage = files.find((f) => f.type === StorageFileType.SectionImage);

  const answers =
    type === 'emotions'
      ? [
          {
            Icon: YesAnswerEmotionsIcon,
            text: t('components.projects.sections.yes-no.answers.yes'),
            value: 'yes',
            className:
              answer?.answer === 'yes' ? 'icon-fill-circle-main-highlight-70 icon-main' : undefined,
          },
          {
            Icon: NoAnswerEmotionsIcon,
            text: t('components.projects.sections.yes-no.answers.no'),
            value: 'no',
            className:
              answer?.answer === 'no' ? 'icon-fill-circle-main-danger-20 icon-main' : undefined,
          },
        ]
      : [
          {
            Icon: YesAnswerIcon,
            text: t('components.projects.sections.yes-no.answers.yes'),
            value: 'yes',
            className:
              answer?.answer === 'yes' ? 'icon-fill-main-highlight-70 icon-main' : undefined,
          },
          {
            Icon: NoAnswerIcon,
            text: t('components.projects.sections.yes-no.answers.no'),
            value: 'no',
            className: answer?.answer === 'no' ? 'icon-fill-danger-20 icon-main' : undefined,
          },
        ];

  return (
    <div className={styles.view_section_wrapper}>
      {sectionImage && <SectionImage image={sectionImage} />}
      <div className='flex flex-col max-w-full'>
        {interactive && <SectionTypeLabel label={t('components.projects.sections.question')} />}
        <h6 className='text-xl text-main-contrast font-bold leading-30 mb-2.5 break-all'>
          {title}
        </h6>
        {description && (
          <p className='text-lg text-main-contrast font-normal leading-normal'>{description}</p>
        )}
        <div className='flex flex-col mt-7 sm:flex-row sm:justify-start flex-shrink'>
          {answers.map(({ Icon, text, value, className }, index) => (
            <div
              key={value}
              onClick={() =>
                onSetAnswer?.({
                  answer: value,
                  type: DiscoverySectionType.YesNo,
                })
              }
              className={allClasses(
                'relative flex flex-1 w-full sm:max-w-1/2 flex-shrink flex-col justify-center items-center border-2 border-solid pt-14 pb-2 px-9 sm:px-24 rounded-lg box-border',
                index === 0 ? 'sm:mr-4' : 'mt-4 sm:mt-0',
                answer?.answer === value ? 'border-main-highlight' : 'border-main-10',
                onSetAnswer && 'cursor-pointer'
              )}>
              {answer?.answer === value && (
                <div className='absolute inset-0 bg-main-highlight opacity-10 z-0' />
              )}
              <Icon className={allClasses('w-20 h-20 mb-8 z-10', className)} />
              <h6 className='text-xl text-main-contrast font-bold leading-30 mb-2.5 z-10'>
                {text}
              </h6>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
