import { useTranslationContext } from '../../../../../services/translation';
import { DiscoveryYesNoAnswer } from '../../../../services/api-iteration1';
import { allClasses } from '../../../../../services/utilities/array';

import {
  NoAnswerEmotionsIcon,
  NoAnswerIcon,
  YesAnswerEmotionsIcon,
  YesAnswerIcon,
} from '../../../../assets/icons';
import { AnalyticsSectionAnswerEmptyState } from '../../../empty-states';

interface AnalyticsYesNoAnswerProps {
  answer?: DiscoveryYesNoAnswer | undefined;
  iconType: string;
}

export const AnalyticsYesNoAnswer = ({ answer, iconType }: AnalyticsYesNoAnswerProps) => {
  const { t } = useTranslationContext.useContext();

  const answers =
    iconType === 'emotions'
      ? [
          {
            Icon: YesAnswerEmotionsIcon,
            text: t('components.projects.sections.yes-no.answers.yes'),
            value: 'yes',
            className:
              answer?.answer === 'yes' ? 'icon-fill-circle-main-highlight-70 icon-main' : undefined,
          },
          {
            Icon: NoAnswerEmotionsIcon,
            text: t('components.projects.sections.yes-no.answers.no'),
            value: 'no',
            className:
              answer?.answer === 'no' ? 'icon-fill-circle-main-danger-20 icon-main' : undefined,
          },
        ]
      : [
          {
            Icon: YesAnswerIcon,
            text: t('components.projects.sections.yes-no.answers.yes'),
            value: 'yes',
            className:
              answer?.answer === 'yes' ? 'icon-fill-main-highlight-70 icon-main' : undefined,
          },
          {
            Icon: NoAnswerIcon,
            text: t('components.projects.sections.yes-no.answers.no'),
            value: 'no',
            className: answer?.answer === 'no' ? 'icon-fill-danger-20 icon-main' : undefined,
          },
        ];

  return answer ? (
    <div className='flex flex-col w-full items-center'>
      <div className='flex flex-row w-full justify-center'>
        {answers.map(({ Icon, text, value, className }, index) => (
          <div
            key={value}
            className={allClasses(
              'relative flex flex-col w-1/2 justify-center items-center border-2 border-solid pt-16 pb-4 px-24 rounded-lg',
              index === 0 && 'mr-4',
              answer?.answer === value ? 'border-main-highlight' : 'border-main-10'
            )}>
            {answer?.answer === value && (
              <div className='absolute inset-0 bg-main-highlight opacity-10 z-0' />
            )}
            <Icon className={allClasses('w-20 h-20 mb-8 z-10', className)} />
            <h6 className='text-xl text-main-contrast font-bold leading-30 mb-2.5 z-10'>{text}</h6>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <AnalyticsSectionAnswerEmptyState />
  );
};
