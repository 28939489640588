import { emptyArray } from '../utilities/array';

export type UrlParams = { [key: string]: number | string | boolean | undefined | null | string[] };

const isNonEmptyUrlParam = (value: any) =>
  value !== undefined && value !== '' && !emptyArray(value);

const serializeAsQueryParams = (params: UrlParams) =>
  encodeURI(
    (Object as any)
      .keys(params)
      .filter((key: string) => isNonEmptyUrlParam(params[key]))
      .map((key: string) => `${key}=${params[key]}`)
      .join('&')
  );

export const createQueryUrl = (url: string, params: UrlParams = {}) => {
  const queryParams = serializeAsQueryParams(params);
  const queryUrl = queryParams ? `${url}?${queryParams}` : url;

  return queryUrl;
};
