import { MutableRefObject, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useRecordCanvas, useTestSectionDuration } from '../../../../../../services/record';
import {
  endSingleGoalTest,
  getAnsweredFrames,
  isDirectSingleGoalSuccess,
  NavigatedFrame,
  Path,
  QuestionAnswer,
  UsabilitySectionType,
  UsabilitySingleGoalAnswer,
} from '../../../../../services/api-iteration1';
import {
  DesignPlatform,
  DesignPrototypeVersion,
} from '../../../../../services/api-iteration1/design-platforms';
import { useLivePrototypeNavigation } from '../../../../../services/design-platforms/useLivePrototypeNavigation';
import { getPathFirstFrame } from '../../../../../services/userTest';
import {
  Coordinates,
  CoordinatesInsideContainer,
  getClicksGroups,
  getCoordinatesOfHotspotKey,
} from '../../../../../../services/position';
import {
  UserTestFeedback,
  UserTestVersionResponseFeedbackType,
} from '../../../../../services/api-iteration1/projects/userTest/responses';
import { useTranslationContext } from '../../../../../../services/translation';
import { useAlertsContext } from '../../../../../../services/alerts';
import { uiEvents } from '../../../../../services/ui-events';

import { useToggle } from '../../../../../../components';

import { useMouseClickVisualEffect } from './useMouseClickVisualEffect';
import { LivePrototype } from '../../../live-prototype/LivePrototype';
import { MultipleClicksModal } from '../../../../modals';

const livePrototypeId = 'prototype-in-test-mode';
const maxTimeLimit = 300000; // 5 min;

interface ViewSingleGoalPrototypeTestProps {
  sectionId: string;
  type: DesignPlatform;
  designPrototype: DesignPrototypeVersion;
  paths: Path[];
  currentSectionScreenIndex?: number;
  sectionViewContainerRef: MutableRefObject<HTMLDivElement | null>;
  onIntermediateAnswer?: (
    sectionId: string,
    answer: Omit<UsabilitySingleGoalAnswer, 'duration'>,
    video?: Blob
  ) => void;
  onIntermediateVideo?: (video: Blob) => void;
  onSaveAnswerImmediately?: (
    sectionId: string,
    answer: Omit<QuestionAnswer, 'duration'>,
    video: Blob | undefined
  ) => Promise<void>;
  onFinishAnswerScreen?: (status?: { restart?: boolean }) => void;
  onSendFeedbackMultipleClicks?: (feedback: Omit<UserTestFeedback, 'sectionId'>) => void;
}

export const ViewSingleGoalPrototypeTest = ({
  sectionId,
  type,
  designPrototype,
  paths,
  sectionViewContainerRef,
  onIntermediateAnswer,
  onSaveAnswerImmediately,
  onFinishAnswerScreen,
  onSendFeedbackMultipleClicks,
}: ViewSingleGoalPrototypeTestProps) => {
  const { t } = useTranslationContext.useContext();
  const { addWarningAlert } = useAlertsContext.useContext();
  const { currentFrameId, setPrototypeLocation } = useLivePrototypeNavigation.useContext();

  useMouseClickVisualEffect(livePrototypeId);

  const sectionDurationRecorder = useTestSectionDuration({
    isAnswerableView: true,
  });

  const [automaticRestart, setAutomaticRestart] = useState<boolean>(false);

  const { canStartRecording, startRecording, stopRecording, getRecording } = useRecordCanvas({
    recordingCanvasId: 'canvas-for-recording-prototype-interaction',
    htmlElementToRecordId: 'iteration1_document_body_id',
    onError: (err) => {
      console.error(err);
    },
  });

  const initialFrameId = getPathFirstFrame(paths[0])?.id;

  if (!initialFrameId) throw Error('no initial frame id');

  const [navigatedFrames, setNavigatedFrames] = useState<NavigatedFrame[]>([
    { id: initialFrameId, clicks: [], startTime: 0 },
  ]);
  const [multipleSimilarClicks, setMultipleSimilarClicks] = useState<string[]>([]);
  const {
    isOpen: isMultipleClicksModalVisible,
    open: openMultipleClicksModal,
    close: closeMultipleClicksModal,
  } = useToggle();

  const shouldStartRecording = navigatedFrames.length === 1;

  const frameClicks = useRef<Coordinates[]>([]);

  const shouldSetStartingFrame = navigatedFrames.length === 0;

  const endTest = endSingleGoalTest(paths, navigatedFrames);

  const lastNavigatedFrameId = navigatedFrames.length
    ? navigatedFrames[navigatedFrames.length - 1].id
    : undefined;

  const increaseMissClickCount = useCallback(
    (click: Coordinates) => {
      setNavigatedFrames((prev) => {
        const prevFrames = [...prev];
        const lastFrame = prevFrames.pop();

        if (!lastFrame) return prev;

        return [
          ...prevFrames,
          {
            ...lastFrame,
            clicks: [
              ...lastFrame.clicks,
              {
                ...click,
                timestamp: sectionDurationRecorder.current.getTotalDuration(),
                type: 'missclick',
              },
            ],
          },
        ];
      });

      frameClicks.current.push(click);

      const groupedFrameSimilarClicks = getClicksGroups(frameClicks.current);
      const multipleSimilarClicksHotspots = Object.keys(groupedFrameSimilarClicks).filter(
        (key) => groupedFrameSimilarClicks[key].length > 4
      );

      setMultipleSimilarClicks((prev) => {
        const hasNewHotspot = prev.length !== multipleSimilarClicksHotspots.length;

        return hasNewHotspot ? multipleSimilarClicksHotspots : prev;
      });
    },
    [sectionDurationRecorder]
  );

  const addNavigatedFrame = useCallback(
    (frameId: string, click?: CoordinatesInsideContainer) => {
      const endTime = sectionDurationRecorder.current.getTotalDuration();
      sectionDurationRecorder.current.restart();

      setNavigatedFrames((prev) => {
        const prevFrames = [...prev];
        const lastFrame = prevFrames.pop();

        if (!lastFrame) return [...prevFrames, { id: frameId, clicks: [], startTime: 0 }];

        const updatedLastFrame = {
          ...lastFrame,
          endTime,
          ...(click
            ? {
                clicks: [
                  ...lastFrame.clicks,
                  {
                    ...click,
                    timestamp: endTime,
                    type: 'hotspot',
                  } as const,
                ],
              }
            : {}),
        };

        return [...prevFrames, updatedLastFrame, { id: frameId, clicks: [], startTime: 0 }];
      });

      setPrototypeLocation((prev) => ({
        ...prev,
        navigatedFramesIds: [...prev.navigatedFramesIds, frameId],
      }));
    },
    [sectionDurationRecorder, setPrototypeLocation]
  );

  const sendFeedbackMultipleClicks = async (reason: string) => {
    const lastClickHotspot = [...multipleSimilarClicks].pop();
    const lastNavigatedFrame = [...navigatedFrames].pop();

    if (!lastClickHotspot || !lastNavigatedFrame) return;

    const { x, y } = getCoordinatesOfHotspotKey(lastClickHotspot);

    onSendFeedbackMultipleClicks?.({
      type: UserTestVersionResponseFeedbackType.SameHotspotMultipleClicks,
      frameId: lastNavigatedFrame.id,
      x: String(x),
      y: String(y),
      reason,
    });
  };

  const saveProgressImmediately = useCallback(() => {
    const prevFrames = [...navigatedFrames];
    const lastFrame = prevFrames.pop();

    if (!lastFrame) return;

    const allNavigatedFrames = [
      ...prevFrames,
      { ...lastFrame, endTime: sectionDurationRecorder.current.getTotalDuration() },
    ];

    const video = getRecording();
    onSaveAnswerImmediately?.(
      sectionId,
      {
        type: UsabilitySectionType.SingleGoal,
        frames: getAnsweredFrames(allNavigatedFrames),
      },
      video
    );
  }, [getRecording, navigatedFrames, onSaveAnswerImmediately, sectionDurationRecorder, sectionId]);

  useEffect(() => {
    const lastClickHotspot = [...multipleSimilarClicks].pop();

    if (lastClickHotspot) openMultipleClicksModal();
  }, [multipleSimilarClicks, openMultipleClicksModal]);

  useEffect(() => {
    if (!initialFrameId) return;

    setPrototypeLocation({
      navigatedFramesIds: [initialFrameId],
    });
  }, [initialFrameId, setPrototypeLocation]);

  useEffect(() => {
    if (canStartRecording && shouldStartRecording) startRecording();
  }, [canStartRecording, shouldStartRecording, startRecording]);

  useLayoutEffect(() => {
    if (lastNavigatedFrameId && sectionViewContainerRef.current)
      sectionViewContainerRef.current.scrollTop = 0;
  }, [lastNavigatedFrameId, sectionViewContainerRef]);

  useEffect(() => {
    const timer = setInterval(() => {
      const totalSectionPausedTime = sectionDurationRecorder.current.getTotalPauseDuration();

      if (totalSectionPausedTime > maxTimeLimit) {
        setAutomaticRestart(true);
        clearInterval(timer);
      }
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, [sectionDurationRecorder]);

  useEffect(() => {
    if (shouldSetStartingFrame) return;

    const shouldEndSectionTest = automaticRestart || endTest;
    if (!shouldEndSectionTest) {
      const video = getRecording();
      return onIntermediateAnswer?.(
        sectionId,
        {
          type: UsabilitySectionType.SingleGoal,
          frames: getAnsweredFrames(navigatedFrames),
        },
        video
      );
    }

    setTimeout(() => {
      stopRecording((video) => {
        if (automaticRestart) addWarningAlert(t('warnings.single-goal-test-duration'));
        onFinishAnswerScreen?.({ restart: automaticRestart });
        onSaveAnswerImmediately?.(
          sectionId,
          {
            type: UsabilitySectionType.SingleGoal,
            frames: getAnsweredFrames(navigatedFrames),
            successType: isDirectSingleGoalSuccess(paths, navigatedFrames) ? 'direct' : 'indirect',
          },
          video
        );
        setPrototypeLocation({ navigatedFramesIds: [] });
      });
    }, 1000);
  }, [
    addWarningAlert,
    automaticRestart,
    endTest,
    navigatedFrames,
    onSaveAnswerImmediately,
    onFinishAnswerScreen,
    paths,
    sectionId,
    setPrototypeLocation,
    shouldSetStartingFrame,
    stopRecording,
    t,
    getRecording,
    onIntermediateAnswer,
  ]);

  useEffect(() => {
    const removeGiveUpListener = uiEvents.giveUpTest.listen(saveProgressImmediately);

    function saveVideoBeforeUnload(e: BeforeUnloadEvent) {
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = '';

      saveProgressImmediately();

      // Prompt the user with a confirmation dialog
      const confirmationMessage = 'Are you sure you want to leave?';
      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; // Webkit, Safari, Chrome etc.
    }

    window.addEventListener('beforeunload', saveVideoBeforeUnload);

    return () => {
      removeGiveUpListener();
      window.removeEventListener('beforeunload', saveVideoBeforeUnload);
    };
  }, [saveProgressImmediately]);

  return (
    <>
      <LivePrototype
        id={livePrototypeId}
        hotspotHints={false}
        onChangeFrame={addNavigatedFrame}
        onMissClick={increaseMissClickCount}
        reachedEndFrame={endTest}
        {...{ type, designPrototype, currentFrameId }}
      />
      {onSendFeedbackMultipleClicks && (
        <MultipleClicksModal
          isOpen={isMultipleClicksModalVisible}
          closeModal={closeMultipleClicksModal}
          sendFeedback={sendFeedbackMultipleClicks}
        />
      )}
      <canvas
        id='canvas-for-recording-prototype-interaction'
        style={{ visibility: 'hidden' }}
        className='fixed'
      />
    </>
  );
};
