import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

type ApiConfig = AxiosRequestConfig;
type RequestConfig = AxiosRequestConfig;
export type ApiResponse<Data> = AxiosResponse<Data>;

export class Api {
  private apiGateway: AxiosInstance;
  private authExpirationTime: string | undefined;

  constructor(config: ApiConfig) {
    this.apiGateway = Axios.create(config);
  }

  get = async <T = unknown>(endpoint: string, config?: RequestConfig): Promise<ApiResponse<T>> => {
    return this.apiGateway.get(endpoint, config);
  };
  post = async <T = unknown>(
    endpoint: string,
    data: any = undefined,
    config?: RequestConfig
  ): Promise<ApiResponse<T>> => {
    return this.apiGateway.post(endpoint, data, config);
  };
  patch = async <T = unknown>(
    endpoint: string,
    data: any = undefined,
    config?: RequestConfig
  ): Promise<ApiResponse<T>> => {
    return this.apiGateway.patch(endpoint, data, config);
  };
  put = async <T = unknown>(
    endpoint: string,
    data: any = undefined,
    config?: RequestConfig
  ): Promise<ApiResponse<T>> => {
    return this.apiGateway.put(endpoint, data, config);
  };
  destroy = async <T = unknown>(
    endpoint: string,
    config?: RequestConfig
  ): Promise<ApiResponse<T>> => {
    return this.apiGateway.delete(endpoint, config);
  };
  setTokenInHeader = async (accessToken: string | undefined, expirationTime?: string) => {
    if (accessToken) {
      this.apiGateway.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      this.authExpirationTime = expirationTime;
    } else {
      this.apiGateway.defaults.headers.common.Authorization = '';
      this.authExpirationTime = undefined;
    }
  };
  hasActiveAuth = () => {
    const tokenHasExpired = this.authExpirationTime
      ? new Date(this.authExpirationTime).getTime() - Date.now() < 0
      : false;

    return !tokenHasExpired && !!this.apiGateway.defaults.headers.common.Authorization;
  };
}
