interface LabelProps {
  alignment: 'left' | 'center' | 'right';
  text: string;
}

export const Label = ({ alignment, text }: LabelProps) => {
  return (
    <p
      className={`mt-0 text-sm sm:text-lg leading-6 font-normal text-neutral text-${alignment} sm:mt-5`}>
      {text}
    </p>
  );
};
