import { useEffect, useMemo, useState } from 'react';

import { useTranslationContext } from '../../../../../services/translation';
import {
  Path,
  UsabilitySectionSingleGoal,
  UsabilitySectionType,
  UsabilitySectionInput,
  isTemporarySection,
} from '../../../../services/api-iteration1';
import { useSectionsListContext } from '../../../../services/sections/useSectionsList';
import { isRequired } from '../../../../services/validation';
import { allClasses, CleanArray } from '../../../../../services/utilities/array';
import { FigmaDesignPrototypeVersion } from '../../../../services/api-iteration1/design-platforms';
import { useErrorHandlerContext } from '../../../../../services/error-handling';
import { useEditUsabilitySection } from '../../../../services/userTest';
import { isUndefined } from '../../../../../services/utilities/value';
import { getIdenticalPathsIndexes } from '../../../../services/validation/sections/usability';

import { Form, FormError, FormField, useToggle } from '../../../../../components';

import { SingleGoalIcon } from '../../../../assets/icons';
import { SelectSectionItem } from '../../../containers';
import { PathCard } from '../../../cards';
import { ChangeStartingFrameModal } from '../../../modals';

import styles from './Section.module.css';

interface EditSectionSingleGoalProps {
  section: UsabilitySectionSingleGoal;
  editSection: (
    sectionId: string,
    updates: UsabilitySectionInput,
    shouldUpdate?: boolean
  ) => Promise<void> | undefined;
  designPrototype?: FigmaDesignPrototypeVersion;
  startingPointNodeId?: string;
}

interface SectionSingleGoalProps extends EditSectionSingleGoalProps {
  index: number;
  isInvalid: boolean;
  removeSection: (sectionId: string) => Promise<void> | undefined;
  duplicateSection: ((sectionId: string) => Promise<void> | undefined) | (() => void);
}

const SectionSingleGoal = ({
  section,
  index,
  isInvalid,
  editSection,
  removeSection,
  duplicateSection,
  designPrototype,
  startingPointNodeId,
}: SectionSingleGoalProps) => {
  const { t } = useTranslationContext.useContext();

  const {
    inFocusSectionId,
    setSectionInFocus,
    resetSectionInFocus: collapseSection,
  } = useSectionsListContext.useContext();

  const { _id, others } = section;
  const { title } = others;

  const isLoading = isTemporarySection(_id);
  const isExpanded = inFocusSectionId === _id && !isLoading;
  const expandSection = () => {
    setSectionInFocus(_id);
  };

  return (
    <SelectSectionItem
      alreadyAdded
      draggableItemId={_id}
      title={title || t('components.projects.sections.section-title-placeholder')}
      description={t('components.projects.sections.field-types.singleGoal')}
      icon={<SingleGoalIcon className='icon__small' />}
      onClick={isExpanded ? collapseSection : expandSection}
      type={UsabilitySectionType.SingleGoal}
      onDelete={(e) => {
        e.stopPropagation();
        removeSection(_id);
      }}
      onDuplicate={(e) => {
        e.stopPropagation();
        duplicateSection?.(_id);
      }}
      {...{ isExpanded, isInvalid, isLoading, index }}>
      <EditSectionSingleGoal
        {...{
          section,
          editSection,
          designPrototype,
          startingPointNodeId,
        }}
      />
    </SelectSectionItem>
  );
};

const EditSectionSingleGoal = ({
  section,
  editSection,
  designPrototype,
  startingPointNodeId,
}: EditSectionSingleGoalProps) => {
  const { t } = useTranslationContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();

  const {
    addNewPath,
    deletePath,
    deleteFrames,
    changeStartFrame,
    setPrototypeLocation,
    pathInFocusIndex,
  } = useEditUsabilitySection({
    section,
    editSection,
  });

  const {
    isOpen: isOpenChangeFrameModal,
    open: openChangeFrameModal,
    close: closeChangeFrameModal,
  } = useToggle();
  const [pathToChangeStartScreen, setPathToChangeStartScreen] = useState<number>(0);

  const { _id, others } = section;
  const { title, description, paths } = others;

  const firstPath = paths.find((path) => path.index === 0);

  const pathStartingFrame = paths.length ? paths[0].frames[0].id : startingPointNodeId;

  const identicalPathsIndexes: number[] = getIdenticalPathsIndexes(paths);

  const updateSectionValues = (values: any) => {
    if (!_id) return;

    const updates = {
      others: {
        title: values.title,
        description: values.description,
        paths: paths,
      },
    };

    editSection(_id, updates, !isTemporarySection(_id));
  };

  const initialValues = useMemo(
    () => ({
      title: title,
      description: description || '',
    }),
    [description, title]
  );

  const openFramesModal = (pathIndex: number) => {
    setPathToChangeStartScreen(pathIndex);
    openChangeFrameModal();
  };

  const changeInitialFrame = async (pathIndex: number, frameId: string) => {
    try {
      await changeStartFrame(pathIndex, frameId);
    } catch (err) {
      handleError(err);
    }
    closeChangeFrameModal();
  };

  useEffect(() => {
    const noPathSelected = isUndefined(pathInFocusIndex);

    if (!firstPath) return;

    if (noPathSelected)
      setPrototypeLocation({
        pathIndex: firstPath.index,
        navigatedFramesIds: firstPath.frames.map((frame) => frame.id),
      });
  }, [firstPath, pathInFocusIndex, setPrototypeLocation]);

  useEffect(() => {
    return () => {
      setPrototypeLocation({ navigatedFramesIds: [] });
    };
  }, [setPrototypeLocation]);

  return (
    <>
      <hr className={allClasses(styles.section_hr, 'w-11/12')} />
      <Form
        validateOnMount
        enableReinitialize
        initialValues={initialValues}
        onChange={updateSectionValues}
        className='px-5'>
        {({ status, errors, touched }) => (
          <>
            <FormError {...{ status }} />
            <FormField
              autoFocus
              type='text'
              name='title'
              label={`${t('components.projects.sections.single-goal.edit.title.label')}*`}
              placeholder={t('components.projects.sections.single-goal.edit.title.placeholder')}
              validate={isRequired}
              fieldClassName='mb-5'
              inputClassName={allClasses(
                styles.section_input,
                errors.title && touched.title && styles.section_input__invalid
              )}
              labelClassName={styles.section_label}
            />
            <FormField
              type='text'
              name='description'
              label={t('components.projects.sections.single-goal.edit.description.label')}
              placeholder={t(
                'components.projects.sections.single-goal.edit.description.placeholder'
              )}
              fieldClassName='mb-5'
              inputClassName={styles.section_input}
              labelClassName={styles.section_label}
            />
            <div>
              <label className='block mb-1'>
                {`${t('components.projects.sections.single-goal.edit.expected-path.label')}*`}
              </label>
              <p
                className='mb-2 text-xs text-neutral-70 leading-4 font-normal'
                onClick={addNewPath}>
                {t('components.projects.sections.single-goal.edit.expected-path.info')}
              </p>
              <div className='mb-4'>
                {CleanArray(paths).map((path: Path, _, paths) => (
                  <PathCard
                    key={`path-${path.index}`}
                    path={path}
                    designPrototype={designPrototype}
                    enableDelete={paths.length > 1}
                    onDelete={deletePath}
                    onDeleteFrames={deleteFrames}
                    onSelectPath={(path) =>
                      setPrototypeLocation({
                        pathIndex: path.index,
                        navigatedFramesIds: path.frames.map((frame) => frame.id),
                      })
                    }
                    pathInFocusIndex={pathInFocusIndex}
                    isIdenticalPath={identicalPathsIndexes.includes(path.index)}
                    onClickChangeStartFrame={() => openFramesModal(path.index)}
                    showChangeStartFrame={
                      paths.length === 1 && path.index === 0 && path.frames.length === 1
                    }
                  />
                ))}
              </div>
              <p
                className='my-5 text-info underline cursor-pointer hover:text-info-70'
                onClick={addNewPath}>
                {t('components.projects.sections.single-goal.edit.expected-path.add-new-path')}
              </p>
              {isOpenChangeFrameModal && (
                <ChangeStartingFrameModal
                  isOpen={isOpenChangeFrameModal}
                  designPrototype={designPrototype}
                  startingPointNodeId={pathStartingFrame}
                  pathIndex={pathToChangeStartScreen}
                  onClose={closeChangeFrameModal}
                  onSelect={changeInitialFrame}
                />
              )}
            </div>
          </>
        )}
      </Form>
    </>
  );
};

export default SectionSingleGoal;
