import { useFormikContext } from 'formik';

import { createContextService } from '../../services/context';

interface FormServiceProps {
  submitChange: (values?: any) => void;
}

const useForm = ({ submitChange }: FormServiceProps) => {
  const formikProps = useFormikContext();

  return { ...formikProps, submitChange };
};

export const useFormContext = createContextService(useForm);
