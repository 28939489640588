import { useRouteParams } from '../../services/router';
import { VersionStatus } from '../services/api-iteration1';
import { useCurrentDiscoveryVersionContext } from '../services/discovery';

import { withAuth } from '../components';

import { DiscoveryResults } from './DiscoveryResults';
import { EditDiscovery } from './EditDiscovery';

type DiscoveryParams = {
  discoveryId: string;
};

export const Discovery = withAuth(() => {
  const { discoveryId } = useRouteParams<DiscoveryParams>();

  return (
    <useCurrentDiscoveryVersionContext.ProvideContext {...{ discoveryId }}>
      <DiscoveryPage />
    </useCurrentDiscoveryVersionContext.ProvideContext>
  );
});

export const DiscoveryPage = () => {
  const { discoveryVersion } = useCurrentDiscoveryVersionContext.useContext();
  const { status } = discoveryVersion || {};

  return status === VersionStatus.Draft ? <EditDiscovery /> : <DiscoveryResults />;
};
