import { useTranslationContext } from '../../../../services/translation';
import {
  DiscoveryLegalAnswer,
  DiscoveryMultipleChoiceAnswer,
  DiscoveryOpenWordAnswer,
  DiscoveryPreferenceAnswer,
  DiscoveryRatingScaleAnswer,
  DiscoverySection,
  DiscoverySectionLegalOutput,
  DiscoverySectionMultipleChoiceOutput,
  DiscoverySectionPreferenceOutput,
  DiscoverySectionRatingScaleOutput,
  DiscoverySectionType,
  DiscoveryVersionResponseOutput,
  DiscoveryYesNoAnswer,
  isDiscoverySection,
  isUsabilitySection,
  UsabilitySection,
  UsabilitySectionFirstClick,
  UsabilitySectionFirstClickAnswer,
  UsabilitySectionFiveSeconds,
  UsabilitySectionFiveSecondsAnswer,
  UsabilitySectionType,
  UsabilitySingleGoalAnswer,
} from '../../../services/api-iteration1';
import { UserTestProjectSection } from '../../../services/api-iteration1/projects/userTest/sections/api-models';
import { UserTestVersionResponseOutput } from '../../../services/api-iteration1/projects/userTest/responses';
import { FigmaDesignPrototypeVersion } from '../../../services/api-iteration1/design-platforms';
import { formatDate } from '../../../../services/date';
import { computeFramesAnalytics } from '../../../services/analytics';

import { SectionsIcon } from '../../../assets/icons';
import { AnalyticsSectionContainer } from '../../containers';
import { renderSectionIcon } from '../../icons';
import {
  AnalyticsYesNoAnswer,
  AnalyticsRatingScaleAnswer,
  ViewAnalyticsOpenWordChoiceAnswer,
  AnalyticsSingleGoalAnswer,
  AnalyticsFiveSecondsAnswer,
  AnalyticsMultipleChoiceAnswer,
  AnalyticsFirstClickAnswer,
  AnalyticsPreferenceTestAnswer,
} from './answers';
import { ViewAnalyticsLegalAnswer } from './answers/AnalyticsLegalAnswer';

interface AnalyticsSectionsAnswersProps {
  sections: (DiscoverySection | UserTestProjectSection)[];
  inFocusResponseId: string;
  responses: (DiscoveryVersionResponseOutput | UserTestVersionResponseOutput)[];
  responseInFocusIndex: number;
  designPrototype?: FigmaDesignPrototypeVersion;
  versionName?: string;
}

export const AnalyticsSectionsAnswers = ({
  inFocusResponseId,
  responses,
  sections,
  responseInFocusIndex,
  designPrototype,
  versionName,
}: AnalyticsSectionsAnswersProps) => {
  const { t } = useTranslationContext.useContext();

  const currentTesterResponse = responses.find((response) => response.id === inFocusResponseId);
  const currentTesterAnswers = currentTesterResponse?.answers;

  const renderTitle = (section: DiscoverySection | UsabilitySection) => {
    if (section.type === UsabilitySectionType.FirstClick) {
      return (section as UsabilitySectionFirstClick).title as string;
    }
    if (isDiscoverySection(section)) return section.title;
    if (isUsabilitySection(section)) return section.others.title as string;

    return '';
  };

  return (
    <div className='-mx-7 mt-20'>
      {sections.map((item, index) => (
        <div key={index} className='mb-5'>
          <AnalyticsSectionContainer
            contentTitle={
              renderTitle(item as DiscoverySection | UsabilitySection) ||
              (item.type !== UsabilitySectionType.FiveSeconds
                ? t('components.projects.sections.section-title-placeholder')
                : undefined)
            }
            icon={renderSectionIcon(item.type as DiscoverySectionType | UsabilitySectionType)}
            key={index}
            rightSideInformation={
              <span className='flex flex-row items-center'>
                <SectionsIcon />
                <span className='inline-block ml-2'>{`${index + 1} / ${sections.length}`}</span>
              </span>
            }
            title={t(`components.projects.sections.field-types.${item.type}`)}
            description={item.others?.description as string}>
            <div className='w-full flex flex-row items-center mt-10 mb-10'>
              {(() => {
                const sectionAnswer = currentTesterAnswers?.[item._id];

                switch (item.type) {
                  case DiscoverySectionType.YesNo:
                    return (
                      <AnalyticsYesNoAnswer
                        key={item._id}
                        answer={sectionAnswer as DiscoveryYesNoAnswer | undefined}
                        iconType={item?.others?.type as string}
                      />
                    );
                  case DiscoverySectionType.RatingScale:
                    return (
                      <AnalyticsRatingScaleAnswer
                        key={item._id}
                        answer={sectionAnswer as DiscoveryRatingScaleAnswer | undefined}
                        ratingScaleSection={item as DiscoverySectionRatingScaleOutput}
                      />
                    );
                  case DiscoverySectionType.MultipleChoice:
                    return (
                      <AnalyticsMultipleChoiceAnswer
                        key={item._id}
                        multipleChoiceSection={item as DiscoverySectionMultipleChoiceOutput}
                        answer={sectionAnswer as DiscoveryMultipleChoiceAnswer | undefined}
                      />
                    );
                  case DiscoverySectionType.OpenWordChoice:
                    return (
                      <ViewAnalyticsOpenWordChoiceAnswer
                        key={item._id}
                        answer={sectionAnswer as DiscoveryOpenWordAnswer | undefined}
                        updatedAt={
                          responses &&
                          formatDate(new Date(responses[responseInFocusIndex].updatedAt), 'PPPPp')
                        }
                      />
                    );
                  case UsabilitySectionType.SingleGoal:
                    const { uniqueFramesData } =
                      (sectionAnswer &&
                        computeFramesAnalytics(
                          [sectionAnswer as UsabilitySingleGoalAnswer],
                          1,
                          designPrototype
                        )) ||
                      {};

                    return (
                      <AnalyticsSingleGoalAnswer
                        key={item._id}
                        answer={sectionAnswer as UsabilitySingleGoalAnswer | undefined}
                        screensAnalytics={uniqueFramesData}
                        testerIndex={responses.length - responseInFocusIndex}
                        browser={currentTesterResponse?.device.browser.name || ''}
                        sectionId={item._id}
                        {...{ versionName }}
                      />
                    );
                  case UsabilitySectionType.FiveSeconds:
                    return (
                      <AnalyticsFiveSecondsAnswer
                        key={item._id}
                        section={item as UsabilitySectionFiveSeconds}
                        responsesCount={responses.length}
                        answer={sectionAnswer as UsabilitySectionFiveSecondsAnswer | undefined}
                        testerIndex={responses.length - responseInFocusIndex}
                        {...{ designPrototype, versionName }}
                      />
                    );
                  case UsabilitySectionType.FirstClick:
                    return (
                      <AnalyticsFirstClickAnswer
                        key={item._id}
                        section={item as UsabilitySectionFirstClick}
                        answer={sectionAnswer as UsabilitySectionFirstClickAnswer | undefined}
                        responsesCount={responses.length}
                        testerIndex={responses.length - responseInFocusIndex}
                        {...{ designPrototype, versionName }}
                      />
                    );
                  case DiscoverySectionType.Preference:
                    return (
                      <AnalyticsPreferenceTestAnswer
                        key={item._id}
                        preferenceSection={item as DiscoverySectionPreferenceOutput}
                        answer={sectionAnswer as DiscoveryPreferenceAnswer | undefined}
                      />
                    );
                  case DiscoverySectionType.Legal:
                    return (
                      <ViewAnalyticsLegalAnswer
                        key={item._id}
                        legalSection={item as DiscoverySectionLegalOutput}
                        answer={sectionAnswer as DiscoveryLegalAnswer | undefined}
                      />
                    );
                  default:
                    return null;
                }
              })()}
            </div>
          </AnalyticsSectionContainer>
        </div>
      ))}
    </div>
  );
};
