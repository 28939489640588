import { useTranslationContext } from '../../../services/translation';
import { getKeyForCondition } from '../../../services/utilities/object';
import { ScreenAnalytics } from '../../services/analytics';
import { getStoragePublicFileUrl } from '../../services/api-cloud-storage';

import { ScreenAnalyticsCard } from '../cards/ScreenAnalyticsCard';
import { ScreensFiltersTypes } from '../config';

interface AnalyticsScreensListProps {
  screensAnalytics: ScreenAnalytics[];
  sectionExpand: (sectionId: string) => void;
  expandedSection: string;
  inFocusFilterId?: string;
  setFilterInFocus: (filter: 'all-clicks' | 'first-click' | undefined) => void;
  selectedFilter: ScreensFiltersTypes;
  isIndividualResponse?: boolean;
}

export const AnalyticsScreensList = ({
  screensAnalytics,
  sectionExpand,
  expandedSection,
  inFocusFilterId,
  setFilterInFocus,
  selectedFilter,
  isIndividualResponse,
}: AnalyticsScreensListProps) => {
  const { t } = useTranslationContext.useContext();

  return (
    <>
      {screensAnalytics.length > 0 &&
        screensAnalytics.map((frame, index) => (
          <ScreenAnalyticsCard
            key={frame.frameId}
            {...{ inFocusFilterId, setFilterInFocus, selectedFilter }}
            title={`${t('common.screen')} ${index + 1}`}
            screenName={frame.screenName}
            image={frame.screenImage && getStoragePublicFileUrl(frame.screenImage.id)}
            rating={frame.usabilityScore.toFixed(1)}
            ratingColor={frame.usabilityScoreColor}
            isExpanded={expandedSection === frame.frameId}
            onClick={() => sectionExpand(frame.frameId)}
            isLast={index === screensAnalytics.length - 1}
            data={[
              {
                icon: 'users',
                title: t(
                  'components.projects.usability-testing.results.single-goal.key-stats.total-testers'
                ),
                value: frame.testersCount,
                color: getKeyForCondition({
                  'success-result': frame.testersCount >= 5,
                  'warning-result': frame.testersCount >= 3 && frame.testersCount < 5,
                  'danger-result': frame.testersCount < 3,
                }),
              },
              {
                icon: 'missclick',
                title: t(
                  'components.projects.usability-testing.results.single-goal.key-stats.misclick-rate'
                ),
                value: `${frame.missclicksRate}%`,
                color: getKeyForCondition({
                  'success-result': frame.missclicksRate < 30,
                  'warning-result': frame.missclicksRate >= 30 && frame.missclicksRate < 50,
                  'danger-result': frame.missclicksRate >= 50,
                }),
              },
              {
                icon: 'time',
                title: t(
                  'components.projects.usability-testing.results.single-goal.screens.time-spent'
                ),
                value: frame.timeSpent ? `${Number(frame.timeSpent.toFixed(1))}s` : '0s',
                color: getKeyForCondition({
                  'success-result': frame.timeSpent < 10,
                  'warning-result': frame.timeSpent >= 10 && frame.timeSpent < 30,
                  'danger-result': frame.timeSpent >= 30,
                }),
              },
              {
                icon: 'checks',
                title: t(
                  'components.projects.usability-testing.results.single-goal.key-stats.completion-rate'
                ),
                value: `${frame.completionRate}%`,
                color: getKeyForCondition({
                  'success-result': frame.completionRate >= 70,
                  'warning-result': frame.completionRate >= 30 && frame.completionRate < 70,
                  'danger-result': frame.completionRate < 30,
                }),
              },
            ]}
            className={
              selectedFilter !== 'all' &&
              (frame.isLastFrame || frame.usabilityScoreType !== selectedFilter)
                ? 'hidden'
                : undefined
            }
            {...{ isIndividualResponse }}
          />
        ))}
    </>
  );
};
