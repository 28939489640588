import { useCallback, useMemo, useState } from 'react';

import { createContextService } from '../../../services/context';
import { convertNullsToValue, PartialNullable } from '../../../services/utilities/object';
import { useRouter } from '../../../services/router';
import { useErrorHandlerContext } from '../../../services/error-handling';

import { getSortedSections } from './utils';
import { FolderParentOutput } from '../api-iteration1';
import { dynamicRoutes, routes } from '../../pages/routing/routes';

import {
  DefaultSectionThankYouOutput,
  DefaultSectionWelcomeOutput,
  deleteUserTest,
  DiscoverySection,
  renameUserTest,
  UsabilitySection,
  UserTestOutput,
  useUserTest,
} from '../api-iteration1/projects';
import {
  UserTestVersionOutput,
  useUserTestVersion,
} from '../api-iteration1/projects/userTest/versions';
import { UserTestProjectSection } from '../api-iteration1/projects/userTest/sections/api-models';

interface CurrentUserTestVersionService {
  userTestId: string;
  userTestVersionId: string;
  parents: FolderParentOutput[];
  userTest: UserTestOutput | undefined;
  userTestVersion: UserTestVersionOutput | undefined;
  isLoadingUserTest: boolean;
  sortedSections: Required<DiscoverySection | UsabilitySection>[];
  thankYouSection: DefaultSectionThankYouOutput | undefined;
  welcomeSection: DefaultSectionWelcomeOutput | undefined;
  revalidate: () => void;
  revalidateProject: () => void;
  mutateSectionLocally: (
    sectionId: string,
    updates: PartialNullable<UserTestProjectSection> | null
  ) => void;
  error?: any;
  removeUserTest: () => Promise<void> | undefined;
  renameCurrentUserTest: (name: string) => Promise<void> | undefined;
  startingPointNodeId?: string;
  setShouldRevalidateOnFocus: (value: boolean) => void;
}

interface useCurrentUserTestVersionProps {
  userTestId: string;
  userTestVersionId: string;
}

export const useCurrentUserTestVersion = ({
  userTestId,
  userTestVersionId,
}: useCurrentUserTestVersionProps): CurrentUserTestVersionService => {
  const router = useRouter();
  const { handleError } = useErrorHandlerContext.useContext();

  const [shouldRevalidateOnFocus, setShouldRevalidateOnFocus] = useState<boolean>(true);

  const {
    data: userTest,
    error: userTestError,
    revalidate: revalidateProject,
  } = useUserTest(userTestId);

  const {
    data: userTestVersion,
    isLoading: isLoadingUserTest,
    revalidate: revalidateVersion,
    mutate,
    error: userTestVersionError,
  } = useUserTestVersion(userTestId, userTestVersionId, undefined, {
    revalidateOnFocus: shouldRevalidateOnFocus,
  });

  const { sortableSections, welcomeSection, thankYouSection } = useMemo(
    () => getSortedSections(userTestVersion?.sections),
    [userTestVersion?.sections]
  );

  const mutateSectionLocally = useCallback(
    async (sectionId: string, updates: PartialNullable<UserTestProjectSection> | null) => {
      mutate((prev) => {
        if (!prev) return;

        const data: UserTestVersionOutput = (prev as any).data;

        const shouldRemoveSection = !updates;
        if (shouldRemoveSection) {
          const updatedSections = data.sections.filter((section) => section._id !== sectionId);

          return {
            ...prev,
            data: {
              ...data,
              sections: updatedSections,
            },
          };
        }

        const sectionToUpdate = data.sections.find((section) => section._id === sectionId);

        if (!sectionToUpdate) {
          const versionWithNewSection = {
            ...data,
            sections: [...data.sections, updates],
          } as UserTestVersionOutput;

          return { ...prev, data: versionWithNewSection };
        } else {
          const updatedSections = data.sections.map((section) => {
            return section._id === sectionId
              ? {
                  ...section,
                  ...updates,
                  others: {
                    ...section.others,
                    ...(convertNullsToValue(
                      updates?.others || {},
                      undefined
                    ) as UserTestProjectSection['others']),
                  },
                }
              : section;
          });

          const updatedVersion = { ...data, sections: updatedSections };

          return { ...prev, data: updatedVersion as UserTestVersionOutput };
        }
      }, false);
    },
    [mutate]
  );

  const removeUserTest = async () => {
    if (!userTestVersion) return;

    try {
      const directParent = { _id: '' };
      await deleteUserTest(userTestId);
      revalidateVersion();

      const redirectRoute = directParent ? dynamicRoutes.folder(directParent._id) : routes.projects;
      router.replace(redirectRoute);
    } catch (err) {
      handleError(err);
    }
  };

  const renameCurrentUserTest = async (name: string) => {
    if (!userTestVersion) return;

    try {
      await renameUserTest(userTestId, userTestVersionId || '', { name });
      revalidateVersion();
    } catch (err) {
      handleError(err);
    }
  };

  return {
    userTestId,
    userTestVersionId,
    userTest,
    parents: userTest?.parents || [],
    userTestVersion,
    isLoadingUserTest,
    sortedSections: sortableSections,
    thankYouSection,
    welcomeSection,
    revalidate: revalidateVersion,
    revalidateProject,
    mutateSectionLocally,
    error: userTestError || userTestVersionError,
    removeUserTest,
    renameCurrentUserTest,
    startingPointNodeId: userTest?.designPrototype?.params?.startingPointNodeId,
    setShouldRevalidateOnFocus,
  };
};

export const useCurrentUserTestVersionContext = createContextService(useCurrentUserTestVersion);
