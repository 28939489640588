import { MyProjectPermission } from '@iteration1/permission-validators';

import { useTranslationContext } from '../../services/translation';
import { FolderOutput, useFolder, useProjectsCount } from '../services/api-iteration1';
import { useRouteParams } from '../../services/router';
import { useCurrentFolderContext } from '../services/folder/useCurrentFolder';
import { usePermissionsContext } from '../services/authorization';
import { useUpgradeModalToggle } from '../services/billing/useUpgradeModalToggle';
import { FetchMutator } from '../../services/api/resource-fetch-factory';

import { MainLayout, ProjectsList, withAuth } from '../components';
import { Button, useToggle } from '../../components';
import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import { CreateFolderModal } from '../components/modals/CreateFolderModal';
import { CreateProjectTypeModal } from '../components/modals/CreateProjectTypeModal';
import { EditFolderNavbarActions } from '../components/navbars/EditFolderNavbarActions';

import { PlusSignIcon, ProjectsEmptyStateIllustration } from '../assets/icons';
import { dynamicRoutes, routes } from './routing/routes';

type FolderParams = {
  folderId: string;
};

interface FolderPageProps {
  folder: FolderOutput | undefined;
  isLoading: boolean;
  error: string;
  mutate: FetchMutator<FolderOutput>;
  revalidate: () => void;
}

export const Folder = withAuth(() => {
  const { folderId } = useRouteParams<FolderParams>();
  const { data: folder, isLoading, error, mutate, revalidate } = useFolder(folderId);

  return (
    <useCurrentFolderContext.ProvideContext {...{ folder, mutate }}>
      <FolderPage
        {...{
          folder,
          isLoading,
          error,
          mutate,
          revalidate,
        }}
      />
    </useCurrentFolderContext.ProvideContext>
  );
});

const FolderPage = ({ folder, isLoading, error, revalidate, mutate }: FolderPageProps) => {
  const { t } = useTranslationContext.useContext();
  const { checkPermission } = usePermissionsContext.useContext();

  const {
    isOpen: isCreateFolderModalVisible,
    open: openCreateFolderModal,
    close: closeCreateFolderModal,
  } = useToggle();
  const {
    isOpen: isCreateProjectModalVisible,
    open: openCreateProjectModal,
    close: closeCreateProjectModal,
  } = useToggle();
  const { openUpgradeModal } = useUpgradeModalToggle.useContext();

  const { removeCurrentFolder, renameCurrentFolder } = useCurrentFolderContext.useContext();
  const {
    data: projectsCount,
    revalidate: revalidateProjectsCount,
    mutate: mutateProjectsCount,
  } = useProjectsCount();

  const currentFolderPath = folder
    ? [...folder.parents, { _id: folder.id, name: folder.name, index: folder.parents.length }]
    : [];
  const breadcrumbs = currentFolderPath
    ? [
        { label: t('pages.projects.breadcrumb'), url: routes.projects },
        ...currentFolderPath
          .sort((a, b) => a.index - b.index)
          .map((folder) => ({
            label: folder?.name,
            url: dynamicRoutes.folder(folder._id),
          })),
      ]
    : [];

  const allowCreateProject = checkPermission(MyProjectPermission.create, {
    count: projectsCount || 0,
  });

  return (
    <MainLayout
      title={folder?.name || ''}
      analyticsName='Folder'
      error={error}
      navbarLeftComponent={breadcrumbs ? <Breadcrumbs items={breadcrumbs} /> : undefined}
      navbarRightComponent={
        folder && (
          <EditFolderNavbarActions
            onClickDelete={() => folder && removeCurrentFolder(folder.id)}
            onClickRename={renameCurrentFolder}
            openCreateFolderModal={allowCreateProject ? openCreateFolderModal : openUpgradeModal}
            openCreateProjectModal={allowCreateProject ? openCreateProjectModal : openUpgradeModal}
            revalidateResource={revalidate}
            {...{
              folder,
            }}
          />
        )
      }
      isLoading={isLoading}
      isPlanLimitReached={!allowCreateProject}
      availableOnMobile={false}>
      {folder?.children.length ? (
        <div className='my-20'>
          <ProjectsList
            items={folder.children}
            {...{ revalidate, revalidateProjectsCount, mutate, mutateProjectsCount }}
          />
        </div>
      ) : (
        <div className='h-screen flex flex-col items-center justify-center text-center'>
          <ProjectsEmptyStateIllustration />
          <div className='mt-10 flex flex-col items-center justify-center'>
            <h5>{t('empty-states.folder.title')}</h5>
            <p className='mt-2.5 mb-0 max-w-sm text-lg leading-5'>
              {t('empty-states.folder.description')}
            </p>
            <Button
              className='button__filled mt-10'
              onClick={() => (allowCreateProject ? openCreateProjectModal() : openUpgradeModal())}>
              <PlusSignIcon />
              <span className='px-5'>{t('common.create')}</span>
            </Button>
          </div>
        </div>
      )}
      {isCreateFolderModalVisible && (
        <CreateFolderModal
          closeModal={closeCreateFolderModal}
          isOpen={isCreateFolderModalVisible}
          onSuccess={revalidate}
          folder={folder}
        />
      )}

      {isCreateProjectModalVisible && (
        <CreateProjectTypeModal
          isOpen={isCreateProjectModalVisible}
          closeModal={closeCreateProjectModal}
          onSuccess={revalidate}
          parents={currentFolderPath}
        />
      )}
    </MainLayout>
  );
};
