import { ReactNode } from 'react';

import { WellDoneIllustration } from '../../../../assets/icons';

import styles from './SectionIntermediaryScreens.module.css';

interface SectionCongratulationsProps {
  imageSrc?: string;
  title: string;
  description?: string;
  children?: ReactNode;
}

export const SectionCongratulations = ({
  imageSrc,
  title,
  description,
}: SectionCongratulationsProps) => (
  <div className={styles.container}>
    <div className='w-full max-w-md text-center mt-20'>
      {imageSrc ? (
        <img
          src={imageSrc}
          title='Congratulation'
          className='cover'
          alt='Congratulation for finishing the task'
        />
      ) : (
        <div className='flex mx-auto justify-center items-center pl-12'>
          <WellDoneIllustration />
        </div>
      )}
      <h5 className='mt-10'>{title}</h5>
      {description && <div className='mt-2 text-main-contrast'>{description}</div>}
    </div>
  </div>
);
