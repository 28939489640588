import { useCallback, useState } from 'react';

export interface MultiStepService<T extends string, U = undefined> {
  step: T;
  context?: U;
  stepTo: (step: T, context?: U) => void;
  isStep: (step: T) => boolean;
}

export const useMultiStep = <T extends string, U = undefined>(
  initialStep: T,
  initialContext?: U
): MultiStepService<T, U> => {
  const [stepState, setStepState] = useState<{ step: T; context?: U }>({
    step: initialStep,
    context: initialContext,
  });

  const { step, context } = stepState;

  const isStep = useCallback((stepForCheck: T) => step === stepForCheck, [step]);

  const stepTo = useCallback((newStep: T, newContext?: U) => {
    setStepState({ step: newStep, context: newContext });
  }, []);

  return {
    step,
    context,
    stepTo,
    isStep,
  };
};
