import { Coordinates } from '../../../../services/position';
import {
  DesignPlatform,
  DesignPrototypeVersion,
  FigmaDesignPrototypeVersion,
} from '../../../services/api-iteration1/design-platforms';

import { FigmaPrototype } from './FigmaPrototype';

interface LivePrototypeProps {
  id: string;
  type: DesignPlatform;
  designPrototype: DesignPrototypeVersion;
  hotspotHints?: boolean;
  startingFrameId?: string;
  currentFrameId?: string;
  reachedEndFrame?: boolean;
  onChangeFrame?: (frameId: string) => void;
  onMissClick?: (click: Coordinates) => void;
  onInit?: () => void;
}

export const LivePrototype = ({
  id,
  type,
  designPrototype,
  hotspotHints,
  startingFrameId,
  currentFrameId,
  reachedEndFrame,
  onChangeFrame,
  onMissClick,
  onInit,
}: LivePrototypeProps) => {
  if (!currentFrameId) return null;

  if (type === DesignPlatform.Figma) {
    return (
      <FigmaPrototype
        designPrototype={designPrototype as FigmaDesignPrototypeVersion}
        {...{
          id,
          hotspotHints,
          startingFrameId,
          currentFrameId,
          reachedEndFrame,
          onChangeFrame,
          onMissClick,
          onInit,
        }}
      />
    );
  }

  return null;
};
