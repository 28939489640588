import { useMemo } from 'react';

import { useCurrentUserContext } from '../../../services/current-user';
import { useLocation, useQueryParams, useRouter } from '../../../../services/router';
import { validateEmail, isValidPassword } from '../../../services/validation';
import { useErrorHandlerContext } from '../../../../services/error-handling';
import { useTranslationContext } from '../../../../services/translation';
import { createQueryUrl } from '../../../../services/api/utils';

import { AuthError } from './components/AuthError';
import {
  Form,
  Button,
  createFormSubmitHandler,
  Navlink,
  FormError,
  FormField,
} from '../../../../components';
import { SeparatorLine } from '../../../../components/separator-line/SeparatorLine';

import { routes } from '../../../pages/routing/routes';
import { GoogleDarkIcon, Iteration1FullLogo } from '../../../assets/icons';

export const SignInForm = () => {
  const router = useRouter();
  const { search } = useLocation();
  const {
    params: { email },
  } = useQueryParams<{ email: string | undefined }>();
  const { t } = useTranslationContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();
  const { signInWithEmail, signInWithGoogle } = useCurrentUserContext.useContext();

  const initialUserValues = useMemo(
    () => ({
      email: email || '',
      password: '',
    }),
    [email]
  );

  const signIn = createFormSubmitHandler<typeof initialUserValues>({
    tryAction: ({ email, password }) => signInWithEmail(email, password),
    onSuccess: () => router.push(`${routes.home}${search}`),
  });

  return (
    <div className='flex flex-col w-full max-w-sm mt-32 mb-16 mx-4'>
      <div className='mb-10'>
        <Iteration1FullLogo />
      </div>
      <h3 className='mb-1 text-2xl text-main-contrast font-bold leading-8'>
        {t('pages.sign-in.welcome-back')}
      </h3>
      <p className='mb-10 text-base text-neutral-65 leading-5'>{t('pages.sign-in.subtitle')}</p>
      <Button
        className='w-full flex flex-row items-center justify-center py-3.5 border-2 border-main-highlight border-solid rounded-lg hover:enabled:bg-main-highlight'
        onClick={() => signInWithGoogle().catch(handleError)}>
        <GoogleDarkIcon />
        <span className='ml-5 text-base text-main-contrast leading-5 font-medium'>
          {t('pages.sign-in.google-sign-in')}
        </span>
      </Button>
      <div className='my-10'>
        <SeparatorLine content={t('common.or')} />
      </div>
      <Form initialValues={initialUserValues} onSubmit={signIn}>
        {({ status, setStatus, isValidForSubmit, isSubmitting }) => (
          <>
            <FormError {...{ status }} />
            <AuthError
              handleErrorCode={(errorCode) =>
                setStatus({ state: 'error', messageKeys: [`errors.firebase.${errorCode}`] })
              }
            />
            <FormField
              name='email'
              type='email'
              placeholder={t('form.fields.email-address')}
              label={t('form.fields.email-address')}
              validate={validateEmail}
              fieldClassName='mb-6'
              inputClassName='py-3 px-4 text-lg font-normal leading-normal text-main-contrast border-main-10 border-2 border-solid placeholder-neutral rounded-lg cursor-pointer hover:border-main-highlight focus:border-main-highlight focus:outline-none'
              labelClassName='text-sm text-main-contrast font-bold'
            />

            <FormField
              type='password'
              name='password'
              placeholder={t('form.fields.password')}
              label={t('form.fields.password')}
              validate={isValidPassword}
              fieldClassName='mb-6'
              inputClassName='py-3 px-4 text-lg font-normal leading-normal text-main-contrast border-main-10 border-2 border-solid placeholder-neutral rounded-lg cursor-pointer hover:border-main-highlight focus:border-main-highlight focus:outline-none'
              labelClassName='text-sm text-main-contrast font-bold'
            />
            <Button
              loading={isSubmitting}
              className='button__filled w-full justify-center h-12'
              type='submit'
              disabled={!isValidForSubmit}>
              {t('common.auth.sign-in')}
            </Button>
            <div className='flex flex-row items-center justify-between mt-10'>
              <Navlink
                to={createQueryUrl(routes.signUp, { email })}
                className='text-info border-b-default border-main-70 border-solid hover:text-info-70'>
                {t('pages.sign-in.account-create')}
              </Navlink>
              <Navlink
                to={routes.forgotPassword}
                className='text-info border-b-default border-main-70 border-solid hover:text-info-70'>
                {t('pages.sign-in.forgot-password')}
              </Navlink>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
