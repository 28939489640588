import {
  Frame,
  UsabilitySectionFiveSeconds,
  UsabilitySectionFiveSecondsAnswer,
} from '../../../../services/api-iteration1';
import { useTranslationContext } from '../../../../../services/translation';
import {
  FigmaDesignPrototypeVersion,
  FigmaFrame,
} from '../../../../services/api-iteration1/design-platforms';
import { getStoragePublicFileUrl } from '../../../../services/api-cloud-storage';
import { StorageFileType } from '../../../../services/api-cloud-storage/api-models';
import { useAnalyticsQueryParams } from '../../../../services/analytics';

import { LoadingOrContent } from '../../../../../components';

import { AnalyticsSectionContainer } from '../../../containers';
import {
  ClicksIcon,
  FiveSecondsIcon,
  PlaceholderSmallIcon,
  SectionsIcon,
  TimeIcon,
} from '../../../../assets/icons';
import { AnalyticsSummaryStatistic } from '../../../headers/AnalyticsSummaryStatistic';
import { UsabilityMetricsModal } from '../../../modals/UsabilityMetricsModal';

import styles from '../../../cards/ScreenAnalyticsCard.module.css';

interface AnalyticsFiveSecondsSummaryProps {
  answers: UsabilitySectionFiveSecondsAnswer[];
  section: UsabilitySectionFiveSeconds;
  sectionsCount: number;
  versionName?: string;
  designPrototype?: FigmaDesignPrototypeVersion;
  isLoadingResponses: boolean;
}

export const AnalyticsFiveSecondsSummary = ({
  answers,
  section,
  sectionsCount,
  versionName,
  designPrototype,
  isLoadingResponses,
}: AnalyticsFiveSecondsSummaryProps) => {
  const { t } = useTranslationContext.useContext();

  const { queryParams, setQueryParams } = useAnalyticsQueryParams();

  const openMetricsModal = () => {
    setQueryParams((prev) => ({ ...prev, metrics: section._id }));
  };

  const closeMetricsModal = () => {
    setQueryParams(undefined);
  };

  const image =
    section.others.type === 'image'
      ? section.files.find((f) => f.type === StorageFileType.FiveSecondsImage)
      : undefined;
  const imageUrl = image && getStoragePublicFileUrl(image.id);

  const frame = section.others.type === 'prototype' ? section.others.frame : undefined;
  const frameDetails: FigmaFrame | undefined = designPrototype?.frames.find(
    (item: Frame) => item.id === frame?.id
  );

  const frameUrl =
    frameDetails && frameDetails.image && getStoragePublicFileUrl(frameDetails.image.id);

  const formattedImageName = image && image.fileName?.split('.')[0];

  return (
    <AnalyticsSectionContainer
      icon={<FiveSecondsIcon />}
      rightSideInformation={
        <AnalyticsSummaryStatistic
          currentSectionIndex={section.index}
          answersCount={answers.length || 1}
          {...{ sectionsCount }}
        />
      }
      title={t('components.projects.analytics.responses.five-seconds.five-seconds-test')}>
      <LoadingOrContent isLoading={isLoadingResponses} className='pb-12'>
        <div className='w-full mt-10 mb-16'>
          {answers.length ? (
            <div className='flex flex-row justify-start items-center'>
              {image ? (
                <div className='max-w-[212px]'>
                  <img src={imageUrl} className='rounded' />
                </div>
              ) : frameDetails && frameDetails.image ? (
                <div className='w-28 border-solid border-default border-main-10'>
                  <img src={frameUrl} />
                </div>
              ) : (
                <div className='flex justify-center items-center bg-main-10 p-10 rounded-lg'>
                  <PlaceholderSmallIcon className='w-14 h-14' />
                </div>
              )}
              <div className='flex flex-row ml-20'>
                <div className='text-main-contrast'>
                  <p className='mb-3.5 text-sm leading-[18px]'>{t('common.duration')}</p>
                  <div className='flex flex-row items-center'>
                    <TimeIcon className='icon-stroke-circle-main-contrast icon-stroke-polyline-main-contrast mr-4' />
                    <span className='text-2xl font-bold'>{section.others.seconds}s</span>
                  </div>
                </div>
                <div className='h-14 w-0.5 mx-10 flex bg-main-10' />
                <div>
                  <p className='mb-3.5 text-sm leading-[18px] text-main-contrast'>
                    {t('common.metrics')}
                  </p>
                  <div className='flex flex-row items-start cursor-pointer'>
                    <SectionsIcon className='rotate-90 icon-info icon-stroke icon-stroke-circle-info mr-4' />
                    <span
                      className='text-info text-base font-medium pb-1 border-b-default border-solid border-main-10 hover:text-info-70'
                      onClick={openMetricsModal}>
                      {t('components.projects.analytics.responses.five-seconds.view-metrics')}
                    </span>
                  </div>
                </div>
                {queryParams.metrics === section._id && (
                  <UsabilityMetricsModal
                    isOpen
                    closeModal={closeMetricsModal}
                    title={frameDetails ? frameDetails.name : t('common.image')}
                    breadcrumbTitle={t(
                      'components.projects.analytics.responses.five-seconds.five-seconds-test'
                    )}
                    data={[
                      {
                        title: t('common.duration'),
                        color: 'main-contrast',
                        icon: 'time',
                        value: `${section.others.seconds}s`,
                      },
                      {
                        title: t('common.tester_plural'),
                        color: 'main-contrast',
                        icon: 'users',
                        value: answers.length || 1,
                      },
                    ]}
                    firstBreadcrumb={{
                      type: 'version',
                      versionName,
                    }}
                    frameOptions={[
                      {
                        id: 'all-clicks',
                        iconSelectedClass: 'icon-main-contrast',
                        text: t(
                          'components.projects.usability-testing.results.single-goal.screens.options.clicks'
                        ),
                        containerClassName: styles.container,
                        Icon: ClicksIcon,
                      },
                    ]}
                    screenName={formattedImageName || undefined}
                    {...{ answers, frameDetails, image }}
                  />
                )}
              </div>
            </div>
          ) : (
            <p className='text-center font-medium text-danger'>
              {t('components.projects.analytics.no-answers')}
            </p>
          )}
        </div>
      </LoadingOrContent>
    </AnalyticsSectionContainer>
  );
};
