export type BreadCrumbListItem = {
  label: string;
  url: string;
};

export type Breadcrumbs = {
  displayed: BreadCrumbListItem[];
  hidden?: BreadCrumbListItem[];
};

export const groupBreadcrumbs = (
  breadcrumbs: BreadCrumbListItem[],
  collapsedItemsCount: number
): Breadcrumbs => {
  const totalBreadcrumbItems = breadcrumbs.length;
  const breadcrumbItemsBeforeCollapsible = 0;

  const collapsedCount = !collapsedItemsCount
    ? 0
    : totalBreadcrumbItems > breadcrumbItemsBeforeCollapsible + collapsedItemsCount
    ? collapsedItemsCount
    : totalBreadcrumbItems - breadcrumbItemsBeforeCollapsible - 1;

  const newBreadcrumbList = [...breadcrumbs];

  const displayed = !collapsedCount
    ? breadcrumbs
    : ([
        ...newBreadcrumbList.slice(0, breadcrumbItemsBeforeCollapsible),
        collapsedCount ? { label: '...', url: '' } : undefined,
        ...newBreadcrumbList.slice(
          breadcrumbItemsBeforeCollapsible + collapsedCount,
          totalBreadcrumbItems
        ),
      ].filter(Boolean) as BreadCrumbListItem[]);

  const hidden = !collapsedCount
    ? []
    : [
        ...newBreadcrumbList.slice(
          breadcrumbItemsBeforeCollapsible,
          breadcrumbItemsBeforeCollapsible + collapsedCount
        ),
      ];

  return {
    displayed,
    hidden,
  };
};
