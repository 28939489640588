import {
  FetchOptions,
  ResourceServiceOptions,
} from '../../../../../../services/api/resource-fetch-factory';

import { apiIteration1, useIteration1Resource } from '../../../config';
import { DiscoveryVersionOutput } from './api-models';
import { endpoints } from './endpoints';

export * from './api-models';

export const useDiscoveryVersion = (
  discoveryId: string,
  options?: FetchOptions | undefined,
  config?: ResourceServiceOptions<any, any>
) => {
  return useIteration1Resource<DiscoveryVersionOutput>(
    endpoints.v1.versions(discoveryId),
    options || {},
    config
  );
};

export const publishDiscoveryVersion = (discoveryId: string, versionId: string) => {
  return apiIteration1.patch(endpoints.v1.publishVersion(discoveryId, versionId));
};
