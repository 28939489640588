import { useCallback } from 'react';

import {
  PrototypeClick,
  UsabilitySectionFirstClickAnswer,
  UsabilitySectionFirstClick,
  UsabilitySectionType,
} from '../../../../../services/api-iteration1';
import {
  DesignPrototypeVersion,
  FigmaFrame,
} from '../../../../../services/api-iteration1/design-platforms';
import { useTranslationContext } from '../../../../../../services/translation';
import { getClickPosition } from '../../../../../../services/position';
import { useShowInstructionsContext } from '../../../../../services/userTest';
import { StorageFileType } from '../../../../../services/api-cloud-storage/api-models';
import { allClasses } from '../../../../../../services/utilities/array';

import { FigmaPrototypeImage } from '../../../live-prototype/FigmaPrototypeImage';
import { SectionInstructions } from '../SectionInstructions';
import { PlaceholderSmallIcon } from '../../../../../assets/icons';

import styles from '../ViewSection.module.css';

interface ViewFirstClickProps {
  firstClickSection: UsabilitySectionFirstClick;
  currentSectionScreenIndex?: number;
  designPrototype?: DesignPrototypeVersion;
  isEmbeddedIframe?: boolean;
  onFinishAnswerScreen?: () => void;
  onSaveAnswerImmediately?: (
    sectionId: string,
    answer: Omit<UsabilitySectionFirstClickAnswer, 'duration'>
  ) => void;
}

const idImageContainer = 'first-click-section-image-container';

export const ViewFirstClick = ({
  firstClickSection,
  currentSectionScreenIndex,
  designPrototype,
  onFinishAnswerScreen,
  onSaveAnswerImmediately,
  isEmbeddedIframe,
}: ViewFirstClickProps) => {
  const { t } = useTranslationContext.useContext();
  const { isVisibleInstructionsAgain } = useShowInstructionsContext.useContext();

  const {
    _id,
    title,
    files,
    others: { frame, description },
  } = firstClickSection;

  const isInstructionsScreen = currentSectionScreenIndex === 0;

  const frameDetails: FigmaFrame | undefined = (designPrototype?.frames.find(
    (item) => item.id === frame?.id
  ) || designPrototype?.frames[0]) as FigmaFrame | undefined;

  const sectionImage = files.find((f) => f.type === StorageFileType.FirstClickImage);

  const saveAnswerAndContinue: React.MouseEventHandler<HTMLImageElement> = useCallback(
    (click) => {
      const clickPosition = getClickPosition(idImageContainer, click);

      const prototypeClick = {
        ...clickPosition,
        timestamp: Date.now(),
        type: 'missclick',
      } as PrototypeClick;

      onSaveAnswerImmediately?.(_id, {
        type: UsabilitySectionType.FirstClick,
        click: prototypeClick,
      });

      onFinishAnswerScreen?.();
    },
    [_id, onFinishAnswerScreen, onSaveAnswerImmediately]
  );

  return (
    <div className={styles.view_section_wrapper_no_size_limit}>
      {isInstructionsScreen || isVisibleInstructionsAgain ? (
        <SectionInstructions
          {...{ title, description }}
          label={t('common.task')}
          info={{
            messages: [
              t('components.projects.sections.first-click.view.instructions.info.part-one'),
              t('components.projects.sections.first-click.view.instructions.info.part-two'),
            ],
          }}
        />
      ) : frameDetails && frameDetails.image ? (
        <FigmaPrototypeImage
          imgFileId={frameDetails.image.id}
          width={frameDetails.bounds.width}
          onClick={saveAnswerAndContinue}
          containerId={idImageContainer}
        />
      ) : sectionImage ? (
        <FigmaPrototypeImage
          imgFileId={sectionImage.id}
          onClick={saveAnswerAndContinue}
          containerId={idImageContainer}
        />
      ) : (
        <div
          className={allClasses(
            isEmbeddedIframe
              ? styles.view_section_wrapper_with_top_margin_embedded
              : styles.view_section_wrapper_with_top_margin,
            'mt-0'
          )}>
          <div className='flex justify-center items-center bg-main-10 p-10 rounded-lg'>
            <PlaceholderSmallIcon className='w-10 h-10' />
          </div>
          <p className='h5 text-main-contrast mt-10'>
            {t('components.projects.sections.first-click.view.no-preview.title')}
          </p>
          <p className='body-l text-main-contrast mt-2'>
            {t('components.projects.sections.first-click.view.no-preview.subtitle')}
          </p>
        </div>
      )}
    </div>
  );
};
