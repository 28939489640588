import { useTranslationContext } from '../../../services/translation';

import { Button, Modal } from '../../../components';

import { CloseIcon } from '../../assets/icons';

interface DowngradePlanAlertModalProps {
  isOpen: boolean;
  planName: string;
  allowedProjectsCount: number;
  close: () => void;
  onRemoveProjects: () => void;
}

export const DowngradePlanAlertModal = ({
  isOpen,
  planName,
  allowedProjectsCount,
  close,
  onRemoveProjects,
}: DowngradePlanAlertModalProps) => {
  const { t } = useTranslationContext.useContext();

  return (
    <Modal
      title={t('pages.account.tabs.plan-billing.downgrade-plan-alert-modal.title')}
      isOpen={isOpen}
      onClose={close}
      variant='regular'
      closeModalComponent={<CloseIcon className='icon-hover-main-contrast' />}
      closeIconClassName='absolute top-5 right-5'>
      <div className='pt-10 pb-20 text-center'>
        <h2 className='my-10 text-main-contrast leading-8 text-2xl font-bold'>
          {t('pages.account.tabs.plan-billing.downgrade-plan-alert-modal.title', {
            planName,
          })}
        </h2>
        <div className='mb-10 text-main-contrast text-lg leading-6 font-medium'>
          {t('pages.account.tabs.plan-billing.downgrade-plan-alert-modal.description')}
          <p className='mt-10 text-danger-60'>
            {t('pages.account.tabs.plan-billing.downgrade-plan-alert-modal.message-part-one', {
              planName,
              projectsCount: allowedProjectsCount,
            })}
          </p>
          <p className='mb-0 text-danger-60'>
            {t('pages.account.tabs.plan-billing.downgrade-plan-alert-modal.message-part-two')}
          </p>
        </div>
        <div className='flex flex-row items-center justify-center'>
          <Button
            className='button__filled mr-4 py-2.5 px-10 justify-center bg-main border-2 border-solid border-main-10 hover:enabled:bg-main-10'
            type='button'
            onClick={onRemoveProjects}>
            {t('pages.account.tabs.plan-billing.downgrade-plan-alert-modal.button')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
