import { forwardRef } from 'react';

import { allClasses } from '../../services/utilities/array';

import { Navlink } from '..';
import { BreadCrumbListItem } from './utils';

interface BreadCrumbItem {
  breadcrumb: BreadCrumbListItem;
  onToggle: () => void;
  className?: string;
  disabled?: boolean;
}

export const BreadcrumbItem = forwardRef<HTMLAnchorElement, BreadCrumbItem>(
  ({ className, disabled, breadcrumb: { url, label }, onToggle }, ref) => (
    <li
      key={url}
      className={allClasses(
        'cursor-pointer text-xl leading-5',
        disabled && 'pointer-events-none',
        className
      )}>
      {url ? (
        <Navlink
          end
          to={url}
          innerRef={ref}
          className={allClasses(
            'no-underline font-bold block truncate max-w-full',
            disabled ? 'text-main-contrast' : 'text-neutral hover:text-main-contrast'
          )}>
          {label}
        </Navlink>
      ) : (
        <button
          className='px-2.5 py-0.5 no-underline font-bold text-neutral focus:outline-none transition duration-150 ease-in-out'
          onClick={onToggle}>
          {label}
        </button>
      )}
    </li>
  )
);
