import { useEffect, useRef } from 'react';

import { allClasses } from '../../services/utilities/array';

import { useToggle } from '../utilities/useToggle';

export interface Item {
  value: string;
  label: string;
  subLabel?: string;
  className?: string;
  disabled?: boolean;
}

interface RenderInput extends Omit<DropdownProps, 'renderInput' | 'selectItem'> {
  toggle(): void;
  open(): void;
  selectedIndex: number;
  autoComplete?: HTMLInputElement['autocomplete'];
}

export interface DropdownProps {
  placeholder: string;
  items: Item[];
  value: string | null | undefined;
  name?: string;
  className?: string;
  itemClassName?: string;
  dropdownClassName?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.ChangeEventHandler<HTMLInputElement>;
  selectItem(item: Item): void;
  isOpen?: boolean;
  renderInput: ({
    value,
    placeholder,
    toggle,
    open,
    selectedIndex,
    items,
    onChange,
    onBlur,
    isOpen,
  }: RenderInput) => JSX.Element;
  suffix?: React.ReactNode;
  suffixClassName?: string;
  suffixClassNameOpen?: string;
  dropdownOpenClassName?: string;
  subLabelClassName?: string;
  containerPosition?: string;
  onClick?: (e: React.SyntheticEvent) => void;
}

export const Dropdown = ({ renderInput, selectItem, ...props }: DropdownProps) => {
  const { isOpen, toggle, open, close } = useToggle();

  const thisRef = useRef<any>(null);

  const { items, value } = props;
  const selectedIndex = items.findIndex((item) => item.value === value);

  useEffect(() => {
    if (!thisRef.current) return;

    const closeOnOutsideClick = (e: Event) => {
      if (thisRef.current.contains(e.target)) return;
      close();
    };

    document.addEventListener('mousedown', closeOnOutsideClick);
    return () => document.removeEventListener('mousedown', closeOnOutsideClick);
  });

  return (
    <div className='relative' ref={thisRef}>
      {renderInput && renderInput({ ...props, toggle, open, selectedIndex, isOpen })}
      {isOpen && (
        <div
          className={allClasses(
            'absolute top-16 z-10 w-full max-h-48 scroll-container rounded-lg border-solid border-2 border-main-10',
            props.dropdownOpenClassName
          )}>
          {items.map((item) => (
            <a
              className={allClasses(
                'block cursor-pointer',
                props.itemClassName,
                item.className,
                item.disabled ? 'pointer-events-none cursor-text ' : undefined
              )}
              key={item.value}
              onClick={() => {
                selectItem(item);
                close();
              }}>
              {item.label}
              {item.subLabel && <span className={props.subLabelClassName}>{item.subLabel}</span>}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};
