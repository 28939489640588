import { useCallback, useState } from 'react';

interface SelectFilterService<T extends string> {
  inFocusFilterId: T | undefined;
  setFilterInFocus: (filterId: T | undefined) => void;
  resetFilterInFocus: () => void;
}

export const useSelectFilter = <T extends string>(): SelectFilterService<T> => {
  const [focusFilterId, setFocusFilterId] = useState<T | undefined>(undefined);

  const setFilterInFocus = useCallback((filterId: T | undefined) => {
    setFocusFilterId(filterId);
  }, []);

  const resetFilterInFocus = useCallback(() => {
    setFocusFilterId(undefined);
  }, []);

  return {
    inFocusFilterId: focusFilterId,
    setFilterInFocus,
    resetFilterInFocus,
  };
};
