import { useState } from 'react';

import { useTranslationContext } from '../../../../../services/translation';
import { VersionStatus } from '../../../../services/api-iteration1';

import { OptionsMenu, OptionsMenuItem, useToggle } from '../../../../../components';

import { RenameProjectModal } from '../../../modals/RenameProjectModal';
import { DeleteConfirmModal } from '../../../modals/DeleteConfirmModal';
import { EndTestModal } from '../../../modals/EndTestModal';
import { DotsButton } from '../../../buttons';

interface ProjectCardActionsProps {
  className?: string;
  onClick: () => void;
  isMenuOpen: boolean;
  onRemove: () => void;
  onRename: (name: string) => void;
  projectType: 'discovery' | 'folder' | 'userTest';
  status?: VersionStatus;
  onEndTest?: () => void;
}

export const ProjectCardActions = ({
  className,
  onClick,
  isMenuOpen,
  onRemove,
  onRename,
  projectType,
  onEndTest,
  status,
}: ProjectCardActionsProps) => {
  const { t } = useTranslationContext.useContext();
  const showEndTestOption = status === VersionStatus.Live;

  const {
    isOpen: isRenameModalVisible,
    open: openRenameModal,
    close: closeRenameModal,
  } = useToggle();
  const {
    isOpen: isDeleteConfirmModalVisible,
    open: openDeleteConfirmModal,
    close: closeDeleteConfirmModal,
  } = useToggle();
  const {
    isOpen: isEndTestModalVisible,
    open: openEndTestModal,
    close: closeEndTestModal,
  } = useToggle();

  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState<boolean>(false);

  const removeProject = () => {
    setDeleteButtonDisabled(true);
    onRemove();
    closeDeleteConfirmModal();
  };

  return (
    <>
      <div
        className={className}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClick();
        }}>
        <DotsButton {...{ isMenuOpen }} />
        {isMenuOpen && (
          <OptionsMenu id='project-card-actions' label='Project card actions'>
            <OptionsMenuItem text={t('common.rename')} onClick={openRenameModal} />
            {showEndTestOption && (
              <OptionsMenuItem
                text={t('components.projects.testing.end-test')}
                onClick={openEndTestModal}
              />
            )}
            <OptionsMenuItem
              className='text-danger-60'
              text={t('common.delete')}
              onClick={openDeleteConfirmModal}
            />
          </OptionsMenu>
        )}
      </div>

      {isRenameModalVisible && (
        <RenameProjectModal
          isOpen
          closeModal={closeRenameModal}
          onClickRename={onRename}
          projectType={projectType}
        />
      )}
      {isDeleteConfirmModalVisible && (
        <DeleteConfirmModal
          isOpen
          closeDeleteConfirmModal={closeDeleteConfirmModal}
          onRemove={removeProject}
          projectType={projectType}
          deleteButtonDisabled={deleteButtonDisabled}
        />
      )}
      {isEndTestModalVisible && <EndTestModal isOpen {...{ closeEndTestModal, onEndTest }} />}
    </>
  );
};
