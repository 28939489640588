import { isEmpty } from './value';

export const onlyMatchingKeys = <T extends Record<string, any>, U extends Record<string, any>>(
  obj: T,
  comparisonObject: U
): Extract<T, U> =>
  Object.keys(comparisonObject).reduce((result, key) => {
    if (obj.hasOwnProperty(key)) return { ...result, [key]: obj[key] };
    return result;
  }, {} as any);

export const allKeysToValue = (obj: Record<string, unknown>, value: any) =>
  Object.keys(obj).reduce((result, key) => ({ ...result, [key]: value }), {});

type NullPropertiesToValue<T extends Record<string, any>, U> = {
  [key in keyof T]: T[key] extends null ? U : T[key];
};

export const convertNullsToValue = <T extends Record<string, any>, U>(
  obj: T,
  value: U
): NullPropertiesToValue<T, U> =>
  Object.keys(obj).reduce(
    (result, key) => ({ ...result, [key]: obj[key] === null ? value : obj[key] }),
    {} as any
  );

export const getKeyForCondition = <T extends Record<string, boolean>>(args: T): keyof T => {
  const resultObj = Object.entries(args).find(([_, value]) => !!value);

  return resultObj ? resultObj[0] : '';
};

export type PartialNullable<T> = Partial<{
  [K in keyof T]: T[K] | null;
}>;

export const extractNonEmptyProps = (
  props: Record<string, any>
): Partial<Record<string, string>> => {
  return Object.entries(props).reduce(
    (result, [key, value]) => (isEmpty(value) ? result : { ...result, [key]: value }),
    {}
  );
};
