import { useRef, useState } from 'react';
import { PermissionPlans } from '@iteration1/permission-validators';

import { useErrorHandlerContext } from '../../../services/error-handling';
import { createSubscription, updateSubscription } from '../../services/api-iteration1/billing';
import {
  BillingPeriod,
  BillingUpdateInput,
  PaymentPlanOutput,
} from '../../services/api-iteration1/billing/api-models';
import { useCurrentUserContext } from '../../services/current-user';
import { useTranslationContext } from '../../../services/translation';
import { getStripePaymentErrorMessage } from '../../services/billing/payment';
import { usePromoCodeContext } from '../../services/billing/usePromoCode';
import { sendAnalyticsEvent } from '../../../services/app-analytics';
import { analyticsEvents } from '../../services/app-analytics/events';

import { MultiStepService, usePaymentInstance } from '../../../components';

import { CheckoutSteps } from '../config';
import { PaymentButton } from './PaymentButton';

interface SaveSubscriptionButtonProps {
  hasCurrentSubscription: boolean;
  plan: PaymentPlanOutput;
  billingPeriod: BillingPeriod;
  billingInfo: BillingUpdateInput | undefined;
  disabled?: boolean;
  stepTo: MultiStepService<
    CheckoutSteps,
    { clientSecret: string; invoiceId: string } | undefined
  >['stepTo'];
  onSuccess: () => void;
}

export const SaveSubscriptionButton = ({
  hasCurrentSubscription,
  plan,
  billingPeriod,
  billingInfo,
  disabled,
  stepTo,
  onSuccess,
}: SaveSubscriptionButtonProps) => {
  const { t } = useTranslationContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();
  const { reloadUser, currentUser } = useCurrentUserContext.useContext();
  const { promoCode } = usePromoCodeContext.useContext();

  const hasCreatedSubscription = useRef<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>();

  const paymentInstance = usePaymentInstance();

  const planPrice = plan.price.find((p) => p.recurring?.interval === billingPeriod);

  const saveSubscription = async () => {
    if (!planPrice || !paymentInstance) return;

    const subscriptionUpdates = {
      priceId: planPrice.id,
      promoCode: promoCode?.id,
      ...(billingInfo
        ? {
            address: billingInfo.address,
            company: billingInfo.company,
          }
        : {}),
    };

    try {
      let result;
      if (hasCurrentSubscription || hasCreatedSubscription.current) {
        result = await updateSubscription(subscriptionUpdates);
        const isBillingPeriodUpgrade =
          currentUser?.subscription?.plan.id === plan.id &&
          planPrice.recurring?.interval === 'year';
        const isPlanUpgrade =
          plan.name === PermissionPlans.IndividualPro &&
          currentUser?.subscription?.plan.name === PermissionPlans.IndividualBasic;
        const isUpgrade = isBillingPeriodUpgrade || isPlanUpgrade;

        sendAnalyticsEvent(
          isUpgrade
            ? analyticsEvents.upgradePlan({
                user_id: currentUser?.id as string,
                old_plan_name: currentUser?.subscription?.plan.name as string,
                new_plan_name: plan.name,
                billing_type: billingPeriod,
              })
            : analyticsEvents.downgradePlan({
                user_id: currentUser?.id as string,
                old_plan_name: currentUser?.subscription?.plan.name as string,
                new_plan_name: plan.name,
              })
        );
      } else {
        result = await createSubscription(subscriptionUpdates);
        sendAnalyticsEvent(
          analyticsEvents.upgradePlan({
            user_id: currentUser?.id as string,
            old_plan_name: 'Free plan',
            new_plan_name: plan.name,
            billing_type: billingPeriod,
          })
        );
        hasCreatedSubscription.current = true;
      }

      // wait for the subscription updates to be reflected
      setTimeout(() => reloadUser(), 2000);

      if (result.clientSecret && result.latestInvoiceId) {
        if (result.latestInvoicePaymentIntentStatus === 'requires_payment_method')
          return stepTo('payment', {
            clientSecret: result.clientSecret,
            invoiceId: result.latestInvoiceId,
          });

        if (result.latestInvoicePaymentIntentStatus === 'requires_action') {
          const { error } = await paymentInstance.confirmCardPayment(result.clientSecret);
          if (error) return setErrorMessage(getStripePaymentErrorMessage(error, t));
        }
      }

      onSuccess();
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <PaymentButton
      onClick={saveSubscription}
      text={t('pages.account.tabs.plan-billing.order-summary.pay')}
      {...{ disabled, errorMessage }}
    />
  );
};
