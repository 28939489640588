import { allClasses } from '../../../../../../services/utilities/array';

interface TextAnswerBlockProps {
  text: string;
  isActive: boolean;
  onClick: () => void;
}

export const TextAnswerBlock = ({ text, isActive, onClick }: TextAnswerBlockProps) => {
  return (
    <div
      className={allClasses(
        'w-full relative p-5 rounded-lg border-2 border-solid text-xl leading-30 text-main-contrast font-bold cursor-pointer',
        isActive ? 'border-main-highlight' : 'border-main-10'
      )}
      onClick={onClick}>
      {isActive && <div className='absolute inset-0 bg-main-highlight opacity-10 z-0' />}
      {text}
    </div>
  );
};
