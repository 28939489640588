import { ProjectCollaboratorsPermission } from '@iteration1/permission-validators';
import { useEffect, useState } from 'react';

import { useErrorHandlerContext } from '../../../../services/error-handling';
import {
  addCollaborator,
  removeCollaborator,
  removePendingCollaborator,
  temporaryCollaboratorId,
  updateCollaborator,
} from '../../../services/api-iteration1/collaborators';
import {
  CollaborationResource,
  Collaborator,
  CollaboratorRole,
  OwnershipRole,
} from '../../../services/api-iteration1/collaborators/api-models';
import { FolderChildType } from '../../../services/api-iteration1/folders';
import { usePermissionsContext } from '../../../services/authorization';

import { useResourceCollaboratorsList } from './useCurrentCollaborators';

interface CollaboratorsModalProps {
  projectDetails: {
    type: FolderChildType;
    id: string;
  };
  resourceOwner: Omit<Collaborator, 'role'> & { role: OwnershipRole };
  revalidateResource: () => void;
}

interface CollaboratorsModalService {
  showSearchEmptyState: boolean;
  showUpgradePlan: boolean;
  showOwner: boolean;
  sortedCollaborators: Collaborator[];
  projectCollaborators: Collaborator[];
  allowAddNewCollaborators: boolean;
  addNewCollaborator: (email: string, role: CollaboratorRole) => void;
  removeResourceCollaborator: (collaborator: Collaborator) => void;
  resendInvitation: (collaborator: Collaborator) => void;
  cancelInvitation: (collaborator: Collaborator) => void;
  handleKeyDown: (e: KeyboardEvent) => void;
  updateCollaboratorRole: (id: string, email: string, role: CollaboratorRole) => void;
  setSearchTerm: (term: string) => void;
}

export const useCollaboratorsModal = ({
  projectDetails,
  resourceOwner,
  revalidateResource,
}: CollaboratorsModalProps): CollaboratorsModalService => {
  const { checkPermission } = usePermissionsContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();

  const {
    sortedCollaborators,
    projectCollaborators,
    searchTerm,
    searchResults,
    mutateCollaboratorsLocally,
    setSearchTerm,
    revalidateCollaborators,
    revalidateProjectCollaborators,
  } = useResourceCollaboratorsList({
    resourceType: projectDetails.type,
    resourceId: projectDetails.id,
  });

  const [showSearchEmptyState, setShowSearchEmptyState] = useState<boolean>(false);
  const [showUpgradePlan, setShowUpgradePlan] = useState<boolean>(false);
  const [showOwner, setShowOwner] = useState<boolean>(true);

  const allowAddNewCollaborators = checkPermission(ProjectCollaboratorsPermission.create, {
    count: projectCollaborators.length,
  });

  const handleKeyDown = (e: KeyboardEvent) => {
    const clearSearchInput = e && !(e.target as HTMLInputElement).value && e.key === 'Backspace';
    if (!clearSearchInput) return;

    setShowSearchEmptyState(false);
    setShowUpgradePlan(false);
  };

  const getUpdatedResources = (
    email: string,
    resourceUpdates: Partial<CollaborationResource> | null
  ): CollaborationResource[] => {
    const collaborator = projectCollaborators.find((collab) => collab.email === email);
    if (!resourceUpdates)
      return (
        collaborator?.resources.filter(
          (resource) => resource.id !== projectDetails.id && resource.type !== projectDetails.type
        ) || []
      );

    return (
      collaborator?.resources.map((resource) =>
        resource.id === projectDetails.id && resource.type === projectDetails.type
          ? { ...resource, ...resourceUpdates }
          : resource
      ) || []
    );
  };

  const addNewCollaborator = async (email: string, role: CollaboratorRole) => {
    if (!projectDetails.id) return;

    const collaboratorId =
      sortedCollaborators.find((collab) => collab.email === email)?.id || temporaryCollaboratorId;

    try {
      const tempCollaborator: Collaborator = {
        id: collaboratorId,
        email,
        resources: [
          { id: projectDetails.id, ownerId: resourceOwner.id, type: projectDetails.type, role },
        ],
        isPending: true,
      };

      mutateCollaboratorsLocally({ collaboratorId }, tempCollaborator);
      setShowSearchEmptyState(false);
      setSearchTerm('');

      const newlyCreatedCollaborator = await addCollaborator(
        projectDetails.type,
        projectDetails.id,
        {
          email,
          role: role || CollaboratorRole.Viewer,
        }
      );

      mutateCollaboratorsLocally(
        { collaboratorId },
        { ...newlyCreatedCollaborator, isPending: newlyCreatedCollaborator.isPending || false }
      );
      revalidateResource();
    } catch (err) {
      handleError(err);
      revalidateCollaborators();
    }
  };

  const removeResourceCollaborator = async (collaborator: Collaborator) => {
    if (!projectDetails.id) return;

    try {
      const updatedResources = getUpdatedResources(collaborator.email, null);
      mutateCollaboratorsLocally(
        {
          collaboratorId: collaborator.id,
          resourceId: projectDetails.id,
          resourceType: projectDetails.type,
        },
        { resources: updatedResources }
      );
      await removeCollaborator(projectDetails.type, projectDetails.id, collaborator.id);
      revalidateResource();
    } catch (err) {
      handleError(err);
      revalidateCollaborators();
    }
  };

  const resendInvitation = async (collaborator: Collaborator) => {
    if (!projectDetails.id) return;

    try {
      await removePendingCollaborator(projectDetails.type, projectDetails.id, {
        email: collaborator.email,
      });

      await addCollaborator(projectDetails.type, projectDetails.id, {
        email: collaborator.email,
        role: CollaboratorRole.Editor,
      });

      revalidateResource();
    } catch (err) {
      handleError(err);
      revalidateCollaborators();
    }
  };

  const cancelInvitation = async (collaborator: Collaborator) => {
    if (!projectDetails.id) return;

    try {
      const updatedResources = getUpdatedResources(collaborator.email, null);
      mutateCollaboratorsLocally(
        {
          collaboratorId: collaborator.id,
          resourceId: projectDetails.id,
          resourceType: projectDetails.type,
        },
        { resources: updatedResources }
      );
      await removePendingCollaborator(projectDetails.type, projectDetails.id, {
        email: collaborator.email,
      });

      revalidateResource();
      revalidateProjectCollaborators();
    } catch (err) {
      handleError(err);
      revalidateCollaborators();
    }
  };

  const updateCollaboratorRole = async (id: string, email: string, role: CollaboratorRole) => {
    if (!projectDetails.id) return;

    const resourcesWithUpdates = getUpdatedResources(email, { role });

    try {
      mutateCollaboratorsLocally(
        { collaboratorId: id },
        {
          resources: resourcesWithUpdates,
        }
      );
      await updateCollaborator(projectDetails.type, projectDetails.id, {
        email,
        role,
      });
      revalidateResource();
    } catch (err) {
      handleError(err);
      revalidateCollaborators();
    }
  };

  useEffect(() => {
    setShowOwner(
      !searchTerm ? true : !!searchResults.find((result) => result.id === resourceOwner.id)
    );

    setShowSearchEmptyState(!!searchTerm && !searchResults.length);
    setShowUpgradePlan(!allowAddNewCollaborators && !searchResults.length && !!searchTerm);
  }, [allowAddNewCollaborators, resourceOwner.id, searchResults, searchTerm]);

  useEffect(() => {
    if (!searchResults && !allowAddNewCollaborators) setShowUpgradePlan(true);
  }, [allowAddNewCollaborators, searchResults]);

  return {
    sortedCollaborators,
    projectCollaborators,
    showOwner,
    showUpgradePlan,
    showSearchEmptyState,
    allowAddNewCollaborators,
    addNewCollaborator,
    removeResourceCollaborator,
    resendInvitation,
    cancelInvitation,
    updateCollaboratorRole,
    handleKeyDown,
    setSearchTerm,
  };
};
