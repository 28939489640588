import { useEffect } from 'react';

import styles from './MouseEffect.module.css';

export const hiddenMouseEffectClass = 'hidden_mouse_click_effect';

export const useMouseClickVisualEffect = (elementId: string) => {
  useEffect(() => {
    const elem = document.getElementById(elementId);

    if (!elem) return;

    const showMouseClickEffect = (e: MouseEvent) => {
      const positionTop = e.clientY + 'px';
      const positionLeft = e.clientX + 'px';

      const effectDiv = document.createElement('div');
      effectDiv.className = styles.clickEffect;
      effectDiv.style.top = positionTop;
      effectDiv.style.left = positionLeft;

      elem.appendChild(effectDiv);

      const hiddenEffectDiv = document.createElement('div');
      hiddenEffectDiv.className = `${styles.hiddenClickEffect} ${hiddenMouseEffectClass}`;
      hiddenEffectDiv.style.top = positionTop;
      hiddenEffectDiv.style.left = positionLeft;
      hiddenEffectDiv.style.visibility = 'hidden';

      elem.appendChild(hiddenEffectDiv);

      effectDiv.addEventListener('animationend', () => {
        effectDiv.parentElement?.removeChild(effectDiv);
        hiddenEffectDiv.parentElement?.removeChild(hiddenEffectDiv);
      });
    };

    elem.addEventListener('click', showMouseClickEffect);

    return () => {
      elem.removeEventListener('click', showMouseClickEffect);
    };
  });
};
