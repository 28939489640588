import { PermissionPlans } from '@iteration1/permission-validators';

import { useQueryParamsOnce } from '../../../services/router';
import { getKeyForCondition } from '../../../services/utilities/object';
import { createContextService } from '../../../services/context';

import { useCurrentUserContext } from '../current-user';

import { isSubscriptionPaymentDue } from '../api-iteration1/billing';

const usePaymentRedirectConfirmation = () => {
  const { currentUser } = useCurrentUserContext.useContext();

  const {
    params: { setup_intent, payment_intent, redirect_status, planName, planWasChanged },
    clearSavedParams,
  } = useQueryParamsOnce();

  const type = getKeyForCondition({
    success_setup_intent: !!setup_intent && redirect_status === 'succeeded',
    failure_setup_intent: !!setup_intent && redirect_status === 'failed',
    success_plan_change: (!!payment_intent && redirect_status === 'succeeded') || !!planWasChanged,
    payment_due: isSubscriptionPaymentDue(currentUser?.subscription),
  });

  return {
    type,
    planName: planName as PermissionPlans,
    clearRedirectionDetails: clearSavedParams,
  };
};

export const usePaymentRedirectConfirmationContext = createContextService(
  usePaymentRedirectConfirmation
);
