import { allClasses } from '../../services/utilities/array';

interface SeparatorLineProps {
  className?: string;
  content?: string;
  lineColor?: string;
}

export const SeparatorLine = ({ className, content, lineColor }: SeparatorLineProps) => {
  return (
    <div
      className={allClasses(
        'w-full relative border-default text-center',
        lineColor ? `border-${lineColor}` : 'border-main-10',
        className
      )}>
      {content && (
        <span className='absolute px-3.5 bg-main text-lg text-neutral leading-5 capitalize top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2'>
          {content}
        </span>
      )}
    </div>
  );
};
