import { PermissionPlans } from '@iteration1/permission-validators';

import { useTranslationContext } from '../../../services/translation';
import { useCurrentUserContext } from '../../services/current-user';
import { usePermissionsContext } from '../../services/authorization';
import {
  isSubscriptionActive,
  isSubscriptionCancelled,
  isSubscriptionPaymentDue,
  isSubscriptionReactivable,
  isSubscriptionUnavailable,
  reactivateSubscription,
} from '../../services/api-iteration1/billing';
import { useErrorHandlerContext } from '../../../services/error-handling';

import { Button } from '../../../components';

import { FormattedDate, Price } from '../formatters';
import { PaymentStatusCard } from '../cards';
import { PayDueSubscriptionButton } from '../buttons';

interface BillingHeaderProps {
  openCheckoutModal: (context?: { clientSecret: string; invoiceId: string }) => void;
}

export const BillingHeader = ({ openCheckoutModal }: BillingHeaderProps) => {
  const { t } = useTranslationContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();
  const { currentUser, reloadUser } = useCurrentUserContext.useContext();
  const { userPlan } = usePermissionsContext.useContext();

  const subscription = currentUser?.subscription;
  const subscriptionPrice =
    subscription && !isSubscriptionCancelled(subscription) ? subscription.price.value : 0;

  const displayPrice = <Price value={subscriptionPrice} />;
  const displayPeriod = t(
    `pages.account.tabs.plan-billing.order-summary.frequency-type.${
      subscription?.price?.recurring?.interval || 'month'
    }`
  );

  const reactivateUserSubscription = async () => {
    try {
      await reactivateSubscription();
      // wait for the payment changes to be reflected in our db
      setTimeout(() => reloadUser(), 2000);
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <div className='px-10'>
      <p className='text-xl text-main-contrast leading-30 font-bold mb-2.5'>
        {t(
          `pages.account.tabs.plan-billing.plans.${
            subscription && !isSubscriptionCancelled(subscription)
              ? subscription.plan.name
              : PermissionPlans.IndividualFree
          }.title`
        )}
      </p>
      <p className='text-lg text-neutral-60 leading-6'>
        {displayPrice} / {displayPeriod}
      </p>

      {[PermissionPlans.IndividualBasic, PermissionPlans.IndividualPro].includes(userPlan) && (
        <div className='mt-2.5 text-sm text-main-contrast leading-5'>
          {t('pages.account.tabs.plan-billing.additional-information-part-one')}
          <span className='font-extra-bold'>
            {t(`pages.account.tabs.plan-billing.plans.${userPlan}.title`)}
          </span>
          {subscription && (
            <>
              {t(
                `pages.account.tabs.plan-billing.additional-information-part-two${
                  isSubscriptionReactivable(subscription) ? '-canceled' : ''
                }`
              )}
              <span className='font-extra-bold'>
                <FormattedDate seconds={subscription.timeSubscriptionEnd} precision='PP' />
              </span>
              {'.'}
              <div>
                {t(
                  `pages.account.tabs.plan-billing.additional-information-part-three${
                    isSubscriptionReactivable(subscription) ? '-canceled' : ''
                  }`
                )}
              </div>
            </>
          )}
        </div>
      )}

      <PaymentStatusCard />

      <div className='flex flex-row my-10'>
        {isSubscriptionReactivable(subscription) && (
          <Button
            unstoppableLoading
            className='button__filled px-10 mr-5'
            onClick={reactivateUserSubscription}>
            <span>{t('pages.account.tabs.plan-billing.plans.reactivate-plan')}</span>
          </Button>
        )}

        {(isSubscriptionUnavailable(subscription) || isSubscriptionActive(subscription)) &&
          !isSubscriptionReactivable(subscription) && (
            <>
              {[PermissionPlans.IndividualFree, PermissionPlans.IndividualBasic].includes(
                userPlan
              ) && (
                <Button className='button__filled px-10 mr-5' onClick={() => openCheckoutModal()}>
                  <span>{t('pages.account.tabs.plan-billing.plans.upgrade-plan')}</span>
                </Button>
              )}
            </>
          )}

        {isSubscriptionPaymentDue(subscription) && subscription && (
          <div className='flex w-full justify-between'>
            <PayDueSubscriptionButton
              subscription={subscription}
              openCheckoutModal={openCheckoutModal}
            />
          </div>
        )}

        {(userPlan === PermissionPlans.IndividualPro ||
          isSubscriptionReactivable(subscription)) && (
          <Button className='button__ghost px-10' onClick={() => openCheckoutModal()}>
            <span>{t('pages.account.tabs.plan-billing.view-plans')}</span>
          </Button>
        )}
      </div>
    </div>
  );
};
