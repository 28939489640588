import { enumToValueArray } from '../../../../../../services/utilities/array';

import { DefaultSection, DefaultSectionType } from './common';
import { DiscoverySection, DiscoverySectionType } from './discovery';
import { UsabilitySection, UsabilitySectionType } from './usability';

export * from './common';
export * from './answers';
export * from './templates';
export * from './discovery';
export * from './usability';
export {
  temporarySectionId,
  isTemporarySection,
  temporaryFileId,
  isTemporaryFile,
  createIndexedTemporaryFile,
} from './utils';

export interface NewSectionInput {
  type: DiscoverySectionType | UsabilitySectionType;
}

type ExistingSections = DefaultSection | DiscoverySection | UsabilitySection;

export const isDefaultSection = (
  section: ExistingSections | undefined
): section is DefaultSection =>
  enumToValueArray(DefaultSectionType).includes(section?.type as DefaultSectionType);

export const isDiscoverySection = (
  section: ExistingSections | undefined
): section is DiscoverySection =>
  enumToValueArray(DiscoverySectionType).includes(section?.type as DiscoverySectionType);

export const isUsabilitySection = (
  section: ExistingSections | undefined
): section is UsabilitySection =>
  enumToValueArray(UsabilitySectionType).includes(section?.type as UsabilitySectionType);

export const isImageFiveSeconds = (
  section: ExistingSections | undefined
): section is UsabilitySection =>
  section?.type === UsabilitySectionType.FiveSeconds && section.others?.type === 'image';
