import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { isValidPassword, passwordsMatch } from '../services/validation/inputs';
import { useTranslationContext } from '../../services/translation';
import { confirmPasswordReset, verifyPasswordResetCode } from '../services/api-iteration1';
import { useCurrentUserContext } from '../services/current-user';
import { useQueryParams } from '../../services/router';
import { mapErrorToTranslationKeys } from '../../services/error-handling/utils';

import { NoFooterLayout } from '../components';
import {
  Button,
  createFormSubmitHandler,
  Form,
  FormError,
  FormField,
  Navlink,
} from '../../components';

import { routes } from './routing/routes';
import { Iteration1FullLogo } from '../assets/icons';

const initialValues = {
  password: '',
  repeatPassword: '',
};

export const ResetPassword = () => {
  const {
    params: { oobCode: resetCode },
  } = useQueryParams<{ oobCode: string | undefined }>();
  const { t } = useTranslationContext.useContext();
  const { isAuthenticated, signInWithEmail } = useCurrentUserContext.useContext();

  const [email, setEmail] = useState<string>();
  const [resetCodeError, setResetCodeError] = useState<string>();
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

  const disabled = !!resetCodeError || showSuccessMessage;

  const resetPassword = createFormSubmitHandler<typeof initialValues>({
    tryAction: async (values) => {
      if (!resetCode) return;

      await confirmPasswordReset(resetCode, values.password);
      if (email) await signInWithEmail(email, values.password);
    },
    onSuccess: () => setShowSuccessMessage(true),
  });

  useEffect(() => {
    (async () => {
      if (!resetCode) return;

      try {
        const email = await verifyPasswordResetCode(resetCode);
        setEmail(email);
      } catch (err: any) {
        setResetCodeError(mapErrorToTranslationKeys(err).pop());
      }
    })();
  }, [resetCode]);

  return (
    <NoFooterLayout
      analyticsName='Reset Password'
      title={t('pages.reset-password.title')}
      canAccessPage={!isAuthenticated}
      availableOnMobile={false}>
      <div className='flex flex-col w-full max-w-sm mb-16 mx-4'>
        <div className='mb-10'>
          <Iteration1FullLogo />
        </div>
        <h3 className='mb-1 text-2xl text-main-contrast font-bold leading-8'>
          {t('pages.reset-password.action')}
        </h3>
        <div className='mt-10'>
          {resetCode ? (
            <>
              <Form initialValues={initialValues} onSubmit={resetPassword}>
                {({ status, values, isValidForSubmit, isSubmitting }) => (
                  <>
                    <FormError externalErr={resetCodeError} {...{ status }} />
                    <FormField
                      type='password'
                      name='password'
                      placeholder={t('form.fields.new-password')}
                      label={t('form.fields.new-password')}
                      validate={isValidPassword}
                      fieldClassName='mb-6'
                      inputClassName='py-3 px-4 text-lg font-normal leading-normal text-main-contrast border-main-10 border-2 border-solid placeholder-neutral rounded-lg cursor-pointer hover:border-main-highlight focus:border-main-highlight focus:outline-none'
                      labelClassName='text-sm text-main-contrast font-bold'
                      {...{ disabled }}
                    />

                    <FormField
                      type='password'
                      name='repeatPassword'
                      placeholder={t('form.fields.confirm-password')}
                      label={t('form.fields.confirm-password')}
                      validate={(repeatPassword) => passwordsMatch(values.password, repeatPassword)}
                      fieldClassName='mb-6'
                      inputClassName='py-3 px-4 text-lg font-normal leading-normal text-main-contrast border-main-10 border-2 border-solid placeholder-neutral rounded-lg cursor-pointer hover:border-main-highlight focus:border-main-highlight focus:outline-none'
                      labelClassName='text-sm text-main-contrast font-bold'
                      {...{ disabled }}
                    />

                    {showSuccessMessage ? (
                      <div className='w-full py-3.5 text-base text-main text-center leading-5 bg-success rounded-lg'>
                        {t('pages.reset-password.success-message')}
                      </div>
                    ) : (
                      <Button
                        loading={isSubmitting}
                        type='submit'
                        className='button__filled w-full justify-center h-12'
                        disabled={!isValidForSubmit}>
                        {t('pages.reset-password.submit')}
                      </Button>
                    )}
                  </>
                )}
              </Form>
              <div className='mt-10 text-center'>
                <Navlink
                  to={routes.signIn}
                  className='text-info border-b-default border-main-70 border-solid hover:text-info-70'>
                  {t('common.auth.return-to-sign-in')}
                </Navlink>
              </div>
            </>
          ) : (
            <Navigate to={routes.home} />
          )}
        </div>
      </div>
    </NoFooterLayout>
  );
};
