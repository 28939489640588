import React from 'react';

import { allClasses } from '../../../services/utilities/array';
import { useTranslationContext } from '../../../services/translation';
import { VersionStatus } from '../../services/api-iteration1';

import { Button, Icon } from '../../../components';

import { PlayIcon, PreviewIcon } from '../../assets/icons';
import { ProjectTypeActionsButton } from '../buttons/ProjectTypeActionsButton';

interface EditProjectNavbarActionsProps {
  disabledTest: boolean;
  changeStatus: string;
  onClickPreview: () => void;
  onClickStartTesting: () => void;
  onClickRename: (name: string) => void;
  onClickDelete: () => void;
  projectType: string;
  status?: VersionStatus;
}

export const EditProjectNavbarActions = ({
  disabledTest,
  changeStatus,
  onClickPreview,
  onClickStartTesting,
  onClickDelete,
  onClickRename,
  projectType,
  status,
}: EditProjectNavbarActionsProps) => {
  const { t } = useTranslationContext.useContext();

  return (
    <>
      <div className='mr-5'>
        <span className='mr-2.5 text-base text-main-contrast font-medium leading-5'>
          {t(`components.projects.sections.${changeStatus}`)}
        </span>
        <span
          className={allClasses(
            'w-2 h-2 rounded-full inline-block',
            changeStatus === 'changes-saved'
              ? 'bg-main-highlight'
              : changeStatus === 'changes-not-saved'
              ? 'bg-danger'
              : changeStatus === 'changes-saving'
              ? 'bg-neutral'
              : undefined
          )}
        />
      </div>
      <Button className='mr-5' onClick={onClickPreview} disabled={disabledTest}>
        <Icon
          Svg={PreviewIcon}
          className='w-10 h-10 p-2 border-2 border-main-highlight border-solid rounded-lg hover:bg-main-highlight-30 hover:border-main-highlight-30'
        />
      </Button>
      <Button className='button__filled' onClick={onClickStartTesting} disabled={disabledTest}>
        <PlayIcon />
        <span className='px-5'>{t('components.projects.sections.start-testing')}</span>
      </Button>
      <ProjectTypeActionsButton
        onClickRename={onClickRename}
        projectType={projectType}
        onClickDelete={onClickDelete}
        {...{ status }}
      />
    </>
  );
};
