import { SectionsIcon, UsersIcon } from '../../assets/icons';

interface AnalyticsSummaryStatisticProps {
  answersCount: number;
  sectionsCount: number;
  currentSectionIndex: number;
}

export const AnalyticsSummaryStatistic = ({
  answersCount,
  sectionsCount,
  currentSectionIndex,
}: AnalyticsSummaryStatisticProps) => {
  return (
    <span className='flex flex-row justify-between flex-1'>
      <span className='flex flex-row'>
        <UsersIcon />
        <span className='inline-block ml-2'>{answersCount}</span>
      </span>
      <span className='flex flex-row ml-5'>
        <span className='w-[2px] h-5 inline-block bg-main-10'></span>
        <span className='flex flex-row items-center ml-5'>
          <SectionsIcon />
          <span className='inline-block ml-2'>{`${
            currentSectionIndex + 1
          } / ${sectionsCount}`}</span>
        </span>
      </span>
    </span>
  );
};
