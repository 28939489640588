import { useMemo } from 'react';

import {
  Frame,
  UsabilitySectionFirstClickAnswer,
  UsabilitySectionFirstClick,
} from '../../../../services/api-iteration1';
import { useTranslationContext } from '../../../../../services/translation';
import {
  FigmaDesignPrototypeVersion,
  FigmaFrame,
} from '../../../../services/api-iteration1/design-platforms';
import { getStoragePublicFileUrl } from '../../../../services/api-cloud-storage';
import { StorageFileType } from '../../../../services/api-cloud-storage/api-models';
import { useAnalyticsQueryParams } from '../../../../services/analytics';

import { LoadingOrContent } from '../../../../../components';

import { AnalyticsSectionContainer } from '../../../containers';
import {
  FirstClickIcon,
  FirstClickScreenIcon,
  PlaceholderSmallIcon,
  SectionsIcon,
  TimeIcon,
} from '../../../../assets/icons';
import { AnalyticsSummaryStatistic } from '../../../headers/AnalyticsSummaryStatistic';
import { UsabilityMetricsModal } from '../../../modals/UsabilityMetricsModal';

import styles from '../../../cards/ScreenAnalyticsCard.module.css';

interface AnalyticsFirstClickSummaryProps {
  answers: UsabilitySectionFirstClickAnswer[];
  section: UsabilitySectionFirstClick;
  sectionsCount: number;
  versionName?: string;
  designPrototype?: FigmaDesignPrototypeVersion;
  isLoadingResponses: boolean;
}

export const AnalyticsFirstClickSummary = ({
  answers,
  section,
  sectionsCount,
  versionName,
  designPrototype,
  isLoadingResponses,
}: AnalyticsFirstClickSummaryProps) => {
  const { t } = useTranslationContext.useContext();

  const { queryParams, setQueryParams } = useAnalyticsQueryParams();

  const openMetricsModal = () => {
    setQueryParams({ metrics: section._id });
  };

  const closeMetricsModal = () => {
    setQueryParams(undefined);
  };

  const {
    files,
    others: { frame, type },
  } = section;

  const image =
    type === 'image' ? files.find((f) => f.type === StorageFileType.FirstClickImage) : undefined;
  const imageUrl = image && getStoragePublicFileUrl(image.id);

  const averageTimeForFirstClick = useMemo(() => {
    let duration = 0;

    answers.forEach((answer: UsabilitySectionFirstClickAnswer) => {
      if (answer.duration) {
        duration += answer.duration;
      }
    });

    return (duration / 1000 / answers.length).toFixed(0);
  }, [answers]);

  const frameDetails: FigmaFrame | undefined = designPrototype?.frames.find(
    (item: Frame) => item.id === frame?.id
  );

  const frameUrl =
    frameDetails && frameDetails.image && getStoragePublicFileUrl(frameDetails.image.id);
  const formattedImageName = image && image.fileName?.split('.')[0];

  return (
    <AnalyticsSectionContainer
      contentTitle={section.title}
      description={section.others?.description as string}
      icon={<FirstClickIcon />}
      rightSideInformation={
        <AnalyticsSummaryStatistic
          currentSectionIndex={section.index}
          answersCount={answers.length || 1}
          {...{ sectionsCount }}
        />
      }
      title={t('components.projects.sections.field-types.firstClick')}>
      <LoadingOrContent isLoading={isLoadingResponses} className='pb-12'>
        <div className='w-full mt-10 mb-16'>
          {answers.length ? (
            <div className='flex flex-row justify-start items-center'>
              {frameDetails && frameDetails.image ? (
                <div className='w-28 border-solid border-default border-main-10'>
                  <img src={frameUrl} />
                </div>
              ) : image ? (
                <div className='max-w-[212px]'>
                  <img src={imageUrl} className='rounded' />
                </div>
              ) : (
                <div className='flex justify-center items-center bg-main-10 p-10 rounded-lg'>
                  <PlaceholderSmallIcon className='w-14 h-14' />
                </div>
              )}
              <div className='flex flex-row ml-20'>
                <div className='text-main-contrast'>
                  <p className='mb-3.5 text-sm leading-[18px]'>
                    {t('components.projects.analytics.responses.first-click.average-time')}
                  </p>
                  <div className='flex flex-row items-center'>
                    <TimeIcon className='icon-stroke-circle-main-contrast icon-stroke-polyline-main-contrast mr-4' />
                    <span className='text-2xl font-bold'>{averageTimeForFirstClick}s</span>
                  </div>
                </div>
                <div className='h-14 w-0.5 mx-10 flex bg-main-10' />
                <div>
                  <p className='mb-3.5 text-sm leading-[18px] text-main-contrast'>
                    {t('common.metrics')}
                  </p>
                  <div className='flex flex-row items-start cursor-pointer'>
                    <SectionsIcon className='rotate-90 icon-info icon-stroke icon-stroke-circle-info mr-4' />
                    <span
                      className='text-info text-base font-medium pb-1 border-b-default border-solid border-main-10 hover:text-info-70'
                      onClick={openMetricsModal}>
                      {t('components.projects.analytics.responses.first-click.view-metrics')}
                    </span>
                  </div>
                </div>
                {queryParams.metrics === section._id && (
                  <UsabilityMetricsModal
                    isOpen
                    closeModal={closeMetricsModal}
                    title={frameDetails ? frameDetails.name : t('common.image')}
                    breadcrumbTitle={t('components.projects.sections.field-types.firstClick')}
                    data={[
                      {
                        title: t(
                          'components.projects.analytics.responses.first-click.average-time'
                        ),
                        color: 'main-contrast',
                        icon: 'time',
                        value: `${averageTimeForFirstClick}s`,
                      },
                      {
                        title: t('common.user_plural'),
                        color: 'main-contrast',
                        icon: 'users',
                        value: answers.length || 1,
                      },
                    ]}
                    firstBreadcrumb={{
                      type: 'version',
                      versionName,
                    }}
                    frameOptions={[
                      {
                        id: 'first-click',
                        iconSelectedClass:
                          'icon-stroke-circle-main-contrast icon-stroke-polyline-main-contrast',
                        text: t(
                          'components.projects.usability-testing.results.single-goal.screens.options.first-click'
                        ),
                        containerClassName: styles.container,
                        Icon: FirstClickScreenIcon,
                      },
                    ]}
                    screenName={formattedImageName || undefined}
                    {...{ answers, frameDetails, image }}
                  />
                )}
              </div>
            </div>
          ) : (
            <p className='text-center font-medium text-danger'>
              {t('components.projects.analytics.no-answers')}
            </p>
          )}
        </div>
      </LoadingOrContent>
    </AnalyticsSectionContainer>
  );
};
