export const endpoints = {
  v1: {
    discoveries: '/v1/discoveries',
    discovery: (discoveryId: string) => `/v1/discoveries/${discoveryId}`,
    discoveryVersion: (discoveryId: string, versionId: string) =>
      `/v1/discoveries/${discoveryId}/versions/${versionId}`,
    versions: (discoveryId: string) => `/v1/discoveries/${discoveryId}/versions`,
    endDiscovery: (discoveryId: string, versionId: string) =>
      `/v1/discoveries/${discoveryId}/versions/${versionId}/unpublish`,
  },
};
