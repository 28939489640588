import React, { useState } from 'react';

import { allClasses } from '../../services/utilities/array';
import { ClientError } from '../../services/error-handling/errors/ClientError';

import { Spinner } from '../loading-indicator';
import { useToggle } from '../utilities';

import { DefaultAvatarIcon, RetryUploadIcon, UploadSuccess } from '../../domain/assets/icons';

import styles from './Upload.module.css';

interface UploadProfileImageProps {
  title: string;
  placeholderImgSrc?: string;
  excludeFromForm?: boolean;
  fileSizeLimit?: number;
  upload: (image: File) => Promise<void>;
  onUploadSuccess?: (image: File) => void;
  onUploadError?: (err: any) => void;
}

type UploadStatus = 'success' | 'error' | 'uploading' | 'idle';

export const UploadProfileImage = ({
  title,
  placeholderImgSrc,
  excludeFromForm,
  fileSizeLimit,
  upload,
  onUploadSuccess,
  onUploadError,
}: UploadProfileImageProps) => {
  const [image, setImage] = useState<File>();
  const [imageStream, setImageStream] = useState<string | null | undefined>();
  const [uploadStatus, setUploadingStatus] = useState<UploadStatus>('idle');
  const {
    isOpen: validUserImage,
    open: setValidUserImage,
    close: setInvalidUserImage,
  } = useToggle(true);

  const imageSrc = imageStream || placeholderImgSrc;

  const uploadImage = async (image: File) => {
    try {
      setUploadingStatus('uploading');
      await upload(image);
      setUploadingStatus('success');
      onUploadSuccess?.(image);
      setValidUserImage();
      setTimeout(() => setUploadingStatus('idle'), 1000);
    } catch (err) {
      setUploadingStatus('error');
      onUploadError?.(err);
    }
  };

  const selectImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (excludeFromForm) e.stopPropagation();

    const image = e?.target?.files?.[0];
    if (!image) return;
    if (fileSizeLimit && image.size > fileSizeLimit)
      return onUploadError?.(new ClientError('errors.validation.image-size'));

    setImage(image);

    const reader = new FileReader();
    reader.onload = function (e) {
      setImageStream(e.target?.result as string);
    };

    reader.readAsDataURL(image);

    uploadImage(image);
  };

  return (
    <div className='flex items-center justify-between my-2'>
      {imageSrc && validUserImage ? (
        <div className='relative h-20 min-h-20 w-20 min-w-20 rounded-full overflow-hidden'>
          {imageSrc && (
            <img
              src={imageSrc}
              title={image?.name}
              className={allClasses(
                'h-full w-full object-cover overflow-hidden',
                uploadStatus !== 'idle' && 'opacity-50'
              )}
              onError={setInvalidUserImage}
            />
          )}
          <div className='absolute inset-0'>
            <div className='w-full h-full flex justify-center items-center'>
              {uploadStatus === 'uploading' ? (
                <Spinner className='icon__small' />
              ) : uploadStatus === 'error' ? (
                <RetryUploadIcon
                  className='icon__small cursor-pointer'
                  onClick={() => image && uploadImage(image)}
                />
              ) : uploadStatus === 'success' ? (
                <UploadSuccess className='icon__small' />
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <DefaultAvatarIcon className='w-20 h-20' />
      )}
      <div className='relative cursor-pointer'>
        <span className='ml-10 text-info border-b-default border-solid border-main-10 cursor-pointer hover:text-info-70'>
          {title}
        </span>
        <input
          type='file'
          accept='image/png, image/jpeg'
          className={styles.input}
          onChange={selectImage}
        />
      </div>
    </div>
  );
};
