import { allClasses } from '../../services/utilities/array';

import { Button } from '..';
import { Dropdown, DropdownProps } from './Dropdown';

export const DropdownSelect = (props: Omit<DropdownProps, 'renderInput'>) => {
  return (
    <Dropdown
      {...props}
      renderInput={({ value, placeholder, toggle, className, selectedIndex, items, isOpen }) => (
        <Button
          aria-haspopup='true'
          aria-controls='dropdown-menu'
          onClick={(e) => {
            props.onClick?.(e);
            toggle();
          }}
          className={allClasses(
            className,
            props.dropdownClassName,
            isOpen ? 'text-main-contrast' : 'text-neutral-20'
          )}>
          <div>
            {value && items[selectedIndex] ? (
              items[selectedIndex].label
            ) : (
              <span className={isOpen ? props.dropdownOpenClassName : 'text-neutral-30'}>
                {placeholder}
              </span>
            )}
          </div>
          <span className={allClasses(props.suffixClassName, isOpen && props.suffixClassNameOpen)}>
            {props.suffix}
          </span>
        </Button>
      )}
    />
  );
};
