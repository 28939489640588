import { ReactNode, useMemo } from 'react';
import { DragDropContext, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';

import { useDragArea } from './components';

interface DropAreaProps {
  id: string;
  onDragEnd(result: DropResult, provided: ResponderProvided): void;
  type?: string;
  className?: string;
  children?: ReactNode;
}

const DropArea = ({ id, type, onDragEnd, className, children }: DropAreaProps) => {
  const { setItemIdToBeDragged } = useDragArea.useContext();

  return useMemo(
    () => (
      <DragDropContext
        onDragEnd={(...props) => {
          onDragEnd(...props);
          setItemIdToBeDragged(undefined);
        }}
        onBeforeCapture={({ draggableId }) => setItemIdToBeDragged(draggableId)}>
        <Droppable droppableId={id} {...{ type }}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps} {...{ className }}>
              {children}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    ),
    [children, className, id, onDragEnd, setItemIdToBeDragged, type]
  );
};

const DropAreaWithContext = (props: DropAreaProps) => (
  <useDragArea.ProvideContext>
    <DropArea {...props} />
  </useDragArea.ProvideContext>
);

export { DropAreaWithContext as DropArea };
