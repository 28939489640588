import { useTranslationContext } from '../../../services/translation';
import {
  BillingPeriod,
  InvoiceOutput,
  PaymentPlanOutput,
  PriceOutput,
  PromoCodeOutput,
} from '../../services/api-iteration1/billing/api-models';
import { allClasses } from '../../../services/utilities/array';
import { getPaymentDetails } from '../../services/billing/payment';

import { Divider, Icon } from '../../../components';
import { SeparatorLine } from '../../../components/separator-line/SeparatorLine';
import { Tooltip } from '../../../components/tooltip/Tooltip';

import { InfoIcon, ReceiptIcon } from '../../assets/icons';
import { Price } from '../formatters';
import { PromoCodeButton } from '../buttons';

interface PaymentSummaryCardProps {
  plan: PaymentPlanOutput;
  billingPeriod: BillingPeriod;
  planPrice: PriceOutput;
  invoice: InvoiceOutput | undefined;
  isLoading: boolean;
  waitForData?: boolean;
  promoCode?: PromoCodeOutput;
  setPromoCode?: (promoCode: PromoCodeOutput | undefined) => void;
  button: (disabled: boolean) => React.ReactNode;
}

export const PaymentSummaryCard = ({
  plan,
  planPrice,
  billingPeriod,
  invoice,
  isLoading,
  waitForData,
  promoCode,
  setPromoCode,
  button,
}: PaymentSummaryCardProps) => {
  const { t } = useTranslationContext.useContext();

  const paymentFrequency = planPrice?.recurring?.intervalCount;

  const {
    initialPlanPrice = 0,
    initialPlanProrataPrice,
    tax,
    promoDiscount = 0,
    credit,
    creditDueNow,
    billedNow,
    remainingCredit,
    differentPaymentsLater,
    isProrata,
    isDiscountable,
  } = getPaymentDetails({
    planPrice,
    invoice,
  });

  return (
    <div className='flex flex-col w-96 p-4 rounded-lg border-main-10 border-solid border-2 h-fit'>
      <div className='flex'>
        <ReceiptIcon />
        <p className='ml-2 font-bold'>{t('pages.account.tabs.plan-billing.order-summary.title')}</p>
      </div>
      <Divider className='px-2 bg-main-10' />
      <div className='flex flex-col flex-1 px-3'>
        {!!credit && (
          <>
            <div className='flex justify-between items-center mt-2 text-billing'>
              <div className='flex items-center'>
                <p>{t('pages.account.tabs.plan-billing.order-summary.available-credit')}</p>
                <Tooltip
                  direction='bottom'
                  className='bg-white text-main-contrast border-2 border-main-10 -m-16 w-72 whitespace-pre-wrap'
                  content={
                    <p className='text-main-contrast m-2 text-left'>
                      {t('pages.account.tabs.plan-billing.order-summary.available-credit-info')}
                    </p>
                  }>
                  <Icon
                    Svg={InfoIcon}
                    className={allClasses('icon__small ml-2 icon-stroke-circle-neutral-30')}
                  />
                </Tooltip>
              </div>
              <p>
                <Price value={credit} precision={2} isLoading={isLoading} />
              </p>
            </div>

            <SeparatorLine className='border-0 h-px bg-main-10 mt-5' />
          </>
        )}

        <div className='flex justify-between items-center mt-5'>
          <div>
            <p>
              {`${t(`pages.account.tabs.plan-billing.plans.${plan.name}.title`)} - ${t(
                `pages.account.tabs.plan-billing.period.${billingPeriod}`
              )}`}
            </p>
            <p className='text-neutral text-xs'>
              {billingPeriod === 'year'
                ? `( ${t(
                    `pages.account.tabs.plan-billing.order-summary.plan-subtitle.yearly${
                      isProrata ? '-prorata' : ''
                    }`,
                    {
                      monthlyPrice: initialPlanPrice / 12 / 100,
                    }
                  )} )`
                : `( ${t(
                    `pages.account.tabs.plan-billing.order-summary.plan-subtitle.monthly${
                      isProrata ? '-prorata' : ''
                    }`,
                    {
                      monthlyPrice: initialPlanPrice / 100,
                    }
                  )} )`}
            </p>
          </div>
          <p>
            <Price value={isProrata ? initialPlanProrataPrice : initialPlanPrice} precision={2} />
          </p>
        </div>
        {waitForData ? (
          <SeparatorLine className='border-0 h-px bg-main-10 mt-5' />
        ) : (
          <>
            <PromoCodeButton
              isDiscountable={!!isDiscountable}
              promoCode={promoCode}
              setPromoCode={setPromoCode}
              billedAmount={billedNow}
              value={promoDiscount}
              isLoading={isLoading}
            />

            <div className='flex justify-between items-center mt-5 text-main-contrast'>
              <div className='flex items-center'>
                <p>{t('pages.account.tabs.plan-billing.order-summary.tax')}</p>
                <Tooltip
                  direction='bottom'
                  className='bg-white text-main-contrast border-2 border-main-10 -m-10'
                  content={
                    <div className='m-2'>
                      <p className='text-main-contrast'>
                        {t('pages.account.tabs.plan-billing.order-summary.tax-info1')}
                      </p>
                      <p className='text-main-contrast'>
                        {t('pages.account.tabs.plan-billing.order-summary.tax-info2')}
                      </p>
                    </div>
                  }>
                  <Icon
                    Svg={InfoIcon}
                    className={allClasses('icon__small ml-2 icon-stroke-circle-neutral-30')}
                  />
                </Tooltip>
              </div>
              <p>
                <Price value={tax} precision={2} isLoading={isLoading} />
              </p>
            </div>

            {!!credit && (
              <div className='flex justify-between items-center mt-5 text-billing'>
                <div className='flex items-center'>
                  <p>{t('pages.account.tabs.plan-billing.order-summary.credited-now')}</p>
                  <Tooltip
                    direction='bottom'
                    className='bg-white text-main-contrast border-2 border-main-10 -m-6'
                    content={
                      <p className='text-main-contrast m-2'>
                        {t('pages.account.tabs.plan-billing.order-summary.credited-now-info')}.
                      </p>
                    }>
                    <Icon
                      Svg={InfoIcon}
                      className={allClasses('icon__small ml-2 icon-stroke-circle-neutral-30')}
                    />
                  </Tooltip>
                </div>
                <p>
                  <Price value={-creditDueNow} precision={2} isLoading={isLoading} />
                </p>
              </div>
            )}

            <SeparatorLine className='border-0 h-px bg-main-10 mt-5' />

            <div className='flex justify-between items-center mt-5 font-bold'>
              <div>
                <p>{t('pages.account.tabs.plan-billing.order-summary.billed-now')}</p>
                {!!remainingCredit ? (
                  <p className='text-neutral text-xs'>
                    (
                    {t('pages.account.tabs.plan-billing.order-summary.remaining-credit', {
                      value: remainingCredit / 100,
                    })}
                    )
                  </p>
                ) : (
                  !differentPaymentsLater && (
                    <p className='text-neutral text-xs'>
                      ({t('pages.account.tabs.plan-billing.order-summary.frequency')}{' '}
                      {t(
                        `pages.account.tabs.plan-billing.order-summary.frequency-type.${billingPeriod}`,
                        {
                          count: paymentFrequency,
                        }
                      )}
                      )
                    </p>
                  )
                )}
              </div>
              <p>
                <Price value={billedNow} precision={2} isLoading={isLoading} />
              </p>
            </div>
          </>
        )}

        <div className='flex flex-1 flex-col justify-end mt-8 mb-6'>{button(isLoading)}</div>
      </div>
    </div>
  );
};
