import { UrlParams } from '../../../services/api/utils';
import { useQueryParams } from '../../../services/router';

import { FolderChildType, ProjectType } from '../api-iteration1';

export type AnalyticsResponsesFiltersTypes = 'all' | 'direct' | 'indirect' | 'bounce';

export interface DiscoveryAnalyticsQueryParams extends UrlParams {
  metrics?: string;
  responseFilter: undefined;
  screenFilter?: 'first-click';
}

export interface UserTestAnalyticsQueryParams extends UrlParams {
  metrics?: string;
  screenId?: string;
  scoreFilter?: string;
  screenFilter?: 'all-clicks' | 'first-click';
  videoModal?: string;
  responseFilter?: AnalyticsResponsesFiltersTypes;
}

export const useAnalyticsQueryParams = <T extends ProjectType>() => {
  const { params: queryParams, setQueryParams } =
    useQueryParams<
      T extends FolderChildType.Discovery
        ? DiscoveryAnalyticsQueryParams
        : T extends FolderChildType.UserTest
        ? UserTestAnalyticsQueryParams
        : DiscoveryAnalyticsQueryParams | UserTestAnalyticsQueryParams
    >();

  return { queryParams, setQueryParams };
};
