import { DesignPlatform } from '../../../design-platforms';
import { SectionStorageFile } from './common';
import { SectionPropertyOthers } from './utils';

export enum UsabilitySectionType {
  SingleGoal = 'singleGoal',
  MultipleGoals = 'multipleGoals',
  FreePlay = 'freePlay',
  FirstClick = 'firstClick',
  FiveSeconds = '5seconds',
}

export interface Frame {
  id: string;
  index: number;
}

export interface Path {
  index: number;
  frames: Frame[];
}

export type Goal = {
  title: string;
  description?: string;
  paths: Path[];
};

export interface UsabilitySectionInput<
  T extends SectionPropertyOthers<any> = SectionPropertyOthers<any>
> {
  title?: string;
  files?: SectionStorageFile[];
  others: T;
}

export interface UsabilitySection {
  _id: string;
  index: number;
  type: UsabilitySectionType;
  designPlatform?: DesignPlatform;
  files: SectionStorageFile[];
  others: SectionPropertyOthers<Goal | Path | Frame>;
  required?: boolean;
}

export interface UsabilitySectionSingleGoal extends UsabilitySection {
  index: number;
  designPlatform: DesignPlatform;
  type: UsabilitySectionType.SingleGoal;
  others: Goal;
}

export interface UsabilitySectionMultipleGoals extends UsabilitySection {
  index: number;
  type: UsabilitySectionType.MultipleGoals;
  designPlatform: DesignPlatform;
  others: {
    goals: Goal[];
  };
}

export interface UsabilitySectionFreePlay extends UsabilitySection {
  index: number;
  type: UsabilitySectionType.FreePlay;
  designPlatform: DesignPlatform;
  others: {
    description?: string;
    frame?: Frame;
  };
}

export interface UsabilitySectionFirstClick extends UsabilitySection {
  index: number;
  title: string;
  type: UsabilitySectionType.FirstClick;
  others: {
    type: 'image' | 'prototype';
    description?: string;
    frame?: Frame;
  };
}

export interface UsabilitySectionFiveSeconds extends UsabilitySection {
  index: number;
  title: string;
  type: UsabilitySectionType.FiveSeconds;
  image?: undefined;
  required?: boolean;
  others: {
    type: 'image' | 'prototype';
    seconds: number;
    frame?: Frame;
  };
}
