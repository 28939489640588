import { useTranslationContext } from '../../../services/translation';
import { enumToValueArray } from '../../../services/utilities/array';
import { FolderChildType } from '../../services/api-iteration1';
import { DiscoveryTemplate, ProjectType } from '../../services/api-iteration1/projects';

import { ProjectTemplateCard } from '../cards/ProjectTemplateCard';

interface ProjectTemplatesListProps {
  type: ProjectType;
  values: {
    name: string;
    template: string;
  };
  onSelect: (field: string, value: string) => void;
}

export const ProjectTemplatesList = ({ type, values, onSelect }: ProjectTemplatesListProps) => {
  const { t } = useTranslationContext.useContext();

  const projectTemplates = enumToValueArray<string>(
    type === FolderChildType.Discovery ? DiscoveryTemplate : {}
  );

  return (
    <div className='grid grid-cols-2 sm:grid-cols-3 sm:gap-2 mx-auto'>
      {projectTemplates.map((template) => (
        <ProjectTemplateCard
          key={`${template}`}
          title={t(`components.projects.templates.${type}.${template}`)}
          onClick={() => onSelect('template', template)}
          checkedClassName={values.template === template ? 'border-main-highlight' : undefined}
        />
      ))}
    </div>
  );
};
