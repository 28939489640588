import { useEffect, useRef } from 'react';

import { RecordDuration } from '../record';

interface TimerServiceProps {
  canStart?: boolean;
  duration: number;
  onTimerEnd: () => void;
  paused?: boolean;
}

export const useTimer = ({
  canStart = true,
  duration,
  onTimerEnd,
  paused = false,
}: TimerServiceProps) => {
  const durationRecorder = useRef(new RecordDuration());

  useEffect(() => {
    if (!canStart || paused) return durationRecorder.current.pauseCurrentDuration();

    durationRecorder.current.resumeCurrentDuration();

    const interval: NodeJS.Timer = setInterval(() => {
      if (durationRecorder.current.getPaused()) {
        return clearInterval(interval);
      }

      const timePassed = durationRecorder.current.getTotalDuration();
      const timeHasPassed = timePassed > duration;
      if (timeHasPassed) {
        onTimerEnd();
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [canStart, duration, onTimerEnd, paused]);
};
