import { FolderChildType } from '../../folders/api-models';

export * from './sections';
export * from './responses';

export type ProjectType = Exclude<FolderChildType, FolderChildType.Folder>;

export enum VersionStatus {
  Live = 'live',
  Draft = 'draft',
  Done = 'done',
}
