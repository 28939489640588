import { ProjectVersionResponsePermission } from '@iteration1/permission-validators';

import { useTranslationContext } from '../../services/translation';
import { VersionStatus, getValidResponses } from '../services/api-iteration1';
import { useCurrentUserTestVersionContext } from '../services/userTest';
import { allClasses } from '../../services/utilities/array';
import { usePermissionsContext } from '../services/authorization';
import useAnalyticsRouting from '../services/analytics-routing/useAnalyticsRouting';
import { useRouteParams } from '../../services/router';
import { useUserTestResponses } from '../services/api-iteration1/projects/userTest/responses';

import {
  MainLayout,
  withAuth,
  ProjectVersionResultPlaceholder,
  UserTestVersionResult,
} from '../components';
import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import { UserTestAnalyticsNavbar } from '../components/navbars/UserTestAnalyticsNavbar';

import { dynamicRoutes, routes } from './routing/routes';

type TabParams = {
  tab: 'summary' | 'responses';
};

export const UserTestResults = withAuth(() => {
  const { t } = useTranslationContext.useContext();
  const {
    userTest,
    userTestVersion,
    isLoadingUserTest,
    parents,
    error,
    userTestId,
    userTestVersionId,
  } = useCurrentUserTestVersionContext.useContext();
  const { checkPermission } = usePermissionsContext.useContext();
  const { setAnalyticsRoute } = useAnalyticsRouting();
  const { tab: shownTab } = useRouteParams<TabParams>();

  const { data: responses, isLoading: isLoadingResponses } = useUserTestResponses(
    userTestId,
    userTestVersionId || '',
    {
      waitForFetch: !userTestVersionId,
    }
  );

  const { responses: responseSummaries } = userTestVersion || {};
  const hasResponses = !!responseSummaries?.length;
  const { validResponses, invalidResponsesCount } = getValidResponses(responses);
  const isDone = userTestVersion?.status === VersionStatus.Done;

  const testUrl =
    window.location.origin + dynamicRoutes.testing.userTest(userTestId, userTestVersionId || '');

  const breadcrumbs = userTestVersion
    ? [
        { label: t('pages.projects.breadcrumb'), url: routes.projects },
        ...parents
          .sort((a, b) => a.index - b.index)
          .map((parent) => ({
            label: parent.name,
            url: dynamicRoutes.folder(parent._id),
          })),
        {
          label: userTest?.name || '',
          url: dynamicRoutes.userTest(userTestId),
        },
        {
          label: userTestVersion.name,
          url: userTestVersion.id,
        },
      ]
    : [];

  const tabs = [
    { name: t('components.projects.analytics.overview.summary'), key: 'summary' },
    { name: t('components.projects.analytics.overview.responses'), key: 'responses' },
  ] as const;

  const changeTab = (tabId: 'summary' | 'responses') => {
    setAnalyticsRoute(tabId);
  };

  const allowReceiveNewResponses = checkPermission(ProjectVersionResponsePermission.create, {
    count: validResponses.length,
  });

  return (
    <MainLayout
      analyticsName='Analytics - UserTest'
      title={t('pages.userTest.results', {
        projectName: userTest?.name,
        versionName: userTestVersion?.name,
      })}
      navbarLeftComponent={breadcrumbs ? <Breadcrumbs items={breadcrumbs} /> : undefined}
      navbarRightComponent={
        <UserTestAnalyticsNavbar
          shouldShowButton={hasResponses ? true : false}
          validResponsesCount={validResponses.length}
          testUrl={testUrl}
        />
      }
      isLoading={isLoadingUserTest}
      error={error}
      navbarTabs={
        (hasResponses || isDone) && (
          <div className='flex flex-row w-full'>
            {tabs.map((tab) => (
              <div
                key={tab.key}
                className={allClasses(
                  'inline-block -mb-0.5 relative z-10 py-2.5 px-5 text-base leading-5 font-medium cursor-pointer',
                  shownTab === tab.key
                    ? 'bg-main text-main-contrast border-b-2 border-main-highlight border-solid'
                    : 'text-neutral-60 hover:text-main-contrast'
                )}
                onClick={() => changeTab(tab.key)}>
                {tab.name}
              </div>
            ))}
          </div>
        )
      }
      isPlanLimitReached={!allowReceiveNewResponses}
      availableOnMobile={false}>
      {hasResponses || isDone ? (
        <UserTestVersionResult
          isLoading={isLoadingResponses}
          validResponses={validResponses}
          invalidResponsesCount={invalidResponsesCount}
          {...{ shownTab }}
        />
      ) : (
        <ProjectVersionResultPlaceholder
          testPath={dynamicRoutes.testing.userTest(userTestId, userTestVersionId || '')}
          testUrl={testUrl}
          projectId={userTestId}
          projectName={userTest?.name}
        />
      )}
    </MainLayout>
  );
});
