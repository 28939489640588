import React, { useState } from 'react';

import { allClasses } from '../../services/utilities/array';
import { ClientError } from '../../services/error-handling/errors/ClientError';
import { useTranslationContext } from '../../services/translation';

import { Spinner } from '../loading-indicator';

import {
  DeleteIcon,
  EditIcon,
  PlaceholderSmallIcon,
  RetryUploadIcon,
  UploadSuccess,
} from '../../domain/assets/icons';

import styles from './Upload.module.css';

interface UploadImageProps {
  id?: string;
  title: string;
  subtitle: string;
  placeholderImgSrc?: string;
  excludeFromForm?: boolean;
  fileSizeLimit?: number;
  upload: (image: File) => Promise<void>;
  remove: () => Promise<void>;
  onUploadSuccess?: (image: File) => void;
  onUploadError?: (err: any) => void;
  onRemoveSuccess?: () => void;
  onRemoveError?: (err: any) => void;
  onLoadingStatusChange?: (id: string, status: 'started' | 'finished') => void;
  className?: string;
  required?: boolean;
  fileName?: string;
}

type UploadStatus = 'success' | 'error' | 'uploading' | 'idle' | 'removing';

export const UploadImage = ({
  id,
  title,
  subtitle,
  placeholderImgSrc,
  excludeFromForm,
  fileSizeLimit,
  upload,
  remove,
  onUploadSuccess,
  onUploadError,
  onRemoveSuccess,
  onRemoveError,
  onLoadingStatusChange,
  className,
  required,
  fileName,
}: UploadImageProps) => {
  const { t } = useTranslationContext.useContext();
  const [image, setImage] = useState<File>();
  const [imageStream, setImageStream] = useState<string | null | undefined>();
  const [uploadStatus, setUploadingStatus] = useState<UploadStatus>('idle');

  const imageSrc = imageStream || placeholderImgSrc;
  const missingRequiredImg = required && !imageSrc;

  const uploadImage = async (image: File) => {
    const uploadId = `UploadImage-${id}-${image.name}-${image.size}-${image.type}-${image.lastModified}`;
    onLoadingStatusChange?.(uploadId, 'started');

    try {
      setUploadingStatus('uploading');

      await upload(image);

      setUploadingStatus('success');
      onUploadSuccess?.(image);
      setTimeout(() => setUploadingStatus('idle'), 1000);
    } catch (err) {
      setUploadingStatus('error');
      onUploadError?.(err);
    }

    onLoadingStatusChange?.(uploadId, 'finished');
  };

  const selectImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (excludeFromForm) e.stopPropagation();

    const image = e?.target?.files?.[0];
    if (!image) return;
    if (fileSizeLimit && image.size > fileSizeLimit)
      return onUploadError?.(new ClientError('errors.validation.image-size'));

    setImage(image);

    const reader = new FileReader();
    reader.onload = function (e) {
      setImageStream(e.target?.result as string);
    };

    reader.readAsDataURL(image);

    uploadImage(image);
  };

  const removeImage = async () => {
    const removeUploadId = `UploadImage-remove-${id}`;
    onLoadingStatusChange?.(removeUploadId, 'started');

    try {
      setUploadingStatus('removing');

      setImage(undefined);
      setImageStream(undefined);
      await remove();

      onRemoveSuccess?.();
      setUploadingStatus('idle');
    } catch (err) {
      onRemoveError?.(err);
    }

    onLoadingStatusChange?.(removeUploadId, 'finished');
  };

  return (
    <div className={className}>
      <div
        className={allClasses(
          'relative flex items-center h-20 bg-main border-2 border-solid rounded-lg overflow-hidden p-2.5 cursor-pointer hover:border-main-highlight',
          imageSrc ? 'cursor-default' : 'cursor-pointer',
          missingRequiredImg ? 'border-danger' : 'border-main-10'
        )}
        {...{ id }}>
        {imageSrc ? (
          <>
            <div className='flex justify-between items-center w-24 h-full rounded overflow-hidden'>
              {imageSrc && <img src={imageSrc} title={image?.name} className='cover' />}
            </div>
            {fileName && <div className='flex-1 px-4 w-20 truncate'>{fileName}</div>}
            <div className='flex mr-1'>
              {uploadStatus === 'uploading' ? (
                <div className='ml-2 flex flex-row justify-between items-center'>
                  <p className='text-base text-main-contrast font-normal leading-5 mr-2'>
                    {t('components.upload.uploading-placeholder')}
                  </p>
                  <Spinner className='icon__small' />
                </div>
              ) : uploadStatus === 'removing' ? (
                <Spinner className='icon__small' color='danger' />
              ) : uploadStatus === 'error' ? (
                <RetryUploadIcon
                  className='icon__small cursor-pointer'
                  onClick={() => image && uploadImage(image)}
                />
              ) : uploadStatus === 'success' ? (
                <UploadSuccess className='icon__small' />
              ) : uploadStatus === 'idle' ? (
                <>
                  <label htmlFor='edit-image'>
                    <EditIcon className='icon__small ml-2 cursor-pointer icon-hover-danger-20' />
                  </label>
                  <input
                    id='edit-image'
                    type='file'
                    accept='image/png, image/gif, image/jpeg'
                    className='hidden'
                    onChange={selectImage}
                  />
                  <DeleteIcon
                    className='icon__small ml-2 cursor-pointer icon-hover-danger-20'
                    onClick={removeImage}
                  />
                </>
              ) : null}
            </div>
          </>
        ) : (
          <>
            <PlaceholderSmallIcon className='w-10 h-10 mx-2' />
            <div className='flex flex-1 flex-col items-start pl-3'>
              <p className='text-base text-main-contrast font-normal leading-5 mb-1'>{title}</p>
              <p className='text-xs text-neutral-70 font-normal leading-4'>{subtitle}</p>
            </div>
          </>
        )}
        {!imageSrc && (
          <input
            type='file'
            accept='image/png, image/gif, image/jpeg'
            className={styles.input}
            onChange={selectImage}
          />
        )}
      </div>
      {missingRequiredImg && (
        <div className={'text-danger text-sm bg-transparent w-full text-left mt-1'}>
          {t('errors.validation.required-img')}
        </div>
      )}
    </div>
  );
};
