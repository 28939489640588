import { allClasses } from '../../../../services/utilities/array';
import { CollaboratorRole } from '../../../services/api-iteration1/collaborators/api-models';
import { useTranslationContext } from '../../../../services/translation';

import { ChevronDownIcon } from '../../../assets/icons';

type RolesDropdownType =
  | { x: number; y: number; height: number; triggerer: HTMLElement; index: number }
  | undefined;

interface CollaboratorRolesDropdownTriggerProps {
  collaboratorRole?: CollaboratorRole;
  currentDropdownIndex?: number;
  dropdownIndex: number;
  isDropdownOpen?: boolean;
  rolesDropdown: RolesDropdownType;
  setRolesDropdown: ({ x, y, height, triggerer, index }: any) => void;
  setCurrentDropdownIndex?: (index: number) => void;
}

export const CollaboratorRolesDropdownTrigger = ({
  collaboratorRole,
  currentDropdownIndex,
  dropdownIndex,
  isDropdownOpen,
  rolesDropdown,
  setRolesDropdown,
  setCurrentDropdownIndex,
}: CollaboratorRolesDropdownTriggerProps) => {
  const { t } = useTranslationContext.useContext();

  return (
    <div
      onClick={(e) => {
        setRolesDropdown(
          !rolesDropdown
            ? {
                x: e.currentTarget.getBoundingClientRect().x,
                y: e.currentTarget.getBoundingClientRect().y,
                height: e.currentTarget.getBoundingClientRect().height,
                triggerer: e.currentTarget,
                index: dropdownIndex,
              }
            : undefined
        );
        setCurrentDropdownIndex?.(dropdownIndex);
      }}
      className={allClasses(
        'py-2.5 pl-6 pr-2.5 flex flex-row items-center rounded-lg cursor-pointer',
        isDropdownOpen ? 'bg-main-90 text-main-contrast' : 'bg-main text-neutral-20'
      )}>
      <span
        className={
          isDropdownOpen ? 'text-main-contrast rounded-lg overflow-hidden' : 'text-neutral-30'
        }>
        {t(`components.collaborators.roles-dropdown.${collaboratorRole}`)}
      </span>
      <span
        className={allClasses(
          'cursor-pointer icon-polyline-neutral-20 ml-2.5',
          rolesDropdown &&
            currentDropdownIndex === rolesDropdown.index &&
            'rotate-180 icon-stroke-polyline-main-contrast'
        )}>
        <ChevronDownIcon />
      </span>
    </div>
  );
};
