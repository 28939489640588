export const isJSONParsable = (val: any) => {
  try {
    JSON.parse(val);
  } catch (err) {
    return false;
  }
  return true;
};

export const tryJSONParse = (val: any, defaultReturn: any = undefined) =>
  isJSONParsable(val) ? JSON.parse(val) : defaultReturn;
