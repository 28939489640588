interface ProjectCardHeaderProps {
  icon: JSX.Element;
  title: string;
}

export const ProjectCardHeader = ({ icon, title }: ProjectCardHeaderProps) => {
  return (
    <div className='flex justify-items-start'>
      {icon}
      <div className='pl-2.5 leading-5 text-base font-normal'>{title}</div>
    </div>
  );
};
