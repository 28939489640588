import {
  DefaultSection,
  DiscoverySection,
  isDefaultSection,
  isUsabilitySection,
  UsabilitySection,
  UsabilitySectionType,
} from '../api-iteration1';

export type SectionScreenGroup = {
  totalCount: number;
  index: number;
  answerScreenIndexes: number[];
  instructionScreenIndex?: number;
};

export const noAnswerSectionScreens: SectionScreenGroup = {
  totalCount: 1,
  index: 0,
  answerScreenIndexes: [],
};

export const defaultSectionScreens: SectionScreenGroup = {
  totalCount: 1,
  index: 0,
  answerScreenIndexes: [0],
};

export const getSectionsScreens = (
  sections: (DefaultSection | DiscoverySection | UsabilitySection)[]
): Record<string, SectionScreenGroup> => {
  return sections.reduce((result, section) => {
    if (isDefaultSection(section)) return { ...result, [section._id]: noAnswerSectionScreens };
    if (!isUsabilitySection(section)) return { ...result, [section._id]: defaultSectionScreens };

    const usabilitySectionScreens = (() => {
      switch (section.type) {
        case UsabilitySectionType.SingleGoal:
          return {
            totalCount: 3,
            index: 0,
            answerScreenIndexes: [1],
            instructionScreenIndex: 0,
          };
        case UsabilitySectionType.FiveSeconds:
          return {
            totalCount: 2,
            index: 0,
            answerScreenIndexes: [1],
            instructionScreenIndex: 0,
          };
        case UsabilitySectionType.FirstClick:
          return {
            totalCount: 2,
            index: 0,
            answerScreenIndexes: [1],
            instructionScreenIndex: 0,
          };
        default:
          return defaultSectionScreens;
      }
    })();

    return { ...result, [section._id]: usabilitySectionScreens };
  }, {});
};
