import { useCallback, useMemo, useState } from 'react';

import { createContextService } from '../context';

const useActionInProgress = () => {
  const [actionsInProgress, setActionsInProgress] = useState<Record<string, 1>>({});

  const addActionInProgress = useCallback((key: string) => {
    setActionsInProgress((prev) => ({ ...prev, [key]: 1 }));
  }, []);

  const removeActionInProgress = useCallback((key: string) => {
    setActionsInProgress((prev) => {
      delete prev[key];
      return { ...prev };
    });
  }, []);

  const reflectUploadingStatus = useCallback(
    (id: string, status: 'started' | 'finished') => {
      if (status === 'started') return addActionInProgress(id);
      removeActionInProgress(id);
    },
    [addActionInProgress, removeActionInProgress]
  );

  const hasActionsInProgress = !!Object.keys(actionsInProgress).length;

  return useMemo(
    () => ({
      hasActionsInProgress,
      addActionInProgress,
      removeActionInProgress,
      reflectUploadingStatus,
    }),
    [hasActionsInProgress, addActionInProgress, removeActionInProgress, reflectUploadingStatus]
  );
};

export const useActionInProgressContext = createContextService(useActionInProgress);
