import { allClasses } from '../../../../../services/utilities/array';

import { Icon, Navlink } from '../../../../../components';

import { HomeIcon, LogoIcon } from '../../../../assets/icons';
import { routes } from '../../../../pages/routing/routes';

interface LogoProps {
  isHovered: boolean;
}

export const Logo = ({ isHovered }: LogoProps) => (
  <Navlink
    className='border-2 rounded-lg border-main-10 border-solid flex items-center h-10 cursor-pointer px-2'
    to={routes.home}>
    <LogoIcon className='box-content w-5 h-3 pr-4' />
    <Icon Svg={HomeIcon} className={allClasses('icon__small', isHovered && 'icon-main-contrast')} />
  </Navlink>
);
