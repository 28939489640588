import { LoadingTextIndicator } from '../../../components';

interface PriceProps {
  value: number | null | undefined;
  isLoading?: boolean;
  precision?: number;
}

export const Price = ({ value, precision = 0, isLoading }: PriceProps) => {
  return isLoading ? (
    <LoadingTextIndicator />
  ) : (
    <>
      {value && value < 0 ? '-' : ''}${Math.abs((value || 0) / 100).toFixed(precision)}
    </>
  );
};
