import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

interface NavlinkProps extends React.RefAttributes<HTMLAnchorElement> {
  to: string;
  innerRef?: React.Ref<HTMLAnchorElement>;
  className?: string;
  end?: boolean;
  strict?: boolean;
  children?: ReactNode;
}

export const Navlink = ({ children, innerRef, ...linkProps }: NavlinkProps) => {
  return (
    <NavLink ref={innerRef} {...linkProps}>
      {children}
    </NavLink>
  );
};
