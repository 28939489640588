import {
  DiscoverySectionYesNoOutput,
  DiscoveryYesNoAnswer,
} from '../../../../services/api-iteration1';
import { useTranslationContext } from '../../../../../services/translation';

import { Charts, LoadingOrContent } from '../../../../../components';

import { AnalyticsSectionContainer } from '../../../containers/AnalyticsSectionContainer';
import { AnalyticsIconNo, AnalyticsIconYes, YesNoIcon } from '../../../../assets/icons';
import { AnalyticsPercentage } from '../AnalyticsPercentage';
import { AnalyticsSummaryStatistic } from '../../../headers/AnalyticsSummaryStatistic';

interface AnalyticsYesNoSummaryProps {
  answers: DiscoveryYesNoAnswer[];
  section: DiscoverySectionYesNoOutput;
  sectionsCount: number;
  isLoadingResponses: boolean;
}

export const AnalyticsYesNoSummary = ({
  answers,
  section,
  sectionsCount,
  isLoadingResponses,
}: AnalyticsYesNoSummaryProps) => {
  const { t } = useTranslationContext.useContext();

  const positiveAnswers = answers.filter(
    (response: DiscoveryYesNoAnswer) => response.answer === 'yes'
  );
  const negativeAnswers = answers.filter((item) => item.answer === 'no');
  const positivePercentage =
    positiveAnswers && answers.length
      ? Math.round((positiveAnswers.length / answers.length) * 100)
      : 0;
  const negativePercentage =
    negativeAnswers && answers.length
      ? Math.round((negativeAnswers.length / answers.length) * 100)
      : 0;

  const data = [
    [
      `t('components.projects.sections.yes-no.answers.yes') / t('components.projects.sections.yes-no.answers.no')`,
      'Answers',
    ],
    [t('components.projects.sections.yes-no.answers.yes'), positivePercentage || 0],
    [t('components.projects.sections.yes-no.answers.no'), negativePercentage || 0],
  ];

  const emptyData = [
    [
      `t('components.projects.sections.yes-no.answers.yes') / t('components.projects.sections.yes-no.answers.no')`,
      'Answers',
    ],
    [t('components.projects.sections.yes-no.answers.yes'), 100],
    [t('components.projects.sections.yes-no.answers.no'), 0],
  ];

  const options = {
    colors: [answers && answers.length > 0 ? '#d6f446' : '#cbcbcb', '#ac85f6'],
    enableInteractivity: false,
    legend: 'none',
    pieHole: 0.45,
    pieSliceText: 'none',
    pieStartAngle: 180,
    tooltip: {
      trigger: 'none',
    },
    chartArea: { width: '100%', height: '100%' },
  };

  return (
    <AnalyticsSectionContainer
      icon={<YesNoIcon />}
      description={section.others?.description as string}
      contentTitle={section.title}
      rightSideInformation={
        <AnalyticsSummaryStatistic
          currentSectionIndex={section.index}
          answersCount={answers.length || 1}
          {...{ sectionsCount }}
        />
      }
      title={t('components.projects.sections.field-types.yesNo')}>
      <LoadingOrContent isLoading={isLoadingResponses} className='pb-12'>
        <div className='w-full flex flex-col items-center justify-center mt-20 mb-16 xl:flex-row'>
          {answers.length ? (
            <>
              <span className='w-14 text-center mb-5 xl:mb-0'>
                <AnalyticsIconYes className='w-10 h-10 mb-2.5 inline-block' />
                <span className='text-main-highlight-70 text-xl leading-30 font-bold'>
                  {t('components.projects.sections.yes-no.answers.yes')}
                </span>
                <hr className='w-14 h-0.5 my-5' />
                <AnalyticsPercentage
                  answersNumber={positiveAnswers?.length || 0}
                  answerPercentage={positivePercentage}
                />
              </span>
              <div className='flex justify-center max-w-2xl mx-20'>
                <Charts
                  type='PieChart'
                  data={answers && answers.length > 0 ? data : emptyData}
                  options={options}
                  width='378px'
                  height='378px'
                />
              </div>
              <span className='w-14 text-center mt-5 xl:mt-0'>
                <AnalyticsIconNo className='w-10 h-10 mb-2.5 inline-block' />
                <span className='text-info-20 text-xl leading-30 font-bold'>
                  {t('components.projects.sections.yes-no.answers.no')}
                </span>
                <hr className='w-14 h-0.5 my-5' />
                <AnalyticsPercentage
                  answersNumber={negativeAnswers?.length || 0}
                  answerPercentage={negativePercentage}
                />
              </span>
            </>
          ) : (
            <p className='text-center font-medium text-danger'>
              {t('components.projects.analytics.no-answers')}
            </p>
          )}
        </div>
      </LoadingOrContent>
    </AnalyticsSectionContainer>
  );
};
