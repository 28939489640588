import { allClasses } from '../../../../../../services/utilities/array';
import {
  DiscoveryRatingScaleAnswer,
  DiscoverySectionType,
} from '../../../../../services/api-iteration1';

import { Icon } from '../../../../../../components';

import {
  EmotionIconFive,
  EmotionIconFour,
  EmotionIconOne,
  EmotionIconThree,
  EmotionIconTwo,
} from '../../../../../assets/icons';
import { Label } from './Label';

interface ViewEmotionsProps {
  answer: DiscoveryRatingScaleAnswer | undefined;
  onSetAnswer?: (answer: Omit<DiscoveryRatingScaleAnswer, 'duration'>) => void;
  labels?: {
    left: string;
    middle: string;
    right: string;
  };
}

const ViewEmotions = ({ labels, answer, onSetAnswer }: ViewEmotionsProps) => {
  const chooseAnswer = (answer: number) =>
    onSetAnswer?.({
      type: DiscoverySectionType.RatingScale,
      answer,
    });

  return (
    <div className='grid grid-rows-1 sm:grid-cols-5'>
      <div className='w-full flex flex-row items-end mb-2 sm:items-start sm:flex-col sm:mb-0'>
        <EmotionsItem
          icon={EmotionIconOne}
          isSelected={answer?.answer === 1}
          onSelect={() => chooseAnswer(1)}
          selectedClassName='icon-fill-circle-danger icon-circle-danger icon-main'
          className='icon-hover-danger icon-hover-circle-danger'
        />
        {labels && (
          <span className='mr-2 order-1 [writing-mode:vertical-lr] w-10 h-20 sm:[writing-mode:horizontal-tb] sm:order-2 sm:h-full'>
            {labels?.left && <Label text={labels.left} alignment='left' />}
          </span>
        )}
      </div>
      <div className='w-full flex flex-row mb-2 sm:flex-col sm:mb-0'>
        <EmotionsItem
          icon={EmotionIconTwo}
          isSelected={answer?.answer === 2}
          onSelect={() => chooseAnswer(2)}
          selectedClassName='icon-fill-circle-warning-10 icon-circle-warning-10 icon-main'
          className='icon-hover-warning-10 icon-hover-circle-warning-10'
        />
        {labels && <span className='h-20 w-12 order-1 sm:order-2'></span>}
      </div>
      <div className='w-full flex flex-row items-end mb-2 sm:items-center sm:flex-col sm:mb-0'>
        <EmotionsItem
          icon={EmotionIconThree}
          isSelected={answer?.answer === 3}
          onSelect={() => chooseAnswer(3)}
          selectedClassName='icon-fill-circle-info icon-circle-info icon-main'
          className='icon-hover-info icon-hover-circle-info'
        />
        {labels && (
          <span className='mr-2 order-1 [writing-mode:vertical-lr] w-10 h-20 sm:[writing-mode:horizontal-tb] sm:order-2 sm:h-full'>
            {labels.middle && <Label text={labels.middle} alignment='center' />}
          </span>
        )}
      </div>
      <div className='w-full flex flex-row mb-2 sm:flex-col sm:mb-0'>
        <EmotionsItem
          icon={EmotionIconFour}
          isSelected={answer?.answer === 4}
          onSelect={() => chooseAnswer(4)}
          selectedClassName='icon-fill-circle-main-highlight-95 icon-circle-main-highlight-95 icon-main'
          className='icon-hover-main-highlight-95 icon-hover-circle-main-highlight-95'
        />
        {labels && <span className='h-20 w-12 order-1 sm:order-2'></span>}
      </div>
      <div className='w-full flex flex-row items-end mb-2 sm:flex-col sm:mb-0'>
        <EmotionsItem
          icon={EmotionIconFive}
          isSelected={answer?.answer === 5}
          onSelect={() => chooseAnswer(5)}
          selectedClassName='icon-fill-circle-success-20 icon-circle-success-20 icon-main icon-main-fill'
          className='icon-hover-success-20 icon-hover-circle-success-20 icon-fill-hover-success-20'
          isLast
        />
        {labels && (
          <span className='mr-2 order-1 [writing-mode:vertical-lr] w-10 h-20 sm:[writing-mode:horizontal-tb] sm:order-2 sm:h-full'>
            {labels?.right && <Label text={labels.right} alignment='right' />}
          </span>
        )}
      </div>
    </div>
  );
};

interface EmotionsItemProps {
  isSelected: boolean;
  selectedClassName: string;
  className: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  isLast?: boolean;
  onSelect?: () => void;
}

const EmotionsItem = ({
  isSelected,
  onSelect,
  icon,
  isLast,
  selectedClassName,
  className,
}: EmotionsItemProps) => {
  return (
    <div
      onClick={onSelect}
      className={allClasses(
        'relative flex flex-1 flex-col justify-center items-center border-2 border-solid p-3 rounded-lg order-2',
        isSelected ? 'border-main-highlight' : 'border-main-10',
        onSelect && 'cursor-pointer',
        !isLast ? 'sm:mr-2' : undefined
      )}>
      {isSelected && <div className='absolute inset-0 bg-main-highlight opacity-10 z-0' />}
      <Icon
        Svg={icon}
        className={allClasses(
          'h-14 w-14 z-10 sm:h-20 sm:w-20',
          isSelected ? selectedClassName : className
        )}
      />
    </div>
  );
};

export default ViewEmotions;
