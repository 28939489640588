import { ApiValidationError } from './ApiValidationError';

export class ApiServerError {
  static contains = (err: any): boolean => {
    const isApiError = err && err.isAxiosError && !ApiValidationError.contains(err);

    return !!isApiError;
  };

  static getError = (err: any): string => {
    const apiError = err && err.response && err.response.data && err.response.data.message;

    return typeof apiError == 'string' ? apiError : '';
  };
}
