import { useTranslationContext } from '../../../../../services/translation';
import {
  UsabilitySectionSingleGoal,
  UsabilitySingleGoalAnswer,
} from '../../../../services/api-iteration1';
import { allClasses } from '../../../../../services/utilities/array';
import { getKeyForCondition } from '../../../../../services/utilities/object';
import {
  calculateAvgSingleGoalDuration,
  calculateSingleGoalCompletionRate,
  calculateSingleGoalMisclickRate,
  calculateSingleGoalUsabilityScore,
  computeFramesAnalytics,
} from '../../../../services/analytics';
import { FigmaDesignPrototypeVersion } from '../../../../services/api-iteration1/design-platforms';

import { LoadingOrContent } from '../../../../../components';

import { SingleGoalIcon } from '../../../../assets/icons';
import { AnalyticsSingleGoalTabsCard } from '../../../cards';
import { AnalyticsSectionContainer } from '../../../containers';
import { AnalyticsSummaryStatistic } from '../../../headers/AnalyticsSummaryStatistic';

interface AnalyticsSingleGoalSummaryProps {
  answers: UsabilitySingleGoalAnswer[];
  section: UsabilitySectionSingleGoal;
  versionName?: string;
  designPrototype?: FigmaDesignPrototypeVersion;
  sectionsCount: number;
  isLoadingResponses: boolean;
}

export const AnalyticsSingleGoalSummary = ({
  section,
  answers,
  versionName,
  designPrototype,
  sectionsCount,
  isLoadingResponses,
}: AnalyticsSingleGoalSummaryProps) => {
  const { t } = useTranslationContext.useContext();

  const responsesCount = answers.length || 0;

  const lowestFrameCountPath =
    section.others.paths.map((p) => p.frames.length).sort((a, b) => a - b)[0] || 1;

  const usabilityScore = calculateSingleGoalUsabilityScore({
    avgDuration: calculateAvgSingleGoalDuration(answers || []),
    testersCount: responsesCount,
    missclicksRate: calculateSingleGoalMisclickRate(answers || []),
    completionRate: calculateSingleGoalCompletionRate(answers || []),
    framesCount: lowestFrameCountPath,
  });

  const screenBackgroundColor = getKeyForCondition({
    'bg-main-highlight': usabilityScore >= 7,
    'bg-warning': usabilityScore >= 3 && usabilityScore < 7,
    'bg-danger': usabilityScore < 3,
  });

  const { uniqueFramesData, screensToCheck, screensToRework, greatScreens } =
    computeFramesAnalytics(answers, responsesCount, designPrototype);

  return (
    <AnalyticsSectionContainer
      icon={<SingleGoalIcon />}
      description={section.others.description as string}
      contentTitle={(section.others.title as string) || ''}
      rightSideInformation={
        <AnalyticsSummaryStatistic
          currentSectionIndex={section.index}
          answersCount={answers.length || 1}
          {...{ sectionsCount }}
        />
      }
      title={t('components.projects.sections.field-types.singleGoal')}>
      <LoadingOrContent isLoading={isLoadingResponses} className='pb-12'>
        <div className='pb-10 xl:px-2 2xl:px-5'>
          <div className='w-full mt-20 mb-5 flex flex-col xl:flex-row flex-wrap items-center justify-center'>
            <div
              className={allClasses(
                'relative w-80 h-80 mr-0 2xl:w-92 2xl:h-92 xl:mr-6 2xl:mr-14 rounded-full text-center',
                responsesCount ? 'align-top' : 'align-middle',
                usabilityScore ? screenBackgroundColor : 'bg-main-10'
              )}>
              <div className='w-full absolute top-1/2 left-0 transform -translate-y-1/2'>
                <p className='text-7xl text-main-contrast font-bold leading-snug'>
                  {responsesCount ? usabilityScore : 0}
                </p>
                <p className='text-base text-main-contrast font-bold leading-5'>
                  {t('components.projects.usability-testing.results.score-label')}
                </p>
              </div>
            </div>
            <div
              className={allClasses(
                ' mt-10 2xl:mt-0',
                responsesCount ? 'align-top md:text-center' : 'align-middle'
              )}>
              {responsesCount ? (
                <AnalyticsSingleGoalTabsCard
                  {...{ answers, versionName }}
                  screenCategoriesCount={{
                    screensToRework,
                    screensToCheck,
                    greatScreens,
                  }}
                  screensAnalytics={uniqueFramesData}
                  sectionId={section._id}
                />
              ) : (
                <p className='text-center font-medium text-danger'>
                  {t('components.projects.analytics.no-answers')}
                </p>
              )}
            </div>
          </div>
        </div>
      </LoadingOrContent>
    </AnalyticsSectionContainer>
  );
};
