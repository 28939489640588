export const endpoints = {
  v1: {
    responses: (userTestId: string, versionId: string) =>
      `/v1/userTests/${userTestId}/versions/${versionId}/responses`,
    response: (userTestId: string, versionId: string, responseId: string) =>
      `/v1/userTests/${userTestId}/versions/${versionId}/responses/${responseId}`,
    response_give_up: (userTestId: string, versionId: string, responseId: string) =>
      `/v1/userTests/${userTestId}/versions/${versionId}/responses/${responseId}/give-up`,
    response_section_feedback: (
      userTestId: string,
      versionId: string,
      responseId: string,
      sectionId: string
    ) =>
      `/v1/userTests/${userTestId}/versions/${versionId}/responses/${responseId}/${sectionId}/feedback`,
    response_upload_section_video: (
      userTestId: string,
      versionId: string,
      sectionId: string,
      responseId: string
    ) =>
      `/v1/userTests/${userTestId}/versions/${versionId}/responses/${responseId}/${sectionId}/upload/video`,
  },
};
