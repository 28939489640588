// We consume html2canfast which at the moment uses console.log to log canvas render information.
// Until the package is fixed, we need to disable console logging for most of the app.
// This utility function tries to overcome the disabling of console.log

const defaultLogger = console.log;

export const silenceBrowserLogger = () => {
  console.log = () => ({});
};

export const logToConsole = (...args: any) => {
  console.log = defaultLogger;

  console.log(...args);
};
