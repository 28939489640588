import { useTranslationContext } from '../../../../services/translation';
import { Path } from '../../../services/api-iteration1';
import { FigmaDesignPrototypeVersion } from '../../../services/api-iteration1/design-platforms';
import { getStoragePublicFileUrl } from '../../../services/api-cloud-storage';
import { allClasses, CleanArray, getDuplicateValues } from '../../../../services/utilities/array';

import { useHover } from '../../../../components/utilities/useHover';

import { DeleteIcon } from '../../../assets/icons';
import FrameCard from './FrameCard';
import ActionMessageFill from './ActionMessageFill';

import styles from './PathCard.module.css';

interface PathCardProps {
  designPrototype?: FigmaDesignPrototypeVersion;
  path: Path;
  enableDelete: boolean;
  onDelete?: (index: number) => void;
  onDeleteFrames?: (pathIndex: number, frameIndex: number) => void;
  onClickChangeStartFrame?: (pathIndex: number) => void;
  onSelectPath: (path: Path) => void;
  showChangeStartFrame?: boolean;
  pathInFocusIndex?: number;
  isIdenticalPath?: boolean;
}

export const PathCard = ({
  path,
  enableDelete,
  designPrototype,
  showChangeStartFrame,
  pathInFocusIndex,
  isIdenticalPath,
  onDelete,
  onDeleteFrames,
  onClickChangeStartFrame,
  onSelectPath,
}: PathCardProps) => {
  const { t } = useTranslationContext.useContext();

  const { onHover, onLeave, isHover, metadata: hoverMetadata } = useHover<{ frameIndex: number }>();

  const { index: pathIndex, frames } = path;

  const isFocused = pathInFocusIndex === pathIndex;

  const identicalFramesIds = getDuplicateValues(frames.map((f) => f.id));

  const errorKey = (() => {
    if (isIdenticalPath) return 'errors.validation.identical-path';
    if (identicalFramesIds.length) return 'errors.validation.identical-frames';
  })();

  const setHoveredFrame = (frameIndex: number) => {
    onHover({ frameIndex });
  };

  const deleteFrames = (pathIndex: number, frameIndex: number) => {
    onDeleteFrames?.(pathIndex, frameIndex);
    onLeave();
  };

  return (
    <>
      <div
        className={allClasses(
          'relative h-50 pt-3 px-5 pb-5 border-2 border-solid rounded-lg cursor-pointer mb-5 hover:border-main-highlight',
          isFocused ? 'border-main-highlight' : 'border-main-10'
        )}
        onClick={() => onSelectPath(path)}>
        <div className={styles.path_heading_wrapper}>
          <p className='mb-3 text-lg text-contrast leading-normal font-normal'>
            {t('common.path')} {pathIndex + 1}
          </p>
          {enableDelete && onDelete && (
            <span
              className={styles.path_action}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(pathIndex);
              }}>
              <DeleteIcon />
            </span>
          )}
        </div>
        <div
          className={allClasses(
            'relative flex flex-col justify-center items-center p-5 border-2 border-solid rounded-xl leading-5 overflow-hidden',
            errorKey ? 'border-danger' : 'border-main-10'
          )}>
          <div className='w-full flex flex-row items-start justify-start flex-wrap'>
            {CleanArray(frames).map((frame) => {
              const prototypeFrame = designPrototype?.frames.find((f) => f.id === frame.id);
              const isHoveredForDelete =
                isHover && hoverMetadata && hoverMetadata.frameIndex <= frame.index;
              const canDelete = !enableDelete
                ? frame.index !== 0 && hoverMetadata?.frameIndex !== 0
                : true;

              return (
                <FrameCard
                  key={`${frame.index}_${frame.id}`}
                  frameIndex={frame.index}
                  pathIndex={pathIndex}
                  withIssues={identicalFramesIds.includes(frame.id)}
                  imageSrc={
                    prototypeFrame?.image && getStoragePublicFileUrl(prototypeFrame.image.id)
                  }
                  onDeleteFrames={canDelete ? deleteFrames : undefined}
                  onFrameHover={setHoveredFrame}
                  onFrameLeave={onLeave}
                  hoverClassNames={
                    isHoveredForDelete && canDelete
                      ? 'flex absolute top-0 left-0 w-full items-center justify-center'
                      : undefined
                  }
                />
              );
            })}
            <ActionMessageFill
              messageType={
                showChangeStartFrame
                  ? 'change-start-frame'
                  : frames.length === 1 && pathIndex > 0
                  ? 'click-prototype'
                  : undefined
              }
              onClick={
                showChangeStartFrame && onClickChangeStartFrame
                  ? () => onClickChangeStartFrame(pathIndex)
                  : undefined
              }
            />
          </div>
        </div>
        {errorKey && <p className='mt-2 text-danger text-xs'>{t(errorKey)}</p>}
      </div>
    </>
  );
};
