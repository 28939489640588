import { useTranslationContext } from '../../services/translation';

import { SomethingWentWrongIllustration } from '../../domain/assets/icons';

export const SomethingWentWrong = () => {
  const { t } = useTranslationContext.useContext();

  return (
    <div className='flex w-full h-full flex-col justify-center items-center text-center mt-32'>
      <div className='flex justify-center items-center w-full max-w-2xl py-14'>
        <SomethingWentWrongIllustration />
      </div>
      <p className='text-xl leading-8 text-main-contrast font-bold mt-10 mb-2.5'>
        {t('empty-states.something-went-wrong.title')}
      </p>
      <p className='w-full max-w-2xl m-0 text-lg text-main-contrast leading-6'>
        {t('empty-states.something-went-wrong.description')}
      </p>
      <div className='mt-10'>
        <span
          className='text-info border-b-default border-main-70 border-solid cursor-pointer hover:text-info-70'
          onClick={() => window.location.reload()}>
          {t('empty-states.something-went-wrong.refresh-page')}
        </span>
      </div>
    </div>
  );
};
