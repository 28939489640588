import { useTranslationContext } from '../../services/translation';
import { useCurrentUserContext } from '../services/current-user';

import { NoFooterLayout } from '../components';
import { FinishSignUpForm } from '../components/views/auth/FinishSignUpForm';

export const FinishSignUp = () => {
  const { t } = useTranslationContext.useContext();
  const { currentUser } = useCurrentUserContext.useContext();

  const isFinishedSignUp =
    !!currentUser?.firstName && !!currentUser?.privacyConsent && !!currentUser?.termsConsent;

  return (
    <NoFooterLayout
      requiresAuthentication
      canAccessPage={!isFinishedSignUp}
      analyticsName='Finish Sign up'
      title={t('pages.finish-sign-up.title')}
      availableOnMobile={false}>
      <FinishSignUpForm />
    </NoFooterLayout>
  );
};
