import { useCallback, useRef } from 'react';

import { useTranslationContext } from '../../../../../../services/translation';
import {
  PrototypeClick,
  UsabilitySectionFiveSeconds,
  UsabilitySectionFiveSecondsAnswer,
  UsabilitySectionType,
} from '../../../../../services/api-iteration1';
import {
  DesignPrototypeVersion,
  FigmaFrame,
} from '../../../../../services/api-iteration1/design-platforms';
import { getClickPosition } from '../../../../../../services/position';
import { useTimer } from '../../../../../../services/timer';
import { StorageFileType } from '../../../../../services/api-cloud-storage/api-models';
import { useShowInstructionsContext } from '../../../../../services/userTest';
import { allClasses } from '../../../../../../services/utilities/array';

import { PlaceholderSmallIcon } from '../../../../../assets/icons';
import { SectionInstructions } from './SectionInstructions';
import { FigmaPrototypeImage } from '../../../live-prototype/FigmaPrototypeImage';

import styles from '../ViewSection.module.css';

interface ViewFiveSecondsProps {
  fiveSecondsSection: UsabilitySectionFiveSeconds;
  currentSectionScreenIndex?: number;
  designPrototype?: DesignPrototypeVersion;
  isEmbeddedIframe?: boolean;
  onIntermediateAnswer?: (
    sectionId: string,
    answer: Omit<UsabilitySectionFiveSecondsAnswer, 'duration'>
  ) => void;
  onSaveAnswerImmediately?: (
    sectionId: string,
    answer: Omit<UsabilitySectionFiveSecondsAnswer, 'duration'>
  ) => void;
  onFinishAnswerScreen?: () => void;
}

const idImageContainer = 'five-seconds-section-image-container';

export const ViewFiveSeconds = ({
  fiveSecondsSection,
  currentSectionScreenIndex,
  designPrototype,
  onIntermediateAnswer,
  onSaveAnswerImmediately,
  onFinishAnswerScreen,
  isEmbeddedIframe,
}: ViewFiveSecondsProps) => {
  const { t } = useTranslationContext.useContext();
  const { isVisibleInstructionsAgain } = useShowInstructionsContext.useContext();

  const clicks = useRef<PrototypeClick[]>([]);

  const {
    files,
    others: { seconds, type },
  } = fiveSecondsSection;

  const isInstructionsScreen = currentSectionScreenIndex === 0;
  const image =
    fiveSecondsSection.others.type === 'image'
      ? files.find((f) => f.type === StorageFileType.FiveSecondsImage)
      : undefined;
  const frame =
    fiveSecondsSection.others.type === 'prototype' ? fiveSecondsSection.others.frame : undefined;
  const frameDetails: FigmaFrame | undefined = (designPrototype?.frames.find(
    (item) => item.id === frame?.id
  ) || designPrototype?.frames[0]) as FigmaFrame | undefined;

  const saveClick: React.MouseEventHandler<HTMLImageElement> = (click) => {
    const clickPosition = getClickPosition(idImageContainer, click);

    const prototypeClick = {
      ...clickPosition,
      timestamp: Date.now(),
      type: 'missclick',
    } as PrototypeClick;

    clicks.current = [...clicks.current, prototypeClick];

    onIntermediateAnswer?.(fiveSecondsSection._id, {
      type: UsabilitySectionType.FiveSeconds,
      clicks: clicks.current,
    });
  };

  const saveAnswerAndContinue = useCallback(() => {
    onSaveAnswerImmediately?.(fiveSecondsSection._id, {
      type: UsabilitySectionType.FiveSeconds,
      clicks: clicks.current,
    });
    onFinishAnswerScreen?.();
  }, [fiveSecondsSection._id, onFinishAnswerScreen, onSaveAnswerImmediately]);

  useTimer({
    canStart: !isInstructionsScreen,
    duration: seconds * 1000,
    onTimerEnd: saveAnswerAndContinue,
    paused: isVisibleInstructionsAgain,
  });

  return (
    <div className={styles.view_section_wrapper_no_size_limit}>
      {isInstructionsScreen || isVisibleInstructionsAgain ? (
        <SectionInstructions
          title={t('components.projects.sections.five-seconds.view.instructions.title')}
          description={t('components.projects.sections.five-seconds.view.instructions.description')}
        />
      ) : frameDetails && type === 'prototype' ? (
        <FigmaPrototypeImage
          imgFileId={frameDetails.image?.id || ''}
          width={frameDetails.bounds.width}
          onClick={saveClick}
          containerId={idImageContainer}
        />
      ) : image ? (
        <FigmaPrototypeImage
          imgFileId={image.id}
          onClick={saveClick}
          containerId={idImageContainer}
        />
      ) : (
        <div
          className={allClasses(
            isEmbeddedIframe
              ? styles.view_section_wrapper_with_top_margin_embedded
              : styles.view_section_wrapper_with_top_margin,
            'mt-0'
          )}>
          <div className='flex justify-center items-center bg-main-10 p-10 rounded-lg'>
            <PlaceholderSmallIcon className='w-10 h-10' />
          </div>
          <p className='h5 text-main-contrast mt-10'>
            {t('components.projects.sections.five-seconds.view.no-preview.title')}
          </p>
          <p className='body-l text-main-contrast mt-2'>
            {t('components.projects.sections.five-seconds.view.no-preview.subtitle')}
          </p>
        </div>
      )}
    </div>
  );
};
