import React from 'react';

interface ProgressLineProps {
  completed: number;
}

export const ProgressLine = ({ completed }: ProgressLineProps) => {
  return (
    <div className='w-full h-1 flex relative bg-main-10' aria-label='Progress line'>
      <div
        className='absolute top-0 left-0 h-1 bg-main-highlight'
        style={{
          width: `${completed}%`,
        }}
        aria-label={`Progress completed: ${completed}`}
      />
    </div>
  );
};
