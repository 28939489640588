import { UserPermissionsConfig } from '@iteration1/permission-validators';

import Firebase from '../../../../services/auth/firebase';

import { StorageFile } from '../../api-cloud-storage/api-models';
import { apiIteration1, useIteration1Resource } from '../config';
import { CurrentUserCreateInput, CurrentUserUpdateInput, CurrentUserOutput } from './api-models';
import { endpoints } from './endpoints';

export * from './api-models';

export const useCurrentUser = () => {
  return useIteration1Resource<CurrentUserOutput>(endpoints.v1.currentUser);
};

export const createNewCurrentUser = async (
  user: CurrentUserCreateInput
): Promise<CurrentUserOutput> => {
  const response = await apiIteration1.post<CurrentUserOutput>(endpoints.v1.currentUser, user);
  return response.data;
};

export const updateCurrentUser = async (user: CurrentUserUpdateInput): Promise<undefined> => {
  const response = await apiIteration1.put<undefined>(endpoints.v1.currentUser, user);
  return response.data;
};

export const getCurrentUser = async (): Promise<CurrentUserOutput> => {
  const response = await apiIteration1.get<CurrentUserOutput>(endpoints.v1.currentUser);
  return response.data;
};

export const removeCurrentUser = async (): Promise<void> => {
  await apiIteration1.destroy(endpoints.v1.currentUser);
};

export const getCurrentUserPermissionConfig = async (): Promise<UserPermissionsConfig> => {
  //  TODO: add later when multiple resources can be shared between users
  return {};
};

export const uploadCurrentUserProfilePicture = async (image: File): Promise<StorageFile> => {
  const formData = new FormData();
  formData.append('image', image);

  const result = await apiIteration1.patch<StorageFile>(
    endpoints.v1.currentUserProfileImage,
    formData
  );

  return result.data;
};

export const sendResetPasswordEmail = async (email: string): Promise<void> => {
  return Firebase.sendPasswordResetEmail(email);
};

export const confirmPasswordReset = async (resetCode: string, password: string): Promise<void> => {
  return Firebase.confirmPasswordReset(resetCode, password);
};

export const verifyPasswordResetCode = async (resetCode: string) => {
  return Firebase.verifyPasswordResetCode(resetCode);
};

export const resendEmailVerification = async () => {
  const response = await apiIteration1.post(endpoints.v1.requestResendAccountConfirmationEmail);
  return response.data;
};

export const confirmEmailVerification = async (payload: {
  email: string;
  emailConfirmCode: string;
}) => {
  const response = await apiIteration1.post(endpoints.v1.confirmEmail, payload);
  return response.data;
};
