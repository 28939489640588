import { useState, useCallback, useRef } from 'react';

type Toggle = () => void;
type Open<T = undefined> = (context?: T) => void;
type Close = () => void;
type ToggleService<T = undefined> = {
  isOpen: boolean;
  context: T | undefined;
  toggle: Toggle;
  open: Open<T>;
  close: Close;
  toggleCount: number;
};

export const useToggle = <T = undefined>(
  initialState = false,
  initialContext = undefined
): ToggleService<T> => {
  const toggleCount = useRef(0);

  const [{ isOpen, context }, setState] = useState<{
    isOpen: boolean;
    context?: T;
  }>({
    isOpen: initialState,
    context: initialContext,
  });

  const open: Open<T> = useCallback(
    (context: T | undefined) =>
      setState(() => {
        toggleCount.current++;
        return { isOpen: true, context };
      }),
    []
  );

  const close: Close = useCallback(
    () =>
      setState(() => {
        toggleCount.current++;
        return { isOpen: false };
      }),
    []
  );

  const toggle: Toggle = useCallback(
    () =>
      setState((prevState) => {
        toggleCount.current++;
        return { isOpen: !prevState.isOpen, context: undefined };
      }),
    []
  );

  return { isOpen, context, toggle, open, close, toggleCount: toggleCount.current };
};
