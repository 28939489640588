import { useCallback, useEffect, useRef, useState } from 'react';

interface UnmovedMouseService {
  isUnmoved: boolean;
  resetUnmoved: () => void;
}

interface UnmovedMouseServiceProps {
  interval?: number;
  timeLimit: number;
}

export const useUnmovedMouse = ({
  interval = 500,
  timeLimit,
}: UnmovedMouseServiceProps): UnmovedMouseService => {
  const [isUnmoved, setIsUnmoved] = useState<boolean>(false);

  const lastMouseMove = useRef(Date.now());

  const resetUnmoved = useCallback(() => {
    setIsUnmoved(false);
    lastMouseMove.current = Date.now();
  }, []);

  useEffect(() => {
    lastMouseMove.current = Date.now();

    const handleMouseMove = () => {
      lastMouseMove.current = Date.now();
      setIsUnmoved(false);
    };

    const checkInterval = setInterval(() => {
      if (Date.now() - lastMouseMove.current > timeLimit) setIsUnmoved(true);
    }, interval);

    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      clearInterval(checkInterval);
    };
  }, [interval, timeLimit]);

  return { isUnmoved, resetUnmoved };
};
