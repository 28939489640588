import { allClasses } from '../../services/utilities/array';

interface OutsideModalClickProps {
  isVisibleModal: boolean;
  onClick?: () => void;
}

export const OutsideModalClick = ({ isVisibleModal, onClick }: OutsideModalClickProps) => {
  return (
    <div
      className={allClasses(
        !isVisibleModal && 'hidden',
        'fixed left-0 right-0 top-0 bottom-0 z-40 cursor-default'
      )}
      {...{ onClick }}
    />
  );
};
