import { DiscoverySectionType, UsabilitySectionType } from '../../services/api-iteration1';
import { allClasses } from '../../../services/utilities/array';

import {
  ChecksIcon,
  FirstClickIcon,
  FiveSecondsIcon,
  LegalIcon,
  MultipleChoiceIcon,
  OpenWordChoiceIcon,
  PreferenceTestIcon,
  RatingScaleIcon,
  SectionsIcon,
  SingleGoalIcon,
  TimeIcon,
  UsersIcon,
  YesNoIcon,
} from '../../assets/icons';

export const renderSectionIcon = (sectionType: DiscoverySectionType | UsabilitySectionType) => {
  switch (sectionType) {
    case DiscoverySectionType.YesNo:
      return <YesNoIcon />;
    case DiscoverySectionType.RatingScale:
      return <RatingScaleIcon />;
    case DiscoverySectionType.MultipleChoice:
      return <MultipleChoiceIcon />;
    case DiscoverySectionType.OpenWordChoice:
      return <OpenWordChoiceIcon />;
    case UsabilitySectionType.SingleGoal:
      return <SingleGoalIcon />;
    case UsabilitySectionType.FiveSeconds:
      return <FiveSecondsIcon />;
    case UsabilitySectionType.FirstClick:
      return <FirstClickIcon />;
    case DiscoverySectionType.Preference:
      return <PreferenceTestIcon />;
    case DiscoverySectionType.Legal:
      return <LegalIcon />;
    default:
      break;
  }
};

export const renderAnalyticsIcon = (
  result: string,
  type: 'users' | 'sections' | 'time' | 'checks' | 'first-click' | 'missclick',
  className?: string
) => {
  switch (result) {
    case 'danger-result':
      if (type === 'users') {
        return <UsersIcon className={allClasses(className, 'icon-danger icon-circle-danger')} />;
      } else if (type === 'sections') {
        return <SectionsIcon className={allClasses(className, 'icon-danger icon-circle-danger')} />;
      } else if (type === 'time') {
        return <TimeIcon className={allClasses(className, 'icon-danger icon-circle-danger')} />;
      } else if (type === 'checks') {
        return <ChecksIcon className={allClasses(className, 'icon-danger')} />;
      }

      return <FirstClickIcon className={allClasses(className, 'icon-danger')} />;

    case 'success-result':
      if (type === 'users') {
        return (
          <UsersIcon className={allClasses(className, 'icon-success-20 icon-circle-success-20')} />
        );
      } else if (type === 'sections') {
        return (
          <SectionsIcon
            className={allClasses(className, 'icon-success-20 icon-circle-success-20')}
          />
        );
      } else if (type === 'time') {
        return (
          <TimeIcon className={allClasses(className, 'icon-success-20 icon-circle-success-20')} />
        );
      } else if (type === 'checks') {
        return <ChecksIcon className={allClasses(className, 'icon-success-20')} />;
      }

      return <FirstClickIcon className={allClasses(className, 'icon-success-20')} />;

    case 'warning-result':
      if (type === 'users') {
        return <UsersIcon className={allClasses(className, 'icon-warning icon-circle-warning')} />;
      } else if (type === 'sections') {
        return (
          <SectionsIcon className={allClasses(className, 'icon-warning icon-circle-warning')} />
        );
      } else if (type === 'time') {
        return <TimeIcon className={allClasses(className, 'icon-warning icon-circle-warning')} />;
      } else if (type === 'checks') {
        return <ChecksIcon className={allClasses(className, 'icon-warning ')} />;
      }

      return <FirstClickIcon className={allClasses(className, 'icon-warning')} />;

    case 'main-contrast':
      if (type === 'users') {
        return (
          <UsersIcon
            className={allClasses(className, 'icon-main-contrast icon-stroke-circle-main-contrast')}
          />
        );
      } else if (type === 'sections') {
        return (
          <SectionsIcon
            className={allClasses(className, 'icon-main-contrast icon-stroke-circle-main-contrast')}
          />
        );
      } else if (type === 'time') {
        return (
          <TimeIcon
            className={allClasses(
              className,
              'icon-main-contrast icon-stroke-circle-main-contrast icon-stroke-polyline-main-contrast'
            )}
          />
        );
      } else if (type === 'checks') {
        return <ChecksIcon className={allClasses(className, 'icon-main-contrast')} />;
      }

      return <FirstClickIcon className={allClasses(className, 'icon-main-contrast')} />;

    default:
      return null;
  }
};
