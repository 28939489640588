import { FetchOptions } from '../../../../../../services/api/resource-fetch-factory';
import { createQueryUrl } from '../../../../../../services/api/utils';

import { apiIteration1, usePublicIteration1Resource } from '../../../config';
import {
  NewSectionInput,
  SectionFileRemovalDetails,
  SectionFileUploadDetails,
  SectionStorageFile,
} from '../../api-models';
import { UserTestVersionOutput } from '../versions';
import { UserTestProjectSection, UserTestProjectSectionInput } from './api-models';
import { endpoints } from './endpoints';

export { endpoints };
export * from './api-models';

export const addUserTestSection = (
  userTestId: string,
  versionId: string,
  payload: NewSectionInput
) =>
  apiIteration1.post<UserTestProjectSection>(endpoints.v1.sections(userTestId, versionId), payload);

export const updateUserTestSection = (
  userTestId: string,
  versionId: string,
  sectionId: string,
  payload: UserTestProjectSectionInput
) => apiIteration1.patch(endpoints.v1.section(userTestId, versionId, sectionId), payload);

export const sortUserTestSections = (
  userTestId: string,
  versionId: string,
  orderedSectionIds: string[]
) =>
  apiIteration1.put(endpoints.v1.sort_sections(userTestId, versionId), {
    order: orderedSectionIds,
  });

export const deleteUserTestSection = (userTestId: string, versionId: string, sectionId: string) =>
  apiIteration1.destroy(endpoints.v1.section(userTestId, versionId, sectionId));

export const duplicateUserTestSection = (
  userTestId: string,
  versionId: string,
  sectionId: string
) => apiIteration1.post(endpoints.v1.section_duplicate(userTestId, versionId, sectionId));

export const useUserTestVersionTestData = (
  userTestId: string,
  versionId: string,
  options?: FetchOptions
) => {
  return usePublicIteration1Resource<
    Pick<UserTestVersionOutput, 'status' | 'sections' | 'designPrototype'> | undefined
  >(endpoints.v1.sections(userTestId, versionId), options);
};

export const uploadUserTestVersionSectionFile = async (details: {
  userTestId: string;
  versionId: string;
  sectionId: string;
  fileDetails: SectionFileUploadDetails;
}): Promise<SectionStorageFile> => {
  const formData = new FormData();
  formData.append('file', details.fileDetails.file);
  formData.append('type', details.fileDetails.type);

  if (details.fileDetails.indexId) formData.append('indexId', String(details.fileDetails.indexId));

  const result = await apiIteration1.post<SectionStorageFile>(
    endpoints.v1.section_upload_file(details.userTestId, details.versionId, details.sectionId),
    formData
  );

  return result.data;
};

export const removeUserTestVersionSectionFile = (details: {
  userTestId: string;
  versionId: string;
  sectionId: string;
  fileDetails: SectionFileRemovalDetails;
}) => {
  const { id: fileId, ...queryParams } = details.fileDetails;

  return apiIteration1.destroy(
    createQueryUrl(
      endpoints.v1.section_remove_file(
        details.userTestId,
        details.versionId,
        details.sectionId,
        fileId
      ),
      { ...queryParams }
    )
  );
};
