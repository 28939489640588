import { ReactNode } from 'react';

import { useTranslationContext } from '../../../services/translation';
import {
  BillingPeriod,
  BillingUpdateInput,
  PaymentPlanOutput,
} from '../../services/api-iteration1/billing/api-models';
import { usePromoCodeContext } from '../../services/billing/usePromoCode';

import { SubscriptionPaymentSummaryCard, InvoicePaymentSummaryCard } from '../cards';

interface CheckoutContainerProps {
  billingInfo: BillingUpdateInput | null;
  button: (disabled: boolean) => React.ReactNode;
  plan: PaymentPlanOutput;
  billingPeriod: BillingPeriod;
  invoiceId: string | undefined;
  children?: ReactNode;
}

export const CheckoutContainer = ({
  billingInfo,
  button,
  children,
  plan,
  billingPeriod,
  invoiceId,
}: CheckoutContainerProps) => {
  const { t } = useTranslationContext.useContext();

  return (
    <usePromoCodeContext.ProvideContext>
      <div className='h-full'>
        <div className='my-10 text-center' id='modal-title'>
          <h5 className='mb-2'>
            {t('pages.account.tabs.plan-billing.modal.checkout.title', {
              planName: t(`pages.account.tabs.plan-billing.plans.${plan.name}.title`),
            })}
          </h5>
          <p className='text-sm leading-5 text-neutral-70'>
            {t('pages.account.tabs.plan-billing.modal.checkout.subtitle')}
          </p>
        </div>
        <div className='flex justify-center h-full container-with-margin mt-8'>
          {children}
          {invoiceId ? (
            <InvoicePaymentSummaryCard {...{ invoiceId, plan, billingPeriod, button }} />
          ) : (
            <SubscriptionPaymentSummaryCard {...{ plan, billingPeriod, billingInfo, button }} />
          )}
        </div>
      </div>
    </usePromoCodeContext.ProvideContext>
  );
};
