import { useTranslationContext } from '../../../services/translation';
import { BillingOutput } from '../../services/api-iteration1/billing/api-models';
import { allClasses } from '../../../services/utilities/array';
import { isExpiredPaymentMethod } from '../../services/api-iteration1/billing';

import { useToggle } from '../../../components';

import { CreditCardIconOn } from '../../assets/icons';
import { AddCardModal } from '../modals/AddCardModal';

interface PaymentMethodCardProps {
  showBorder?: boolean;
  displaySmall?: boolean;
  paymentMethod: BillingOutput['paymentMethod'];
  onChangeSuccess?: (newPaymentMethod: BillingOutput['paymentMethod']) => void;
}

export const PaymentMethodCard = ({
  showBorder,
  displaySmall,
  paymentMethod,
  onChangeSuccess,
}: PaymentMethodCardProps) => {
  const { t } = useTranslationContext.useContext();
  const { isOpen: isVisibleCardModal, open: openCardModal, close: closeCardModal } = useToggle();

  const isExpired = paymentMethod && isExpiredPaymentMethod(paymentMethod);

  return (
    <div
      className={allClasses(
        'w-full flex flex-col',
        !displaySmall && 'mb-5',
        showBorder && 'px-5 border-2 border-main-10 border-solid rounded-lg'
      )}>
      <div className='flex flex-row py-5 items-center justify-between border-b-2 border-main-10 border-solid'>
        <span className='flex flex-row items-center'>
          <CreditCardIconOn />
          <span className='pl-5 text-base leading-5 text-main-contrast font-bold'>
            {t('pages.account.tabs.plan-billing.payment-method.title')}
          </span>
        </span>
      </div>
      <div className={showBorder ? 'p-10' : 'py-6'}>
        {paymentMethod ? (
          <>
            <div className='flex flex-wrap justify-between items-end mb-2.5 text-xl text-neutral-30 leading-30'>
              <span className='font-bold text-main-contrast mr-4'>
                **** **** **** {paymentMethod.lastDigits}
              </span>
              <span className='mx-4'>
                {paymentMethod.expMonth}/{paymentMethod.expYear}
              </span>
              <span className='mx-4'>{paymentMethod.cardType}</span>
              <span
                className={allClasses(
                  'font-medium text-info text-base leading-7 border-b-default border-main-70 border-solid cursor-pointer',
                  displaySmall && 'mt-4'
                )}
                onClick={() => openCardModal()}>
                {t('pages.account.tabs.plan-billing.payment-method.change-credit-card')}
              </span>
              {isExpired && <div className='text-danger'>EXPIRED</div>}
            </div>
          </>
        ) : (
          <div className='flex justify-between mb-10'>
            <p className='text-neutral-80 text-lg leading-6'>
              {t('pages.account.tabs.plan-billing.payment-method.no-credit-card')}
            </p>
            <span
              className='text-info text-base leading-7 border-b-default border-main-70 border-solid cursor-pointer'
              onClick={() => openCardModal()}>
              {t('pages.account.tabs.plan-billing.payment-method.add-credit-card')}
            </span>
          </div>
        )}
      </div>
      {isVisibleCardModal && (
        <AddCardModal isOpen closeModal={closeCardModal} onSuccess={onChangeSuccess} />
      )}
    </div>
  );
};
