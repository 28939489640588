import { useState } from 'react';

import { allClasses } from '../../services/utilities/array';

import { Dropdown, DropdownProps, Item } from './Dropdown';

interface DropdownSearchProps extends Omit<DropdownProps, 'renderInput'> {
  id?: string;
}

export const DropdownSearch = ({
  items,
  selectItem,
  className,
  suffix,
  suffixClassName,
  suffixClassNameOpen,
  ...otherProps
}: DropdownSearchProps) => {
  const { id, value } = otherProps;

  const [text, setText] = useState(() => {
    const index = items.findIndex((item) => item.value === value);
    return index > -1 ? items[index].label : '';
  });

  const filteredItems = text
    ? items.filter((item) => item.label.toLowerCase().startsWith(text.toLowerCase()))
    : items;

  const selectItemAndSyncInput = (item: Item) => {
    selectItem(item);
    setText(item.label);
  };

  return (
    <Dropdown
      items={filteredItems}
      selectItem={selectItemAndSyncInput}
      {...otherProps}
      renderInput={({ placeholder, name, value, open, onBlur, onChange, isOpen, toggle }) => (
        <>
          <input
            className={className}
            onFocus={open}
            value={value || ''}
            onChange={(e) => {
              onChange?.(e);
              setText(e.target.value);
            }}
            {...{ id, name, placeholder, onBlur }}
          />
          <span
            className={allClasses(suffixClassName, isOpen && suffixClassNameOpen)}
            onClick={toggle}>
            {suffix}
          </span>
        </>
      )}
    />
  );
};
