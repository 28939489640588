import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { handleErrorSilently, useErrorHandlerContext } from '../../../services/error-handling';
import { useLocalStorage } from '../../../services/local-storage';
import { getDeviceDetails } from '../../../services/device';
import { isUndefined } from '../../../services/utilities/value';
import { debounce } from '../../../services/utilities/debounce';
import { useTimer } from '../../../services/timer';
import { useTestSectionDuration } from '../../../services/record';
import { useTestPauseModalContext } from '../../../services/record/useTestPauseModal';

import { getSortedSections } from './utils';
import {
  DefaultSectionType,
  isUsabilitySection as isUsabilitySectionType,
  QuestionAnswer,
  VersionStatus,
} from '../api-iteration1';
import { useCurrentUserContext } from '../current-user';
import { localStorageKeys } from '../local-storage';
import { validateSectionAnswers } from '../validation';
import { useShowInstructionsContext } from './useShowInstructions';
import {
  defaultSectionScreens,
  getSectionsScreens,
  SectionScreenGroup,
} from '../sections/getSectionsScreens';
import { uiEvents } from '../ui-events';

import {
  addUserTestResponseAnswer,
  createNewUserTestResponse,
  giveUpUserTestResponseAnswer,
  sendFeedbackUserTestResponseAnswer,
  uploadUserTestResponseSectionVideo,
  UserTestFeedback,
} from '../api-iteration1/projects/userTest/responses';
import {
  UserTestProjectSection,
  useUserTestVersionTestData,
} from '../api-iteration1/projects/userTest/sections';
import { UserTestVersionOutput } from '../api-iteration1/projects/userTest/versions';

interface ViewUserTestService {
  isLoading: boolean;
  isFirst: boolean;
  isLast: boolean;
  isLastScreen: boolean;
  isLive: boolean;
  isDraft: boolean;
  isDone: boolean;
  isAnswerableScreen: boolean;
  availableInstructions: boolean;
  canSeeInstructions: boolean;
  canGiveUp: boolean;
  canContinue: boolean;
  canOnlyContinue: boolean;
  canSkip: boolean;
  currentSection: UserTestProjectSection | undefined;
  currentSectionScreenIndex: number;
  sectionAnswer: Omit<QuestionAnswer, 'duration'> | undefined;
  startUserTestFlow: () => Promise<void>;
  setSectionAnswer: (answer: Omit<QuestionAnswer, 'duration'>) => void;
  submitAndContinueFlow: () => Promise<void>;
  continueFlow: () => Promise<void>;
  nextSection: () => void;
  nextSectionScreen: () => void;
  restartSection: () => void;
  giveUpTesting: (reason: string) => void;
  sendFeedback: (feedback: Omit<UserTestFeedback, 'sectionId'>) => void;
  immediatelySubmitAnswer: (
    sectionId: string,
    answer: Omit<QuestionAnswer, 'duration'>,
    video?: Blob
  ) => Promise<void>;
  debouncedSubmitAnswer: (sectionId: string, answer: Omit<QuestionAnswer, 'duration'>) => void;
  submitSelectedSectionAnswer: () => Promise<void>;
  error?: string;
  totalAnswerableSections: number;
  designPrototype: UserTestVersionOutput['designPrototype'] | undefined;
  isUsabilitySection: boolean;
  isAvailableOnMobile: boolean;
  skippingSectionInProgress: boolean;
}

interface ViewUserTestServiceProps {
  userTestId: string;
  versionId: string;
  inPreviewVersion?: UserTestVersionOutput;
}

export const useViewUserTest = ({
  userTestId,
  versionId,
  inPreviewVersion,
}: ViewUserTestServiceProps): ViewUserTestService => {
  const { currentUser } = useCurrentUserContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();
  const { closePauseModal } = useTestPauseModalContext.useContext();

  const { getLocalCopy, saveLocalCopy, deleteLocalCopy } = useLocalStorage<{
    index: number;
    responseId: string | undefined;
  }>(localStorageKeys.userTestResponse(versionId));

  const { toggleInstructionsCount } = useShowInstructionsContext.useContext();

  const inPreview = !!inPreviewVersion;

  const [currentSectionIndex, setCurrentSectionIndex] = useState<number>(() => {
    return (!inPreview && getLocalCopy()?.index) || 0;
  });
  const [currentSectionScreens, setCurrentSectionScreens] = useState<
    SectionScreenGroup & { sectionId: string }
  >({ ...defaultSectionScreens, sectionId: '' });
  const [responseId, setResponseId] = useState<string | undefined>(
    () => (!inPreview && getLocalCopy()?.responseId) || undefined
  );
  const [sectionAnswer, setSectionAnswer] = useState<Omit<QuestionAnswer, 'duration'>>();

  const [shouldEnableGiveUp, setShouldEnableGiveUp] = useState<boolean>(false);

  const { data, isLoading, error } = useUserTestVersionTestData(userTestId, versionId, {
    waitForFetch: inPreview,
    placeholderDataWhileWait: inPreviewVersion,
  });

  const { sections = [], status, designPrototype } = data || {};

  const { sortableSections, welcomeSection, thankYouSection } = getSortedSections(sections);

  const orderedSections = [welcomeSection, ...sortableSections, thankYouSection];

  const currentSection = orderedSections.length ? orderedSections[currentSectionIndex] : undefined;
  const currentSectionId = currentSection?._id;
  const currentSectionScreenIndex = currentSectionScreens.index;
  const lastSectionIndex = orderedSections.length - 1;
  const isLast = currentSectionIndex === lastSectionIndex;
  const isLastScreen = currentSectionScreens.index + 1 === currentSectionScreens.totalCount;
  const isFirst = currentSectionIndex === 0;
  const shouldSkipWelcome = isFirst && !!welcomeSection?.others.disabled;
  const totalAnswerableSections = sortableSections.length;
  const isValidAnswer = validateSectionAnswers(currentSection?.type, sectionAnswer);
  const isUsabilitySection = isUsabilitySectionType(currentSection);

  const isLive = status === VersionStatus.Live;
  const isDraft = status === VersionStatus.Draft;
  const isDone = status === VersionStatus.Done;

  const hasSectionNextScreen = useCallback(
    () => currentSectionScreens.index + 1 < currentSectionScreens.totalCount,
    [currentSectionScreens.index, currentSectionScreens.totalCount]
  );

  const isAnswerableSectionScreen = ({ answerScreenIndexes, index }: SectionScreenGroup) =>
    answerScreenIndexes.includes(index);

  const nextSectionScreen = useCallback(() => {
    setCurrentSectionScreens((prev) =>
      prev.index + 1 < prev.totalCount ? { ...prev, index: prev.index + 1 } : prev
    );
  }, []);

  const restartSection = useCallback(() => {
    setCurrentSectionScreens((prev) => ({ ...prev, index: 0 }));
  }, []);

  const currentScreenIndexIsAnswerable = isAnswerableSectionScreen(currentSectionScreens);

  const hasInstructionsScreen = !isUndefined(currentSectionScreens.instructionScreenIndex);
  const isInstructionsScreen =
    currentSectionScreens.instructionScreenIndex === currentSectionScreenIndex;
  const availableInstructions =
    hasInstructionsScreen && (isInstructionsScreen || currentScreenIndexIsAnswerable);

  const canSeeInstructions = hasInstructionsScreen && currentScreenIndexIsAnswerable;

  const sectionDurationRecorder = useTestSectionDuration({
    isAnswerableView: currentScreenIndexIsAnswerable,
    sectionId: currentSection?._id,
  });

  useTimer({
    canStart: currentSectionScreenIndex !== 0,
    duration: 5000,
    onTimerEnd: () => setShouldEnableGiveUp(true),
  });

  const canGiveUp =
    (!!toggleInstructionsCount && currentScreenIndexIsAnswerable) || shouldEnableGiveUp;
  const canContinue =
    (currentSection?.type !== DefaultSectionType.Welcome && !isLast && isValidAnswer) ||
    !currentSectionScreens.answerScreenIndexes.includes(currentSectionScreens.index);
  const canOnlyContinue = !currentScreenIndexIsAnswerable;
  const canSkip = !currentSection?.required && !isFirst && !isLast && !isUsabilitySection;

  const nextSection = useCallback(
    (newResponseId?: string) => {
      if (isLast) return;

      setSectionAnswer(undefined);
      setCurrentSectionIndex((prev) => {
        const next = prev + 1;

        if (!inPreview) saveLocalCopy({ index: next, responseId: responseId || newResponseId });

        return next;
      });
    },
    [inPreview, isLast, responseId, saveLocalCopy]
  );

  const uploadSectionResponseVideo = useCallback(
    async (video: Blob) => {
      if (!currentSectionId || !responseId) return;

      try {
        await uploadUserTestResponseSectionVideo(
          userTestId,
          versionId,
          currentSectionId,
          responseId,
          video
        );
      } catch (err) {
        handleErrorSilently(err);
      }
    },
    [currentSectionId, responseId, userTestId, versionId]
  );

  const submitAnswer = useCallback(
    async (sectionId: string, answer: Omit<QuestionAnswer, 'duration'>, video?: Blob) => {
      try {
        const newAnswer = {
          sectionId,
          answer: {
            ...answer,
            duration: sectionDurationRecorder.current.getTotalDuration(),
          } as QuestionAnswer,
        };

        if (responseId) {
          await addUserTestResponseAnswer(userTestId, versionId, responseId, newAnswer);
          if (video) uploadSectionResponseVideo(video).catch((err) => handleErrorSilently(err));
        }
      } catch (err) {
        handleError(err);
      }
    },
    [
      handleError,
      responseId,
      sectionDurationRecorder,
      uploadSectionResponseVideo,
      userTestId,
      versionId,
    ]
  );

  const submitSelectedSectionAnswer = useCallback(async () => {
    if (!currentSection || !sectionAnswer) return;

    submitAnswer(currentSection._id, sectionAnswer);
  }, [currentSection, sectionAnswer, submitAnswer]);

  const debouncedSubmitAnswer = useMemo(() => debounce(submitAnswer, 1000), [submitAnswer]);

  const immediatelySubmitAnswer = useCallback(
    async (sectionId: string, answer: Omit<QuestionAnswer, 'duration'>, video?: Blob) => {
      try {
        await Promise.all([
          debouncedSubmitAnswer(sectionId, answer),
          debouncedSubmitAnswer.flush(), // Immediately invoke the debounced function
          video ? uploadSectionResponseVideo(video) : true,
        ]);
      } catch (err) {
        handleError(err);
      }
    },
    [handleError, debouncedSubmitAnswer, uploadSectionResponseVideo]
  );

  const createNewResponse = useCallback(async () => {
    if (inPreview || !totalAnswerableSections) return;

    const device = getDeviceDetails();
    const newUserTestResponse = currentUser?.id
      ? {
          userId: currentUser.id,
          totalQuestions: totalAnswerableSections,
          device,
        }
      : {
          totalQuestions: totalAnswerableSections,
          device,
        };

    const userTestResponseDetails = await createNewUserTestResponse(
      userTestId,
      versionId,
      newUserTestResponse
    );
    setResponseId(userTestResponseDetails.id);

    return userTestResponseDetails.id;
  }, [currentUser?.id, inPreview, totalAnswerableSections, userTestId, versionId]);

  const startUserTestFlow = useCallback(async () => {
    if (inPreview) return nextSection();

    try {
      const responseId = await createNewResponse();
      nextSection(responseId);
    } catch (err) {
      handleError(err);
    }
  }, [createNewResponse, handleError, inPreview, nextSection]);

  const continueFlow = useCallback(async () => {
    hasSectionNextScreen() ? nextSectionScreen() : nextSection();
  }, [hasSectionNextScreen, nextSection, nextSectionScreen]);

  const submitAndContinueFlow = async () => {
    if (!inPreview && currentScreenIndexIsAnswerable) await submitSelectedSectionAnswer();

    continueFlow();
  };

  const giveUpTesting = async (reason: string) => {
    if (!inPreview && responseId) {
      uiEvents.giveUpTest.emit();
      await giveUpUserTestResponseAnswer(userTestId, versionId, responseId, reason);
    }
    deleteLocalCopy();
    setCurrentSectionIndex(lastSectionIndex);
    closePauseModal();
  };

  const sendFeedback = async (feedback: Omit<UserTestFeedback, 'sectionId'>) => {
    if (inPreview || !responseId || !currentSectionId) return;

    try {
      await sendFeedbackUserTestResponseAnswer(
        userTestId,
        versionId,
        responseId,
        currentSectionId,
        feedback
      );
    } catch (err) {
      handleErrorSilently(err);
    }
  };

  useLayoutEffect(() => {
    (async () => {
      if (shouldSkipWelcome) {
        try {
          await createNewResponse();
          setCurrentSectionIndex(1);
        } catch (err) {
          handleError(err);
        }
      }
    })();
  }, [createNewResponse, handleError, shouldSkipWelcome]);

  useEffect(() => {
    if (!isDone) return;

    setCurrentSectionIndex(0);
    if (!inPreview) deleteLocalCopy();
  }, [deleteLocalCopy, inPreview, isDone]);

  useEffect(() => {
    if (!currentSectionId) return;

    const sectionsScreens = getSectionsScreens(sections);
    const initialCurrentSectionScreens = currentSectionId
      ? sectionsScreens[currentSectionId]
      : defaultSectionScreens;

    setCurrentSectionScreens((prev) => {
      const sectionHasChanged = prev.sectionId !== currentSectionId;

      return sectionHasChanged
        ? { ...initialCurrentSectionScreens, sectionId: currentSectionId }
        : prev;
    });
  }, [currentSectionId, sections]);

  const isAvailableOnMobile = isLoading || designPrototype?.device === 'mobile';

  return {
    isLoading,
    isFirst,
    isLast,
    isLastScreen,
    isLive,
    isDraft,
    isDone,
    isAnswerableScreen: currentScreenIndexIsAnswerable,
    availableInstructions,
    canSeeInstructions,
    canGiveUp,
    canContinue,
    canOnlyContinue,
    canSkip,
    currentSection,
    currentSectionScreenIndex,
    sectionAnswer,
    startUserTestFlow,
    setSectionAnswer,
    continueFlow,
    nextSection,
    nextSectionScreen,
    restartSection,
    giveUpTesting,
    sendFeedback,
    immediatelySubmitAnswer,
    submitAndContinueFlow,
    debouncedSubmitAnswer,
    error,
    totalAnswerableSections,
    designPrototype,
    submitSelectedSectionAnswer,
    isUsabilitySection,
    isAvailableOnMobile,
    skippingSectionInProgress: shouldSkipWelcome,
  };
};
