import { useEffect, useMemo } from 'react';
import { PermissionPlans } from '@iteration1/permission-validators';

import { useCurrentUserContext } from '../../../services/current-user';
import { useTranslationContext } from '../../../../services/translation';
import {
  isSubscriptionCancelled,
  isSubscriptionReactivable,
  updateBillingRecord,
  useRecentInvoices,
  useUserBillingRecord,
} from '../../../services/api-iteration1/billing';
import {
  BillingOutput,
  BillingUpdateInput,
} from '../../../services/api-iteration1/billing/api-models';
import { usePermissionsContext } from '../../../services/authorization';
import { usePaymentRedirectConfirmationContext } from '../../../services/billing/usePaymentRedirectConfirmation';

import { LoadingOrContent, useToggle } from '../../../../components';
import { BillingHistory } from './components/BillingHistory';

import { PaymentMethodCard } from '../../cards/PaymentMethodCard';
import { BillingInfoForm } from '../../forms/BillingInfoForm';
import { CancelPlanModal } from '../../modals/CancelPlanModal';
import { BillingHeader } from '../../headers';
import { CheckoutModal } from '../../modals/CheckoutModal';
import { AnalyticsScreenName } from '../../layout/AnalyticsScreenName';

interface BillingSettingsProps {
  icon: React.ReactNode;
  title: string;
}

export const BillingSettings = ({ icon, title }: BillingSettingsProps) => {
  const { t } = useTranslationContext.useContext();
  const { currentUser, isLoadingUser } = useCurrentUserContext.useContext();
  const { userPlan } = usePermissionsContext.useContext();
  const { type } = usePaymentRedirectConfirmationContext.useContext();

  const subscription = currentUser?.subscription;
  const subscriptionIdentifier = `${subscription?.status}_${subscription?.plan}_${subscription?.price.value}_${subscription?.price.recurring?.interval}_${subscription?.timeSubscriptionEnd}`;

  const {
    data: recentInvoices,
    mutate: revalidateRecentInvoices,
    isValidating: isValidatingRecentInvoices,
  } = useRecentInvoices();
  const {
    data: billingRecord,
    isLoading,
    mutate: mutateBillingRecord,
    revalidate: revalidateBillingRecord,
  } = useUserBillingRecord();

  const {
    isOpen: isOpenCheckoutModal,
    open: openCheckoutModal,
    close: closeCheckoutModal,
    context: contextCheckout,
  } = useToggle<{ clientSecret: string; invoiceId: string } | undefined>();
  const {
    isOpen: isVisibleCancelPlan,
    open: openCancelPlanModal,
    close: closeCancelPlanModal,
  } = useToggle();

  const initialBillingInfo = useMemo(
    () =>
      billingRecord?.address
        ? {
            company: { ...billingRecord.company },
            address: { ...billingRecord.address },
          }
        : undefined,
    [billingRecord]
  );

  const updateBillingInfo = async (updates: BillingUpdateInput) => updateBillingRecord(updates);

  const mutateBillingInfo = (updates: BillingUpdateInput) =>
    mutateBillingRecord((prev) => {
      return prev
        ? {
            ...prev,
            data: {
              ...prev.data,
              address: updates.address,
              company: updates.company,
            },
          }
        : prev;
    });

  const updatePaymentMethod = (newPaymentMethod: BillingOutput['paymentMethod']) => {
    mutateBillingRecord(
      (prev) => {
        return prev
          ? {
              ...prev,
              data: {
                ...prev.data,
                paymentMethod: newPaymentMethod,
              },
            }
          : prev;
      },
      { revalidate: false }
    );
    setTimeout(revalidateBillingRecord, 2000);
  };

  useEffect(() => {
    if (subscriptionIdentifier) revalidateRecentInvoices();
  }, [subscriptionIdentifier, revalidateRecentInvoices]);

  useEffect(() => {
    if (['success_setup_intent', 'success_plan_change'].includes(type))
      setTimeout(() => {
        revalidateBillingRecord();
      }, 2000);
  }, [revalidateBillingRecord, type]);

  return (
    <>
      <AnalyticsScreenName name='Profile - Billing' />

      <LoadingOrContent isLoading={isLoadingUser}>
        {currentUser && (
          <div className='w-full flex flex-col mb-5 px-5 border-2 border-main-10 border-solid rounded-lg'>
            <div className='flex flex-row py-5 items-center justify-between border-b-2 border-main-10 border-solid'>
              <span className='flex flex-row items-center'>
                {icon}
                <span className='pl-5 text-base leading-5 text-main-contrast font-bold'>
                  {title}
                </span>
              </span>
            </div>
            {userPlan === PermissionPlans.Internal ? (
              <>
                <p className='text-xl text-main-contrast leading-30 font-bold mb-2.5 px-10 pt-10'>
                  {t(`pages.account.tabs.plan-billing.plans.${PermissionPlans.Internal}.title`)}
                </p>
                <p className='text-neutral leading-30 font-bold mb-2.5 px-10'>
                  {t(`pages.account.tabs.plan-billing.plans.${PermissionPlans.Internal}.subtitle`)}
                </p>
              </>
            ) : (
              <LoadingOrContent {...{ isLoading }}>
                <div className='py-10 my-5'>
                  {billingRecord && <BillingHeader openCheckoutModal={openCheckoutModal} />}
                  {billingRecord?.paymentMethod && (
                    <PaymentMethodCard
                      showBorder
                      paymentMethod={billingRecord?.paymentMethod}
                      onChangeSuccess={updatePaymentMethod}
                    />
                  )}
                  {initialBillingInfo && (
                    <BillingInfoForm
                      expectInitialValues
                      onSubmit={updateBillingInfo}
                      onSuccess={mutateBillingInfo}
                      {...{ initialBillingInfo }}
                    />
                  )}
                  <BillingHistory
                    invoices={recentInvoices?.invoices || []}
                    openCheckoutModal={openCheckoutModal}
                    {...{ isValidatingRecentInvoices, revalidateRecentInvoices }}
                  />
                  {currentUser.subscription &&
                    !isSubscriptionCancelled(currentUser.subscription) &&
                    !isSubscriptionReactivable(currentUser.subscription) && (
                      <div className='mt-10 pl-10'>
                        <span
                          className='text-danger text-base leading-5 border-b-default border-main-70 border-solid cursor-pointer'
                          onClick={() => openCancelPlanModal()}>
                          {t('pages.account.tabs.plan-billing.cancel-plan')}
                        </span>
                      </div>
                    )}
                  {isVisibleCancelPlan && <CancelPlanModal isOpen close={closeCancelPlanModal} />}
                </div>
              </LoadingOrContent>
            )}
          </div>
        )}
      </LoadingOrContent>

      {isOpenCheckoutModal && (
        <CheckoutModal
          isOpen
          closeModal={closeCheckoutModal}
          initialStep={contextCheckout ? { step: 'payment', context: contextCheckout } : undefined}
          onChangePaymentMethod={updatePaymentMethod}
          {...{ billingRecord }}
        />
      )}
    </>
  );
};
