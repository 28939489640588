import { SubscriptionOutput } from '../users';
import { BillingOutput } from './api-models';

export const isSubscriptionCanceledAtPeriodEnd = (subscription: SubscriptionOutput): boolean => {
  return subscription.timeSubscriptionEnd < Date.now();
};

export const isSubscriptionPaymentDue = (subscription: SubscriptionOutput | undefined): boolean => {
  return ['incomplete', 'past_due', 'unpaid'].includes(subscription?.status as string);
};

export const isSubscriptionCancelled = (subscription: SubscriptionOutput): boolean => {
  return (
    ['canceled', 'incomplete_expired'].includes(subscription?.status as string) ||
    isSubscriptionCanceledAtPeriodEnd(subscription)
  );
};

export const isSubscriptionReactivable = (
  subscription: SubscriptionOutput | undefined
): boolean => {
  return !!(
    subscription &&
    isSubscriptionActive(subscription) &&
    subscription.cancelAtPeriodEnd &&
    !isSubscriptionCanceledAtPeriodEnd(subscription)
  );
};

export const isSubscriptionUnavailable = (
  subscription: SubscriptionOutput | undefined
): boolean => {
  return !subscription || isSubscriptionCancelled(subscription);
};

export const isSubscriptionActive = (subscription: SubscriptionOutput | undefined): boolean => {
  return !!(
    subscription &&
    ['active'].includes(subscription.status) &&
    !isSubscriptionCanceledAtPeriodEnd(subscription)
  );
};

export const isExpiredPaymentMethod = (
  paymentMethod: NonNullable<BillingOutput['paymentMethod']>
) => {
  const today = new Date();
  const currentMonth = today.getUTCMonth() + 1;
  const currentYear = today.getUTCFullYear();

  const expired = !!(
    !paymentMethod.expYear ||
    !paymentMethod.expMonth ||
    paymentMethod.expYear < currentYear ||
    (paymentMethod.expYear === currentYear && paymentMethod.expMonth < currentMonth)
  );

  return expired;
};
