import { PrototypeClick } from '../';
import { isUndefined } from '../../../../../../../../services/utilities/value';
import { Path } from '../../usability';

export interface NavigatedFrame {
  id: string;
  clicks: PrototypeClick[];
  startTime: number;
  endTime?: number;
}

export const getAnsweredFrames = (navigatedFrames: NavigatedFrame[]) => {
  return navigatedFrames.map((frame, index) => ({
    id: frame.id,
    index,
    clicks: frame.clicks,
    duration: isUndefined(frame.endTime) ? undefined : frame.endTime - frame.startTime,
  }));
};

export const endSingleGoalTest = (paths: Path[], navigatedFrames: NavigatedFrame[]) => {
  const expectedLastFramesIds = paths.reduce((result, path) => {
    const lastPathFrame = path.frames[path.frames.length - 1];
    return [...result, lastPathFrame.id];
  }, [] as string[]);

  const lastNavigatedFrame = navigatedFrames.length
    ? navigatedFrames[navigatedFrames.length - 1]
    : undefined;

  return lastNavigatedFrame ? expectedLastFramesIds.includes(lastNavigatedFrame.id) : false;
};

export const isDirectSingleGoalSuccess = (paths: Path[], navigatedFrames: NavigatedFrame[]) => {
  for (let i = 0; i < paths.length; i++) {
    const currentPath = paths[i];
    const currentPathFrames = currentPath.frames;

    if (currentPathFrames.length !== navigatedFrames.length) break;

    const identicalFrames = navigatedFrames.filter(
      (frame, index) => currentPathFrames[index] && frame.id === currentPathFrames[index].id
    );

    const matchingPath = identicalFrames.length === currentPathFrames.length;

    if (matchingPath) return true;
  }

  return false;
};
