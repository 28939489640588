import Bowser from 'bowser';

type Size = {
  width: number;
  height: number;
};

type Details = {
  name: string;
  version: string;
};

export interface DeviceDetails {
  deviceType: string;
  os: Details;
  browser: Details;
  screen: Size;
  window: Size;
}

export const getDeviceDetails = (): DeviceDetails => {
  const details = Bowser.parse(window.navigator.userAgent);

  return {
    deviceType: details.platform.type || '',
    os: {
      name: details.os.name || '',
      version: details.os.version || '',
    },
    browser: {
      name: !!(navigator as any).brave ? 'brave' : details.browser.name || '',
      version: details.browser.version || '',
    },
    screen: getDeviceScreenSize(),
    window: getPlatformWindowSize(),
  };
};

const getDeviceScreenSize = (): Size => ({
  width: window.screen.width * window.devicePixelRatio,
  height: window.screen.height * window.devicePixelRatio,
});

const getPlatformWindowSize = (): Size => ({
  width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
  height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
});

export const isSafari = () => getDeviceDetails().browser.name.toLowerCase() === 'safari';
