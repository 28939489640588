import { useCallback, useEffect } from 'react';

import { Path, UsabilitySection, UsabilitySectionInput } from '../api-iteration1';
import { useCurrentUserTestVersionContext } from './useCurrentUserTestVersion';
import {
  LivePrototypeNavigationService,
  useLivePrototypeNavigation,
} from '../design-platforms/useLivePrototypeNavigation';

import { prototypeEventHandlers } from '../api-iteration1/design-platforms';

interface LivePrototypeService {
  addNewPath: () => Promise<void> | undefined;
  deletePath: (pathIndex: number) => Promise<void> | undefined;
  deleteFrames: (pathIndex: number, frameIndex: number) => Promise<void> | undefined;
  setPrototypeLocation: LivePrototypeNavigationService['setPrototypeLocation'];
  changeStartFrame: (pathIndex: number, frameId: string) => Promise<void>;
  pathInFocusIndex?: number;
}

interface useEditUsabilitySectionProps {
  section: UsabilitySection;
  editSection: (
    sectionId: string,
    updates: UsabilitySectionInput,
    shouldUpdate?: boolean
  ) => Promise<void> | undefined;
}

const { listeners: prototypeListeners } = prototypeEventHandlers();

export const useEditUsabilitySection = ({
  section,
  editSection,
}: useEditUsabilitySectionProps): LivePrototypeService => {
  const { userTestVersion } = useCurrentUserTestVersionContext.useContext();
  const { pathIndex, setPrototypeLocation } = useLivePrototypeNavigation.useContext();

  const addNewPath = async () => {
    if (!userTestVersion) return;

    const currentPaths = section.others.paths as Path[];
    const firstPath = currentPaths[0];
    const initialFrame = firstPath.frames.find((frame) => frame.index === 0);

    const newPath: Path = {
      index: currentPaths.length || 0,
      frames: initialFrame ? [initialFrame] : [],
    };

    const updates = {
      others: {
        ...section.others,
        paths: [...currentPaths, newPath],
      },
    };

    editSection(section._id, updates);
    setPrototypeLocation({
      pathIndex: newPath.index,
      navigatedFramesIds: newPath.frames.map((frame) => frame.id),
    });
  };

  const deletePath = async (pathIndex: number) => {
    if (!userTestVersion) return;

    const currentPaths = section.others.paths as Path[];

    const remainingPaths = currentPaths
      .filter((path) => path.index !== pathIndex)
      .sort((a, b) => a.index - b.index)
      .map((path, index) => ({ ...path, index }));

    if (!remainingPaths.length) return;

    const updates = {
      others: {
        ...section.others,
        paths: remainingPaths,
      },
    };

    editSection(section._id, updates);

    const newPathInFocus = remainingPaths.find((path) => {
      if (!pathIndex) return path.index === 0;

      if (pathIndex === pathIndex) return path.index === pathIndex - 1;
      if (pathIndex < pathIndex) return path.index === pathIndex - 1;
      if (pathIndex > pathIndex) return path.index === pathIndex;
    });

    setPrototypeLocation(
      newPathInFocus
        ? {
            pathIndex: newPathInFocus.index,
            navigatedFramesIds: newPathInFocus.frames.map((frame) => frame.id),
          }
        : { navigatedFramesIds: [] }
    );
  };

  const deleteFrames = async (pathIndex: number, frameIndex: number) => {
    if (!userTestVersion) return;

    const currentPaths = section.others.paths as Path[];

    if (frameIndex === 0) return deletePath(pathIndex);

    const updatedPaths = [...currentPaths];

    const currentPath = updatedPaths.find((path) => path.index === pathIndex);

    if (!currentPath) return;

    currentPath.frames = currentPath.frames.filter((frame) => frame.index < frameIndex);

    const updates = {
      others: {
        ...section.others,
        paths: updatedPaths,
      },
    };

    editSection(section._id, updates);
    setPrototypeLocation({
      pathIndex: currentPath.index,
      navigatedFramesIds: currentPath.frames.map((frame) => frame.id),
    });
  };

  const changeStartFrame = async (pathIndex: number, frameId: string) => {
    const paths = section?.others?.paths as Path[];

    if (!paths.length) return;

    const updatedPaths = [...paths];
    const currentPath = updatedPaths.find((path) => path.index === pathIndex);

    if (!currentPath) return;

    currentPath.frames = [{ id: frameId, index: 0 }];

    const updates = {
      others: {
        ...section.others,
        paths: updatedPaths,
      },
    };

    await editSection(section._id, updates);
    setPrototypeLocation({
      pathIndex: currentPath.index,
      navigatedFramesIds: currentPath.frames.map((frame) => frame.id),
    });
  };

  const addNewFrame = useCallback(
    (frameId: string) => {
      const currentPaths = section.others.paths as Path[];

      const updatedPaths = [...currentPaths];
      const currentPath = updatedPaths.find((path) => path.index === pathIndex);

      currentPath?.frames.push({ id: frameId, index: currentPath.frames.length });

      const updates = {
        others: {
          ...section.others,
          paths: updatedPaths,
        },
      };

      if (currentPath?.frames.length) {
        editSection(section._id, updates);
      }
    },
    [editSection, pathIndex, section._id, section.others]
  );

  useEffect(() => {
    const addFrameToPath = (
      e: CustomEvent<{
        targetFrameId: string;
      }>
    ) => {
      const navigatedFrameId = e.detail.targetFrameId;

      addNewFrame(navigatedFrameId);
      setPrototypeLocation((prev) => ({
        ...prev,
        navigatedFramesIds: [...prev.navigatedFramesIds, navigatedFrameId],
      }));
    };

    const removeListener = prototypeListeners.onChangeFrameByHotspotClick(addFrameToPath);

    return () => {
      removeListener();
    };
  }, [addNewFrame, setPrototypeLocation]);

  return {
    addNewPath,
    deletePath,
    deleteFrames,
    setPrototypeLocation,
    changeStartFrame,
    pathInFocusIndex: pathIndex,
  };
};
