import React, { useEffect, useMemo, useRef } from 'react';

import { allClasses } from '../../../../services/utilities/array';
import { Coordinates, getClickPosition } from '../../../../services/position';
import {
  FigmaDesignPrototypeVersion,
  prototypeEventHandlers,
} from '../../../services/api-iteration1/design-platforms';
import { usePreloadResources } from '../../../../services/preload';
import { useElementResize } from '../../../../services/resize';
import { getHotspotPosition } from '../../../services/api-iteration1/design-platforms/figma/utils';

import { useToggle } from '../../../../components';

import { FigmaPrototypeImage } from './FigmaPrototypeImage';
import { getCrossOriginAnonymousImageSource } from './utils';

interface FigmaPrototypeProps {
  id: string;
  designPrototype: FigmaDesignPrototypeVersion;
  startingFrameId?: string;
  currentFrameId?: string;
  hotspotHints?: boolean;
  reachedEndFrame?: boolean;
  onChangeFrame?: (frameId: string, click?: Coordinates) => void;
  onMissClick?: (click: Coordinates) => void;
  onInit?: () => void;
}

const { triggers: prototypeTriggers } = prototypeEventHandlers();

export const FigmaPrototype = ({
  designPrototype,
  id,
  currentFrameId,
  hotspotHints,
  reachedEndFrame,
  onChangeFrame,
  onMissClick,
  onInit,
}: FigmaPrototypeProps) => {
  const isInitialized = useRef(false);
  const imgRef = useRef<HTMLImageElement>(null);
  const dimensions = useElementResize(imgRef);

  const {
    isOpen: visibleHotspotHints,
    open: showHotspotHints,
    close: hideHotspotHints,
  } = useToggle();

  const { frames } = designPrototype || {};
  const currentFrame = frames?.find((frame) => frame.id === currentFrameId);

  const framesImagesPreloads = useMemo(() => {
    const links = frames.map((frame) =>
      frame?.image
        ? {
            as: 'image',
            rel: 'preload',
            crossOrigin: 'anonymous',
            href: getCrossOriginAnonymousImageSource(frame.image.id),
          }
        : undefined
    );

    return links.filter(Boolean) as NonNullable<typeof links[number]>[];
  }, [frames]);

  usePreloadResources(framesImagesPreloads);

  const changeFrame = (e: React.MouseEvent, frameId: string) => {
    const currentClickPosition = getClickPosition(id, e);

    onChangeFrame?.(frameId, currentClickPosition);
    prototypeTriggers.changeFrameByHotspotClick(frameId);
  };

  const clickPrototypeOutsideHotspot: React.MouseEventHandler<HTMLImageElement> = (e) => {
    if (hotspotHints) showHotspotHints();

    const currentClickPosition = getClickPosition(id, e);

    if (!currentClickPosition) return;

    onMissClick?.(currentClickPosition);
  };

  useEffect(() => {
    if (visibleHotspotHints) setTimeout(hideHotspotHints, 100);
  }, [hideHotspotHints, visibleHotspotHints]);

  useEffect(() => {
    if (isInitialized.current) return;

    isInitialized.current = true;

    onInit?.();
  }, [onInit]);

  return currentFrame ? (
    <FigmaPrototypeImage
      {...{ imgRef }}
      containerId={id}
      onClick={clickPrototypeOutsideHotspot}
      width={currentFrame.bounds.width}
      isEndFrame={reachedEndFrame}
      imgFileId={(currentFrame?.image && currentFrame.image.id) || ''}>
      {currentFrame.hotspots.map((hotspot) => {
        const position = getHotspotPosition({
          hotspotBoundingBox: hotspot.bounds,
          frameBoundingBox: currentFrame.bounds,
          htmlElDimensions: dimensions,
        });

        return (
          <div
            key={`${hotspot.transitionNodeId}-${hotspot.bounds.x}-${hotspot.bounds.y}`}
            className={allClasses(
              'absolute',
              hotspotHints && 'cursor-pointer',
              visibleHotspotHints && 'border-2 border-solid border-highlight'
            )}
            style={position}
            onClick={(e) => {
              e.stopPropagation();
              changeFrame(e, hotspot.transitionNodeId);
            }}
          />
        );
      })}
    </FigmaPrototypeImage>
  ) : null;
};
