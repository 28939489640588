import { tryJSONParse } from '../utilities/json';
import { isEmpty } from '../utilities/value';

export const getFromStorage = <T>(key: string): T | null => {
  const value = localStorage.getItem(key);
  return value && tryJSONParse(value, value);
};

export const saveToStorage = <T>(key: string, value: T) => {
  !isEmpty(value) && localStorage.setItem(key, JSON.stringify(value));
};

export const removeFromStorage = (key: string) => {
  localStorage.removeItem(key);
};
