import { useTranslationContext } from '../../../../services/translation';
import { allClasses } from '../../../../services/utilities/array';

interface AnalyticsPercentageProps {
  answersNumber: number;
  answerPercentage: number;
  containerClassName?: string;
}

export const AnalyticsPercentage = ({
  answerPercentage,
  answersNumber,
  containerClassName,
}: AnalyticsPercentageProps) => {
  const { t } = useTranslationContext.useContext();

  return (
    <span
      className={allClasses(
        'flex flex-col',
        answerPercentage > 0 ? 'text-main-contrast' : 'text-neutral-30',
        containerClassName
      )}>
      <span className='text-2xl font-bold leading-8'>{answerPercentage}%</span>
      <span className='text-sm leading-5'>
        {answersNumber} {t('common.answer', { count: answersNumber })}
      </span>
    </span>
  );
};
