import { useErrorHandlerContext } from '../../services/error-handling';

import { FormStatus } from './Form';

interface FormErrorProps {
  status: FormStatus;
  externalErr?: string;
}

export const FormError = ({ status, externalErr }: FormErrorProps) => {
  //  TODO: consume status from Form Hook (useFormikState)
  const { translateErrorKeys } = useErrorHandlerContext.useContext();
  const errorMessages = translateErrorKeys(status?.messageKeys || externalErr || 'errors.default');

  if (status?.state === 'error' || externalErr)
    return (
      <div className='py-3 mb-3 bg-danger-10 text-danger text-center text-lg leading-normal font-normal rounded-lg'>
        {errorMessages.map((message, index) => (
          <p key={`${message}-${index}`}>{message}</p>
        ))}
      </div>
    );

  return null;
};
