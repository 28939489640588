import i18n, { TOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { createContextService } from '../context';

type TranslationsObject = Record<string, any>;

interface TranslationServiceProps {
  languages: {
    [language: string]: {
      translation: TranslationsObject;
    };
  };
}

interface TranslationService {
  t: (key: string | string[], options?: TOptions | undefined) => string;
}

const useTranslation = ({ languages }: TranslationServiceProps): TranslationService => {
  i18n.use(initReactI18next).init({
    resources: languages,
    lng: 'en',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
    compatibilityJSON: 'v3',
  });

  return { t: (key: string | string[], options?: TOptions) => i18n.t(key, options) };
};

export const useTranslationContext = createContextService(useTranslation);
