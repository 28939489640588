import { useState } from 'react';

import { useTranslationContext } from '../../../services/translation';
import { cancelSubscription } from '../../services/api-iteration1/billing';
import { useErrorHandlerContext } from '../../../services/error-handling';
import { useCurrentUserContext } from '../../services/current-user';
import { analyticsEvents } from '../../services/app-analytics/events';
import { sendAnalyticsEvent } from '../../../services/app-analytics';

import { Button, Modal } from '../../../components';

import { CloseIcon } from '../../assets/icons';

interface CancelPlanModalProps {
  isOpen: boolean;
  close: () => void;
  onSuccess?: () => void;
}

export const CancelPlanModal = ({ isOpen, close, onSuccess }: CancelPlanModalProps) => {
  const { t } = useTranslationContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();
  const { reloadUser, currentUser } = useCurrentUserContext.useContext();

  const [feedback, setFeedback] = useState<string>();

  const cancelUserSubscription = async () => {
    try {
      await cancelSubscription();
      sendAnalyticsEvent(
        analyticsEvents.downgradePlan({
          user_id: currentUser?.id as string,
          old_plan_name: currentUser?.subscription?.plan.name as string,
          new_plan_name: 'Free',
          feedback,
        })
      );
      close();
      onSuccess?.();
      //  wait in order for the user changes to be reflected in the db
      setTimeout(() => reloadUser(), 2000);
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <Modal
      title={t('pages.account.tabs.plan-billing.cancel-plan-modal.title')}
      isOpen={isOpen}
      onClose={close}
      variant='regular'
      closeModalComponent={<CloseIcon className='icon-hover-main-contrast' />}
      closeIconClassName='absolute top-5 right-5'>
      <div className='pt-10 pb-20 text-center'>
        <h2 className='my-10 text-main-contrast leading-8 text-2xl font-bold'>
          {t('pages.account.tabs.plan-billing.cancel-plan-modal.title')}
        </h2>
        <div className='mb-10 text-main-contrast text-lg leading-6 font-medium'>
          {t('pages.account.tabs.plan-billing.cancel-plan-modal.description-part-one')}
          <p className='mb-0 text-danger-60'>
            {t('pages.account.tabs.plan-billing.cancel-plan-modal.description-part-two')}
          </p>
        </div>
        <div className='mb-10 mx-16'>
          <textarea
            autoFocus
            placeholder={t('pages.account.tabs.plan-billing.cancel-plan-modal.reason')}
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            className='py-3 px-5 w-full h-24 bg-main border-2 rounded-lg border-solid border-main-10 text-lg placeholder-neutral hover:border-main-highlight focus:border-main-highlight focus:outline-none'
          />
        </div>
        <div className='flex flex-row items-center justify-center'>
          <Button
            className='button__filled mr-4 py-2.5 px-10 justify-center bg-main border-2 border-solid border-main-10 hover:enabled:bg-main-10'
            type='button'
            onClick={close}>
            {t('pages.account.tabs.plan-billing.cancel-plan-modal.cancel')}
          </Button>
          <Button
            className='button__filled py-2.5 px-10 justify-center bg-danger-60 text-main hover:enabled:bg-danger'
            type='submit'
            onClick={cancelUserSubscription}
            loadingIconType='light'
            loadingOpacity='50'>
            {t('pages.account.tabs.plan-billing.cancel-plan-modal.submit')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
