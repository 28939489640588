import { allClasses } from '../../../../services/utilities/array';

import { DeleteIcon } from '../../../assets/icons';

interface FrameNameProps {
  imageSrc?: string;
  frameIndex: number;
  pathIndex: number;
  withIssues: boolean;
  onDeleteFrames?: (pathIndex: number, frameIndex: number) => void;
  onFrameHover: (frameIndex: number) => void;
  onFrameLeave: () => void;
  hoverClassNames?: string;
}

const FrameCard = ({
  imageSrc,
  frameIndex,
  pathIndex,
  withIssues,
  onDeleteFrames,
  onFrameHover,
  onFrameLeave,
  hoverClassNames,
}: FrameNameProps) => {
  const handleFrameDelete: React.MouseEventHandler = (e) => {
    e.stopPropagation();

    onDeleteFrames?.(pathIndex, frameIndex);
  };

  const handleOnFrameHover = () => onFrameHover(frameIndex);

  return (
    <div
      className={allClasses(
        'mr-1.5 relative cursor-pointer m-1 h-20 min-w-[43px] max-w-[45%]',
        withIssues && 'border-2 border-danger border-solid rounded-sm'
      )}
      onClick={onDeleteFrames ? handleFrameDelete : undefined}
      onMouseOver={handleOnFrameHover}
      onMouseLeave={onFrameLeave}>
      <img src={imageSrc} className='h-full w-full' />
      <span className={allClasses('h-full bg-danger', hoverClassNames || 'hidden')}>
        <DeleteIcon className='icon-main' />
      </span>
    </div>
  );
};

export default FrameCard;
