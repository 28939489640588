import {
  DiscoveryRatingScaleAnswer,
  DiscoverySectionRatingScaleOutput,
} from '../../../../../services/api-iteration1';

import { AnalyticsSectionAnswerEmptyState } from '../../../../empty-states';
import AnalyticsEmotionsAnswer from './AnalyticsEmotionsAnswer';
import AnalyticsNumericalAnswer from './AnalyticsNumericalAnswer';
import AnalyticsStarsAnswer from './AnalyticsStarsAnswer';

interface AnalyticsRatingScaleAnswerProps {
  answer?: DiscoveryRatingScaleAnswer;
  ratingScaleSection: DiscoverySectionRatingScaleOutput;
}

export const AnalyticsRatingScaleAnswer = ({
  answer,
  ratingScaleSection,
}: AnalyticsRatingScaleAnswerProps) => {
  const {
    others: { type, startingStep, stepsCount, labels },
  } = ratingScaleSection;

  return answer ? (
    <div className='flex flex-col w-full items-center'>
      <div className='flex flex-row w-full justify-start'>
        {type === 'numerical' ? (
          <AnalyticsNumericalAnswer {...{ answer, startingStep, stepsCount, labels }} />
        ) : type === 'emotions' ? (
          <AnalyticsEmotionsAnswer {...{ answer, labels }} />
        ) : (
          <AnalyticsStarsAnswer {...{ answer, labels }} />
        )}
      </div>
    </div>
  ) : (
    <AnalyticsSectionAnswerEmptyState />
  );
};
