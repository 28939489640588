import { useTranslationContext } from '../../../services/translation';

import { OptionsMenu, OptionsMenuItem, useToggle } from '../../../components';

import { RenameFolderModal } from '../modals/RenameFolderModal';
import { DeleteConfirmModal } from '../modals/DeleteConfirmModal';
import { DotsButton } from './DotsButton';

interface FolderActionsButtonProps {
  onClickDelete: () => void;
  onClickRename: (name: string) => void;
}

export const FolderActionsButton = ({ onClickDelete, onClickRename }: FolderActionsButtonProps) => {
  const { t } = useTranslationContext.useContext();
  const { isOpen: isMenuOpen, toggle: toggleMenuOpen } = useToggle();
  const {
    isOpen: isRenameFolderModalVisible,
    open: openRenameFolderModal,
    close: closeRenameFolderModal,
  } = useToggle();
  const {
    isOpen: isDeleteConfirmModalVisible,
    open: openDeleteConfirmModal,
    close: closeDeleteConfirmModal,
  } = useToggle();

  return (
    <>
      <div className='flex items-center relative mx-5' onClick={toggleMenuOpen}>
        <DotsButton hasBorder isMenuOpen={isMenuOpen} />
        {isMenuOpen && (
          <OptionsMenu id='folder-card-actions' label='Folder card actions'>
            <OptionsMenuItem text={t('common.rename')} onClick={openRenameFolderModal} />
            <OptionsMenuItem
              className='text-danger-60'
              text={t('common.delete')}
              onClick={openDeleteConfirmModal}
            />
          </OptionsMenu>
        )}
      </div>
      {isRenameFolderModalVisible && (
        <RenameFolderModal
          closeModal={closeRenameFolderModal}
          isOpen={isRenameFolderModalVisible}
          {...{ onClickRename }}
        />
      )}
      {isDeleteConfirmModalVisible && (
        <DeleteConfirmModal
          closeDeleteConfirmModal={closeDeleteConfirmModal}
          isOpen={isDeleteConfirmModalVisible}
          onRemove={onClickDelete}
          projectType='folder'
        />
      )}
    </>
  );
};
