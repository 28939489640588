import { ProjectVersionResponsePermission } from '@iteration1/permission-validators';

import { useTranslationContext } from '../../services/translation';
import { useCurrentDiscoveryVersionContext } from '../services/discovery';
import {
  VersionStatus,
  getValidResponses,
  useDiscoveryResponses,
} from '../services/api-iteration1';
import { allClasses } from '../../services/utilities/array';
import { usePermissionsContext } from '../services/authorization';
import useAnalyticsRouting from '../services/analytics-routing/useAnalyticsRouting';
import { useRouteParams } from '../../services/router';

import {
  DiscoveryVersionResult,
  ProjectVersionResultPlaceholder,
  MainLayout,
  withAuth,
} from '../components';
import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import { DiscoveryAnalyticsNavbar } from '../components/navbars/DiscoveryAnalyticsNavbar';

import { dynamicRoutes, routes } from './routing/routes';

type TabParams = {
  tab: 'summary' | 'responses';
};

export const DiscoveryResults = withAuth(() => {
  const { t } = useTranslationContext.useContext();
  const {
    discoveryVersion,
    isLoadingDiscovery,
    parents,
    error,
    discoveryId,
    discoveryVersionId,
    discovery,
  } = useCurrentDiscoveryVersionContext.useContext();
  const { checkPermission } = usePermissionsContext.useContext();
  const { setAnalyticsRoute } = useAnalyticsRouting();
  const { tab: shownTab } = useRouteParams<TabParams>();

  const { data: responses, isLoading: isLoadingResponses } = useDiscoveryResponses(
    discoveryId,
    discoveryVersionId || '',
    {
      waitForFetch: !discoveryVersionId,
    }
  );

  const { responses: responseSummaries } = discoveryVersion || {};
  const hasResponses = !!responseSummaries?.length;
  const { validResponses, invalidResponsesCount } = getValidResponses(responses);
  const isDone = discoveryVersion?.status === VersionStatus.Done;
  const testUrl =
    window.location.origin +
    dynamicRoutes.testing.discovery(discoveryId, discoveryVersion?.id || '');

  const breadcrumbs = discoveryVersion
    ? [
        { label: t('pages.projects.breadcrumb'), url: routes.projects },
        ...parents
          .sort((a, b) => a.index - b.index)
          .map((parent) => ({
            label: parent.name,
            url: dynamicRoutes.folder(parent._id),
          })),
        {
          label: discoveryVersion.name,
          url: discoveryVersion.id,
        },
      ]
    : [];

  const tabs = [
    { name: t('components.projects.analytics.overview.summary'), key: 'summary' },
    { name: t('components.projects.analytics.overview.responses'), key: 'responses' },
  ] as const;

  const changeTab = (tabId: 'summary' | 'responses') => {
    setAnalyticsRoute(tabId);
  };

  const allowReceiveNewResponses = checkPermission(ProjectVersionResponsePermission.create, {
    count: validResponses.length,
  });

  return (
    <MainLayout
      analyticsName={'Analytics - Discovery'}
      title={t('pages.discovery.results', {
        projectName: discoveryVersion?.name,
      })}
      navbarLeftComponent={breadcrumbs ? <Breadcrumbs items={breadcrumbs} /> : undefined}
      navbarRightComponent={
        <DiscoveryAnalyticsNavbar
          shouldShowButton={hasResponses ? true : false}
          validResponsesCount={validResponses.length}
          testUrl={testUrl}
        />
      }
      isLoading={isLoadingDiscovery}
      error={error}
      navbarTabs={
        (hasResponses || isDone) && (
          <div className='flex flex-row w-full'>
            {tabs.map((tab) => (
              <div
                key={tab.key}
                className={allClasses(
                  'inline-block -mb-0.5 relative z-10 py-2.5 px-5 text-base leading-5 font-medium cursor-pointer',
                  shownTab === tab.key
                    ? 'bg-main text-main-contrast border-b-2 border-solid border-main-highlight'
                    : 'text-neutral-60 hover:text-main-contrast'
                )}
                onClick={() => changeTab(tab.key)}>
                {tab.name}
              </div>
            ))}
          </div>
        )
      }
      isPlanLimitReached={!allowReceiveNewResponses}
      availableOnMobile={false}>
      {hasResponses || isDone ? (
        <DiscoveryVersionResult
          isLoading={isLoadingResponses}
          validResponses={validResponses}
          invalidResponsesCount={invalidResponsesCount}
          {...{ shownTab }}
        />
      ) : (
        <ProjectVersionResultPlaceholder
          testPath={dynamicRoutes.testing.discovery(discoveryId, discoveryVersionId || '')}
          testUrl={testUrl}
          projectId={discoveryId}
          projectName={discovery?.name}
        />
      )}
    </MainLayout>
  );
});
