import { allClasses } from '../../../../services/utilities/array';
import { getStoragePublicFileUrl } from '../../../services/api-cloud-storage';
import { Collaborator } from '../../../services/api-iteration1/collaborators/api-models';
import { useTranslationContext } from '../../../../services/translation';

import { DefaultAvatarIcon } from '../../../assets/icons';

interface CollaboratorItemProps {
  collaborator: Omit<Collaborator, 'role'>;
}

export const CollaboratorItem = ({ collaborator }: CollaboratorItemProps) => {
  const { t } = useTranslationContext.useContext();

  return (
    <div className='flex flex-row flex-1 items-center'>
      <div className='mr-5'>
        {collaborator.image ? (
          <img
            src={getStoragePublicFileUrl(collaborator.image?.id || '')}
            className={allClasses('h-full w-full object-cover overflow-hidden')}
          />
        ) : (
          <DefaultAvatarIcon className='w-10 h-10' />
        )}
      </div>
      <div className='flex flex-col items-start'>
        <p
          className={allClasses(
            'text-base leading-4 font-medium',
            'text-main-contrast',
            collaborator.isPending ? 'text-warning-10' : 'text-main-contrast'
          )}>
          {collaborator.isPending
            ? collaborator.resources.length
              ? t('components.collaborators.roles-dropdown.pending')
              : ''
            : `${collaborator.firstName} ${collaborator.lastName}`}
        </p>

        <p className='text-sm leading-4 text-neutral-70'>{collaborator.email}</p>
      </div>
    </div>
  );
};
