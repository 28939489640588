import { useEffect } from 'react';
import { ProjectVersionResponsePermission } from '@iteration1/permission-validators';

import {
  DiscoverySection,
  DiscoveryVersionResponseOutput,
  ProjectType,
  UsabilitySectionType,
} from '../../../services/api-iteration1';
import { useTranslationContext } from '../../../../services/translation';
import { UserTestProjectSection } from '../../../services/api-iteration1/projects/userTest/sections/api-models';
import { UserTestVersionResponseOutput } from '../../../services/api-iteration1/projects/userTest/responses';
import { sorting } from '../../../../services/utilities/array';
import { usePermissionsContext } from '../../../services/authorization';
import { FigmaDesignPrototypeVersion } from '../../../services/api-iteration1/design-platforms';
import {
  AnalyticsResponsesFiltersTypes,
  calculateAvgTestDuration,
  useAnalyticsQueryParams,
} from '../../../services/analytics';

import { LoadingOrContent } from '../../../../components';

import { UserIcon } from '../../../assets/icons';
import { AnalyticsSectionContainer } from '../../containers';
import { AnalyticsSectionsAnswers } from './AnalyticsSectionsAnswers';
import { AnalyticsResponseList } from '../../lists';
import { AnalyticsResponsesEmptyState } from '../../empty-states';
import { AnalyticsStatistic } from './AnalyticsStatistic';

interface AnalyticsResponsesProps {
  sections: (DiscoverySection | UserTestProjectSection)[];
  inFocusResponseId: string;
  responses: (DiscoveryVersionResponseOutput | UserTestVersionResponseOutput)[];
  invalidResponsesCount: number;
  setResponseInFocus: (responseId: string) => void;
  projectType: ProjectType;
  designPrototype?: FigmaDesignPrototypeVersion;
  versionName?: string;
  isLoadingResponses: boolean;
}

export const AnalyticsResponses = ({
  sections,
  inFocusResponseId,
  responses,
  invalidResponsesCount,
  setResponseInFocus,
  projectType,
  designPrototype,
  versionName,
  isLoadingResponses,
}: AnalyticsResponsesProps) => {
  const { t } = useTranslationContext.useContext();
  const { userPermissionsPlan } = usePermissionsContext.useContext();
  const {
    queryParams: { responseFilter = 'all' },
    setQueryParams,
  } = useAnalyticsQueryParams();

  const responseLimitUserPlan =
    userPermissionsPlan?.[ProjectVersionResponsePermission.create.id].limit;

  const hasSingleGoalResponse = sections.some(
    (item) => item.type === UsabilitySectionType.SingleGoal
  );

  const availableResponses = responses
    .sort(sorting.byDateAsc('createdAt'))
    .slice(0, responseLimitUserPlan)
    .reverse();

  const responsesFilteredByOutcome = availableResponses?.reduce((result, response) => {
    if (responseFilter === 'all') return [...result, response];

    const successTypes: string[] = Object.values(response.answers)
      .filter((answer) => answer.type === UsabilitySectionType.SingleGoal)
      .map((answer) => answer.successType);

    const bounced = !!successTypes.filter((type) => !type).length;
    if (responseFilter === 'bounce' && bounced) return [...result, response];

    const direct = successTypes.filter((type) => type === 'direct').length === successTypes.length;
    if (responseFilter === 'direct' && direct) return [...result, response];

    const indirect = successTypes.filter((type) => type === 'indirect').length;
    if (responseFilter === 'indirect' && indirect) return [...result, response];

    return result;
  }, [] as (DiscoveryVersionResponseOutput | UserTestVersionResponseOutput)[]);

  const responseInFocus = responsesFilteredByOutcome.find(
    (response) => response.id === inFocusResponseId
  );

  const responseInFocusIndex = responseInFocus
    ? responsesFilteredByOutcome.indexOf(responseInFocus)
    : -1;
  const firstFilteredResponseId = responsesFilteredByOutcome[0]?.id;

  const selectFilter = (filter: AnalyticsResponsesFiltersTypes) => {
    setQueryParams((prev) => ({ ...prev, responseFilter: filter }));
  };

  useEffect(() => {
    if (
      (!inFocusResponseId ||
        !responsesFilteredByOutcome.some(({ id }) => inFocusResponseId === id)) &&
      responsesFilteredByOutcome.length > 0
    ) {
      setResponseInFocus(firstFilteredResponseId);
    }
  }, [firstFilteredResponseId, inFocusResponseId, responsesFilteredByOutcome, setResponseInFocus]);

  return (
    <>
      <LoadingOrContent isLoading={isLoadingResponses} isFullscreen>
        <AnalyticsResponseList
          hasFilters={projectType === 'userTest' && hasSingleGoalResponse}
          responses={responsesFilteredByOutcome}
          responsesCount={responses.length}
          invalidResponsesCount={invalidResponsesCount}
          limitResponses={responseLimitUserPlan || 0}
          selectedResponseId={responseInFocus?.id}
          onSelectResponse={setResponseInFocus}
          onSelectFilter={(filter: AnalyticsResponsesFiltersTypes) => selectFilter(filter)}
          selectedFilter={responseFilter}
          {...{ projectType }}
        />
        {responsesFilteredByOutcome.length ? (
          responseInFocusIndex >= 0 && (
            <div className='scroll-container flex flex-col items-center w-full overflow-y-auto mb-5 pt-16'>
              <AnalyticsSectionContainer
                icon={<UserIcon />}
                title={`${t('common.tester')} #${
                  responsesFilteredByOutcome.length - responseInFocusIndex
                }`}>
                <div className='w-full mt-10'>
                  <div className='grid grid-flow-col h-auto'>
                    <AnalyticsStatistic title={t('components.projects.analytics.responses.device')}>
                      <span className='text-2xl text-main-contrast font-bold leading-8 capitalize'>
                        {responseInFocus?.device.deviceType || '-'}
                      </span>
                    </AnalyticsStatistic>
                    <AnalyticsStatistic
                      title={t('components.projects.analytics.responses.operating-system')}>
                      <span className='text-2xl text-main-contrast font-bold leading-8 capitalize'>
                        {responseInFocus ? `${responseInFocus.device.os.name}` : '-'}
                      </span>
                    </AnalyticsStatistic>
                    <AnalyticsStatistic
                      title={t('components.projects.analytics.responses.screen-size')}>
                      <span className='text-2xl text-main-contrast font-bold leading-8'>
                        {responseInFocus
                          ? `${responseInFocus.device.screen.width}x${responseInFocus.device.screen.height}`
                          : '-'}
                      </span>
                    </AnalyticsStatistic>
                    <AnalyticsStatistic
                      title={t('components.projects.analytics.responses.browser')}>
                      <span className='text-2xl text-main-contrast font-bold leading-8 capitalize'>
                        {responseInFocus?.device.browser.name || '-'}
                      </span>
                    </AnalyticsStatistic>
                    <AnalyticsStatistic isLast title={t('common.duration')}>
                      <span className='text-2xl text-main-contrast font-bold leading-8'>
                        {`${calculateAvgTestDuration(responseInFocus ? [responseInFocus] : [])}s`}
                      </span>
                    </AnalyticsStatistic>
                  </div>
                </div>
                <AnalyticsSectionsAnswers
                  key={inFocusResponseId}
                  sections={sections}
                  responses={responsesFilteredByOutcome}
                  inFocusResponseId={inFocusResponseId}
                  {...{
                    designPrototype,
                    responseInFocusIndex,
                    versionName,
                  }}
                />
              </AnalyticsSectionContainer>
            </div>
          )
        ) : (
          <AnalyticsResponsesEmptyState />
        )}
      </LoadingOrContent>
    </>
  );
};
