import {
  DesignPlatform,
  DesignPrototypeVersion,
} from '../../../../../services/api-iteration1/design-platforms';
import { useLivePrototypeNavigation } from '../../../../../services/design-platforms/useLivePrototypeNavigation';

import { LivePrototype } from '../../../live-prototype/LivePrototype';

interface ViewSingleGoalPrototypeEditProps {
  type: DesignPlatform;
  designPrototype: DesignPrototypeVersion;
}

export const ViewSingleGoalPrototypeEdit = ({
  type,
  designPrototype,
}: ViewSingleGoalPrototypeEditProps) => {
  const { currentFrameId } = useLivePrototypeNavigation.useContext();

  return (
    <LivePrototype
      hotspotHints
      id='prototype-in-edit-mode'
      {...{ type, designPrototype, currentFrameId }}
    />
  );
};
