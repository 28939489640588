import { allClasses } from '../../../../../../services/utilities/array';
import { DiscoveryRatingScaleAnswer } from '../../../../../services/api-iteration1';

import { StarRatingIcon } from '../../../../../assets/icons';
import { renderRatingScaleLabels } from '../../summaries/utilities';

interface AnalyticsStarsAnswerProps {
  answer?: DiscoveryRatingScaleAnswer;
  labels?: {
    left: string;
    middle: string;
    right: string;
  };
}

const AnalyticsStarsAnswer = ({ answer, labels }: AnalyticsStarsAnswerProps) => {
  if (!answer) return null;

  return (
    <>
      {[...Array(5)].map((_, index) => {
        const value = index + 1;
        const isLast = value === 5;
        const isActive = value <= answer.answer;

        return (
          <div className='w-full' key={value}>
            <div
              key={value}
              className={allClasses(
                'h-28 relative flex flex-1 flex-col justify-center items-center border-2 border-solid rounded-lg',
                isLast ? 'mr-0' : 'mr-5',
                isActive ? 'border-main-highlight' : 'border-main-10'
              )}>
              {isActive && <div className='absolute inset-0 bg-main-highlight opacity-10 z-0' />}
              <StarRatingIcon
                className={allClasses('h-20 z-10', isActive && 'icon-fill-polygon-warning')}
              />
            </div>
            {renderRatingScaleLabels(index, labels)}
          </div>
        );
      })}
    </>
  );
};

export default AnalyticsStarsAnswer;
