export interface Alert {
  title?: string;
  message: string;
  type: 'error' | 'warning' | 'success';
  timestamp: number;
}

export const errorAlert = (message: string): Alert => ({
  message,
  type: 'error',
  timestamp: Date.now(),
});

export const warningAlert = (message: string): Alert => ({
  message,
  type: 'warning',
  timestamp: Date.now(),
});

export const successAlert = (title: string, message: string): Alert => ({
  title,
  message,
  type: 'success',
  timestamp: Date.now(),
});
