import React, { ChangeEvent, ReactNode, Ref, useCallback, useEffect, useRef } from 'react';
import {
  Formik,
  Form as FormikForm,
  FormikValues,
  FormikErrors,
  FormikHelpers,
  FormikProps,
  FormikTouched,
  useFormikContext,
} from 'formik';

import { useFormContext } from './useForm';

export type FormStatus =
  | {
      state: 'pending' | 'error' | undefined;
      messageKeys?: string[];
    }
  | undefined;

export type SetFieldValue = (
  field: string,
  value: any,
  shouldValidate?: boolean | undefined
) => void;

export interface FormActions<Values> extends FormikHelpers<Values> {
  setStatus: (status: FormStatus) => void;
}

export type FormErrors<Values> = FormikErrors<Values>;

interface FormRenderProps<Values> extends FormikProps<Values> {
  status?: FormStatus;
  setStatus: (status: FormStatus) => void;
  isValidForSubmit: boolean;
  submitChange: (values?: Values) => Promise<void>;
}

interface FormComponentProps<Values extends FormikValues = FormikValues> {
  formRef?: React.Ref<HTMLFormElement>;
  enableChangeOnInputCountChange?: boolean;
  className?: string;
  children: (values: FormRenderProps<Values>) => ReactNode;
  onChange?: (values: Values, isValid: boolean) => void | Promise<any>;
  onBlur?: (values: Values, isValid: boolean) => void | Promise<any>;
}

interface FormProps<Values extends FormikValues = FormikValues> extends FormComponentProps<Values> {
  initialValues: Values;
  initialTouched?: FormikTouched<Values>;
  validateOnMount?: boolean;
  enableReinitialize?: boolean;
  onSubmit?: (values: Values, formActions: FormActions<Values>) => void | Promise<any>;
  validate?: (values: Values) => void | Record<string, unknown> | Promise<FormErrors<Values>>;
}

export const FormComponent = <Values extends FormikValues = FormikValues>({
  enableChangeOnInputCountChange,
  formRef,
  children,
  className,
  onChange,
  onBlur,
}: FormComponentProps<Values>) => {
  const formikProps = useFormikContext<Values>();

  const lastValuesCount = useRef(Object.keys(formikProps.values).length);

  const isValidForSubmit = formikProps.dirty && formikProps.isValid && !formikProps.isSubmitting;

  const submitChange = useCallback(
    async (values?: Values) => {
      const latestValues = values || formikProps.values;
      const formikErrors = await formikProps.validateForm(latestValues);
      const isValid = !Object.keys(formikErrors).length;

      onChange?.(latestValues, isValid);
    },
    [formikProps, onChange]
  );

  const onChangeHandler = async (
    e: ChangeEvent<HTMLFormElement>,
    formikProps: FormikProps<Values>
  ) => {
    const currentValue = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const latestValues = { ...formikProps.values, [e.target.name]: currentValue };

    submitChange(latestValues);
    formikProps.handleChange(e);
  };

  useEffect(() => {
    if (!enableChangeOnInputCountChange) return;

    const values = formikProps.values;
    const currentValuesCount = Object.keys(values).length;

    const sameValuesCount = currentValuesCount === lastValuesCount.current;

    if (sameValuesCount) return;

    lastValuesCount.current = currentValuesCount;

    (async () => {
      const formikErrors = await formikProps.validateForm(values);
      const isValid = !Object.keys(formikErrors).length;

      onChange?.(values, isValid);
    })();
  }, [enableChangeOnInputCountChange, formikProps, onChange]);

  return (
    <useFormContext.ProvideContext {...{ submitChange }}>
      <FormikForm
        noValidate
        ref={formRef}
        onBlur={() => onBlur?.(formikProps.values, !isValidForSubmit)}
        onChange={(e: ChangeEvent<HTMLFormElement>) => onChangeHandler(e, formikProps)}
        {...{ className }}>
        {children({ ...formikProps, isValidForSubmit, submitChange })}
      </FormikForm>
    </useFormContext.ProvideContext>
  );
};

const FormComponentWrapper = <Values extends FormikValues = FormikValues>(
  {
    enableChangeOnInputCountChange,
    children,
    className,
    onChange,
    onBlur,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit = () => {},
    ...formProps
  }: FormProps<Values>,
  ref: Ref<HTMLFormElement>
) => {
  const initialTouched = Object.keys(formProps.initialValues).reduce(
    (result, key) => ({ ...result, [key]: true }),
    {}
  );

  return (
    <Formik
      validateOnMount
      //  TODO: use `validate` in order to properly validate entire form instead of per field basis
      initialTouched={formProps.validateOnMount ? initialTouched : undefined}
      {...{ onSubmit }}
      {...formProps}>
      <FormComponent
        formRef={ref}
        {...{ enableChangeOnInputCountChange, children, className, onChange, onBlur }}
      />
    </Formik>
  );
};

export const Form = React.forwardRef(FormComponentWrapper) as <
  Values extends FormikValues = FormikValues
>(
  props: FormProps<Values> & { ref?: Ref<HTMLFormElement> }
) => JSX.Element;
