import { ChangeEventHandler } from 'react';
import { FieldAttributes, FieldProps } from 'formik';

import { allClasses } from '../../../../services/utilities/array';

interface InputProps extends FieldProps, FieldAttributes<any> {
  inputClassName?: string;
  placeholder?: string;
  validate?: (value: any) => string | void | Promise<string | void>;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;
}

interface AccessoryInputProps extends InputProps {
  prefix: React.ReactNode;
  suffix: React.ReactNode;
  label?: string;
  containerClassName: string;
  inputClassName: string;
  labelClassName?: string;
  errorClassName?: string;
  disabled?: boolean;
  otherInfo?: React.ReactNode;
}

export const AccessoryInput = ({
  prefix,
  suffix,
  label,
  containerClassName,
  inputClassName,
  labelClassName,
  disabled = false,
  otherInfo,
  field,
  ...otherProps
}: AccessoryInputProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { form, meta, ...inputProps } = otherProps;

  return (
    <div className='mb-5'>
      {label && (
        <label className={labelClassName} htmlFor={otherProps.id}>
          {label}
        </label>
      )}
      <div className={containerClassName}>
        {prefix}
        <input
          {...field}
          {...inputProps}
          className={allClasses(inputClassName, 'text-center w-full bg-main focus:outline-none')}
          onChange={(e) => {
            field.onChange(e);
            otherProps.onChange && otherProps.onChange(e);
          }}
          onBlur={(e) => {
            field.onBlur(e);
            otherProps.onBlur && otherProps.onBlur(e);
          }}
          disabled={disabled}
        />
        {suffix}
      </div>
      {otherInfo}
    </div>
  );
};
