import { useRef } from 'react';

import { allClasses } from '../../../services/utilities/array';
import { isValidFigmaUrl } from '../../services/validation';
import { useTranslationContext } from '../../../services/translation';

import { Button, Form, FormError, FormField, Modal } from '../../../components';

import { CloseIcon, FigmaIcon } from '../../assets/icons';

import styles from './Modals.module.css';

interface PrototypeLinkModalProps {
  addPrototypeLink: (values: any) => void;
  closeModal: () => void;
  demonstrationUrl: string;
  isOpen: boolean;
  prototypeType: string;
}

export const PrototypeLinkModal = ({
  addPrototypeLink,
  closeModal,
  // demonstrationUrl,
  isOpen,
  prototypeType,
}: PrototypeLinkModalProps) => {
  const { t } = useTranslationContext.useContext();
  const focusInputRef = useRef(null);

  return (
    <Modal
      title={t('components.projects.prototype-link-modal.title', { prototypeType })}
      isOpen={isOpen}
      onClose={closeModal}
      variant='regular'
      closeModalComponent={<CloseIcon className='icon-hover-main-contrast' />}
      closeIconClassName='absolute top-5 right-5'
      refInitialFocus={focusInputRef}>
      <div className='pt-10 text-center'>
        <h2 className={allClasses(styles.title, 'mt-10 mb-12')}>
          {t('components.projects.prototype-link-modal.title', { prototypeType })}
        </h2>
        <div className='flex flex-col items-center max-w-sm mx-auto w-full mb-10'>
          <div className='flex flex-row items-center justify-center mb-6'>
            <span className='w-10 h-10 flex items-center justify-center border-2 border-solid border-main-highlight rounded-full'>
              1
            </span>
            <span className='flex w-10/12 ml-4 text-left'>
              {t('components.projects.prototype-link-modal.steps.one', {
                prototypeType,
              })}
            </span>
          </div>
          <div className='flex flex-row items-center justify-center mb-6'>
            <span className='w-10 h-10 flex items-center justify-center border-2 border-solid border-main-highlight rounded-full'>
              2
            </span>
            <span className='flex w-10/12 ml-4 text-left'>
              {t('components.projects.prototype-link-modal.steps.two')}
            </span>
          </div>
          <div className='flex flex-row items-center justify-center mb-6'>
            <span className='w-10 h-10 flex items-center justify-center border-2 border-solid border-main-highlight rounded-full'>
              3
            </span>
            <span className='flex w-10/12 ml-4 text-left'>
              {t('components.projects.prototype-link-modal.steps.three')}
            </span>
          </div>
          <div className='flex flex-row items-center justify-center'>
            <span className='w-10 h-10 flex items-center justify-center border-2 border-solid border-main-highlight rounded-full'>
              4
            </span>
            <span className='flex w-10/12 ml-4 text-left'>
              {t('components.projects.prototype-link-modal.steps.four')}
            </span>
          </div>
        </div>
        {/* <a
          href={demonstrationUrl}
          className='text-info text-base font-medium leading-5 pb-1 border-b-default border-solid cursor-pointer mb-7 inline-block hover:text-info-70'>
          {t('components.projects.prototype-link-modal.watch-video')}
        </a> */}
        <div className='mb-20'>
          <Form
            className='flex flex-col items-center'
            initialValues={{ designPrototype: '' }}
            onSubmit={addPrototypeLink}>
            {({ status, isValidForSubmit, isSubmitting, errors, touched }) => {
              return (
                <>
                  <FormError {...{ status }} />
                  <FormField
                    autoFocus
                    type='text'
                    name='designPrototype'
                    placeholder={t('components.projects.prototype-link-modal.placeholder')}
                    validate={isValidFigmaUrl}
                    fieldClassName={`w-full max-w-sm ${
                      !touched.designPrototype ? 'mb-10' : 'mb-1'
                    }`}
                    inputClassName={allClasses(
                      styles.project_input,
                      errors.designPrototype &&
                        touched.designPrototype &&
                        styles.project_input__invalid
                    )}
                    innerRef={focusInputRef}
                  />
                  {touched.designPrototype && !errors.designPrototype && (
                    <div className='w-full max-w-sm flex items-center justify-start mb-10'>
                      <FigmaIcon />
                      <span className='pl-2 text-info'>
                        {t('components.projects.project-type-modal.valid-prototype-link', {
                          linkType: 'Figma',
                        })}
                      </span>
                    </div>
                  )}

                  <Button
                    className='button__filled px-10'
                    disabled={!isValidForSubmit || isSubmitting}
                    type='submit'
                    loading={isSubmitting}>
                    <span className='text-main-contrast leading-5 text-base font-medium'>
                      {t('common.create')}
                    </span>
                  </Button>
                </>
              );
            }}
          </Form>
        </div>
      </div>
    </Modal>
  );
};
