export class ClientError {
  constructor(private errKey: string) {}

  static contains = (err: any): boolean => {
    const isClientError = err && err instanceof ClientError;

    return !!isClientError;
  };

  static getError = (err: any): string => {
    const clientError = err && err.errKey;

    return clientError;
  };
}
