import { useTranslationContext } from '../../../../services/translation';
import { allClasses } from '../../../../services/utilities/array';
import { Collaborator } from '../../../services/api-iteration1/collaborators/api-models';
import { useCurrentUserContext } from '../../../services/current-user';

import { useToggle } from '../../../../components';

import { CollaboratorItem } from './CollaboratorItem';
import { DeleteIcon } from '../../../assets/icons';
import { RemoveCollaboratorModal } from '../../modals/RemoveCollaboratorModal';
import { CollaboratorProjectsModal } from '../../modals/CollaboratorProjectsModal';

import styles from './CollaboratorCard.module.css';

interface CollaboratorCardInSettingsProps {
  collaborator: Collaborator;
  isLast?: boolean;
  revalidate: () => void;
}

export const CollaboratorCardInSettings = ({
  collaborator,
  isLast,
  revalidate,
}: CollaboratorCardInSettingsProps) => {
  const { t } = useTranslationContext.useContext();
  const {
    isOpen: isRemoveModalVisible,
    open: openRemoveModal,
    close: closeRemoveModal,
  } = useToggle();
  const {
    isOpen: isProjectsModalVisible,
    open: openProjectsModal,
    close: closeProjectsModal,
  } = useToggle();
  const { currentUser } = useCurrentUserContext.useContext();

  const projectsCount: number = collaborator.resources.filter(
    (res) => res.ownerId === currentUser?.id
  ).length;

  return (
    <div
      className={allClasses(
        'flex flex-row justify-between items-center py-4',
        !isLast && 'border-b-2 border-solid border-main-90'
      )}>
      <div className='w-[28rem]'>
        <CollaboratorItem {...{ collaborator }} />
      </div>
      <div
        className={allClasses(
          styles.collaboratorsInfoLine,
          !projectsCount ? 'text-neutral' : 'text-info'
        )}>
        <span
          className={allClasses(
            'border-b-default border-main-70 border-solid',
            !!projectsCount && 'cursor-pointer hover:text-info-70'
          )}
          onClick={() => openProjectsModal()}>
          {projectsCount} {t('common.project', { count: projectsCount })}
        </span>
        <DeleteIcon
          className='icon__small ml-5 cursor-pointer icon-danger icon-hover-danger-20'
          onClick={() => openRemoveModal()}
        />
      </div>
      {isRemoveModalVisible && (
        <RemoveCollaboratorModal
          isOpen
          close={closeRemoveModal}
          {...{ collaborator, revalidate }}
        />
      )}
      {isProjectsModalVisible && (
        <CollaboratorProjectsModal isOpen close={closeProjectsModal} {...{ collaborator }} />
      )}
    </div>
  );
};
