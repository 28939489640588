import { allClasses } from '../../../services/utilities/array';

interface TagProps {
  name: string | React.ReactNode;
  id: string;
  className?: string;
  onClick?: (e: string) => void;
}

export const Tag = ({ name, id, className, onClick }: TagProps) => {
  return (
    <span
      key={id}
      className={allClasses('mr-2 mb-2 inline-block', className)}
      onClick={() => onClick?.(id)}>
      {name}
    </span>
  );
};
