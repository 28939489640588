import { ElementSize } from '../../../../../services/resize';

import { FigmaBoundingBox } from '.';

export const isFigmaUrl = (value: string) => {
  const figmaRegex = new RegExp(
    'https://([w.-]+.)?figma.com/(file|proto)/([0-9a-zA-Z]{0,128})(?:/.*)?$'
  );

  return figmaRegex.test(value);
};

const getHtmlElementBorders = (htmlElDimensions: ElementSize | undefined) => {
  return {
    x: htmlElDimensions
      ? htmlElDimensions.borderBoxSize[0]?.inlineSize -
        htmlElDimensions.contentBoxSize[0]?.inlineSize
      : 0,
    y: htmlElDimensions
      ? htmlElDimensions.borderBoxSize[0]?.blockSize - htmlElDimensions.contentBoxSize[0]?.blockSize
      : 0,
  };
};

export const getHotspotPosition = ({
  hotspotBoundingBox,
  frameBoundingBox,
  htmlElDimensions,
}: {
  hotspotBoundingBox: FigmaBoundingBox;
  frameBoundingBox: FigmaBoundingBox;
  htmlElDimensions: ElementSize | undefined;
}): {
  left: number;
  top: number;
  width: number;
  height: number;
} => {
  const { x: htmlElBorderX, y: htmlElBorderY } = getHtmlElementBorders(htmlElDimensions);

  const dimensionsAdjustmentRatio = {
    x: htmlElDimensions ? htmlElDimensions.contentRect.width / frameBoundingBox.width : 1,
    y: htmlElDimensions ? htmlElDimensions.contentRect.height / frameBoundingBox.height : 1,
  };

  const positionX = hotspotBoundingBox.x - frameBoundingBox.x;
  const positionY = hotspotBoundingBox.y - frameBoundingBox.y;
  const { height, width } = hotspotBoundingBox;

  return {
    left: positionX * dimensionsAdjustmentRatio.x + htmlElBorderX / 2,
    top: positionY * dimensionsAdjustmentRatio.y + htmlElBorderY / 2,
    width: width * dimensionsAdjustmentRatio.x,
    height: height * dimensionsAdjustmentRatio.y,
  };
};

export const getTestClickPosition = ({
  clickBoundingBox,
  frameBoundingBox,
  htmlElDimensions,
}: {
  clickBoundingBox: FigmaBoundingBox;
  frameBoundingBox: FigmaBoundingBox;
  htmlElDimensions: ElementSize | undefined;
}): {
  left: number;
  top: number;
  width: number;
  height: number;
} => {
  const { x: frameElBorderX, y: frameElBorderY } = getHtmlElementBorders(htmlElDimensions);

  const dimensionsAdjustmentRatio = {
    x: htmlElDimensions ? htmlElDimensions.contentRect.width / frameBoundingBox.width : 1,
    y: htmlElDimensions ? htmlElDimensions.contentRect.height / frameBoundingBox.height : 1,
  };

  const positionX = clickBoundingBox.x - frameBoundingBox.x;
  const positionY = clickBoundingBox.y - frameBoundingBox.y;
  const { height, width } = clickBoundingBox;

  return {
    left: positionX * dimensionsAdjustmentRatio.x + frameElBorderX / 2 - width / 2,
    top: positionY * dimensionsAdjustmentRatio.y + frameElBorderY / 2 - height / 2,
    width,
    height,
  };
};
