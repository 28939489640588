import { createContextService } from '../../../services/context';

import { useToggle } from '../../../components';

const useUpgradeModalToggle = () => {
  const {
    isOpen: isUpgradePlanModalVisible,
    toggle: toggleUpgradePlan,
    open: openUpgradeModal,
    close: closeUpgradeModal,
  } = useToggle();

  return { isUpgradePlanModalVisible, toggleUpgradePlan, openUpgradeModal, closeUpgradeModal };
};

const useUpgradeModalToggleContext = createContextService(useUpgradeModalToggle);

export { useUpgradeModalToggleContext as useUpgradeModalToggle };
