import { ReactNode } from 'react';

import { mapErrorToTranslationKeys } from '../../services/error-handling/utils';

import { SomethingWentWrong } from '../something-went-wrong/SomethingWentWrong';
import { AccessForbidden } from '../access-forbidden/AccessForbidden';

import { NotFoundLayout } from '../../domain/components/layout/NotFoundLayout';

interface ContentOrErrorProps {
  error: any;
  children?: ReactNode;
}

export const ContentOrError = ({ error, children }: ContentOrErrorProps) => {
  const errorKey = mapErrorToTranslationKeys(error).pop();

  if (!errorKey) return <>{children}</>;

  switch (errorKey) {
    case 'errors.not-found':
      return <NotFoundLayout />;
    case 'errors.forbidden':
      return <AccessForbidden />;
    default:
      return <SomethingWentWrong />;
  }
};
