import { getStoragePublicFileUrl } from '../../services/api-cloud-storage';
import { FigmaDesignPrototypeVersion } from '../../services/api-iteration1/design-platforms';
import { useTranslationContext } from '../../../services/translation';

import { Modal } from '../../../components';

import { CloseIcon } from '../../assets/icons';
import { FrameCard } from '../cards';

interface ChangeStartingFrameModalProps {
  isOpen: boolean;
  designPrototype?: FigmaDesignPrototypeVersion;
  startingPointNodeId?: string;
  pathIndex: number;
  onClose: () => void;
  onSelect: (pathIndex: number, frameId: string) => void;
  modalTitle?: string;
}

export const ChangeStartingFrameModal = ({
  isOpen,
  onClose,
  onSelect,
  designPrototype,
  startingPointNodeId,
  pathIndex,
  modalTitle,
}: ChangeStartingFrameModalProps) => {
  const { t } = useTranslationContext.useContext();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeModalComponent={<CloseIcon className='icon-hover-main-contrast' />}
      variant='full-page'
      headerTitleComponent={
        <h5 className='w-full'>
          {modalTitle ||
            t('components.projects.sections.single-goal.edit.change-start-screen.modal-title')}
        </h5>
      }
      headerClassName='container-with-margin mt-8 items-center'>
      <div className='container-with-margin h-full flex flex-row flex-wrap items-center justify-start mt-20'>
        {designPrototype &&
          designPrototype.frames.length > 0 &&
          designPrototype.frames.map((item) => (
            <FrameCard
              key={`${item.id}_${item.index}`}
              lastModified={t('common.today')}
              imgSrc={item.image && getStoragePublicFileUrl(item.image.id)}
              index={item.index + 1}
              frameId={item.id}
              onSelect={(pathIndex: number, frameId: string) => onSelect(pathIndex, frameId)}
              isSelected={startingPointNodeId === item.id}
              pathIndex={pathIndex}
            />
          ))}
      </div>
    </Modal>
  );
};
