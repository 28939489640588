import { useMemo, useState } from 'react';

import { useTranslationContext } from '../../../../../services/translation';
import { isRequired } from '../../../../services/validation';
import {
  DiscoverySectionType,
  DiscoverySectionRatingScaleOutput,
  DiscoverySectionInput,
  SectionFileUploadDetails,
  SectionFileRemovalDetails,
  isTemporarySection,
} from '../../../../services/api-iteration1';
import { allClasses } from '../../../../../services/utilities/array';
import { useErrorHandlerContext } from '../../../../../services/error-handling';
import { useSectionsListContext } from '../../../../services/sections/useSectionsList';
import { useActionInProgressContext } from '../../../../../services/progress';
import {
  getStoragePublicFileUrl,
  imageFileSizeLimit,
} from '../../../../services/api-cloud-storage';
import { StorageFileType } from '../../../../services/api-cloud-storage/api-models';

import { Form, FormError, FormField, ToggleField, UploadImage } from '../../../../../components';
import { RadioGroup } from '../../../../../components/radio-group/RadioGroup';

import {
  EmotionsIcon,
  NumericalIcon,
  NumericalIconOn,
  RatingScaleIcon,
  StarsIcon,
  StarsIconOn,
} from '../../../../assets/icons';
import { SelectSectionItem } from '../../../containers';

import styles from './Section.module.css';

interface SectionFormValues {
  title: string;
  required?: boolean;
  description: string;
  type: DiscoverySectionRatingScaleOutput['others']['type'];
  hasImage: boolean;
  startingStep?: 0 | 1;
  stepsCount?: number;
  labels_left: string;
  labels_middle: string;
  labels_right: string;
}

interface SectionRatingScaleProps {
  section: DiscoverySectionRatingScaleOutput;
  index: number;
  isInvalid: boolean;
  editSection: (
    sectionId: string,
    updates: DiscoverySectionInput,
    shouldUpdate?: boolean
  ) => Promise<void> | undefined;
  uploadSectionFile: (sectionId: string, fileDetails: SectionFileUploadDetails) => Promise<void>;
  removeSectionFile: (sectionId: string, fileDetails: SectionFileRemovalDetails) => Promise<void>;
  removeSection: (sectionId: string) => Promise<void> | undefined;
  duplicateSection: ((sectionId: string) => Promise<void> | undefined) | (() => void);
}

export const SectionRatingScale = ({
  section,
  isInvalid,
  index,
  editSection,
  uploadSectionFile,
  removeSectionFile,
  removeSection,
  duplicateSection,
}: SectionRatingScaleProps) => {
  const { t } = useTranslationContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();
  const { reflectUploadingStatus } = useActionInProgressContext.useContext();
  const {
    inFocusSectionId,
    setSectionInFocus,
    resetSectionInFocus: collapseSection,
  } = useSectionsListContext.useContext();

  const { title, _id, files, others, required } = section;
  const { description, type, startingStep, stepsCount, labels } = others;

  const sectionImage = files.find((f) => f.type === StorageFileType.SectionImage);
  const imageUrl = sectionImage && getStoragePublicFileUrl(sectionImage.id);

  const [hasImage, setHasImage] = useState(!!sectionImage);

  const isLoading = isTemporarySection(_id);
  const isExpanded = inFocusSectionId === _id && !isLoading;
  const expandSection = () => setSectionInFocus(_id);

  const updateSectionValues = (values: SectionFormValues) => {
    if (!_id) return;

    const shouldRemoveSectionImage = !values.hasImage && sectionImage;
    if (shouldRemoveSectionImage)
      removeSectionFile(_id, {
        id: sectionImage.id,
        type: StorageFileType.SectionImage,
      }).catch(handleError);

    const updates = {
      title: values.title,
      others: {
        description: values.description,
        type: values.type,
        ...(values.startingStep
          ? {
              startingStep: values.type === type ? Number(values.startingStep) : 0,
            }
          : values.type === 'numerical'
          ? { startingStep: 0 }
          : {}),
        ...(values.stepsCount
          ? {
              stepsCount:
                values.type === type
                  ? values.startingStep === startingStep
                    ? Number(values.stepsCount)
                    : values.startingStep?.toString() === '0'
                    ? 6
                    : 5
                  : 6,
            }
          : {}),
        ...(values.labels_left || values.labels_middle || values.labels_right
          ? {
              labels:
                values.type === type
                  ? {
                      left: values.labels_left,
                      middle: values.labels_middle,
                      right: values.labels_right,
                    }
                  : {},
            }
          : {}),
      },
      required: values.required,
      ...(shouldRemoveSectionImage && !!sectionImage
        ? { files: files.filter((f) => f.id !== sectionImage.id) }
        : {}),
    };

    editSection(_id, updates, !isTemporarySection(_id));
  };

  const initialValues = useMemo(
    () =>
      ({
        title: title,
        description: description || '',
        type: type,
        required: !!required,
        hasImage: !!sectionImage || hasImage,
        startingStep: startingStep || 0,
        stepsCount: stepsCount || 6,
        labels_left: labels?.left || '',
        labels_middle: labels?.middle || '',
        labels_right: labels?.right || '',
      } as const),
    [title, description, type, required, sectionImage, hasImage, startingStep, stepsCount, labels]
  );

  return (
    <SelectSectionItem
      alreadyAdded
      draggableItemId={_id}
      title={title || t('components.projects.sections.section-title-placeholder')}
      description={t('components.projects.sections.field-types.ratingScale')}
      icon={<RatingScaleIcon className='icon__small' />}
      onClick={isExpanded ? collapseSection : expandSection}
      type={DiscoverySectionType.RatingScale}
      onDelete={(e) => {
        e.stopPropagation();
        removeSection(_id);
      }}
      onDuplicate={(e) => {
        e.stopPropagation();
        duplicateSection?.(_id);
      }}
      showRequiredIcon={required}
      {...{ isExpanded, isInvalid, isLoading, index }}>
      <>
        <hr className={allClasses(styles.section_hr, 'w-11/12')} />
        <Form
          validateOnMount
          enableReinitialize
          initialValues={initialValues}
          onChange={updateSectionValues}
          className='px-5'>
          {({ status, values, errors, touched }) => {
            return (
              <>
                <FormError {...{ status }} />
                <FormField
                  autoFocus
                  type='text'
                  name='title'
                  label={`${t('components.projects.sections.rating-scale.edit.title.label')}*`}
                  placeholder={t(
                    'components.projects.sections.rating-scale.edit.title.placeholder'
                  )}
                  validate={isRequired}
                  fieldClassName='mb-5'
                  inputClassName={allClasses(
                    styles.section_input,
                    errors.title && touched.title && styles.section_input__invalid
                  )}
                  labelClassName={styles.section_label}
                />
                <FormField
                  type='text'
                  name='description'
                  label={t('components.projects.sections.rating-scale.edit.description.label')}
                  placeholder={t(
                    'components.projects.sections.rating-scale.edit.description.placeholder'
                  )}
                  fieldClassName='mb-5'
                  inputClassName={styles.section_input}
                  labelClassName={styles.section_label}
                />
                <div className='mb-6'>
                  <FormField
                    component={RadioGroup}
                    label={`${t('components.projects.sections.scale-type')}*`}
                    name='type'
                    options={[
                      {
                        label: 'Numerical',
                        value: 'numerical',
                        icons: {
                          selected: (
                            <NumericalIconOn className='icon-main-contrast icon-circle-main-contrast' />
                          ),
                          notSelected: <NumericalIcon />,
                        },
                      },
                      {
                        label: 'Stars',
                        value: 'stars',
                        icons: {
                          selected: (
                            <StarsIconOn className='icon-main-contrast icon-circle-main-contrast' />
                          ),
                          notSelected: <StarsIcon />,
                        },
                      },
                      {
                        label: 'Emotions',
                        value: 'emotions',
                        icons: {
                          selected: (
                            <EmotionsIcon className='icon-main-contrast icon-circle-main-contrast' />
                          ),
                          notSelected: <EmotionsIcon />,
                        },
                      },
                    ]}
                    checkedWrapperClassName='border-main-highlight'
                    checkedTextClassName='text-main-contrast'
                    labelClassName={styles.section_label}
                    checked={values.type}
                    wrapperClassName='grid-cols-2'
                  />
                </div>
                {values.type === 'numerical' && (
                  <div className='mb-5'>
                    <FormField
                      component={RadioGroup}
                      label={`${t(
                        'components.projects.sections.rating-scale.edit.options.starting-step'
                      )}*`}
                      name='startingStep'
                      options={[
                        {
                          label: '0',
                          value: 0,
                        },
                        {
                          label: '1',
                          value: 1,
                        },
                      ]}
                      checkedWrapperClassName='border-main-highlight'
                      checkedTextClassName='text-main-contrast'
                      labelClassName={styles.section_label}
                      checked={values.startingStep}
                      wrapperClassName='grid-cols-2 mb-5'
                    />
                    <FormField
                      component={RadioGroup}
                      label={`${t(
                        'components.projects.sections.rating-scale.edit.options.number-of-steps'
                      )}*`}
                      name='stepsCount'
                      options={[
                        {
                          label: '5',
                          value: 5,
                          disabled: values.startingStep === 0,
                        },
                        {
                          label: '6',
                          value: 6,
                          disabled: values.startingStep === 1,
                        },
                        {
                          label: '10',
                          value: 10,
                          disabled: values.startingStep === 0,
                        },
                        {
                          label: '11',
                          value: 11,
                          disabled: values.startingStep === 1,
                        },
                      ]}
                      checkedWrapperClassName='border-main-highlight'
                      checkedTextClassName='text-main-contrast'
                      labelClassName={styles.section_label}
                      checked={values.stepsCount}
                      wrapperClassName='grid-cols-4'
                    />
                  </div>
                )}
                <div className='mb-5'>
                  <span className={styles.section_label}>
                    {t('components.projects.sections.rating-scale.edit.options.labels.title')}
                  </span>

                  <FormField
                    type='text'
                    name='labels_left'
                    placeholder={t(
                      `components.projects.sections.rating-scale.edit.options.labels.left`
                    )}
                    fieldClassName='mb-5'
                    inputClassName={styles.section_input}
                    labelClassName={styles.section_label}
                  />
                  <FormField
                    type='text'
                    name='labels_middle'
                    placeholder={t(
                      `components.projects.sections.rating-scale.edit.options.labels.middle`
                    )}
                    fieldClassName='mb-5'
                    inputClassName={styles.section_input}
                    labelClassName={styles.section_label}
                  />
                  <FormField
                    type='text'
                    name='labels_right'
                    placeholder={t(
                      `components.projects.sections.rating-scale.edit.options.labels.right`
                    )}
                    fieldClassName='mb-5'
                    inputClassName={styles.section_input}
                    labelClassName={styles.section_label}
                  />
                </div>
                <hr className={styles.section_hr} />
                <div className={allClasses(styles.section_toggle_wrapper, 'mb-5 py-2.5')}>
                  <span>{t('components.projects.sections.required-section')}</span>
                  <FormField
                    component={ToggleField}
                    name='required'
                    sliderClassName={styles.section_toggle_slider_wrapper}
                    circleClassName={styles.section_toggle_slider_circle}
                  />
                </div>
                <div className={allClasses(styles.section_toggle_wrapper, 'mb-5')}>
                  <span>{t('components.projects.sections.image')}</span>
                  <FormField
                    component={ToggleField}
                    name='hasImage'
                    sliderClassName={styles.section_toggle_slider_wrapper}
                    circleClassName={styles.section_toggle_slider_circle}
                    onChange={() => setHasImage(!values.hasImage)}
                  />
                </div>
                {values.hasImage && (
                  <UploadImage
                    excludeFromForm
                    fileSizeLimit={imageFileSizeLimit}
                    title={t('components.upload.image.title')}
                    subtitle={t('components.upload.image.subtitle')}
                    placeholderImgSrc={imageUrl}
                    upload={(image) =>
                      uploadSectionFile(_id, { file: image, type: StorageFileType.SectionImage })
                    }
                    onUploadError={handleError}
                    remove={async () => {
                      if (!sectionImage) return;
                      await removeSectionFile(_id, {
                        id: sectionImage.id,
                        type: StorageFileType.SectionImage,
                      });
                    }}
                    onRemoveSuccess={() => setHasImage(true)}
                    onRemoveError={handleError}
                    onLoadingStatusChange={reflectUploadingStatus}
                    className='mb-4'
                    fileName={sectionImage?.fileName}
                  />
                )}
              </>
            );
          }}
        </Form>
      </>
    </SelectSectionItem>
  );
};
