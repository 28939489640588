import { useRef } from 'react';

import { allClasses } from '../../../services/utilities/array';
import { useTranslationContext } from '../../../services/translation';
import { isRequired } from '../../services/validation';

import { Modal } from '../../../components/modal';
import { Button, createFormSubmitHandler, Form, FormError, FormField } from '../../../components';

import { CloseIcon } from '../../assets/icons';

import styles from './Modals.module.css';

interface RenameFolderModalProps {
  isOpen: boolean;
  closeModal: () => void;
  onClickRename: (name: string) => void;
}

export const RenameFolderModal = ({
  isOpen,
  closeModal,
  onClickRename,
}: RenameFolderModalProps) => {
  const { t } = useTranslationContext.useContext();
  const focusInputRef = useRef(null);

  const renameFolder = createFormSubmitHandler<{ name: string }>({
    tryAction: (values) => onClickRename(values.name),
    onSuccess: () => {
      closeModal();
    },
    resetFormOnSubmit: true,
  });

  return (
    <Modal
      title={t('components.projects.project-actions.rename-modal-title', {
        projectTypeToRename: t('components.projects.type.folder'),
      })}
      isOpen={isOpen}
      onClose={closeModal}
      variant='regular'
      closeModalComponent={<CloseIcon className='icon-hover-main-contrast' />}
      closeIconClassName='absolute top-5 right-5'
      refInitialFocus={focusInputRef}>
      <div className='pt-10 text-center'>
        <h2 className={allClasses(styles.title, 'mb-10')}>
          {t('components.projects.project-actions.rename-modal-title', {
            projectTypeToRename: t('components.projects.type.folder'),
          })}
        </h2>
        <div className='mb-20'>
          <Form
            className='flex flex-col items-center'
            initialValues={{ name: '' }}
            onSubmit={renameFolder}>
            {({ status, isValidForSubmit, isSubmitting, errors, touched }) => {
              return (
                <>
                  <FormError {...{ status }} />
                  <FormField
                    autoFocus
                    type='text'
                    name='name'
                    placeholder={t('components.projects.project-actions.rename-input-placeholder')}
                    validate={isRequired}
                    fieldClassName='w-full max-w-sm mb-10'
                    inputClassName={allClasses(
                      styles.project_input,
                      errors.name && touched.name && styles.project_input__invalid
                    )}
                    innerRef={focusInputRef}
                  />

                  <Button
                    className='button__filled px-10'
                    disabled={!isValidForSubmit || isSubmitting}
                    type='submit'
                    loading={isSubmitting}>
                    <span className='text-main-contrast leading-5 text-base font-medium'>
                      {t('common.rename')}
                    </span>
                  </Button>
                </>
              );
            }}
          </Form>
        </div>
      </div>
    </Modal>
  );
};
