import { useTranslationContext } from '../../../services/translation';

interface PlanUpgradeBannerProps {
  onClick: () => void;
}

export const PlanUpgradeBanner = ({ onClick }: PlanUpgradeBannerProps) => {
  const { t } = useTranslationContext.useContext();
  return (
    <div className='py-2.5 text-base leading-5 text-main font-medium bg-gradient-to-r from-billing via-billing to-billing-10 text-center'>
      {t('plan-permissions-check.banner.upgrade-plan-banner-part-one')}
      <span className='border-b-default border-solid border-main cursor-pointer' onClick={onClick}>
        {t('plan-permissions-check.banner.upgrade-plan-banner-part-two')}
      </span>
    </div>
  );
};
