import { useEffect, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';

import { reorder } from './utils';
export interface DraggableItem {
  _id: string;
}

interface SortableItems<T extends DraggableItem> {
  items: T[];
  onSort: (sortedItems: T[]) => void | Promise<void>;
}

export const useSortItemsOnDrag = <T extends DraggableItem>({
  items,
  onSort,
}: SortableItems<T>) => {
  const [sortedItems, setSortedItems] = useState<typeof items>(items);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const reorderedItems = reorder(items, result.source.index, result.destination.index);
    setSortedItems(reorderedItems);
    onSort(reorderedItems);
  };

  useEffect(() => {
    setSortedItems(items);
  }, [items]);

  return { onDragEnd, sortedItems };
};
