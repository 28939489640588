import { useEffect, useMemo, useRef, useState } from 'react';

import { useTranslationContext } from '../../../../../services/translation';
import { allClasses } from '../../../../../services/utilities/array';
import {
  DiscoveryMultipleChoiceAnswer,
  DiscoverySectionMultipleChoiceOutput,
  DiscoverySectionType,
} from '../../../../services/api-iteration1';
import { StorageFileType } from '../../../../services/api-cloud-storage/api-models';

import { CheckboxIcon, RadioButtonIconEmpty } from '../../../../assets/icons';
import { SectionImage } from './SectionImage';
import { SectionTypeLabel } from './SectionTypeLabel';

import styles from './ViewSection.module.css';

interface ViewMultipleChoiceProps {
  multipleChoiceSection: DiscoverySectionMultipleChoiceOutput;
  answer: DiscoveryMultipleChoiceAnswer | undefined;
  onSetAnswer?: (answer: Omit<DiscoveryMultipleChoiceAnswer, 'duration'>) => void;
  interactive?: boolean;
}

const customChoice = 'user-custom-choice';

export const ViewMultipleChoice = ({
  multipleChoiceSection,
  answer,
  onSetAnswer,
  interactive,
}: ViewMultipleChoiceProps) => {
  const { t } = useTranslationContext.useContext();

  const [customInputValue, setCustomInputValue] = useState('');

  const customAnswerRef = useRef<HTMLInputElement>(null);

  const { _id, title, files, others } = multipleChoiceSection;
  const { description, choices, customEnabled, type } = others;

  const sectionImage = files.find((f) => f.type === StorageFileType.SectionImage);

  const isMultipleChoice = type === 'multiple';
  const availableChoices = useMemo(
    () => (customEnabled && choices ? [...choices, customChoice] : choices),
    [choices, customEnabled]
  );

  const createSingleAnswer = (value: number | string) =>
    ({
      type: DiscoverySectionType.MultipleChoice,
      choice: value,
      choiceType: 'single',
    } as const);

  const setAnswer = (
    newAnswer: number | string,
    customMultipleChoice?: {
      selected?: boolean;
      ignore?: boolean;
    }
  ) => {
    if (!isMultipleChoice) return onSetAnswer?.(createSingleAnswer(newAnswer));

    const prevChoices = answer?.choices || [];

    const isCustomChoice = !!customMultipleChoice;

    if (isCustomChoice) {
      return customMultipleChoice?.ignore
        ? undefined
        : onSetAnswer?.({
            type: DiscoverySectionType.MultipleChoice,
            choiceType: 'multiple',
            choices: prevChoices,
            choice: customMultipleChoice?.selected ? newAnswer : undefined,
          });
    }

    const answerIndex = prevChoices.findIndex((oldAnswer) => oldAnswer === newAnswer);

    if (answerIndex === -1)
      return onSetAnswer?.({
        ...answer,
        type: DiscoverySectionType.MultipleChoice,
        choiceType: 'multiple',
        choices: [...prevChoices, newAnswer as number],
      });

    prevChoices.splice(answerIndex, 1);

    onSetAnswer?.({
      ...answer,
      type: DiscoverySectionType.MultipleChoice,
      choiceType: 'multiple',
      choices: [...prevChoices],
    });
  };

  const selectCustomChoice = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>,
    selected: boolean
  ) => {
    setAnswer(customInputValue, { selected });
    e.stopPropagation();
  };

  useEffect(() => {
    if (_id) setCustomInputValue('');
  }, [_id]);

  return (
    <div className={styles.view_section_wrapper}>
      {sectionImage && <SectionImage image={sectionImage} />}
      {interactive && (
        <SectionTypeLabel label={t('components.projects.sections.question')} className='w-full' />
      )}
      <h6 className='inline-block w-full text-xl text-main-contrast font-bold leading-30 mb-2.5 break-all'>
        {title}
      </h6>
      {description && (
        <p className='inline-block w-full text-lg text-main-contrast font-normal leading-normal'>
          {description}
        </p>
      )}
      <div className='flex flex-col justify-start mt-7 w-full'>
        {availableChoices?.map((choice, index) => {
          const isCustomChoice = choice === customChoice;

          const value = index;

          const selectedCustomChoice = isMultipleChoice
            ? typeof answer?.choice !== 'undefined'
            : typeof answer?.choice === 'string';

          const isActive = isCustomChoice
            ? selectedCustomChoice
            : isMultipleChoice
            ? answer?.choices?.includes(value)
            : answer?.choice === value;

          const Icon = isMultipleChoice ? CheckboxIcon : RadioButtonIconEmpty;

          return (
            <div
              key={index}
              onClick={() => (isCustomChoice ? customAnswerRef.current?.focus() : setAnswer(value))}
              className={allClasses(
                'relative flex items-center p-3 mb-3 border-2 border-solid rounded-lg w-full',
                isActive ? 'border-main-highlight' : 'border-main-10',
                onSetAnswer && 'cursor-pointer'
              )}>
              {isActive && <div className='absolute inset-0 bg-main-highlight opacity-10 z-0' />}
              <Icon
                className={allClasses(
                  'z-10 mr-4',
                  isMultipleChoice
                    ? isActive && 'icon-main-contrast icon-fill-main-contrast'
                    : isActive && 'icon-stroke-circle-main-contrast icon-fill-circle-main-contrast'
                )}
                onClick={isCustomChoice ? (e) => selectCustomChoice(e, !isActive) : undefined}
              />
              {isCustomChoice ? (
                <input
                  ref={customAnswerRef}
                  value={customInputValue}
                  maxLength={200}
                  disabled={!onSetAnswer}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setCustomInputValue(newValue);
                    setAnswer(newValue, { selected: isActive });
                  }}
                  onFocus={(e) => {
                    setAnswer(e.target.value, { ignore: true });
                  }}
                  placeholder={t(
                    'components.projects.sections.multiple-choice.custom-answer-placeholder'
                  )}
                  className='text-xl text-main-contrast placeholder-neutral-20 bg-transparent outline-none font-bold p-1 w-full'
                />
              ) : (
                <p className='text-xl text-main-contrast font-bold'>{choice || '...'}</p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
