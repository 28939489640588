import { ProjectVersionResponsePermission } from '@iteration1/permission-validators';
import { useState } from 'react';

import { useTranslationContext } from '../../../services/translation';
import { useCopyToClipboard } from '../../services/copy-to-clipboard/useCopyToClipboard';
import { usePermissionsContext } from '../../services/authorization';
import { useUpgradeModalToggle } from '../../services/billing/useUpgradeModalToggle';
import { endDiscovery, FolderChildType } from '../../services/api-iteration1';
import { useErrorHandlerContext } from '../../../services/error-handling';
import { useCurrentDiscoveryVersionContext } from '../../services/discovery';

import { Button } from '../../../components';
import { Tooltip } from '../../../components/tooltip/Tooltip';
import { Notification } from '../../../components/notification/Notification';

import { InviteTestersIcon } from '../../assets/icons';
import { ProjectTypeActionsButton } from '../buttons/ProjectTypeActionsButton';
import { ResourceCollaboratorsButton } from '../buttons/ResourceCollaboratorsButton';
import { EmbedTestButton } from '../buttons';

interface DiscoveryAnalyticsNavbarProps {
  shouldShowButton: boolean;
  validResponsesCount: number;
  testUrl: string;
}

export const DiscoveryAnalyticsNavbar = ({
  shouldShowButton,
  validResponsesCount,
  testUrl,
}: DiscoveryAnalyticsNavbarProps) => {
  const { t } = useTranslationContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();
  const { checkPermission } = usePermissionsContext.useContext();
  const { openUpgradeModal } = useUpgradeModalToggle.useContext();
  const {
    discovery,
    discoveryVersion,
    discoveryId,
    discoveryVersionId,
    removeDiscovery,
    renameCurrentDiscovery,
    revalidateProject,
  } = useCurrentDiscoveryVersionContext.useContext();

  const { copied, copyToClipboard } = useCopyToClipboard();
  const [showNotification, setShowNotification] = useState<boolean>(false);

  const copyShareLink = () => {
    copyToClipboard(testUrl);
  };

  const allowReceiveNewResponses = checkPermission(ProjectVersionResponsePermission.create, {
    count: validResponsesCount,
  });

  const onEndTest = async () => {
    if (!discoveryId || !discoveryVersionId) return;

    try {
      await endDiscovery(discoveryId, discoveryVersionId);
      setShowNotification(true);
    } catch (err) {
      handleError(err);
    }
  };

  const collaboratorsCount = discovery?.collaborators?.length;

  return (
    <>
      <ResourceCollaboratorsButton
        displayCount={!!collaboratorsCount}
        projectDetails={{
          type: FolderChildType.Discovery,
          id: discoveryId,
          collaboratorsCount: collaboratorsCount,
        }}
        revalidateResource={revalidateProject}
      />
      {!!validResponsesCount && (
        <EmbedTestButton
          className='ml-5'
          testUrl={testUrl}
          testId={discoveryId}
          testName={discovery?.name}
        />
      )}
      <div className='relative ml-5'>
        <Tooltip
          content={
            <div className='flex flex-row items-center justify-between'>
              <span className='w-2.5 h-2.5 relative inline-block mr-2.5 border-default border-main-highlight border-solid rounded-sm'>
                <span className='w-2.5 h-2.5 inline-block border-default border-main-highlight border-solid rounded-sm absolute right-1 bottom-1'></span>
                <span className='w-2 h-2 absolute top-0 left-0 bg-main-contrast'></span>
              </span>
              {t('components.projects.project-actions.invite-testers-tooltip')}
            </div>
          }
          direction='bottom'>
          {shouldShowButton && (
            <Button
              className='button__filled px-4'
              onClick={allowReceiveNewResponses ? copyShareLink : () => openUpgradeModal()}>
              {copied ? (
                t('components.projects.navbar.button.copied')
              ) : (
                <>
                  <InviteTestersIcon />
                  <span className='px-2'>
                    {t('components.projects.project-actions.invite-testers')}
                  </span>
                </>
              )}
            </Button>
          )}
        </Tooltip>
      </div>
      <ProjectTypeActionsButton
        onClickRename={renameCurrentDiscovery}
        projectType='discovery'
        onClickDelete={removeDiscovery}
        status={discoveryVersion?.status}
        {...{ onEndTest }}
      />
      {showNotification && (
        <Notification
          title={t('components.projects.end-test.notification.title')}
          description={t('components.projects.end-test.notification.description')}
        />
      )}
    </>
  );
};
