import { useIteration1Resource } from '../config';
import { FolderChildType, FolderParentOutput } from '../folders';
import { endpoints } from './enpoints';

export * from './api-models';
export * from './discovery';
export * from './userTest';

export const useProjectParents = (prototypeId: string, type: FolderChildType) => {
  return useIteration1Resource<FolderParentOutput[]>(endpoints.v1.projectParents(prototypeId), {
    fetchOnlyParams: { type },
  });
};

export const useProjectsCount = () => {
  return useIteration1Resource<number>(endpoints.v1.projectsCount());
};
