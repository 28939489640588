import { FolderChildType } from '../../../../services/api-iteration1';

import { ResourceCollaboratorsButton } from '../../../buttons/ResourceCollaboratorsButton';
import { DateFromToday } from '../../../formatters/DateFromToday';

interface ProjectCardFooterProps {
  date: Date;
  projectDetails: {
    type: FolderChildType;
    id: string;
    collaboratorsCount: number | undefined;
  };
  revalidateResource: () => void;
}

export const ProjectCardFooter = ({
  date,
  projectDetails,
  revalidateResource,
}: ProjectCardFooterProps) => {
  return (
    <div className='flex flex-row items-center justify-between'>
      <span className='text-neutral-70 text-base font-medium leading-5'>
        <DateFromToday {...{ date }} />
      </span>
      <ResourceCollaboratorsButton {...{ projectDetails, revalidateResource }} />
    </div>
  );
};
