interface ProjectCardBodyProps {
  title: string;
}

export const ProjectCardBody = ({ title }: ProjectCardBodyProps) => {
  return (
    <p className='text-main-contrast text-xl medium font-medium overflow-hidden text-ellipsis mt-5 mb-auto'>
      {title}
    </p>
  );
};
