import { DefaultSectionWelcomeOutput } from '../../../../services/api-iteration1';
import { useTranslationContext } from '../../../../../services/translation';
import { StorageFileType } from '../../../../services/api-cloud-storage/api-models';

import { Button } from '../../../../../components';

import { WelcomeIllustration } from '../../../../assets/icons';
import { SectionImage } from './SectionImage';

import styles from './ViewSection.module.css';

interface ViewWelcomeScreenProps {
  welcomeSection: DefaultSectionWelcomeOutput;
  onStart?: () => void;
  isEmbeddedIframe?: boolean;
}

export const ViewWelcomeScreen = ({
  welcomeSection,
  onStart,
  isEmbeddedIframe,
}: ViewWelcomeScreenProps) => {
  const { t } = useTranslationContext.useContext();

  const { title, files, others } = welcomeSection;
  const { message, steps } = others;

  const sectionImage = files.find((f) => f.type === StorageFileType.SectionImage);

  return (
    <div
      className={
        isEmbeddedIframe
          ? styles.view_section_wrapper_with_top_margin_embedded
          : styles.view_section_wrapper_with_top_margin
      }>
      {sectionImage ? (
        <SectionImage image={sectionImage} />
      ) : (
        <WelcomeIllustration className='mb-8 max-w-full' />
      )}
      <h6 className='text-xl text-main-contrast font-bold leading-30 mb-2.5 break-all'>{title}</h6>
      <p className='text-lg text-main-contrast font-normal leading-normal mb-10'>{message}</p>
      <div className='grid grid-flow-col auto-cols-fr gap-10 justify-center'>
        {Array.isArray(steps) &&
          steps.map((step, index) => (
            <div key={index} className='flex flex-col items-center text-center'>
              <div className='border-main-highlight border-2 border-solid w-10 h-10 rounded-full flex items-center justify-center mb-6'>
                {index + 1}
              </div>
              <p className='text-base text-neutral font-regular leading-5 break-words'>{step}</p>
            </div>
          ))}
      </div>
      <Button className='button__filled mt-8' onClick={onStart}>
        <span className='px-5'>{t('common.get-started')}</span>
      </Button>
    </div>
  );
};
