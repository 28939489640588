import { PaymentElement, PaymentElementProps } from '@stripe/react-stripe-js';
import { StripePaymentElementChangeEvent } from '@stripe/stripe-js';

export type PaymentMethodChangeEvent = StripePaymentElementChangeEvent;

export interface PaymentMethodProps extends PaymentElementProps {
  onChange: (event: PaymentMethodChangeEvent) => any;
}

export const PaymentMethod = (props: PaymentMethodProps) => {
  return <PaymentElement {...props} />;
};
