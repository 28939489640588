import { Tab } from '@headlessui/react';

import { allClasses } from '../../services/utilities/array';

type Tab = {
  title: string;
  content: React.ReactNode;
};

interface TabsProps {
  tabs: Tab[];
  contentClassName?: string;
  defaultIndex: number;
}

export const Tabs = ({ tabs, contentClassName, defaultIndex }: TabsProps) => {
  return (
    <div>
      <Tab.Group {...{ defaultIndex }}>
        <Tab.List className='border-b-2 border-main-10 border-solid'>
          {tabs.map((tab) => (
            <Tab
              key={tab.title}
              className={({ selected }) =>
                allClasses(
                  'inline-block -mb-0.5 py-2.5 px-5 text-base leading-5 font-medium',
                  'hover:text-main-contrast focus-visible:outline-none',
                  selected
                    ? 'bg-main text-main-contrast border-b-2 border-main-highlight border-solid'
                    : 'text-neutral-60'
                )
              }>
              {tab.title}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className='mt-10'>
          {tabs.map((tab, index) => (
            <Tab.Panel className={contentClassName} key={index}>
              {tab.content}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
