import { useMemo, useRef } from 'react';

import { useTranslationContext } from '../../../services/translation';
import { ScreenAnalytics, useAnalyticsQueryParams } from '../../services/analytics';
import { useScreenAnalyticsFilterContext } from '../../services/analytics/useScreenAnalyticsFilter';
import { usePreloadResources } from '../../../services/preload';
import { getTestClickPosition } from '../../services/api-iteration1/design-platforms/figma/utils';
import { useElementResize } from '../../../services/resize';
import { getStoragePublicFileUrl } from '../../services/api-cloud-storage';
import { FolderChildType } from '../../services/api-iteration1';

import { Modal } from '../../../components';

import { CloseIcon } from '../../assets/icons';
import { FigmaPrototypeImage } from '../views/live-prototype/FigmaPrototypeImage';
import { AnalyticsScreensFilter } from '../headers/AnalyticsScreensFilters';
import { AnalyticsScreensList } from '../lists/AnalyticsScreensList';
import { AnalyticsScreensEmptyState } from '../empty-states/AnalyticsScreensEmptyState';
import { ScreensFiltersTypes } from '../config';

interface ScreenAnalyticsModalProps {
  isOpen: boolean;
  closeModal: () => void;
  screensAnalytics: ScreenAnalytics[];
  versionName?: string;
  selectedFilter: ScreensFiltersTypes;
  expandSection: (sectionId: string) => void;
  testerBreadcrumbValue?: string;
  isIndividualResponse?: boolean;
  showFilters?: boolean;
}

export const ScreenAnalyticsModal = ({ ...props }: ScreenAnalyticsModalProps) => (
  <useScreenAnalyticsFilterContext.ProvideContext>
    <ScreenAnalyticsModalComponent {...props} />
  </useScreenAnalyticsFilterContext.ProvideContext>
);

const ScreenAnalyticsModalComponent = ({
  isOpen,
  closeModal,
  screensAnalytics,
  versionName,
  selectedFilter,
  expandSection,
  testerBreadcrumbValue,
  isIndividualResponse = false,
  showFilters = true,
}: ScreenAnalyticsModalProps) => {
  const { t } = useTranslationContext.useContext();
  const { queryParams, setQueryParams } = useAnalyticsQueryParams<FolderChildType.UserTest>();

  const currentFrame = screensAnalytics.find((item) => item.frameId === queryParams.screenId);

  const imgRef = useRef<HTMLImageElement>(null);
  const dimensions = useElementResize(imgRef);

  const framesImagesPreloads = useMemo(() => {
    const links = screensAnalytics.map((frame) =>
      frame?.screenImage
        ? {
            as: 'image',
            rel: 'preload',
            crossOrigin: 'anonymous',
            href: getStoragePublicFileUrl(frame.screenImage.id),
          }
        : undefined
    );

    return links.filter(Boolean) as NonNullable<typeof links[number]>[];
  }, [screensAnalytics]);

  usePreloadResources(framesImagesPreloads);

  const selectScreensFilter = (filter: ScreensFiltersTypes) => {
    const firstScreen =
      filter === 'all'
        ? screensAnalytics[0]
        : screensAnalytics.find((item) => item.usabilityScoreType === filter);

    setQueryParams((prev) => ({
      ...prev,
      scoreFilter: filter,
      screenId: firstScreen?.frameId,
      screenFilter: undefined,
    }));
  };

  const selectScreenOption = (option: 'all-clicks' | 'first-click' | undefined) => {
    setQueryParams((prev) => ({
      ...prev,
      screenFilter: option,
    }));
  };

  const showEmptyState =
    !screensAnalytics.find((item) => item.usabilityScoreType === selectedFilter) &&
    selectedFilter !== 'all';

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      variant='full-page'
      closeModalComponent={<CloseIcon className='icon-hover-main-contrast' />}
      closeIconClassName='absolute top-5 right-5'
      headerTitleComponent={
        <div className='absolute top-5 left-20 flex w-full py-2.5 rounded items-center cursor-default'>
          <span
            className='no-underline font-bold block truncate max-w-full text-neutral text-xl leading-5 cursor-pointer hover:text-main-contrast'
            onClick={closeModal}>
            {versionName}
          </span>
          {testerBreadcrumbValue && (
            <>
              <span className='font-bold text-neutral text-xl leading-5 px-5'>/</span>
              <span
                className='no-underline font-bold block truncate max-w-full text-neutral text-xl leading-5 cursor-pointer hover:text-main-contrast'
                onClick={closeModal}>
                {testerBreadcrumbValue}
              </span>
            </>
          )}
          <span className='font-bold text-neutral text-xl leading-5 px-5'>/</span>
          <span className='no-underline font-bold block truncate max-w-full text-main-contrast text-xl leading-5'>
            {t('components.projects.usability-testing.results.single-goal.screens.section-title')}
          </span>
        </div>
      }>
      <div className='flex flex-1 flex-row items-start mt-20 px-20 w-full min-h-screen'>
        <div className='w-90 pr-2'>
          {/** TODO: check why we can't use w-full instead of w-90 */}
          <div className='flex flex-row mb-4 w-90'>
            {showFilters && (
              <AnalyticsScreensFilter
                filterClass='flex flex-1 justify-center items-center py-1.5 rounded-xl text-main-contrast cursor-pointer text-xs'
                onSelect={(filter) => selectScreensFilter(filter)}
                selectedFilter={selectedFilter}
              />
            )}
          </div>
          <AnalyticsScreensList
            {...{
              screensAnalytics,
              selectedFilter,
              isIndividualResponse,
            }}
            sectionExpand={expandSection}
            expandedSection={queryParams.screenId || ''}
            setFilterInFocus={selectScreenOption}
            inFocusFilterId={queryParams.screenFilter || undefined}
          />
        </div>
        {currentFrame ? (
          <div className='flex flex-row justify-center relative w-full items-center pt-16 pb-5'>
            <FigmaPrototypeImage
              imgRef={imgRef}
              width={currentFrame.screenBounds.width}
              imgFileId={currentFrame.screenImage.id}>
              {queryParams.screenFilter === 'all-clicks' &&
                !!currentFrame.clicks.length &&
                currentFrame.clicks.map((_, index) => {
                  const position = getTestClickPosition({
                    clickBoundingBox: { ...currentFrame.clicks[index], width: 10, height: 10 },
                    frameBoundingBox: {
                      width: currentFrame.clicks[index].containerWidth || 0,
                      height: currentFrame.clicks[index].containerHeight || 0,
                      x: 0,
                      y: 0,
                    },
                    htmlElDimensions: dimensions,
                  });

                  return (
                    <div
                      key={index}
                      className='absolute border-solid border-main-highlight border-2 rounded-full z-59'
                      style={position}
                    />
                  );
                })}
              {queryParams.screenFilter === 'first-click' && !!currentFrame.clicks.length && (
                <div
                  className='absolute border-solid border-main-highlight border-2 rounded-full z-59 w-4 h-4'
                  style={getTestClickPosition({
                    clickBoundingBox: { ...currentFrame.clicks[0], width: 10, height: 10 },
                    frameBoundingBox: {
                      width: currentFrame.clicks[0].containerWidth || 0,
                      height: currentFrame.clicks[0].containerHeight || 0,
                      x: 0,
                      y: 0,
                    },
                    htmlElDimensions: dimensions,
                  })}
                />
              )}
            </FigmaPrototypeImage>
          </div>
        ) : showEmptyState ? (
          <AnalyticsScreensEmptyState />
        ) : null}
      </div>
    </Modal>
  );
};
