import { useState } from 'react';

import { createContextService } from '../../../services/context';

export interface LivePrototypeFocus {
  goalIndex?: number;
  pathIndex?: number;
  navigatedFramesIds: string[];
}

interface LivePrototypeLocation {
  goalIndex?: number;
  pathIndex?: number;
  navigatedFramesIds: string[];
  currentFrameId?: string;
}

export interface LivePrototypeNavigationService extends LivePrototypeLocation {
  setPrototypeLocation: React.Dispatch<React.SetStateAction<LivePrototypeLocation>>;
}

const useLivePrototypeNavigation = (): LivePrototypeNavigationService => {
  const [prototypeLocation, setPrototypeLocation] = useState<LivePrototypeLocation>({
    navigatedFramesIds: [],
  });

  return {
    goalIndex: prototypeLocation.goalIndex,
    pathIndex: prototypeLocation.pathIndex,
    navigatedFramesIds: prototypeLocation.navigatedFramesIds,
    currentFrameId: [...prototypeLocation.navigatedFramesIds].pop(),
    setPrototypeLocation,
  };
};

const useLivePrototypeNavigationContext = createContextService(useLivePrototypeNavigation);

export { useLivePrototypeNavigationContext as useLivePrototypeNavigation };
