import { useMemo, useState } from 'react';

import { useErrorHandlerContext } from '../../../../services/error-handling';
import { useTranslationContext } from '../../../../services/translation';
import {
  updateCurrentUser,
  uploadCurrentUserProfilePicture,
} from '../../../services/api-iteration1';
import {
  composeValidators,
  isRequired,
  validateEmail,
  isValidText,
} from '../../../services/validation';
import { getStoragePublicFileUrl, imageFileSizeLimit } from '../../../services/api-cloud-storage';
import { useCurrentUserContext } from '../../../services/current-user';

import {
  Button,
  createFormSubmitHandler,
  Form,
  FormError,
  FormField,
  LoadingOrContent,
  UploadProfileImage,
} from '../../../../components';

interface ProfileSettingsProps {
  icon: React.ReactNode;
  title: string;
}

export const ProfileSettings = ({ icon, title }: ProfileSettingsProps) => {
  const { t } = useTranslationContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();
  const { currentUser, isLoadingUser, reloadUser } = useCurrentUserContext.useContext();
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

  const initialProfileValues = useMemo(
    () => ({
      firstName: currentUser?.firstName || '',
      lastName: currentUser?.lastName || '',
      email: currentUser?.email || '',
      country: currentUser?.country?.toUpperCase() || '',
    }),
    [currentUser]
  );

  const toggleSuccessMessage = () => {
    setShowSuccessMessage(true);
    setTimeout(() => setShowSuccessMessage(false), 2500);
  };

  const updateProfile = createFormSubmitHandler<typeof initialProfileValues>({
    tryAction: async (values) => {
      await updateCurrentUser({
        ...values,
        email: values.email !== initialProfileValues.email ? values.email : undefined,
        privacyConsent: true,
        termsConsent: true,
      });
    },
    onError: (err) => handleError(err),
    onSuccess: () => {
      toggleSuccessMessage();
      reloadUser();
    },
  });

  return (
    <LoadingOrContent isLoading={isLoadingUser}>
      {currentUser && (
        <div className='w-full flex flex-col px-5 border-2 border-main-10 border-solid rounded-lg'>
          <div className='flex flex-row py-5 items-center justify-between border-b-2 border-main-10 border-solid'>
            <span className='flex flex-row items-center'>
              {icon}
              <span className='pl-5 text-base leading-5 text-main-contrast font-bold'>{title}</span>
            </span>
          </div>
          <div className='px-10 py-20'>
            <Form initialValues={initialProfileValues} enableReinitialize onSubmit={updateProfile}>
              {({ isValidForSubmit, dirty, status }) => (
                <>
                  <FormError {...{ status }} />

                  <div className='w-full flex flex-row items-center justify-start mb-10'>
                    <UploadProfileImage
                      excludeFromForm
                      fileSizeLimit={imageFileSizeLimit}
                      title={t('form.fields.update-avatar')}
                      placeholderImgSrc={
                        currentUser.image && getStoragePublicFileUrl(currentUser.image.id)
                      }
                      upload={async (image) => {
                        await uploadCurrentUserProfilePicture(image);
                      }}
                      onUploadError={handleError}
                    />
                  </div>

                  <div className='w-2/3 max-w-sm'>
                    <div className='mb-6 flex flex-col items-start justify-between sm:flex-row'>
                      <FormField
                        type='text'
                        name='firstName'
                        placeholder={t('form.fields.first-name')}
                        label={t('form.fields.first-name')}
                        validate={composeValidators(isValidText, isRequired)}
                        fieldClassName='w-full relative mb-6 sm:w-1/2 sm:mr-5 sm:mb-0'
                        inputClassName='text-main-contrast border-main-10 border-2 border-solid placeholder-neutral rounded-lg hover:border-main-highlight focus:border-main-highlight focus:outline-none'
                        labelClassName='text-sm text-main-contrast font-bold'
                      />
                      <FormField
                        type='text'
                        name='lastName'
                        placeholder={t('form.fields.last-name')}
                        label={t('form.fields.last-name')}
                        validate={composeValidators(isValidText, isRequired)}
                        fieldClassName='w-full relative sm:w-1/2'
                        inputClassName='text-main-contrast border-main-10 border-2 border-solid placeholder-neutral rounded-lg hover:border-main-highlight focus:border-main-highlight focus:outline-none'
                        labelClassName='text-sm text-main-contrast font-bold'
                      />
                    </div>
                    <FormField
                      name='email'
                      type='email'
                      placeholder={t('form.fields.email-address')}
                      label={t('form.fields.email-address')}
                      validate={validateEmail}
                      fieldClassName='mb-6 relative'
                      inputClassName='text-main-contrast border-main-10 border-2 border-solid placeholder-neutral rounded-lg hover:border-main-highlight focus:border-main-highlight focus:outline-none'
                      labelClassName='text-sm text-main-contrast font-bold'
                    />
                  </div>
                  <div className='w-full max-w-sm flex flex-row items-center'>
                    <Button
                      className='button__filled py-2.5 px-10'
                      type='submit'
                      disabled={!isValidForSubmit || !dirty}>
                      {t('common.save')}
                    </Button>
                    {showSuccessMessage && (
                      <span className='flex ml-4 text-success font-bold'>
                        {t('pages.account.messages.successfully-update-profile')}
                      </span>
                    )}
                  </div>
                </>
              )}
            </Form>
          </div>
        </div>
      )}
    </LoadingOrContent>
  );
};
