import { ReactNode } from 'react';

import { useTranslationContext } from '../../../../../services/translation';

import { AttentionIcon, UsabilityInfoIcon } from '../../../../assets/icons';
import { SectionTypeLabel } from './SectionTypeLabel';

import styles from './SectionIntermediaryScreens.module.css';

interface SectionInstructionsProps {
  id?: string;
  label: string;
  title: string;
  description?: string;
  info?: {
    messages: string[];
  };
  warning?: {
    messages: string[];
  };
  children?: ReactNode;
}

export const SectionInstructions = ({
  id,
  label,
  title,
  description,
  info,
  warning,
}: SectionInstructionsProps) => {
  const { t } = useTranslationContext.useContext();

  return (
    <div id={id} className={styles.container}>
      <div className='w-full max-w-md'>
        <SectionTypeLabel {...{ label }} />
        <h5 className='mb-2 break-all'>{title}</h5>
        {description && <div className='mt-1 text-main-contrast'>{description}</div>}
      </div>
      <div className='max-w-md'>
        {info && (
          <div className='flex flex-col items-center mb-6 pb-4 bg-info-10 rounded-10'>
            <UsabilityInfoIcon className='m-3' />
            <h6 className='text-info mt-2 mb-4'>
              {t('components.projects.sections.instructions.info.title')}
            </h6>
            {info.messages.map((message) => (
              <div key={message} className='text-info text-center pl-14 pr-14'>
                {message}
              </div>
            ))}
          </div>
        )}
        {warning && (
          <div className='flex flex-col items-center mb-6 pb-4 bg-warning-10 rounded-10'>
            <AttentionIcon className='m-3' />
            <h6 className='text-warning mt-2 mb-4'>
              {t('components.projects.sections.instructions.warning.title')}
            </h6>
            {warning.messages.map((message) => (
              <div key={message} className='text-warning text-center pl-14 pr-14'>
                {message}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
