import {
  DiscoverySectionType,
  isTemporaryFile,
  PreferenceSectionText,
  SectionStorageFile,
} from '../../api-iteration1';

export const sectionValidators: Record<
  DiscoverySectionType,
  (section: Record<string, any>) => boolean
> = {
  [DiscoverySectionType.YesNo]: (section) => !!section.title,
  [DiscoverySectionType.RatingScale]: (section) => !!section.title,
  [DiscoverySectionType.MultipleChoice]: (section) =>
    !!section.title &&
    Array.isArray(section.others.choices) &&
    section.others.choices.filter(Boolean).length === section.others.choices.length,
  [DiscoverySectionType.OpenWordChoice]: (section) => !!section.title,
  [DiscoverySectionType.Preference]: (section) => {
    const others = section.others;

    const hasEmptyTexts =
      others.texts && !!others.texts.filter((text: PreferenceSectionText) => !text.context).length;
    const underTextCountThreshold = others.texts && others.texts.length < 2;

    if (others.type === 'text' && (underTextCountThreshold || hasEmptyTexts)) return false;

    const hasTemporaryFiles =
      section.files &&
      !!section.files.filter((file: SectionStorageFile) => isTemporaryFile(file.id)).length;
    const underFileCountThreshold = section.files && section.files.length < 2;

    if (others.type === 'image' && (underFileCountThreshold || hasTemporaryFiles)) return false;

    return !!section.title && ['image', 'text'].includes(section.others.type);
  },
  [DiscoverySectionType.Legal]: (section) =>
    !!section.title && section.files.length > 0 && !!section.others.message,
};

export const answerValidators: Record<
  DiscoverySectionType,
  (answer: Record<string, any>) => boolean
> = {
  [DiscoverySectionType.YesNo]: (answer) => !!answer && ['yes', 'no'].includes(answer.answer),
  [DiscoverySectionType.RatingScale]: (answer) => {
    return !!answer && typeof answer.answer === 'number';
  },
  [DiscoverySectionType.MultipleChoice]: (answer) => {
    if (!answer) return false;

    const isValidChoice = (choice: any) =>
      typeof choice === 'number' || (!!choice && typeof choice === 'string');

    if (answer.choiceType === 'single') return isValidChoice(answer.choice);
    if (answer.choiceType === 'multiple') {
      return (
        (!!answer.choices?.length &&
          answer.choices.filter(isValidChoice).length === answer.choices.length) ||
        isValidChoice(answer.choice)
      );
    }

    return false;
  },
  [DiscoverySectionType.OpenWordChoice]: (answer) =>
    !!answer && !!answer.answer && typeof answer.answer === 'string',
  [DiscoverySectionType.Preference]: (answer) =>
    !!answer &&
    typeof answer === 'object' &&
    typeof answer.choice === 'number' &&
    ['image', 'text'].includes(answer.choiceType),
  [DiscoverySectionType.Legal]: (answer) => !!answer && answer.agreed,
};
