import { useState, useRef } from 'react';

import { handleErrorSilently } from '../../services/error-handling';
import { allClasses } from '../../services/utilities/array';

import { LoaderIcon } from '../../domain/assets/icons';

type ReactButton = JSX.IntrinsicElements['button'];
interface ButtonProps extends ReactButton {
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void | Promise<void>;
  loading?: boolean;
  unstoppableLoading?: boolean;
  loadingOpacity?: '10' | '20' | '30' | '40' | '50' | '60' | '70' | '80' | '90' | '100';
  loadingIconType?: 'light' | 'dark';
  disabledClassName?: string;
}

export const Button = ({
  children,
  className,
  onClick,
  disabled,
  disabledClassName,
  loading,
  unstoppableLoading,
  loadingOpacity = '50',
  loadingIconType,
  ...otherProps
}: ButtonProps) => {
  const buttonRef = useRef(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClick = async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (!onClick) return;

    setIsLoading(true);
    try {
      await onClick(event);
    } catch (err) {
      handleErrorSilently(err);
    }

    const existsInDOM = !!buttonRef.current;
    if (existsInDOM && !unstoppableLoading) setIsLoading(false);
  };

  const showLoading = loading || isLoading;
  const isDisabled = showLoading || disabled;

  return (
    <button
      ref={buttonRef}
      type='button'
      className={allClasses(
        className,
        isDisabled && allClasses('cursor-default opacity-[0.16]', disabledClassName),
        showLoading && 'is-loading',
        showLoading && `opacity-${loadingOpacity}`
      )}
      onClick={handleClick}
      disabled={isDisabled}
      {...otherProps}
      aria-label={`${showLoading ? 'loading-state' : 'default-state'}`}>
      {showLoading ? (
        <div className='animate-spin-slow'>
          <LoaderIcon
            aria-label='Loading'
            className={loadingIconType === 'light' ? 'icon-main' : undefined}
          />
        </div>
      ) : (
        children
      )}
    </button>
  );
};
