import { tryJSONParse } from '../../utilities/json';

export class ApiValidationError {
  static contains = (err: any): boolean => {
    const apiError = tryJSONParse(
      err && err.response && err.response.data && err.response.data.message
    );
    const isApiValidationError = err?.isAxiosError && apiError && typeof apiError === 'object';

    return !!isApiValidationError;
  };

  static getErrorList = (err: any): string[] => {
    const apiError = tryJSONParse(
      err && err.response && err.response.data && err.response.data.message
    );

    try {
      const errors: string[] = Object.values(apiError);

      return errors;
    } catch (err) {
      return [];
    }
  };

  static getErrors = (err: any): Record<string, string> => {
    const apiError: Record<string, string> = tryJSONParse(
      err && err.response && err.response.data && err.response.data.message
    );

    return apiError;
  };
}
