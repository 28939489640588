import { Api } from './api-factory';

export * from './api-factory';
export * from './wrapper';

export const http = new Api({
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
});
