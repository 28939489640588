export class AuthenticationError {
  static contains = (err: any): boolean => {
    const isAuthError =
      (err && err.code && typeof err.code === 'string' && err.code.startsWith('auth/')) ||
      (err && err.message && typeof err.message === 'string' && err.message.startsWith('auth/'));

    return !!isAuthError;
  };

  static getError = (err: any): string => {
    const errorKey = err && (err.code || err.message);

    return `errors.firebase.${errorKey}`;
  };
}
