import justDebounceIt from 'just-debounce-it';
import { useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
type Debounce = <T extends Function>(
  fn: T,
  wait: number,
  callFirst?: boolean
) => T & {
  cancel: () => void;
  flush: () => void;
};

export const debounce: Debounce = (fn, wait, callFirst) =>
  justDebounceIt(fn, wait, callFirst as any);

export const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
