import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export const initErrorReporting = () => {
  if (process.env.NODE_ENV !== 'production') return;

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: process.env.NODE_ENV,
    tracesSampleRate: 1.0,
  });
};

export const reportAppError = (err: any) => {
  if (process.env.NODE_ENV != 'production') return console.log('Reported error:', err);

  Sentry.captureException(err);
};
