import { StorageFile } from '../../../../../../api-cloud-storage/api-models';
import { DiscoverySectionType } from '../../discovery';
import { UsabilitySectionType } from '../../usability';

export * from './utils';

export interface UsabilityAnswer {
  type: DiscoverySectionType | UsabilitySectionType;
  duration: number; //milliseconds
  [key: string]: any;
}

export const prototypeClickTypes = ['hotspot', 'missclick'] as const;

export interface PrototypeClick {
  x: number;
  y: number;
  timestamp: number;
  type: typeof prototypeClickTypes[number];
  containerWidth?: number;
  containerHeight?: number;
}

export interface UsabilitySingleGoalAnswer extends UsabilityAnswer {
  video?: StorageFile;
  successType?: 'direct' | 'indirect';
  frames: {
    id: string;
    index: number;
    clicks: PrototypeClick[];
    duration?: number; // milliseconds
  }[];
}

export interface UsabilitySectionFiveSecondsAnswer extends UsabilityAnswer {
  clicks: PrototypeClick[];
}

export interface UsabilitySectionFirstClickAnswer extends UsabilityAnswer {
  type: UsabilitySectionType.FirstClick;
  click: PrototypeClick;
}
