import {
  DiscoveryRatingScaleAnswer,
  DiscoverySectionRatingScaleOutput,
} from '../../../../../services/api-iteration1';
import { useTranslationContext } from '../../../../../../services/translation';
import { StorageFileType } from '../../../../../services/api-cloud-storage/api-models';
import { allClasses } from '../../../../../../services/utilities/array';

import { SectionImage } from '../SectionImage';
import { SectionTypeLabel } from '../SectionTypeLabel';
import ViewNumerical from './ViewNumerical';
import ViewEmotions from './ViewEmotions';
import ViewStars from './ViewStars';

import styles from '../ViewSection.module.css';

interface ViewRatingScaleProps {
  ratingScaleSection: DiscoverySectionRatingScaleOutput;
  answer: DiscoveryRatingScaleAnswer | undefined;
  onSetAnswer?: (answer: Omit<DiscoveryRatingScaleAnswer, 'duration'>) => void;
  interactive?: boolean;
}

export const ViewRatingScale = ({
  ratingScaleSection,
  answer,
  onSetAnswer,
  interactive,
}: ViewRatingScaleProps) => {
  const { t } = useTranslationContext.useContext();

  const { title, files, others } = ratingScaleSection;
  const { description, type, startingStep, stepsCount, labels } = others;

  const sectionImage = files.find((f) => f.type === StorageFileType.SectionImage);

  return (
    <div className={allClasses(styles.view_section_wrapper, 'pt-5')}>
      {sectionImage && <SectionImage image={sectionImage} />}
      <div className='flex flex-col w-full sm:w-auto sm:max-w-full'>
        {interactive && <SectionTypeLabel label={t('components.projects.sections.question')} />}
        <h6 className='text-xl text-main-contrast font-bold leading-30 mb-2.5 break-all text-center sm:text-left'>
          {title}
        </h6>
        {description && (
          <p className='text-lg text-main-contrast font-normal leading-normal text-center'>
            {description}
          </p>
        )}
        <div className='flex flex-col items-center sm:items-start sm:flex-row justify-start min-h-min mt-7 pb-10 sm:pb-0'>
          {type === 'stars' ? (
            <ViewStars {...{ answer, onSetAnswer, labels }} />
          ) : type === 'numerical' ? (
            <ViewNumerical
              {...{ answer, onSetAnswer, labels }}
              startingStep={startingStep || 0}
              stepsCount={stepsCount || 6}
            />
          ) : type === 'emotions' ? (
            <ViewEmotions {...{ answer, onSetAnswer, labels }} />
          ) : null}
        </div>
      </div>
    </div>
  );
};
