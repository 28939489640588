import { useTranslationContext } from '../../../../services/translation';

import styles from './FrameCard.module.css';

interface FrameCardProps {
  imgSrc?: string;
  lastModified?: string;
  index?: number;
  frameId: string;
  isSelected: boolean;
  pathIndex: number;
  onSelect: (pathIndex: number, frameId: string) => void;
}

export const FrameCard = ({
  imgSrc,
  index,
  lastModified,
  onSelect,
  frameId,
  isSelected,
  pathIndex,
}: FrameCardProps) => {
  const { t } = useTranslationContext.useContext();
  return (
    <div
      className={styles.card_wrapper}
      onClick={!isSelected ? () => onSelect(pathIndex, frameId) : undefined}>
      <div className='relative pt-5 bg-main-10 text-center'>
        {imgSrc && (
          <img
            className='h-64 max-w-[90%] inline-block'
            src={imgSrc}
            alt={`${t('common.screen')} ${index}`}
          />
        )}
        {isSelected && (
          <div className='w-full h-56 absolute top-0 left-0 flex items-center justify-center bg-main-contrast rounded-t-lg opacity-95'>
            <span className='inline-block py-1 px-2 text-base text-main-contrast font-medium leading-tight bg-main-highlight rounded'>
              {t(
                'components.projects.sections.single-goal.edit.change-start-screen.current-screen'
              )}
            </span>
          </div>
        )}
      </div>
      <div className='w-full absolute left-0 bottom-0 z-1 p-5 bg-main rounded-b-lg'>
        <p className='text-xl text-main-contrast font-medium'>
          {t('common.screen')} {index}
        </p>
        <p className='text-base font-medium leading-tight text-neutral-70'>{lastModified}</p>
      </div>
      {!isSelected && (
        <div className={styles.overlay_wrapper}>
          <span className='inline-block py-1 px-2 text-base text-main-contrast font-medium leading-tight bg-main-highlight rounded'>
            {t('components.projects.sections.single-goal.edit.change-start-screen.select-screen')}
          </span>
        </div>
      )}
    </div>
  );
};
