import { allClasses } from '../../../../../services/utilities/array';

interface SectionTypeLabelProps {
  label: string;
  className?: string;
}

export const SectionTypeLabel = ({ label, className }: SectionTypeLabelProps) => {
  return <div className={allClasses('subtitle-2 text-neutral-70 mb-2.5', className)}>{label}</div>;
};
