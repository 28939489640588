import { useRef } from 'react';

import { useTranslationContext } from '../../../services/translation';

import { Modal } from '../../../components/modal';
import { Button, createFormSubmitHandler, Form, FormError, FormField } from '../../../components';

import { CloseIcon, SadIcon } from '../../assets/icons';

interface GiveUpModalProps {
  isOpen: boolean;
  closeModal: () => void;
  giveUp: (reason: string) => void;
}

export const GiveUpModal = ({ isOpen, closeModal, giveUp }: GiveUpModalProps) => {
  const { t } = useTranslationContext.useContext();
  const focusInputRef = useRef(null);

  const giveUpTesting = createFormSubmitHandler<{ reason: string }>({
    tryAction: (values) => giveUp(values.reason),
    onSuccess: () => {
      closeModal();
    },
    resetFormOnSubmit: true,
  });

  return (
    <Modal
      title={t('components.projects.sections.modals.give-up.title')}
      isOpen={isOpen}
      onClose={closeModal}
      variant='regular'
      closeModalComponent={<CloseIcon className='icon-hover-main-contrast' />}
      refInitialFocus={focusInputRef}
      containerClassName='max-w-full'
      className='w-[22rem] sm:w-[600px]'>
      <div className='flex flex-col items-center pt-10 text-center'>
        <SadIcon className='icon-small' />
        <h5 className='mt-4 text-2xl'>{t('components.projects.sections.modals.give-up.title')}</h5>
        <div className='mt-5 mb-10 text-neutral-70 w-96 max-w-[90%] text-lg leading-6'>
          {t('components.projects.sections.modals.give-up.description')}
        </div>
        <div className='mb-20 w-11/12'>
          <Form
            className='flex flex-col flex-1 items-center'
            initialValues={{ reason: '' }}
            onSubmit={giveUpTesting}>
            {({ status, isValidForSubmit, isSubmitting, values }) => {
              return (
                <>
                  <FormError {...{ status }} />
                  <FormField
                    autoFocus
                    as='textarea'
                    type='text'
                    name='reason'
                    placeholder={t('components.projects.sections.modals.give-up.placeholder')}
                    fieldClassName='w-full sm:w-96 mb-10'
                    inputClassName='py-3 px-5 h-32 bg-main border-2 rounded-lg border-solid border-main-10 text-lg leading-6 placeholder-neutral hover:border-main-highlight focus:border-main-highlight focus:outline-none'
                    innerRef={focusInputRef}
                  />

                  <Button
                    className='button__filled px-10'
                    disabled={!isValidForSubmit || isSubmitting || !values.reason}
                    type='submit'
                    loading={isSubmitting}>
                    <span className='text-main-contrast leading-5 text-base font-medium'>
                      {t('common.give-up')}
                    </span>
                  </Button>
                </>
              );
            }}
          </Form>
        </div>
      </div>
    </Modal>
  );
};
