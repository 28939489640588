import { useTranslationContext } from '../../../services/translation';
import { useCurrentUserContext } from '../../services/current-user';

import { Navlink } from '../../../components';

import { NotFoundPageIllustration } from '../../assets/icons';
import { routes } from '../../pages/routing/routes';
import { NoNavbarLayout } from './NoNavbarLayout';
import { MainLayout } from '.';

const NotFound = () => {
  const { t } = useTranslationContext.useContext();

  return (
    <div className='flex w-full h-full flex-col justify-center items-center text-center'>
      <div className='flex justify-center items-center w-full max-w-2xl py-14'>
        <NotFoundPageIllustration />
      </div>
      <p className='text-xl leading-7 font-bold text-main-contrast mt-10 mb-2.5'>
        {t('pages.404.title')}
      </p>
      <p className='w-full max-w-2xl m-0 text-lg text-main-contrast leading-6'>
        {t('pages.404.description')}
      </p>
      <div className='mt-10'>
        <Navlink
          to={routes.home}
          className='text-info border-b-default border-main-70 border-solid hover:text-info-70'>
          {t('pages.404.back-to-homepage')}
        </Navlink>
      </div>
    </div>
  );
};

export const NotFoundLayout = () => {
  const { t } = useTranslationContext.useContext();
  const { isAuthenticated, isLoadingUser } = useCurrentUserContext.useContext();

  const pageProps = {
    title: t('pages.404.title'),
    analyticsName: t('pages.404.title'),
    isLoading: isLoadingUser,
    availableOnMobile: true,
  };

  return isAuthenticated ? (
    <MainLayout {...pageProps}>
      <NotFound />
    </MainLayout>
  ) : (
    <NoNavbarLayout {...pageProps}>
      <NotFound />
    </NoNavbarLayout>
  );
};
