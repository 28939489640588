import { useState } from 'react';

import { useErrorHandlerContext } from '../../../services/error-handling';
import { FolderChildType, FolderParentOutput } from '../../services/api-iteration1';
import {
  createNewDiscovery,
  createNewUserTest,
  DiscoveryTemplate,
  NewDiscoveryInput,
  NewUserTestInput,
  ProjectType,
} from '../../services/api-iteration1/projects';
import { useRouter } from '../../../services/router';

import { Modal } from '../../../components/modal';
import { createFormSubmitHandler, FormActions, useMultiStep } from '../../../components';

import { ProjectTypeModalStep1 } from './ProjectTypeModalStep1';
import { ProjectTypeModalStep2 } from './ProjectTypeModalStep2';
import { ProjectTypeModalStep3 } from './ProjectTypeModalStep3';
import { BackIcon, CloseIcon } from '../../assets/icons';
import { dynamicRoutes } from '../../pages/routing/routes';

interface CreateProjectTypeModalProps {
  isOpen: boolean;
  closeModal: () => void;
  onSuccess: () => void;
  parents?: FolderParentOutput[];
}

export const CreateProjectTypeModal = ({
  isOpen,
  closeModal,
  onSuccess,
  parents,
}: CreateProjectTypeModalProps) => {
  const router = useRouter();

  const { stepTo, isStep } = useMultiStep<'flows' | 'project_name' | 'project_details'>('flows');
  const [chosenFlow, setChosenFlow] = useState<ProjectType>();
  const [initialValues, setInitialValues] = useState<any>({ name: '' });
  const { handleError } = useErrorHandlerContext.useContext();

  const selectFlow = (type: ProjectType) => {
    stepTo('project_name');
    setChosenFlow(type);
  };

  const addProjectName = ({ name }: { name: string }) => {
    stepTo('project_details');
    setInitialValues({ name });
  };

  const createProject = createFormSubmitHandler<
    Omit<NewDiscoveryInput | NewUserTestInput, 'parents'>
  >({
    tryAction: (values) => {
      const newProject = parents?.length ? { ...values, parents } : values;
      if (chosenFlow === FolderChildType.Discovery)
        return createNewDiscovery(newProject as NewDiscoveryInput);
      if (chosenFlow === FolderChildType.UserTest)
        return createNewUserTest(newProject as NewUserTestInput);
    },
    onSuccess: (result) => {
      onSuccess();
      closeModal();
      if (chosenFlow === FolderChildType.Discovery)
        router.push(dynamicRoutes.discovery(result.data.id));
      if (chosenFlow === FolderChildType.UserTest)
        router.push(dynamicRoutes.userTest(result.data.id));
    },
    onError: (err) => handleError(err),
    resetFormOnSubmit: true,
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      variant='full-page'
      closeModalComponent={<CloseIcon className='icon-hover-main-contrast' />}
      backButton={
        isStep('project_name') ? (
          <button onClick={() => stepTo('flows')}>
            <BackIcon className='icon-hover-main-contrast' />
          </button>
        ) : undefined
      }
      closeIconClassName='pt-5'
      headerClassName='container-with-margin'>
      {isStep('flows') ? (
        <ProjectTypeModalStep1 onSelectFlow={selectFlow} />
      ) : isStep('project_name') && chosenFlow ? (
        <ProjectTypeModalStep2
          projectType={chosenFlow}
          initialValues={initialValues}
          onClickSubmit={
            chosenFlow === FolderChildType.Discovery
              ? (values, formActions) =>
                  createProject(
                    { ...values, template: DiscoveryTemplate.Blank },
                    formActions as FormActions<any>
                  )
              : addProjectName
          }
        />
      ) : isStep('project_details') && chosenFlow ? (
        <ProjectTypeModalStep3
          projectType={chosenFlow}
          initialValues={initialValues}
          onSubmitProject={createProject}
        />
      ) : null}
    </Modal>
  );
};
