import { useState } from 'react';

export const useHover = <M = undefined>() => {
  const [{ isHover, metadata }, setIsHover] = useState<{
    isHover: boolean;
    metadata: M | undefined;
  }>({
    isHover: false,
    metadata: undefined,
  });

  const onHover = (metadata: M) => {
    setIsHover({ isHover: true, metadata });
  };

  const onLeave = () => setIsHover({ isHover: false, metadata: undefined });

  return {
    isHover,
    metadata,
    onHover,
    onLeave,
  };
};
