import { useCallback, useEffect, useMemo, useReducer } from 'react';

import Firebase, { FirebaseAuthError, FirebaseAuthUser, FirebaseUserCredential } from './firebase';
import { isNewUser } from './utils';
import { Actions, AuthReducer, authReducer } from './reducer';
import { handleErrorSilently } from '../error-handling';
import { useResourceFetcherWrapper } from '../api';
import { removeFromStorage, saveToStorage } from '../local-storage';

import { localStorageKeys } from '../../domain/services/local-storage';

export enum AuthState {
  isLoading,
  isAuthenticated,
  isNotAuthenticated,
}

interface useAuthenticationProps {
  tryOnNewAccount: (account: FirebaseUserCredential, otherProfileInfo?: any) => Promise<void>;
  setToken(token: string | undefined, expirationTime?: string): void;
}

interface UseAuthentication {
  currentAccount: FirebaseAuthUser | null | undefined;
  isAuthenticated: boolean;
  isLoadingUser: boolean;
  error: FirebaseAuthError | unknown | undefined;
  clearAuthSession(): void;
  clearSocialAuthError(): void;
  signInWithEmail(email: string, password: string): Promise<void>;
  signUpWithEmail(email: string, password: string, otherProfileInfo: any): Promise<void>;
  signInWithGoogle(): Promise<void>;
  signOut(): Promise<void>;
  reloadAccount(): Promise<FirebaseAuthUser | null>;
  updatePassword(password: string): Promise<void>;
  reauthenticateWithEmail(password: string): Promise<FirebaseUserCredential | void>;
}

export const useAuthentication = ({
  setToken,
  tryOnNewAccount,
}: useAuthenticationProps): UseAuthentication => {
  const { clearResourceFetcherCache } = useResourceFetcherWrapper();
  const [state, dispatch] = useReducer<AuthReducer>(authReducer, {
    isLoading: true,
    account: undefined,
    error: undefined,
  });

  const loadToken = useCallback(
    async (user: FirebaseAuthUser) => {
      const { token, expirationTime } = await user.getIdTokenResult();
      setToken(token, expirationTime);
    },
    [setToken]
  );

  const loadAuthSession = useCallback(
    async (user: FirebaseAuthUser) => {
      await loadToken(user);
      dispatch({ type: Actions.Success, user });
    },
    [loadToken]
  );

  const failAuthSession = useCallback(
    (error: unknown) => {
      dispatch({ type: Actions.Fail, error });
      setToken(undefined);
    },
    [setToken]
  );

  const clearAuthSession = useCallback(() => {
    dispatch({ type: Actions.Clear });
    setToken(undefined);
    clearResourceFetcherCache();
  }, [clearResourceFetcherCache, setToken]);

  const clearSocialAuthError = useCallback(() => dispatch({ type: Actions.ResetError }), []);

  const signInWithEmail = useCallback(
    async (email: string, password: string) => {
      const user = await Firebase.signInWithEmail(email, password);
      if (user.user && (await isNewUser(user))) {
        await loadToken(user.user);

        await tryOnNewAccount(user);
      }
    },
    [loadToken, tryOnNewAccount]
  );

  const signUpWithEmail = useCallback(
    async (email: string, password: string, otherProfileInfo?: unknown) => {
      const user = await Firebase.signUpWithEmail(email, password);
      if (user.user && (await isNewUser(user))) {
        await loadToken(user.user);

        saveToStorage(localStorageKeys.signUpInProgress, true);
        await tryOnNewAccount(user, otherProfileInfo);
        removeFromStorage(localStorageKeys.signUpInProgress);
      }
    },
    [loadToken, tryOnNewAccount]
  );

  const signInWithGoogle = useCallback(async () => {
    try {
      const user = await Firebase.signInWithGoogle();
      if (user.user && (await isNewUser(user))) {
        await loadToken(user.user);

        saveToStorage(localStorageKeys.signUpInProgress, true);
        await tryOnNewAccount(user);
        removeFromStorage(localStorageKeys.signUpInProgress);
      }
    } catch (err) {
      failAuthSession(err);
    }
  }, [failAuthSession, loadToken, tryOnNewAccount]);

  const signOut = useCallback(async () => {
    Firebase.signOut();
    clearAuthSession();
  }, [clearAuthSession]);

  useEffect(() => {
    const unsubscribe = Firebase.onTokenChanged(
      async (user) => {
        if (!user) return dispatch({ type: Actions.Fail });
        loadAuthSession(user).catch((err) => {
          handleErrorSilently(err);
          clearAuthSession();
        });
      },
      (err) => handleErrorSilently(err)
    );

    return unsubscribe;
  }, [clearAuthSession, loadAuthSession]);

  /**
   * Necessary due to Firebase method onTokenChanged failure to react to changes
   * when window is running in background
   * This forces token refresh when window gets back in foreground
   */
  useEffect(() => {
    const refreshToken = () => state.account && loadToken(state.account);

    window.addEventListener('focus', refreshToken, true);

    return () => {
      window.removeEventListener('focus', refreshToken);
    };
  }, [loadToken, setToken, state.account]);

  return useMemo(
    () => ({
      currentAccount: state.account,
      isAuthenticated: !state.isLoading && !!state.account,
      isLoadingUser: state.isLoading,
      error: state.error,
      clearSocialAuthError,
      signInWithEmail,
      signUpWithEmail,
      signInWithGoogle,
      signOut,
      reloadAccount: Firebase.reloadUser,
      clearAuthSession,
      updatePassword: Firebase.updatePassword,
      reauthenticateWithEmail: Firebase.reauthenticateWithEmail,
    }),
    [
      clearAuthSession,
      clearSocialAuthError,
      signInWithEmail,
      signInWithGoogle,
      signOut,
      signUpWithEmail,
      state.account,
      state.error,
      state.isLoading,
    ]
  );
};
