import { allClasses } from '../../services/utilities/array';

import { Navlink } from '../navigation';

import styles from './Card.module.css';

interface CardProps {
  header: JSX.Element;
  body: JSX.Element;
  footer: JSX.Element;
  urlTo: string;
  className?: string;
}

export const Card = ({ header, body, footer, urlTo, className }: CardProps) => {
  return (
    <Navlink end to={urlTo} className={allClasses(styles.container, className)}>
      <div className='flex flex-col justify-between border-2 border-main-10 border-solid rounded-lg p-5 w-full h-full hover:border-main-highlight'>
        {header}
        {body}
        {footer}
      </div>
    </Navlink>
  );
};
