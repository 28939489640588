import { useTranslationContext } from '../../../services/translation';
import { FolderChildType, UsabilitySingleGoalAnswer } from '../../services/api-iteration1';
import { getKeyForCondition } from '../../../services/utilities/object';
import {
  countSingleGoalBounces,
  calculateAvgSingleGoalDuration,
  calculateSingleGoalCompletionRate,
  calculateSingleGoalMisclickRate,
  calculateSingleGoalSuccessfullCompletionRate,
} from '../../services/analytics/calculus';
import { allClasses } from '../../../services/utilities/array';
import { ScreenAnalytics, useAnalyticsQueryParams } from '../../services/analytics';

import { Tabs } from '../../../components/tabs/Tabs';

import { ChevronRightIcon } from '../../assets/icons';
import { renderAnalyticsIcon } from '../icons';
import { ScreenAnalyticsModal } from '../modals/ScreensAnalyticsModal';
import { ScreensFiltersTypes } from '../config';

interface AnalyticsSingleGoalTabsCardProps {
  answers: UsabilitySingleGoalAnswer[];
  versionName?: string;
  screensAnalytics: ScreenAnalytics[];
  screenCategoriesCount: {
    screensToRework: number;
    screensToCheck: number;
    greatScreens: number;
  };
  sectionId: string;
}

export const AnalyticsSingleGoalTabsCard = ({
  answers,
  versionName,
  screensAnalytics,
  screenCategoriesCount,
  sectionId,
}: AnalyticsSingleGoalTabsCardProps) => {
  const { t } = useTranslationContext.useContext();
  const { queryParams, setQueryParams } = useAnalyticsQueryParams<FolderChildType.UserTest>();
  const { screensToCheck, screensToRework, greatScreens } = screenCategoriesCount;

  const openMetricsModal = (filter: ScreensFiltersTypes) => {
    const firstScreenInFilter = screensAnalytics.find((item) => item.usabilityScoreType === filter);

    setQueryParams((prev) => ({
      ...prev,
      metrics: sectionId,
      scoreFilter: filter,
      screenId: firstScreenInFilter?.frameId,
    }));
  };

  const closeMetricsModal = () => {
    setQueryParams(undefined);
  };

  const expandSection = (screenId: string) => {
    setQueryParams((prev) => ({
      ...prev,
      screenId: prev.screenId !== screenId ? screenId : undefined,
      screenFilter: undefined,
    }));
  };

  return (
    <Tabs
      contentClassName='min-h-17 outline-none'
      tabs={[
        {
          title: t(
            'components.projects.usability-testing.results.single-goal.key-stats.section-title'
          ),
          content: (
            <>
              <ViewAnalyticsSingleGoalStatisticsBlock
                color={getKeyForCondition({
                  'success-result': answers.length >= 5,
                  'warning-result': answers.length >= 3 && answers.length < 5,
                  'danger-result': answers.length < 3,
                })}
                icon='users'
                isLast={false}
                title={t(
                  'components.projects.usability-testing.results.single-goal.key-stats.total-testers'
                )}
                value={answers.length}
              />
              <ViewAnalyticsSingleGoalStatisticsBlock
                color={getKeyForCondition({
                  'success-result': calculateAvgSingleGoalDuration(answers) < 10,
                  'warning-result':
                    calculateAvgSingleGoalDuration(answers) >= 10 &&
                    calculateAvgSingleGoalDuration(answers) < 30,
                  'danger-result': calculateAvgSingleGoalDuration(answers) >= 30,
                })}
                icon='time'
                title={t(
                  'components.projects.usability-testing.results.single-goal.key-stats.average-duration'
                )}
                value={`${calculateAvgSingleGoalDuration(answers)}s`}
              />
              <ViewAnalyticsSingleGoalStatisticsBlock
                color={getKeyForCondition({
                  'success-result': calculateSingleGoalMisclickRate(answers) < 30,
                  'warning-result':
                    calculateSingleGoalMisclickRate(answers) >= 30 &&
                    calculateSingleGoalMisclickRate(answers) < 50,
                  'danger-result': calculateSingleGoalMisclickRate(answers) >= 50,
                })}
                icon='first-click'
                title={t(
                  'components.projects.usability-testing.results.single-goal.key-stats.misclick-rate'
                )}
                value={`${calculateSingleGoalMisclickRate(answers)}%`}
              />
              <ViewAnalyticsSingleGoalStatisticsBlock
                isLast
                color={getKeyForCondition({
                  'success-result': calculateSingleGoalCompletionRate(answers) >= 70,
                  'warning-result':
                    calculateSingleGoalCompletionRate(answers) >= 30 &&
                    calculateSingleGoalCompletionRate(answers) < 70,
                  'danger-result': calculateSingleGoalCompletionRate(answers) < 30,
                })}
                icon='checks'
                title={t(
                  'components.projects.usability-testing.results.single-goal.key-stats.completion-rate'
                )}
                value={`${calculateSingleGoalCompletionRate(answers)}%`}
              />
            </>
          ),
        },
        {
          title: t(
            'components.projects.usability-testing.results.single-goal.outcome.section-title'
          ),
          content: (
            <>
              <ViewAnalyticsSingleGoalStatisticsBlock
                color={getKeyForCondition({
                  'success-result':
                    calculateSingleGoalSuccessfullCompletionRate(answers, 'direct') >= 70,
                  'warning-result':
                    calculateSingleGoalSuccessfullCompletionRate(answers, 'direct') >= 30 &&
                    calculateSingleGoalSuccessfullCompletionRate(answers, 'direct') < 70,
                  'danger-result':
                    calculateSingleGoalSuccessfullCompletionRate(answers, 'direct') < 30,
                })}
                redirectLink='/'
                icon='checks'
                title={t(
                  'components.projects.usability-testing.results.single-goal.outcome.direct-success'
                )}
                value={`${calculateSingleGoalSuccessfullCompletionRate(answers, 'direct')}%`}
              />
              <ViewAnalyticsSingleGoalStatisticsBlock
                color={getKeyForCondition({
                  'success-result':
                    calculateSingleGoalSuccessfullCompletionRate(answers, 'indirect') >= 70,
                  'warning-result':
                    calculateSingleGoalSuccessfullCompletionRate(answers, 'indirect') >= 30 &&
                    calculateSingleGoalSuccessfullCompletionRate(answers, 'indirect') < 70,
                  'danger-result':
                    calculateSingleGoalSuccessfullCompletionRate(answers, 'indirect') < 30,
                })}
                redirectLink='/'
                icon='checks'
                title={t(
                  'components.projects.usability-testing.results.single-goal.outcome.indirect-success'
                )}
                value={`${calculateSingleGoalSuccessfullCompletionRate(answers, 'indirect')}%`}
              />
              <ViewAnalyticsSingleGoalStatisticsBlock
                isLast
                color={getKeyForCondition({
                  'success-result': countSingleGoalBounces(answers) < 30,
                  'warning-result':
                    countSingleGoalBounces(answers) >= 30 && countSingleGoalBounces(answers) < 70,
                  'danger-result': countSingleGoalBounces(answers) >= 70,
                })}
                redirectLink='/'
                icon='checks'
                title={t(
                  'components.projects.usability-testing.results.single-goal.outcome.bounce-rate'
                )}
                value={`${countSingleGoalBounces(answers)}%`}
              />
            </>
          ),
        },
        {
          title: t(
            'components.projects.usability-testing.results.single-goal.screens.section-title'
          ),
          content: (
            <>
              <ViewAnalyticsSingleGoalStatisticsBlock
                color='danger-result'
                onClick={() => openMetricsModal('rework')}
                title={t(
                  'components.projects.usability-testing.results.single-goal.screens.screens-to-rework'
                )}
                value={screensToRework}
              />
              <ViewAnalyticsSingleGoalStatisticsBlock
                color='warning-result'
                onClick={() => openMetricsModal('check')}
                title={t(
                  'components.projects.usability-testing.results.single-goal.screens.screens-to-check'
                )}
                value={screensToCheck}
              />
              <ViewAnalyticsSingleGoalStatisticsBlock
                isLast
                color='success-result'
                onClick={() => openMetricsModal('great')}
                title={t(
                  'components.projects.usability-testing.results.single-goal.screens.great-screens'
                )}
                value={greatScreens}
              />
              {queryParams.metrics && (
                <ScreenAnalyticsModal
                  isOpen
                  closeModal={closeMetricsModal}
                  selectedFilter={(queryParams.scoreFilter || 'all') as ScreensFiltersTypes}
                  {...{
                    versionName,
                    screensAnalytics,
                    expandSection,
                    queryParams,
                    setQueryParams,
                  }}
                />
              )}
            </>
          ),
        },
      ]}
      defaultIndex={queryParams.metrics ? 2 : 0}
    />
  );
};

const ViewAnalyticsSingleGoalStatisticsBlock = (props: {
  title: string;
  icon?: 'users' | 'first-click' | 'time' | 'checks';
  value: number | string;
  color: 'danger-result' | 'success-result' | 'warning-result';
  isLast?: boolean;
  redirectLink?: string;
  onClick?: () => void;
}) => {
  return (
    <div
      className={allClasses(
        'flex flex-row items-center justify-between py-4',
        !props.isLast && 'border-b-default border-main-10 border-solid'
      )}>
      <p className='text-base text-main-contrast font-regular leading-5'>{props.title}</p>
      <div className='flex flex-row items-center'>
        <span className={allClasses('text-2xl font-bold leading-8 mr-5', props.color)}>
          {props.value}
        </span>
        {!props.redirectLink && props.icon ? (
          <span className={props.color}>{renderAnalyticsIcon(props.color, props.icon)}</span>
        ) : (
          ((props.redirectLink && !props.icon) || (props.onClick && !props.icon)) && (
            <a
              href={props.redirectLink}
              onClick={props.onClick}
              className={allClasses('cursor-pointer', !props.value && 'invisible')}>
              <ChevronRightIcon className='icon-hover-stroke-polyline-main-contrast' />
            </a>
          )
        )}
      </div>
    </div>
  );
};
