import { useState } from 'react';

import { useErrorHandlerContext } from '../../../../services/error-handling';
import { useTranslationContext } from '../../../../services/translation';
import { isValidPassword, passwordsMatch } from '../../../services/validation';
import { useCurrentUserContext } from '../../../services/current-user';

import {
  Button,
  createFormSubmitHandler,
  Form,
  FormError,
  FormField,
  LoadingOrContent,
} from '../../../../components';

interface PasswordSettingsProps {
  icon: React.ReactNode;
  title: string;
}

export const PasswordSettings = ({ icon, title }: PasswordSettingsProps) => {
  const { t } = useTranslationContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();

  const { currentUser, isLoadingUser, updatePassword, reauthenticateWithEmail } =
    useCurrentUserContext.useContext();
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

  const initialPasswordValues = {
    password: '',
    oldPassword: '',
    repeatPassword: '',
  };

  const toggleSuccessMessage = () => {
    setShowSuccessMessage(true);
    setTimeout(() => setShowSuccessMessage(false), 2500);
  };

  const updateCurrentPassword = createFormSubmitHandler<typeof initialPasswordValues>({
    tryAction: async (values) => {
      try {
        await updatePassword(values.password);
      } catch (err: any) {
        if (err.code !== 'auth/requires-recent-login') throw err;

        await reauthenticateWithEmail(values.oldPassword);
        await updatePassword(values.password);
      }
    },
    onError: (err) => handleError(err),
    onSuccess: () => toggleSuccessMessage(),
    resetFormOnSubmit: true,
  });

  return (
    <LoadingOrContent isLoading={isLoadingUser}>
      {currentUser && (
        <div className='w-full flex flex-col px-5 border-2 border-main-10 border-solid rounded-lg'>
          <div className='flex flex-row py-5 items-center justify-between border-b-2 border-main-10 border-solid'>
            <span className='flex flex-row items-center'>
              {icon}
              <span className='pl-5 text-base leading-5 text-main-contrast font-bold'>{title}</span>
            </span>
          </div>
          <div className='px-10 py-20'>
            <Form
              className='w-2/3'
              initialValues={initialPasswordValues}
              enableReinitialize
              onSubmit={updateCurrentPassword}>
              {({ isValidForSubmit, status, values, isSubmitting }) => (
                <>
                  <FormError {...{ status }} />

                  <div className='w-full max-w-sm'>
                    <FormField
                      type='password'
                      name='oldPassword'
                      placeholder={t('form.fields.old-password')}
                      label={t('form.fields.old-password')}
                      fieldClassName='mb-6'
                      inputClassName='text-main-contrast border-main-10 border-2 border-solid placeholder-neutral rounded-lg hover:border-main-highlight focus:border-main-highlight focus:outline-none'
                      labelClassName='text-sm text-main-contrast font-bold'
                    />
                    <FormField
                      type='password'
                      name='password'
                      placeholder={t('form.fields.new-password')}
                      label={t('form.fields.new-password')}
                      validate={isValidPassword}
                      fieldClassName='mb-6'
                      inputClassName='text-main-contrast border-main-10 border-2 border-solid placeholder-neutral rounded-lg hover:border-main-highlight focus:border-main-highlight focus:outline-none'
                      labelClassName='text-sm text-main-contrast font-bold'
                    />
                    <FormField
                      type='password'
                      name='repeatPassword'
                      placeholder={t('form.fields.confirm-password')}
                      label={t('form.fields.confirm-password')}
                      validate={(repeatPassword) => passwordsMatch(values.password, repeatPassword)}
                      fieldClassName='mb-6'
                      inputClassName='text-main-contrast border-main-10 border-2 border-solid placeholder-neutral rounded-lg hover:border-main-highlight focus:border-main-highlight focus:outline-none'
                      labelClassName='text-sm text-main-contrast font-bold'
                    />
                  </div>
                  <div className='w-full max-w-sm flex flex-row items-center'>
                    <Button
                      className='button__filled py-2.5 px-10'
                      type='submit'
                      loading={isSubmitting}
                      disabled={!isValidForSubmit}>
                      {t('common.save')}
                    </Button>
                    {showSuccessMessage && (
                      <span className='flex ml-4 text-success font-bold'>
                        {t('pages.account.messages.successfully-update-profile')}
                      </span>
                    )}
                  </div>
                </>
              )}
            </Form>
          </div>
        </div>
      )}
    </LoadingOrContent>
  );
};
