import { ReactNode } from 'react';

import { useTranslationContext } from '../../../../../../services/translation';

import { FocusOnScreenIllustration } from '../../../../../assets/icons';

import sectionStyles from '../ViewSection.module.css';

interface SectionInstructionsProps {
  title: string;
  description: string;
  children?: ReactNode;
}

export const SectionInstructions = ({ title, description }: SectionInstructionsProps) => {
  const { t } = useTranslationContext.useContext();

  return (
    <div className={sectionStyles.view_section_wrapper}>
      <div className='max-w-md self-center'>
        <div className='w-[120px] h-[120px] flex items-center justify-center mb-5 rounded-lg'>
          <FocusOnScreenIllustration />
        </div>
        <h6 className='h6 mb-2.5 text-neutral-70 font-normal'>{t('common.task')}</h6>
        <h5 className='mb-2.5 break-all'>{title}</h5>
        <p className='text-lg text-main-contrast leading-6 break-all'>{description}</p>
      </div>
    </div>
  );
};
