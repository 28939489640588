import { DeviceDetails } from '../../../../../../services/device';

import { QuestionAnswer } from '../../api-models';

export enum UserTestVersionResponseFeedbackType {
  SameHotspotMultipleClicks = 'sameHotspotMultipleClicks',
}

export type UserTestFeedback = {
  type: UserTestVersionResponseFeedbackType.SameHotspotMultipleClicks;
  sectionId: string;
  frameId: string;
  x: string;
  y: string;
  reason: string;
};

export interface UserTestVersionResponseOutput {
  id: string;
  userId?: string;
  versionId: string;
  totalQuestions: number;
  device: DeviceDetails;
  answers: {
    [sectionId: string]: QuestionAnswer;
  };
  feedback: UserTestFeedback[];
  giveUp?: {
    reason: string;
  };
  createdAt: Date;
  updatedAt: Date;
}

export type CreateUserTestVersionResponseOutput = Pick<
  UserTestVersionResponseOutput,
  'id' | 'createdAt'
>;
