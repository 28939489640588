import { AccessView } from '../components';

const AuthPage: React.FC<any> = (props) => {
  const { PageThatRequiresAuth, ...otherProps } = props;

  return (
    <AccessView requiresAuthentication>
      <PageThatRequiresAuth {...otherProps} />
    </AccessView>
  );
};

export const withAuth = (PageThatRequiresAuth: React.FC) => {
  return (...props: any) => <AuthPage {...props} {...{ PageThatRequiresAuth }} />;
};
