import { useTranslationContext } from '../../../services/translation';
import {
  FolderChildType,
  NewDiscoveryInput,
  NewUserTestInput,
  ProjectType,
} from '../../services/api-iteration1';
import { allClasses } from '../../../services/utilities/array';
import { isValidFigmaUrl } from '../../services/validation';
import { isFigmaUrl } from '../../services/api-iteration1/design-platforms/figma/utils';
import { DesignPlatform } from '../../services/api-iteration1/design-platforms';

import {
  Button,
  Form,
  FormError,
  FormField,
  useToggle,
  VoidAsyncFormFunction,
} from '../../../components';

import {
  AdobeXdIcon,
  DiscoveryProjectIllustration,
  FigmaIcon,
  InvisionIcon,
  MarvelIcon,
  SketchIcon,
  UsabilityProjectIllustration,
} from '../../assets/icons';
import { ProjectTemplatesList } from '../lists/ProjectTemplatesList';
import { PrototypeLinkModal } from './PrototypeLinkModal';

import styles from './Modals.module.css';

type ProjectTypeModalStep3Props = {
  projectType: ProjectType;
  initialValues: NewUserTestInput | NewDiscoveryInput;
  onSubmitProject: VoidAsyncFormFunction<Omit<NewDiscoveryInput | NewUserTestInput, 'parents'>>;
};

export const ProjectTypeModalStep3 = ({
  projectType,
  onSubmitProject,
  initialValues,
}: ProjectTypeModalStep3Props) => {
  const { t } = useTranslationContext.useContext();
  const {
    isOpen: isPrototypeLinkModalVisible,
    open: openPrototypeLinkModal,
    close: closePrototypeLinkModal,
  } = useToggle();

  return (
    <div className='text-center mt-20'>
      <div className={styles.project_type_thumbnail}>
        {projectType === 'discovery' ? (
          <DiscoveryProjectIllustration />
        ) : (
          <UsabilityProjectIllustration />
        )}
      </div>
      <h2 className={allClasses(styles.title, 'my-10')}>
        {t(`components.projects.project-type-modal.create-project-type.${projectType}`)}
      </h2>
      <Form
        className='flex flex-col items-center'
        initialValues={initialValues as any}
        onSubmit={onSubmitProject as VoidAsyncFormFunction<any>}
        enableReinitialize>
        {({
          status,
          isValidForSubmit,
          values,
          setFieldValue,
          isSubmitting,
          errors,
          touched,
          submitForm,
        }) => {
          return (
            <>
              <FormError {...{ status }} />
              <FormField
                type='text'
                name='name'
                fieldClassName='w-full smallScreen:max-w-md max-w-sm mb-10'
                disabled
                inputClassName='bg-main-10 border-2 rounded-lg border-main-10 border-solid py-3 px-5 m-0 focus:outline-none'
              />
              {projectType !== FolderChildType.UserTest ? (
                <ProjectTemplatesList type={projectType} values={values} onSelect={setFieldValue} />
              ) : (
                <>
                  <div className='w-full smallScreen:max-w-md max-w-sm mb-4 flex flex-row items-center text-base text-main-contrast leading-5'>
                    <span className='font-bold mr-11'>
                      {t(
                        'components.projects.project-type-modal.user-test-prototype-section.title'
                      )}
                    </span>
                    <div className='flex flex-row items-center'>
                      <span className='flex flex-row mr-5'>
                        {t(
                          'components.projects.project-type-modal.user-test-prototype-section.works-with'
                        )}
                        <FigmaIcon className='ml-1' />
                      </span>
                      <span className='flex flex-row'>
                        {t(
                          'components.projects.project-type-modal.user-test-prototype-section.soon'
                        )}
                        <SketchIcon className='ml-1' />
                        <AdobeXdIcon className='ml-1' />
                        <InvisionIcon className='ml-1' />
                        <MarvelIcon className='ml-1' />
                      </span>
                    </div>
                  </div>
                  <FormField
                    autoFocus
                    type='text'
                    name='designPrototype.url'
                    placeholder={t(
                      'components.projects.project-type-modal.placeholder-prototype-link'
                    )}
                    validate={isValidFigmaUrl}
                    fieldClassName='w-full smallScreen:max-w-md max-w-sm mb-5'
                    inputClassName={allClasses(
                      styles.project_input,
                      errors.designPrototype &&
                        touched.designPrototype &&
                        styles.project_input__invalid
                    )}
                    value={values.designPrototype?.url || ''}
                    onChange={(e) =>
                      setFieldValue('designPrototype', {
                        url: e.target.value,
                        type: isFigmaUrl(e.target.value) ? DesignPlatform.Figma : undefined,
                      })
                    }
                  />
                  {values.designPrototype && !errors.designPrototype && (
                    <div className='w-full smallScreen:max-w-md max-w-sm flex items-center justify-start -mt-3 mb-3'>
                      <FigmaIcon />
                      <span className='pl-2 text-info'>
                        {t('components.projects.project-type-modal.valid-prototype-link', {
                          linkType: 'Figma',
                        })}
                      </span>
                    </div>
                  )}
                  <span
                    className='text-info text-base font-medium leading-5 pb-1 border-b-default border-solid cursor-pointer mb-7 inline-block hover:text-info-70'
                    onClick={() => openPrototypeLinkModal()}>
                    {t('components.projects.project-type-modal.where-to-find-prototype-link')}
                  </span>
                  {isPrototypeLinkModalVisible && (
                    <PrototypeLinkModal
                      prototypeType='Figma'
                      demonstrationUrl='/'
                      addPrototypeLink={(values: any) => {
                        setFieldValue('designPrototype', {
                          url: values.designPrototype,
                          type: isFigmaUrl(values.designPrototype)
                            ? DesignPlatform.Figma
                            : undefined,
                        });

                        submitForm();
                      }}
                      isOpen={isPrototypeLinkModalVisible}
                      closeModal={closePrototypeLinkModal}
                    />
                  )}
                </>
              )}
              <Button
                className={allClasses(
                  'button__filled font-medium mt-5 px-10',
                  isSubmitting && 'cursor-default'
                )}
                disabled={
                  !isValidForSubmit ||
                  (projectType === FolderChildType.UserTest
                    ? !values.designPrototype
                    : !values.template) ||
                  isSubmitting
                }
                loadingOpacity='50'
                type='submit'
                loading={isSubmitting}>
                {t('common.create')}
              </Button>
            </>
          );
        }}
      </Form>
    </div>
  );
};
