import { useState } from 'react';

import { createContextService } from '../../../services/context';

const useDragArea = () => {
  const [itemIdToBeDragged, setItemIdToBeDragged] = useState<string>();

  return {
    itemIdToBeDragged,
    setItemIdToBeDragged,
  };
};

const useDragAreaContext = createContextService(useDragArea);

export { useDragAreaContext as useDragArea };
