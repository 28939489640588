import { FieldAttributes, FieldProps } from 'formik';

import { allClasses } from '../../services/utilities/array';
import { getKeyForCondition } from '../../services/utilities/object';

type RadioGroupItem = {
  icons?: {
    selected: React.ReactNode;
    notSelected: React.ReactNode;
  };
  label: string;
  value: string | number;
  disabled?: boolean;
};

interface RadioGroupProps extends FieldProps, FieldAttributes<any> {
  options: RadioGroupItem[];
  checkedWrapperClassName: string;
  checkedTextClassName: string;
  checked: string;
  wrapperClassName?: string;
  textAlignment?: 'left' | 'center' | 'right';
}

export const RadioGroup = ({
  options,
  checkedWrapperClassName,
  checkedTextClassName,
  checked,
  field,
  wrapperClassName,
  textAlignment = 'center',
  ...otherProps
}: RadioGroupProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { form, meta, ...inputProps } = otherProps;

  return (
    <div className={allClasses('grid auto-cols-auto gap-5', wrapperClassName)}>
      {options.map((option: RadioGroupItem) => (
        <div key={option.value} className='relative'>
          <input
            {...field}
            {...inputProps}
            type='radio'
            value={option.value}
            checked={checked === option.value}
            className={allClasses(
              'w-full h-full absolute opacity-0',
              option.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
            )}
            onChange={(e) => {
              field.onChange(e);
            }}
            onBlur={(e: any) => {
              field.onBlur(e);
            }}
            disabled={option.disabled}
          />
          <div
            className={allClasses(
              'border-main-10 border-2 border-solid rounded',
              checked === option.value && checkedWrapperClassName,
              option.disabled && 'bg-main-10'
            )}>
            <span
              className={allClasses(
                'w-full h-full flex flex-row py-3 px-5 items-center',
                getKeyForCondition({
                  'justify-start': textAlignment === 'left',
                  'justify-center': textAlignment === 'center',
                  'justify-end': textAlignment === 'right',
                })
              )}>
              {option.icons && (
                <span className='mr-2.5 leading-6'>
                  {checked === option.value ? option.icons.selected : option.icons.notSelected}
                </span>
              )}
              <span
                className={allClasses(
                  'text-lg leading-6 font-normal cursor-pointer',
                  checked === option.value ? checkedTextClassName : 'text-neutral'
                )}>
                {option.label}
              </span>
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};
