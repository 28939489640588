import { useQueryParams, useRouteParams } from '../../services/router';

import { ViewDiscoveryFlow } from '../components';

type PageParams = {
  discoveryId: string;
  versionId: string;
};

export const ViewDiscovery = () => {
  const { discoveryId, versionId } = useRouteParams<PageParams>();
  const {
    params: { embedded },
  } = useQueryParams<{ embedded: boolean }>();

  return <ViewDiscoveryFlow {...{ discoveryId, versionId }} isEmbeddedIframe={embedded} />;
};
