import { countries } from 'countries-list';

export const countryNamesList = Object.values(countries).map((country) => ({
  label: country.name,
  value: country.name,
}));

export const getCountry = (countryName: string) => {
  const [code, countryDetails] =
    Object.entries(countries).find(
      ([_countryCode, country]) => country.name.toLowerCase() === countryName.toLowerCase()
    ) || [];

  return { code, countryDetails };
};

export const getCountryNameByCode = (countryCode: string) => {
  const [, countryDetails] =
    Object.entries(countries).find(([code]) => code.toLowerCase() === countryCode.toLowerCase()) ||
    [];

  return countryDetails?.name;
};
