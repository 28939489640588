import { useState } from 'react';

import { useTranslationContext } from '../../../services/translation';
import { useErrorHandlerContext } from '../../../services/error-handling';
import { Collaborator } from '../../services/api-iteration1/collaborators/api-models';
import { getStoragePublicFileUrl } from '../../services/api-cloud-storage';
import { allClasses } from '../../../services/utilities/array';
import { removeCollaboratorFromAllProjects } from '../../services/api-iteration1/collaborators';

import { Button, Modal } from '../../../components';

import { CloseIcon, DefaultAvatarIcon } from '../../assets/icons';

interface RemoveCollaboratorProps {
  isOpen: boolean;
  close: () => void;
  collaborator: Collaborator;
  revalidate: () => void;
}

export const RemoveCollaboratorModal = ({
  isOpen,
  close,
  collaborator,
  revalidate,
}: RemoveCollaboratorProps) => {
  const { t } = useTranslationContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const removeCollaborator = async () => {
    try {
      setIsLoading(true);
      await removeCollaboratorFromAllProjects(collaborator.email);
      revalidate();
      close();
    } catch (err) {
      setIsLoading(false);
      handleError(err);
    }
  };

  return (
    <Modal
      title={t('pages.account.tabs.profile.delete-account-modal.title')}
      isOpen={isOpen}
      onClose={close}
      variant='regular'
      closeModalComponent={<CloseIcon className='icon-hover-main-contrast' />}
      closeIconClassName='absolute top-5 right-5'>
      <div className='pt-10 pb-20 text-center'>
        <h2 className='my-10 text-main-contrast leading-8 text-2xl font-bold'>
          {t('pages.account.tabs.collaborators.remove-modal.title')}
        </h2>
        <div className='flex flex-row items-center w-72 p-4 mx-auto mb-6 border-2 border-solid border-main-85 rounded-lg'>
          <div className='mr-5'>
            {collaborator.image ? (
              <img
                src={getStoragePublicFileUrl(collaborator.image?.id || '')}
                className={allClasses('h-full w-full object-cover overflow-hidden')}
              />
            ) : (
              <DefaultAvatarIcon className='w-10 h-10' />
            )}
          </div>
          <div className='text-left'>
            <p className='text-base leading-5 text-main-contrast font-medium'>
              {collaborator.firstName} {collaborator.lastName}
            </p>
            <p className='text-sm leading-[18px] text-neutral-70'>{collaborator.email}</p>
          </div>
        </div>
        <div className='mx-auto max-w-22 mb-10 text-main-contrast text-lg leading-6 font-medium'>
          {t('pages.account.tabs.collaborators.remove-modal.description')}
        </div>
        <div className='flex flex-row items-center justify-center'>
          <Button
            className='button__filled mr-4 py-2.5 px-10 justify-center bg-main border-2 border-solid border-main-10 hover:enabled:bg-main-10'
            type='button'
            onClick={close}
            disabled={isLoading}>
            {t('pages.account.tabs.collaborators.remove-modal.cancel')}
          </Button>
          <Button
            className='button__filled py-2.5 px-10 justify-center bg-danger-60  text-main hover:enabled:bg-danger'
            type='submit'
            onClick={removeCollaborator}
            loadingIconType='light'
            loadingOpacity='50'>
            {t('pages.account.tabs.collaborators.remove-modal.submit')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
