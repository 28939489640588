import { useTranslationContext } from '../../../services/translation';

import { NoResponsesIllustration } from '../../assets/icons';

export const AnalyticsResponsesEmptyState = () => {
  const { t } = useTranslationContext.useContext();

  return (
    <div className='flex flex-col justify-center items-center text-center h-screen w-full'>
      <NoResponsesIllustration className='w-[120px] mb-10' />
      <p className='text-xl text-main-contrast leading-30 font-bold mb-2.5'>
        {t('components.projects.analytics.responses.empty-state.title')}
      </p>
      <p className='text-lg text-main-contrast leading-6'>
        {t('components.projects.analytics.responses.empty-state.description-part-one')}
      </p>
      <p className='text-lg text-main-contrast leading-6'>
        {t('components.projects.analytics.responses.empty-state.description-part-two')}
      </p>
    </div>
  );
};
