import { useTranslationContext } from '../../../services/translation';
import {
  deleteUserTestVersion,
  endUserTest,
  renameUserTestVersion,
  VersionStatus,
} from '../../services/api-iteration1/projects';
import { UserTestVersionOutput } from '../../services/api-iteration1/projects/userTest/versions';
import { useErrorHandlerContext } from '../../../services/error-handling';

import { Card, useToggle } from '../../../components';
import { VersionCardHeader } from './components/header/VersionCardHeader';
import { VersionCardBody } from './components/body/VersionCardBody';
import { VersionCardFooter } from './components/footer/VersionCardFooter';
import { ProjectCardActions } from './components/actions/ProjectCardActions';
import styles from '../../../components/card/Card.module.css';

import { DoneIcon, LiveIcon, DraftIcon } from '../../assets/icons';
import { dynamicRoutes } from '../../pages/routing/routes';

interface UserTestVersionCardProps {
  userTestVersion: UserTestVersionOutput;
  userTestId: string;
  revalidate: () => void;
}

export const UserTestVersionCard = ({
  userTestVersion,
  userTestId,
  revalidate,
}: UserTestVersionCardProps) => {
  const { t } = useTranslationContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();

  const { isOpen: isMenuOpen, toggle: toggleOpenMenu } = useToggle();

  const removeUserTestVersion = async () => {
    if (!userTestId) return;

    try {
      await deleteUserTestVersion(userTestId, userTestVersion.id);
      revalidate();
    } catch (err) {
      handleError(err);
    }
  };

  const renameCurrentUserTestVersion = async (name: string) => {
    if (!userTestId) return;

    try {
      await renameUserTestVersion(userTestId, userTestVersion.id, { name });
      revalidate();
    } catch (err) {
      handleError(err);
    }
  };

  const { StatusIcon, title, status } = (() => {
    switch (userTestVersion.status) {
      case VersionStatus.Live:
        return {
          StatusIcon: LiveIcon,
          status: VersionStatus.Live,
          title: t(`components.projects.versions.${VersionStatus.Live}`),
        };
      case VersionStatus.Draft:
        return {
          StatusIcon: DraftIcon,
          status: VersionStatus.Draft,
          title: t(`components.projects.versions.${VersionStatus.Draft}`),
        };
      case VersionStatus.Done:
        return {
          StatusIcon: DoneIcon,
          status: VersionStatus.Done,
          title: t(`components.projects.versions.${VersionStatus.Done}`),
        };
      default:
        return { StatusIcon: () => null, title: '', status: VersionStatus.Draft };
    }
  })();

  const onEndTest = async () => {
    if (!userTestId || !userTestVersion.id) return;

    try {
      await endUserTest(userTestId, userTestVersion.id);
      revalidate();
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <Card
      className='max-w-xs'
      header={
        <div className='flex flex-row justify-between items-center relative'>
          <VersionCardHeader icon={<StatusIcon />} {...{ title, status }} />
          <ProjectCardActions
            className={styles.card_actions}
            onClick={toggleOpenMenu}
            isMenuOpen={isMenuOpen}
            onRemove={removeUserTestVersion}
            onRename={renameCurrentUserTestVersion}
            projectType='userTest'
            status={userTestVersion?.status}
            {...{ onEndTest }}
          />
        </div>
      }
      body={<VersionCardBody title={userTestVersion.name} />}
      footer={
        <VersionCardFooter
          date={userTestVersion.updatedAt}
          {...{ status }}
          responsesNumber={userTestVersion.responses.length}
        />
      }
      urlTo={
        status === VersionStatus.Draft
          ? dynamicRoutes.userTestVersion(userTestId, userTestVersion.id)
          : dynamicRoutes.userTestVersionSummary(userTestId, userTestVersion.id)
      }
    />
  );
};
