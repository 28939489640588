import { useState } from 'react';
import { MyProjectVersionPermission } from '@iteration1/permission-validators';

import { useRouteParams, useRouter } from '../../services/router';
import {
  createNewUserTestVersion,
  deleteUserTest,
  FolderChildType,
  updateUserTest,
  useUserTest,
} from '../services/api-iteration1';
import { useTranslationContext } from '../../services/translation';
import { useUserTestVersions } from '../services/api-iteration1/projects/userTest/versions';
import { useErrorHandlerContext } from '../../services/error-handling';
import { usePermissionsContext } from '../services/authorization';
import { useUpgradeModalToggle } from '../services/billing/useUpgradeModalToggle';

import { MainLayout, withAuth, UserTestVersionsList, DotsButton } from '../components';
import {
  Breadcrumbs,
  Button,
  LoadingIndicator,
  OptionsMenu,
  OptionsMenuItem,
  useToggle,
} from '../../components';
import { RenameProjectModal } from '../components/modals/RenameProjectModal';
import { DeleteConfirmModal } from '../components/modals/DeleteConfirmModal';
import { ResourceCollaboratorsButton } from '../components/buttons/ResourceCollaboratorsButton';

import { NewVersionIcon } from '../assets/icons';
import { dynamicRoutes, routes } from './routing/routes';

type UserTestParams = {
  userTestId: string;
};

export const UserTest = withAuth(() => {
  const router = useRouter();
  const { t } = useTranslationContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();
  const { checkPermission } = usePermissionsContext.useContext();
  const { openUpgradeModal } = useUpgradeModalToggle.useContext();

  const { userTestId } = useRouteParams<UserTestParams>();
  const [isCreateNewVersionLoading, setIsCreateNewVersionLoading] = useState<boolean>(false);

  const {
    data: versions,
    isLoading,
    error: versionsError,
    revalidate: revalidateUserTestVersions,
  } = useUserTestVersions(userTestId);

  const {
    data: userTest,
    error: userTestError,
    revalidate: revalidateUserTest,
  } = useUserTest(userTestId);

  const { isOpen: isMenuOpen, toggle: toggleMenuOpen } = useToggle();
  const {
    isOpen: isRenameProjectModalVisible,
    open: openRenameProjectModal,
    close: closeRenameProjectModal,
  } = useToggle();

  const {
    isOpen: isDeleteConfirmModalVisible,
    open: openDeleteConfirmModal,
    close: closeDeleteConfirmModal,
  } = useToggle();

  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState<boolean>(false);

  const createNewVersion = async () => {
    if (!versions) return;
    setIsCreateNewVersionLoading(true);

    try {
      await createNewUserTestVersion(userTestId, `${t('common.version')} ${versions.length + 1}`);
      setIsCreateNewVersionLoading(false);
      revalidateUserTestVersions();
    } catch (err) {
      setIsCreateNewVersionLoading(false);
      handleError(err);
    }
  };

  const removeUserTest = async () => {
    if (!userTest?.id) return;

    try {
      setDeleteButtonDisabled(true);
      await deleteUserTest(userTest.id);
      closeDeleteConfirmModal();
      const directParent = userTest?.parents[userTest.parents.length - 1];
      const redirectRoute = directParent ? dynamicRoutes.folder(directParent._id) : routes.projects;
      router.replace(redirectRoute);
    } catch (err) {
      setDeleteButtonDisabled(false);
      closeDeleteConfirmModal();
      handleError(err);
    }
  };

  const renameCurrentUserTest = async (name: string) => {
    if (!userTest?.id) return;

    try {
      await updateUserTest(userTest.id, { name });
      revalidateUserTest();
    } catch (err) {
      handleError(err);
    }
  };

  const allowCreateVersion = checkPermission(MyProjectVersionPermission.create, {
    count: versions?.length || 0,
  });

  const breadcrumbs = userTest
    ? [
        { label: t('pages.projects.breadcrumb'), url: routes.projects },
        ...(!userTest.parents
          ? []
          : userTest.parents
              .sort((a, b) => a.index - b.index)
              .map((parent) => ({
                label: parent.name,
                url: dynamicRoutes.folder(parent._id),
              }))),
        { label: t(userTest.name), url: dynamicRoutes.userTest(userTestId) },
      ]
    : [];

  return (
    <MainLayout
      analyticsName='UserTest versions'
      isLoading={isLoading}
      requiresAuthentication
      title={userTest?.name || ''}
      navbarLeftComponent={<Breadcrumbs items={breadcrumbs} />}
      navbarRightComponent={
        <>
          <ResourceCollaboratorsButton
            displayCount={!!userTest?.collaborators?.length || false}
            projectDetails={{
              type: FolderChildType.UserTest,
              id: userTestId,
              collaboratorsCount: userTest?.collaborators?.length,
            }}
            revalidateResource={revalidateUserTest}
          />
          <Button
            className='button__ghost ml-5'
            onClick={allowCreateVersion ? createNewVersion : () => openUpgradeModal()}
            loading={isCreateNewVersionLoading}
            loadingOpacity='50'>
            <NewVersionIcon />
            <span className='px-5'>{t('components.projects.version.create')}</span>
          </Button>
          <div className='flex items-center relative mx-5' onClick={toggleMenuOpen}>
            <DotsButton hasBorder isMenuOpen={isMenuOpen} />
            {isMenuOpen && (
              <OptionsMenu id='project-card-actions' label='Project card actions'>
                <OptionsMenuItem text={t('common.rename')} onClick={openRenameProjectModal} />
                <OptionsMenuItem
                  className='text-danger-60'
                  text={t('common.delete')}
                  onClick={openDeleteConfirmModal}
                />
              </OptionsMenu>
            )}
            {isRenameProjectModalVisible && (
              <RenameProjectModal
                closeModal={closeRenameProjectModal}
                isOpen={isRenameProjectModalVisible}
                onClickRename={renameCurrentUserTest}
                projectType='userTest'
              />
            )}
            {isDeleteConfirmModalVisible && (
              <DeleteConfirmModal
                closeDeleteConfirmModal={closeDeleteConfirmModal}
                isOpen={isDeleteConfirmModalVisible}
                onRemove={removeUserTest}
                projectType='userTest'
                deleteButtonDisabled={deleteButtonDisabled}
              />
            )}
          </div>
        </>
      }
      error={userTestError || versionsError}
      isPlanLimitReached={!allowCreateVersion}
      availableOnMobile={false}>
      {!isCreateNewVersionLoading ? (
        <div className='mt-20 mx-auto w-full'>
          {versions && (
            <UserTestVersionsList
              {...{ versions, userTestId }}
              revalidate={revalidateUserTestVersions}
            />
          )}
        </div>
      ) : (
        <div className='flex h-full w-full items-center justify-center'>
          <LoadingIndicator />
        </div>
      )}
    </MainLayout>
  );
});
