import { useEffect, useMemo, useRef, useState } from 'react';

import { useTranslationContext } from '../../../../services/translation';
import { allClasses } from '../../../../services/utilities/array';
import {
  CollaboratorRole,
  OwnerAsCollaborator,
  OwnershipRole,
  roleDropdownPrefix,
} from '../../../services/api-iteration1/collaborators/api-models';
import { AuthenticatedUser } from '../../../services/current-user/AuthenticatedUser';
import { FolderChildType } from '../../../services/api-iteration1/folders';
import { isValidEmail } from '../../../services/validation';

import { Button, Form, FormError, FormField, Modal } from '../../../../components';

import { AlertIcon, CloseIcon } from '../../../assets/icons';
import { CollaboratorCard } from '../../cards/CollaboratorCard';
import { CollaboratorsSearchEmptyState } from '../../empty-states/CollaboratorsSearchEmptyState';
import { CollaboratorsUpgradePlan } from '../../views/collaborators/CollaboratorsUpgradePlan';
import { useCollaboratorsModal } from './useCollaboratorsModal';
import { CollaboratorRolesDropdownTrigger } from '../../cards/CollaboratorCard/CollaboratorRolesDropdownTrigger';
import { CollaboratorRolesDropdown } from '../../cards/CollaboratorCard/CollaboratorRolesDropdown';

interface CollaboratorsModalProps {
  isOpen: boolean;
  closeModal: () => void;
  revalidateResource: () => void;
  openUpgradeModal: () => void;
  projectDetails: {
    type: FolderChildType;
    id: string;
  };
  currentUser: AuthenticatedUser | undefined;
}

export const CollaboratorsModal = ({
  currentUser,
  isOpen,
  projectDetails,
  revalidateResource,
  closeModal,
  openUpgradeModal,
}: CollaboratorsModalProps) => {
  const { t } = useTranslationContext.useContext();

  const resourceOwner: OwnerAsCollaborator = useMemo(
    () => ({
      id: currentUser?.id || '',
      role: OwnershipRole.Owner,
      _id: currentUser?.id || '',
      email: currentUser?.email || '',
      firstName: currentUser?.firstName || '',
      lastName: currentUser?.lastName || '',
      avatar: currentUser?.image || null,
      resources: [],
      isPending: false,
    }),
    [currentUser]
  );

  const {
    sortedCollaborators,
    showSearchEmptyState,
    showUpgradePlan,
    showOwner,
    allowAddNewCollaborators,
    addNewCollaborator,
    removeResourceCollaborator,
    resendInvitation,
    cancelInvitation,
    updateCollaboratorRole,
    handleKeyDown,
    setSearchTerm,
  } = useCollaboratorsModal({
    resourceOwner,
    projectDetails,
    revalidateResource,
  });

  const [currentDropdownIndex, setCurrentDropdownIndex] = useState<number | undefined>(undefined);

  const initialValues = useMemo(
    () => ({
      searched_collaborator_email: '',
      searched_collaborator_role: CollaboratorRole.Editor,
    }),
    []
  );

  const searchInputRef = useRef(null);

  const showCollaboratorsList = !showSearchEmptyState && !showUpgradePlan;

  const [rolesDropdown, setRolesDropdown] = useState<
    { x: number; y: number; height: number; triggerer: HTMLElement; index: number } | undefined
  >(undefined);

  const isDropdownOpen = rolesDropdown && currentDropdownIndex === rolesDropdown.index;

  const closeRolesDropdown = () => {
    setRolesDropdown(undefined);
  };

  useEffect(() => {
    window.addEventListener('scroll', closeRolesDropdown, true);
    return () => {
      window.removeEventListener('scroll', closeRolesDropdown, true);
    };
  }, []);

  return (
    <Modal
      title={t('components.projects.folder.modal-title')}
      isOpen={isOpen}
      onClose={closeModal}
      variant='regular'
      closeModalComponent={<CloseIcon className='icon-hover-main-contrast' />}
      closeIconClassName='absolute top-5 right-5'
      className='relative'
      refInitialFocus={searchInputRef}>
      <div
        className={allClasses(
          'pt-10 text-center',
          allowAddNewCollaborators && showCollaboratorsList && 'mb-32'
        )}>
        <h2 className='text-2xl leading-30 text-main-contrast font-bold my-10'>
          {t('components.collaborators.modal.title')}
        </h2>
        <Form
          className='flex flex-col relative items-center max-w-[28rem] mx-auto'
          initialValues={initialValues}
          enableReinitialize>
          {({ status, values, errors, touched, setFieldError, setValues }) => {
            return (
              <>
                <FormError {...{ status }} />
                <div
                  className={allClasses(
                    'flex flex-row items-center justify-between py-0 pl-5 pr-1 w-full border-2 border-solid border-main-10 rounded-lg active:border-main-highlight focus-within:border-main-highlight visited:border-main-highlight hover:border-main-highlight',
                    errors.searched_collaborator_email &&
                      touched.searched_collaborator_email &&
                      'border-danger mb-12'
                  )}>
                  <FormField
                    autoFocus
                    type='text'
                    name='searched_collaborator_email'
                    placeholder={t('components.collaborators.modal.input-placeholder')}
                    fieldClassName='w-3/4 h-12'
                    inputClassName='bg-main p-0 m-0 h-10 border-main-10 focus:border-main-highlight focus:outline-none'
                    errorClassName='w-auto mt-2'
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyUp={handleKeyDown}
                    innerRef={searchInputRef}
                  />
                  {isValidEmail(values.searched_collaborator_email) && !showUpgradePlan && (
                    <>
                      <CollaboratorRolesDropdownTrigger
                        {...{
                          setRolesDropdown,
                          rolesDropdown,
                          setCurrentDropdownIndex,
                          currentDropdownIndex,
                          isDropdownOpen,
                        }}
                        dropdownIndex={0}
                        collaboratorRole={'editor' as CollaboratorRole}
                      />
                      {isDropdownOpen && (
                        <CollaboratorRolesDropdown
                          isPending={false}
                          key={`${roleDropdownPrefix}_collaborator`}
                          close={closeRolesDropdown}
                          options={[
                            {
                              label: t('components.collaborators.roles-dropdown.admin'),
                              value: CollaboratorRole.Admin,
                              subLabel: t('components.collaborators.roles-dropdown.admin-label'),
                              onSelectNewOption: () => {
                                addNewCollaborator(
                                  values.searched_collaborator_email,
                                  CollaboratorRole.Admin
                                );
                                setValues(
                                  {
                                    searched_collaborator_email: '',
                                    searched_collaborator_role: CollaboratorRole.Admin,
                                  },
                                  false
                                );
                              },
                            },
                            {
                              label: t('components.collaborators.roles-dropdown.editor'),
                              value: CollaboratorRole.Editor,
                              subLabel: t('components.collaborators.roles-dropdown.editor-label'),
                              onSelectNewOption: () => {
                                addNewCollaborator(
                                  values.searched_collaborator_email,
                                  CollaboratorRole.Editor
                                );
                                setValues(
                                  {
                                    searched_collaborator_email: '',
                                    searched_collaborator_role: CollaboratorRole.Editor,
                                  },
                                  false
                                );
                              },
                              isSelected: true,
                            },
                            {
                              label: t('components.collaborators.roles-dropdown.viewer'),
                              value: CollaboratorRole.Viewer,
                              subLabel: t('components.collaborators.roles-dropdown.viewer-label'),
                              onSelectNewOption: () => {
                                addNewCollaborator(
                                  values.searched_collaborator_email,
                                  CollaboratorRole.Viewer
                                );
                                setValues(
                                  {
                                    searched_collaborator_email: '',
                                    searched_collaborator_role: CollaboratorRole.Viewer,
                                  },
                                  false
                                );
                              },
                            },
                          ]}
                          {...{ rolesDropdown }}
                        />
                      )}
                    </>
                  )}
                </div>
                {showCollaboratorsList ? (
                  <>
                    <div className='w-full h-12 absolute top-[3.25rem] z-10 bg-gradient-to-b from-main to-transparent' />
                    <div className='w-full h-80 pt-10 scroll-container overflow-y-auto relative'>
                      {showOwner && (
                        <CollaboratorCard
                          isOwner
                          index={0}
                          isLast={sortedCollaborators.length === 0}
                          collaborator={resourceOwner}
                        />
                      )}
                      {sortedCollaborators.map((collaborator, index) => {
                        const isAssignedToProject = collaborator.resources.some(
                          (resource) =>
                            resource.id === projectDetails.id &&
                            resource.type === projectDetails.type
                        );

                        const currentResource = collaborator.resources.find(
                          (resource) =>
                            resource.id === projectDetails.id &&
                            resource.type === projectDetails.type
                        );
                        const collaboratorRole = currentResource?.role;

                        return (
                          <CollaboratorCard
                            key={`${collaborator.email}_${index}`}
                            isLast={index === sortedCollaborators.length - 1}
                            removeCollaborator={() => removeResourceCollaborator(collaborator)}
                            cancelInvitation={() => cancelInvitation(collaborator)}
                            addCollaborator={addNewCollaborator}
                            resendInvitation={() => resendInvitation(collaborator)}
                            {...{
                              index,
                              collaborator,
                              isAssignedToProject,
                              updateCollaboratorRole,
                              collaboratorRole,
                              allowAddNewCollaborators,
                              currentDropdownIndex,
                              setCurrentDropdownIndex,
                            }}
                          />
                        );
                      })}
                    </div>
                    <div className='w-full h-12 absolute top-[20.5rem] bg-gradient-to-b from-transparent to-main' />
                  </>
                ) : !showUpgradePlan ? (
                  <CollaboratorsSearchEmptyState
                    title={t('components.collaborators.empty-state.title')}
                    description={t('components.collaborators.empty-state.description')}
                    buttonText={t('components.collaborators.empty-state.buttons.submit')}
                    onClick={() => {
                      if (isValidEmail(values.searched_collaborator_email)) {
                        addNewCollaborator(
                          values.searched_collaborator_email,
                          values.searched_collaborator_role
                        );
                        setValues(
                          {
                            searched_collaborator_email: '',
                            searched_collaborator_role: CollaboratorRole.Editor,
                          },
                          false
                        );
                        setRolesDropdown(undefined);
                      } else {
                        setFieldError('searched_collaborator_email', 'errors.validation.email');
                      }
                    }}
                  />
                ) : (
                  <CollaboratorsUpgradePlan
                    title={t('components.collaborators.upgrade-plan.title')}
                    description={t('components.collaborators.upgrade-plan.description')}
                    buttonText={t('components.collaborators.upgrade-plan.submit')}
                    onClick={openUpgradeModal}
                    learnMoreUrl=''
                  />
                )}

                {!allowAddNewCollaborators && !showUpgradePlan && (
                  <div className='mb-20 flex flex-col items-center justify-center'>
                    <div className='flex flex-row mt-5 mb-10 p-5 border-2 border-solid border-billing rounded-lg bg-billing-60'>
                      <AlertIcon />
                      <div className='flex flex-col items-start ml-5 text-base leading-5'>
                        <p className='mb-2 text-main-contrast font-bold'>
                          {t('components.collaborators.upgrade-plan.banner.title')}
                        </p>
                        <p className='text-billing'>
                          {t('components.collaborators.upgrade-plan.banner.description')}
                        </p>
                      </div>
                    </div>
                    <Button
                      className='button__filled px-10'
                      type='button'
                      onClick={openUpgradeModal}>
                      <span className='text-main-contrast leading-5 text-base font-medium'>
                        {t('components.collaborators.upgrade-plan.submit')}
                      </span>
                    </Button>
                  </div>
                )}
              </>
            );
          }}
        </Form>
      </div>
    </Modal>
  );
};
