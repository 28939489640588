import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useParams,
  useLocation as useLocationService,
  useNavigate,
  NavigateOptions,
  useSearchParams,
} from 'react-router-dom';

import { UrlParams } from '../api/utils';
import { isUndefined } from '../utilities/value';

export type SetQueryParams = (
  nextInit?: UrlParams | ((prev: UrlParams) => UrlParams),
  navigateOpts?: NavigateOptions
) => void;

export const useRouter = () => {
  const navigate = useNavigate();

  const replace = useCallback(
    (to: string, options?: NavigateOptions) => navigate(to, { ...options, replace: true }),
    [navigate]
  );

  const goBack = useCallback(
    (jumpLength = 1, options?: NavigateOptions) =>
      navigate(-jumpLength as any, { ...options, replace: true }),
    [navigate]
  );

  return {
    push: navigate,
    replace,
    goBack,
  };
};

export const useLocation = useLocationService;

export const useRouteParams = <Params extends Record<string, string>>() =>
  useParams<Params>() as Params;

const parseQueryParams = <T extends UrlParams = UrlParams>(search: URLSearchParams): Partial<T> => {
  const queryParams: any = {};
  const parsedSearch = new URLSearchParams(search);

  for (const key of parsedSearch.keys()) {
    queryParams[key] = parsedSearch.get(key);
  }

  return queryParams;
};

export const useQueryParams = <T extends UrlParams = UrlParams>(): {
  params: Partial<T>;
  setQueryParams: SetQueryParams;
} => {
  const [search, setSearch] = useSearchParams();

  const params = useMemo(() => parseQueryParams<T>(search), [search]);

  const setQueryParams: SetQueryParams = useCallback(
    (nextInit = {}) => {
      setSearch((prev) => {
        const parsedPrev = parseQueryParams(prev);
        const newResult = typeof nextInit === 'function' ? nextInit(parsedPrev) : nextInit;

        const filteredResults = Object.entries(newResult).reduce((result, [key, value]) => {
          return isUndefined(value) ? result : { ...result, [key]: value };
        }, {});

        return filteredResults;
      });
    },
    [setSearch]
  );

  return {
    params,
    setQueryParams,
  };
};

export const useQueryParamsOnce = <T extends UrlParams = UrlParams>(): {
  params: Partial<T>;
  setQueryParams: SetQueryParams;
  clearSavedParams: () => void;
} => {
  const [search, setSearch] = useSearchParams();

  const [lastQueryParams, setLastQueryParams] = useState<Partial<T>>({});

  const clearSavedParams = useCallback(() => {
    setLastQueryParams({});
  }, []);

  const setQueryParams: SetQueryParams = useCallback(
    (nextInit = {}) => {
      setSearch((prev) => {
        const parsedPrev = parseQueryParams(prev);

        const newResult = typeof nextInit === 'function' ? nextInit(parsedPrev) : nextInit;

        const filteredResults = Object.entries(newResult).reduce((result, [key, value]) => {
          return isUndefined(value) ? result : { ...result, [key]: value };
        }, {});

        return filteredResults;
      });
    },
    [setSearch]
  );

  useEffect(() => {
    const queryParams = parseQueryParams<T>(search);
    const hasQueryParams = !!Object.keys(queryParams).length;

    if (hasQueryParams) {
      setLastQueryParams(queryParams);
      setSearch({});
    }
  }, [search, setSearch]);

  return { params: lastQueryParams, setQueryParams, clearSavedParams };
};
