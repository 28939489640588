import { useRef } from 'react';

import { useTranslationContext } from '../../../../services/translation';
import { ApiResponse, http } from '../../../../services/api';

import { VideoPlayer } from '../../../../components/video-player';
import { Icon, Modal } from '../../../../components';

import { CloseIcon, DownloadIcon } from '../../../assets/icons';

import styles from './index.module.css';

interface SingleGoalVideoModalProps {
  videoUrl: string;
  isOpen: boolean;
  closeModal: () => void;
  testerIndex: number;
}

export const SingleGoalVideoModal = ({
  isOpen,
  videoUrl,
  closeModal,
  testerIndex,
}: SingleGoalVideoModalProps) => {
  const { t } = useTranslationContext.useContext();
  const videoRef = useRef(null);

  const downloadFile = () => {
    http.get(videoUrl, { responseType: 'arraybuffer' }).then((res: ApiResponse<any>) => {
      const blob = new Blob([res.data], { type: 'video/webm' });

      const aElement = document.createElement('a');
      aElement.setAttribute('download', 'single-goal-video.webm');
      const href = URL.createObjectURL(blob);
      aElement.href = href;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(href);
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      variant='full-page'
      className='max-h-screen'
      closeModalComponent={<CloseIcon className='icon-hover-main-contrast' />}
      closeIconClassName='absolute top-5 right-5'
      headerTitleComponent={
        <>
          <div className='absolute top-5 left-5 flex w-full p-2.5 rounded items-center cursor-default'>
            <span className='no-underline font-bold block truncate max-w-full text-neutral text-xl leading-5'>
              {t('common.tester')} #{testerIndex}
            </span>
            <span className='font-bold text-neutral text-xl leading-5 px-5'>/</span>
            <span className='no-underline font-bold block truncate max-w-full text-main-contrast text-xl leading-5'>
              {t('components.projects.analytics.responses.single-goal.session-recording')}
            </span>
          </div>

          <Icon
            Svg={DownloadIcon}
            className='absolute right-20 top-[35%] icon-fill-neutral-40 icon__small'
            onClick={downloadFile}
          />
        </>
      }>
      <div className='relative flex flex-1 flex-row justify-center h-full'>
        <VideoPlayer ref={videoRef} autoPlay url={videoUrl} className={styles.video_container} />
      </div>
    </Modal>
  );
};
