import { useTranslationContext } from '../../../../../services/translation';
import { DefaultSectionThankYouOutput } from '../../../../services/api-iteration1';
import { StorageFileType } from '../../../../services/api-cloud-storage/api-models';

import { Button } from '../../../../../components';

import { SectionImage } from './SectionImage';
import { ThankYouIllustration } from '../../../../assets/icons';

import styles from './ViewSection.module.css';
interface ViewThankYouProps {
  thankYouSection: DefaultSectionThankYouOutput;
  disableInteraction?: boolean;
  isEmbeddedIframe?: boolean;
}

export const ViewThankYou = ({
  thankYouSection,
  disableInteraction,
  isEmbeddedIframe,
}: ViewThankYouProps) => {
  const { t } = useTranslationContext.useContext();

  const { title, files, others } = thankYouSection;
  const { message, url } = others;

  const sectionImage = files.find((f) => f.type === StorageFileType.SectionImage);

  return (
    <div
      className={
        isEmbeddedIframe
          ? styles.view_section_wrapper_with_top_margin_embedded
          : styles.view_section_wrapper_with_top_margin
      }>
      {sectionImage ? (
        <SectionImage image={sectionImage} />
      ) : (
        <ThankYouIllustration className='mb-8 max-w-full' />
      )}
      <div className='flex flex-col items-center'>
        <h6 className='text-xl text-main-contrast font-bold leading-30 mb-2.5 mt-10 break-all'>
          {title}
        </h6>
        <p className='text-lg text-main-contrast font-normal leading-normal mb-10 max-w-md text-center'>
          {message}
        </p>
      </div>
      {url && (
        <Button
          className='button__filled my-1'
          onClick={() => {
            !disableInteraction && window.open(url, '_blank');
          }}>
          <span className='px-5'>{t('common.finish')}</span>
        </Button>
      )}
    </div>
  );
};
