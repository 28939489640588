import { useQueryParams, useRouteParams } from '../../services/router';
import { useLivePrototypeNavigation } from '../services/design-platforms';

import { ViewUserTestFlow } from '../components';

type PageParams = {
  userTestId: string;
  versionId: string;
};

export const ViewUserTest = () => {
  const { userTestId, versionId } = useRouteParams<PageParams>();
  const {
    params: { embedded },
  } = useQueryParams<{ embedded: boolean }>();

  return (
    <useLivePrototypeNavigation.ProvideContext>
      <ViewUserTestFlow {...{ userTestId, versionId }} isEmbeddedIframe={embedded} />
    </useLivePrototypeNavigation.ProvideContext>
  );
};
