import { useTranslationContext } from '../../../services/translation';
import { Collaborator } from '../../services/api-iteration1/collaborators/api-models';
import { useCommonResourcesWithCollaborator } from '../../services/api-iteration1/collaborators';

import { LoadingOrContent, Modal } from '../../../components';

import { CloseIcon } from '../../assets/icons';
import { ProjectsList } from '..';

interface CollaboratorProjectsProps {
  isOpen: boolean;
  close: () => void;
  collaborator: Collaborator;
}

export const CollaboratorProjectsModal = ({
  isOpen,
  close,
  collaborator,
}: CollaboratorProjectsProps) => {
  const { t } = useTranslationContext.useContext();

  const {
    data: resources,
    isLoading,
    revalidate,
  } = useCommonResourcesWithCollaborator(collaborator.email);

  return (
    <Modal
      title={t('pages.account.tabs.profile.delete-account-modal.title')}
      isOpen={isOpen}
      onClose={close}
      variant='full-page'
      closeModalComponent={<CloseIcon className='icon-hover-main-contrast' />}
      closeIconClassName='absolute top-5 right-5'
      headerTitleComponent={
        <div className='absolute top-5 left-20 flex w-full py-2.5 rounded items-center cursor-default'>
          <span
            className='no-underline font-bold block truncate max-w-full text-neutral text-xl leading-5 cursor-pointer hover:text-main-contrast'
            onClick={close}>
            {t('common.collaborators')}
          </span>
          <span className='font-bold text-neutral text-xl leading-5 px-5'>/</span>
          <span className='no-underline font-bold block truncate max-w-full text-main-contrast text-xl leading-5'>
            {collaborator.isPending
              ? `${collaborator.email}`
              : `${collaborator.firstName} ${collaborator.lastName}`}
          </span>
        </div>
      }
      headerClassName='container-with-margin items-center'>
      <LoadingOrContent {...{ isLoading }}>
        <div className='pt-10 pb-20 text-center'>
          {!!resources?.length && (
            <div className='flex flex-col flex-1 overflow-auto scroll-container container-with-margin text-left'>
              <ProjectsList items={resources} {...{ revalidate }} />
            </div>
          )}
        </div>
      </LoadingOrContent>
    </Modal>
  );
};
