import { useMemo } from 'react';

import { useTranslationContext } from '../../../../services/translation';
import { useCurrentUserContext } from '../../../services/current-user';
import {
  Collaborator,
  CollaboratorRole,
} from '../../../services/api-iteration1/collaborators/api-models';
import { allClasses } from '../../../../services/utilities/array';
import { useSearch } from '../../../services/filters/useSearch';
import { useUserCollaboratorsContext } from '../../../services/collaborators';
import { useRouter } from '../../../../services/router';

import { Form, FormError, FormField, LoadingOrContent } from '../../../../components';

import { CollaboratorCardInSettings } from '../../cards/CollaboratorCard/CollaboratorCardInSettings';
import { CollaboratorsSearchEmptyState } from '../../empty-states/CollaboratorsSearchEmptyState';
import { routes } from '../../../pages/routing/routes';
import { AnalyticsScreenName } from '../../layout/AnalyticsScreenName';

interface CollaboratorsSettingsProps {
  icon: React.ReactNode;
  title: string;
}

export const CollaboratorsSettings = ({ icon, title }: CollaboratorsSettingsProps) => {
  const { t } = useTranslationContext.useContext();
  const { currentUser, isLoadingUser } = useCurrentUserContext.useContext();
  const router = useRouter();

  const { currentUserCollaborators, revalidate } = useUserCollaboratorsContext.useContext();

  const { filteredResults: searchResults, setSearchTerm } = useSearch({
    fieldNames: ['firstName', 'lastName', 'email'],
    items: currentUserCollaborators || [],
  });

  const sortedCollaborators = useMemo(() => {
    if (currentUserCollaborators) {
      const currentCollaboratorsList = (
        searchResults.length > 0 ? searchResults : currentUserCollaborators
      ) as Collaborator[];

      const availableCollaborators: Collaborator[] = currentCollaboratorsList
        .filter((collaborator) => !collaborator.isPending)
        .sort((a: Collaborator, b: Collaborator) =>
          a.firstName && b.firstName ? a.firstName.localeCompare(b.firstName) : 1
        );

      const pendingCollaborators: Collaborator[] = currentCollaboratorsList
        .filter((item) => item.isPending)
        .sort((a: Collaborator, b: Collaborator) => a.email.localeCompare(b.email));

      return [...availableCollaborators, ...pendingCollaborators];
    }

    return [];
  }, [currentUserCollaborators, searchResults]);

  const initialValues = useMemo(
    () => ({
      searched_collaborator_email: '',
      searched_collaborator_role: CollaboratorRole.Editor,
    }),
    []
  );

  return (
    <>
      <AnalyticsScreenName name='Profile - Collaborators' />

      <LoadingOrContent isLoading={isLoadingUser}>
        {currentUser && (
          <div className='w-full flex flex-col px-5 border-2 border-main-10 border-solid rounded-lg'>
            <div className='flex flex-row py-5 items-center justify-between border-b-2 border-main-10 border-solid'>
              <span className='flex flex-row items-center'>
                {icon}
                <span className='pl-5 text-base leading-5 text-main-contrast font-bold'>
                  {title}
                </span>
              </span>
            </div>
            {!!sortedCollaborators.length ? (
              <div className='px-10 py-20'>
                <div className='flex flex-row items-center justify-start'>
                  <Form className='w-[28rem]' initialValues={initialValues}>
                    {({ status, errors, touched }) => {
                      return (
                        <>
                          <FormError {...{ status }} />
                          <div
                            className={allClasses(
                              'flex flex-row items-center justify-between py-0 pl-5 w-full border-2 border-solid border-main-10 rounded-lg active:border-main-highlight focus-within:border-main-highlight visited:border-main-highlight hover:border-main-highlight',
                              errors.searched_collaborator_email &&
                                touched.searched_collaborator_email &&
                                'border-danger mb-12'
                            )}>
                            <FormField
                              autoFocus
                              type='text'
                              name='searched_collaborator_email'
                              placeholder={t('components.collaborators.modal.input-placeholder')}
                              fieldClassName='w-3/4 h-12'
                              inputClassName='bg-main p-0 m-0 h-10 border-main-10 focus:border-main-highlight focus:outline-none'
                              errorClassName='absolute bottom-5 w-auto'
                              onChange={(e) => {
                                setSearchTerm(e.target.value);
                              }}
                            />
                          </div>
                        </>
                      );
                    }}
                  </Form>
                  <p className='flex flex-1 text-sm leading-[18px] text-main-contrast font-bold justify-center'>
                    {t('pages.account.tabs.collaborators.projects-in-common')}
                  </p>
                </div>
                <div className='flex flex-row items-center justify-start relative mt-10'>
                  <div className='w-full h-12 absolute top-0 z-10 bg-gradient-to-b from-main to-transparent' />
                  <div className='w-full h-76 pt-5 scroll-container overflow-y-auto relative'>
                    {sortedCollaborators.map((collaborator, index) => {
                      return (
                        <CollaboratorCardInSettings
                          key={`${collaborator.email}_${index}`}
                          isLast={index === sortedCollaborators.length - 1}
                          {...{
                            collaborator,
                            revalidate,
                          }}
                        />
                      );
                    })}
                  </div>
                  <div className='w-full h-12 absolute top-[20rem] bg-gradient-to-b from-transparent to-main' />
                </div>
              </div>
            ) : (
              <CollaboratorsSearchEmptyState
                title={t('components.collaborators.empty-state.title')}
                description={t('components.collaborators.empty-state.description')}
                buttonText={t('components.collaborators.empty-state.buttons.projects')}
                onClick={() => router.push(routes.home)}
              />
            )}
          </div>
        )}
      </LoadingOrContent>
    </>
  );
};
