import { FunctionComponent, SVGProps } from 'react';

import { useTranslationContext } from '../../../services/translation';
import { allClasses } from '../../../services/utilities/array';

import { ClicksIcon, ExitIcon, FirstClickScreenIcon } from '../../assets/icons';
import { ScreensFiltersTypes } from '../config';
import { ScreenAnalyticsCardItem } from './ScreenAnalyticsCardItem';

import styles from './ScreenAnalyticsCard.module.css';

type FrameOptionItem = {
  id: string;
  iconSelectedClass: string;
  selected: boolean;
  onClick: () => void;
  textKey: string;
  containerClassName: string;
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
};

type FrameItem = {
  icon: 'users' | 'sections' | 'time' | 'checks' | 'first-click' | 'missclick';
  title: string;
  value: string | number;
  color: string;
};

type FrameFilter = 'all-clicks' | 'first-click';

interface ScreenAnalyticsCardProps {
  title: string;
  screenName?: string;
  rating: string;
  ratingColor: string;
  image: string;
  data: FrameItem[];
  isExpanded: boolean;
  isLast: boolean;
  onClick: () => void;
  inFocusFilterId?: string;
  setFilterInFocus: (filter: 'all-clicks' | 'first-click' | undefined) => void;
  selectedFilter: ScreensFiltersTypes;
  className?: string;
  isIndividualResponse?: boolean;
}

export const ScreenAnalyticsCard = ({
  title,
  screenName,
  rating,
  image,
  data,
  isExpanded,
  onClick,
  ratingColor,
  isLast,
  inFocusFilterId,
  selectedFilter,
  className,
  isIndividualResponse,
  setFilterInFocus,
}: ScreenAnalyticsCardProps) => {
  const { t } = useTranslationContext.useContext();

  const selectOption = (option: FrameFilter) => {
    setFilterInFocus(inFocusFilterId !== option ? option : undefined);
  };

  const frameOptions: FrameOptionItem[] = [
    {
      id: 'all-clicks',
      iconSelectedClass: 'icon-main-contrast',
      selected: inFocusFilterId === 'all-clicks',
      onClick: () => selectOption('all-clicks'),
      textKey: 'components.projects.usability-testing.results.single-goal.screens.options.clicks',
      containerClassName: styles.container,
      Icon: ClicksIcon,
    },
    {
      id: 'first-click',
      iconSelectedClass: 'icon-stroke-circle-main-contrast icon-stroke-polyline-main-contrast',
      selected: inFocusFilterId === 'first-click',
      onClick: () => selectOption('first-click'),
      textKey:
        'components.projects.usability-testing.results.single-goal.screens.options.first-click',
      containerClassName: styles.container,
      Icon: FirstClickScreenIcon,
    },
  ];

  const dataForResponsesTab = data.filter((item) => item.icon === 'time' || item.icon === 'checks');

  return (
    <div
      className={allClasses(
        'cursor-pointer w-full bg-main mb-5 border-2 border-solid border-main-10 rounded-lg hover:border-main-highlight',
        isExpanded && 'border-main-highlight min-w-22',
        !isLast && isExpanded && 'pb-5',
        className
      )}>
      <div className='flex flex-row relative py-5 px-[1.125rem]' onClick={onClick}>
        <span>
          <span className='w-6 h-full'>
            <img src={image} className='border-2 border-main-10 border-solid h-12 min-w-[24px]' />
          </span>
        </span>
        <div className='ml-5'>
          <p
            className={allClasses(
              'text-base text-main-contrast leading-5 font-bold pr-4 overflow-hidden w-60 text-ellipsis break-all whitespace-nowrap',
              screenName && 'mb-2'
            )}>
            {title}
          </p>
          <p className='text-base text-neutral-70 leading-5 m-0'>{screenName}</p>
          <div
            className={allClasses(
              'absolute top-5 right-5 w-12 h-12 mr-0 rounded-full text-center align-middle m-0',
              isLast && selectedFilter === 'all' ? 'bg-main-80' : ratingColor
            )}>
            <div className='w-full absolute top-1/2 left-0 transform -translate-y-1/2 flex justify-center items-center'>
              {isLast && selectedFilter === 'all' ? (
                <ExitIcon className='m-0' />
              ) : (
                <p
                  className={allClasses(
                    'text-base leading-[48px] font-bold',
                    ratingColor === 'bg-danger' ? 'text-main' : 'text-main-contrast'
                  )}>
                  {rating}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      {isExpanded && !isLast ? (
        <>
          <div className='w-11/12 mx-auto'>
            <hr className='mb-5 border-default border-main-10 border-solid rounded-lg' />
            <div className='grid grid-cols-2 h-auto'>
              {isIndividualResponse
                ? dataForResponsesTab.map((item: FrameItem, index: number) => (
                    <ScreenAnalyticsCardItem
                      key={index}
                      analyticsLength={data.length}
                      {...{ item, index, isIndividualResponse }}
                    />
                  ))
                : data.map((item: FrameItem, index: number) => (
                    <ScreenAnalyticsCardItem
                      key={index}
                      analyticsLength={data.length}
                      isIndividualResponse={false}
                      {...{ item, index }}
                    />
                  ))}
            </div>
            <div className='mt-5'>
              <p className='mb-2'>
                {t(
                  'components.projects.usability-testing.results.single-goal.screens.options.title'
                )}
              </p>
              <div className='flex flex-row items-start justify-between'>
                {frameOptions.map(
                  (
                    { id, onClick, Icon, textKey, selected, iconSelectedClass, containerClassName },
                    index
                  ) => (
                    <div
                      key={id}
                      className={allClasses(
                        containerClassName,
                        index === 0 && 'mr-5',
                        selected
                          ? 'border-main-highlight text-main-contrast font-medium'
                          : 'border-main-10 text-neutral hover:border-main-highlight hover:text-main-contrast'
                      )}
                      onClick={onClick}>
                      <Icon className={allClasses('mr-2.5', selected && iconSelectedClass)} />
                      <span>{t(textKey)}</span>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};
