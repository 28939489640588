import { FolderChildType } from '../folders';

export const endpoints = {
  v1: {
    currentUserCollaborators: '/v1/users/current/collaborators',
    resource: (resourceType: FolderChildType, resourceId: string) =>
      `/v1/resources/${resourceType}/${resourceId}/collaborators`,
    removeCollaborator: (resourceType: FolderChildType, resourceId: string, userId: string) =>
      `/v1/resources/${resourceType}/${resourceId}/collaborators/${userId}`,
    removePendingCollaborator: (resourceType: FolderChildType, resourceId: string) =>
      `/v1/resources/${resourceType}/${resourceId}/collaborators`,
    removeCollaboratorFromAllProjects: (userEmail: string) =>
      `v1/users/current/collaborators/${userEmail}`,
    collaborator_resources: (email: string) => `/v1/resources/collaborators/${email}`,
  },
};
