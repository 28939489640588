import { PermissionPlans } from '@iteration1/permission-validators';

import { useTranslationContext } from '../../services/translation';
import { useQueryParams, useRouteParams, useRouter } from '../../services/router';
import { useErrorHandlerContext } from '../../services/error-handling';
import { FolderChildType, publishDiscoveryVersion } from '../services/api-iteration1';
import { sendAnalyticsEvent } from '../../services/app-analytics';
import { analyticsEvents } from '../services/app-analytics/events';
import { useCurrentUserContext } from '../services/current-user';

import { NoNavbarLayout, withAuth } from '../components';
import { Button } from '../../components';

import { dynamicRoutes } from './routing/routes';
import { PlayIcon, StartTestIllustration } from '../assets/icons';

type StartDiscoveryTestingParams = {
  discoveryId: string;
  versionId: string;
};

export const StartDiscoveryTesting = withAuth(() => {
  const router = useRouter();
  const {
    params: { sectionCount },
  } = useQueryParams<{ sectionCount: number }>();
  const { discoveryId, versionId } = useRouteParams<StartDiscoveryTestingParams>();
  const { t } = useTranslationContext.useContext();
  const { currentUser } = useCurrentUserContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();

  const publishDiscovery = async () => {
    if (!versionId || !currentUser) return;

    try {
      await publishDiscoveryVersion(discoveryId, versionId);
      sendAnalyticsEvent(
        analyticsEvents.publishProject({
          user_id: currentUser.id,
          plan_name: currentUser.subscription?.plan.name || PermissionPlans.IndividualFree,
          billing_type: currentUser.subscription?.price.recurring?.interval,
          project_type: FolderChildType.Discovery,
          project_section_count: sectionCount,
        })
      );
      window.location.replace(dynamicRoutes.discoveryResponses(discoveryId));
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <NoNavbarLayout
      analyticsName='Start testing - Discovery'
      title={t('pages.start-discovery-testing.title', { versionId })}
      availableOnMobile>
      <div className='flex w-full h-full flex-col justify-center items-center text-center'>
        <div className='flex justify-center items-center w-full max-w-2xl mb-10'>
          <StartTestIllustration />
        </div>
        <p className='h5 text-main-contrast mb-3'>{t('pages.start-discovery-testing.almost')}</p>
        <p className='body-l text-main-contrast'>{t('pages.start-discovery-testing.warning')}</p>

        <div className='flex my-10'>
          <Button className='button__ghost px-10 mr-5' onClick={() => router.goBack()}>
            <span className='px-5'>{t('common.go-back')}</span>
          </Button>
          <Button className='button__filled' onClick={publishDiscovery}>
            <PlayIcon />
            <span className='px-5'>{t('components.projects.sections.start-testing')}</span>
          </Button>
        </div>
      </div>
    </NoNavbarLayout>
  );
});
