import { allClasses } from '../../../../../services/utilities/array';
import { VersionStatus } from '../../../../services/api-iteration1';

interface VersionCardHeaderProps {
  icon: JSX.Element;
  title: string;
  status: VersionStatus;
}

export const VersionCardHeader = ({ icon, title, status }: VersionCardHeaderProps) => {
  return (
    <div className='flex justify-items-start'>
      {icon}
      <div
        className={allClasses(
          'pl-2.5 leading-5 text-base font-normal',
          status === VersionStatus.Live && 'text-danger',
          status === VersionStatus.Done && 'text-neutral'
        )}>
        {title}
      </div>
    </div>
  );
};
