import { useMemo } from 'react';

import { useErrorHandlerContext } from '../../../../../services/error-handling';
import { useActionInProgressContext } from '../../../../../services/progress';
import { useTranslationContext } from '../../../../../services/translation';
import {
  DiscoverySectionInput,
  DiscoverySectionPreferenceOutput,
  DiscoverySectionType,
  PreferenceSectionText,
  SectionFileRemovalDetails,
  SectionFileUploadDetails,
  SectionStorageFile,
  SectionStorageMultipleFile,
} from '../../../../services/api-iteration1';
import { useSectionsListContext } from '../../../../services/sections/useSectionsList';
import { allClasses } from '../../../../../services/utilities/array';
import { isRequired } from '../../../../services/validation';
import { StorageFileType } from '../../../../services/api-cloud-storage/api-models';
import {
  getStoragePublicFileUrl,
  imageFileSizeLimit,
} from '../../../../services/api-cloud-storage';
import { useDynamicInputs } from '../../../../services/interaction';
import {
  isTemporaryFile,
  isTemporarySection,
} from '../../../../services/api-iteration1/projects/api-models/sections/utils';

import { Form, FormError, FormField, ToggleField, UploadImage } from '../../../../../components';
import { RadioGroup } from '../../../../../components/radio-group/RadioGroup';
import { Collapsible } from '../../../../../components/collapsible/Collapsible';

import { PlaceholderSmallIcon, PreferenceTestIcon, TextIcon } from '../../../../assets/icons';
import { SelectSectionItem } from '../../..';
import { getSectionPreferenceTestFiles } from '../utils';

import styles from './Section.module.css';

const dynamicContextInputPrefix = 'context_';
const defaultValueTextFields = { context: '', indicator: '' };
const initialDefaultValueTextFields = [
  { context: '', indicator: '' },
  { context: '', indicator: '' },
];

const dynamicFileInputPrefix = 'image_';

interface SectionFormValues {
  title: string;
  required?: boolean;
  description: string;
  type: 'image' | 'text';
  [key: string]: undefined | boolean | string | PreferenceSectionText | SectionStorageFile;
}

interface SectionPreferenceTestProps {
  section: DiscoverySectionPreferenceOutput;
  index: number;
  isInvalid: boolean;
  editSection: (
    sectionId: string,
    updates: DiscoverySectionInput,
    shouldUpdate?: boolean
  ) => Promise<void> | undefined;
  uploadSectionFile: (sectionId: string, fileDetails: SectionFileUploadDetails) => Promise<void>;
  removeSectionFile: (sectionId: string, fileDetails: SectionFileRemovalDetails) => Promise<void>;
  removeSection: (sectionId: string) => Promise<void> | undefined;
  duplicateSection: ((sectionId: string) => Promise<void> | undefined) | (() => void);
}

export const SectionPreferenceTest = ({
  section,
  index,
  isInvalid,
  editSection,
  uploadSectionFile,
  removeSectionFile,
  removeSection,
  duplicateSection,
}: SectionPreferenceTestProps) => {
  const { t } = useTranslationContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();
  const { reflectUploadingStatus } = useActionInProgressContext.useContext();
  const {
    inFocusSectionId,
    setSectionInFocus,
    resetSectionInFocus: collapseSection,
  } = useSectionsListContext.useContext();

  const { title, _id, others, required, files } = section;
  const { description, type, texts } = others;

  const isLoading = isTemporarySection(_id);
  const isExpanded = inFocusSectionId === _id && !isLoading;
  const expandSection = () => setSectionInFocus(_id);

  const {
    inputs: textInputs,
    prefixedInputs: textFields,
    autoFocusInputKey: autoFocusTextFieldsKey,
    insertInput: insertTextField,
    saveInput: saveTextField,
    removeInput: removeTextField,
    focusInput: focusTextField,
  } = useDynamicInputs<PreferenceSectionText>({
    inputPrefix: dynamicContextInputPrefix,
    initialValues: texts,
    maxCount: 4,
    defaultValue: defaultValueTextFields,
    initialDefaultValues: initialDefaultValueTextFields,
  });

  const { files: allFiles, defaultNewFile } = useMemo(
    () => getSectionPreferenceTestFiles(files as SectionStorageMultipleFile[]),
    [files]
  );

  const {
    inputs: fileInputs,
    prefixedInputs: fileFields,
    autoFocusInputKey: autoFocusImageFieldsKey,
    insertInput: insertFileField,
    removeInput: removeFileField,
    focusInput: focusImageField,
  } = useDynamicInputs<SectionStorageMultipleFile>({
    inputPrefix: dynamicFileInputPrefix,
    initialValues: allFiles,
    defaultValue: defaultNewFile,
    maxCount: 4,
  });

  const updateSectionValues = (values: SectionFormValues) => {
    if (!_id) return;

    const updates = {
      title: values.title,
      required: values.required,
      others: {
        description: values.description,
        type: values.type,
        ...(values.type === 'text'
          ? {
              texts: textInputs.map((text) => text.value),
            }
          : {}),
      },
      ...(values.type === 'image'
        ? {
            files: fileInputs.map((file) => file.value),
          }
        : {}),
    };

    editSection(_id, updates);
  };

  const initialValues: SectionFormValues = useMemo(
    () => ({
      title: title,
      description: description || '',
      type: type,
      required: !!required,
      ...textFields,
      ...fileFields,
    }),
    [description, fileFields, required, textFields, title, type]
  );

  return (
    <SelectSectionItem
      alreadyAdded
      draggableItemId={_id}
      title={title || t('components.projects.sections.section-title-placeholder')}
      description={t('components.projects.sections.field-types.preference')}
      icon={<PreferenceTestIcon className='icon__small' />}
      onClick={isExpanded ? collapseSection : expandSection}
      type={DiscoverySectionType.Preference}
      onDelete={(e) => {
        e.stopPropagation();
        removeSection(_id);
      }}
      onDuplicate={(e) => {
        e.stopPropagation();
        duplicateSection?.(_id);
      }}
      showRequiredIcon={required}
      {...{ isExpanded, isInvalid, isLoading, index }}>
      <>
        <hr className={allClasses(styles.section_hr, 'w-11/12')} />
        <Form
          validateOnMount
          enableReinitialize
          enableChangeOnInputCountChange
          initialValues={initialValues}
          onChange={updateSectionValues}
          className='px-5'>
          {({ status, values, errors, touched, submitChange }) => (
            <>
              <FormError {...{ status }} />
              <FormField
                autoFocus
                type='text'
                name='title'
                label={`${t('components.projects.sections.preference-test.edit.title.label')}*`}
                placeholder={t(
                  'components.projects.sections.preference-test.edit.title.placeholder'
                )}
                validate={isRequired}
                fieldClassName='mb-5'
                inputClassName={allClasses(
                  styles.section_input,
                  errors.title && touched.title && styles.section_input__invalid
                )}
                labelClassName={styles.section_label}
              />
              <FormField
                type='text'
                name='description'
                label={t('components.projects.sections.preference-test.edit.description.label')}
                placeholder={t(
                  'components.projects.sections.preference-test.edit.description.placeholder'
                )}
                fieldClassName='mb-5'
                inputClassName={styles.section_input}
                labelClassName={styles.section_label}
              />
              <div className='mb-5'>
                <FormField
                  component={RadioGroup}
                  label={`${t('common.type')}*`}
                  name='type'
                  options={[
                    {
                      label: t('common.image'),
                      value: 'image',
                      icons: {
                        selected: (
                          <PlaceholderSmallIcon className='icon__small icon-main-contrast icon-stroke-polyline-main-contrast' />
                        ),
                        notSelected: <PlaceholderSmallIcon className='icon__small ' />,
                      },
                    },
                    {
                      label: t('common.text'),
                      value: 'text',
                      icons: {
                        selected: <TextIcon className='icon__small icon-main-contrast' />,
                        notSelected: <TextIcon className='icon__small' />,
                      },
                    },
                  ]}
                  checkedWrapperClassName='border-main-highlight'
                  checkedTextClassName='text-main-contrast'
                  labelClassName={styles.section_label}
                  checked={values.type}
                  wrapperClassName='grid-cols-2'
                />
              </div>
              {type === 'image' ? (
                <>
                  <div>
                    <div className='flex flex-row justify-between items-center mb-2'>
                      <span className='flex flex-row items-baseline text-base leading-5 text-main-contrast'>
                        <>{t('common.image_plural')}*</>
                        <span className='text-neutral text-xs leading-5 pl-1'>
                          ({t('common.minimum')} 2)
                        </span>
                      </span>
                      <span className='text-xs leading-4 text-neutral-70'>
                        {t('components.projects.sections.preference-test.edit.counter', {
                          min: fileInputs.length,
                        })}
                      </span>
                    </div>
                    <div>
                      {Object.entries(values)
                        .filter(([key]) => key.startsWith(dynamicFileInputPrefix))
                        .map(([key, fileValue], index, arr) => {
                          const file = fileValue as SectionStorageMultipleFile;
                          const fileUrl = !isTemporaryFile(file.id)
                            ? getStoragePublicFileUrl(file.id)
                            : '';

                          const shouldAutoFocus = autoFocusImageFieldsKey.current === key;
                          const keyForEnablingAutoFocus = `${key}_${shouldAutoFocus}`;
                          const isExpanded = shouldAutoFocus || !fileUrl;
                          const isLast = index === arr.length - 1;

                          return (
                            <Collapsible
                              key={keyForEnablingAutoFocus}
                              title={`${t('common.image')} ${index + 1}`}
                              isExpanded={isExpanded}
                              allowDelete={isLast && arr.length > 2}
                              onClick={() => focusImageField(isExpanded ? undefined : key)}
                              onDelete={async () => {
                                if (isTemporaryFile(file.id)) return removeFileField(index);

                                removeSectionFile(_id, {
                                  id: file.id,
                                  type: StorageFileType.SectionMultipleImages,
                                  indexId: file.indexId,
                                }).catch((err) => handleError(err));
                              }}>
                              <div className='mt-3'>
                                <UploadImage
                                  excludeFromForm
                                  required
                                  fileSizeLimit={imageFileSizeLimit}
                                  title={t('components.upload.image.title')}
                                  subtitle={t('components.upload.image.subtitle')}
                                  placeholderImgSrc={fileUrl}
                                  upload={(image) => {
                                    return uploadSectionFile(_id, {
                                      file: image,
                                      type: StorageFileType.SectionMultipleImages,
                                      indexId: isTemporaryFile(file.id)
                                        ? String(index)
                                        : file.indexId,
                                    });
                                  }}
                                  onUploadError={handleError}
                                  remove={async () => {
                                    if (isTemporaryFile(file.id)) return;

                                    return removeSectionFile(_id, {
                                      id: file.id,
                                      type: StorageFileType.SectionMultipleImages,
                                      indexId: file.indexId,
                                    });
                                  }}
                                  onRemoveError={handleError}
                                  onLoadingStatusChange={reflectUploadingStatus}
                                  fileName={file?.fileName}
                                />
                              </div>
                            </Collapsible>
                          );
                        })}
                    </div>
                    {fileInputs.length < 4 && (
                      <div className='my-5'>
                        <span
                          className='text-info border-b-default border-main-70 border-solid cursor-pointer hover:text-info-70'
                          onClick={() => insertFileField(fileInputs.length)}>
                          {t(
                            'components.projects.sections.preference-test.edit.image-section.add-new-image'
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <div className='flex flex-row justify-between items-center mb-2'>
                      <span className='flex flex-row items-baseline text-base leading-5 text-main-contrast'>
                        <>{t('common.text_plural')}*</>
                        <span className='text-neutral text-xs leading-5 pl-1'>
                          ({t('common.minimum')} 2)
                        </span>
                      </span>
                      <span className='text-xs leading-4 text-neutral-70'>
                        {t('components.projects.sections.preference-test.edit.counter', {
                          min: texts?.length,
                        })}
                      </span>
                    </div>
                    <div>
                      {Object.entries(values)
                        .filter(([key]) => key.startsWith(dynamicContextInputPrefix))
                        .map(([key, fieldValue], index, arr) => {
                          const value = fieldValue as PreferenceSectionText;
                          const shouldAutoFocus = autoFocusTextFieldsKey.current === key;
                          const keyForEnablingAutoFocus = `${key}_${shouldAutoFocus}`;
                          const isExpanded = !!shouldAutoFocus;
                          const shouldAutoFocusContextInput = shouldAutoFocus && !value.context;

                          return (
                            <Collapsible
                              key={keyForEnablingAutoFocus}
                              title={value.indicator || `${t('common.text')} ${index + 1}`}
                              isExpanded={isExpanded}
                              missingValue={!value.context}
                              allowDelete={arr.length > 2}
                              onClick={() => {
                                focusTextField(isExpanded ? undefined : key);
                              }}
                              onDelete={() => removeTextField(index, false)}>
                              <div className='mt-3'>
                                <FormField
                                  autoFocus={shouldAutoFocusContextInput}
                                  as='textarea'
                                  name={key}
                                  label={`${t(
                                    'components.projects.sections.preference-test.edit.text-section.context'
                                  )}*`}
                                  placeholder={t(
                                    'components.projects.sections.preference-test.edit.text-section.context-placeholder'
                                  )}
                                  fieldClassName='mb-5'
                                  inputClassName={allClasses(
                                    styles.section_input,
                                    'min-h-9 resize-none'
                                  )}
                                  labelClassName={styles.section_label}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    saveTextField(index, {
                                      context: e.target.value,
                                      indicator: value.indicator,
                                    });
                                    submitChange();
                                  }}
                                  value={value.context || ''}
                                />
                                <FormField
                                  type='text'
                                  name={key}
                                  label={t(
                                    'components.projects.sections.preference-test.edit.text-section.indicator'
                                  )}
                                  placeholder={t(
                                    'components.projects.sections.preference-test.edit.text-section.indicator-placeholder'
                                  )}
                                  fieldClassName='mb-5'
                                  inputClassName={styles.section_input}
                                  labelClassName={styles.section_label}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    saveTextField(index, {
                                      context: value.context,
                                      indicator: e.target.value,
                                    });
                                    submitChange();
                                  }}
                                  value={value.indicator || ''}
                                />
                              </div>
                            </Collapsible>
                          );
                        })}
                    </div>
                    {textInputs.length < 4 && (
                      <div className='my-5'>
                        <span
                          className='text-info border-b-default border-main-70 border-solid cursor-pointer hover:text-info-70'
                          onClick={() => {
                            insertTextField(textInputs.length);
                          }}>
                          {t(
                            'components.projects.sections.preference-test.edit.text-section.add-new-text'
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                </>
              )}
              <hr className={styles.section_hr} />
              <div className={allClasses(styles.section_toggle_wrapper, 'mb-5 py-2.5')}>
                <span>{t('components.projects.sections.required-section')}</span>
                <FormField
                  component={ToggleField}
                  name='required'
                  sliderClassName={styles.section_toggle_slider_wrapper}
                  circleClassName={styles.section_toggle_slider_circle}
                />
              </div>
            </>
          )}
        </Form>
      </>
    </SelectSectionItem>
  );
};
