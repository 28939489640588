export class RecordDuration {
  private initialStartTime = Date.now();
  private currentStartTime = this.initialStartTime;
  private pauseStartTime = 0;
  private totalPauseTime = 0;
  private isPaused = false;
  public name = '';

  constructor(initialDelay = 0, name = '') {
    this.name = name;
    this.initialStartTime += initialDelay;
    this.currentStartTime = this.initialStartTime;
  }

  get getName() {
    return this.name;
  }

  getPaused = () => {
    return this.isPaused;
  };

  startNewDurationRecording = (delay = 0) => {
    this.currentStartTime = Date.now() + delay;
  };

  restart = () => {
    this.initialStartTime = Date.now();
    this.currentStartTime = this.initialStartTime;
    this.pauseStartTime = 0;
    this.totalPauseTime = 0;
    this.isPaused = false;
  };

  getCurrentDuration = () => {
    const endTime = Date.now() - this.currentStartTime;

    return endTime;
  };

  getInitialTime = () => {
    return this.initialStartTime;
  };

  getCurrentPauseDuration = () => {
    if (!this.isPaused || !this.pauseStartTime) return 0;
    return Date.now() - this.pauseStartTime;
  };

  pauseCurrentDuration = () => {
    if (this.isPaused) return;

    this.pauseStartTime = Date.now();
    this.isPaused = true;
  };

  resumeCurrentDuration = () => {
    if (!this.isPaused || !this.pauseStartTime) return;

    this.totalPauseTime = this.totalPauseTime + this.getCurrentPauseDuration();
    this.pauseStartTime = 0;
    this.isPaused = false;
  };

  getTotalDuration = () => {
    return Date.now() - this.initialStartTime - this.totalPauseTime;
  };

  getTotalPauseDuration = () => {
    return this.isPaused
      ? this.totalPauseTime + this.getCurrentPauseDuration()
      : this.totalPauseTime;
  };
}
