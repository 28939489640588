import { Children, ReactNode } from 'react';

import { allClasses } from '../../services/utilities/array';

import styles from './Card.module.css';

// Min number of children in order to mainain expected grid layout dimensions
const minChildrenCount = 5;

interface CardListProps {
  children?: ReactNode;
}

export const CardList = ({ children }: CardListProps) => {
  const childrenCount = Children.count(children);

  const hiddrenExtraGridChildren =
    childrenCount > minChildrenCount ? [] : [...Array(minChildrenCount - childrenCount)];

  return (
    <div className={styles.card_list}>
      {children}
      {hiddrenExtraGridChildren.map((el, index) => (
        <div key={index} className={allClasses(styles.container, 'opacity-0')} />
      ))}
    </div>
  );
};
