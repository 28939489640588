import { useRef } from 'react';

import { allClasses } from '../../../services/utilities/array';
import { useTranslationContext } from '../../../services/translation';
import { createNewFolder, FolderCreateInput, FolderOutput } from '../../services/api-iteration1';
import { isRequired } from '../../services/validation';
import { useRouter } from '../../../services/router';

import { Modal } from '../../../components/modal';
import { Button, createFormSubmitHandler, Form, FormError, FormField } from '../../../components';

import { CloseIcon } from '../../assets/icons';
import { dynamicRoutes } from '../../pages/routing/routes';

import styles from './Modals.module.css';

interface CreateFolderModalProps {
  isOpen: boolean;
  closeModal: () => void;
  onSuccess: () => void;
  folder?: FolderOutput;
}

export const CreateFolderModal = ({
  isOpen,
  closeModal,
  onSuccess,
  folder,
}: CreateFolderModalProps) => {
  const router = useRouter();
  const { t } = useTranslationContext.useContext();
  const focusInputRef = useRef(null);

  const formatPayload = (payload: FolderCreateInput) => {
    if (folder?.parents) {
      if (folder?.isDefault) {
        return {
          ...payload,
        };
      }

      return {
        ...payload,
        parents: [
          ...folder.parents,
          { _id: folder.id, name: folder.name, index: folder.parents.length },
        ],
      };
    }

    return payload;
  };

  const createFolder = createFormSubmitHandler<{ name: string }>({
    tryAction: (values) => createNewFolder(formatPayload(values)),
    onSuccess: (result) => {
      onSuccess();
      closeModal();
      router.push(dynamicRoutes.folder(result.id));
    },
    resetFormOnSubmit: true,
  });

  return (
    <Modal
      title={t('components.projects.folder.modal-title')}
      isOpen={isOpen}
      onClose={closeModal}
      variant='regular'
      closeModalComponent={<CloseIcon className='icon-hover-main-contrast' />}
      closeIconClassName='absolute top-5 right-5'
      refInitialFocus={focusInputRef}>
      <div className='pt-10 text-center'>
        <h2 className={allClasses(styles.title, 'mb-10')}>
          {t('components.projects.folder.modal-title')}
        </h2>
        <div className='mb-20'>
          <Form
            className='flex flex-col items-center'
            initialValues={{ name: '' }}
            onSubmit={createFolder}>
            {({ status, isValidForSubmit, isSubmitting, errors, touched }) => {
              return (
                <>
                  <FormError {...{ status }} />
                  <FormField
                    autoFocus
                    type='text'
                    name='name'
                    placeholder={t('components.projects.folder.modal-input-placeholder')}
                    validate={isRequired}
                    fieldClassName='w-full max-w-sm mb-10'
                    inputClassName={allClasses(
                      styles.project_input,
                      errors.name && touched.name && styles.project_input__invalid
                    )}
                    innerRef={focusInputRef}
                  />

                  <Button
                    className='button__filled px-10'
                    disabled={!isValidForSubmit || isSubmitting}
                    type='submit'
                    loading={isSubmitting}>
                    <span className='text-main-contrast leading-5 text-base font-medium'>
                      {t('common.create')}
                    </span>
                  </Button>
                </>
              );
            }}
          </Form>
        </div>
      </div>
    </Modal>
  );
};
