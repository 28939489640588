import { StripeError } from '@stripe/stripe-js';

import { InvoiceOutput, PriceOutput } from '../api-iteration1/billing/api-models';

export const getPaymentDetails = (context: {
  planPrice: PriceOutput;
  invoice: InvoiceOutput | undefined;
}) => {
  const { planPrice, invoice } = context;

  if (!planPrice?.value || !planPrice?.recurring) return {};

  const currentPlanPrice = planPrice.value;
  const currentPlanProrataPrice = invoice?.prorataPlanPrice;
  const billedNow = invoice?.totalCostDueNow || 0;
  const tax = invoice?.tax || 0;
  const promoDiscount = invoice?.discount || 0;
  const isDiscountable = !!invoice?.discountable;

  const initialPlanPrice = currentPlanPrice;
  const initialPlanProrataPrice = currentPlanProrataPrice
    ? currentPlanProrataPrice + promoDiscount
    : 0;

  const credit = invoice?.credit || 0;
  const remainingCredit = invoice?.remainingCredit || 0;
  const creditDueNow = credit - remainingCredit;
  const subtotal = (currentPlanProrataPrice || 0) + tax - creditDueNow;

  const differentPaymentsLater = currentPlanPrice !== invoice?.totalCostDueNow;

  const isProrata = !!(currentPlanProrataPrice && currentPlanPrice !== currentPlanProrataPrice);

  return {
    initialPlanPrice,
    initialPlanProrataPrice,
    tax,
    promoDiscount,
    credit,
    creditDueNow,
    subtotal,
    billedNow,
    remainingCredit,
    differentPaymentsLater,
    isProrata,
    isDiscountable,
  };
};

export const getStripePaymentErrorMessage = (
  error: StripeError,
  translate: (toTranslate: string) => string
) => {
  const paymentErrorMessage = translate(`errors.payment.${error.type}`);
  const errorMessage =
    error.type === 'card_error' ? error.message || paymentErrorMessage : paymentErrorMessage;
  return errorMessage;
};
