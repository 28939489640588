import { useTranslationContext } from '../../../services/translation';

import { Button, Icon } from '../../../components';

import { websiteRoutes } from '../../pages/routing/routes';
import { LockIcon } from '../../assets/icons';
import { PaymentErrorCard } from '../cards';

interface PaymentButtonProps {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  errorMessage?: string;
}

export const PaymentButton = ({ text, onClick, disabled, errorMessage }: PaymentButtonProps) => {
  const { t } = useTranslationContext.useContext();

  return (
    <>
      <div className='flex justify-between items-center'>
        <Button
          className='button__filled w-32 justify-center font-medium'
          {...{ onClick, disabled }}>
          {text}
        </Button>
        <div className='flex items-center'>
          <Icon
            Svg={LockIcon}
            className='icon__small cursor-default icon-stroke-main-highlight-95 mr-4'
          />
          <p className='flex-1 text-xs text-main-highlight-95 w-28'>
            {t('pages.account.tabs.plan-billing.order-summary.secure-payment')}
          </p>
        </div>
      </div>
      {errorMessage && <PaymentErrorCard errorMessage={errorMessage} />}
      <p className='text-sm text-neutral-30 mt-4'>
        {t('pages.account.tabs.plan-billing.order-summary.t&c.part1')}
      </p>
      <p className='text-sm text-neutral-30 mt-4'>
        {t('pages.account.tabs.plan-billing.order-summary.t&c.part2')}
        &nbsp;
        <a href={websiteRoutes.terms} target='_blank' className='underline' rel='noreferrer'>
          {t('pages.account.tabs.plan-billing.order-summary.t&c.part3')}
        </a>
        &nbsp;
        {t('pages.account.tabs.plan-billing.order-summary.t&c.part4')}
      </p>
    </>
  );
};
