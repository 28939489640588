import { Button } from '../../../components';

import { InviteCollaboratorsIllustration } from '../../assets/icons';

interface CollaboratorsSearchEmptyStateProps {
  title: string;
  description: string;
  buttonText: string;
  onClick?: () => void;
}

export const CollaboratorsSearchEmptyState = ({
  title,
  description,
  buttonText,
  onClick,
}: CollaboratorsSearchEmptyStateProps) => {
  return (
    <div className='flex flex-col justify-center items-center text-center mt-[5.5rem]'>
      <span className='w-20 flex items-center justify-center mb-10'>
        <InviteCollaboratorsIllustration />
      </span>
      <div className='flex flex-col max-w-[25rem]'>
        <p className='text-xl text-main-contrast leading-30 font-bold mb-2.5'>{title}</p>
        <p className='text-lg text-main-contrast leading-6'>{description}</p>
      </div>
      {onClick && (
        <Button className='button__filled px-10 mt-16 mb-20' type='button' {...{ onClick }}>
          <span className='text-main-contrast leading-5 text-base font-medium'>{buttonText}</span>
        </Button>
      )}
    </div>
  );
};
