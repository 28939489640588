export const endpoints = {
  v1: {
    sections: (userTestId: string, versionId: string) =>
      `/v1/userTests/${userTestId}/versions/${versionId}/sections`,
    sort_sections: (userTestId: string, versionId: string) =>
      `/v1/userTests/${userTestId}/versions/${versionId}/sections/sort`,
    section: (userTestId: string, versionId: string, sectionId: string) =>
      `/v1/userTests/${userTestId}/versions/${versionId}/sections/${sectionId}`,
    section_upload_file: (userTestId: string, versionId: string, sectionId: string) =>
      `/v1/userTests/${userTestId}/versions/${versionId}/sections/${sectionId}/files`,
    section_remove_file: (
      userTestId: string,
      versionId: string,
      sectionId: string,
      fileId: string
    ) => `/v1/userTests/${userTestId}/versions/${versionId}/sections/${sectionId}/files/${fileId}`,
    section_duplicate: (userTestId: string, versionId: string, sectionId: string) =>
      `/v1/userTests/${userTestId}/versions/${versionId}/sections/${sectionId}/duplicate`,
  },
};
