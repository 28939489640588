import {
  FetchOptions,
  ResourceServiceOptions,
} from '../../../../services/api/resource-fetch-factory';

import { apiIteration1, useIteration1Resource } from '../config';
import { FolderChildOutput, FolderChildType } from '../folders';
import { ResourceAccessRoles, Collaborator } from './api-models';
import { endpoints } from './endpoints';

export * from './utils';

export const useCurrentUserCollaborators = (
  options?: FetchOptions,
  config?: ResourceServiceOptions<any, any>
) => {
  return useIteration1Resource<Collaborator[]>(
    endpoints.v1.currentUserCollaborators,
    options,
    config
  );
};

export const useResourceCollaborators = (
  resourceType: FolderChildType,
  resourceId: string,
  config?: ResourceServiceOptions<any, any>
) => {
  return useIteration1Resource<Collaborator[]>(
    endpoints.v1.resource(resourceType, resourceId),
    {},
    config
  );
};

export const useCommonResourcesWithCollaborator = (
  email: string,
  config?: ResourceServiceOptions<any, any>
) => {
  return useIteration1Resource<FolderChildOutput[]>(
    endpoints.v1.collaborator_resources(email),
    {},
    config
  );
};

export const addCollaborator = async (
  resourceType: FolderChildType,
  resourceId: string,
  payload: { email: string; role: ResourceAccessRoles }
) => {
  const result = await apiIteration1.post<Collaborator>(
    endpoints.v1.resource(resourceType, resourceId),
    payload
  );

  return result.data;
};

export const removeCollaborator = (
  resourceType: FolderChildType,
  resourceId: string,
  userId: string
) => {
  return apiIteration1.destroy(endpoints.v1.removeCollaborator(resourceType, resourceId, userId));
};

export const removePendingCollaborator = (
  resourceType: FolderChildType,
  resourceId: string,
  payload: { email: string }
) => {
  return apiIteration1.destroy(endpoints.v1.removePendingCollaborator(resourceType, resourceId), {
    data: payload,
  });
};

export const updateCollaborator = async (
  resourceType: FolderChildType,
  resourceId: string,
  payload: { email: string; role: ResourceAccessRoles }
): Promise<Collaborator[]> => {
  const response = await apiIteration1.put<Collaborator[]>(
    endpoints.v1.resource(resourceType, resourceId),
    payload
  );

  return response.data;
};

export const removeCollaboratorFromAllProjects = async (email: string) => {
  return apiIteration1.destroy(endpoints.v1.removeCollaboratorFromAllProjects(email));
};
