import { useTranslationContext } from '../../../services/translation';
import { allClasses } from '../../../services/utilities/array';

interface AnalyticsOpenWordAnswerCardProps {
  content: string;
  date?: React.ReactNode;
  index: number;
  isLast?: boolean;
  shouldDisplayTesterIndex?: boolean;
}

export const AnalyticsOpenWordAnswerCard = ({
  content,
  date,
  index,
  isLast,
  shouldDisplayTesterIndex,
}: AnalyticsOpenWordAnswerCardProps) => {
  const { t } = useTranslationContext.useContext();

  return (
    <div
      className={allClasses(
        'w-full p-5 bg-main-10 border-2 border-main-10 border-solid rounded-lg text-main-contrast',
        isLast ? 'mb-0' : 'mb-5'
      )}>
      {content}
      {date && (
        <p className='mt-2.5 text-neutral-30 text-sm leading-5'>
          {shouldDisplayTesterIndex && `${t('common.tester')} #${index},`} {date}
        </p>
      )}
    </div>
  );
};
