import {
  DiscoveryLegalAnswer,
  DiscoverySectionLegalOutput,
} from '../../../../services/api-iteration1';
import { useTranslationContext } from '../../../../../services/translation';
import { StorageFileType } from '../../../../services/api-cloud-storage/api-models';

import { LoadingOrContent } from '../../../../../components';

import { AnalyticsSectionContainer } from '../../../containers';
import { LegalIcon } from '../../../../assets/icons';
import { AnalyticsSummaryStatistic } from '../../../headers/AnalyticsSummaryStatistic';
import { AnalyticsLegalAnswerCard } from '../../../cards/AnalyticsLegalAnswerCard';

interface AnalyticsLegalSummaryProps {
  answers: DiscoveryLegalAnswer[];
  section: DiscoverySectionLegalOutput;
  sectionsCount: number;
  isLoadingResponses: boolean;
}

export const AnalyticsLegalSummary = ({
  answers,
  section,
  sectionsCount,
  isLoadingResponses,
}: AnalyticsLegalSummaryProps) => {
  const { t } = useTranslationContext.useContext();

  const sectionDocument = section.files.find((f) => f.type === StorageFileType.LegalDocument);
  const viewedCount = answers.filter((answer) => answer.viewed).length;

  return (
    <AnalyticsSectionContainer
      contentTitle={section.title}
      description={section.others?.description as string}
      icon={<LegalIcon />}
      rightSideInformation={
        <AnalyticsSummaryStatistic
          currentSectionIndex={section.index}
          answersCount={answers.length || 1}
          {...{ sectionsCount }}
        />
      }
      title={t('components.projects.sections.field-types.legal')}>
      <LoadingOrContent isLoading={isLoadingResponses} className='pb-12'>
        <div className='w-full mt-20 mb-16'>
          {answers.length ? (
            <AnalyticsLegalAnswerCard
              inSummary
              {...{ sectionDocument }}
              answersCount={answers.length}
              viewedCount={viewedCount}
            />
          ) : (
            <p className='text-center font-medium text-danger'>
              {t('components.projects.analytics.no-answers')}
            </p>
          )}
        </div>
      </LoadingOrContent>
    </AnalyticsSectionContainer>
  );
};
