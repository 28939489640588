import { useTranslationContext } from '../../../services/translation';

import { Button, Modal } from '../../../components';

import { CloseIcon } from '../../assets/icons';

interface DeleteConfirmModalProps {
  onRemove: () => void | Promise<void>;
  isOpen: boolean;
  closeDeleteConfirmModal: () => void;
  projectType: string;
  deleteButtonDisabled?: boolean;
}

export const DeleteConfirmModal = ({
  isOpen,
  closeDeleteConfirmModal,
  onRemove,
  projectType,
  deleteButtonDisabled,
}: DeleteConfirmModalProps) => {
  const { t } = useTranslationContext.useContext();

  const removeProject = async () => {
    await onRemove();
    closeDeleteConfirmModal();
  };

  return (
    <Modal
      title={t('components.projects.project-actions.delete-confirm-modal-title', {
        projectTypeToDelete: t(`components.projects.type.${projectType}`).toLowerCase(),
      })}
      isOpen={isOpen}
      onClose={closeDeleteConfirmModal}
      variant='regular'
      closeModalComponent={<CloseIcon className='icon-hover-main-contrast' />}
      closeIconClassName='absolute top-5 right-5'>
      <div className='pt-10 pb-20 text-center'>
        <h2 className='my-10 text-main-contrast leading-8 text-2xl font-bold'>
          {t('components.projects.project-actions.delete-confirm-modal-title', {
            projectTypeToDelete: t(`components.projects.type.${projectType}`).toLowerCase(),
          })}
        </h2>
        <div className='mb-10 text-main-contrast text-lg leading-6 font-medium'>
          {t('components.projects.project-actions.delete-confirm-modal-description-part-one', {
            projectTypeToDelete: t(`components.projects.type.${projectType}`).toLowerCase(),
          })}
          <p className='mb-0 text-danger-60'>
            {t('components.projects.project-actions.delete-confirm-modal-description-part-two')}
          </p>
        </div>
        <div className='flex flex-row items-center justify-center'>
          <Button
            disabled={deleteButtonDisabled}
            className='button__filled mr-4 py-2.5 px-10 justify-center bg-main border-2 border-solid border-main-10 hover:enabled:bg-main-10'
            type='button'
            onClick={closeDeleteConfirmModal}>
            {t('components.projects.project-actions.delete-confirm-modal-button-cancel', {
              projectTypeToDelete: t(`components.projects.type.${projectType}`).toLowerCase(),
            })}
          </Button>
          <Button
            disabled={deleteButtonDisabled}
            loading={deleteButtonDisabled}
            className='button__filled py-2.5 px-10 justify-center bg-danger-60 text-main hover:enabled:bg-danger'
            type='submit'
            onClick={removeProject}
            loadingIconType='light'
            loadingOpacity='50'>
            {t('components.projects.project-actions.delete-confirm-modal-button-submit', {
              projectTypeToDelete: t(`components.projects.type.${projectType}`).toLowerCase(),
            })}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
