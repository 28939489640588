export const endpoints = {
  v1: {
    userTests: '/v1/userTests',
    userTest: (userTestId: string) => `/v1/userTests/${userTestId}`,
    userTestPrototype: (userTestId: string) => `/v1/userTests/${userTestId}/prototype`,
    userTestVersion: (userTestId: string, versionId: string) =>
      `/v1/userTests/${userTestId}/versions/${versionId}`,
    versions: (userTestId: string) => `/v1/userTests/${userTestId}/versions`,
    endUserTest: (userTestId: string, versionId: string) =>
      `/v1/userTests/${userTestId}/versions/${versionId}/unpublish`,
  },
};
