import {
  isToday,
  isYesterday,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
} from 'date-fns';

import { useTranslationContext } from '../../../services/translation';

export const DateFromToday = ({ date }: { date: Date }) => {
  const { t } = useTranslationContext.useContext();
  const currentDate = new Date();
  const dateToRender = new Date(date);

  const dateToDisplay = () => {
    if (isToday(dateToRender)) {
      return t('components.projects.dates.today');
    }

    if (isYesterday(dateToRender)) {
      return t('components.projects.dates.yesterday');
    }

    if (differenceInDays(currentDate, dateToRender) === 1) {
      return `${differenceInDays(currentDate, dateToRender)} ${t('components.projects.dates.day')}`;
    }

    if (
      differenceInDays(currentDate, dateToRender) > 1 &&
      differenceInDays(currentDate, dateToRender) < 31
    ) {
      return `${differenceInDays(currentDate, dateToRender)} ${t(
        'components.projects.dates.day_plural'
      )}`;
    }

    if (differenceInMonths(currentDate, dateToRender) === 1) {
      return `${differenceInMonths(currentDate, dateToRender)} ${t(
        'components.projects.dates.month_ago'
      )}`;
    }

    if (differenceInMonths(currentDate, dateToRender) > 1) {
      return `${differenceInMonths(currentDate, dateToRender)} ${t(
        'components.projects.dates.month_ago_plural'
      )}`;
    }

    if (differenceInYears(currentDate, dateToRender) === 1) {
      return `${differenceInMonths(currentDate, dateToRender)} ${t(
        'components.projects.dates.year_ago'
      )}`;
    }

    if (differenceInYears(currentDate, dateToRender) > 1) {
      return `${differenceInMonths(currentDate, dateToRender)} ${t(
        'components.projects.dates.year_ago_plural'
      )}`;
    }

    return null;
  };

  return <>{dateToDisplay()}</>;
};
