import { useTranslationContext } from '../../../../services/translation';
import { allClasses } from '../../../../services/utilities/array';

import { Tooltip } from '../../../../components/tooltip/Tooltip';

interface InvalidResponsesTextProps {
  invalidResponsesCount: number;
  className?: string;
}

export const InvalidResponsesText = ({
  invalidResponsesCount,
  className,
}: InvalidResponsesTextProps) => {
  const { t } = useTranslationContext.useContext();

  if (!invalidResponsesCount) return null;

  return (
    <div
      className={allClasses(
        'flex flex-row bg-main-10 py-3 px-4 rounded-lg mb-5 mt-10 w-fit',
        className
      )}>
      <p className='text-neutral-80 text-base font-bold'>
        {t('components.projects.analytics.responses.invalid.title', {
          count: invalidResponsesCount,
        })}
        {'.'}
      </p>
      <div className='ml-1'>
        <p className='inline text-neutral-80 text-base'>
          {t('components.projects.analytics.responses.invalid.subtitle')}
        </p>{' '}
        <Tooltip
          containerClassName='' // Necessary so there's no hidden overflow of the tooltip content
          className='bg-white text-main-contrast border-2 border-main-10 w-90 p-5 flex-wrap whitespace-pre-wrap top-12 -left-24 xl:-left-5 xl:translate-x-4'
          content={
            <p className='text-base text-left text-main-contrast inline-block'>
              {t('components.projects.analytics.responses.invalid.tooltip1')}
              <span className='font-bold'>
                {t('components.projects.analytics.responses.invalid.tooltip2')}
              </span>
              {t('components.projects.analytics.responses.invalid.tooltip3')}
            </p>
          }>
          <p className='inline text-neutral-80 text-base border-b border-b-neutral-30'>
            {t('components.projects.analytics.responses.invalid.info')}
          </p>
        </Tooltip>
      </div>
    </div>
  );
};
