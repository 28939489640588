import { useTranslationContext } from '../../services/translation';
import { useCurrentUserContext } from '../services/current-user';

import { SignInForm, NoFooterLayout } from '../components';

import { routes } from './routing/routes';

export const SignIn = () => {
  const { t } = useTranslationContext.useContext();
  const { isAuthenticated } = useCurrentUserContext.useContext();

  return (
    <NoFooterLayout
      canAccessPage={!isAuthenticated}
      redirectUrl={routes.home}
      analyticsName='Sign in'
      title={t('pages.sign-in.title')}
      availableOnMobile={false}>
      <SignInForm />
    </NoFooterLayout>
  );
};
