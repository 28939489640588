import { useTranslationContext } from '../../../../../services/translation';
import { InvoiceOutput } from '../../../../services/api-iteration1/billing/api-models';
import { getKeyForCondition } from '../../../../../services/utilities/object';
import { allClasses } from '../../../../../services/utilities/array';

import { Icon, LoadingOrContent, PaymentContext } from '../../../../../components';

import { ExternalLinkIcon, ReceiptIcon, RefreshIcon } from '../../../../assets/icons';
import { FormattedDate, Price } from '../../../formatters';
import { PayInvoiceButton } from '../../../buttons';

interface BillingHistoryProps {
  invoices: InvoiceOutput[];
  isValidatingRecentInvoices: boolean;
  revalidateRecentInvoices: () => void;
  openCheckoutModal: (context: { clientSecret: string; invoiceId: string }) => void;
}

export const BillingHistory = ({
  invoices,
  isValidatingRecentInvoices,
  revalidateRecentInvoices,
  openCheckoutModal,
}: BillingHistoryProps) => {
  const { t } = useTranslationContext.useContext();

  return (
    <div className='w-full flex flex-col px-5 border-2 border-main-10 border-solid rounded-lg'>
      <div className='flex flex-row py-5 items-center justify-between border-b-2 border-main-10 border-solid'>
        <span className='flex flex-row items-center'>
          <ReceiptIcon />
          <span className='pl-5 text-base leading-5 text-main-contrast font-bold'>
            {t('pages.account.tabs.plan-billing.billing-history.title')}
          </span>
        </span>

        <Icon
          Svg={RefreshIcon}
          className={allClasses('w-6 h-6', isValidatingRecentInvoices && 'animate-spin')}
          onClick={revalidateRecentInvoices}
        />
      </div>
      <div className='p-10'>
        <LoadingOrContent isLoading={isValidatingRecentInvoices}>
          {invoices.length ? (
            invoices.map((invoice) => (
              <div
                key={invoice.id}
                className='grid grid-cols-5 gap-x-3 items-center mb-5 pb-5 border-b-default border-solid border-main-10 last-of-type:mb-0 last-of-type:border-b-0'>
                <div>
                  <FormattedDate seconds={invoice.createdAt} precision='PP' />
                </div>
                <div>
                  <Price value={invoice.totalCost} precision={2} />
                </div>
                <div
                  className={allClasses(
                    getKeyForCondition({
                      'text-success font-bold': ['paid'].includes(invoice.status as string),
                      'text-danger font-bold': ['uncollectible'].includes(invoice.status as string),
                      'text-warning font-bold': ['open'].includes(invoice.status as string),
                      'text-neutral': ['draft', 'deleted', 'void'].includes(
                        invoice.status as string
                      ),
                    })
                  )}>
                  {t(
                    `pages.account.tabs.plan-billing.billing-history.invoice.status.${invoice.status}`
                  )}
                </div>
                {invoice.invoicePdfUrl ? (
                  <a
                    href={invoice.invoicePdfUrl || ''}
                    target='_blank'
                    className='flex flex-row'
                    rel='noreferrer'>
                    <Icon Svg={ExternalLinkIcon} className='icon__small icon-info' />
                    <span className='ml-4 text-info text-base h-fit border-b-default border-main-70 border-solid cursor-pointer'>
                      {t('pages.account.tabs.plan-billing.billing-history.invoice.pdf')}
                    </span>
                  </a>
                ) : (
                  <span className='ml-4 text-base h-fit px-3'>N/A</span>
                )}
                {invoice.status === 'open' && (
                  <PaymentContext
                    options={{ clientSecret: invoice.paymentIntentClientSecret || undefined }}>
                    <PayInvoiceButton
                      invoice={invoice}
                      onRequiresNewPaymentMethod={openCheckoutModal}
                    />
                  </PaymentContext>
                )}
              </div>
            ))
          ) : (
            <p className='text-neutral-80 text-lg leading-6'>
              {t('pages.account.tabs.plan-billing.billing-history.empty-state')}
            </p>
          )}
        </LoadingOrContent>
      </div>
    </div>
  );
};
