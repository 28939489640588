interface ProjectVersionEmptyStateProps {
  description: string;
  icon: React.ReactNode;
  title: string;
}

export const ProjectVersionEmptyState = ({
  description,
  icon,
  title,
}: ProjectVersionEmptyStateProps) => {
  return (
    <div className='flex flex-col items-center justify-start text-center mt-40'>
      {icon}
      <div className='mt-14 flex flex-col items-center justify-center'>
        <h5>{title}</h5>
        <p className='mt-2.5 mb-0 text-lg leading-5'>{description}</p>
      </div>
    </div>
  );
};
