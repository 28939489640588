import { AuthProviders } from '../../../services/auth/firebase';

import { AuthUserData } from './utils';

import { CurrentUserOutput } from '../api-iteration1/users/api-models';

export interface AuthenticatedUser extends CurrentUserOutput {
  account: AuthUserData;
}

export class AuthenticatedUser {
  constructor(authUserData: AuthUserData, apiUserData: CurrentUserOutput) {
    Object.assign(this, apiUserData);
    this.account = authUserData;
  }

  get usedSocialAuth() {
    const countSocialConnectedAccounts = this?.account.providerData.filter(
      (provider) => provider && provider.providerId !== AuthProviders.Password
    ).length;
    return !!countSocialConnectedAccounts;
  }
}
