import { useCallback } from 'react';
import { SWRConfig, useSWRConfig } from 'swr';

const cacheObject = new Map();

export const ResourceFetcherWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <SWRConfig
      value={{
        provider: () => cacheObject,
      }}>
      {children}
    </SWRConfig>
  );
};

export const useResourceFetcherWrapper = () => {
  const { cache } = useSWRConfig();

  const clearResourceFetcherCache = useCallback(() => (cache as any).clear(), [cache]);

  return { clearResourceFetcherCache };
};
