import { FieldAttributes, FieldProps } from 'formik';

import { Toggle } from '../../../toggle';

interface ToggleFieldProps extends FieldProps, FieldAttributes<any> {
  circleClassName: string;
  sliderClassName: string;
  text?: string;
  id: string;
}

export const ToggleField = ({
  circleClassName,
  sliderClassName,
  text,
  id,
  field,
  ...otherProps
}: ToggleFieldProps) => {
  const isChecked = !!(field.value || otherProps.value);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { form, meta, ...inputProps } = otherProps;

  return (
    <Toggle
      {...field}
      {...inputProps}
      circleClassName={circleClassName}
      sliderClassName={sliderClassName}
      text={text}
      isChecked={isChecked}
      id={id}
      onChange={(e) => {
        field.onChange(e);
        otherProps.onChange && otherProps.onChange(e);
      }}
      onBlur={(e) => {
        field.onBlur(e);
        otherProps.onBlur && otherProps.onBlur(e);
      }}
    />
  );
};
