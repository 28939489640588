import { FolderChildType } from '../folders';
import { CurrentUserOutput } from '../users';

export enum CollaboratorRole {
  Admin = 'admin',
  Editor = 'editor',
  Viewer = 'viewer',
}

export enum OwnershipRole {
  Owner = 'owner',
}

export type ResourceAccessRoles = CollaboratorRole | OwnershipRole;

export const roleDropdownPrefix = 'role_';

export interface ResourceCollaborator {
  id: string;
  role: CollaboratorRole;
}

export interface CollaborationResource {
  id: string;
  type: FolderChildType;
  ownerId: string;
  role: CollaboratorRole;
}

export interface CollaboratorInPending {
  email: string;
  resources: CollaborationResource[];
  isPending: boolean;
}

export type Collaborator = CollaboratorInPending &
  Pick<CurrentUserOutput, 'id' | 'email' | 'firstName' | 'lastName' | 'image' | 'resources'>;

export type OwnerAsCollaborator = Collaborator & { role: OwnershipRole.Owner };
