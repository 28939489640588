import { useTranslationContext } from '../../../services/translation';

export const AnalyticsSectionAnswerEmptyState = () => {
  const { t } = useTranslationContext.useContext();

  return (
    <div className='flex flex-col w-full items-center'>
      <div className='flex flex-row w-full justify-start'>
        <p className='text-danger font-medium'>
          {t('components.projects.analytics.responses.no-answer')}
        </p>
      </div>
    </div>
  );
};
