import { useTranslationContext } from '../../../services/translation';
import { getStoragePublicFileUrl } from '../../services/api-cloud-storage';
import { SectionStorageFile } from '../../services/api-iteration1';

import { CheckIconOn, ExternalLinkIcon, UploadDocumentIcon, ViewedIcon } from '../../assets/icons';

interface AnalyticsLegalAnswerCardProps {
  answersCount?: number;
  viewedCount?: number;
  sectionDocument: SectionStorageFile | undefined;
  inSummary: boolean;
}

export const AnalyticsLegalAnswerCard = ({
  answersCount,
  viewedCount,
  sectionDocument,
  inSummary,
}: AnalyticsLegalAnswerCardProps) => {
  const { t } = useTranslationContext.useContext();

  return (
    <div className='flex justify-between items-center w-full rounded-lg border-2 border-solid border-main-10 p-5'>
      <div className='flex items-center justify-center'>
        <div className='bg-main-10 p-2.5 rounded mr-5'>
          <UploadDocumentIcon className='w-10 h-10' />
        </div>
        <span>{sectionDocument?.fileName}</span>
      </div>
      <div className='flex flex-row justify-between items-center'>
        {inSummary ? (
          <div className='flex flex-row'>
            <span className='flex flex-col items-center justify-between'>
              <span className='text-sm leading-[1.125rem] text-main-contrast font-normal mb-2'>
                {t('components.projects.analytics.responses.legal.accepted')}
              </span>
              <span className='flex flex-row justify-between items-center'>
                <CheckIconOn className='icon-stroke-polyline-main-contrast icon__small mr-4' />
                <span className='text-2xl text-main-contrast font-bold'>{answersCount}</span>
              </span>
            </span>
            <div className='w-full h-14 mx-10'>
              <div className='h-full bg-main-10 w-0.5' />
            </div>
            <span className='flex flex-col items-center justify-between'>
              <span className='text-sm leading-[1.125rem] text-main-contrast font-normal mb-2'>
                {t('components.projects.analytics.responses.legal.viewed')}
              </span>
              <span className='flex flex-row justify-between items-center'>
                <ViewedIcon className='icon__small mr-4' />
                <span className='text-2xl text-main-contrast font-bold'>{viewedCount}</span>
              </span>
            </span>
          </div>
        ) : (
          <span className='flex flex-row items-center'>
            <CheckIconOn className='icon-success-20 icon__small mr-4' />
            <span className='text-2xl text-success-20 font-bold'>
              {t('components.projects.analytics.responses.legal.agreed')}
            </span>
          </span>
        )}
        {sectionDocument && (
          <a
            href={getStoragePublicFileUrl(sectionDocument.id)}
            target='_blank'
            rel='noreferrer'
            className='ml-10'>
            <ExternalLinkIcon className='icon-main-contrast icon-stroke-polyline-main-contrast' />
          </a>
        )}
      </div>
    </div>
  );
};
