import { useState } from 'react';

import { useTranslationContext } from '../../../services/translation';
import { VersionStatus } from '../../services/api-iteration1';

import { OptionsMenu, OptionsMenuItem, useToggle } from '../../../components';

import { RenameProjectModal } from '../modals/RenameProjectModal';
import { DeleteConfirmModal } from '../modals/DeleteConfirmModal';
import { EndTestModal } from '../modals/EndTestModal';
import { DotsButton } from './DotsButton';

interface ProjectTypeActionsButtonProps {
  projectType: string;
  onClickDelete: () => void | Promise<void>;
  onClickRename: (name: string) => void;
  onEndTest?: () => void;
  status?: VersionStatus;
}

export const ProjectTypeActionsButton = ({
  projectType,
  onClickDelete,
  onClickRename,
  onEndTest,
  status,
}: ProjectTypeActionsButtonProps) => {
  const { t } = useTranslationContext.useContext();
  const { isOpen: isMenuOpen, toggle: toggleMenuOpen } = useToggle();
  const showEndTestOption = status === VersionStatus.Live;

  const {
    isOpen: isRenameProjectModalVisible,
    open: openRenameProjectModal,
    close: closeRenameProjectModal,
  } = useToggle();

  const {
    isOpen: isDeleteConfirmModalVisible,
    open: openDeleteConfirmModal,
    close: closeDeleteConfirmModal,
  } = useToggle();

  const {
    isOpen: isEndTestModalVisible,
    open: openEndTestModal,
    close: closeEndTestModal,
  } = useToggle();
  const [endTestButtonDisabled, setEndTestButtonDisabled] = useState<boolean>(false);

  const endTest = () => {
    setEndTestButtonDisabled(true);
    onEndTest?.();
  };

  return (
    <>
      <div className='flex items-center relative mx-5' onClick={toggleMenuOpen}>
        <DotsButton hasBorder isMenuOpen={isMenuOpen} />
        {isMenuOpen && (
          <OptionsMenu id='project-card-actions' label='Project card actions'>
            <OptionsMenuItem text={t('common.rename')} onClick={openRenameProjectModal} />
            {showEndTestOption && (
              <OptionsMenuItem
                text={t('components.projects.testing.end-test')}
                onClick={openEndTestModal}
              />
            )}
            <OptionsMenuItem
              className='text-danger-60'
              text={t('common.delete')}
              onClick={openDeleteConfirmModal}
            />
          </OptionsMenu>
        )}
      </div>
      {isRenameProjectModalVisible && (
        <RenameProjectModal
          closeModal={closeRenameProjectModal}
          isOpen={isRenameProjectModalVisible}
          onClickRename={onClickRename}
          projectType={projectType}
        />
      )}
      {isDeleteConfirmModalVisible && (
        <DeleteConfirmModal
          closeDeleteConfirmModal={closeDeleteConfirmModal}
          isOpen={isDeleteConfirmModalVisible}
          onRemove={onClickDelete}
          projectType={projectType}
        />
      )}
      {isEndTestModalVisible && (
        <EndTestModal
          isOpen
          onEndTest={endTest}
          {...{ closeEndTestModal, endTestButtonDisabled }}
        />
      )}
    </>
  );
};
