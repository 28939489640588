import { useEffect, useState } from 'react';
import {
  getPermissionPlan,
  MyProjectPermission,
  PermissionPlans,
} from '@iteration1/permission-validators';

import { useTranslationContext } from '../../../../services/translation';
import { allClasses, enumToValueArray } from '../../../../services/utilities/array';
import { getKeyForCondition } from '../../../../services/utilities/object';
import {
  BillingPeriod,
  PaymentPlanOutput,
} from '../../../services/api-iteration1/billing/api-models';
import { usePermissionsContext } from '../../../services/authorization';
import { useCurrentUserContext } from '../../../services/current-user';
import {
  isSubscriptionReactivable,
  reactivateSubscription,
} from '../../../services/api-iteration1/billing';
import { useErrorHandlerContext } from '../../../../services/error-handling';
import { useProjectsCount } from '../../../services/api-iteration1';
import { useRouter } from '../../../../services/router';

import { Button, Form, FormField, Icon, ToggleField, useToggle } from '../../../../components';

import { YearlyPaymentIcon } from '../../../assets/icons';
import { PaymentPlanCard } from '../../cards';
import { PaymentPlansFeatureList } from '../../lists';
import { CancelPlanModal } from '../../modals/CancelPlanModal';
import { FormattedDate } from '../../formatters';
import { DowngradePlanAlertModal } from '../../modals/DowngradePlanAlertModal';
import { routes } from '../../../pages/routing/routes';

interface PaymentPlansProps {
  paymentPlans?: PaymentPlanOutput[];
  onSelectNewPlan: (planDetails: { plan: PermissionPlans; billingPeriod: BillingPeriod }) => void;
  onExit: () => void;
}

export const PaymentPlans = ({ paymentPlans = [], onSelectNewPlan, onExit }: PaymentPlansProps) => {
  const router = useRouter();
  const { t } = useTranslationContext.useContext();
  const { currentUser, reloadUser } = useCurrentUserContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();
  const { userPlan, willCancelSubscriptionAtPeriodEnd } = usePermissionsContext.useContext();

  const freePermissionPlan = getPermissionPlan(PermissionPlans.IndividualFree);
  const freePlanProjectCountLimit = freePermissionPlan?.[MyProjectPermission.create.id].limit;

  const basicPermissionPlan = getPermissionPlan(PermissionPlans.IndividualBasic);
  const basicPlanProjectCountLimit = basicPermissionPlan?.[MyProjectPermission.create.id].limit;

  const { data: projectsCount } = useProjectsCount();

  const {
    isOpen: isVisibleDowngradeAlertModal,
    open: openDowngradeAlertModal,
    close: closeDowngradeAlertModal,
    context: contextDowngradeModal,
  } = useToggle<{ name: string; allowedProjectsCount: number }>();

  const [billingPeriod, setBillingPeriod] = useState<BillingPeriod>('month');
  const {
    isOpen: isVisibleCancelPlan,
    open: openCancelPlanModal,
    close: closeCancelPlanModal,
  } = useToggle();

  const changeBillingPeriod = ({ period }: { period: boolean }) => {
    setBillingPeriod(period ? 'year' : 'month');
  };

  const selectPlan = (plan: PermissionPlans) => {
    onSelectNewPlan({ plan, billingPeriod });
  };

  const reactivateUserSubscription = async () => {
    try {
      await reactivateSubscription();
      onExit();
      // wait for the payment changes to be reflected in our db
      setTimeout(() => reloadUser(), 2000);
    } catch (err) {
      handleError(err);
    }
  };

  const choosePlan = (
    plan: PermissionPlans,
    context: { isActivePlan: boolean; isReactivableSubscription: boolean }
  ) => {
    const { isActivePlan, isReactivableSubscription } = context;

    const isFreePlan = plan === PermissionPlans.IndividualFree;
    const isFreeExceeded =
      isFreePlan &&
      projectsCount &&
      freePlanProjectCountLimit &&
      projectsCount > freePlanProjectCountLimit;

    const isBasicExceeded =
      plan === PermissionPlans.IndividualBasic &&
      projectsCount &&
      basicPlanProjectCountLimit &&
      projectsCount > basicPlanProjectCountLimit;

    if (isFreePlan && !isFreeExceeded) {
      openCancelPlanModal();
    } else if (isFreeExceeded || isBasicExceeded) {
      openDowngradeAlertModal({
        name: t(
          `pages.account.tabs.plan-billing.plans.${
            isFreePlan ? PermissionPlans.IndividualFree : PermissionPlans.IndividualBasic
          }.title`
        ),
        allowedProjectsCount: isFreePlan
          ? (freePlanProjectCountLimit as number)
          : (basicPlanProjectCountLimit as number),
      });
    } else if (!isActivePlan) {
      selectPlan(plan);
    } else if (isActivePlan && isReactivableSubscription) {
      reactivateUserSubscription();
    }
  };

  const redirectToProjects = () => {
    router.push(routes.projects);
  };

  useEffect(() => {
    const modalTitle = document.getElementById('modal-title') as HTMLElement;

    const handleScroll = (e: Event) => {
      const scrolledElement = e.target as HTMLDivElement | null;

      if (scrolledElement) {
        const modalHeader = scrolledElement.previousElementSibling;

        if (
          modalHeader &&
          modalTitle.getBoundingClientRect().bottom < modalHeader.getBoundingClientRect().bottom
        ) {
          modalTitle.classList.add('hidden');
          scrolledElement.classList.add('overflow-hidden');
          scrolledElement.classList.remove('overflow-y-scroll');
          document.getElementById('plan-features')?.classList.add('overflow-y-scroll');
        }
      }
    };

    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
      modalTitle.classList.remove('hidden');
    };
  }, []);

  return (
    <div
      className='flex flex-1 flex-col items-center max-w-[980px] mx-auto overflow-y-scroll scroll-container'
      id='modal-content'>
      <div className='my-10 text-center' id='modal-title'>
        <h5 className='mb-2'>{t('pages.account.tabs.plan-billing.modal.plans.title')}</h5>
        <p className='text-sm leading-5 text-neutral-70'>
          {t('pages.account.tabs.plan-billing.modal.plans.subtitle')}
        </p>
      </div>
      <div className='w-full'>
        <div className='w-full grid grid-cols-4 items-center mb-10 pt-1'>
          {/* TODO: this form should be replaced with a regular Toggle/Switch component */}
          <Form
            validateOnMount
            initialValues={{ period: false }}
            onChange={changeBillingPeriod}
            className='max-w-[180px]'>
            {() => (
              <div className='flex flex-row justify-center'>
                <span className='text-base font-regular leading-5 text-main-contrast'>
                  {t('pages.account.tabs.plan-billing.period.month')}
                </span>
                <FormField
                  component={ToggleField}
                  name='period'
                  sliderClassName='w-8 h-5 rounded-10'
                  circleClassName='w-3 h-3 bg-main rounded-full'
                  fieldClassName='p-1 py-0'
                />
                <span className='text-base font-regular leading-5 text-main-contrast relative'>
                  {t('pages.account.tabs.plan-billing.period.year')}
                  <span className='absolute -top-9 right-0 flex flex-row items-start text-info text-base leading-4'>
                    <div className='mr-2 min-w-max'>
                      {t('pages.account.tabs.plan-billing.plans.yearly-discount')}
                    </div>
                    <Icon Svg={YearlyPaymentIcon} className='icon__small' />
                  </span>
                </span>
              </div>
            )}
          </Form>
          {enumToValueArray(PermissionPlans).map((plan, index, plans) => {
            if (plan === PermissionPlans.Internal) return null;

            const currentPlanIndex = plans.indexOf(userPlan);
            const isFreePlan = plan === PermissionPlans.IndividualFree;
            const activePeriod = currentUser?.subscription?.price.recurring?.interval;
            const isCurrentPlanType = plan === userPlan;
            const isActivePlan =
              isCurrentPlanType && (isFreePlan ? true : activePeriod === billingPeriod);
            const isDifferentPeriod =
              isCurrentPlanType && !isFreePlan && activePeriod !== billingPeriod;
            const forceShowGoYearly =
              userPlan === PermissionPlans.IndividualPro &&
              activePeriod === 'month' &&
              plan === PermissionPlans.IndividualBasic &&
              billingPeriod === 'year';
            const isReactivableSubscription = isSubscriptionReactivable(currentUser?.subscription);
            const isUpgrade = index >= currentPlanIndex && !isActivePlan;
            const isDowngrade = index < currentPlanIndex;
            const currentPlan = paymentPlans.find((p) => p.name === plan);
            const currentPrice = currentPlan?.price.find(
              (p) => p.recurring?.interval === billingPeriod
            );
            const currentPriceValue = isFreePlan
              ? 0
              : billingPeriod === 'month'
              ? currentPrice?.value
              : (currentPrice?.value || 0) / 12;

            return (
              <PaymentPlanCard
                key={plan}
                name={t(`pages.account.tabs.plan-billing.plans.${plan}.title`)}
                price={currentPriceValue}
                shortDescription={t(
                  `pages.account.tabs.plan-billing.plans.${plan}.short-description-${billingPeriod}`
                )}
                discountText={
                  !isFreePlan && billingPeriod === 'year'
                    ? t(`pages.account.tabs.plan-billing.plans.${plan}.yearly-discount`)
                    : undefined
                }
                longDescription={
                  isCurrentPlanType && isReactivableSubscription ? (
                    <div className='flex-col text-main-contrast font-medium'>
                      {t('pages.account.tabs.plan-billing.additional-information-part-one')}
                      <span className='font-extra-bold'>
                        {t(`pages.account.tabs.plan-billing.plans.${userPlan}.title`)}
                      </span>
                      {t(
                        'pages.account.tabs.plan-billing.additional-information-part-two-canceled'
                      )}
                      <span className='font-extra-bold'>
                        <FormattedDate
                          seconds={currentUser?.subscription?.timeSubscriptionEnd}
                          precision='PP'
                        />
                      </span>
                      {'.'}
                    </div>
                  ) : (
                    t(`pages.account.tabs.plan-billing.plans.${plan}.long-description`)
                  )
                }
                button={
                  <Button
                    disabled={
                      (isActivePlan && !isReactivableSubscription) ||
                      (willCancelSubscriptionAtPeriodEnd && isFreePlan)
                    }
                    onClick={() => choosePlan(plan, { isActivePlan, isReactivableSubscription })}
                    disabledClassName='opacity-100'
                    className={allClasses(
                      'w-[10rem] h-10 justify-center items-center mb-[30px] font-medium',
                      getKeyForCondition({
                        'button__ghost bg-main-90 border-main-90 cursor-default':
                          isActivePlan && !isReactivableSubscription,
                        'button__filled':
                          isUpgrade ||
                          (isActivePlan && isReactivableSubscription) ||
                          forceShowGoYearly,
                        'button__filled bg-transparent border-2 border-main-10 text-danger hover:enabled:bg-transparent hover:enabled:border-danger-10 disabled:opacity-40':
                          isDowngrade,
                      })
                    )}
                    type='button'>
                    {t(
                      `pages.account.tabs.plan-billing.plans.${getKeyForCondition({
                        'current-plan': isActivePlan && !isReactivableSubscription,
                        'reactivate-plan': isActivePlan && isReactivableSubscription,
                        'change-period': isDifferentPeriod || forceShowGoYearly,
                        'upgrade-plan': isUpgrade,
                        'downgrade-plan': isDowngrade,
                      })}`,
                      {
                        period:
                          activePeriod === 'month' || forceShowGoYearly
                            ? t('pages.account.tabs.plan-billing.period.year')
                            : t('pages.account.tabs.plan-billing.period.month'),
                      }
                    )}
                  </Button>
                }
              />
            );
          })}
        </div>
        <PaymentPlansFeatureList />
      </div>

      {isVisibleCancelPlan && (
        <CancelPlanModal isOpen close={closeCancelPlanModal} onSuccess={onExit} />
      )}

      {isVisibleDowngradeAlertModal && (
        <DowngradePlanAlertModal
          isOpen
          close={closeDowngradeAlertModal}
          onRemoveProjects={redirectToProjects}
          planName={contextDowngradeModal?.name || ''}
          allowedProjectsCount={contextDowngradeModal?.allowedProjectsCount || 1}
        />
      )}
    </div>
  );
};
