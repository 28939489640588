import { allClasses } from '../../../../../../services/utilities/array';
import { getKeyForCondition } from '../../../../../../services/utilities/object';
import {
  DiscoveryRatingScaleAnswer,
  DiscoverySectionType,
} from '../../../../../services/api-iteration1';

import { Label } from './Label';

interface ViewNumericalProps {
  stepsCount: number;
  startingStep: number;
  answer: DiscoveryRatingScaleAnswer | undefined;
  onSetAnswer?: (answer: Omit<DiscoveryRatingScaleAnswer, 'duration'>) => void;
  labels?: {
    left: string;
    middle: string;
    right: string;
  };
}

const ViewNumerical = ({
  startingStep,
  stepsCount,
  answer,
  onSetAnswer,
  labels,
}: ViewNumericalProps) => {
  return (
    <>
      {[...Array(stepsCount)].map((_, index) => {
        const value = startingStep === 0 ? index : index + 1;
        const isLast = startingStep === 0 ? value === stepsCount - 1 : value === stepsCount;
        const isActive = answer ? value === answer.answer : false;

        return (
          <div
            key={value}
            className={allClasses(
              'w-full flex flex-row sm:flex-col',
              getKeyForCondition({
                'items-end sm:items-start': index === 0,
                'items-end sm:items-center': value === 3,
                'items-end': isLast,
              }),
              isLast ? 'mb-0' : 'mb-2'
            )}>
            <div
              onClick={() =>
                onSetAnswer?.({
                  type: DiscoverySectionType.RatingScale,
                  answer: value,
                })
              }
              className={allClasses(
                'relative flex flex-1 flex-col justify-center items-center border-2 border-solid p-3 rounded-lg h-[4.5rem] w-[38rem] order-2 sm:flex-none ',
                !isLast && 'mr-0 sm:mr-2',
                isActive ? 'border-main-highlight' : 'border-main-10',
                stepsCount === 11
                  ? 'sm:w-10'
                  : stepsCount === 6
                  ? 'sm:w-[5.5rem]'
                  : stepsCount === 5
                  ? 'sm:w-28'
                  : 'sm:w-11',
                onSetAnswer && 'cursor-pointer',
                'mb-0'
              )}>
              {isActive && <div className='absolute inset-0 bg-main-highlight opacity-10 z-0' />}
              <span
                className={allClasses(
                  'text-xl leading-30 text-main-contrast font-bold z-10',
                  isActive && 'border-main-highlight'
                )}>
                {value}
              </span>
            </div>
            {value === startingStep ? (
              labels && (
                <span className='mr-2 order-1 [writing-mode:vertical-lr] w-10 h-[4.5rem] sm:[writing-mode:horizontal-tb] sm:order-2 sm:h-full'>
                  <Label text={labels.left} alignment='left' />
                </span>
              )
            ) : isLast ? (
              labels && (
                <span className='mr-2 order-1 [writing-mode:vertical-lr] w-10 h-[4.5rem] sm:[writing-mode:horizontal-tb] sm:order-2 sm:h-full'>
                  <Label text={labels.right} alignment='right' />
                </span>
              )
            ) : labels ? (
              [stepsCount === 5 ? 3 : 5].includes(value) && labels ? (
                <span className='mr-2 order-1 [writing-mode:vertical-lr] w-10 h-[4.5rem] sm:[writing-mode:horizontal-tb] sm:order-2 sm:h-full'>
                  <Label text={labels.middle} alignment='center' />
                </span>
              ) : [stepsCount === 6 ? 2 : 5].includes(value) && labels ? (
                <span className='mr-2 order-1 [writing-mode:vertical-lr] w-10 h-[4.5rem] relative top-[1.75rem] sm:[writing-mode:horizontal-tb] sm:order-2 sm:h-full sm:top-0 sm:left-1/2 sm:flex sm:ml-2.5 sm:justify-end'>
                  <Label text={labels.middle} alignment='center' />
                </span>
              ) : (
                <span
                  className={allClasses(
                    'h-[4.5rem] order-1 sm:order-2',
                    labels ? 'w-12' : 'w-0'
                  )}></span>
              )
            ) : (
              <span
                className={allClasses(
                  'h-[4.5rem] order-1 sm:order-2',
                  labels ? 'w-12' : 'w-0'
                )}></span>
            )}
          </div>
        );
      })}
    </>
  );
};

export default ViewNumerical;
