import {
  DiscoveryMultipleChoiceAnswer,
  DiscoveryOpenWordAnswer,
  DiscoveryRatingScaleAnswer,
  DiscoverySection,
  DiscoverySectionMultipleChoiceOutput,
  DiscoverySectionOpenWordChoiceOutput,
  DiscoverySectionRatingScaleOutput,
  DiscoverySectionType,
  DiscoverySectionYesNoOutput,
  DiscoveryVersionResponseOutput,
  DiscoveryYesNoAnswer,
  QuestionAnswer,
  UsabilitySectionFirstClickAnswer,
  UsabilitySection,
  UsabilitySectionFirstClick,
  UsabilitySectionFiveSeconds,
  UsabilitySectionFiveSecondsAnswer,
  UsabilitySectionSingleGoal,
  UsabilitySectionType,
  UsabilitySingleGoalAnswer,
  WithDateTime,
  DiscoverySectionPreferenceOutput,
  DiscoveryPreferenceAnswer,
  DiscoverySectionLegalOutput,
  DiscoveryLegalAnswer,
} from '../../../services/api-iteration1';
import { UserTestVersionResponseOutput } from '../../../services/api-iteration1/projects/userTest/responses';
import { FigmaDesignPrototypeVersion } from '../../../services/api-iteration1/design-platforms';

import { AnalyticsSectionList } from '../../lists';
import {
  AnalyticsFiveSecondsSummary,
  AnalyticsMultipleChoiceSummary,
  AnalyticsOpenWordSummary,
  AnalyticsRatingScaleSummary,
  AnalyticsSingleGoalSummary,
  AnalyticsYesNoSummary,
} from './summaries';
import { AnalyticsSummaryOverview } from './AnalyticsSummaryOverview';
import { AnalyticsFirstClickSummary } from './summaries/AnalyticsFirstClickSummary';
import { AnalyticsPreferenceTestSummary } from './summaries/AnalyticsPreferenceTestSummary';
import { AnalyticsLegalSummary } from './summaries/AnalyticsLegalSummary';

interface AnalyticsSummaryProps {
  sections: (DiscoverySection | UsabilitySection)[];
  inFocusResponseId: string;
  versionName?: string;
  responses: (DiscoveryVersionResponseOutput | UserTestVersionResponseOutput)[];
  invalidResponsesCount: number;
  averageDuration: number;
  completionRate: number;
  designPrototype?: FigmaDesignPrototypeVersion;
  isLoadingResponses: boolean;
  setSectionInFocus: (sectionId: string) => void;
}

export const AnalyticsSummary = ({
  sections,
  inFocusResponseId,
  versionName,
  responses,
  invalidResponsesCount,
  averageDuration,
  completionRate,
  designPrototype,
  isLoadingResponses,
  setSectionInFocus,
}: AnalyticsSummaryProps) => {
  const sectionInFocus = sections?.find((item) => item._id === inFocusResponseId);

  const sectionAnswers = responses?.reduce((result, response) => {
    Object.entries(response.answers).forEach(([answerKey, answerValue]) => {
      if (answerKey === sectionInFocus?._id)
        result.push({ ...answerValue, createdAt: response.createdAt });
    });

    return result;
  }, [] as WithDateTime<QuestionAnswer>[]);

  return (
    <>
      <AnalyticsSectionList
        sections={sections}
        versionName={versionName}
        totalResponsesCount={responses.length}
        inFocusSectionId={inFocusResponseId}
        {...{ setSectionInFocus }}
      />
      <div className='scroll-container flex flex-col items-center w-full overflow-y-auto pt-16 pb-5'>
        {(() => {
          switch (sectionInFocus?.type) {
            case DiscoverySectionType.YesNo:
              return (
                <AnalyticsYesNoSummary
                  section={sectionInFocus as DiscoverySectionYesNoOutput}
                  answers={sectionAnswers as WithDateTime<DiscoveryYesNoAnswer>[]}
                  sectionsCount={sections.length}
                  {...{ isLoadingResponses }}
                />
              );
            case DiscoverySectionType.RatingScale:
              return (
                <AnalyticsRatingScaleSummary
                  section={sectionInFocus as DiscoverySectionRatingScaleOutput}
                  answers={sectionAnswers as WithDateTime<DiscoveryRatingScaleAnswer>[]}
                  sectionsCount={sections.length}
                  {...{ isLoadingResponses }}
                />
              );
            case DiscoverySectionType.MultipleChoice:
              return (
                <AnalyticsMultipleChoiceSummary
                  section={sectionInFocus as DiscoverySectionMultipleChoiceOutput}
                  answers={sectionAnswers as WithDateTime<DiscoveryMultipleChoiceAnswer>[]}
                  sectionsCount={sections.length}
                  {...{ isLoadingResponses }}
                />
              );
            case DiscoverySectionType.OpenWordChoice:
              return (
                <AnalyticsOpenWordSummary
                  section={sectionInFocus as DiscoverySectionOpenWordChoiceOutput}
                  answers={sectionAnswers as WithDateTime<DiscoveryOpenWordAnswer>[]}
                  sectionsCount={sections.length}
                  {...{ isLoadingResponses }}
                />
              );
            case UsabilitySectionType.SingleGoal:
              return (
                <AnalyticsSingleGoalSummary
                  section={sectionInFocus as UsabilitySectionSingleGoal}
                  answers={sectionAnswers as WithDateTime<UsabilitySingleGoalAnswer>[]}
                  sectionsCount={sections.length}
                  {...{ versionName, designPrototype, isLoadingResponses }}
                />
              );
            case UsabilitySectionType.FiveSeconds:
              return (
                <AnalyticsFiveSecondsSummary
                  section={sectionInFocus as UsabilitySectionFiveSeconds}
                  answers={sectionAnswers as WithDateTime<UsabilitySectionFiveSecondsAnswer>[]}
                  sectionsCount={sections.length}
                  {...{ versionName, designPrototype, isLoadingResponses }}
                />
              );
            case UsabilitySectionType.FirstClick:
              return (
                <AnalyticsFirstClickSummary
                  section={sectionInFocus as UsabilitySectionFirstClick}
                  answers={sectionAnswers as WithDateTime<UsabilitySectionFirstClickAnswer>[]}
                  sectionsCount={sections.length}
                  {...{ versionName, designPrototype, isLoadingResponses }}
                />
              );
            case DiscoverySectionType.Preference:
              return (
                <AnalyticsPreferenceTestSummary
                  section={sectionInFocus as DiscoverySectionPreferenceOutput}
                  answers={sectionAnswers as WithDateTime<DiscoveryPreferenceAnswer>[]}
                  sectionsCount={sections.length}
                  {...{ isLoadingResponses }}
                />
              );
            case DiscoverySectionType.Legal:
              return (
                <AnalyticsLegalSummary
                  section={sectionInFocus as DiscoverySectionLegalOutput}
                  answers={sectionAnswers as WithDateTime<DiscoveryLegalAnswer>[]}
                  sectionsCount={sections.length}
                  {...{ isLoadingResponses }}
                />
              );
            default:
              return (
                <AnalyticsSummaryOverview
                  sectionsCount={sections.length}
                  responsesCount={responses.length}
                  invalidResponsesCount={invalidResponsesCount}
                  lastUpdate={responses.length ? responses[0].updatedAt : undefined}
                  {...{ averageDuration, completionRate, isLoadingResponses }}
                />
              );
          }
        })()}
      </div>
    </>
  );
};
