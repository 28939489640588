export type SectionPropertyOthers<T = never> = Record<
  string,
  | T
  | string
  | number
  | boolean
  | undefined
  | Record<string, string>
  | (T | string | number | boolean | undefined)[]
>;

export type SectionFactory<
  Section extends { index: number },
  T extends Record<string, any> = Record<string, unknown>
> = (index: number, other: T) => Omit<Section, '_id'>;

export const temporarySectionId = 'temp-section-id';

export const isTemporarySection = (id: string) => id === temporarySectionId;

export const temporaryFileId = 'temp-file-id';

export const isTemporaryFile = (fileId: string) => fileId.startsWith(temporaryFileId);

export const createIndexedTemporaryFile = (index: string) => `${temporaryFileId}_${index}`;
