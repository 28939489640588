import { ResourceCollaborator } from '../../collaborators/api-models';
import { DesignPlatform } from '../../design-platforms';
import { FolderParentOutput } from '../../folders';

export enum UserTestTemplate {
  Blank = 'blank',
  SingleGoal = 'singleGoal',
  MultipleGoals = 'multipleGoals',
  FreePlay = 'freePlay',
  FirstClick = 'firstClick',
  FiveSeconds = '5seconds',
}

export interface UserTestDesignPrototype {
  url: string;
  type: DesignPlatform;
  id: string;
  params: Record<string, string>;
}

export interface UserTestOutput {
  id: string;
  name: string;
  ownerId: string;
  parents: FolderParentOutput[];
  template: UserTestTemplate;
  designPrototype: UserTestDesignPrototype;
  collaborators?: ResourceCollaborator[];
  createdAt: Date;
  updatedAt: Date;
}

export interface NewUserTestInput {
  name: string;
  template: UserTestTemplate;
  designPrototype: {
    url: string;
    type: 'figma';
  };
  parents: FolderParentOutput[];
}

export interface UserTestUpdate {
  name?: string;
}

export interface UserTestVersionUpdate {
  name?: string;
}
