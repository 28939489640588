import { useMemo, useState } from 'react';

import { isRequired } from '../../../../services/validation';
import { useTranslationContext } from '../../../../../services/translation';
import {
  DiscoverySectionMultipleChoiceOutput,
  DiscoverySectionInput,
  DiscoverySectionType,
  SectionFileUploadDetails,
  SectionFileRemovalDetails,
  isTemporarySection,
} from '../../../../services/api-iteration1';
import { allClasses } from '../../../../../services/utilities/array';
import { useDynamicInputs } from '../../../../services/interaction';
import { isNullOrUndefined } from '../../../../../services/utilities/value';
import { useErrorHandlerContext } from '../../../../../services/error-handling';
import { useSectionsListContext } from '../../../../services/sections/useSectionsList';
import { useActionInProgressContext } from '../../../../../services/progress';
import {
  getStoragePublicFileUrl,
  imageFileSizeLimit,
} from '../../../../services/api-cloud-storage';
import { StorageFileType } from '../../../../services/api-cloud-storage/api-models';

import { Form, FormError, FormField, ToggleField, UploadImage } from '../../../../../components';
import { RadioGroup } from '../../../../../components/radio-group/RadioGroup';

import { IconsIcon, MultipleChoiceIcon, RadioButtonIcon } from '../../../../assets/icons';
import { SelectSectionItem } from '../../../containers';

import styles from './Section.module.css';

interface SectionFormValues {
  title: string;
  required?: boolean;
  description: string;
  type: DiscoverySectionMultipleChoiceOutput['others']['type'];
  customEnabled: boolean;
  hasImage: boolean;
  [choice: string]: string | undefined | boolean; // boolean is here in order to allow above props of type boolean
}

const dynamicInputsPrefix = 'choice_';

interface EditSectionMultipleChoiceProps {
  section: DiscoverySectionMultipleChoiceOutput;
  editSection: (
    sectionId: string,
    updates: DiscoverySectionInput,
    shouldUpdate?: boolean
  ) => Promise<void> | undefined;
  uploadSectionFile: (sectionId: string, fileDetails: SectionFileUploadDetails) => Promise<void>;
  removeSectionFile: (sectionId: string, fileDetails: SectionFileRemovalDetails) => Promise<void>;
}

interface SectionMultipleChoiceProps extends EditSectionMultipleChoiceProps {
  index: number;
  isInvalid: boolean;
  removeSection: (sectionId: string) => Promise<void> | undefined;
  duplicateSection: ((sectionId: string) => Promise<void> | undefined) | (() => void);
}

export const SectionMultipleChoice = ({
  section,
  isInvalid,
  index,
  editSection,
  uploadSectionFile,
  removeSectionFile,
  removeSection,
  duplicateSection,
}: SectionMultipleChoiceProps) => {
  const { t } = useTranslationContext.useContext();
  const {
    inFocusSectionId,
    setSectionInFocus,
    resetSectionInFocus: collapseSection,
  } = useSectionsListContext.useContext();

  const { title, _id, required } = section;

  const isLoading = isTemporarySection(_id);
  const isExpanded = inFocusSectionId === _id && !isLoading;
  const expandSection = () => setSectionInFocus(_id);

  return (
    <SelectSectionItem
      alreadyAdded
      draggableItemId={_id}
      title={title || t('components.projects.sections.section-title-placeholder')}
      description={t('components.projects.sections.field-types.multipleChoice')}
      icon={<MultipleChoiceIcon className='icon__small' />}
      onClick={isExpanded ? collapseSection : expandSection}
      type={DiscoverySectionType.MultipleChoice}
      onDelete={(e) => {
        e.stopPropagation();
        removeSection(_id);
      }}
      onDuplicate={(e) => {
        e.stopPropagation();
        duplicateSection?.(_id);
      }}
      showRequiredIcon={required}
      {...{ isExpanded, isInvalid, isLoading, index }}>
      <EditSectionMultipleChoice
        {...{ section, editSection, uploadSectionFile, removeSectionFile }}
      />
    </SelectSectionItem>
  );
};

const EditSectionMultipleChoice = ({
  section,
  editSection,
  uploadSectionFile,
  removeSectionFile,
}: EditSectionMultipleChoiceProps) => {
  const { t } = useTranslationContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();
  const { reflectUploadingStatus } = useActionInProgressContext.useContext();

  const { title, _id, files, others, required } = section;
  const { description, type, choices, customEnabled } = others;

  const sectionImage = files.find((f) => f.type === StorageFileType.SectionImage);
  const imageUrl = sectionImage && getStoragePublicFileUrl(sectionImage.id);

  const [hasImage, setHasImage] = useState(!!sectionImage);

  const {
    inputs: choiceInputs,
    prefixedInputs: choiceFields,
    autoFocusInputKey: autoFocusChoiceKey,
    saveInput: saveChoiceField,
    editInputCount: editChoicesCount,
  } = useDynamicInputs<string>({
    inputPrefix: dynamicInputsPrefix,
    initialValues: choices,
    defaultValue: '',
  });

  const updateSectionValues = (values: SectionFormValues) => {
    if (!_id) return;

    const shouldRemoveSectionImage = !values.hasImage && sectionImage;
    if (shouldRemoveSectionImage)
      removeSectionFile(_id, { id: sectionImage.id, type: StorageFileType.SectionImage }).catch(
        handleError
      );

    const updates = {
      title: values.title,
      others: {
        description: values.description,
        choices: choiceInputs
          .map((choice) => choice.value)
          .filter((choice) => !isNullOrUndefined(choice)),
        type: values.type,
        customEnabled: values.customEnabled,
      },
      required: values.required,
      ...(shouldRemoveSectionImage ? { files: files.filter((f) => f.id !== sectionImage.id) } : {}),
    };

    editSection(_id, updates, !isTemporarySection(_id));
  };

  const initialValues: SectionFormValues = useMemo(
    () => ({
      title: title,
      description: description || '',
      required,
      type: type || '',
      customEnabled: !!customEnabled,
      hasImage: !!sectionImage || hasImage,
      ...choiceFields,
    }),
    [title, description, required, type, customEnabled, choiceFields, sectionImage, hasImage]
  );

  return (
    <>
      <hr className={allClasses(styles.section_hr, 'w-11/12')} />
      <Form
        validateOnMount
        enableReinitialize
        enableChangeOnInputCountChange
        onChange={updateSectionValues}
        className='px-5'
        {...{ initialValues }}>
        {({ status, values, errors, touched }) => (
          <>
            <FormError {...{ status }} />
            <FormField
              autoFocus
              type='text'
              name='title'
              label={`${t('components.projects.sections.multiple-choice.edit.title.label')}*`}
              placeholder={t('components.projects.sections.multiple-choice.edit.title.placeholder')}
              validate={isRequired}
              fieldClassName='mb-5'
              inputClassName={allClasses(
                styles.section_input,
                errors.title && touched.title && styles.section_input__invalid
              )}
              labelClassName={styles.section_label}
            />
            <FormField
              type='text'
              name='description'
              label={t('components.projects.sections.multiple-choice.edit.description.label')}
              placeholder={t(
                'components.projects.sections.multiple-choice.edit.description.placeholder'
              )}
              fieldClassName='mb-5'
              inputClassName={styles.section_input}
              labelClassName={styles.section_label}
            />
            <p className={styles.section_label}>{`${t(
              'components.projects.sections.choice_plural'
            )}*`}</p>
            {Object.entries(values)
              .filter(([key]) => key.startsWith(dynamicInputsPrefix))
              .map(([key, value], index: number) => {
                const isFirst = index === 0;
                const shouldAutoFocus = autoFocusChoiceKey.current === key;
                const keyForEnablingAutoFocus = `${key}_${shouldAutoFocus}`;

                return (
                  <FormField
                    key={keyForEnablingAutoFocus}
                    autoFocus={shouldAutoFocus}
                    type='text'
                    name={key}
                    placeholder={`${t('components.projects.sections.multiple-choice.choice')} ${
                      index + 1
                    }`}
                    value={value}
                    validate={isRequired}
                    fieldClassName='mb-2'
                    inputClassName={allClasses(
                      styles.section_input,
                      errors[key] && touched[key] && styles.section_input__invalid
                    )}
                    labelClassName={styles.section_label}
                    onKeyDown={(e: KeyboardEvent) => {
                      editChoicesCount(e, { index, isRemovable: !isFirst });
                    }}
                    onChange={(e) => {
                      saveChoiceField(index, e.target.value);
                    }}
                  />
                );
              })}
            <span className='block mb-5 text-neutral text-xs leading-none'>
              {t('components.projects.sections.multiple-choice.edit.add-choice')}
            </span>
            <div className='mb-6'>
              <FormField
                component={RadioGroup}
                label={`${t('components.projects.sections.selection-type')}*`}
                name='type'
                options={[
                  {
                    label: t('common.multiple'),
                    value: 'multiple',
                    icons: {
                      selected: (
                        <IconsIcon className='icon__small icon-main-contrast icon-stroke-polyline-main-contrast' />
                      ),
                      notSelected: <IconsIcon className='icon__small' />,
                    },
                  },
                  {
                    label: t('common.single'),
                    value: 'single',
                    icons: {
                      selected: (
                        <RadioButtonIcon className='icon__small icon-stroke-circle-main-contrast icon-fill-circle-main-contrast' />
                      ),
                      notSelected: <RadioButtonIcon className='icon__small' />,
                    },
                  },
                ]}
                checkedWrapperClassName='border-main-highlight'
                checkedTextClassName='text-main-contrast'
                labelClassName={styles.section_label}
                checked={values.type}
                wrapperClassName='grid-cols-2'
              />
            </div>
            <hr className={styles.section_hr} />
            <div className={`${styles.section_toggle_wrapper} mb-5 py-2.5`}>
              <span>{t('components.projects.sections.required-section')}</span>
              <FormField
                component={ToggleField}
                name='required'
                sliderClassName={styles.section_toggle_slider_wrapper}
                circleClassName={styles.section_toggle_slider_circle}
                id='multiple_choice_required'
              />
            </div>
            <div className={allClasses(styles.section_toggle_wrapper, 'mb-5')}>
              <span>{t('components.projects.sections.image')}</span>
              <FormField
                component={ToggleField}
                name='hasImage'
                sliderClassName={styles.section_toggle_slider_wrapper}
                circleClassName={styles.section_toggle_slider_circle}
                onChange={() => setHasImage(!values.hasImage)}
              />
            </div>
            {values.hasImage && (
              <UploadImage
                excludeFromForm
                fileSizeLimit={imageFileSizeLimit}
                title={t('components.upload.image.title')}
                subtitle={t('components.upload.image.subtitle')}
                placeholderImgSrc={imageUrl}
                upload={(image) =>
                  uploadSectionFile(_id, { file: image, type: StorageFileType.SectionImage })
                }
                onUploadError={handleError}
                remove={async () => {
                  if (!sectionImage) return;
                  await removeSectionFile(_id, {
                    id: sectionImage.id,
                    type: StorageFileType.SectionImage,
                  });
                }}
                onRemoveSuccess={() => setHasImage(true)}
                onRemoveError={handleError}
                onLoadingStatusChange={reflectUploadingStatus}
                className='mb-4'
                fileName={sectionImage?.fileName}
              />
            )}
            <div className={allClasses(styles.section_toggle_wrapper, 'mb-5')}>
              <span>{t('components.projects.sections.custom-answer')}</span>
              <FormField
                component={ToggleField}
                name='customEnabled'
                sliderClassName={styles.section_toggle_slider_wrapper}
                circleClassName={styles.section_toggle_slider_circle}
                id='multiple_choice_customEnabled'
              />
            </div>
          </>
        )}
      </Form>
    </>
  );
};
