import { useCallback } from 'react';

import { getFromStorage, removeFromStorage, saveToStorage } from './methods';

export const useLocalStorage = <T = any>(key: string) => {
  const getLocalCopy = useCallback((): T | null => getFromStorage(key), [key]);

  const saveLocalCopy = useCallback((value: T) => saveToStorage(key, value), [key]);

  const deleteLocalCopy = useCallback(() => removeFromStorage(key), [key]);

  return { getLocalCopy, saveLocalCopy, deleteLocalCopy };
};
