import { useCurrentUserContext } from '../services/current-user';
import { useTranslationContext } from '../../services/translation';

import { NoFooterLayout, SignUpForm } from '../components';

import { routes } from './routing/routes';

export const SignUp = () => {
  const { isAuthenticated } = useCurrentUserContext.useContext();
  const { t } = useTranslationContext.useContext();

  return (
    <NoFooterLayout
      requiresAuthentication={false}
      canAccessPage={!isAuthenticated}
      redirectUrl={routes.home}
      analyticsName='Sign up'
      title={t('pages.sign-up.title')}
      availableOnMobile={false}>
      <SignUpForm />
    </NoFooterLayout>
  );
};
