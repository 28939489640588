import { useMemo } from 'react';

import { useTranslationContext } from '../../../../../services/translation';
import { isRequired } from '../../../../services/validation';
import { allClasses } from '../../../../../services/utilities/array';
import { useErrorHandlerContext } from '../../../../../services/error-handling';
import { useSectionsListContext } from '../../../../services/sections/useSectionsList';
import {
  DiscoverySectionInput,
  DiscoverySectionType,
  SectionFileRemovalDetails,
  SectionFileUploadDetails,
  isTemporarySection,
  DiscoverySectionLegalOutput,
} from '../../../../services/api-iteration1';
import { useActionInProgressContext } from '../../../../../services/progress';
import {
  getStoragePublicFileUrl,
  documentFileSizeLimit,
} from '../../../../services/api-cloud-storage';
import { StorageFileType } from '../../../../services/api-cloud-storage/api-models';

import { Form, FormError, FormField } from '../../../../../components';
import { UploadDocument } from '../../../../../components/upload/UploadDocument';

import { LegalIcon } from '../../../../assets/icons';
import { SelectSectionItem } from '../../../containers';

import styles from './Section.module.css';

interface SectionFormValues {
  title: string;
  required?: boolean;
  description: string;
  message: string;
}

interface SectionLegalProps {
  section: DiscoverySectionLegalOutput;
  index: number;
  isInvalid: boolean;
  editSection: (
    sectionId: string,
    updates: DiscoverySectionInput,
    shouldUpdate?: boolean
  ) => Promise<void> | undefined;
  uploadSectionFile: (sectionId: string, fileDetails: SectionFileUploadDetails) => Promise<void>;
  removeSectionFile: (sectionId: string, fileDetails: SectionFileRemovalDetails) => Promise<void>;
  removeSection: (sectionId: string) => Promise<void> | undefined;
  duplicateSection: ((sectionId: string) => Promise<void> | undefined) | (() => void);
}

export const SectionLegal = ({
  section,
  isInvalid,
  index,
  editSection,
  uploadSectionFile,
  removeSectionFile,
  removeSection,
  duplicateSection,
}: SectionLegalProps) => {
  const { t } = useTranslationContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();
  const { reflectUploadingStatus } = useActionInProgressContext.useContext();
  const {
    inFocusSectionId,
    setSectionInFocus,
    resetSectionInFocus: collapseSection,
  } = useSectionsListContext.useContext();

  const { title, _id, files, others, required } = section;
  const { description, message } = others;

  const sectionDocument = files.find((f) => f.type === StorageFileType.LegalDocument);
  const documentUrl = sectionDocument && getStoragePublicFileUrl(sectionDocument.id);

  const isLoading = isTemporarySection(_id);
  const isExpanded = inFocusSectionId === _id && !isLoading;
  const expandSection = () => setSectionInFocus(_id);

  const updateSectionValues = (values: SectionFormValues) => {
    if (!_id) return;

    const shouldRemoveSectionDocument = !!sectionDocument;
    if (shouldRemoveSectionDocument)
      removeSectionFile(_id, { id: sectionDocument.id, type: StorageFileType.LegalDocument }).catch(
        handleError
      );
    const updates = {
      title: values.title,
      others: {
        description: values.description,
        message: values.message,
      },
      required: true,
      ...(shouldRemoveSectionDocument
        ? { files: files.filter((f) => f.id !== sectionDocument?.id) }
        : {}),
    };

    editSection(_id, updates, !isTemporarySection(_id));
  };

  const initialValues = useMemo(
    () => ({
      title: title,
      description: description || '',
      required: !!required,
      message: message || '',
    }),
    [title, description, required, message]
  );

  return (
    <SelectSectionItem
      alreadyAdded
      draggableItemId={_id}
      title={title || t('components.projects.sections.section-title-placeholder')}
      description={t('components.projects.sections.field-types.legal')}
      icon={<LegalIcon className='icon__small' />}
      onClick={isExpanded ? collapseSection : expandSection}
      type={DiscoverySectionType.Legal}
      onDelete={(e) => {
        e.stopPropagation();
        removeSection(_id);
      }}
      onDuplicate={(e) => {
        e.stopPropagation();
        duplicateSection?.(_id);
      }}
      showRequiredIcon={required}
      {...{ isExpanded, isInvalid, isLoading, index }}>
      <>
        <hr className={allClasses(styles.section_hr, 'w-11/12')} />
        <Form
          validateOnMount
          enableReinitialize
          initialValues={initialValues}
          onChange={updateSectionValues}
          className='px-5'>
          {({ status, errors, touched }) => (
            <>
              <FormError {...{ status }} />
              <FormField
                autoFocus
                type='text'
                name='title'
                label={`${t('components.projects.sections.legal.edit.title.label')}*`}
                placeholder={t('components.projects.sections.legal.edit.title.placeholder')}
                validate={isRequired}
                fieldClassName='mb-5'
                inputClassName={allClasses(
                  styles.section_input,
                  errors.title && touched.title && styles.section_input__invalid
                )}
                labelClassName={styles.section_label}
              />
              <FormField
                type='text'
                name='description'
                label={t('components.projects.sections.legal.edit.description.label')}
                placeholder={t('components.projects.sections.legal.edit.description.placeholder')}
                fieldClassName='mb-5'
                inputClassName={styles.section_input}
                labelClassName={styles.section_label}
              />
              <FormField
                type='text'
                name='message'
                label={t('components.projects.sections.legal.edit.agreement.label')}
                placeholder={t('components.projects.sections.legal.edit.agreement.placeholder')}
                fieldClassName='mb-5'
                labelClassName={styles.section_label}
                inputClassName={allClasses(
                  styles.section_input,
                  errors.message && touched.message && styles.section_input__invalid
                )}
                validate={isRequired}
              />
              <p className={styles.section_label}>
                {t('components.projects.sections.legal.edit.document')}
              </p>
              <UploadDocument
                required
                excludeFromForm
                fileSizeLimit={documentFileSizeLimit}
                title={t('components.upload.document.title')}
                subtitle={t('components.upload.document.subtitle')}
                placeholderDocSrc={documentUrl}
                upload={(document) =>
                  uploadSectionFile(_id, { file: document, type: StorageFileType.LegalDocument })
                }
                onUploadError={handleError}
                remove={async () => {
                  if (!sectionDocument) return;
                  await removeSectionFile(_id, {
                    id: sectionDocument.id,
                    type: StorageFileType.LegalDocument,
                  });
                }}
                onRemoveError={handleError}
                onLoadingStatusChange={reflectUploadingStatus}
                className='mb-4'
                fileName={sectionDocument?.fileName}
              />
            </>
          )}
        </Form>
      </>
    </SelectSectionItem>
  );
};
