import { StorageFile, StorageFileType } from '../../../../api-cloud-storage/api-models';
import { SectionPropertyOthers } from './utils';

export interface SectionStorageFile extends StorageFile {
  type: StorageFileType;
  indexId?: string;
}

export interface SectionStorageMultipleFile extends SectionStorageFile {
  indexId: string;
}

export type SectionFileRemovalDetails = { id: string; type: StorageFileType; indexId?: string };
export type SectionFileUploadDetails = { file: File; type: StorageFileType; indexId?: string };

export enum DefaultSectionType {
  Welcome = 'welcome',
  ThankYou = 'thankYou',
}

export interface DefaultSection {
  _id: string;
  title: string;
  type: DefaultSectionType;
  files: SectionStorageFile[];
  others?: SectionPropertyOthers;
  index: undefined;
  required: undefined;
}

export interface DefaultSectionWelcomeOutput extends DefaultSection {
  type: DefaultSectionType.Welcome;
  others: {
    steps?: string[];
    message: string;
    disabled?: boolean;
  };
}

export interface DefaultSectionThankYouOutput extends DefaultSection {
  type: DefaultSectionType.ThankYou;
  others: {
    url?: string;
    message: string;
    disabled?: boolean;
  };
}
