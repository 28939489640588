import { useTranslationContext } from '../../../services/translation';
import { useSectionsListContext } from '../../services/sections/useSectionsList';

import { PlusSignIcon } from '../../assets/icons';

interface SelectSectionListProps {
  sectionsList: React.ReactNode;
}

export const SelectSectionList = ({ sectionsList }: SelectSectionListProps) => {
  const { t } = useTranslationContext.useContext();
  const { shouldShowSectionsList, showSectionsList } = useSectionsListContext.useContext();

  return (
    <div className='mb-5'>
      {shouldShowSectionsList ? (
        <>
          <div
            className='flex flex-row justify-start items-center p-5 border-2 border-solid border-main-highlight border-b-0 rounded-lg rounded-br-none rounded-bl-none cursor-pointer hover:border-main-highlight'
            onClick={showSectionsList}>
            <PlusSignIcon className='mr-5' />
            <span className='text-lg leading-5 text-main-contrast font-bold'>
              {t('components.projects.sections.new-section')}
            </span>
          </div>
          <div className='flex flex-col justify-start items-center w-full mb-2 border-2 border-main-highlight border-solid rounded-b-lg rounded-tr-none rounded-tl-none cursor-pointer'>
            {sectionsList}
          </div>
        </>
      ) : (
        <>
          <div
            className='flex flex-row justify-start items-center mb-5 p-5 border-2 border-dashed border-main-10 rounded-lg cursor-pointer hover:border-main-highlight'
            onClick={showSectionsList}>
            <PlusSignIcon className='mr-5' />
            <span className='text-lg leading-5 text-main-contrast font-bold'>
              {t('components.projects.sections.new-section')}
            </span>
          </div>
        </>
      )}
    </div>
  );
};
