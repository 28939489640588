import { Button } from '../../../../components';

import { PlanLimitReachedIllustration } from '../../../assets/icons';

interface CollaboratorsUpgradePlanProps {
  title: string;
  description: string;
  buttonText: string;
  learnMoreUrl: string;
  onClick: () => void;
}

export const CollaboratorsUpgradePlan = ({
  title,
  description,
  buttonText,
  onClick,
}: CollaboratorsUpgradePlanProps) => {
  return (
    <div className='flex flex-col justify-center items-center text-center mt-10'>
      <span className='flex items-center justify-center mb-5'>
        <PlanLimitReachedIllustration />
      </span>
      <div className='flex flex-col max-w-[25rem] mb-14'>
        <p className='text-xl text-main-contrast leading-30 font-bold mb-2.5'>{title}</p>
        <p className='text-lg text-main-contrast leading-6'>{description}</p>
      </div>
      {/* <InfoLearnMore url={learnMoreUrl} className='p-2.5 mb-5' /> */}
      <Button className='button__filled px-10 mb-20' type='button' {...{ onClick }}>
        <span className='text-main-contrast leading-5 text-base font-medium'>{buttonText}</span>
      </Button>
    </div>
  );
};
