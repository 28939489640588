import { useTranslationContext } from '../../../services/translation';

import { Button, Modal } from '../../../components';

import { CloseIcon, HelloIllustration } from '../../assets/icons';

interface WelcomeModalProps {
  onExit: () => void;
}

export const WelcomeModal = ({ onExit }: WelcomeModalProps) => {
  const { t } = useTranslationContext.useContext();

  return (
    <Modal
      isOpen
      title={t('pages.homepage.welcome-modal.title')}
      onClose={onExit}
      variant='regular'
      closeModalComponent={<CloseIcon className='icon-hover-main-contrast' />}
      closeIconClassName='absolute top-5 right-5'>
      <div className='flex flex-col pt-10 pb-20 items-center text-center'>
        <HelloIllustration className='w-auto h-28' />
        <h2 className='my-10 text-main-contrast leading-8 text-2xl font-bold'>
          {t('pages.homepage.welcome-modal.title')}
        </h2>
        <div className='max-w-xs mx-auto mb-10 text-main-contrast text-lg leading-6 font-medium'>
          {t('pages.homepage.welcome-modal.description')}
        </div>
        <div className='flex flex-row items-center justify-center'>
          <Button
            className='button__filled font-medium mr-4 py-2.5 px-10 justify-center'
            type='button'
            onClick={onExit}>
            {t('common.continue')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
