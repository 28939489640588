import { useMemo, useState } from 'react';

import { useTranslationContext } from '../../../../../services/translation';
import { isRequired } from '../../../../services/validation';
import {
  DefaultSectionWelcomeOutput,
  DiscoverySectionInput,
  SectionFileRemovalDetails,
  SectionFileUploadDetails,
} from '../../../../services/api-iteration1';
import { useDynamicInputs } from '../../../../services/interaction';
import { isNullOrUndefined } from '../../../../../services/utilities/value';
import { allClasses } from '../../../../../services/utilities/array';
import { useErrorHandlerContext } from '../../../../../services/error-handling';
import { useSectionsListContext } from '../../../../services/sections/useSectionsList';
import { useActionInProgressContext } from '../../../../../services/progress';
import {
  getStoragePublicFileUrl,
  imageFileSizeLimit,
} from '../../../../services/api-cloud-storage';
import { StorageFileType } from '../../../../services/api-cloud-storage/api-models';

import { Form, FormError, FormField, ToggleField, UploadImage } from '../../../../../components';

import { PlayIcon } from '../../../../assets/icons';
import { SelectSectionItem } from '../../../containers';

import styles from './Section.module.css';

interface SectionFormValues {
  title: string;
  message: string;
  hasImage: boolean;
  hasSteps: boolean;
  [step: string]: string | undefined | boolean; // boolean is here in order to allow above props of type boolean
}

const dynamicInputsPrefix = 'step_';

interface SectionWelcomeScreenProps {
  section: DefaultSectionWelcomeOutput;
  isInvalid?: boolean;
  editSection: (
    sectionId: string,
    updates: DiscoverySectionInput,
    shouldUpdate?: boolean
  ) => Promise<void> | undefined;
  uploadSectionFile: (sectionId: string, fileDetails: SectionFileUploadDetails) => Promise<void>;
  removeSectionFile: (sectionId: string, fileDetails: SectionFileRemovalDetails) => Promise<void>;
}

export const SectionWelcomeScreen = ({
  section,
  isInvalid,
  editSection,
  uploadSectionFile,
  removeSectionFile,
}: SectionWelcomeScreenProps) => {
  const { t } = useTranslationContext.useContext();
  const {
    inFocusSectionId,
    setSectionInFocus,
    resetSectionInFocus: collapseSection,
  } = useSectionsListContext.useContext();

  const { _id } = section;

  const isExpanded = inFocusSectionId === _id;
  const expandSection = () => setSectionInFocus(_id);

  return (
    <SelectSectionItem
      alreadyAdded
      title={t('components.projects.sections.field-types.welcome')}
      icon={<PlayIcon className='icon__small' />}
      onClick={isExpanded ? collapseSection : expandSection}
      containerClassName='mt-16'
      disabled={section.others.disabled}
      onDisable={(disabled) => {
        editSection(_id, { others: { ...section.others, disabled } });
      }}
      {...{ isExpanded, isInvalid }}>
      <EditSectionWelcomeScreen
        {...{
          section,
          editSection,
          uploadSectionFile,
          removeSectionFile,
        }}
      />
    </SelectSectionItem>
  );
};

const EditSectionWelcomeScreen = ({
  section,
  editSection,
  uploadSectionFile,
  removeSectionFile,
}: SectionWelcomeScreenProps) => {
  const { t } = useTranslationContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();
  const { reflectUploadingStatus } = useActionInProgressContext.useContext();

  const { _id, title, files, others } = section;

  const { message, steps } = others;

  const sectionImage = files.find((f) => f.type === StorageFileType.SectionImage);
  const imageUrl = sectionImage && getStoragePublicFileUrl(sectionImage.id);

  const [hasImage, setHasImage] = useState(!!sectionImage);
  const [hasSteps, setHasSteps] = useState(steps && steps.length > 0 ? true : false);

  const {
    inputs: stepInputs,
    prefixedInputs: stepFields,
    autoFocusInputKey: autoFocusStepKey,
    saveInput: saveStepField,
    editInputCount: editStepsCount,
  } = useDynamicInputs<string>({
    inputPrefix: dynamicInputsPrefix,
    initialValues:
      steps && steps.length > 0
        ? steps
        : [
            t('components.projects.sections.welcome.default-steps.step1'),
            t('components.projects.sections.welcome.default-steps.step2'),
            t('components.projects.sections.welcome.default-steps.step3'),
          ],
    defaultValue: '',
    maxCount: 3,
  });

  const updateSectionValues = (values: SectionFormValues) => {
    if (!_id) return;

    const shouldRemoveSectionImage = !values.hasImage && sectionImage;
    if (shouldRemoveSectionImage)
      removeSectionFile(_id, { id: sectionImage.id, type: StorageFileType.SectionImage }).catch(
        handleError
      );

    const updates = {
      title: values.title,
      others: {
        message: values.message,
        steps:
          values.hasSteps && stepFields
            ? stepInputs.map((step) => step.value).filter((step) => !isNullOrUndefined(step))
            : [],
      },
      ...(shouldRemoveSectionImage ? { files: files.filter((f) => f.id !== sectionImage.id) } : {}),
    };

    editSection(_id, updates);
  };

  const initialValues: SectionFormValues = useMemo(
    () => ({
      title: title || '',
      message: message || '',
      hasImage: !!sectionImage || hasImage,
      hasSteps,
      ...stepFields,
    }),
    [hasImage, sectionImage, message, stepFields, title, hasSteps]
  );

  return (
    <>
      <hr className={allClasses(styles.section_hr, 'w-11/12')} />
      <Form
        validateOnMount
        enableReinitialize
        enableChangeOnInputCountChange
        onChange={updateSectionValues}
        className='px-5'
        {...{ initialValues }}>
        {({ status, values, errors, touched }) => (
          <>
            <FormError {...{ status }} />
            <FormField
              type='text'
              name='title'
              label={`${t('common.title')}*`}
              validate={isRequired}
              fieldClassName='mb-5'
              inputClassName={allClasses(
                styles.section_input,
                errors.title && touched.title && styles.section_input__invalid
              )}
              labelClassName={styles.section_label}
            />
            <FormField
              type='text'
              name='message'
              label={t('common.message')}
              fieldClassName='mb-5'
              inputClassName={styles.section_input}
              labelClassName={styles.section_label}
            />
            <hr className={styles.section_hr} />
            <div className={allClasses(styles.section_toggle_wrapper, 'mb-5')}>
              <span>{t('components.projects.sections.image')}</span>
              <FormField
                component={ToggleField}
                name='hasImage'
                sliderClassName={styles.section_toggle_slider_wrapper}
                circleClassName={styles.section_toggle_slider_circle}
                onChange={() => setHasImage(!values.hasImage)}
              />
            </div>
            {values.hasImage && (
              <div className='mb-5'>
                <UploadImage
                  excludeFromForm
                  fileSizeLimit={imageFileSizeLimit}
                  title={t('components.upload.image.title')}
                  subtitle={t('components.upload.image.subtitle')}
                  placeholderImgSrc={imageUrl}
                  upload={(image) =>
                    uploadSectionFile(_id, { file: image, type: StorageFileType.SectionImage })
                  }
                  onUploadError={handleError}
                  remove={async () => {
                    if (!sectionImage) return;
                    await removeSectionFile(_id, {
                      id: sectionImage.id,
                      type: StorageFileType.SectionImage,
                    });
                  }}
                  onRemoveSuccess={() => setHasImage(true)}
                  onRemoveError={handleError}
                  onLoadingStatusChange={reflectUploadingStatus}
                  className='mb-4'
                  fileName={sectionImage?.fileName}
                />
              </div>
            )}
            <div className={allClasses(styles.section_toggle_wrapper, 'mb-5')}>
              <span>{t('components.projects.sections.welcome.informative-steps')}</span>
              <div className='flex flex-row justify-between items-center text-base text-main-contrast leading-5'>
                <span className='flex items-end text-neutral-70 text-xs leading-none font-normal mr-2'>
                  {t('common.maximum')}3
                </span>
                <FormField
                  component={ToggleField}
                  name='hasSteps'
                  sliderClassName={styles.section_toggle_slider_wrapper}
                  circleClassName={styles.section_toggle_slider_circle}
                  onChange={() => setHasSteps(!values.hasSteps)}
                />
              </div>
            </div>
            <div className='mb-5'>
              {values.hasSteps && (
                <>
                  {Object.entries(values)
                    .filter(([key]) => key.startsWith(dynamicInputsPrefix))
                    .map(([key, value], index: number) => {
                      const isFirst = index === 0;
                      const shouldAutoFocus = autoFocusStepKey.current === key;
                      const keyForEnablingAutoFocus = `${key}_${shouldAutoFocus}`;

                      return (
                        <FormField
                          key={keyForEnablingAutoFocus}
                          autoFocus={shouldAutoFocus}
                          type='text'
                          name={key}
                          value={value}
                          validate={isRequired}
                          placeholder={`${t('components.projects.sections.welcome.step')} ${
                            index + 1
                          }`}
                          fieldClassName={index === 2 ? 'mb-0' : 'mb-2'}
                          inputClassName={allClasses(
                            styles.section_input,
                            errors[key] && touched[key] && styles.section_input__invalid
                          )}
                          onKeyDown={(e: KeyboardEvent) =>
                            editStepsCount(e, { index, isRemovable: !isFirst })
                          }
                          onChange={(e) => saveStepField(index, e.target.value)}
                        />
                      );
                    })}
                </>
              )}
            </div>
          </>
        )}
      </Form>
    </>
  );
};
