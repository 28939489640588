import { createContextService } from '../../../services/context';

import { useSelectFilter } from '../filters/useSelectFilter';

export interface ScreenAnalyticsFilter {
  inFocusFilterId: 'all-clicks' | 'first-click' | undefined;
  setFilterInFocus: (filter: 'all-clicks' | 'first-click' | undefined) => void;
}

export const useScreenAnalyticsFilter = (): ScreenAnalyticsFilter => {
  const { inFocusFilterId, setFilterInFocus } = useSelectFilter<'all-clicks' | 'first-click'>();

  return { inFocusFilterId, setFilterInFocus };
};

export const useScreenAnalyticsFilterContext = createContextService(useScreenAnalyticsFilter);
