import { allClasses } from '../../../services/utilities/array';

interface NewProjectCardProps {
  thumbnail: React.ReactNode;
  title: string;
  content?: React.ReactNode;
  submitButton?: React.ReactNode;
  containerClassName?: string;
  learnMoreUrl: string;
  isLast?: boolean;
}

export const NewProjectCard = ({
  thumbnail,
  title,
  content,
  containerClassName,
  submitButton,
  isLast,
}: NewProjectCardProps) => {
  return (
    <div
      className={allClasses(
        'border-2 border-main-10 border-solid max-w-md inline-block mb-5 py-10 px-8 rounded-lg	',
        containerClassName,
        isLast ? 'md:mr-0' : 'md:mr-5'
      )}>
      <div className='flex mx-auto justify-center items-center rounded-lg w-[120px] h-[120px]'>
        {thumbnail}
      </div>
      <h2 className='mt-10 text-xl text-main-contrast leading-relaxed font-bold'>{title}</h2>
      {content}
      {/* <InfoLearnMore url={learnMoreUrl} className='mb-7' /> */}
      {submitButton}
    </div>
  );
};
