import { CleanArray } from '../../../services/utilities/array';
import { isNullOrUndefined } from '../../../services/utilities/value';

import { isFigmaUrl } from '../api-iteration1/design-platforms/figma/utils';

type Validator = (val: any) => string | undefined | void;

export const isValidEmail = (email?: string) =>
  email?.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);

export const validateEmail = (email: string) =>
  isValidEmail(email) ? undefined : 'errors.validation.email';

export const isValidPassword = (password: string | undefined) =>
  password && password.length > 7 ? undefined : 'errors.validation.password-length';

export const passwordsMatch = (password: string | undefined, repeatPassword: string | undefined) =>
  password === repeatPassword ? undefined : 'errors.validation.password-match';

export const isValidName = (name: string) =>
  name?.length > 1 ? undefined : 'errors.validation.name';

export const isRequired = (value: any) => {
  const validationError = 'errors.validation.required';

  if (Array.isArray(value)) return value.length ? undefined : validationError;
  if (typeof value === 'string') return value ? undefined : validationError;
  if (typeof value === 'boolean') return !!value ? undefined : validationError;

  return !isNullOrUndefined(value) ? undefined : validationError;
};

export const isValidText = (value: any) =>
  typeof value === 'string' || typeof value === 'number' ? undefined : 'errors.validation.text';

export const isBoolean = (value: any) =>
  typeof value === 'boolean' ? undefined : 'errors.validation.boolean';

// TODO: add line breaks between error statements
export const composeValidators =
  (...validators: Validator[]) =>
  (value: any) =>
    CleanArray(validators.map((validator) => validator(value))).join('. ');

export const isValidFigmaUrl = (value: any) => {
  const validationError = 'errors.validation.invalid-figma-link';

  return isFigmaUrl(value) ? undefined : validationError;
};

export const isNumber = (value: any) =>
  typeof value === 'number' ? undefined : 'errors.validation.number';

export const isMoreThan = (limit: number) => (value: any) => {
  typeof value === 'number' && value > limit ? undefined : 'errors.validation.lower-limit';
};
