import { ProjectVersionResponsePermission } from '@iteration1/permission-validators';

import { allClasses } from '../../../services/utilities/array';
import { getKeyForCondition } from '../../../services/utilities/object';
import {
  DiscoveryVersionResponseOutput,
  ProjectType,
  UsabilitySectionType,
} from '../../services/api-iteration1';
import { UserTestVersionResponseOutput } from '../../services/api-iteration1/projects/userTest/responses';
import { useTranslationContext } from '../../../services/translation';
import { usePermissionsContext } from '../../services/authorization';
import { formatDate } from '../../../services/date';
import { AnalyticsResponsesFiltersTypes } from '../../services/analytics';

import { UserIcon } from '../../assets/icons';
import { SelectSectionItem } from '../containers';
import { AnalyticsResponsesFilters } from '../headers';
import { InvalidResponsesCard, ResponseLimitReachedCard } from '../cards';

interface AnalyticsResponseListProps {
  projectType: ProjectType;
  responses: (DiscoveryVersionResponseOutput | UserTestVersionResponseOutput)[];
  responsesCount: number;
  invalidResponsesCount: number;
  limitResponses: number;
  hasFilters: boolean;
  selectedFilter: AnalyticsResponsesFiltersTypes;
  selectedResponseId?: string;
  onSelectResponse: (responseId: string) => void;
  onSelectFilter: (filter: AnalyticsResponsesFiltersTypes) => void;
}

export const AnalyticsResponseList = ({
  projectType,
  responses,
  limitResponses,
  responsesCount,
  invalidResponsesCount,
  hasFilters,
  selectedFilter,
  selectedResponseId,
  onSelectResponse,
  onSelectFilter,
}: AnalyticsResponseListProps) => {
  const { t } = useTranslationContext.useContext();
  const { checkPermission } = usePermissionsContext.useContext();

  const hasResponseCountUnderLimit = checkPermission(ProjectVersionResponsePermission.create, {
    count: responsesCount,
  });

  return (
    <div className='max-w-22 w-1/2 min-w-min overflow-y-auto mr-5 pr-2 pt-16 scroll-container'>
      {projectType === 'userTest' && hasFilters && (
        <div className='flex flex-row'>
          <AnalyticsResponsesFilters
            filterClasses='flex flex-1 justify-center items-center py-1.5 rounded-xl text-main-contrast cursor-pointer text-xs'
            onSelect={onSelectFilter}
            selectedFilter={selectedFilter}
          />
        </div>
      )}
      <div className={projectType === 'userTest' ? 'mt-4' : undefined}>
        {!hasResponseCountUnderLimit && responsesCount >= limitResponses && (
          <ResponseLimitReachedCard responseCount={responsesCount} limit={limitResponses} />
        )}
        <InvalidResponsesCard invalidResponsesCount={invalidResponsesCount} />
        {responses?.map((response, index) => {
          const outcome = Object.values(response.answers).reduce((result, answerValue) => {
            return answerValue.type === UsabilitySectionType.SingleGoal
              ? answerValue.successType
              : result;
          }, '');

          return (
            <SelectSectionItem
              alreadyAdded
              description={response && formatDate(new Date(response.createdAt), 'PPp')}
              title={`${t('common.tester')} #${responses.length - index}`}
              key={index}
              icon={<UserIcon />}
              onClick={() => onSelectResponse(response.id)}
              isExpanded={selectedResponseId === response.id}
              outcomeBullet={
                hasFilters && (
                  <div
                    className={allClasses(
                      'w-4 h-4 flex absolute top-5 right-5 rounded-full',
                      getKeyForCondition({
                        'bg-success': outcome === 'direct',
                        'bg-warning': outcome === 'indirect',
                        'bg-danger': !outcome,
                      })
                    )}></div>
                )
              }
            />
          );
        })}
      </div>
    </div>
  );
};
