import { DiscoveryVersionResponseOutput, FolderChildType } from '../../../services/api-iteration1';
import { useCurrentDiscoveryVersionContext } from '../../../services/discovery';
import {
  calculateAvgTestDuration,
  calculateTotalResponseCompletionRate,
} from '../../../services/analytics/calculus';
import { differenceInMilliseconds } from '../../../../services/date';
import useAnalyticsRouting from '../../../services/analytics-routing/useAnalyticsRouting';

import { AnalyticsSummary } from '../analytics/AnalyticsSummary';
import { AnalyticsResponses } from '../analytics/AnalyticsResponses';

interface DiscoveryVersionResultProps {
  shownTab: 'summary' | 'responses';
  validResponses: DiscoveryVersionResponseOutput[];
  invalidResponsesCount: number;
  isLoading: boolean;
}

export const DiscoveryVersionResult = ({
  shownTab,
  validResponses,
  invalidResponsesCount,
  isLoading,
}: DiscoveryVersionResultProps) => {
  const { discoveryVersion, sortedSections } = useCurrentDiscoveryVersionContext.useContext();

  const { inFocusSectionId, setSectionInFocus } = useAnalyticsRouting();

  const responsesSortedByDate = validResponses.sort((item1, item2) => {
    return differenceInMilliseconds(new Date(item2.updatedAt), new Date(item1.updatedAt));
  });

  const completionRate = calculateTotalResponseCompletionRate({
    responses: responsesSortedByDate,
    sections: sortedSections,
    // at this moment we took the decision to consider all sections as required in the computation
    isRequired: () => true,
  });

  return (
    <div className='overflow-hidden mx-auto w-full flex flex-row xl:pl-2'>
      {shownTab === 'summary' ? (
        <AnalyticsSummary
          versionName={discoveryVersion?.name}
          sections={sortedSections}
          responses={responsesSortedByDate}
          invalidResponsesCount={invalidResponsesCount}
          inFocusResponseId={inFocusSectionId}
          averageDuration={calculateAvgTestDuration(responsesSortedByDate)}
          isLoadingResponses={isLoading}
          {...{ completionRate, setSectionInFocus }}
        />
      ) : (
        <AnalyticsResponses
          sections={sortedSections}
          responses={responsesSortedByDate}
          invalidResponsesCount={invalidResponsesCount}
          inFocusResponseId={inFocusSectionId}
          setResponseInFocus={setSectionInFocus}
          projectType={FolderChildType.Discovery}
          isLoadingResponses={isLoading}
        />
      )}
    </div>
  );
};
