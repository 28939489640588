import { allClasses } from '../../../services/utilities/array';

import { Button, Icon } from '../../../components';

import { AddFolderIcon } from '../../assets/icons';

interface AddFolderButtonProps {
  className?: string;
  onClick: () => void;
}

export const AddFolderButton = ({ className, onClick }: AddFolderButtonProps) => {
  return (
    <Button
      className={allClasses(
        'p-2 mr-5 border-2 border-main-highlight rounded-lg icon-hover-neutral-30',
        className
      )}
      {...{ onClick }}>
      <Icon Svg={AddFolderIcon} className='icon-main-contrast' />
    </Button>
  );
};
