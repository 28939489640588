import { useTranslationContext } from '../../../../../services/translation';
import { FolderChildType, UsabilitySingleGoalAnswer } from '../../../../services/api-iteration1';
import { allClasses } from '../../../../../services/utilities/array';
import { getStoragePublicFileUrl } from '../../../../services/api-cloud-storage';
import { getKeyForCondition } from '../../../../../services/utilities/object';
import {
  calculateAvgSingleGoalDuration,
  calculateSingleGoalMisclickRate,
  ScreenAnalytics,
  useAnalyticsQueryParams,
} from '../../../../services/analytics';
import { isSafari } from '../../../../../services/device';

import { Icon } from '../../../../../components';

import { AnalyticsPlayIcon, SectionsIcon } from '../../../../assets/icons';
import { SingleGoalVideoModal } from '../../../modals/SingleGoalVideoModal';
import { AnalyticsSectionAnswerEmptyState } from '../../../empty-states';
import { renderAnalyticsIcon } from '../../../icons';
import { AnalyticsStatistic } from '../AnalyticsStatistic';
import { ScreenAnalyticsModal } from '../../../modals/ScreensAnalyticsModal';

interface AnalyticsSingleGoalAnswerProps {
  answer?: UsabilitySingleGoalAnswer;
  testerIndex: number;
  screensAnalytics?: ScreenAnalytics[];
  versionName?: string;
  browser?: string;
  sectionId: string;
}

export const AnalyticsSingleGoalAnswer = ({
  answer,
  testerIndex,
  screensAnalytics,
  versionName,
  sectionId,
  browser,
}: AnalyticsSingleGoalAnswerProps) => {
  const { t } = useTranslationContext.useContext();
  const { queryParams, setQueryParams } = useAnalyticsQueryParams<FolderChildType.UserTest>();

  const openMetricsModal = () => {
    const firstScreenInFilter = screensAnalytics ? screensAnalytics[0] : undefined;
    setQueryParams((prev) => ({
      ...prev,
      metrics: sectionId,
      screenId: firstScreenInFilter?.frameId,
    }));
  };

  const closeMetricsModal = () => {
    setQueryParams(undefined);
  };

  const openVideoModal = () => {
    setQueryParams((prev) => ({
      ...prev,
      videoModal: sectionId,
    }));
  };

  const closeVideoModal = () => {
    setQueryParams(undefined);
  };

  const expandSection = (screenId: string) => {
    setQueryParams((prev) => ({
      ...prev,
      metrics: sectionId,
      screenId: prev.screenId !== screenId ? screenId : undefined,
    }));
  };

  if (!answer) return <AnalyticsSectionAnswerEmptyState />;

  const availableVideo = !isSafari() && browser?.toLowerCase() !== 'safari';
  const misclickRate = calculateSingleGoalMisclickRate([answer]);
  const avgDurationKey = getKeyForCondition({
    'success-result': calculateAvgSingleGoalDuration([answer]) < 10,
    'warning-result':
      calculateAvgSingleGoalDuration([answer]) >= 10 &&
      calculateAvgSingleGoalDuration([answer]) < 30,
    'danger-result': calculateAvgSingleGoalDuration([answer]) >= 30,
  });

  const misclicksKey = getKeyForCondition({
    'success-result': misclickRate < 30,
    'warning-result': misclickRate >= 30 && misclickRate < 50,
    'danger-result': misclickRate >= 50,
  });

  const outcomeKey = getKeyForCondition({
    'text-success': answer.successType === 'direct',
    'text-warning': answer.successType === 'indirect',
    'text-danger': answer.successType !== 'direct' && answer.successType !== 'indirect',
  });

  const screenBackgroundColor = getKeyForCondition({
    'bg-success': answer.successType === 'direct',
    'bg-warning': answer.successType === 'indirect',
    'bg-danger': answer.successType !== 'direct' && answer.successType !== 'indirect',
  });

  return (
    <div className='grid grid-cols-2 lg:flex lg:flex-row items-center justify-between xl:mr-16 2xl:mr-20 w-full lg:h-16'>
      <AnalyticsStatistic
        key={0}
        isLast={false}
        title={t('components.projects.analytics.responses.single-goal.outcome')}>
        <span className='flex flex-row items-center leading-8'>
          <span className='mr-4 leading-3'>
            <div
              key='analytics-dot-icon'
              className={allClasses('w-5 h-5 inline-block rounded-full', screenBackgroundColor)}
            />
          </span>
          <span className={allClasses('xl:test-xl 2xl:text-2xl font-bold capitalize', outcomeKey)}>
            {answer.successType !== 'direct' && answer.successType !== 'indirect'
              ? t('components.projects.analytics.responses.single-goal.bounce')
              : answer.successType}
          </span>
        </span>
      </AnalyticsStatistic>

      <AnalyticsStatistic
        key={1}
        isLast={false}
        title={t('components.projects.analytics.responses.single-goal.duration')}>
        <span
          className={allClasses(
            'flex flex-row items-center leading-8',
            avgDurationKey,
            `text-${avgDurationKey}`
          )}>
          <span className='mr-4 leading-3'>{renderAnalyticsIcon(avgDurationKey, 'time')}</span>
          <span className='xl:test-xl 2xl:text-2xl font-bold'>
            {`${calculateAvgSingleGoalDuration([answer] as UsabilitySingleGoalAnswer[])}s`}
          </span>
        </span>
      </AnalyticsStatistic>

      <AnalyticsStatistic
        key={2}
        isLast={false}
        title={t('components.projects.analytics.responses.single-goal.misclick-rate')}>
        <span
          className={allClasses(
            'flex flex-row items-center leading-8',
            misclicksKey,
            `text-${misclicksKey}`
          )}>
          <span className='mr-4 leading-3'>{renderAnalyticsIcon(misclicksKey, 'first-click')}</span>
          <span className='xl:test-xl 2xl:text-2xl font-bold'>{`${calculateSingleGoalMisclickRate([
            answer,
          ])}%`}</span>
        </span>
      </AnalyticsStatistic>

      <AnalyticsStatistic
        key={3}
        title={t('components.projects.analytics.responses.single-goal.screen-metrics')}>
        <div className='mt-1'>
          <span className='flex flex-row items-center'>
            <>
              <SectionsIcon className='rotate-90 icon-info icon-stroke icon-stroke-circle-info mr-4' />

              <span
                className='text-info text-base font-medium pb-1 border-b-default border-solid border-main-10 inline-block cursor-pointer hover:text-info-70'
                onClick={openMetricsModal}>
                {t('components.projects.analytics.responses.single-goal.view-metrics')}
              </span>
              {queryParams.metrics === sectionId && (
                <ScreenAnalyticsModal
                  isOpen
                  closeModal={closeMetricsModal}
                  screensAnalytics={screensAnalytics || []}
                  testerBreadcrumbValue={`${t('common.tester')} #${testerIndex}`}
                  selectedFilter='all'
                  isIndividualResponse
                  showFilters={false}
                  {...{
                    expandSection,
                    versionName,
                  }}
                />
              )}
            </>
          </span>
        </div>
      </AnalyticsStatistic>

      <AnalyticsStatistic
        key={4}
        isLast
        title={t('components.projects.analytics.responses.single-goal.session-recording')}>
        <div className='mt-1'>
          <span className='flex flex-row items-center'>
            {answer.video ? (
              <>
                {availableVideo && (
                  <span className='inline-block mr-4'>
                    <Icon Svg={AnalyticsPlayIcon} className='icon-main-contrast' />
                  </span>
                )}

                {availableVideo ? (
                  <span
                    className='text-info text-base font-medium pb-1 border-b-default border-solid border-main-10 inline-block cursor-pointer hover:text-info-70'
                    onClick={openVideoModal}>
                    {t('components.projects.analytics.responses.single-goal.play-session')}
                  </span>
                ) : (
                  <span className='text-neutral-80 text-base font-medium pb-1 border-b-default border-solid border-main-10'>
                    {t(
                      'components.projects.analytics.responses.single-goal.session-not-available-safari'
                    )}
                  </span>
                )}
                {queryParams.videoModal === sectionId && (
                  <SingleGoalVideoModal
                    isOpen
                    videoUrl={getStoragePublicFileUrl(answer.video.id)}
                    closeModal={closeVideoModal}
                    {...{ testerIndex }}
                  />
                )}
              </>
            ) : (
              <span className='text-base font-medium text-neutral-30 pb-1 border-b-default border-solid border-main-10 inline-block'>
                {t('components.projects.analytics.responses.single-goal.no-session')}
              </span>
            )}
          </span>
        </div>
      </AnalyticsStatistic>
    </div>
  );
};
