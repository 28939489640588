import { ReactNode } from 'react';
import { PermissionPlans } from '@iteration1/permission-validators';

import { useUpgradeModalToggle } from '../../services/billing/useUpgradeModalToggle';
import { SetQueryParams, useQueryParams } from '../../../services/router';
import { enumToValueArray } from '../../../services/utilities/array';
import { BillingOutput, BillingPeriod } from '../../services/api-iteration1/billing/api-models';
import { useUserBillingRecord } from '../../services/api-iteration1/billing';
import { useCurrentUserContext } from '../../services/current-user';

import { Head, useToggle } from '../../../components';
import { Navbar, AccessView } from './components';
import { DeviceAccessView } from './components/DeviceAccessView';

import { PlanUpgradeBanner } from '../banners';
import { routes } from '../../pages/routing/routes';
import { LayoutProps } from './utilities';
import { UpgradePlanModal } from '../modals/UpgradePlanModal';
import { CheckoutModal } from '../modals/CheckoutModal';
import { AnalyticsScreenName } from './AnalyticsScreenName';

interface MainLayoutProps extends LayoutProps {
  navbarLeftComponent?: JSX.Element;
  navbarRightComponent?: JSX.Element;
  navbarTabs?: React.ReactNode;
  isPlanLimitReached?: boolean;
  children?: ReactNode;
}

export const MainLayout = ({
  id,
  children,
  analyticsName,
  title,
  navbarLeftComponent,
  navbarRightComponent,
  navbarTabs,
  isPlanLimitReached,
  availableOnMobile,
  ...accessProps
}: MainLayoutProps) => {
  const { isUpgradePlanModalVisible, closeUpgradeModal } = useUpgradeModalToggle.useContext();
  const { currentUser } = useCurrentUserContext.useContext();

  const {
    params: { planTitle: plan, billingPeriod },
    setQueryParams,
  } = useQueryParams<{ planTitle: PermissionPlans; billingPeriod: BillingPeriod }>();

  const redirectFromWebsitePlans =
    plan &&
    billingPeriod &&
    enumToValueArray(PermissionPlans).includes(plan) &&
    ['year', 'month'].includes(billingPeriod) &&
    (currentUser?.subscription?.plan.name !== plan ||
      currentUser?.subscription?.price.recurring?.interval !== billingPeriod);

  const {
    isOpen: isPaymentModalVisible,
    open: openPaymentModal,
    close: closePaymentModal,
  } = useToggle(!!redirectFromWebsitePlans);

  return (
    <>
      <Head {...{ title }} />
      <AnalyticsScreenName name={analyticsName} />
      <DeviceAccessView {...{ availableOnMobile }}>
        <AccessView redirectUrl={routes.home} {...accessProps}>
          <div className='min-h-screen bg-main h-0' {...{ id }}>
            <div className='flex flex-col h-full w-full'>
              <Navbar
                isLoading={accessProps.isLoading}
                leftSideComponent={navbarLeftComponent}
                rightSideComponent={navbarRightComponent}
                tabs={navbarTabs}
                headerComponent={
                  isPlanLimitReached && <PlanUpgradeBanner onClick={openPaymentModal} />
                }
              />
              <div className='flex flex-col flex-1 overflow-auto scroll-container container-with-margin'>
                {children}
              </div>
            </div>
            {isUpgradePlanModalVisible && (
              <UpgradePlanModal
                isOpen
                closeModal={closeUpgradeModal}
                upgradePlan={openPaymentModal}
              />
            )}
            {isPaymentModalVisible && (
              <CheckoutBilling
                {...{
                  closePaymentModal,
                  setQueryParams,
                  plan,
                  billingPeriod,
                }}
              />
            )}
          </div>
        </AccessView>
      </DeviceAccessView>
    </>
  );
};

const CheckoutBilling = ({
  closePaymentModal,
  setQueryParams,
  plan,
  billingPeriod,
}: {
  closePaymentModal: () => void;
  setQueryParams: SetQueryParams;
  plan: PermissionPlans | undefined;
  billingPeriod: BillingPeriod | undefined;
}) => {
  const {
    data: billingRecord,
    mutate: mutateBillingRecord,
    revalidate: revalidateBillingRecord,
  } = useUserBillingRecord();

  const updatePaymentMethod = (newPaymentMethod: BillingOutput['paymentMethod']) => {
    mutateBillingRecord(
      (prev) => {
        return prev
          ? {
              ...prev,
              data: {
                ...prev.data,
                paymentMethod: newPaymentMethod,
              },
            }
          : prev;
      },
      { revalidate: false }
    );
    setTimeout(revalidateBillingRecord, 2000);
  };

  const isSelectedPlan = plan && billingPeriod;

  return (
    <CheckoutModal
      isOpen
      closeModal={() => {
        closePaymentModal();
        setQueryParams({});
      }}
      initialStep={isSelectedPlan ? { step: 'billing' } : undefined}
      initialPlan={isSelectedPlan ? { plan, billingPeriod } : undefined}
      billingRecord={billingRecord}
      onChangePaymentMethod={updatePaymentMethod}
    />
  );
};
