import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { ReactNode } from 'react';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);

export const usePaymentInstance = useStripe;

export const usePaymentContext = useElements;

interface PaymentContextProps {
  options?: StripeElementsOptions;
  children?: ReactNode;
}

export const PaymentContext = ({ children, options }: PaymentContextProps) => (
  <Elements stripe={stripePromise} options={options}>
    {children}
  </Elements>
);
