import { isEmpty, isNullOrUndefined } from './value';

export const emptyArray = (value: any) => Array.isArray(value) && !value.length;

export const CleanArray = <T>(elements: (T | null | undefined)[] = []): T[] => {
  for (let i = 0; i < elements.length; i++) {
    if (isEmpty(elements[i])) elements.splice(i, 1);
  }

  return elements as T[];
};

export const allClasses = (...args: any[]): string =>
  args.filter((arg) => !isEmpty(arg) && arg !== false).join(' ');

export const enumToValueArray = <T = unknown>(targetEnum: Record<string, T>) =>
  Object.values(targetEnum).filter((val) => !isNullOrUndefined(val));

export const getDuplicateValues = (arr: (string | number | undefined | null)[]) => {
  const sortedArray = [...arr].sort();

  const results = [];
  for (let i = 0; i < sortedArray.length - 1; i++) {
    if (sortedArray[i] === sortedArray[i + 1]) results.push(sortedArray[i]);
  }

  return results;
};

export const sorting = {
  byDateAsc: (propName: string) => (a: any, b: any) =>
    new Date(a[propName]).getTime() - new Date(b[propName]).getTime(),
  byDateDesc: (propName: string) => (a: any, b: any) =>
    new Date(b[propName]).getTime() - new Date(a[propName]).getTime(),
};
