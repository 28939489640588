import { useCallback, useState } from 'react';

import { createContextService } from '../../../services/context';

interface SectionsListService {
  inFocusSectionId: string;
  setSectionInFocus: (sectionId: string) => void;
  resetSectionInFocus: () => void;
  showSectionsList: () => void;
  shouldShowSectionsList: boolean;
}

export const useSectionsList = (): SectionsListService => {
  const [shouldShowSectionsList, setShouldShowSectionsList] = useState<boolean>(true);
  const [focusSectionId, setFocusSectionId] = useState<string>('');

  const setSectionInFocus = useCallback((sectionId: string) => {
    setFocusSectionId(sectionId);
    setShouldShowSectionsList(false);
  }, []);

  const resetSectionInFocus = useCallback(() => {
    setFocusSectionId('');
  }, []);

  const showSectionsList = useCallback(() => {
    setSectionInFocus('');
    setShouldShowSectionsList(!shouldShowSectionsList);
  }, [setSectionInFocus, shouldShowSectionsList]);

  return {
    inFocusSectionId: focusSectionId,
    setSectionInFocus,
    resetSectionInFocus,
    showSectionsList,
    shouldShowSectionsList,
  };
};

export const useSectionsListContext = createContextService(useSectionsList);
