import { useTranslationContext } from '../../../../../services/translation';
import { allClasses } from '../../../../../services/utilities/array';
import { useCurrentUserContext } from '../../../../services/current-user';
import { getStoragePublicFileUrl } from '../../../../services/api-cloud-storage';

import { Button, Navlink, OutsideModalClick, useToggle } from '../../../../../components';

import { routes } from '../../../../pages/routing/routes';
import { DefaultAvatarIcon } from '../../../../assets/icons';

interface MenuProps {
  isOpen: boolean;
}

export const Menu = ({ isOpen }: MenuProps) => {
  const { t } = useTranslationContext.useContext();
  const { currentUser, isAuthenticated, signOut } = useCurrentUserContext.useContext();
  const { isOpen: validUserImage, toggle: toggleValidUserImage } = useToggle(true);

  return isAuthenticated ? (
    <div
      className='ml-3 relative transition duration-150 ease-in-out'
      id='user-menu'
      aria-label='User menu'
      aria-haspopup='true'>
      {validUserImage && currentUser?.image ? (
        currentUser && (
          <div className='rounded-full w-5 h-5 object-cover overflow-hidden'>
            <img
              className='object-cover'
              src={getStoragePublicFileUrl(currentUser.image.id)}
              alt='Profile picture'
              onError={toggleValidUserImage}
            />
          </div>
        )
      ) : (
        <DefaultAvatarIcon />
      )}
      <OutsideModalClick isVisibleModal={isOpen} />
      <div
        className={allClasses(
          !isOpen && 'hidden',
          'origin-top-right absolute -right-2 mt-4 w-28 rounded-md z-50 border-solid border-main-10 border-2 text-center'
        )}>
        <div
          className='p-1 rounded-md bg-main flex flex-col items-center justify-center'
          role='menu'
          aria-orientation='vertical'
          aria-labelledby='user-menu'>
          <Navlink
            to={routes.profile}
            className='w-full py-2.5 text-base text-main-contrast capitalize font-medium leading-5 rounded hover:bg-main-90 transition duration-150 ease-in-out'>
            {t('components.navbar.account')}
          </Navlink>
          <Button
            className='w-full py-2.5 text-base text-danger-60 capitalize font-medium leading-5 rounded hover:enabled:bg-main-90 transition duration-150 ease-in-out'
            onClick={signOut}>
            {t('common.auth.sign-out')}
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <Navlink className='btn-highlight font-bold' to={routes.signIn}>
      {t('common.auth.sign-in')}
    </Navlink>
  );
};
