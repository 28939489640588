import { useTranslationContext } from '../../../services/translation';
import { allClasses } from '../../../services/utilities/array';

interface InfoLearnMoreProps {
  className?: string;
  url?: string;
}

export const InfoLearnMore = ({ className, url }: InfoLearnMoreProps) => {
  const { t } = useTranslationContext.useContext();

  return (
    <a
      target='_blank'
      href={url || process.env.REACT_APP_ITERATION1_UI_URL}
      className={allClasses(
        'inline-block text-base font-medium leading-5 text-info border-b-default border-main-70 border-solid cursor-pointer pb-0.5 outline-none focus:outline-none hover:text-info-70',
        className
      )}
      rel='noreferrer'>
      {t('common.learn-more')}
    </a>
  );
};
