import { FirebaseAuthUser, FirebaseAuthUserInfo } from '../../../services/auth';

export interface OtherNewAccountInfo {
  privacyConsent: boolean;
  termsConsent: boolean;
  firstName?: string;
  lastName?: string;
}

export const extractNewUserDetails = (
  user: FirebaseAuthUser,
  otherProfileInfo?: OtherNewAccountInfo
) => ({
  uid: user.uid,
  email: user.email,
  firstName: otherProfileInfo?.firstName,
  lastName: otherProfileInfo?.lastName,
  privacyConsent: otherProfileInfo?.privacyConsent,
  termsConsent: otherProfileInfo?.termsConsent,
});

export interface AuthUserData {
  uid: string;
  email: string;
  displayName: string | null;
  providerData: (FirebaseAuthUserInfo | null)[];
}

export const extractAuthenticationData = (authUser: FirebaseAuthUser): AuthUserData => {
  const { uid, email, providerData, displayName } = authUser;

  return {
    uid,
    email: email || '',
    displayName,
    providerData,
  };
};
