import { Collaborator } from '../collaborators/api-models';

export interface FolderParentOutput {
  _id: string;
  name: string;
  index: number;
}

export enum FolderChildType {
  Discovery = 'discovery',
  UserTest = 'userTest',
  Folder = 'folder',
}

export interface FolderChildOutput {
  _id: string;
  name: string;
  type: FolderChildType;
  updatedAt: Date;
  childrenCount: number;
  collaborators: Collaborator[];
}

export interface FolderOutput {
  id: string;
  name: string;
  ownerId: string;
  parents: FolderParentOutput[];
  children: FolderChildOutput[];
  createdAt: Date;
  updatedAt: Date;
  isDefault: boolean;
  collaborators: Collaborator[];
}

export interface FolderCreateInput {
  name: string;
  parents?: FolderParentOutput[];
}
