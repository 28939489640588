import { PermissionPlans } from '@iteration1/permission-validators';

import {
  isSubscriptionUnavailable,
  useCurrentUserSubscription,
} from '../../services/api-iteration1/billing';
import { getStripePaymentErrorMessage } from '../../services/billing/payment';
import { useCurrentUserContext } from '../../services/current-user';
import { useTranslationContext } from '../../../services/translation';
import { useErrorHandlerContext } from '../../../services/error-handling';
import { SubscriptionOutput } from '../../services/api-iteration1';
import { PaymentIntentStatus } from '../../services/api-iteration1/billing/api-models';

import { Button, PaymentContext, usePaymentContext, usePaymentInstance } from '../../../components';

import { routes } from '../../pages/routing/routes';

interface PayDueSubscriptionButtonProps {
  subscription: SubscriptionOutput;
  openCheckoutModal: (context: { clientSecret: string; invoiceId: string }) => void;
}

const PayDueSubscriptionButtonContainer = (props: PayDueSubscriptionButtonProps) => {
  const { data: currentSubscription } = useCurrentUserSubscription({
    waitForFetch: isSubscriptionUnavailable(props.subscription),
  });

  return !!currentSubscription?.clientSecret &&
    !!currentSubscription?.latestInvoicePaymentIntentStatus &&
    !!currentSubscription?.latestInvoiceId ? (
    <PaymentContext
      key={currentSubscription.clientSecret}
      options={{ clientSecret: currentSubscription.clientSecret }}>
      <PayDueSubscriptionButton
        openCheckoutModal={props.openCheckoutModal}
        latestInvoiceClientSecret={currentSubscription.clientSecret}
        latestInvoicePaymentIntentStatus={currentSubscription.latestInvoicePaymentIntentStatus}
        latestInvoiceId={currentSubscription.latestInvoiceId}
      />
    </PaymentContext>
  ) : null;
};

export { PayDueSubscriptionButtonContainer as PayDueSubscriptionButton };

const PayDueSubscriptionButton = ({
  latestInvoicePaymentIntentStatus,
  latestInvoiceClientSecret,
  latestInvoiceId,
  openCheckoutModal,
}: {
  latestInvoicePaymentIntentStatus: PaymentIntentStatus;
  latestInvoiceClientSecret: string;
  latestInvoiceId: string;
  openCheckoutModal: PayDueSubscriptionButtonProps['openCheckoutModal'];
}) => {
  const { t } = useTranslationContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();
  const { reloadUser } = useCurrentUserContext.useContext();

  const paymentInstance = usePaymentInstance();
  const paymentElements = usePaymentContext();

  const paySubscription = async () => {
    if (
      !paymentInstance ||
      !paymentElements ||
      ['canceled', 'processing', 'succeeded', null, undefined].includes(
        latestInvoicePaymentIntentStatus
      )
    )
      return;

    if (latestInvoiceClientSecret && latestInvoicePaymentIntentStatus === 'requires_payment_method')
      return openCheckoutModal({
        clientSecret: latestInvoiceClientSecret,
        invoiceId: latestInvoiceId,
      });

    try {
      if (
        latestInvoicePaymentIntentStatus &&
        ['requires_action', 'requires_confirmation'].includes(latestInvoicePaymentIntentStatus)
      ) {
        const { error } = await paymentInstance.confirmPayment({
          elements: paymentElements,
          confirmParams: {
            return_url: window.location.origin + routes.billing,
          },
          redirect: 'if_required',
        });
        if (error) {
          const errorMessage = getStripePaymentErrorMessage(error, t);
          handleError(error, errorMessage);
        }
        return;
      }
      //  wait for payment changes to reflect in our db
      setTimeout(() => {
        reloadUser();
      }, 2000);
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <>
      <Button className='button__filled px-10 mr-5' onClick={paySubscription}>
        <span>{t('pages.account.tabs.plan-billing.plans.pay')}</span>
      </Button>

      <p className='text-xs text-neutral-60 leading-6 mt-4'>
        {t('pages.account.tabs.plan-billing.plans.activation-info', {
          planName: t(
            `pages.account.tabs.plan-billing.plans.${PermissionPlans.IndividualFree}.title`
          ),
        })}
      </p>
    </>
  );
};
