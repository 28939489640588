import { useMemo } from 'react';

import { allClasses } from '../../../services/utilities/array';
import { useTranslationContext } from '../../../services/translation';
import { isRequired, validate } from '../../services/validation';
import {
  BillingOutput,
  BillingUpdateInput,
} from '../../services/api-iteration1/billing/api-models';
import { countryNamesList, getCountry, getCountryNameByCode } from '../../../services/countries';
import { getCountryTaxCodes } from '../../services/api-iteration1/billing';

import {
  Button,
  createFormSubmitHandler,
  createFormValidator,
  DropdownSearchField,
  DropdownSelectField,
  Form,
  FormError,
  FormField,
  LoadingOrContent,
} from '../../../components';

import { CaretDownIcon, InfoIcon } from '../../assets/icons';
import { PaymentMethodCard } from '../cards';

import styles from '../views/settings/BillingSettings.module.css';

interface PartialBillingInfoFormValues {
  company: Partial<BillingOutput['company']>;
  address: Partial<BillingOutput['address']>;
}

type BillingInfoFormProps = {
  initialBillingInfo?: PartialBillingInfoFormValues;
  containsPaymentMethod?: boolean;
  billingRecord?: BillingOutput;
  expectInitialValues?: boolean;
  onChangePaymentMethod?: (newPaymentMethod: BillingOutput['paymentMethod']) => void;
} & (
  | {
      onSubmit: (values: BillingUpdateInput) => void;
      onSuccess: (values: BillingUpdateInput) => void;
      onChange?: never;
    }
  | {
      onSubmit?: never;
      onSuccess?: never;
      onChange: (values: BillingUpdateInput, isValid: boolean) => void;
    }
);

interface FormValues {
  name: string;
  country: string;
  taxCode: string;
  taxValue: string;
  streetLine1: string;
  state: string;
  city: string;
  postalCode: string;
}

export const BillingInfoForm = ({
  initialBillingInfo,
  containsPaymentMethod,
  billingRecord,
  expectInitialValues = false,
  onSubmit,
  onSuccess,
  onChangePaymentMethod,
  onChange,
}: BillingInfoFormProps) => {
  const { t } = useTranslationContext.useContext();

  const initialValues = useMemo(
    () => ({
      name: initialBillingInfo?.company?.name || '',
      taxValue: initialBillingInfo?.company?.taxValue || '',
      taxCode: initialBillingInfo?.company?.taxCode || '',
      country:
        (initialBillingInfo?.address?.country &&
          getCountryNameByCode(initialBillingInfo.address.country)) ||
        '',
      state: initialBillingInfo?.address?.state || '',
      city: initialBillingInfo?.address?.city || '',
      streetLine1: initialBillingInfo?.address?.streetLine1 || '',
      postalCode: initialBillingInfo?.address?.postalCode || '',
    }),
    [initialBillingInfo?.address, initialBillingInfo?.company]
  );

  const mapFormValues = (values: typeof initialValues): BillingUpdateInput => ({
    company: {
      name: values.name,
      taxValue: values.taxValue,
      taxCode: values.taxCode || getCountryTaxCodes(values.country)?.[0].value,
    },
    address: {
      country: getCountry(values.country).code || '',
      state: values.state,
      city: values.city,
      streetLine1: values.streetLine1,
      postalCode: values.postalCode,
    },
  });

  const validateForm = createFormValidator<FormValues>((values) => ({
    name: validate(values.name, [isRequired]),
    country: validate(values.country, [
      (val: string) =>
        countryNamesList.map((c) => c.label.toLowerCase()).includes(val.toLowerCase())
          ? undefined
          : 'errors.validation.invalid-selected-country',
    ]),
    streetLine1: validate(values.streetLine1, [isRequired]),
    city: validate(values.city, [isRequired]),
    state: validate(values.state, [isRequired]),
    postalCode: validate(values.postalCode, [isRequired]),
  }));

  const submitForm = onSubmit
    ? createFormSubmitHandler<typeof initialValues>({
        tryAction: (values) => onSubmit(mapFormValues(values)),
        onSuccess: (values) => onSuccess?.(values),
      })
    : undefined;

  const handleChange = onChange
    ? (values: typeof initialValues, isValid: boolean) => {
        onChange(mapFormValues(values), isValid);
      }
    : undefined;

  return (
    <div
      className={allClasses(
        'w-full flex flex-col mb-5',
        !containsPaymentMethod && 'px-5 border-2 border-main-10 border-solid rounded-lg'
      )}>
      {!!containsPaymentMethod && (
        <PaymentMethodCard
          displaySmall
          paymentMethod={billingRecord?.paymentMethod}
          onChangeSuccess={onChangePaymentMethod}
        />
      )}
      <div className='flex flex-row py-5 items-center justify-between border-b-2 border-main-10 border-solid'>
        <span className='flex flex-row items-center'>
          <InfoIcon />
          <span className='pl-5 text-base leading-5 text-main-contrast font-bold'>
            {t('pages.account.tabs.plan-billing.billing-info.title')}
          </span>
        </span>
      </div>
      <div className={allClasses('py-10', !containsPaymentMethod && 'px-10')}>
        <LoadingOrContent isLoading={expectInitialValues && !initialBillingInfo}>
          <Form
            enableReinitialize
            onChange={handleChange}
            onSubmit={submitForm}
            validate={validateForm}
            className='max-w-[380px]'
            {...{ initialValues }}>
            {({
              values,
              status,
              dirty,
              errors,
              touched,
              isSubmitting,
              isValidForSubmit,
              setFieldValue,
            }) => {
              const countryTaxCodes = getCountryTaxCodes(values.country);
              const hasMultipleAvailableTaxCodes = countryTaxCodes && countryTaxCodes.length > 1;

              return (
                <>
                  <FormField
                    autoFocus
                    type='text'
                    name='name'
                    label={`${t('pages.account.tabs.plan-billing.billing-info.company-name')} *`}
                    placeholder={t('pages.account.tabs.plan-billing.billing-info.company-name')}
                    fieldClassName='mb-6'
                    inputClassName={allClasses(
                      styles.section_input,
                      errors.name && touched.name && styles.section_input__invalid
                    )}
                    labelClassName={styles.section_label}
                  />
                  <FormField
                    type='text'
                    name='streetLine1'
                    label={`${t('pages.account.tabs.plan-billing.billing-info.streetLine1')} *`}
                    placeholder={t('pages.account.tabs.plan-billing.billing-info.streetLine1')}
                    fieldClassName='mb-6'
                    inputClassName={allClasses(
                      styles.section_input,
                      errors.streetLine1 && touched.streetLine1 && styles.section_input__invalid
                    )}
                    labelClassName={styles.section_label}
                  />
                  <FormField
                    name='country'
                    component={DropdownSearchField}
                    items={countryNamesList}
                    onChange={() => setFieldValue('taxCode', '', true)}
                    label={`${t('pages.account.tabs.plan-billing.billing-info.country')} *`}
                    placeholder={t('pages.account.tabs.plan-billing.billing-info.country')}
                    fieldClassName='mb-6'
                    inputClassName={allClasses(
                      styles.section_input,
                      errors.country && touched.country && styles.section_input__invalid
                    )}
                    labelClassName={styles.section_label}
                    itemClassName='bg-main box-content py-2 px-3 m-0 w-full text-main-contrast text-lg font-normal leading-normal hover:bg-main-90 focus:outline-none'
                    dropdownClassName='max-h-[132px] top-[4rem] p-1 bg-main'
                    suffix={<CaretDownIcon />}
                    suffixClassName='absolute top-[1.125rem] right-5 cursor-pointer'
                    suffixClassNameOpen='rotate-180'
                  />
                  <FormField
                    type='text'
                    name='state'
                    label={`${t('pages.account.tabs.plan-billing.billing-info.state')} *`}
                    placeholder={t('pages.account.tabs.plan-billing.billing-info.state')}
                    fieldClassName='mb-6'
                    inputClassName={allClasses(
                      styles.section_input,
                      errors.state && touched.state && styles.section_input__invalid
                    )}
                    labelClassName={styles.section_label}
                  />
                  <FormField
                    type='text'
                    name='city'
                    label={`${t('pages.account.tabs.plan-billing.billing-info.city')} *`}
                    placeholder={t('pages.account.tabs.plan-billing.billing-info.city')}
                    fieldClassName='mb-6'
                    inputClassName={allClasses(
                      styles.section_input,
                      errors.city && touched.city && styles.section_input__invalid
                    )}
                    labelClassName={styles.section_label}
                  />
                  <FormField
                    type='text'
                    name='postalCode'
                    label={`${t('pages.account.tabs.plan-billing.billing-info.postal-code')} *`}
                    placeholder={t('pages.account.tabs.plan-billing.billing-info.postal-code')}
                    fieldClassName='mb-6'
                    inputClassName={allClasses(
                      styles.section_input,
                      errors.postalCode && touched.postalCode && styles.section_input__invalid
                    )}
                    labelClassName={styles.section_label}
                  />
                  <div className='flex w-full'>
                    {hasMultipleAvailableTaxCodes && (
                      <FormField
                        type='text'
                        name='taxCode'
                        component={DropdownSelectField}
                        items={countryTaxCodes}
                        label={t('pages.account.tabs.plan-billing.billing-info.taxCode')}
                        placeholder={t('pages.account.tabs.plan-billing.billing-info.taxCode')}
                        fieldClassName='mb-6 mr-3 w-full max-w-[50%]'
                        inputClassName={allClasses(
                          'h-full truncate',
                          styles.section_input,
                          errors.taxCode && touched.taxCode && styles.section_input__invalid
                        )}
                        labelClassName={styles.section_label}
                        itemClassName='bg-main box-content border-solid hover:border-2 hover:border-main-highlight py-1 px-5 m-0 w-full text-main-contrast text-md font-normal leading-normal focus:border-main-highlight focus:outline-none'
                      />
                    )}
                    <FormField
                      type='text'
                      name='taxValue'
                      label={t('pages.account.tabs.plan-billing.billing-info.taxValue')}
                      placeholder={t('pages.account.tabs.plan-billing.billing-info.taxValue')}
                      fieldClassName='mb-6 w-full'
                      inputClassName={allClasses(
                        styles.section_input,
                        errors.taxValue && touched.taxValue && styles.section_input__invalid
                      )}
                      labelClassName={styles.section_label}
                    />
                  </div>
                  <FormError {...{ status }} />
                  {onSubmit && dirty && (
                    <Button
                      className={allClasses(
                        'button__filled px-10 mt-10 mb-5',
                        isSubmitting && 'cursor-default'
                      )}
                      disabled={!isValidForSubmit}
                      loading={isSubmitting}
                      type='submit'>
                      {t('common.save')}
                    </Button>
                  )}
                </>
              );
            }}
          </Form>
        </LoadingOrContent>
      </div>
    </div>
  );
};
