import React from 'react';
import { MyProjectVersionSectionPermission } from '@iteration1/permission-validators';

import { useTranslationContext } from '../../services/translation';
import { useSectionsListContext } from '../services/sections/useSectionsList';
import { useErrorHandlerContext } from '../../services/error-handling';
import {
  useCurrentUserTestVersionContext,
  useEditUserTestContext,
  useShowInstructionsContext,
} from '../services/userTest';
import { sortUserTestSections } from '../services/api-iteration1/projects/userTest/sections';
import { UserTestProjectSection } from '../services/api-iteration1/projects/userTest/sections/api-models';
import {
  DiscoverySectionMultipleChoiceOutput,
  DiscoverySectionOpenWordChoiceOutput,
  DiscoverySectionRatingScaleOutput,
  DiscoverySectionType,
  DiscoverySectionYesNoOutput,
  UsabilitySectionFiveSeconds,
  UsabilitySectionFirstClick,
  UsabilitySectionSingleGoal,
  UsabilitySectionType,
  DiscoverySectionPreferenceOutput,
  DiscoverySectionLegalOutput,
} from '../services/api-iteration1';
import { useLivePrototypeNavigation } from '../services/design-platforms';
import { enumToValueArray } from '../../services/utilities/array';
import { usePreviewModalContext } from '../services/preview';
import { useActionInProgressContext } from '../../services/progress';
import { usePermissionsContext } from '../services/authorization';
import { useUpgradeModalToggle } from '../services/billing/useUpgradeModalToggle';

import {
  MainLayout,
  withAuth,
  EditUserTestNavbarActions,
  NewUserTestSections,
  SectionPreferenceTest,
} from '../components';
import { Breadcrumbs } from '../../components';
import {
  SectionMultipleChoice,
  SectionOpenWordChoice,
  SectionRatingScale,
  SectionYesNo,
} from '../components/views/sections/edit';
import { SectionEdit } from '../components/lists/SectionEdit';
import { SectionView } from '../components/views/sections/view/SectionView';
import SectionSingleGoal from '../components/views/sections/edit/SectionSingleGoal';
import { ProjectVersionEmptyState } from '../components/empty-states/ProjectVersionEmptyState';
import { SectionFiveSeconds } from '../components/views/sections/edit/SectionFiveSeconds';
import { SectionFirstClick } from '../components/views/sections/edit/SectionFirstClick';
import { SectionLegal } from '../components/views/sections/edit/SectionLegal';

import { dynamicRoutes, routes } from './routing/routes';
import { MoreSectionsIllustration, ProjectEmptyStateIllustration } from '../assets/icons';

export const EditUserTest = withAuth(() => {
  return (
    <useSectionsListContext.ProvideContext>
      <useActionInProgressContext.ProvideContext>
        <useEditUserTestContext.ProvideContext>
          <useLivePrototypeNavigation.ProvideContext>
            <useShowInstructionsContext.ProvideContext>
              <usePreviewModalContext.ProvideContext>
                <EditUserTestPage />
              </usePreviewModalContext.ProvideContext>
            </useShowInstructionsContext.ProvideContext>
          </useLivePrototypeNavigation.ProvideContext>
        </useEditUserTestContext.ProvideContext>
      </useActionInProgressContext.ProvideContext>
    </useSectionsListContext.ProvideContext>
  );
});

const EditUserTestPage = () => {
  const { t } = useTranslationContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();

  const {
    userTestId,
    userTestVersionId,
    userTest,
    userTestVersion,
    sortedSections,
    welcomeSection,
    thankYouSection,
    parents,
    isLoadingUserTest,
    error,
    revalidate,
    removeUserTest,
    renameCurrentUserTest,
    startingPointNodeId,
  } = useCurrentUserTestVersionContext.useContext();

  const { editSection, uploadSectionFile, removeSectionFile, removeSection, duplicateSection } =
    useEditUserTestContext.useContext();

  const { invalidSectionsIds, addNewSection } = useEditUserTestContext.useContext();
  const { inFocusSectionId } = useSectionsListContext.useContext();
  const { checkPermission } = usePermissionsContext.useContext();
  const { openUpgradeModal } = useUpgradeModalToggle.useContext();

  const sectionInFocus = userTestVersion?.sections.find(
    (sectionItem) => sectionItem._id === inFocusSectionId
  );

  const { isOpenPreviewModal, openPreviewModal, closePreviewModal } =
    usePreviewModalContext.useContext();

  /* 
    We hide view with prototype when in preview
    as events in multiple Iframes conflict with eacthother and trigger eachother's handlers
  */
  const hideEdit =
    isOpenPreviewModal &&
    enumToValueArray(UsabilitySectionType).includes(sectionInFocus?.type as UsabilitySectionType);

  const breadcrumbs = userTestVersion
    ? [
        { label: t('pages.projects.breadcrumb'), url: routes.projects },
        ...parents
          .sort((a, b) => a.index - b.index)
          .map((parent) => ({
            label: parent.name,
            url: dynamicRoutes.folder(parent._id),
          })),
        {
          label: userTest?.name || '',
          url: dynamicRoutes.userTest(userTestId),
        },
        {
          label: userTestVersion.name,
          url: userTestVersion.id,
        },
      ]
    : [];

  const saveNewSorting = async (sortedSections: UserTestProjectSection[]) => {
    if (!userTestVersionId || !sortedSections) return;

    const sortedSectionIds = sortedSections.map((section) => section._id);
    try {
      await sortUserTestSections(userTestId, userTestVersionId, sortedSectionIds);
    } catch (err) {
      handleError(err);
    }
    revalidate();
  };

  const allowCreateSection = checkPermission(MyProjectVersionSectionPermission.create, {
    count: userTestVersion?.sections.length || 0,
  });

  return (
    <MainLayout
      analyticsName='Edit - UserTest'
      title={t('pages.userTest.edit', {
        projectName: userTest?.name,
        versionName: userTestVersion?.name,
      })}
      navbarLeftComponent={<Breadcrumbs items={breadcrumbs} />}
      navbarRightComponent={
        <EditUserTestNavbarActions
          onClickDelete={removeUserTest}
          onClickRename={renameCurrentUserTest}
          {...{ isOpenPreviewModal, openPreviewModal, closePreviewModal }}
        />
      }
      isLoading={isLoadingUserTest}
      error={error}
      isPlanLimitReached={!allowCreateSection}
      availableOnMobile={false}>
      {!hideEdit && (
        <div className='overflow-hidden mx-auto w-full flex flex-row'>
          <SectionEdit<UserTestProjectSection>
            onSort={saveNewSorting}
            newSectionsList={
              <NewUserTestSections
                onClick={(section) =>
                  allowCreateSection ? addNewSection(section) : openUpgradeModal()
                }
              />
            }
            sections={sortedSections}
            {...{ welcomeSection, thankYouSection, invalidSectionsIds }}
            dropId='usability-testing-section-edit'
            editSection={editSection}
            uploadSectionFile={uploadSectionFile}
            removeSectionFile={removeSectionFile}>
            {(sortedSections) =>
              sortedSections.map((section, index) => (
                <React.Fragment key={section._id}>
                  {section.type === UsabilitySectionType.SingleGoal && (
                    <SectionSingleGoal
                      section={section as UsabilitySectionSingleGoal}
                      isInvalid={invalidSectionsIds.includes(section._id)}
                      editSection={editSection}
                      removeSection={removeSection}
                      duplicateSection={allowCreateSection ? duplicateSection : openUpgradeModal}
                      designPrototype={userTestVersion?.designPrototype}
                      startingPointNodeId={startingPointNodeId}
                      {...{ index }}
                    />
                  )}
                  {section.type === UsabilitySectionType.FirstClick && (
                    <SectionFirstClick
                      section={section as UsabilitySectionFirstClick}
                      isInvalid={invalidSectionsIds.includes(section._id)}
                      editSection={editSection}
                      removeSection={removeSection}
                      duplicateSection={allowCreateSection ? duplicateSection : openUpgradeModal}
                      removeSectionFile={removeSectionFile}
                      uploadSectionFile={uploadSectionFile}
                      designPrototype={userTestVersion?.designPrototype}
                      {...{ index }}
                    />
                  )}
                  {section.type === DiscoverySectionType.YesNo && (
                    <SectionYesNo
                      section={section as DiscoverySectionYesNoOutput}
                      isInvalid={invalidSectionsIds.includes(section._id)}
                      editSection={editSection}
                      removeSectionFile={removeSectionFile}
                      uploadSectionFile={uploadSectionFile}
                      removeSection={removeSection}
                      duplicateSection={allowCreateSection ? duplicateSection : openUpgradeModal}
                      {...{ index }}
                    />
                  )}
                  {section.type === DiscoverySectionType.RatingScale && (
                    <SectionRatingScale
                      section={section as DiscoverySectionRatingScaleOutput}
                      isInvalid={invalidSectionsIds.includes(section._id)}
                      editSection={editSection}
                      removeSectionFile={removeSectionFile}
                      uploadSectionFile={uploadSectionFile}
                      removeSection={removeSection}
                      duplicateSection={allowCreateSection ? duplicateSection : openUpgradeModal}
                      {...{ index }}
                    />
                  )}
                  {section.type === DiscoverySectionType.MultipleChoice && (
                    <SectionMultipleChoice
                      section={section as DiscoverySectionMultipleChoiceOutput}
                      isInvalid={invalidSectionsIds.includes(section._id)}
                      editSection={editSection}
                      removeSectionFile={removeSectionFile}
                      uploadSectionFile={uploadSectionFile}
                      removeSection={removeSection}
                      duplicateSection={allowCreateSection ? duplicateSection : openUpgradeModal}
                      {...{ index }}
                    />
                  )}
                  {section.type === DiscoverySectionType.OpenWordChoice && (
                    <SectionOpenWordChoice
                      section={section as DiscoverySectionOpenWordChoiceOutput}
                      isInvalid={invalidSectionsIds.includes(section._id)}
                      editSection={editSection}
                      removeSectionFile={removeSectionFile}
                      uploadSectionFile={uploadSectionFile}
                      removeSection={removeSection}
                      duplicateSection={allowCreateSection ? duplicateSection : openUpgradeModal}
                      {...{ index }}
                    />
                  )}
                  {section.type === UsabilitySectionType.FiveSeconds && (
                    <SectionFiveSeconds
                      section={section as UsabilitySectionFiveSeconds}
                      isInvalid={invalidSectionsIds.includes(section._id)}
                      editSection={editSection}
                      removeSectionFile={removeSectionFile}
                      uploadSectionFile={uploadSectionFile}
                      removeSection={removeSection}
                      duplicateSection={allowCreateSection ? duplicateSection : openUpgradeModal}
                      projectType='userTest'
                      designPrototype={userTestVersion?.designPrototype}
                      {...{ index }}
                    />
                  )}
                  {section.type === DiscoverySectionType.Preference && (
                    <SectionPreferenceTest
                      section={section as DiscoverySectionPreferenceOutput}
                      isInvalid={invalidSectionsIds.includes(section._id)}
                      editSection={editSection}
                      removeSectionFile={removeSectionFile}
                      uploadSectionFile={uploadSectionFile}
                      removeSection={removeSection}
                      duplicateSection={allowCreateSection ? duplicateSection : openUpgradeModal}
                      {...{ index }}
                    />
                  )}
                  {section.type === DiscoverySectionType.Legal && (
                    <SectionLegal
                      section={section as DiscoverySectionLegalOutput}
                      isInvalid={invalidSectionsIds.includes(section._id)}
                      editSection={editSection}
                      removeSectionFile={removeSectionFile}
                      uploadSectionFile={uploadSectionFile}
                      removeSection={removeSection}
                      duplicateSection={allowCreateSection ? duplicateSection : openUpgradeModal}
                      {...{ index }}
                    />
                  )}
                </React.Fragment>
              ))
            }
          </SectionEdit>
          <div className='scroll-container pt-16 overflow-y-auto w-full'>
            {inFocusSectionId ? (
              <SectionView
                section={sectionInFocus}
                designPrototype={userTestVersion?.designPrototype}
              />
            ) : !sortedSections.length ? (
              <ProjectVersionEmptyState
                title={t('empty-states.project-item.title')}
                description={t('empty-states.project-item.description')}
                icon={<ProjectEmptyStateIllustration />}
              />
            ) : (
              <ProjectVersionEmptyState
                title={t('empty-states.next-sections.title')}
                description={t('empty-states.next-sections.description')}
                icon={<MoreSectionsIllustration />}
              />
            )}
          </div>
        </div>
      )}
    </MainLayout>
  );
};
