import { Chart, ChartWrapperOptions, GoogleChartWrapperChartType } from 'react-google-charts';

interface ChartsProps {
  type: GoogleChartWrapperChartType;
  data: any[][];
  options: ChartWrapperOptions['options'];
  width?: string | number;
  height?: string | number;
  className?: string;
}

export const Charts = ({ type, data, options, width, height, className }: ChartsProps) => {
  return (
    <Chart
      data={data}
      chartType={type}
      options={options}
      width={width}
      height={height}
      className={className}
    />
  );
};
