import {
  DefaultSection,
  DefaultSectionType,
  DiscoveryAnswer,
  DiscoverySection,
  DiscoverySectionType,
  UsabilityAnswer,
  UsabilitySection,
  UsabilitySectionType,
} from '../../api-iteration1';
import { sectionValidators as defaultSectionValidators } from './default';
import {
  answerValidators as discoveryAnswerValidators,
  sectionValidators as discoverySectionValidators,
} from './discovery';
import {
  answerValidators as usabilityAnswerValidators,
  sectionValidators as usabilitySectionValidators,
} from './usability';

const sectionValidators: Record<
  DiscoverySectionType | UsabilitySectionType,
  (section: any) => boolean
> = {
  ...defaultSectionValidators,
  ...discoverySectionValidators,
  ...usabilitySectionValidators,
};

export const validateSection = (
  type: DefaultSectionType | DiscoverySectionType | UsabilitySectionType | undefined,
  section: DefaultSection | DiscoverySection | UsabilitySection | undefined
) => {
  const validator = type && (sectionValidators as any)[type];

  return validator ? validator(section) : false;
};

const sectionAnswersValidators: Record<
  DiscoverySectionType | UsabilitySectionType,
  (answer: any) => boolean
> = {
  ...discoveryAnswerValidators,
  ...usabilityAnswerValidators,
};

export const validateSectionAnswers = (
  type: DefaultSectionType | DiscoverySectionType | UsabilitySectionType | undefined,
  answer: Omit<DiscoveryAnswer | UsabilityAnswer, 'duration'> | undefined
) => {
  const validator = type && (sectionAnswersValidators as any)[type];

  return validator ? validator(answer) : false;
};
