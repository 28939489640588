import {
  FolderChildOutput,
  deleteFolder,
  renameFolder,
  FolderOutput,
} from '../../services/api-iteration1/folders';
import { useTranslationContext } from '../../../services/translation';
import { useErrorHandlerContext } from '../../../services/error-handling';
import { FetchMutator } from '../../../services/api/resource-fetch-factory';

import { Card, useToggle } from '../../../components';
import { ProjectCardActions } from './components/actions/ProjectCardActions';
import styles from '../../../components/card/Card.module.css';

import { DateFromToday } from '../formatters/DateFromToday';
import { FolderIcon } from '../../assets/icons';
import { ResourceCollaboratorsButton } from '../buttons/ResourceCollaboratorsButton';

interface FolderCardProps {
  folderChild: FolderChildOutput;
  revalidate: () => void;
  revalidateProjectsCount?: () => void;
  mutate?: FetchMutator<FolderOutput>;
  mutateProjectsCount?: FetchMutator<number>;
}

export const FolderCard = ({
  folderChild,
  revalidate,
  revalidateProjectsCount,
  mutate,
  mutateProjectsCount,
}: FolderCardProps) => {
  const { t } = useTranslationContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();
  const { isOpen: isMenuOpen, toggle: toggleOpenMenu } = useToggle();

  const removeCurrentFolder = async () => {
    if (!folderChild._id) return;

    let mutationPrevData: FolderOutput | undefined;
    mutate?.(
      (prev) => {
        if (!prev) return;

        mutationPrevData = prev.data;

        const remainingChildren = mutationPrevData.children.filter(
          (child) => child._id !== folderChild._id
        );

        return { data: { ...mutationPrevData, children: remainingChildren } };
      },
      { revalidate: false }
    );
    mutateProjectsCount?.((prev) => (prev ? { ...prev, data: prev.data - 1 } : prev));

    try {
      await deleteFolder(folderChild._id);
      if (!mutationPrevData) {
        revalidate();
        revalidateProjectsCount?.();
      }
    } catch (err) {
      handleError(err);
      if (mutationPrevData) {
        mutate?.({ data: mutationPrevData });
        mutateProjectsCount?.((prev) => (prev ? { ...prev, data: prev.data + 1 } : prev));
      }
    }
  };

  const renameCurrentFolder = async (name: string) => {
    if (!folderChild._id) return;

    let mutationPrevData: FolderOutput | undefined;
    mutate?.(
      (prev) => {
        if (!prev) return;

        mutationPrevData = prev.data;

        const updatedChildren = mutationPrevData.children.map((child) =>
          child._id !== folderChild._id ? child : { ...child, name }
        );

        return { ...prev, data: { ...mutationPrevData, children: updatedChildren } };
      },
      { revalidate: false }
    );

    try {
      await renameFolder(folderChild._id, { name });
      if (!mutationPrevData) {
        revalidate();
        revalidateProjectsCount?.();
      }
    } catch (err) {
      handleError(err);
      if (mutationPrevData) mutate?.({ data: mutationPrevData });
    }
  };

  return (
    <Card
      header={
        <div className='flex flex-row justify-between items-center relative'>
          <FolderIcon />
          <ProjectCardActions
            className={styles.card_actions}
            onClick={toggleOpenMenu}
            isMenuOpen={isMenuOpen}
            onRemove={removeCurrentFolder}
            onRename={renameCurrentFolder}
            projectType='folder'
          />
        </div>
      }
      body={
        <p className='text-main-contrast overflow-hidden text-ellipsis text-xl bold font-medium mt-5 mb-auto'>
          {folderChild.name}
        </p>
      }
      footer={
        <div className='flex items-center w-full text-neutral-70 text-base font-medium leading-5'>
          <span className='flex-1'>
            <DateFromToday date={folderChild.updatedAt} />
          </span>
          <span className='flex flex-row items-center justify-end flex-1 text-right'>
            <strong className='text-main-contrast pr-2 font-medium'>
              {folderChild.childrenCount}
            </strong>
            {t('components.projects.item', { count: folderChild.childrenCount })}
            <ResourceCollaboratorsButton
              projectDetails={{
                type: folderChild.type,
                id: folderChild._id,
                collaboratorsCount: folderChild.collaborators?.length,
              }}
              revalidateResource={revalidate}
            />
          </span>
        </div>
      }
      urlTo={`/projects/${folderChild._id}`}
    />
  );
};
