import {
  DiscoveryRatingScaleAnswer,
  DiscoverySectionRatingScaleOutput,
} from '../../../../services/api-iteration1';
import { useTranslationContext } from '../../../../../services/translation';

import { Icon, LoadingOrContent } from '../../../../../components';

import { AnalyticsSectionContainer } from '../../../containers';
import {
  EmotionIconFive,
  EmotionIconFour,
  EmotionIconOne,
  EmotionIconThree,
  EmotionIconTwo,
  RatingScaleIcon,
  StarRatingIcon,
} from '../../../../assets/icons';
import { AnalyticsPercentage } from '../AnalyticsPercentage';
import { AnalyticsSummaryStatistic } from '../../../headers/AnalyticsSummaryStatistic';
import { renderNumericalRatingScaleLabels, renderRatingScaleLabels } from './utilities';

interface AnalyticsRatingScaleSummaryProps {
  answers: DiscoveryRatingScaleAnswer[];
  section: DiscoverySectionRatingScaleOutput;
  sectionsCount: number;
  isLoadingResponses: boolean;
}

const emotionsIcons = [
  {
    name: EmotionIconOne,
    className: 'w-10 h-10 icon-danger icon-circle-danger',
  },
  {
    name: EmotionIconTwo,
    className: 'w-10 h-10 icon-warning-10 icon-circle-warning-10',
  },
  {
    name: EmotionIconThree,
    className: 'w-10 h-10 icon-info icon-circle-info',
  },
  {
    name: EmotionIconFour,
    className: 'w-10 h-10 icon-stroke-main-highlight-95 icon-circle-main-highlight-95',
  },
  {
    name: EmotionIconFive,
    className: 'w-10 h-10 icon-success-20 icon-circle-success-20',
  },
];

export const AnalyticsRatingScaleSummary = ({
  answers,
  section,
  sectionsCount,
  isLoadingResponses,
}: AnalyticsRatingScaleSummaryProps) => {
  const { t } = useTranslationContext.useContext();

  const getAnswersForRating = (rating: number) => {
    return answers.filter((item) => item.answer === rating).length;
  };

  const calculatePercentage = (answersNumber: number) => {
    if (!answers) return 0;
    return Math.round((answersNumber / answers.length) * 100);
  };

  const {
    others: { labels, type, startingStep, stepsCount },
  } = section;

  const isNumerical = type === 'numerical';

  return (
    <AnalyticsSectionContainer
      contentTitle={section.title}
      description={section.others?.description as string}
      icon={<RatingScaleIcon />}
      rightSideInformation={
        <AnalyticsSummaryStatistic
          currentSectionIndex={section.index}
          answersCount={answers.length || 1}
          {...{ sectionsCount }}
        />
      }
      title={t('components.projects.sections.field-types.ratingScale')}>
      <LoadingOrContent isLoading={isLoadingResponses} className='pb-12'>
        <div className='w-full mt-20 mb-16'>
          {answers.length ? (
            <div className='flex flex-row justify-between'>
              {[...Array(isNumerical ? stepsCount : 5)].map((_, index) => {
                const value = isNumerical ? (startingStep === 0 ? index : index + 1) : index + 1;

                return (
                  <ViewAnalyticsRatingScaleAnswerBlock
                    key={value}
                    index={type !== 'numerical' ? value - 1 : value}
                    ratingNumber={value}
                    answersNumber={getAnswersForRating(value) || 0}
                    answersPercentage={calculatePercentage(getAnswersForRating(value) || 0) || 0}
                    labels={labels}
                    scaleType={type}
                    {...{ startingStep, stepsCount }}
                  />
                );
              })}
            </div>
          ) : (
            <p className='text-center font-medium text-danger'>
              {t('components.projects.analytics.no-answers')}
            </p>
          )}
        </div>
      </LoadingOrContent>
    </AnalyticsSectionContainer>
  );
};

const ViewAnalyticsRatingScaleAnswerBlock = (props: {
  answersNumber: number;
  answersPercentage: number;
  ratingNumber: number;
  labels:
    | {
        left?: string;
        middle?: string;
        right?: string;
      }
    | undefined;
  index: number;
  scaleType: DiscoverySectionRatingScaleOutput['others']['type'];
  stepsCount?: number;
  startingStep?: number;
}) => {
  const {
    answersNumber,
    answersPercentage,
    ratingNumber,
    labels,
    index,
    scaleType,
    startingStep,
    stepsCount,
  } = props;

  return (
    <div className='min-w-0 mr-5 text-center flex-1 last-of-type:mr-0 relative'>
      <AnalyticsPercentage answerPercentage={answersPercentage} answersNumber={answersNumber} />
      <div className='relative my-5 bg-main-10 rounded-lg h-[300px]'>
        <div
          style={{
            height: `${answersPercentage}%`,
          }}
          className='w-full absolute left-0 bottom-0 rounded-lg bg-main-highlight'></div>
      </div>
      <>
        {scaleType === 'numerical' && (
          <span className='text-xl leading-30 font-bold'>{ratingNumber}</span>
        )}
        {scaleType === 'emotions' && (
          <div className='flex justify-center w-full'>
            <Icon Svg={emotionsIcons[index].name} className={emotionsIcons[index].className} />
          </div>
        )}
        {scaleType === 'stars' && (
          <div className='flex justify-center w-full'>
            <StarRatingIcon className='w-10 h-10 icon-fill-polygon-warning' />
          </div>
        )}
        {scaleType !== 'numerical'
          ? renderRatingScaleLabels(index, labels)
          : renderNumericalRatingScaleLabels(index, startingStep, stepsCount, labels)}
      </>
    </div>
  );
};
