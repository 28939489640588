import {
  DiscoverySectionLegalOutput,
  DiscoverySectionMultipleChoiceOutput,
  DiscoverySectionOpenWordChoiceOutput,
  DiscoverySectionPreferenceOutput,
  DiscoverySectionRatingScaleOutput,
  DiscoverySectionType,
  DiscoverySectionYesNoOutput,
} from '../discovery';
import { SectionFactory } from '../utils';

export const initialDiscoverySections: {
  [DiscoverySectionType.YesNo]: SectionFactory<DiscoverySectionYesNoOutput>;
  [DiscoverySectionType.RatingScale]: SectionFactory<DiscoverySectionRatingScaleOutput>;
  [DiscoverySectionType.MultipleChoice]: SectionFactory<DiscoverySectionMultipleChoiceOutput>;
  [DiscoverySectionType.OpenWordChoice]: SectionFactory<DiscoverySectionOpenWordChoiceOutput>;
  [DiscoverySectionType.Preference]: SectionFactory<DiscoverySectionPreferenceOutput>;
  [DiscoverySectionType.Legal]: SectionFactory<DiscoverySectionLegalOutput>;
} = {
  [DiscoverySectionType.YesNo]: (index: number) => ({
    index,
    title: '',
    type: DiscoverySectionType.YesNo,
    files: [],
    others: {
      type: 'icons',
    },
  }),
  [DiscoverySectionType.RatingScale]: (index: number) => ({
    index,
    title: '',
    type: DiscoverySectionType.RatingScale,
    files: [],
    others: {
      type: 'numerical',
      startingStep: 0,
      stepsCount: 6,
    },
  }),
  [DiscoverySectionType.MultipleChoice]: (index: number) => ({
    index,
    title: '',
    type: DiscoverySectionType.MultipleChoice,
    files: [],
    others: {
      type: 'multiple',
      choices: [''],
    },
  }),
  [DiscoverySectionType.OpenWordChoice]: (index: number) => ({
    index,
    title: '',
    type: DiscoverySectionType.OpenWordChoice,
    files: [],
    others: {},
  }),
  [DiscoverySectionType.Preference]: (index: number) => ({
    index,
    title: '',
    type: DiscoverySectionType.Preference,
    files: [],
    others: {
      description: '',
      type: 'image',
    },
  }),
  [DiscoverySectionType.Legal]: (index: number) => ({
    index,
    title: '',
    type: DiscoverySectionType.Legal,
    files: [],
    others: {
      message: 'I agree with the terms',
    },
  }),
};
