import React from 'react';

interface AnalyticsSectionHeaderProps {
  icon: React.ReactNode;
  title: string;
  rightSideInformation: string | number | React.ReactNode;
}

export const AnalyticsSectionHeader = ({
  icon,
  title,
  rightSideInformation,
}: AnalyticsSectionHeaderProps) => {
  return (
    <div className='flex flex-row py-5 items-center justify-between border-b-2 border-main-10 border-solid'>
      <span className='flex flex-row items-center'>
        {icon}
        <span className='pl-5 text-base leading-5 text-main-contrast font-bold'>{title}</span>
      </span>
      <span className='text-base leading-5 text-main-contrast'>{rightSideInformation}</span>
    </div>
  );
};
