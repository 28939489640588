import { isUndefined } from '../../../services/utilities/value';

import { FolderChildType } from '../api-iteration1';

import { isTemporaryCollaborator } from '../api-iteration1/collaborators';
import { Collaborator } from '../api-iteration1/collaborators/api-models';

export const getSortedCollaborators = (
  projectDetails: {
    type: FolderChildType;
    id: string;
  },
  collaborators: Collaborator[] | undefined
) => {
  if (!collaborators) return { sortableCollaborators: [] };

  let temporaryCollaborator: Collaborator | undefined = undefined;

  const sortableCollaborators = collaborators.filter((collab) => {
    const isTemporaryCollab = isTemporaryCollaborator(collab.id);
    if (isTemporaryCollab) temporaryCollaborator = collab;
    return !isTemporaryCollab;
  });

  const { projectActiveCollaborators, projectPendingCollaborators, otherCollaborators } =
    sortableCollaborators.reduce(
      (result, collaborator) => {
        const isProjectCollaborator = collaborator.resources.find(
          (res) => res.id === projectDetails.id && res.type === projectDetails.type
        );

        if (isProjectCollaborator) {
          return {
            ...result,
            ...(collaborator.isPending
              ? {
                  projectPendingCollaborators: [
                    ...result.projectPendingCollaborators,
                    collaborator,
                  ],
                }
              : {
                  projectActiveCollaborators: [...result.projectActiveCollaborators, collaborator],
                }),
          };
        }
        return { ...result, otherCollaborators: [...result.otherCollaborators, collaborator] };
      },
      {
        projectActiveCollaborators: [],
        projectPendingCollaborators: [],
        otherCollaborators: [],
      } as Record<
        'projectActiveCollaborators' | 'projectPendingCollaborators' | 'otherCollaborators',
        Collaborator[]
      >
    );

  const projectCollaborators = projectActiveCollaborators.sort((a, b) =>
    a.firstName && b.firstName ? a.firstName.localeCompare(b.firstName) : 1
  );

  const otherAvailableCollaborators = otherCollaborators.sort((a, b) =>
    a.firstName && b.firstName ? a.firstName.localeCompare(b.firstName) : 1
  );

  const pendingCollaborators = projectPendingCollaborators.sort((a, b) =>
    a.email.localeCompare(b.email)
  );

  const sortedCollaborators = [
    ...projectCollaborators,
    ...pendingCollaborators,
    ...otherAvailableCollaborators,
  ].filter((collab) => !isUndefined(collab));

  return {
    sortableCollaborators: temporaryCollaborator
      ? [...sortedCollaborators, temporaryCollaborator]
      : sortedCollaborators,
  };
};
