import { useTranslationContext } from '../../../services/translation';

import { Button, Modal } from '../../../components';

import { CloseIcon, UpgradeIcon } from '../../assets/icons';

interface UpgradePlanModalProps {
  isOpen: boolean;
  closeModal: () => void;
  upgradePlan: () => void;
}

export const UpgradePlanModal = ({ isOpen, closeModal, upgradePlan }: UpgradePlanModalProps) => {
  const { t } = useTranslationContext.useContext();

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      variant='regular'
      closeModalComponent={<CloseIcon className='icon-hover-main-contrast' />}
      closeIconClassName='absolute top-5 right-5'>
      <div className='flex flex-1 flex-col items-center'>
        <div className='my-10 text-center'>
          <h5 className='mb-10 text-2xl text-main-contrast leading-30'>
            {t('plan-permissions-check.modal.title')}
          </h5>
          <>
            <p className='text-lg text-main-contrast font-medium leading-6'>
              {t('plan-permissions-check.modal.description-part-one')}
            </p>
            <p className='text-lg text-main-contrast font-medium leading-6'>
              {t('plan-permissions-check.modal.description-part-two')}
            </p>
          </>
        </div>
        <div className='grid grid-cols-2 gap-2 mb-10'>
          <div className='flex flex-row items-center mb-5'>
            <UpgradeIcon />
            <span className='ml-2 text-base text-main-contrast font-bold leading-5'>
              {t('plan-permissions-check.modal.benefits.more-projects')}
            </span>
          </div>
          <div className='flex flex-row items-center mb-5'>
            <UpgradeIcon />
            <span className='ml-2 text-base text-main-contrast font-bold leading-5'>
              {t('plan-permissions-check.modal.benefits.more-responses')}
            </span>
          </div>
          <div className='flex flex-row items-center'>
            <UpgradeIcon />
            <span className='ml-2 text-base text-main-contrast font-bold leading-5'>
              {t('plan-permissions-check.modal.benefits.more-sections')}
            </span>
          </div>
          <div className='flex flex-row items-center'>
            <UpgradeIcon />
            <span className='ml-2 text-base text-main-contrast font-bold leading-5'>
              {t('plan-permissions-check.modal.benefits.more-versions')}
            </span>
          </div>
        </div>
        <Button
          className='button__filled w-[135px] h-10 justify-center items-center mb-20 text-base leading-5 text-main-contrast font-medium'
          type='button'
          onClick={upgradePlan}>
          {t('common.upgrade')}
        </Button>
      </div>
    </Modal>
  );
};
