import { MyProjectPermission } from '@iteration1/permission-validators';
import { useLayoutEffect } from 'react';

import { useTranslationContext } from '../../services/translation';
import { useDefaultFolder, useProjectsCount } from '../services/api-iteration1';
import { usePermissionsContext } from '../services/authorization';
import { useUpgradeModalToggle } from '../services/billing/useUpgradeModalToggle';
import { useQueryParams } from '../../services/router';
import { useAlertsContext } from '../../services/alerts';
import { useCurrentUserContext } from '../services/current-user';

import { AddFolderButton, MainLayout, ProjectsList, WelcomeModal, withAuth } from '../components';
import { Breadcrumbs, Button, useToggle } from '../../components';
import { CreateFolderModal } from '../components/modals/CreateFolderModal';
import { CreateProjectTypeModal } from '../components/modals/CreateProjectTypeModal';

import { PlusSignIcon, ProjectsEmptyStateIllustration } from '../assets/icons';
import { routes } from './routing/routes';

export const Projects = withAuth(() => {
  const { t } = useTranslationContext.useContext();
  const { checkPermission } = usePermissionsContext.useContext();
  const { addErrorAlert } = useAlertsContext.useContext();
  const {
    params: { emailConfirmationStatus },
    setQueryParams,
  } = useQueryParams<{ emailConfirmationStatus?: 'success' | 'failure' }>();
  const { currentUser } = useCurrentUserContext.useContext();

  const { data: defaultProjectsFolder, isLoading, revalidate, mutate, error } = useDefaultFolder();

  const {
    data: projectsCount,
    revalidate: revalidateProjectsCount,
    mutate: mutateProjectsCount,
  } = useProjectsCount();

  const {
    isOpen: isCreateFolderModalVisible,
    open: openCreateFolderModal,
    close: closeCreateFolderModal,
  } = useToggle();
  const {
    isOpen: isCreateProjectModalVisible,
    open: openCreateProjectModal,
    close: closeCreateProjectModal,
  } = useToggle();
  const { isOpen: isWelcomeModalVisible, close: closeWelcomeModal } = useToggle(
    emailConfirmationStatus === 'success'
  );
  const { openUpgradeModal } = useUpgradeModalToggle.useContext();

  const breadcrumbs = [
    {
      label: t('pages.projects.breadcrumb'),
      url: routes.projects,
    },
  ];

  const allowCreateProject = checkPermission(MyProjectPermission.create, {
    count: projectsCount || 0,
  });

  useLayoutEffect(() => {
    if (emailConfirmationStatus === 'failure' && !!currentUser?.requiresEmailConfirmation) {
      addErrorAlert(t('pages.homepage.welcome-modal.alerts.error'), { timeout: 5000 });
      setQueryParams((prev) => ({ ...prev, emailConfirmationStatus: undefined }));
    }
  }, [
    addErrorAlert,
    currentUser?.requiresEmailConfirmation,
    emailConfirmationStatus,
    setQueryParams,
    t,
  ]);

  return (
    <MainLayout
      analyticsName='Projects'
      title={t('pages.projects.title')}
      navbarLeftComponent={<Breadcrumbs items={breadcrumbs} />}
      navbarRightComponent={
        <>
          <AddFolderButton
            onClick={allowCreateProject ? openCreateFolderModal : openUpgradeModal}
          />
          {defaultProjectsFolder && defaultProjectsFolder?.children.length >= 1 && (
            <Button
              className='button__filled mr-5'
              onClick={() => (allowCreateProject ? openCreateProjectModal() : openUpgradeModal())}>
              <PlusSignIcon />
              <span className='px-5'>{t('common.create')}</span>
            </Button>
          )}
        </>
      }
      isLoading={isLoading}
      error={error}
      isPlanLimitReached={!allowCreateProject}
      availableOnMobile={false}>
      {defaultProjectsFolder?.children?.length ? (
        <div className='my-20'>
          <ProjectsList
            items={defaultProjectsFolder.children}
            {...{ revalidate, revalidateProjectsCount, mutate, mutateProjectsCount }}
          />
        </div>
      ) : (
        <div className='h-screen flex flex-col items-center justify-center text-center'>
          <ProjectsEmptyStateIllustration />
          <div className='mt-10 flex flex-col items-center justify-center'>
            <h5>{t('empty-states.folder.title')}</h5>
            <p className='mt-2.5 mb-0 max-w-sm text-lg leading-5'>
              {t('empty-states.folder.description')}
            </p>
            <Button
              className='button__filled mt-10'
              onClick={() => (allowCreateProject ? openCreateProjectModal() : openUpgradeModal())}>
              <PlusSignIcon />
              <span className='px-5'>{t('common.create')}</span>
            </Button>
          </div>
        </div>
      )}
      {isCreateFolderModalVisible && (
        <CreateFolderModal
          closeModal={closeCreateFolderModal}
          isOpen={isCreateFolderModalVisible}
          onSuccess={revalidate}
          folder={defaultProjectsFolder}
        />
      )}
      {isCreateProjectModalVisible && (
        <CreateProjectTypeModal
          isOpen={isCreateProjectModalVisible}
          closeModal={closeCreateProjectModal}
          onSuccess={revalidate}
        />
      )}
      {isWelcomeModalVisible && (
        <WelcomeModal
          onExit={() => {
            closeWelcomeModal();
            setQueryParams({});
          }}
        />
      )}
    </MainLayout>
  );
});
