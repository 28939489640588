import { DefaultSectionType } from '../../api-iteration1';

export const sectionValidators: Record<
  DefaultSectionType,
  (section: Record<string, any>) => boolean
> = {
  [DefaultSectionType.Welcome]: (section) =>
    !!section.title &&
    (typeof section.others.steps === 'undefined' ||
      section.others.steps.filter(Boolean).length === section.others.steps.length),
  [DefaultSectionType.ThankYou]: (section) => !!section.title,
};
