import { FieldAttributes, FieldProps } from 'formik';

import { CheckboxTickIcon } from '../../../../domain/assets/icons';

import styles from './CheckboxField.module.css';

interface CheckboxFieldProps extends FieldProps, FieldAttributes<any> {
  id: string;
}

export const CheckboxField = ({ id, field, ...otherProps }: CheckboxFieldProps) => {
  const isChecked = !!(field.value || otherProps.value);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { form, meta, ...inputProps } = otherProps;

  return (
    <label htmlFor={id} className={styles.checkbox_container}>
      <input
        type='checkbox'
        {...field}
        {...inputProps}
        name={id}
        id={id}
        onChange={(e) => {
          field.onChange(e);
          otherProps.onChange && otherProps.onChange(e);
        }}
        onBlur={(e) => {
          field.onBlur(e);
          otherProps.onBlur && otherProps.onBlur(e);
        }}
        checked={isChecked}
      />
      <span className={styles.checkbox_tick}>
        <CheckboxTickIcon />
      </span>
    </label>
  );
};
