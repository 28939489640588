import { useTranslationContext } from '../../../../services/translation';
import { allClasses } from '../../../../services/utilities/array';
import { getKeyForCondition } from '../../../../services/utilities/object';
import { formatDate } from '../../../../services/date';

import { LoadingOrContent } from '../../../../components';

import { AnalyticsSectionContainer } from '../../containers';
import { OverviewIcon } from '../../../assets/icons';
import { renderAnalyticsIcon } from '../../icons';
import { AnalyticsStatistic } from './AnalyticsStatistic';
import { InvalidResponsesText } from '../../texts';

interface AnalyticsSummaryOverviewProps {
  sectionsCount: number;
  responsesCount: number;
  invalidResponsesCount: number;
  averageDuration: number;
  completionRate: number;
  lastUpdate?: Date;
  isLoadingResponses: boolean;
}

export const AnalyticsSummaryOverview = ({
  lastUpdate,
  sectionsCount,
  responsesCount,
  invalidResponsesCount,
  averageDuration,
  completionRate,
  isLoadingResponses,
}: AnalyticsSummaryOverviewProps) => {
  const { t } = useTranslationContext.useContext();

  const data = [
    {
      icon: 'users',
      title: t('components.projects.analytics.overview.total-testers'),
      value: responsesCount,
      color: getKeyForCondition({
        'success-result': responsesCount >= 5,
        'warning-result': responsesCount >= 3 && responsesCount < 5,
        'danger-result': responsesCount < 3,
      }),
    },
    {
      icon: 'sections',
      title: t('components.projects.analytics.overview.total-sections'),
      value: sectionsCount,
      color: getKeyForCondition({
        'success-result': sectionsCount >= 5,
        'warning-result': sectionsCount >= 3 && sectionsCount < 5,
        'danger-result': sectionsCount < 3,
      }),
    },
    {
      icon: 'time',
      title: t('components.projects.analytics.overview.average-duration'),
      value: averageDuration ? `${averageDuration}s` : '-',
      color: getKeyForCondition({
        'success-result': averageDuration < 10,
        'warning-result': averageDuration >= 10 && averageDuration < 30,
        'danger-result': averageDuration >= 30,
      }),
    },
    {
      icon: 'checks',
      title: t('components.projects.analytics.overview.completion-rate'),
      value: completionRate ? `${completionRate.toFixed(2).replace(/[.,]00$/, '')}%` : '-',
      color: getKeyForCondition({
        'success-result': completionRate >= 70,
        'warning-result': completionRate >= 30 && completionRate < 70,
        'danger-result': completionRate < 30,
      }),
    },
  ] as const;

  return (
    <AnalyticsSectionContainer
      icon={<OverviewIcon />}
      rightSideInformation={
        <>
          {t('common.last-updated')}
          {lastUpdate ? formatDate(new Date(lastUpdate), 'PPp') : formatDate(new Date(), 'PPp')}
        </>
      }
      title={t('common.overview')}>
      <LoadingOrContent isLoading={isLoadingResponses} className='p-12'>
        <div className={allClasses('w-full mt-10', !!invalidResponsesCount ? 'mb-36' : 'mb-16')}>
          <div className='grid grid-cols-2 xl:grid-cols-11 h-auto'>
            {data.map((item, index) => (
              <AnalyticsStatistic key={index} isLast={index === data.length - 1} title={item.title}>
                <div className='flex flex-row items-center sm:mt-2 mt-7'>
                  <div className='md:mr-2 lg:mr-4 w-10 h-10 xl:mr-6'>
                    {renderAnalyticsIcon(item.color, item.icon, 'w-10 h-10')}
                    {index === 0 && !!invalidResponsesCount && (
                      <InvalidResponsesText
                        invalidResponsesCount={invalidResponsesCount}
                        className='absolute top-[40%] xl:top-auto'
                      />
                    )}
                  </div>
                  <span className={allClasses('text-4xl font-bold', item.color)}>{item.value}</span>
                </div>
              </AnalyticsStatistic>
            ))}
          </div>
        </div>
      </LoadingOrContent>
    </AnalyticsSectionContainer>
  );
};
