import { useMemo } from 'react';

import { sendAnalyticsEvent } from '../../../../services/app-analytics';
import { useTranslationContext } from '../../../../services/translation';
import { updateCurrentUser } from '../../../services/api-iteration1';
import { analyticsEvents, EventLocation } from '../../../services/app-analytics/events';
import { useCurrentUserContext } from '../../../services/current-user';
import { composeValidators, isRequired, isValidText } from '../../../services/validation';

import { AuthError } from './components/AuthError';
import {
  Button,
  CheckboxField,
  createFormSubmitHandler,
  Form,
  FormError,
  FormField,
} from '../../../../components';
import { SeparatorLine } from '../../../../components/separator-line/SeparatorLine';

import { Iteration1FullLogo } from '../../../assets/icons';
import { websiteRoutes } from '../../../pages/routing/routes';

export const FinishSignUpForm = () => {
  const { t } = useTranslationContext.useContext();
  const { reloadUser, currentUser } = useCurrentUserContext.useContext();

  const [firstName, middleName, lastName] = currentUser?.account.displayName?.split(' ') || [
    '',
    '',
    '',
  ];

  const initialUserValues = useMemo(
    () => ({
      firstName,
      lastName: lastName ? lastName : middleName || '',
      termsConsent: false,
      privacyConsent: false,
    }),
    [firstName, lastName, middleName]
  );

  const finishSignUp = createFormSubmitHandler<typeof initialUserValues>({
    tryAction: async ({ firstName, lastName, ...terms }) => {
      await updateCurrentUser({
        firstName,
        lastName,
        ...terms,
      });
      await reloadUser();
      sendAnalyticsEvent(
        analyticsEvents.finishSignUp({
          in_app_location: EventLocation.FinishSignUpPage,
        })
      );
    },
  });

  return (
    <div className='flex flex-col w-full max-w-sm mt-32 mb-16 mx-4'>
      <div className='mb-10'>
        <Iteration1FullLogo />
      </div>
      <h3 className='mb-1 text-2xl text-main-contrast font-bold leading-8'>
        {t('pages.finish-sign-up.last-step')}
      </h3>
      <p className='mb-10 text-base text-neutral-65 leading-5'>
        {t('pages.finish-sign-up.subtitle')}
      </p>
      <div className='mb-10'>
        <SeparatorLine />
      </div>
      <Form className='flex flex-col' initialValues={initialUserValues} onSubmit={finishSignUp}>
        {({ status, setStatus, isValidForSubmit, isSubmitting }) => (
          <>
            <FormError {...{ status }} />
            <AuthError
              handleErrorCode={(errorCode) =>
                setStatus({ state: 'error', messageKeys: [errorCode] })
              }
            />
            <div className='mb-6 flex flex-col items-start justify-between sm:flex-row'>
              <FormField
                type='text'
                name='firstName'
                label={t('form.fields.first-name')}
                placeholder={t('form.fields.first-name')}
                validate={composeValidators(isValidText, isRequired)}
                fieldClassName='w-full mb-6 sm:w-1/2 sm:mr-5 sm:mb-0'
                inputClassName='py-3 px-4 text-lg font-normal leading-normal text-main-contrast border-main-10 border-2 border-solid placeholder-neutral rounded-lg cursor-pointer hover:border-main-highlight focus:border-main-highlight focus:outline-none'
                labelClassName='text-sm text-main-contrast font-bold'
              />
              <FormField
                type='text'
                name='lastName'
                placeholder={t('form.fields.last-name')}
                label={t('form.fields.last-name')}
                validate={composeValidators(isValidText, isRequired)}
                fieldClassName='w-full sm:w-1/2'
                inputClassName='py-3 px-4 text-lg font-normal leading-normal text-main-contrast border-main-10 border-2 border-solid placeholder-neutral rounded-lg cursor-pointer hover:border-main-highlight focus:border-main-highlight focus:outline-none'
                labelClassName='text-sm text-main-contrast font-bold'
              />
            </div>
            <FormField
              disabled
              type='text'
              label='Email'
              name='email'
              value={currentUser?.account.email || ''}
              fieldClassName='mb-4'
              inputClassName='py-3 px-4 text-lg font-normal leading-normal text-main-contrast border-main-10 border-2 border-solid placeholder-neutral rounded-lg focus:border-main-highlight focus:outline-none'
              labelClassName='text-sm text-main-contrast font-bold'
            />
            <div className='flex flex-row items-center relative mb-6'>
              <FormField
                component={CheckboxField}
                type='checkbox'
                id='privacyConsent'
                name='privacyConsent'
                validate={isRequired}
                fieldClassName='w-4 h-4 mr-2'
                errorClassName='absolute mt-0.5 text-left'
                inputClassName='w-5 mr-2 cursor-pointer'
                labelClassName='text-sm text-neutral-65 cursor-pointer'
                inputContainerClassName='flex flex-row-reverse justify-end items-center'
              />
              <>
                {t('form.fields.privacy.part1')}
                <a
                  href={websiteRoutes.privacy}
                  className='text-main-contrast underline pl-0.5 hover:text-info'>
                  {t('form.fields.privacy.part2')}
                </a>
              </>
            </div>
            <div className='flex flex-row items-center relative mb-6'>
              <FormField
                component={CheckboxField}
                type='checkbox'
                id='termsConsent'
                name='termsConsent'
                validate={isRequired}
                fieldClassName='w-4 h-4 mr-2'
                errorClassName='absolute mt-0.5 text-left'
                inputClassName='w-5 mr-2 cursor-pointer'
                labelClassName='text-sm text-neutral-65 cursor-pointer'
                inputContainerClassName='flex flex-row-reverse justify-end items-center'
              />
              <>
                {t('form.fields.terms.part1')}
                <a
                  href={websiteRoutes.terms}
                  className='text-main-contrast underline pl-0.5 hover:text-info'>
                  {t('form.fields.terms.part2')}
                </a>
              </>
            </div>

            <Button
              loading={isSubmitting}
              className='button__filled w-full justify-center h-12'
              type='submit'
              disabled={!isValidForSubmit}>
              {t('pages.finish-sign-up.finish')}
            </Button>
          </>
        )}
      </Form>
    </div>
  );
};
