import { ResourceCollaborator } from '../../collaborators/api-models';
import { FolderParentOutput } from '../../folders';
import { DiscoveryProjectSection } from './sections/api-models';

export enum DiscoveryTemplate {
  Blank = 'blank',
  IdeaValidation = 'ideaValidation',
  FeedbackSurvey = 'feedbackSurvey',
  SatisfactionSurvey = 'satisfactionSurvey',
  ConceptValidation = 'conceptValidation',
  ContentTesting = 'contentTesting',
}

export interface DiscoveryOutput {
  id: string;
  name: string;
  ownerId: string;
  parents: FolderParentOutput[];
  template: DiscoveryTemplate;
  createdAt: Date;
  updatedAt: Date;
  sections: DiscoveryProjectSection[];
  collaborators?: ResourceCollaborator[];
}

export interface NewDiscoveryInput {
  name: string;
  template: DiscoveryTemplate;
  parents: FolderParentOutput[];
}

export interface DiscoveryUpdate {
  name?: string;
}

export interface DiscoveryVersionUpdate {
  name?: string;
}
