import { useAlertsContext } from './services/alerts';
import { useErrorHandlerContext } from './services/error-handling';
import { useThemeContext } from './services/theme';
import { useTranslationContext } from './services/translation';
import { ResourceFetcherWrapper } from './services/api';

import { Alerts } from './domain/components';
import { AppRoutes } from './domain/pages/routing';
import { useCurrentUserContext } from './domain/services/current-user';
import { usePermissionsContext } from './domain/services/authorization';
import { useUpgradeModalToggle } from './domain/services/billing/useUpgradeModalToggle';
import translationsEN from './domain/assets/translations/en.json';

const languageResources = {
  en: {
    translation: translationsEN,
  },
};

function App() {
  return (
    <ResourceFetcherWrapper>
      <useThemeContext.ProvideContext>
        <useTranslationContext.ProvideContext languages={languageResources}>
          <useAlertsContext.ProvideContext>
            <useErrorHandlerContext.ProvideContext>
              <useCurrentUserContext.ProvideContext>
                <usePermissionsContext.ProvideContext>
                  <useUpgradeModalToggle.ProvideContext>
                    <AppRoutes />
                    <Alerts />
                  </useUpgradeModalToggle.ProvideContext>
                </usePermissionsContext.ProvideContext>
              </useCurrentUserContext.ProvideContext>
            </useErrorHandlerContext.ProvideContext>
          </useAlertsContext.ProvideContext>
        </useTranslationContext.ProvideContext>
      </useThemeContext.ProvideContext>
    </ResourceFetcherWrapper>
  );
}

export default App;
