import { useCallback, useEffect, useMemo, useState } from 'react';

import { useErrorHandlerContext } from '../../../services/error-handling';
import { createContextService } from '../../../services/context';
import { debounce } from '../../../services/utilities/debounce';

import { useCurrentDiscoveryVersionContext } from './useCurrentDiscoveryVersion';
import {
  DiscoverySectionInput,
  DiscoverySectionType,
  initialProjectSections,
  NewSectionInput,
  SectionFileRemovalDetails,
  SectionFileUploadDetails,
} from '../api-iteration1';
import { validateSection } from '../validation';
import { useSectionsListContext } from '../sections/useSectionsList';
import { StorageFileType } from '../api-cloud-storage/api-models';

import {
  addDiscoverySection,
  deleteDiscoverySection,
  duplicateDiscoverySection,
  removeDiscoveryVersionSectionFile,
  updateDiscoverySection,
  uploadDiscoveryVersionSectionFile,
} from '../api-iteration1/projects/discovery/sections';
import { DiscoveryProjectSection } from '../api-iteration1/projects/discovery/sections/api-models';
import {
  isTemporaryFile,
  temporarySectionId,
} from '../api-iteration1/projects/api-models/sections/utils';

type ChangeStatus = 'changes-saved' | 'changes-not-saved' | 'changes-saving';

interface EditDiscoveryService {
  changeStatus: ChangeStatus;
  invalidDiscovery: boolean;
  invalidSectionsIds: string[];
  addNewSection: (newSection: NewSectionInput) => Promise<void> | undefined;
  editSection: (
    sectionId: string,
    updates: DiscoverySectionInput,
    shouldUpdate?: boolean
  ) => Promise<void> | undefined;
  removeSection: (sectionId: string) => Promise<void> | undefined;
  uploadSectionFile: (sectionId: string, fileDetails: SectionFileUploadDetails) => Promise<void>;
  removeSectionFile: (sectionId: string, fileDetails: SectionFileRemovalDetails) => Promise<void>;
  revalidate: () => void;
  duplicateSection: (sectionId: string) => Promise<void> | undefined;
}

export const useEditDiscovery = (): EditDiscoveryService => {
  const [changeStatus, setChangeStatus] = useState<ChangeStatus>('changes-saved');

  const {
    discoveryId,
    discoveryVersion,
    revalidate,
    mutateSectionLocally,
    setShouldRevalidateOnFocus,
  } = useCurrentDiscoveryVersionContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();
  const { setSectionInFocus, inFocusSectionId } = useSectionsListContext.useContext();

  const sectionInFocus = discoveryVersion?.sections.find((s) => s._id === inFocusSectionId);
  const sectionInFocusHasTemporaryFilesInEdit =
    sectionInFocus?.type === DiscoverySectionType.Preference &&
    sectionInFocus.others?.type === 'image' &&
    sectionInFocus?.files.find((f) => isTemporaryFile(f.id));

  const invalidSectionsIds =
    discoveryVersion?.sections
      .filter((section) => !validateSection(section.type, section))
      .map((s) => s._id) || [];

  const invalidDiscovery = !!invalidSectionsIds?.length;

  const addNewSection = async (newSection: NewSectionInput) => {
    if (!discoveryVersion) return;

    try {
      const tempSection = {
        _id: temporarySectionId,
        ...initialProjectSections[newSection.type as DiscoverySectionType](999999999, {}),
      };
      mutateSectionLocally(tempSection._id, tempSection as DiscoveryProjectSection);
      setSectionInFocus(tempSection._id);

      const response = await addDiscoverySection(discoveryId, discoveryVersion.id, newSection);
      const newlyCreatedSection = response.data;

      mutateSectionLocally(tempSection._id, {
        _id: newlyCreatedSection._id,
        index: newlyCreatedSection.index,
      });

      if (newlyCreatedSection._id) setSectionInFocus(newlyCreatedSection._id);
    } catch (err) {
      handleError(err);
      revalidate();
    }
  };

  const updateSection = useCallback(
    async (sectionId: string, updates: DiscoverySectionInput) => {
      if (!discoveryVersion?.id) return;

      try {
        setChangeStatus('changes-saving');
        await updateDiscoverySection(discoveryId, discoveryVersion?.id, sectionId, updates);
        setChangeStatus((prev) => (prev === 'changes-saving' ? 'changes-saved' : prev));
      } catch (err) {
        handleError(err);
        revalidate();
      }
    },
    [discoveryId, discoveryVersion?.id, handleError, revalidate]
  );

  const debouncedUpdateSection = useMemo(() => debounce(updateSection, 1000), [updateSection]);

  const editSection = async (
    sectionId: string,
    updates: DiscoverySectionInput,
    shouldUpdate = true
  ) => {
    if (!discoveryVersion) return;

    setChangeStatus('changes-not-saved');
    mutateSectionLocally(sectionId, updates);

    if (!shouldUpdate) return;

    debouncedUpdateSection(sectionId, updates);
  };

  const removeSection = async (sectionId: string) => {
    if (!discoveryVersion) return;

    try {
      mutateSectionLocally(sectionId, null);
      await deleteDiscoverySection(discoveryId, discoveryVersion.id, sectionId);
    } catch (err) {
      handleError(err);
      revalidate();
    }
  };

  const duplicateSection = async (sectionId: string) => {
    if (!discoveryVersion) return;

    try {
      await duplicateDiscoverySection(discoveryId, discoveryVersion.id, sectionId);
      revalidate();
    } catch (err) {
      handleError(err);
    }
  };

  const uploadSectionFile = async (sectionId: string, fileDetails: SectionFileUploadDetails) => {
    if (!discoveryVersion?.id) return;

    const uploadResult = await uploadDiscoveryVersionSectionFile({
      discoveryId,
      versionId: discoveryVersion.id,
      sectionId,
      fileDetails,
    });

    const section = discoveryVersion.sections.find((s) => s._id === sectionId);
    const currentSectionFiles =
      section?.files.filter((file) => {
        return uploadResult.type === StorageFileType.SectionImage
          ? file.type !== StorageFileType.SectionImage
          : uploadResult.type === StorageFileType.FirstClickImage
          ? file.type !== StorageFileType.FirstClickImage
          : uploadResult.type === StorageFileType.FiveSecondsImage
          ? file.type !== StorageFileType.FiveSecondsImage
          : uploadResult.type === StorageFileType.SectionMultipleImages
          ? file.type === StorageFileType.SectionMultipleImages &&
            file.indexId !== uploadResult.indexId
          : true;
      }) || [];

    const update = {
      files: [...currentSectionFiles, uploadResult],
    };

    mutateSectionLocally(sectionId, update);
  };

  const removeSectionFile = async (sectionId: string, fileDetails: SectionFileRemovalDetails) => {
    const section = discoveryVersion?.sections.find((s) => s._id === sectionId);
    if (!discoveryVersion || !section) return;

    const remainingFiles = section?.files.filter((f) => f.id !== fileDetails.id);

    mutateSectionLocally(sectionId, {
      files: remainingFiles,
    });

    try {
      await removeDiscoveryVersionSectionFile({
        discoveryId,
        versionId: discoveryVersion.id,
        sectionId,
        fileDetails,
      });
    } catch (err) {
      revalidate();
      throw err;
    }
  };

  useEffect(() => {
    setShouldRevalidateOnFocus(sectionInFocusHasTemporaryFilesInEdit ? false : true);
  }, [sectionInFocusHasTemporaryFilesInEdit, setShouldRevalidateOnFocus]);

  return {
    changeStatus,
    invalidDiscovery,
    invalidSectionsIds,
    addNewSection,
    editSection,
    removeSection,
    uploadSectionFile,
    removeSectionFile,
    revalidate,
    duplicateSection,
  };
};

export const useEditDiscoveryContext = createContextService(useEditDiscovery);
