import { useCallback, useEffect, useMemo, useState } from 'react';

import { useErrorHandlerContext } from '../../../services/error-handling';
import { createContextService } from '../../../services/context';
import { debounce } from '../../../services/utilities/debounce';

import {
  DiscoverySectionType,
  Frame,
  initialProjectSections,
  NewSectionInput,
  SectionFileRemovalDetails,
  SectionFileUploadDetails,
} from '../api-iteration1';
import { useCurrentUserTestVersionContext } from './useCurrentUserTestVersion';
import { validateSection } from '../validation';
import { useSectionsListContext } from '../sections/useSectionsList';
import { StorageFileType } from '../api-cloud-storage/api-models';

import {
  addUserTestSection,
  deleteUserTestSection,
  duplicateUserTestSection,
  removeUserTestVersionSectionFile,
  updateUserTestSection,
  uploadUserTestVersionSectionFile,
} from '../api-iteration1/projects/userTest/sections';
import {
  UserTestProjectSection,
  UserTestProjectSectionInput,
} from '../api-iteration1/projects/userTest/sections/api-models';
import {
  isTemporaryFile,
  temporarySectionId,
} from '../api-iteration1/projects/api-models/sections/utils';
import { DesignPlatform } from '../api-iteration1/design-platforms';

type ChangeStatus = 'changes-saved' | 'changes-not-saved' | 'changes-saving';

interface EditUserTestService {
  invalidUserTest: boolean;
  changeStatus: ChangeStatus;
  invalidSectionsIds: string[];
  addNewSection: (newSection: NewSectionInput) => Promise<void> | undefined;
  editSection: (
    sectionId: string,
    updates: UserTestProjectSectionInput,
    shouldUpdate?: boolean
  ) => Promise<void> | undefined;
  removeSection: (sectionId: string) => Promise<void> | undefined;
  uploadSectionFile: (sectionId: string, fileDetails: SectionFileUploadDetails) => Promise<void>;
  removeSectionFile: (sectionId: string, fileDetails: SectionFileRemovalDetails) => Promise<void>;
  revalidate: () => void;
  duplicateSection: (sectionId: string) => Promise<void> | undefined;
}

export const useEditUserTest = (): EditUserTestService => {
  const [changeStatus, setChangeStatus] = useState<ChangeStatus>('changes-saved');

  const {
    userTestId,
    userTest,
    userTestVersion,
    revalidate,
    mutateSectionLocally,
    setShouldRevalidateOnFocus,
  } = useCurrentUserTestVersionContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();
  const { setSectionInFocus, inFocusSectionId } = useSectionsListContext.useContext();

  const invalidSectionsIds =
    userTestVersion?.sections
      .filter((section) => !validateSection(section.type, section))
      .map((s) => s._id) || [];
  const sectionInFocus = userTestVersion?.sections.find((s) => s._id === inFocusSectionId);
  const sectionInFocusHasTemporaryFilesInEdit =
    sectionInFocus?.type === DiscoverySectionType.Preference &&
    sectionInFocus.others?.type === 'image' &&
    sectionInFocus?.files.find((f) => isTemporaryFile(f.id));

  const invalidUserTest = !!invalidSectionsIds?.length;

  const addNewSection = async (newSection: NewSectionInput) => {
    if (!userTestVersion || !userTest) return;

    try {
      const initialFrame =
        userTestVersion.designPrototype.type === DesignPlatform.Figma
          ? userTestVersion.designPrototype.frames.find((frame) => frame.index === 0)
          : ({} as Frame);
      const tempSection = {
        _id: temporarySectionId,
        ...initialProjectSections[newSection.type](999999999, {
          designPlatform: userTest.designPrototype.type,
          initialFrameId: initialFrame?.id || '',
        }),
      };
      mutateSectionLocally(tempSection._id, tempSection as UserTestProjectSection);
      setSectionInFocus(tempSection._id);

      const response = await addUserTestSection(userTestId, userTestVersion.id, newSection);
      const newlyCreatedSection = response.data;

      mutateSectionLocally(tempSection._id, {
        _id: newlyCreatedSection._id,
        index: newlyCreatedSection.index,
      });

      if (newlyCreatedSection._id) setSectionInFocus(newlyCreatedSection._id);
    } catch (err) {
      handleError(err);
      revalidate();
    }
  };

  const updateSection = useCallback(
    async (sectionId: string, updates: UserTestProjectSectionInput) => {
      if (!userTestVersion?.id) return;

      try {
        setChangeStatus('changes-saving');
        await updateUserTestSection(userTestId, userTestVersion?.id, sectionId, updates);
        setChangeStatus((prev) => (prev === 'changes-saving' ? 'changes-saved' : prev));
      } catch (err) {
        handleError(err);
        revalidate();
      }
    },
    [userTestId, userTestVersion?.id, handleError, revalidate]
  );

  const debouncedUpdateSection = useMemo(() => debounce(updateSection, 1000), [updateSection]);

  const editSection = async (
    sectionId: string,
    updates: UserTestProjectSectionInput,
    shouldUpdate = true
  ) => {
    if (!userTestVersion) return;

    setChangeStatus('changes-not-saved');
    mutateSectionLocally(sectionId, updates);

    if (!shouldUpdate) return;

    debouncedUpdateSection(sectionId, updates);
  };

  const removeSection = async (sectionId: string) => {
    if (!userTestVersion) return;

    try {
      mutateSectionLocally(sectionId, null);
      await deleteUserTestSection(userTestId, userTestVersion.id, sectionId);
    } catch (err) {
      handleError(err);
      revalidate();
    }
  };

  const duplicateSection = async (sectionId: string) => {
    if (!userTestVersion) return;

    try {
      await duplicateUserTestSection(userTestId, userTestVersion.id, sectionId);
      revalidate();
    } catch (err) {
      handleError(err);
    }
  };

  const uploadSectionFile = async (sectionId: string, fileDetails: SectionFileUploadDetails) => {
    if (!userTestVersion?.id) return;

    const uploadResult = await uploadUserTestVersionSectionFile({
      userTestId,
      versionId: userTestVersion.id,
      sectionId,
      fileDetails,
    });

    const section = userTestVersion.sections.find((s) => s._id === sectionId);
    const currentSectionFiles =
      section?.files.filter((file) => {
        return uploadResult.type === StorageFileType.SectionImage
          ? file.type !== StorageFileType.SectionImage
          : uploadResult.type === StorageFileType.FiveSecondsImage
          ? file.type !== StorageFileType.FiveSecondsImage
          : uploadResult.type === StorageFileType.SectionMultipleImages
          ? file.type === StorageFileType.SectionMultipleImages &&
            file.indexId !== uploadResult.indexId
          : true;
      }) || [];

    const update = {
      files: [...currentSectionFiles, uploadResult],
    };

    mutateSectionLocally(sectionId, update);
  };

  const removeSectionFile = async (sectionId: string, fileDetails: SectionFileRemovalDetails) => {
    const section = userTestVersion?.sections.find((s) => s._id === sectionId);
    if (!userTestVersion || !section) return;

    const remainingFiles = section?.files.filter((f) => f.id !== fileDetails.id);

    mutateSectionLocally(sectionId, {
      files: remainingFiles,
    });

    try {
      await removeUserTestVersionSectionFile({
        userTestId,
        versionId: userTestVersion.id,
        sectionId,
        fileDetails,
      });
    } catch (err) {
      revalidate();
      throw err;
    }
  };

  useEffect(() => {
    setShouldRevalidateOnFocus(sectionInFocusHasTemporaryFilesInEdit ? false : true);
  }, [sectionInFocusHasTemporaryFilesInEdit, setShouldRevalidateOnFocus]);

  return {
    invalidUserTest,
    invalidSectionsIds,
    changeStatus,
    addNewSection,
    editSection,
    removeSection,
    uploadSectionFile,
    removeSectionFile,
    revalidate,
    duplicateSection,
  };
};

export const useEditUserTestContext = createContextService(useEditUserTest);
