import { useEffect } from 'react';

import { FirebaseAuthError } from '../../../../../services/auth';
import { useCurrentUserContext } from '../../../../services/current-user';

interface AuthErrorProps {
  handleErrorCode: (code: string) => void;
}

export const AuthError = ({ handleErrorCode }: AuthErrorProps) => {
  const { socialAuthError, clearSocialAuthError } = useCurrentUserContext.useContext();

  useEffect(() => {
    if (socialAuthError) handleErrorCode((socialAuthError as FirebaseAuthError)?.code);
    clearSocialAuthError();
  }, [clearSocialAuthError, handleErrorCode, socialAuthError]);

  return null;
};
