import { getKeyForCondition } from '../../../../services/utilities/object';

import {
  DiscoverySection,
  DiscoveryVersionResponseOutput,
  PrototypeClick,
  UsabilitySection,
  UsabilitySingleGoalAnswer,
} from '../../api-iteration1';
import { StorageFile } from '../../api-cloud-storage/api-models';

import { UserTestVersionResponseOutput } from '../../api-iteration1/projects/userTest/responses';
import {
  FigmaBoundingBox,
  FigmaDesignPrototypeVersion,
} from '../../api-iteration1/design-platforms';

type FrameAnswer = {
  id: string;
  index: number;
  clicks: PrototypeClick[];
  duration?: number;
};

export type ScreenAnalytics = {
  frameId: string;
  timeSpent: number;
  missclicksRate: number;
  testersCount: number;
  completionRate: number;
  screenName: string;
  screenImage: StorageFile;
  screenBounds: FigmaBoundingBox;
  usabilityScore: number;
  usabilityScoreColor: string;
  clicks: PrototypeClick[];
  usabilityScoreType: 'all' | 'great' | 'check' | 'rework';
  isLastFrame: boolean;
};

export const calculateAvgSingleGoalDuration = (answers: UsabilitySingleGoalAnswer[]): number => {
  if (!answers.length) return 0;

  const averageDuration = answers.reduce((result, answer) => {
    return (
      result +
      answer.frames.reduce((result, frame: Record<string, any>) => {
        return result + (frame.duration || 0);
      }, 0)
    );
  }, 0);

  return Number((averageDuration / 1000 / answers.length).toFixed(1));
};

export const calculateAvgTestDuration = (
  responses: (DiscoveryVersionResponseOutput | UserTestVersionResponseOutput)[]
): number => {
  if (!responses.length) return 0;

  const totalTestsDuration = responses.reduce((result, response) => {
    return (
      result +
      Object.values(response.answers).reduce((result, answer) => result + answer.duration, 0)
    );
  }, 0);

  return Number((totalTestsDuration / 1000 / responses.length).toFixed(1));
};

export const calculateSingleGoalCompletionRate = (answers: UsabilitySingleGoalAnswer[]): number => {
  if (!answers.length) return 0;

  return Math.round(
    (answers.filter(
      (answer) => answer.successType === 'direct' || answer.successType === 'indirect'
    ).length /
      answers.length) *
      100
  );
};

export const calculateSingleGoalSuccessfullCompletionRate = (
  answers: UsabilitySingleGoalAnswer[],
  successType: UsabilitySingleGoalAnswer['successType']
): number => {
  if (!answers.length) return 0;

  const completionRate =
    (answers.filter((answer) => answer.successType === successType).length / answers.length) * 100;

  const doubleDecimalCompletionRate = Number(completionRate.toFixed(2).replace(/[.,]00$/, ''));

  return doubleDecimalCompletionRate;
};

export const countSingleGoalBounces = (answers: UsabilitySingleGoalAnswer[]): number => {
  if (!answers.length) return 0;

  return (
    answers.length -
    answers.filter((answer) => answer.successType === 'direct' || answer.successType === 'indirect')
      .length
  );
};

export const calculateSingleGoalMisclickRate = (answers: UsabilitySingleGoalAnswer[]) => {
  if (!answers.length) return 0;

  let misclicksTotalCount = 0;
  let framesTotalCount = 0;

  answers.forEach((answer) => {
    framesTotalCount += answer.frames.length;

    answer.frames.forEach((frame) => {
      const missclicksCount =
        frame.clicks.filter((click) => click.type === 'missclick').length || 0;

      misclicksTotalCount += missclicksCount;
    });
  });

  const missclickRate = (misclicksTotalCount / (misclicksTotalCount + framesTotalCount)) * 100;

  const doubleDecimalMissclickRate = Number(missclickRate.toFixed(2).replace(/[.,]00$/, ''));

  return doubleDecimalMissclickRate;
};

export const calculateSingleGoalUsabilityScore = ({
  avgDuration,
  testersCount,
  missclicksRate,
  completionRate,
  framesCount,
}: {
  avgDuration: number;
  testersCount: number;
  missclicksRate: number;
  completionRate: number;
  framesCount: number;
}) => {
  const durationWeight =
    (1 / 4) *
    (1 / 5) *
    Number(
      getKeyForCondition({
        0: avgDuration >= 60 * framesCount,
        1: avgDuration >= 45 * framesCount && avgDuration < 60 * framesCount,
        2: avgDuration >= 30 * framesCount && avgDuration < 45 * framesCount,
        3: avgDuration >= 15 * framesCount && avgDuration < 30 * framesCount,
        4: avgDuration >= 5 * framesCount && avgDuration < 15 * framesCount,
        5: avgDuration < 5 * framesCount,
      })
    );

  const testersWeight =
    (1 / 4) *
    (1 / 5) *
    Number(
      getKeyForCondition({
        0: !testersCount,
        1: testersCount >= 1 && testersCount < 2,
        2: testersCount >= 2 && testersCount < 3,
        3: testersCount >= 3 && testersCount < 4,
        4: testersCount >= 4 && testersCount < 5,
        5: testersCount >= 5,
      })
    );

  const misclicksWeight =
    (1 / 4) *
    (1 / 5) *
    Number(
      getKeyForCondition({
        0: missclicksRate >= 50,
        1: missclicksRate >= 30 && testersCount < 50,
        2: missclicksRate >= 20 && testersCount < 30,
        3: missclicksRate >= 10 && testersCount < 20,
        4: missclicksRate >= 5 && testersCount < 10,
        5: missclicksRate < 5,
      })
    );

  const completionWeight = (1 / 4) * (completionRate / 100);

  const score = (durationWeight + testersWeight + misclicksWeight + completionWeight) * 10;

  const singleDigitScore = Number(score.toFixed(1));

  return singleDigitScore;
};

export const calculateResponseCompletionRate = (
  completedSections: number,
  expectedCompletedSections: number
): number => {
  if (!completedSections) return 0;
  if (!expectedCompletedSections) return 100;

  return Math.round((completedSections / expectedCompletedSections) * 100);
};

export const calculateTotalResponseCompletionRate = ({
  responses,
  sections,
  isRequired,
}: {
  responses: (DiscoveryVersionResponseOutput | UserTestVersionResponseOutput)[] | undefined;
  sections: (DiscoverySection | UsabilitySection)[];
  isRequired: (section: DiscoverySection | UsabilitySection) => boolean;
}): number => {
  if (!responses?.length) return 0;

  const requiredSectionsCount = sections.filter(isRequired).length;

  if (!requiredSectionsCount) return 100;

  return Math.round(
    responses.reduce((result, response) => {
      const answeredRequiredSectionsCount = Object.entries(response.answers).filter(
        ([sectionId, _answer]) => {
          const currentSection = sections.find((section) => section._id === sectionId);
          return currentSection ? isRequired(currentSection) : false;
        }
      ).length;

      return (
        result +
        calculateResponseCompletionRate(answeredRequiredSectionsCount, requiredSectionsCount)
      );
    }, 0) / responses.length
  );
};

export const calculateMissclicksForFrame = (frame: FrameAnswer) => {
  if (!frame.clicks) return 0;

  const missclicksTotalCount =
    frame.clicks.filter((click: PrototypeClick) => click.type === 'missclick').length || 0;

  const missclickRate = (missclicksTotalCount / frame.clicks.length) * 100;

  return Number(missclickRate.toFixed(0));
};

export const calculateTimeSpentForFrame = (
  answers: UsabilitySingleGoalAnswer[],
  frame: FrameAnswer
) => {
  let timeSpent = 0;

  answers.forEach((answer) => {
    answer.frames.map((frameItem) => {
      if (frame.id === frameItem.id) {
        if (frameItem.duration) {
          timeSpent += frameItem.duration;
        }
      }
    });
  });

  return Number(timeSpent / 1000);
};

export const computeFramesAnalytics = (
  answers: UsabilitySingleGoalAnswer[],
  responsesCount: number,
  designPrototype?: FigmaDesignPrototypeVersion
) => {
  const uniqueFramesData: ScreenAnalytics[] = [];

  answers.forEach((answer) => {
    answer.frames.forEach((frame: FrameAnswer, index: number) => {
      const designFrame = designPrototype?.frames.filter((item) => item.id === frame.id)[0];
      const missClicksCount = frame.clicks.filter((click) => click.type === 'missclick').length;
      const frameTimeSpentInSeconds = frame.duration ? frame.duration / 1000 : 0;
      const alreadyAddedFrame = uniqueFramesData.find(
        (item: ScreenAnalytics) => item.frameId === frame.id
      );

      const usabilityScore = calculateSingleGoalUsabilityScore({
        avgDuration: calculateTimeSpentForFrame(answers, frame),
        testersCount: responsesCount,
        missclicksRate: calculateMissclicksForFrame(frame),
        completionRate:
          (answers.filter((answer) => answer.successType === answer.successType).length /
            responsesCount) *
          100,
        framesCount: 1,
      });

      if (!alreadyAddedFrame) {
        uniqueFramesData.push({
          frameId: frame.id,
          timeSpent: frameTimeSpentInSeconds,
          missclicksRate: missClicksCount,
          testersCount: responsesCount,
          completionRate:
            (answers.filter((answer) => answer.successType === answer.successType).length /
              responsesCount) *
            100,
          screenName: designFrame?.name || '',
          screenImage: designFrame?.image || { id: '', name: '' },
          screenBounds: designFrame?.bounds || { x: 0, y: 0, width: 0, height: 0 },
          usabilityScore: usabilityScore,
          usabilityScoreColor: getKeyForCondition({
            'bg-main-highlight': usabilityScore >= 7,
            'bg-warning': usabilityScore >= 3 && usabilityScore < 7,
            'bg-danger': usabilityScore < 3,
          }),
          clicks: frame.clicks || [],
          usabilityScoreType: getKeyForCondition({
            great: usabilityScore >= 7,
            check: usabilityScore >= 3 && usabilityScore < 7,
            rework: usabilityScore < 3,
          }),
          isLastFrame: index === answer.frames.length - 1,
        });
      } else {
        const frameToUpdate = uniqueFramesData.findIndex(
          (item: ScreenAnalytics) => item.frameId === frame.id
        );

        uniqueFramesData[frameToUpdate] = {
          ...alreadyAddedFrame,
          timeSpent: alreadyAddedFrame.timeSpent + frameTimeSpentInSeconds,
          missclicksRate: alreadyAddedFrame.missclicksRate + missClicksCount,
          clicks: [...(alreadyAddedFrame.clicks || []), ...frame.clicks],
        };
      }
    });
  });

  const screensToRework = uniqueFramesData.filter(
    (frame: ScreenAnalytics) => !frame.isLastFrame && frame.usabilityScore < 3
  ).length;
  const screensToCheck = uniqueFramesData.filter(
    (frame: ScreenAnalytics) =>
      !frame.isLastFrame && frame.usabilityScore >= 3 && frame.usabilityScore < 7
  ).length;
  const greatScreens = uniqueFramesData.filter(
    (frame: ScreenAnalytics) => !frame.isLastFrame && frame.usabilityScore >= 7
  ).length;

  return {
    uniqueFramesData,
    screensToRework,
    screensToCheck,
    greatScreens,
  };
};
