import { Head } from '../../../components';
import { AccessView } from './components';
import { DeviceAccessView } from './components/DeviceAccessView';

import { routes } from '../../pages/routing/routes';
import { LayoutProps } from './utilities';
import { AnalyticsScreenName } from './AnalyticsScreenName';

type NoFooterLayoutProps = LayoutProps;

export const NoFooterLayout = ({
  id,
  children,
  title,
  analyticsName,
  availableOnMobile,
  ...accessProps
}: NoFooterLayoutProps) => {
  return (
    <>
      <Head {...{ title }} />
      <AnalyticsScreenName name={analyticsName} />
      <DeviceAccessView {...{ availableOnMobile }}>
        <AccessView redirectUrl={routes.home} {...accessProps}>
          <div className='min-h-screen bg-main flex justify-center items-center' {...{ id }}>
            {children}
          </div>
        </AccessView>
      </DeviceAccessView>
    </>
  );
};
