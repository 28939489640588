import { getDuplicateValues } from '../../../../services/utilities/array';
import { isUndefined } from '../../../../services/utilities/value';

import { StorageFileType } from '../../api-cloud-storage/api-models';
import {
  Frame,
  Path,
  prototypeClickTypes,
  SectionStorageFile,
  UsabilitySectionType,
} from '../../api-iteration1';

export const sectionValidators: Record<
  UsabilitySectionType,
  (section: Record<string, any>) => boolean
> = {
  [UsabilitySectionType.SingleGoal]: (section) => {
    if (!section.others || typeof section.others !== 'object') return false;
    if (!section.others.title) return false;

    const paths = section.others.paths;
    if (!paths || !Array.isArray(paths) || !paths.length) return false;
    if (getIdenticalPathsIndexes(paths).length) return false;

    const invalidPaths = paths.filter((path) => {
      const hasInvalidFrameLength = path.frames && path.frames?.length < 2;
      const identicalFramesIds = getDuplicateValues(path.frames.map((f: Frame) => f.id));

      return hasInvalidFrameLength || !!identicalFramesIds.length;
    });

    if (invalidPaths.length) return false;

    return true;
  },
  [UsabilitySectionType.MultipleGoals]: () => true,
  [UsabilitySectionType.FirstClick]: (section) => {
    if (!section.title) return false;
    const { others } = section;
    const sectionImage = section.files.find(
      (f: SectionStorageFile) => f.type === StorageFileType.FirstClickImage
    );

    if (!others || typeof others !== 'object' || !['image', 'prototype'].includes(others.type)) {
      return false;
    }

    if (others.type === 'image') {
      const hasInvalidImage =
        !sectionImage ||
        (sectionImage &&
          (typeof sectionImage !== 'object' ||
            typeof sectionImage.id !== 'string' ||
            typeof sectionImage.name !== 'string'));
      if (hasInvalidImage) return false;
    }

    return section.others.frame
      ? !isUndefined(section.others.frame.index) && !isUndefined(section.others.frame.id)
      : true;
  },
  [UsabilitySectionType.FreePlay]: () => true,
  [UsabilitySectionType.FiveSeconds]: (section) => {
    if (!section.others || typeof section.others !== 'object') return false;

    const others = section.others;
    const sectionImage = section.files.find(
      (f: SectionStorageFile) => f.type === StorageFileType.FiveSecondsImage
    );

    if (typeof others !== 'object') return false;

    if ((others.seconds && typeof others.seconds !== 'number') || others.seconds < 5) return false;

    if (others.type && !['image', 'prototype'].includes(others.type)) return false;

    if (others.type === 'image') {
      const hasInvalidImage =
        !sectionImage ||
        (sectionImage &&
          (typeof sectionImage !== 'object' ||
            typeof sectionImage.id !== 'string' ||
            typeof sectionImage.name !== 'string'));
      if (hasInvalidImage) return false;
    } else if (others.type === 'prototype') {
      return others.frame
        ? !isUndefined(others.frame.index) && !isUndefined(others.frame.id)
        : true;
    }

    return true;
  },
};

export const answerValidators: Record<
  UsabilitySectionType,
  (answer: Record<string, any>) => boolean
> = {
  [UsabilitySectionType.SingleGoal]: (answer) => {
    if (!answer || !answer.frames) return false;

    const validFrames = answer.frames.filter((frame: any) => {
      const hasValidClicks = !frame.clicks.filter((click: any) => !validateClick(click)).length;

      return (
        !!frame &&
        typeof frame === 'object' &&
        typeof frame.id === 'string' &&
        typeof frame.index === 'number' &&
        typeof frame.duration === 'number' &&
        hasValidClicks
      );
    });

    return validFrames.length === answer.frames.length;
  },
  [UsabilitySectionType.MultipleGoals]: () => false,
  [UsabilitySectionType.FirstClick]: () => false,
  [UsabilitySectionType.FreePlay]: () => false,
  [UsabilitySectionType.FiveSeconds]: () => false,
};

const validateClick = (click: any): boolean => {
  return (
    typeof click === 'object' &&
    typeof click.x === 'number' &&
    typeof click.y === 'number' &&
    typeof click.timestamp === 'number' &&
    prototypeClickTypes.includes(click.type)
  );
};

export const getIdenticalPathsIndexes = (paths: Path[]): number[] => {
  const validPaths = paths.filter((path) => path.frames.length > 1);

  const notEnoughPaths = validPaths.length < 2;
  if (notEnoughPaths) return [];

  const pathsLastFrames = validPaths.map((path) =>
    path.frames.length > 1 ? path.frames[path.frames.length - 1] : undefined
  );

  const pathsWithSameLastFrame = validPaths.reduce((result, path, index) => {
    const framesWithoutLastFrame = [...path.frames].slice(0, -1);
    const framesWithoutLastFrameIds = framesWithoutLastFrame.map((frame) => frame.id);

    const identicalPathIndex = pathsLastFrames.findIndex(
      (frame) => frame && framesWithoutLastFrameIds.includes(frame.id)
    );

    const isSamePath = index === identicalPathIndex;
    const isIdenticalPath = identicalPathIndex !== -1 && !isSamePath;

    return isIdenticalPath ? [...result, index, identicalPathIndex] : result;
  }, [] as number[]);

  const pathFramesAsStrings = validPaths.reduce((result, path) => {
    result[path.index] = String(path.frames.map((frame) => frame.id));
    return result;
  }, {} as Record<string, string>);

  const completelyIdenticalPaths = Object.entries(pathFramesAsStrings).reduce(
    (result, [currentPathIndex, framesIdsAsString]) => {
      const [identicalPathIndex] = Object.keys(pathFramesAsStrings).filter(
        (pathIndex) =>
          pathIndex !== currentPathIndex && pathFramesAsStrings[pathIndex] === framesIdsAsString
      );

      return [...result, Number(currentPathIndex), Number(identicalPathIndex)];
    },
    [] as number[]
  );

  const allIdenticalPaths = [...pathsWithSameLastFrame, ...completelyIdenticalPaths];

  const uniqueIdenticalPaths = [...new Set(allIdenticalPaths)];

  return uniqueIdenticalPaths;
};
