import { useEffect, useState } from 'react';

import { handleErrorSilently } from '../error-handling';

export type PreloadedResourceProps = JSX.IntrinsicElements['link'] & { href: string };

interface PreloadedResourceStatus {
  status: 'idle' | 'loading' | 'ready' | 'error';
  href: string;
}

export const usePreloadResources = (resources: PreloadedResourceProps[]) => {
  const [statuses, setStatuses] = useState<PreloadedResourceStatus[]>([]);

  const resultStatus: PreloadedResourceStatus['status'] =
    statuses.filter((status) => status.status === 'idle').length === statuses.length
      ? 'idle'
      : statuses.filter((status) => status.status === 'ready').length === statuses.length
      ? 'ready'
      : statuses.filter((status) => status.status === 'error').length > 0
      ? 'error'
      : 'loading';

  useEffect(() => {
    if (!resources) return;

    const allStatusesAsIdle = resources.map((res) => ({ href: res.href, status: 'idle' } as const));
    setStatuses(allStatusesAsIdle);

    const removeListeners = resources.map((linkProps) => {
      let link = document.querySelector(`link[href="${linkProps.href}"]`) as HTMLLinkElement;

      if (!link) {
        link = document.createElement('link');
        Object.entries(linkProps).forEach(([key, val]) => {
          (link as any)[key] = val;
        });

        link.setAttribute('data-status', 'loading');

        document.head.appendChild(link);

        const setAttributeFromEvent = (event: Event) => {
          link.setAttribute('data-status', event.type === 'load' ? 'ready' : 'error');
        };

        link.addEventListener('load', setAttributeFromEvent);
        link.addEventListener('error', setAttributeFromEvent);
      } else {
        // Grab existing link status from attribute and set to state.
        setStatuses((prev) => {
          const resource = prev.find((res) => res.href === linkProps.href);

          if (resource)
            resource.status =
              (link.getAttribute('data-status') as PreloadedResourceStatus['status']) || 'idle';

          return [...prev];
        });
      }

      const setStateFromEvent = (event: Event) => {
        setStatuses((prev) => {
          const resource = prev.find((res) => res.href === linkProps.href);

          if (resource) resource.status = event.type === 'load' ? 'ready' : 'error';

          return [...prev];
        });
      };

      link.addEventListener('load', setStateFromEvent);
      link.addEventListener('error', setStateFromEvent);

      return () => {
        if (link) {
          link.removeEventListener('load', setStateFromEvent);
          link.removeEventListener('error', setStateFromEvent);
        }
      };
    });

    return () => {
      removeListeners.map((remove) => {
        try {
          remove();
        } catch (err) {
          handleErrorSilently(err);
        }
      });
    };
  }, [resources]);

  return resultStatus;
};
