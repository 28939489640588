import './index.css';

import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';

import { initErrorReporting } from './services/error-handling/reporting';

import App from './App';
import reportWebVitals from './reportWebVitals';

initErrorReporting();

const container: HTMLElement | null = document.getElementById('iteration1_root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
