import { ProjectVersionResponsePermission } from '@iteration1/permission-validators';

import {
  DiscoverySection,
  DiscoverySectionType,
  isDiscoverySection,
  isUsabilitySection,
  UsabilitySection,
  UsabilitySectionFirstClick,
  UsabilitySectionType,
} from '../../services/api-iteration1';
import { useTranslationContext } from '../../../services/translation';
import { usePermissionsContext } from '../../services/authorization';

import { OverviewIcon } from '../../assets/icons';
import { SelectSectionItem } from '../containers';
import { renderSectionIcon } from '../icons';
import { ResponseLimitReachedCard } from '../cards';

interface AnalyticsSectionListProps {
  totalResponsesCount: number;
  sections: (DiscoverySection | UsabilitySection)[];
  versionName?: string;
  setSectionInFocus: (sectionId: string) => void;
  inFocusSectionId: string;
}

export const AnalyticsSectionList = ({
  totalResponsesCount,
  sections,
  versionName,
  setSectionInFocus,
  inFocusSectionId,
}: AnalyticsSectionListProps) => {
  const { t } = useTranslationContext.useContext();
  const { checkPermission, userPermissionsPlan } = usePermissionsContext.useContext();

  const responseLimitUserPlan =
    userPermissionsPlan?.[ProjectVersionResponsePermission.create.id].limit;

  const renderTitle = (section: DiscoverySection | UsabilitySection) => {
    if (isDiscoverySection(section)) return section.title;

    if (isUsabilitySection(section) && section.type !== UsabilitySectionType.FirstClick)
      return section.others.title as string;

    if (isUsabilitySection(section) && section.type === UsabilitySectionType.FirstClick)
      return (section as UsabilitySectionFirstClick).title as string;

    return '';
  };

  const hasResponseCountUnderLimit = checkPermission(ProjectVersionResponsePermission.create, {
    count: totalResponsesCount,
  });

  return (
    <div className='scroll-container max-w-22 w-1/2 min-w-min overflow-y-auto mr-5 pr-2 pt-16'>
      <div className='w-full flex flex-col justify-start items-center'>
        {typeof responseLimitUserPlan !== 'undefined' &&
          !hasResponseCountUnderLimit &&
          totalResponsesCount > responseLimitUserPlan && (
            <ResponseLimitReachedCard
              responseCount={totalResponsesCount}
              limit={responseLimitUserPlan}
            />
          )}
        <SelectSectionItem
          alreadyAdded
          description={t('common.overview')}
          title={versionName || t('components.projects.sections.section-title-placeholder')}
          icon={<OverviewIcon />}
          onClick={() => setSectionInFocus('overview')}
          isExpanded={inFocusSectionId === 'overview' || !inFocusSectionId}
        />
        {sections &&
          sections.map((section: DiscoverySection | UsabilitySection, index: number) => (
            <SelectSectionItem
              alreadyAdded
              description={
                section.type === UsabilitySectionType.FiveSeconds
                  ? t('components.projects.analytics.responses.five-seconds.five-seconds-test')
                  : t(`components.projects.sections.field-types.${section.type}`)
              }
              title={
                renderTitle(section as DiscoverySection | UsabilitySection) ||
                (section.type === UsabilitySectionType.FiveSeconds
                  ? t('components.projects.sections.field-types.5seconds')
                  : t('components.projects.sections.section-title-placeholder'))
              }
              key={index}
              icon={renderSectionIcon(section.type as DiscoverySectionType | UsabilitySectionType)}
              onClick={() => setSectionInFocus(section._id)}
              isExpanded={inFocusSectionId === section._id}
              showRequiredIcon={section.required}
            />
          ))}
      </div>
    </div>
  );
};
