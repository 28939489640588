import { useTranslationContext } from '../../../../../services/translation';
import {
  DiscoveryMultipleChoiceAnswer,
  DiscoverySectionMultipleChoiceOutput,
} from '../../../../services/api-iteration1';
import { allClasses } from '../../../../../services/utilities/array';

import { LoadingOrContent } from '../../../../../components';

import { AnalyticsPercentage } from '../AnalyticsPercentage';
import { AnalyticsSectionContainer } from '../../../containers';
import { MultipleChoiceIcon } from '../../../../assets/icons';
import { AnalyticsSummaryStatistic } from '../../../headers/AnalyticsSummaryStatistic';

interface AnalyticsMultipleChoiceSummaryProps {
  answers: DiscoveryMultipleChoiceAnswer[];
  section: DiscoverySectionMultipleChoiceOutput;
  sectionsCount: number;
  isLoadingResponses: boolean;
}

export const AnalyticsMultipleChoiceSummary = ({
  answers,
  section,
  sectionsCount,
  isLoadingResponses,
}: AnalyticsMultipleChoiceSummaryProps) => {
  const { t } = useTranslationContext.useContext();

  const totalMultipleChoiceAnswers = answers.reduce((result, answer) => {
    if (answer?.choices) result += answer.choices.length;
    return result;
  }, 0);

  const getAnswersForSingleChoice = (choice: number) => {
    return answers.filter((item) => item.choice === choice).length;
  };

  const getAnswersForMultipleChoice = (choice: any) => {
    return answers.filter((item) => item.choices?.includes(choice)).length;
  };

  const calculatePercentage = (answersNumber: number | undefined, choiceType: string) => {
    if (!answersNumber) return 0;

    const numerator = choiceType === 'single' ? answers.length : totalMultipleChoiceAnswers;

    if (!numerator) return 0;
    return Math.round((answersNumber / numerator) * 100);
  };

  const otherChoices: string[] | undefined = answers.reduce((result, answer) => {
    if (typeof answer.choice === 'string') result.push(answer.choice);
    return result;
  }, [] as string[]);

  return (
    <AnalyticsSectionContainer
      contentTitle={section.title}
      description={section.others?.description as string}
      icon={<MultipleChoiceIcon />}
      rightSideInformation={
        <AnalyticsSummaryStatistic
          currentSectionIndex={section.index}
          answersCount={answers.length || 1}
          {...{ sectionsCount }}
        />
      }
      title={t('components.projects.sections.field-types.multipleChoice')}>
      <LoadingOrContent isLoading={isLoadingResponses} className='pb-12'>
        <div className='w-full mt-20 mb-16'>
          {answers.length ? (
            <div className='flex flex-col justify-between max-w-3xl'>
              {section?.others?.choices && (
                <>
                  {(section?.others?.choices as string[]).map((choice: string, index: number) => (
                    <ViewAnalyticsMultipleChoiceAnswerBlock
                      key={`${choice}-${index}`}
                      answersNumber={
                        section?.others?.type === 'single'
                          ? getAnswersForSingleChoice(index)
                          : getAnswersForMultipleChoice(index)
                      }
                      answersPercentage={calculatePercentage(
                        section?.others?.type === 'single'
                          ? getAnswersForSingleChoice(index)
                          : getAnswersForMultipleChoice(index),
                        section?.others?.type as string
                      )}
                      choiceName={choice}
                      isLast={index === (section?.others?.choices as []).length - 1}
                    />
                  ))}
                  {otherChoices && otherChoices.length > 0 && (
                    <ViewAnalyticsMultipleChoiceAnswerBlock
                      answersNumber={otherChoices.length}
                      answersPercentage={calculatePercentage(
                        otherChoices.length,
                        section?.others?.type as string
                      )}
                      choiceName={t('common.other')}
                      extraInfo={otherChoices}
                      isLast
                    />
                  )}
                </>
              )}
            </div>
          ) : (
            <p className='text-center font-medium text-danger'>
              {t('components.projects.analytics.no-answers')}
            </p>
          )}
        </div>
      </LoadingOrContent>
    </AnalyticsSectionContainer>
  );
};

const ViewAnalyticsMultipleChoiceAnswerBlock = (props: {
  answersNumber?: number;
  answersPercentage: number;
  choiceName: string;
  extraInfo?: string[];
  isLast: boolean;
}) => {
  const { answersNumber, answersPercentage, choiceName, extraInfo, isLast } = props;

  return (
    <div className={allClasses('w-full text-left mt-2', isLast ? 'mb-0' : 'mb-5')}>
      <span className='text-xl leading-30 font-bold'>{choiceName}</span>
      <p>{extraInfo?.join(', ')}</p>
      <div className='w-full flex flex-row flex-1 items-center justify-between'>
        <div className='w-11/12 h-16 relative bg-main-10 rounded-lg'>
          <div
            style={{
              width: `${answersPercentage}%`,
            }}
            className='h-16 absolute left-0 bottom-0 rounded-lg bg-main-highlight'></div>
        </div>
        <div className='w-16 ml-1 text-right'>
          <AnalyticsPercentage
            answerPercentage={answersPercentage}
            answersNumber={answersNumber || 0}
          />
        </div>
      </div>
    </div>
  );
};
