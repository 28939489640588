import { useTranslationContext } from '../../../services/translation';
import { useCurrentDiscoveryVersionContext } from '../../services/discovery/useCurrentDiscoveryVersion';
import { useRouter } from '../../../services/router';
import { useEditDiscoveryContext } from '../../services/discovery';
import { usePreviewModalContext } from '../../services/preview';
import { useActionInProgressContext } from '../../../services/progress';

import { Modal } from '../../../components/modal';

import { CloseIcon } from '../../assets/icons';
import { dynamicRoutes } from '../../pages/routing/routes';
import { EditProjectNavbarActions } from './EditProjectNavbarActions';
import { ViewDiscoveryFlow } from '../views';

interface EditDiscoveryNavbarActionsProps {
  onClickRename: (name: string) => void;
  onClickDelete: () => void;
}

export const EditDiscoveryNavbarActions = ({
  onClickDelete,
  onClickRename,
}: EditDiscoveryNavbarActionsProps) => {
  const router = useRouter();
  const { t } = useTranslationContext.useContext();
  const { hasActionsInProgress } = useActionInProgressContext.useContext();
  const {
    discoveryId,
    discoveryVersionId: versionId,
    discoveryVersion,
  } = useCurrentDiscoveryVersionContext.useContext();
  const { invalidDiscovery, changeStatus } = useEditDiscoveryContext.useContext();

  const { isOpenPreviewModal, openPreviewModal, closePreviewModal } =
    usePreviewModalContext.useContext();

  const goToStartTesting = () => {
    if (!versionId) return;

    router.push(
      dynamicRoutes.start_testing.discovery(
        discoveryId,
        versionId,
        discoveryVersion?.sections.length || 0
      )
    );
  };

  return (
    <>
      <EditProjectNavbarActions
        disabledTest={invalidDiscovery || hasActionsInProgress}
        changeStatus={changeStatus}
        onClickPreview={openPreviewModal}
        onClickStartTesting={goToStartTesting}
        onClickDelete={onClickDelete}
        onClickRename={onClickRename}
        projectType='discovery'
        status={discoveryVersion?.status}
      />
      {discoveryId && versionId && (
        <Modal
          isOpen={isOpenPreviewModal}
          onClose={closePreviewModal}
          headerClassName='bg-main-contrast max-w-none h-auto items-center'
          headerTitleComponent={
            <p className='text-white'>{t('components.projects.discovery.in-preview')}</p>
          }
          variant='no-scroll-full-page'
          closeModalComponent={<CloseIcon className='icon-hover-main-contrast' />}>
          <div className='relative flex flex-1'>
            <ViewDiscoveryFlow
              inPreviewVersion={discoveryVersion}
              {...{ discoveryId, versionId }}
            />
          </div>
        </Modal>
      )}
    </>
  );
};
