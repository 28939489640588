import { useCallback } from 'react';

import { createContextService } from '../../../services/context';

import { useCurrentUserContext } from '../current-user';
import { FolderChildType } from '../api-iteration1';

import { useCurrentUserCollaborators } from '../api-iteration1/collaborators';
import { Collaborator } from '../api-iteration1/collaborators/api-models';

const useUserCollaborators = () => {
  const { isAuthenticated } = useCurrentUserContext.useContext();
  const { data, mutate } = useCurrentUserCollaborators(
    { waitForFetch: !isAuthenticated },
    { revalidateOnFocus: false }
  );

  const mutateCollaboratorsLocally = useCallback(
    async (
      collaboration: {
        collaboratorId: string;
        resourceId?: string;
        resourceType?: FolderChildType;
      },
      updates: Partial<Collaborator> | null
    ) => {
      mutate((prev) => {
        if (!prev) return;

        const { collaboratorId, resourceId, resourceType } = collaboration;

        const data: Collaborator[] = prev.data;

        const collaboratorToUpdate = data.find(
          (collaborator) => collaborator.id === collaboratorId
        );

        if (!collaboratorToUpdate && updates) {
          const updatedCollaboratorsList = [...data, updates] as Collaborator[];

          return { ...prev, data: updatedCollaboratorsList };
        }

        const updatedCollaboratorsList = data.reduce<Collaborator[]>((result, collaborator) => {
          const isTargetCollaborator = collaborator.id === collaboratorId;

          if (isTargetCollaborator) {
            const shouldRemoveCollaboration = !updates;

            if (shouldRemoveCollaboration) {
              const updatedResources = collaborator.resources.filter(
                (resource) => resource.id !== resourceId && resource.type !== resourceType
              );

              return updatedResources.length
                ? [...result, { ...collaborator, resources: updatedResources }]
                : result;
            } else {
              return [...result, { ...collaborator, ...updates }];
            }
          }

          return [...result, collaborator];
        }, []);

        return {
          ...prev,
          data: updatedCollaboratorsList,
        };
      }, false);
    },
    [mutate]
  );

  return {
    currentUserCollaborators: isAuthenticated ? data || [] : [],
    mutateLocallyCurrentUserCollaborators: mutateCollaboratorsLocally,
    revalidate: mutate,
  };
};

export const useUserCollaboratorsContext = createContextService(useUserCollaborators);
