import { useEffect, useMemo, useState } from 'react';
import {
  createResourcePermissionValidator,
  getPermissionPlan,
  PermissionPlans,
  UserPermissionsConfig,
} from '@iteration1/permission-validators';

import { createContextService } from '../../../services/context';
import { useErrorHandlerContext } from '../../../services/error-handling';

import { getCurrentUserPermissionConfig } from '../api-iteration1';
import { useCurrentUserContext } from '../current-user';

import { isSubscriptionActive } from '../api-iteration1/billing';

const usePermissions = () => {
  const { handleError } = useErrorHandlerContext.useContext();
  const { isAuthenticated, currentUser, signOut } = useCurrentUserContext.useContext();

  const [userPermissionConfig, setUserPermissionConfig] = useState<UserPermissionsConfig>({});

  const userPlan = useMemo(() => {
    if (currentUser?.internalPlan) return PermissionPlans.Internal;

    const isActive = isSubscriptionActive(currentUser?.subscription);
    const subscriptionPlan = currentUser?.subscription?.plan.name;
    return subscriptionPlan && isActive ? subscriptionPlan : PermissionPlans.IndividualFree;
  }, [currentUser?.internalPlan, currentUser?.subscription]);

  const userPermissionsPlan = useMemo(() => getPermissionPlan(userPlan), [userPlan]);

  const checkPermission = useMemo(() => {
    if (!userPermissionConfig || !userPermissionsPlan) return () => false;

    const validator = createResourcePermissionValidator(userPermissionsPlan, userPermissionConfig);

    return validator;
  }, [userPermissionConfig, userPermissionsPlan]);

  useEffect(() => {
    if (!isAuthenticated)
      return setUserPermissionConfig((prev) => {
        const hadPreviouslySetPermissions = !!Object.keys(prev).length;

        // Only rerender if permissions were already set
        return hadPreviouslySetPermissions ? {} : prev;
      });

    getCurrentUserPermissionConfig()
      .then((config) => setUserPermissionConfig(config))
      .catch((err) => {
        handleError(err);
        signOut();
        setUserPermissionConfig({});
      });
  }, [handleError, isAuthenticated, signOut]);

  return {
    checkPermission,
    userPlan,
    userPermissionsPlan,
    willCancelSubscriptionAtPeriodEnd: currentUser?.subscription?.cancelAtPeriodEnd,
  };
};

export const usePermissionsContext = createContextService(usePermissions);
