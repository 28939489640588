import { differenceInMilliseconds } from '../../../../services/date';
import { FolderChildType } from '../../../services/api-iteration1';
import { UserTestVersionResponseOutput } from '../../../services/api-iteration1/projects/userTest/responses';
import { useCurrentUserTestVersionContext } from '../../../services/userTest';
import {
  calculateAvgTestDuration,
  calculateTotalResponseCompletionRate,
} from '../../../services/analytics/calculus';
import useAnalyticsRouting from '../../../services/analytics-routing/useAnalyticsRouting';

import { AnalyticsResponses } from '../analytics/AnalyticsResponses';
import { AnalyticsSummary } from '../analytics/AnalyticsSummary';

interface UserTestVersionResultProps {
  shownTab: 'summary' | 'responses';
  validResponses: UserTestVersionResponseOutput[];
  invalidResponsesCount: number;
  isLoading: boolean;
}

export const UserTestVersionResult = ({
  shownTab,
  validResponses,
  invalidResponsesCount,
  isLoading,
}: UserTestVersionResultProps) => {
  const { userTestVersion, sortedSections } = useCurrentUserTestVersionContext.useContext();

  const { inFocusSectionId, setSectionInFocus } = useAnalyticsRouting();

  const responsesSortedByDate = validResponses.sort((item1, item2) => {
    return differenceInMilliseconds(new Date(item2.createdAt), new Date(item1.createdAt));
  });

  const completionRate = calculateTotalResponseCompletionRate({
    responses: responsesSortedByDate,
    sections: sortedSections,
    // at this moment we took the decision to consider all sections as required in the computation
    isRequired: () => true,
  });

  return (
    <div className='overflow-hidden mx-auto w-full flex flex-row xl:pl-2'>
      {shownTab === 'summary' ? (
        <AnalyticsSummary
          versionName={userTestVersion?.name}
          sections={sortedSections}
          responses={responsesSortedByDate}
          invalidResponsesCount={invalidResponsesCount}
          inFocusResponseId={inFocusSectionId}
          averageDuration={calculateAvgTestDuration(responsesSortedByDate)}
          designPrototype={userTestVersion?.designPrototype}
          isLoadingResponses={isLoading}
          {...{ completionRate, setSectionInFocus }}
        />
      ) : (
        <AnalyticsResponses
          sections={sortedSections}
          responses={responsesSortedByDate}
          invalidResponsesCount={invalidResponsesCount}
          inFocusResponseId={inFocusSectionId}
          setResponseInFocus={setSectionInFocus}
          projectType={FolderChildType.UserTest}
          designPrototype={userTestVersion?.designPrototype}
          versionName={userTestVersion?.name}
          isLoadingResponses={isLoading}
        />
      )}
    </div>
  );
};
