import { useState } from 'react';

import { allClasses } from '../../services/utilities/array';
import { getKeyForCondition } from '../../services/utilities/object';

import { CloseIcon, InfoIcon } from '../../domain/assets/icons';

interface NotificationProps {
  title: string;
  description: string;
  timeout?: number;
  placement?: 'bottom-left' | 'bottom-right';
}

export const Notification = ({
  title,
  description,
  timeout = 3000,
  placement = 'bottom-right',
}: NotificationProps) => {
  const [isHidden, setIsHidden] = useState<boolean>(false);

  const onClose = () => setIsHidden(true);

  setTimeout(() => {
    setIsHidden(true);
  }, timeout);

  if (isHidden) return null;

  return (
    <div
      className={allClasses(
        'flex flex-row items-start max-w-[380px] fixed bottom-[2.5rem] p-5 bg-main-contrast rounded-lg',
        isHidden && 'hidden',
        getKeyForCondition({
          'left-[2.5rem]': placement === 'bottom-left',
          'right-[2.5rem]': placement === 'bottom-right',
        })
      )}>
      <CloseIcon
        className='absolute top-2 right-2 icon-neutral-40 cursor-pointer icon-hover-main'
        onClick={onClose}
        data-testid='notification-close'
      />
      <InfoIcon className='icon-main-highlight icon-circle-main-highlight icon-polyline-main-highlight' />
      <div className='flex flex-col pl-2.5'>
        <div className='mb-2.5 text-lg leading-6 text-main-highlight font-normal'>{title}</div>
        <div className='text-neutral-40 leading-5'>{description}</div>
      </div>
    </div>
  );
};
