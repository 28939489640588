import React from 'react';

import { allClasses } from '../../services/utilities/array';

import { OutsideModalClick } from '../modal';

interface OptionsMenuProps {
  id: string;
  label: string;
  children: React.ReactNode;
  className?: string;
}

export const OptionsMenu = ({ id, label, children, className }: OptionsMenuProps) => {
  return (
    <div className='duration-150 ease-in-out' id={id} aria-label={label} aria-haspopup='true'>
      <OutsideModalClick isVisibleModal />
      <div
        className={allClasses(
          'w-28 origin-top-right absolute right-0 z-50 mt-7 border-2 border-main-10 border-solid rounded-lg bg-main text-center',
          className
        )}>
        <div
          className='p-1 rounded-md bg-main flex flex-col items-center justify-center'
          role='menu'
          aria-orientation='vertical'
          aria-labelledby={id}>
          {children}
        </div>
      </div>
    </div>
  );
};
