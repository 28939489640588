import { ProjectType } from '../api-iteration1';

import { BillingPeriod } from '../api-iteration1/billing/api-models';

export enum EventLocation {
  FinishSignUpPage = 'FinishSignUpPage',
}

export const analyticsEvents = {
  finishSignUp: (eventContent: { in_app_location: EventLocation }) => ({
    eventName: 'FINISH_SIGN_UP',
    eventContent,
  }),
  upgradePlan: (eventContent: {
    user_id: string;
    old_plan_name: string;
    new_plan_name: string;
    billing_type: BillingPeriod;
  }) => ({
    eventName: 'UPGRADE_PLAN',
    eventContent,
  }),
  downgradePlan: (eventContent: {
    user_id: string;
    old_plan_name: string;
    new_plan_name: string;
    feedback?: string;
  }) => ({
    eventName: 'DOWNGRADE_PLAN',
    eventContent,
  }),
  deleteAccount: (eventContent: {
    user_id: string;
    plan_name: string;
    billing_type?: BillingPeriod;
    reason: string;
    email?: string;
  }) => ({
    eventName: 'DELETE_ACCOUNT',
    eventContent,
  }),
  publishProject: (eventContent: {
    user_id: string;
    plan_name: string;
    billing_type?: BillingPeriod;
    project_type: ProjectType;
    project_section_count?: number;
  }) => ({
    eventName: 'PUBLISH_PROJECT',
    eventContent,
  }),
};
