// eslint-disable-next-line import/no-named-as-default
import produce from 'immer';

import { AuthenticatedUser } from './AuthenticatedUser';

export enum CurrentUserAuthActions {
  Success,
  Fail,
}

type CurrentUserAction =
  | {
      type: CurrentUserAuthActions.Success;
      user: AuthenticatedUser;
    }
  | {
      type: CurrentUserAuthActions.Fail;
      error?: unknown;
    };

export interface CurrentUserResult {
  currentUser: AuthenticatedUser | undefined;
  isLoading: boolean;
  error: unknown | undefined;
}

export type CurrentUserReducer = (
  draft: CurrentUserResult,
  action: CurrentUserAction
) => CurrentUserResult;

export const currentUserReducer: CurrentUserReducer = produce(
  (draft: CurrentUserResult, action: CurrentUserAction) => {
    switch (action.type) {
      case CurrentUserAuthActions.Success:
        draft.currentUser = action.user;
        draft.isLoading = false;
        draft.error = undefined;
        break;
      case CurrentUserAuthActions.Fail:
        draft.currentUser = undefined;
        draft.isLoading = false;
        draft.error = action.error;
        break;
    }
  }
);
