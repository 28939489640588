import { ChangeEventHandler } from 'react';
import { Field as FormikField, FieldAttributes, ErrorMessage } from 'formik';

import { allClasses } from '../../services/utilities/array';
import { useTranslationContext } from '../../services/translation';

interface InputProps extends FieldAttributes<any> {
  inputClassName?: string;
  placeholder?: string;
  validate?: (value: any) => string | void | Promise<string | void>;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;
}

interface FormFieldProps extends InputProps {
  fieldClassName?: string;
  inputContainerClassName?: string;
  labelClassName?: string;
  errorClassName?: string;
  label?: string;
}

export const FormField = (props: FormFieldProps) => {
  const { t } = useTranslationContext.useContext();

  const {
    fieldClassName,
    inputContainerClassName,
    labelClassName,
    inputClassName,
    errorClassName,
    label,
    ...fieldProps
  } = props;

  return (
    <div className={fieldClassName}>
      <div className={inputContainerClassName}>
        {label && (
          <label className={labelClassName} htmlFor={fieldProps.id}>
            {label}
          </label>
        )}
        <FormikField
          {...fieldProps}
          className={allClasses(
            'p-2 my-1 block',
            fieldProps.type !== 'checkbox' && 'w-full',
            inputClassName
          )}
        />
      </div>
      <div
        className={allClasses(
          'text-danger text-sm bg-transparent w-full text-left mt-1',
          errorClassName
        )}>
        <ErrorMessage name={fieldProps.name} render={(errorKey) => t(errorKey)} />
      </div>
    </div>
  );
};
