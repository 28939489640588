import { useCallback } from 'react';

import { useTranslationContext } from '../../../../services/translation';
import { useShowInstructionsContext, useViewUserTest } from '../../../services/userTest';
import { UserTestVersionOutput } from '../../../services/api-iteration1/projects/userTest/versions';
import {
  DefaultSectionType,
  isDiscoverySection,
  isImageFiveSeconds,
  isUsabilitySection,
} from '../../../services/api-iteration1';
import { useTestPauseModalContext } from '../../../../services/record/useTestPauseModal';

import { ProgressLine } from '../../../../components/progress-line/ProgressLine';

import { NoNavbarLayout } from '../../layout';
import { NotFoundLayout } from '../../layout/NotFoundLayout';
import { TestNavbarLeftButton, TestNavbarRightButton } from '../../buttons';
import { UnavailableUserTestFlow } from './UnavailableUserTestFlow';
import { SectionView } from '../sections/view';
import { userTestViewId } from '../../config';
import { PausedTestContainer } from './PausedTestContainer';
import { testGiveUpReasons } from '../../../config';

interface ViewUserTestFlowProps {
  userTestId: string;
  versionId: string;
  inPreviewVersion?: UserTestVersionOutput;
  isEmbeddedIframe?: boolean;
}

export { ViewUserTestFlowContainer as ViewUserTestFlow };

const ViewUserTestFlowContainer = (props: ViewUserTestFlowProps) => {
  return (
    <useShowInstructionsContext.ProvideContext>
      <useTestPauseModalContext.ProvideContext>
        <ViewUserTestFlow {...props} />
      </useTestPauseModalContext.ProvideContext>
    </useShowInstructionsContext.ProvideContext>
  );
};

const ViewUserTestFlow = ({
  userTestId,
  versionId,
  inPreviewVersion,
  isEmbeddedIframe,
}: ViewUserTestFlowProps) => {
  const { t } = useTranslationContext.useContext();
  const { isVisibleInstructionsAgain } = useShowInstructionsContext.useContext();
  const {
    currentSection,
    currentSectionScreenIndex,
    isLastScreen,
    isLoading,
    isFirst,
    isLast,
    isAnswerableScreen,
    nextSection,
    continueFlow,
    restartSection,
    sectionAnswer,
    setSectionAnswer,
    startUserTestFlow,
    giveUpTesting,
    sendFeedback,
    immediatelySubmitAnswer,
    debouncedSubmitAnswer,
    availableInstructions,
    canSeeInstructions,
    canContinue,
    canGiveUp,
    canOnlyContinue,
    canSkip,
    isDone,
    isDraft,
    error,
    totalAnswerableSections,
    designPrototype,
    submitAndContinueFlow,
    isAvailableOnMobile,
    skippingSectionInProgress,
  } = useViewUserTest({
    userTestId,
    versionId,
    inPreviewVersion,
  });

  const inPreview = !!inPreviewVersion;
  const inTestingMode = !inPreview;
  const automaticSpacing =
    !isEmbeddedIframe &&
    (isImageFiveSeconds(currentSection) ||
      !(isUsabilitySection(currentSection) && isAnswerableScreen) ||
      isVisibleInstructionsAgain);

  const onFinishAnswerScreen = useCallback(
    (status?: { restart?: boolean | undefined }) => {
      if (status?.restart) restartSection();
      else continueFlow();
    },
    [continueFlow, restartSection]
  );

  if (inTestingMode && isDraft) return <NotFoundLayout />;

  const showProgress =
    currentSection &&
    currentSection.type !== DefaultSectionType.Welcome &&
    currentSection.type !== DefaultSectionType.ThankYou;

  const lastFinishedSectionIndex =
    currentSectionScreenIndex > 0 && isLastScreen && currentSection?.index
      ? currentSection?.index + 1
      : currentSection?.index;
  const currentProgress = lastFinishedSectionIndex
    ? (lastFinishedSectionIndex / totalAnswerableSections) * 100
    : 0;

  return (
    <NoNavbarLayout
      analyticsName={inPreview ? 'Preview UserTest' : 'Test UserTest'}
      title={t('pages.userTest.view')}
      header={showProgress && <ProgressLine completed={currentProgress} />}
      footerLeftComponent={
        !isFirst && (
          <TestNavbarLeftButton
            availableInstructions={availableInstructions}
            {...{ isLast, canSkip, nextSection, canSeeInstructions }}
          />
        )
      }
      footerRightComponent={
        !isFirst && (
          <TestNavbarRightButton
            onClickContinue={submitAndContinueFlow}
            onClickGiveUp={giveUpTesting}
            canGiveUp={canGiveUp}
            actionType={
              canOnlyContinue || canContinue || isDiscoverySection(currentSection)
                ? 'continue'
                : 'give-up'
            }
            {...{ isLast, canContinue }}
          />
        )
      }
      isLoading={isLoading || skippingSectionInProgress}
      error={error}
      availableOnMobile={isAvailableOnMobile || !!isEmbeddedIframe}>
      {isDone ? (
        <UnavailableUserTestFlow />
      ) : (
        <div id={userTestViewId} className='w-full flex flex-col items-center h-full'>
          <SectionView
            interactive
            automaticSpacing={automaticSpacing}
            section={currentSection}
            answer={sectionAnswer}
            onSetAnswer={setSectionAnswer}
            onStart={startUserTestFlow}
            onIntermediateAnswer={inTestingMode ? debouncedSubmitAnswer : undefined}
            onSaveAnswerImmediately={inTestingMode ? immediatelySubmitAnswer : undefined}
            onSendFeedback={inTestingMode ? sendFeedback : undefined}
            onFinishAnswerScreen={onFinishAnswerScreen}
            {...{ designPrototype, currentSectionScreenIndex, isEmbeddedIframe }}
          />
          <PausedTestContainer
            onPressGiveUp={() => inTestingMode && giveUpTesting(testGiveUpReasons.pause_quit)}
          />
        </div>
      )}
    </NoNavbarLayout>
  );
};
