import { useCallback, useEffect, useRef } from 'react';

import { isSafari } from '../../device';
import { useScript } from '../../scripts';
import { CanvasDrawer } from './canvas-draw';

interface RecordCanvasService {
  canStartRecording: boolean;
  startRecording: () => void;
  getRecording: () => Blob | undefined;
  stopRecording: (onSaveVideo: (video: Blob | undefined) => void) => void;
}

interface RecordCanvasServiceProps {
  recordingCanvasId: string;
  htmlElementToRecordId: string;
  onError?: (err: any) => void;
}

const safariPlaceholder: RecordCanvasService = {
  canStartRecording: false,
  startRecording: () => ({}),
  stopRecording: (callback: (video: Blob | undefined) => void) => callback(undefined),
  getRecording: () => undefined,
};

const safariRecorderPlaceholder = (): RecordCanvasService => safariPlaceholder;

export const useRecordCanvas = isSafari()
  ? safariRecorderPlaceholder
  : ({
      recordingCanvasId,
      htmlElementToRecordId,
      onError,
    }: RecordCanvasServiceProps): RecordCanvasService => {
      const status = useScript('https://www.webrtc-experiment.com/screenshot.js');

      const canvasDrawer = useRef<CanvasDrawer | null>(null);
      const recordedChunks = useRef<any>([]);
      const mediaRecorder = useRef<MediaRecorder | null>(null);

      const cleanRecordingState = useCallback(() => {
        canvasDrawer.current = null;
        recordedChunks.current = [];
        mediaRecorder.current = null;
      }, []);

      const startRecording = useCallback(() => {
        if (canvasDrawer.current) return;

        canvasDrawer.current = new CanvasDrawer(
          recordingCanvasId,
          { width: document.body.clientWidth, height: document.body.clientHeight },
          onError
        );

        const stream = canvasDrawer.current.recordingCanvas.captureStream(30);

        mediaRecorder.current = new MediaRecorder(stream);

        mediaRecorder.current.addEventListener('dataavailable', async (event: BlobEvent) => {
          if (event.data.size > 0) {
            recordedChunks.current.push(event.data);
          }
        });
        mediaRecorder.current.start(100);

        canvasDrawer.current.draw({ htmlElementToRecordId });
      }, [htmlElementToRecordId, onError, recordingCanvasId]);

      const getRecording = useCallback(() => {
        return new Blob(recordedChunks.current, {
          type: 'video/webm; codecs=vp8',
        });
      }, []);

      const stopRecording = useCallback(
        async (onSaveVideo: (video: Blob) => void) => {
          mediaRecorder.current?.addEventListener('stop', () => {
            const video = getRecording();
            if (video) onSaveVideo(video);
            cleanRecordingState();
          });

          canvasDrawer.current?.stopDrawing();
          mediaRecorder.current?.stop();
        },
        [cleanRecordingState, getRecording]
      );

      useEffect(() => {
        const abortAnyRecordingInProgress = () => {
          canvasDrawer.current?.stopDrawing();
        };

        return () => {
          abortAnyRecordingInProgress();
        };
      }, []);

      return {
        canStartRecording: status === 'ready',
        startRecording,
        stopRecording,
        getRecording,
      };
    };
