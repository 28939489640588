import { DiscoverySectionType, UsabilitySectionType } from '../../services/api-iteration1';
import { useTranslationContext } from '../../../services/translation';

import {
  FiveSecondsTestIcon,
  FirstClickIcon,
  MultipleChoiceIcon,
  OpenWordChoiceIcon,
  RatingScaleIcon,
  SingleGoalIcon,
  YesNoIcon,
  PreferenceTestIcon,
  LegalIcon,
} from '../../assets/icons';
import { SelectSectionItem } from '../containers/SelectSectionItem';

interface NewUserTestSectionsProps {
  onClick: ({ type }: { type: UsabilitySectionType | DiscoverySectionType }) => void;
}

export const NewUserTestSections = ({ onClick }: NewUserTestSectionsProps) => {
  const { t } = useTranslationContext.useContext();

  return (
    <>
      <SelectSectionItem
        key={UsabilitySectionType.SingleGoal}
        title={t('components.projects.sections.field-types.singleGoal')}
        description={t('components.projects.sections.field-types-descriptions.singleGoal')}
        icon={<SingleGoalIcon />}
        onClick={() => onClick({ type: UsabilitySectionType.SingleGoal })}
        type={UsabilitySectionType.SingleGoal}
        alreadyAdded={false}
      />
      <SelectSectionItem
        key={UsabilitySectionType.FirstClick}
        title={t('components.projects.sections.field-types.firstClick')}
        description={t('components.projects.sections.field-types.firstClick')}
        icon={<FirstClickIcon />}
        onClick={() => onClick({ type: UsabilitySectionType.FirstClick })}
        type={UsabilitySectionType.FirstClick}
        alreadyAdded={false}
      />
      <SelectSectionItem
        key={UsabilitySectionType.FiveSeconds}
        title={t('components.projects.sections.field-types.5seconds')}
        description={t('components.projects.sections.field-types-descriptions.5seconds')}
        icon={<FiveSecondsTestIcon />}
        onClick={() => onClick({ type: UsabilitySectionType.FiveSeconds })}
        type={UsabilitySectionType.FiveSeconds}
        alreadyAdded={false}
      />
      <SelectSectionItem
        key={DiscoverySectionType.YesNo}
        title={t('components.projects.sections.field-types.yesNo')}
        description={t('components.projects.sections.field-types-descriptions.yesNo')}
        icon={<YesNoIcon />}
        onClick={() => onClick({ type: DiscoverySectionType.YesNo })}
        type={DiscoverySectionType.YesNo}
        alreadyAdded={false}
      />
      <SelectSectionItem
        key={DiscoverySectionType.RatingScale}
        title={t('components.projects.sections.field-types.ratingScale')}
        description={t('components.projects.sections.field-types-descriptions.ratingScale')}
        icon={<RatingScaleIcon />}
        onClick={() => onClick({ type: DiscoverySectionType.RatingScale })}
        type={DiscoverySectionType.RatingScale}
        alreadyAdded={false}
      />
      <SelectSectionItem
        key={DiscoverySectionType.MultipleChoice}
        title={t('components.projects.sections.field-types.multipleChoice')}
        description={t('components.projects.sections.field-types-descriptions.multipleChoice')}
        icon={<MultipleChoiceIcon />}
        onClick={() =>
          onClick({
            type: DiscoverySectionType.MultipleChoice,
          })
        }
        type={DiscoverySectionType.MultipleChoice}
        alreadyAdded={false}
      />
      <SelectSectionItem
        key={DiscoverySectionType.OpenWordChoice}
        title={t('components.projects.sections.field-types.openWordChoice')}
        description={t('components.projects.sections.field-types-descriptions.openWordChoice')}
        icon={<OpenWordChoiceIcon />}
        onClick={() => onClick({ type: DiscoverySectionType.OpenWordChoice })}
        type={DiscoverySectionType.OpenWordChoice}
        alreadyAdded={false}
      />
      <SelectSectionItem
        key={DiscoverySectionType.Preference}
        title={t('components.projects.sections.field-types.preference')}
        description={t('components.projects.sections.field-types-descriptions.preference')}
        icon={<PreferenceTestIcon />}
        onClick={() => onClick({ type: DiscoverySectionType.Preference })}
        type={DiscoverySectionType.Preference}
        alreadyAdded={false}
      />
      <SelectSectionItem
        key={DiscoverySectionType.Legal}
        title={t('components.projects.sections.field-types.legal')}
        description={t('components.projects.sections.field-types-descriptions.legal')}
        icon={<LegalIcon />}
        onClick={() => onClick({ type: DiscoverySectionType.Legal })}
        type={DiscoverySectionType.Legal}
        alreadyAdded={false}
        containerClassName='rounded-b-lg'
        childrenClassName='border-b-0'
      />
    </>
  );
};
