import { useEffect, useRef } from 'react';

import { RecordDuration } from './RecordDuration';
import { useTestPauseModalContext } from './useTestPauseModal';

import { useShowInstructionsContext } from '../../domain/services/userTest';

interface TestSectionDurationServiceProps {
  isAnswerableView: boolean;
  sectionId?: string | undefined;
  initialDelay?: number;
}

export const useTestSectionDuration = ({
  isAnswerableView,
  sectionId,
}: TestSectionDurationServiceProps) => {
  const { isPaused, closePauseModal, openPauseModal, isUnmovedMouse } =
    useTestPauseModalContext.useContext();
  const { isVisibleInstructionsAgain } = useShowInstructionsContext.useContext();

  const sectionDurationRecorder = useRef(new RecordDuration());

  const shouldPause = isPaused || !isAnswerableView || isVisibleInstructionsAgain;

  useEffect(() => {
    if (shouldPause) {
      sectionDurationRecorder.current.pauseCurrentDuration();
    } else {
      sectionDurationRecorder.current.resumeCurrentDuration();
    }
  }, [shouldPause]);

  useEffect(() => {
    if (isUnmovedMouse && isAnswerableView) openPauseModal();
    else if (!isAnswerableView) closePauseModal();
  }, [closePauseModal, isAnswerableView, isUnmovedMouse, openPauseModal]);

  useEffect(() => {
    if (isAnswerableView && sectionId) {
      sectionDurationRecorder.current.restart();
    }
  }, [isAnswerableView, sectionId]);

  return sectionDurationRecorder;
};
