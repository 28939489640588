import { ReactNode } from 'react';

import { getDeviceDetails } from '../../../../services/device';
import { useWindowSize } from '../../../../services/window-size';

import { AppNotAvailable } from '../../empty-states/AppNotAvailable';

interface DeviceAccessViewProps {
  children?: ReactNode;
  availableOnMobile: boolean;
}

export const DeviceAccessView = ({ children, availableOnMobile }: DeviceAccessViewProps) => {
  const { width: windowWidth } = useWindowSize();

  const showContent =
    availableOnMobile ||
    (getDeviceDetails().deviceType.toLowerCase() === 'desktop' && windowWidth >= 1000);

  return showContent ? <>{children}</> : <AppNotAvailable />;
};
