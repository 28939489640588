import { useCallback } from 'react';

import { useLocation, useRouteParams, useRouter } from '../../../services/router';

interface AnalyticsRoutingService {
  inFocusSectionId: string;
  setSectionInFocus: (sectionId: string) => void;
  setAnalyticsRoute: (tabId: string) => void;
}

type RouteParams = {
  projectId: string;
  tab: 'summary' | 'responses';
  tabSectionId: string;
};

const useAnalyticsRouting = (): AnalyticsRoutingService => {
  const { push } = useRouter();
  const location = useLocation();
  const routeParams = useRouteParams<RouteParams>();

  const setAnalyticsRoute = useCallback(
    (tabId: string) => {
      push(tabId);
    },
    [push]
  );

  const setSectionInFocus = useCallback(
    (sectionId: string) => {
      const path = location.pathname.split('/');
      const prevSectionIdIndex = path.findIndex(
        (pathItem: string) => pathItem === routeParams.tabSectionId
      );

      if (prevSectionIdIndex !== -1) {
        path.splice(prevSectionIdIndex, 1, sectionId);
      } else {
        path.push(sectionId);
      }

      const pathToPush = path.join('/') + location.search;
      push(pathToPush);
    },
    [location.pathname, location.search, push, routeParams.tabSectionId]
  );

  return {
    inFocusSectionId: routeParams.tabSectionId,
    setSectionInFocus,
    setAnalyticsRoute,
  };
};

export default useAnalyticsRouting;
