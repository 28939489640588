import { SectionStorageFile } from './common';
import { UsabilitySectionType } from './usability';
import { SectionPropertyOthers } from './utils';

export enum DiscoverySectionType {
  YesNo = 'yesNo',
  MultipleChoice = 'multipleChoice',
  RatingScale = 'ratingScale',
  OpenWordChoice = 'openWordChoice',
  Preference = 'preference',
  Legal = 'legal',
}

export type PreferenceSectionText = { context: string; indicator: string };

export interface DiscoverySectionInput<
  T extends SectionPropertyOthers<any> = SectionPropertyOthers<any>
> {
  title?: string;
  files?: SectionStorageFile[];
  others: T;
}

export interface DiscoverySection {
  _id: string;
  index: number;
  title: string;
  type: DiscoverySectionType | UsabilitySectionType.FiveSeconds | UsabilitySectionType.FirstClick;
  required?: boolean;
  files: SectionStorageFile[];
  others?: SectionPropertyOthers<PreferenceSectionText>;
}

export interface DiscoverySectionYesNoOutput extends DiscoverySection {
  index: number;
  type: DiscoverySectionType.YesNo;
  others: {
    description?: string;
    type: 'icons' | 'emotions';
  };
}

export interface DiscoverySectionRatingScaleOutput extends DiscoverySection {
  index: number;
  type: DiscoverySectionType.RatingScale;
  others: {
    description?: string;
    type: 'stars' | 'numerical' | 'emotions';
    startingStep?: 0 | 1;
    stepsCount?: number;
    labels?: {
      left: string;
      middle: string;
      right: string;
    };
  };
}

export interface DiscoverySectionMultipleChoiceOutput extends DiscoverySection {
  index: number;
  type: DiscoverySectionType.MultipleChoice;
  others: {
    description?: string;
    choices?: string[];
    type: 'single' | 'multiple';
    customEnabled?: boolean;
  };
}

export interface DiscoverySectionOpenWordChoiceOutput extends DiscoverySection {
  index: number;
  type: DiscoverySectionType.OpenWordChoice;
  others: {
    description?: string;
  };
}

export interface DiscoverySectionPreferenceOutput extends DiscoverySection {
  index: number;
  type: DiscoverySectionType.Preference;
  others: {
    description?: string;
    type: 'image' | 'text';
    texts?: PreferenceSectionText[];
  };
}

export interface DiscoverySectionLegalOutput extends DiscoverySection {
  index: number;
  type: DiscoverySectionType.Legal;
  others: {
    description?: string;
    message: string;
  };
}
