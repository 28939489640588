import { useMemo, useState } from 'react';

import { useTranslationContext } from '../../../../../services/translation';
import { allClasses } from '../../../../../services/utilities/array';
import { isNullOrUndefined } from '../../../../../services/utilities/value';
import {
  DefaultSectionThankYouOutput,
  DiscoverySectionInput,
  SectionFileRemovalDetails,
  SectionFileUploadDetails,
} from '../../../../services/api-iteration1';
import { isRequired } from '../../../../services/validation';
import { useErrorHandlerContext } from '../../../../../services/error-handling';
import { useSectionsListContext } from '../../../../services/sections/useSectionsList';
import { useActionInProgressContext } from '../../../../../services/progress';
import {
  getStoragePublicFileUrl,
  imageFileSizeLimit,
} from '../../../../services/api-cloud-storage';
import { StorageFileType } from '../../../../services/api-cloud-storage/api-models';

import { Form, FormError, FormField, ToggleField, UploadImage } from '../../../../../components';

import { ThankYouIcon } from '../../../../assets/icons';
import { SelectSectionItem } from '../../../containers';

import sectionStyles from './Section.module.css';

interface SectionFormValues {
  title: string;
  message: string;
  redirectEnabled: boolean;
  url?: string;
  hasImage: boolean;
}

interface SectionThankYouProps {
  section: DefaultSectionThankYouOutput;
  imageUrl?: string;
  isInvalid?: boolean;
  editSection: (
    sectionId: string,
    updates: DiscoverySectionInput,
    shouldUpdate?: boolean
  ) => Promise<void> | undefined;
  uploadSectionFile: (sectionId: string, fileDetails: SectionFileUploadDetails) => Promise<void>;
  removeSectionFile: (sectionId: string, fileDetails: SectionFileRemovalDetails) => Promise<void>;
}

export const SectionThankYou = ({
  section,
  isInvalid,
  editSection,
  uploadSectionFile,
  removeSectionFile,
}: SectionThankYouProps) => {
  const { t } = useTranslationContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();
  const { reflectUploadingStatus } = useActionInProgressContext.useContext();
  const {
    inFocusSectionId,
    setSectionInFocus,
    resetSectionInFocus: collapseSection,
  } = useSectionsListContext.useContext();

  const { title, _id, files, others } = section;
  const { url, message } = others;

  const sectionImage = files.find((f) => f.type === StorageFileType.SectionImage);
  const imageUrl = sectionImage && getStoragePublicFileUrl(sectionImage.id);

  const [hasImage, setHasImage] = useState(!!sectionImage);

  const isExpanded = inFocusSectionId === _id;
  const expandSection = () => setSectionInFocus(_id);

  const updateSectionValues = (values: SectionFormValues) => {
    if (!_id) return;

    const shouldRemoveSectionImage = !values.hasImage && sectionImage;
    if (shouldRemoveSectionImage)
      removeSectionFile(_id, { id: sectionImage.id, type: StorageFileType.SectionImage }).catch(
        handleError
      );

    const updates = {
      title: values.title,
      others: {
        message: values.message,
        url: values.redirectEnabled ? values.url : null,
      },
      ...(shouldRemoveSectionImage ? { files: files.filter((f) => f.id !== sectionImage.id) } : {}),
    };

    editSection(_id, updates);
  };

  const initialValues = useMemo(
    () => ({
      title: title || '',
      message: message || '',
      url: url || '',
      redirectEnabled: !isNullOrUndefined(url),
      hasImage: !!sectionImage || hasImage,
    }),
    [hasImage, sectionImage, message, title, url]
  );

  return (
    <SelectSectionItem
      alreadyAdded
      title={t('components.projects.sections.field-types.thankYou')}
      icon={<ThankYouIcon className='icon__small' />}
      onClick={isExpanded ? collapseSection : expandSection}
      {...{ isExpanded, isInvalid }}>
      <>
        <hr className={allClasses(sectionStyles.section_hr, 'w-11/12')} />
        <Form
          enableReinitialize
          initialValues={initialValues}
          onChange={updateSectionValues}
          className='px-5'>
          {({ status, values, errors, touched }) => (
            <>
              <FormError {...{ status }} />
              <FormField
                type='text'
                name='title'
                label={`${t('common.title')}*`}
                validate={isRequired}
                fieldClassName='mb-5'
                inputClassName={allClasses(
                  sectionStyles.section_input,
                  errors.title && touched.title && sectionStyles.section_input__invalid
                )}
                labelClassName={sectionStyles.section_label}
              />
              <FormField
                as='textarea'
                name='message'
                label={t('common.message')}
                fieldClassName='mb-5'
                inputClassName={allClasses(sectionStyles.section_input, 'min-h-[7.5rem]')}
                labelClassName={sectionStyles.section_label}
              />
              <div className={allClasses(sectionStyles.section_toggle_wrapper, 'mb-5')}>
                <span>{t('components.projects.sections.image')}</span>
                <FormField
                  component={ToggleField}
                  name='hasImage'
                  onChange={() => setHasImage(!values.hasImage)}
                />
              </div>
              {values.hasImage && (
                <UploadImage
                  excludeFromForm
                  fileSizeLimit={imageFileSizeLimit}
                  title={t('components.upload.image.title')}
                  subtitle={t('components.upload.image.subtitle')}
                  placeholderImgSrc={imageUrl}
                  upload={(image) =>
                    uploadSectionFile(_id, { file: image, type: StorageFileType.SectionImage })
                  }
                  onUploadError={handleError}
                  remove={async () => {
                    if (!sectionImage) return;
                    await removeSectionFile(_id, {
                      id: sectionImage.id,
                      type: StorageFileType.SectionImage,
                    });
                  }}
                  onRemoveSuccess={() => setHasImage(true)}
                  onRemoveError={handleError}
                  onLoadingStatusChange={reflectUploadingStatus}
                  className='mb-4'
                  fileName={sectionImage?.fileName}
                />
              )}
              <div className={allClasses(sectionStyles.section_toggle_wrapper, 'mb-5')}>
                <span>{t('components.projects.sections.thank-you.redirect-label')}</span>
                <FormField
                  component={ToggleField}
                  name='redirectEnabled'
                  sliderClassName={sectionStyles.section_toggle_slider_wrapper}
                  circleClassName={sectionStyles.section_toggle_slider_circle}
                />
              </div>
              {values.redirectEnabled && (
                <FormField
                  type='text'
                  name='url'
                  placeholder={t('components.projects.sections.thank-you.redirect-placeholder')}
                  fieldClassName='mb-5'
                  inputClassName={sectionStyles.section_input}
                />
              )}
            </>
          )}
        </Form>
      </>
    </SelectSectionItem>
  );
};
