import { allClasses } from '../../../services/utilities/array';

import { Head, LoadingOrContent } from '../../../components';
import { DeviceAccessView } from './components/DeviceAccessView';

import { LayoutProps } from './utilities';
import { AnalyticsScreenName } from './AnalyticsScreenName';

interface NoNavbarLayoutProps extends LayoutProps {
  footerLeftComponent?: React.ReactNode;
  footerRightComponent?: React.ReactNode;
  header?: React.ReactNode;
}

export const NoNavbarLayout = ({
  id,
  children,
  title,
  analyticsName,
  isLoading = false,
  error,
  footerLeftComponent,
  footerRightComponent,
  header,
  availableOnMobile,
}: NoNavbarLayoutProps) => {
  const hasFooter = footerLeftComponent || footerRightComponent;

  return (
    <>
      <Head {...{ title }} />
      <AnalyticsScreenName name={analyticsName} />
      <DeviceAccessView {...{ availableOnMobile }}>
        <LoadingOrContent isFullscreen {...{ isLoading, error }}>
          <div
            className={allClasses('absolute inset-0 flex flex-1 flex-col bg-main overflow-auto')}
            {...{ id }}>
            {header}
            <div className='flex flex-1 w-full mx-auto overflow-y-auto'>{children}</div>
            {hasFooter && (
              <footer className='flex justify-between px-10 py-5 border-t border-main-10 border-solid'>
                <div>{footerLeftComponent}</div>
                <div>{footerRightComponent}</div>
              </footer>
            )}
          </div>
        </LoadingOrContent>
      </DeviceAccessView>
    </>
  );
};
