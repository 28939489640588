export interface StorageFile {
  id: string;
  name: string;
  fileName?: string;
  extension?: string;
}

export enum StorageFileType {
  SectionImage = 'sectionImage',
  SectionMultipleImages = 'sectionMultipleImages',
  FiveSecondsImage = 'fiveSecondsImage',
  FirstClickImage = 'firstClickImage',
  LegalDocument = 'legalDocument',
}
