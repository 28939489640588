import { ReactNode, useEffect, useRef, useState } from 'react';

import { allClasses } from '../../../../../services/utilities/array';

import { useToggle } from '../../../../../components';

import { Logo } from './Logo';
import { Menu } from './Menu';
import { LoaderIcon, MenuIcon } from '../../../../assets/icons';

import styles from './Navbar.module.css';

interface NavbarChildrenComponentProps {
  leftSideComponent?: ReactNode;
  rightSideComponent?: ReactNode;
  isLoading?: boolean;
  shouldHandleScroll?: boolean;
  tabs?: React.ReactNode;
  headerComponent?: React.ReactNode;
}

export const Navbar = ({
  leftSideComponent,
  rightSideComponent,
  isLoading,
  tabs,
  headerComponent,
}: NavbarChildrenComponentProps) => {
  const { isOpen: isMenuOpen, toggle: toggleMenuOpen } = useToggle();
  const [hasContentScrolledUnder, setHasContentScrolledUnder] = useState<boolean>(false);
  const [hasMouseOver, setHasMouseOver] = useState<boolean>(false);
  const [homeHasMouseOver, setHomeHasMouseOver] = useState<boolean>(false);

  const navbarRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const scrolledUnderElements: string[] = [];

    const handleScrollUnderNavbar = (e: Event) => {
      const scrolledElement: any = e.target || {};
      const uniqueId = `${scrolledElement?.className}_${scrolledElement.firstChild?.className}_${scrolledElement.parentElement?.className}`;

      const navbarHeight = navbarRef.current?.clientHeight;
      const hasBelowNavbarPosition = scrolledElement.offsetTop === navbarHeight;
      const isUnderNavbar = scrolledElement.scrollTop > scrolledElement.offsetTop;
      const wasUnderNavbar = scrolledUnderElements.includes(uniqueId);

      if (hasBelowNavbarPosition && isUnderNavbar && !wasUnderNavbar)
        scrolledUnderElements.push(uniqueId);
      else if (hasBelowNavbarPosition && !isUnderNavbar && wasUnderNavbar) {
        const index = scrolledUnderElements.indexOf(uniqueId);
        scrolledUnderElements.splice(index, 1);
      }

      setHasContentScrolledUnder(scrolledUnderElements.length ? true : false);
    };

    window.addEventListener('scroll', handleScrollUnderNavbar, true);
    return () => {
      window.removeEventListener('scroll', handleScrollUnderNavbar);
    };
  }, []);

  return (
    <nav ref={navbarRef} className='relative bg-main z-10'>
      {headerComponent}
      <div className='flex pt-5 pb-2 container-with-margin'>
        <div
          className='flex-0 flex items-center sm:justify-start py-1.5'
          onMouseEnter={() => setHomeHasMouseOver(true)}
          onMouseLeave={() => setHomeHasMouseOver(false)}>
          <Logo isHovered={homeHasMouseOver} />
        </div>
        <div className='relative flex w-full items-center justify-between min-w-0'>
          {isLoading ? (
            <p className='cursor-pointer text-xl leading-5 lg:text-base'>
              <LoaderIcon className='icon-main' />
            </p>
          ) : (
            <>
              <div className='flex flex-0 justify-start items-center min-w-0'>
                {leftSideComponent}
              </div>
              <div className='flex justify-end items-center'>{rightSideComponent}</div>
            </>
          )}
        </div>
        <div className='flex-0 flex items-center sm:justify-start'>
          <div
            className={allClasses(
              'border-2 border-main-10 rounded-lg border-solid flex items-center h-10 w-20 justify-center cursor-pointer select-none',
              isMenuOpen && 'bg-main-90 border-main-90'
            )}
            onMouseEnter={() => setHasMouseOver(true)}
            onMouseLeave={() => setHasMouseOver(false)}
            onClick={toggleMenuOpen}>
            <MenuIcon className={isMenuOpen || hasMouseOver ? 'icon-main-contrast' : undefined} />
            <Menu isOpen={isMenuOpen} />
          </div>
        </div>
      </div>
      {tabs && (
        <div className='container-with-margin'>
          {tabs}
          <div className={styles.bottom_border} />
        </div>
      )}
      {hasContentScrolledUnder && <div className={styles.bottom_border} />}
    </nav>
  );
};
