import { Price } from '../formatters';
import { DiscountTag } from '../tags';

interface PaymentPlanCardProps {
  button: React.ReactNode;
  longDescription: React.ReactNode;
  name: string;
  price: number | null | undefined;
  shortDescription: string;
  discountText?: string;
}

export const PaymentPlanCard = ({
  name,
  price,
  shortDescription,
  longDescription,
  button,
  discountText,
}: PaymentPlanCardProps) => {
  return (
    <div className='max-w-[180px] mr-2 md:mr-5 last-of-type:mr-0'>
      <p className='text-base leading-5 text-main-contrast font-bold'>{name}</p>
      <div className='flex items-center'>
        <span className='text-[40px] font-bold text-main-contrast leading-[5rem]'>
          <Price value={price} />
        </span>
        {discountText && <DiscountTag text={discountText} className='ml-2.5' />}
      </div>
      <p className='mb-5 text-sm leading-[18px] text-neutral-70'>{shortDescription}</p>
      {button}
      <div className='text-sm leading-[18px] text-neutral-70'>{longDescription}</div>
    </div>
  );
};
