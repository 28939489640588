import {
  FetchOptions,
  ResourceServiceOptions,
} from '../../../../../../services/api/resource-fetch-factory';

import { apiIteration1, useIteration1Resource } from '../../../config';
import { UserTestVersionOutput } from './api-models';
import { endpoints } from './endpoints';

export * from './api-models';

export const useUserTestVersions = (userTestId: string) => {
  return useIteration1Resource<UserTestVersionOutput[]>(endpoints.v1.versions(userTestId));
};

export const useUserTestVersion = (
  userTestId: string,
  versionId: string,
  options?: FetchOptions | undefined,
  config?: ResourceServiceOptions<any, any>
) => {
  return useIteration1Resource<UserTestVersionOutput>(
    endpoints.v1.version(userTestId, versionId),
    options || {},
    config
  );
};

export const publishUserTestVersion = (userTestId: string, versionId: string) => {
  return apiIteration1.patch(endpoints.v1.publishVersion(userTestId, versionId));
};
