import { useEffect } from 'react';

import { setAnalyticsScreenName } from '../../../services/app-analytics';

export const AnalyticsScreenName = ({ name }: { name: string }) => {
  useEffect(() => {
    setAnalyticsScreenName(name);
  }, [name]);

  return null;
};
