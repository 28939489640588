import { FunctionComponent, SVGProps, useRef } from 'react';

import {
  ScreenAnalyticsFilter,
  useScreenAnalyticsFilterContext,
} from '../../services/analytics/useScreenAnalyticsFilter';
import { useTranslationContext } from '../../../services/translation';
import {
  PrototypeClick,
  UsabilitySectionFirstClickAnswer,
  UsabilitySectionFiveSecondsAnswer,
} from '../../services/api-iteration1';
import { FigmaFrame } from '../../services/api-iteration1/design-platforms';
import { StorageFile } from '../../services/api-cloud-storage/api-models';
import { getStoragePublicFileUrl } from '../../services/api-cloud-storage';
import { ElementSize, useElementResize } from '../../../services/resize';
import { getTestClickPosition } from '../../services/api-iteration1/design-platforms/figma/utils';
import { useAnalyticsQueryParams } from '../../services/analytics';

import { Modal } from '../../../components';

import { CloseIcon } from '../../assets/icons';
import { FigmaPrototypeImage } from '../views/live-prototype/FigmaPrototypeImage';
import { UsabilityMetricsCard } from '../cards/UsabilityMetricsCard';

type MetricsDataItem = {
  icon: 'users' | 'time';
  title: string;
  value: string | number;
  color: string;
};

type OptionItem = {
  id: ScreenAnalyticsFilter['inFocusFilterId'];
  iconSelectedClass: string;
  text: string;
  containerClassName: string;
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
};

interface UsabilityMetricsModalProps {
  isOpen: boolean;
  closeModal: () => void;
  data: MetricsDataItem[];
  title: string;
  answers: (UsabilitySectionFiveSecondsAnswer | UsabilitySectionFirstClickAnswer)[];
  image?: StorageFile;
  frameDetails?: FigmaFrame;
  frameOptions: OptionItem[];
  firstBreadcrumb:
    | {
        type: 'version';
        versionName?: string;
      }
    | {
        type: 'tester';
        testerIndex?: number;
      };
  breadcrumbTitle: string;
  versionName?: string;
  screenName?: string;
}

export const UsabilityMetricsModal = ({ ...props }: UsabilityMetricsModalProps) => (
  <useScreenAnalyticsFilterContext.ProvideContext>
    <UsabilityMetricsModalComponent {...props} />
  </useScreenAnalyticsFilterContext.ProvideContext>
);

const UsabilityMetricsModalComponent = ({
  isOpen,
  closeModal,
  data,
  title,
  answers,
  frameDetails,
  image,
  firstBreadcrumb,
  frameOptions,
  breadcrumbTitle,
  versionName,
  screenName,
}: UsabilityMetricsModalProps) => {
  const { t } = useTranslationContext.useContext();

  const { queryParams } = useAnalyticsQueryParams();

  const imgRef = useRef<HTMLImageElement>(null);
  const dimensions = useElementResize(imgRef);

  const imageUrl = image && getStoragePublicFileUrl(image.id);
  const frameUrl =
    frameDetails && frameDetails.image && getStoragePublicFileUrl(frameDetails.image.id);

  const breadCrumb =
    firstBreadcrumb.type === 'version'
      ? firstBreadcrumb.versionName
      : `${t('common.tester')} #${firstBreadcrumb.testerIndex}`;

  const firstClicks: PrototypeClick[] = answers.reduce((result, answer) => {
    const firstClick = answer.click;

    return firstClick ? [...result, firstClick] : result;
  }, [] as PrototypeClick[]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      variant='full-page'
      closeModalComponent={<CloseIcon className='icon-hover-main-contrast' />}
      closeIconClassName='absolute top-5 right-5'
      headerTitleComponent={
        <div className='absolute top-5 left-20 flex w-full py-2.5 rounded items-center cursor-default'>
          {firstBreadcrumb.type !== 'version' && (
            <>
              <span
                className='no-underline font-bold block truncate max-w-full text-neutral text-xl leading-5 cursor-pointer hover:text-main-contrast'
                onClick={closeModal}>
                {versionName}
              </span>
              <span className='font-bold text-neutral text-xl leading-5 px-5'>/</span>
            </>
          )}
          <span
            className='no-underline font-bold block truncate max-w-full text-neutral text-xl leading-5 cursor-pointer hover:text-main-contrast'
            onClick={closeModal}>
            {breadCrumb}
          </span>
          <span className='font-bold text-neutral text-xl leading-5 px-5'>/</span>
          <span
            className='no-underline font-bold block truncate max-w-full text-neutral text-xl leading-5 cursor-pointer hover:text-main-contrast'
            onClick={closeModal}>
            {breadcrumbTitle}
          </span>
          <span className='font-bold text-neutral text-xl leading-5 px-5'>/</span>
          <span className='no-underline font-bold block truncate max-w-full text-main-contrast text-xl leading-5'>
            {t('common.metrics')}
          </span>
        </div>
      }
      headerClassName='border-b-2 border-solid border-main-10'>
      <div className='flex flex-1 flex-row items-start mt-20 px-20 w-full'>
        <div className='w-90 pr-2'>
          <UsabilityMetricsCard
            screenName={screenName || title}
            {...{ data, imageUrl, frameUrl, frameOptions }}
          />
        </div>
        <div className='flex flex-row justify-center relative w-full scroll-container items-center overflow-y-auto pb-5 ml-5'>
          {image ? (
            <FigmaPrototypeImage
              imgRef={imgRef}
              imgFileId={image.id}
              containerId='first-click-image-container'>
              <ClicksInPrototype
                {...{ answers, dimensions, frameDetails, firstClicks, image }}
                inFocusFilterId={queryParams.screenFilter}
              />
            </FigmaPrototypeImage>
          ) : frameDetails && frameDetails.image ? (
            <FigmaPrototypeImage
              imgFileId={frameDetails.image.id}
              imgRef={imgRef}
              width={frameDetails.bounds.width}
              containerId='first-click-frame-container'>
              <ClicksInPrototype
                {...{ answers, dimensions, frameDetails, firstClicks, image }}
                inFocusFilterId={queryParams.screenFilter}
              />
            </FigmaPrototypeImage>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

const ClicksInPrototype = ({
  inFocusFilterId,
  answers,
  dimensions,
  frameDetails,
  firstClicks,
  image,
}: {
  inFocusFilterId: string | undefined;
  answers: UsabilityMetricsModalProps['answers'];
  dimensions: ElementSize | undefined;
  image: UsabilityMetricsModalProps['image'];
  frameDetails: UsabilityMetricsModalProps['frameDetails'];
  firstClicks: PrototypeClick[];
}) => {
  return (
    <>
      {inFocusFilterId === 'all-clicks' &&
        (answers as UsabilitySectionFiveSecondsAnswer[]).map(
          (answer) =>
            answer.clicks &&
            answer.clicks.map((click, index) => {
              const position = getTestClickPosition({
                clickBoundingBox: { ...click, width: 10, height: 10 },
                frameBoundingBox: {
                  width: click.containerWidth || 0,
                  height: click.containerHeight || 0,
                  x: 0,
                  y: 0,
                },
                htmlElDimensions: dimensions,
              });

              return (
                <div
                  key={index}
                  className='absolute border-solid border-main-highlight border-2 rounded-full z-59 w-4 h-4'
                  style={position}
                />
              );
            })
        )}

      {inFocusFilterId === 'first-click'
        ? frameDetails
          ? firstClicks.map((click, index) => (
              <div
                key={index}
                className='absolute border-solid border-main-highlight border-2 rounded-full z-59 w-4 h-4'
                style={getTestClickPosition({
                  clickBoundingBox: { ...click, width: 10, height: 10 },
                  frameBoundingBox: {
                    width: click.containerWidth || 0,
                    height: click.containerHeight || 0,
                    x: 0,
                    y: 0,
                  },
                  htmlElDimensions: dimensions,
                })}
              />
            ))
          : image && (
              <div
                className='absolute border-solid border-main-highlight border-2 rounded-full z-59 w-4 h-4'
                style={getTestClickPosition({
                  clickBoundingBox: { ...firstClicks[0], width: 10, height: 10 },
                  frameBoundingBox: {
                    width: firstClicks[0].containerWidth || 0,
                    height: firstClicks[0].containerHeight || 0,
                    x: 0,
                    y: 0,
                  },
                  htmlElDimensions: dimensions,
                })}
              />
            )
        : null}
    </>
  );
};
