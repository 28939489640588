import { useTranslationContext } from '../../../services/translation';
import { allClasses } from '../../../services/utilities/array';
import { getKeyForCondition } from '../../../services/utilities/object';
import { AnalyticsResponsesFiltersTypes } from '../../services/analytics';

import { Tag } from '../tags/Tag';

interface AnalyticsResponsesFiltersProps {
  filterClasses: string;
  onSelect: (filter: AnalyticsResponsesFiltersTypes) => void;
  selectedFilter: string;
}

export const AnalyticsResponsesFilters = ({
  filterClasses,
  onSelect,
  selectedFilter,
}: AnalyticsResponsesFiltersProps) => {
  const { t } = useTranslationContext.useContext();

  const filters = {
    all: t('components.projects.analytics.responses.single-goal.all-responses'),
    direct: t('components.projects.analytics.responses.single-goal.direct'),
    indirect: t('components.projects.analytics.responses.single-goal.indirect'),
    bounce: t('components.projects.analytics.responses.single-goal.bounce'),
  };

  return (
    <>
      {Object.entries(filters).map(([key, value]) => (
        <Tag
          key={key}
          id={key}
          className={allClasses(
            filterClasses,
            selectedFilter === key ? 'bg-main-highlight' : 'bg-main-10',
            'hover:bg-main-highlight'
          )}
          name={
            <>
              {key !== 'all' && (
                <div
                  className={allClasses(
                    'w-2 h-2 inline-block rounded-full mr-2',
                    getKeyForCondition({
                      'bg-success': key === 'direct',
                      'bg-warning': key === 'indirect',
                      'bg-danger': key !== 'direct' && key !== 'indirect',
                    })
                  )}
                />
              )}
              {value}
            </>
          }
          onClick={(filter) => onSelect(filter as AnalyticsResponsesFiltersTypes)}
        />
      ))}
    </>
  );
};
