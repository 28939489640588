import { isUndefined } from '../../../services/utilities/value';

import {
  DefaultSectionType,
  DefaultSectionThankYouOutput,
  DefaultSectionWelcomeOutput,
  DiscoverySection,
} from '../api-iteration1';

import { DiscoveryProjectSection } from '../api-iteration1/projects/discovery/sections/api-models';

export const getSortedSections = (sections: DiscoveryProjectSection[] | undefined) => {
  if (!sections)
    return { sortableSections: [], welcomeSection: undefined, thankYouSection: undefined };

  const thankYouSection = sections.find(
    (section: DiscoveryProjectSection) => section.type === DefaultSectionType.ThankYou
  ) as DefaultSectionThankYouOutput | undefined;

  const welcomeSection = sections.find(
    (section: DiscoveryProjectSection) => section.type === DefaultSectionType.Welcome
  ) as DefaultSectionWelcomeOutput | undefined;

  const sortableSections = sections.filter(
    (section) => !isUndefined(section.index)
  ) as Required<DiscoverySection>[];

  const sortedSections = sortableSections.sort((s1, s2) => s1.index - s2.index);

  return { sortableSections: sortedSections, welcomeSection, thankYouSection };
};
