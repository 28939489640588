import {
  getPermissionPlan,
  MyProjectPermission,
  MyProjectVersionPermission,
  MyProjectVersionSectionPermission,
  Permission,
  PermissionPlans,
  ProjectVersionResponsePermission,
} from '@iteration1/permission-validators';
import React from 'react';

import { useTranslationContext } from '../../../services/translation';
import { allClasses, enumToValueArray } from '../../../services/utilities/array';

import { CheckIconOn, CloseIcon } from '../../assets/icons';

const getPlansFeatureValues = (
  permission: Permission,
  transformer: (val: string | number | undefined) => React.ReactNode
) => {
  return enumToValueArray(PermissionPlans).reduce((result, plan) => {
    if (plan === PermissionPlans.Internal) return result;

    const permissionPlan = getPermissionPlan(plan);
    const permissionId = permission.id as keyof typeof permissionPlan;
    const permissionConfig = permissionPlan?.[permissionId];

    return {
      ...result,
      [plan]: transformer((permissionConfig as any)?.limit),
    };
  }, {} as Record<PermissionPlans, React.ReactNode>);
};

export const PaymentPlansFeatureList = () => {
  const { t } = useTranslationContext.useContext();

  const permissionPlans = enumToValueArray(PermissionPlans).filter(
    (plan) => plan !== PermissionPlans.Internal
  );

  const displayLimitOrUnlimited = (limit: string | number | undefined) =>
    limit || t(`pages.account.tabs.plan-billing.features.unlimited`);

  const plansFeatures: {
    key: string;
    plans: Partial<Record<PermissionPlans, React.ReactNode>>;
  }[] = [
    {
      key: 'active-projects',
      plans: getPlansFeatureValues(MyProjectPermission.create, displayLimitOrUnlimited),
    },
    {
      key: 'versions-project',
      plans: getPlansFeatureValues(MyProjectVersionPermission.create, displayLimitOrUnlimited),
    },
    {
      key: 'responses-project',
      plans: getPlansFeatureValues(
        ProjectVersionResponsePermission.create,
        displayLimitOrUnlimited
      ),
    },
    {
      key: 'sections-project',
      plans: getPlansFeatureValues(MyProjectVersionSectionPermission.create, (limitCount) =>
        limitCount
          ? t('pages.account.tabs.plan-billing.features.up-to', {
              limitCount: (limitCount as number) - 2,
            })
          : displayLimitOrUnlimited(limitCount)
      ),
    },
    {
      key: 'discovery',
      plans: {
        [PermissionPlans.IndividualFree]: <CheckIconOn className='icon__small' />,
        [PermissionPlans.IndividualBasic]: <CheckIconOn className='icon__small' />,
        [PermissionPlans.IndividualPro]: <CheckIconOn className='icon__small' />,
      },
    },
    {
      key: 'user-test',
      plans: {
        [PermissionPlans.IndividualFree]: <CheckIconOn className='icon__small' />,
        [PermissionPlans.IndividualBasic]: <CheckIconOn className='icon__small' />,
        [PermissionPlans.IndividualPro]: <CheckIconOn className='icon__small' />,
      },
    },
    {
      key: 'analytics',
      plans: {
        [PermissionPlans.IndividualFree]: <CheckIconOn className='icon__small' />,
        [PermissionPlans.IndividualBasic]: <CheckIconOn className='icon__small' />,
        [PermissionPlans.IndividualPro]: <CheckIconOn className='icon__small' />,
      },
    },
    {
      key: 'integrations',
      plans: {
        [PermissionPlans.IndividualFree]: <CheckIconOn className='icon__small' />,
        [PermissionPlans.IndividualBasic]: <CheckIconOn className='icon__small' />,
        [PermissionPlans.IndividualPro]: <CheckIconOn className='icon__small' />,
      },
    },
    {
      key: 'redirect',
      plans: {
        [PermissionPlans.IndividualFree]: <CheckIconOn className='icon__small' />,
        [PermissionPlans.IndividualBasic]: <CheckIconOn className='icon__small' />,
        [PermissionPlans.IndividualPro]: <CheckIconOn className='icon__small' />,
      },
    },
    {
      key: 'collaborators',
      plans: {
        [PermissionPlans.IndividualFree]: (
          <div className='flex flex-col'>
            <p>{t('pages.account.tabs.plan-billing.features.limited')}</p>
            <p className='mt-0.5 text-xs leading-[14px] text-main-contrast font-normal'>
              {t('pages.account.tabs.plan-billing.features.viewers')}
            </p>
          </div>
        ),
        [PermissionPlans.IndividualBasic]: (
          <div className='flex flex-col'>
            <p>{t('pages.account.tabs.plan-billing.features.limited')}</p>
            <p className='mt-0.5 text-xs leading-[14px] text-main-contrast font-normal'>
              {t('pages.account.tabs.plan-billing.features.viewers-editors')}
            </p>
          </div>
        ),
        [PermissionPlans.IndividualPro]: (
          <div className='flex flex-col'>
            <p>{t('pages.account.tabs.plan-billing.features.unlimited')}</p>
            <p className='mt-0.5 text-xs leading-[14px] text-main-contrast font-normal'>
              {t('pages.account.tabs.plan-billing.features.viewers-editors-admins')}
            </p>
          </div>
        ),
      },
    },
    {
      key: 'priority-support',
      plans: {
        [PermissionPlans.IndividualFree]: <CloseIcon className='icon__small' />,
        [PermissionPlans.IndividualBasic]: <CloseIcon className='icon__small' />,
        [PermissionPlans.IndividualPro]: <CheckIconOn className='icon__small' />,
      },
    },
  ];

  return (
    <div className='max-h-[350px] mb-44 pb-4 scroll-container' id='plan-features'>
      {plansFeatures.map((feature, index) => (
        <div
          key={feature.key}
          className={allClasses(
            'grid grid-cols-4 items-center py-4 text-center rounded-lg text-base text-main-contrast leading-5',
            index % 2 === 0 && 'bg-gradient-to-r from-main-90 to-main-70'
          )}>
          <span className='max-w-[180px] text-left pl-6'>
            {t(`pages.account.tabs.plan-billing.features.${feature.key}`)}
          </span>

          {permissionPlans.map((plan) => (
            <span key={plan} className='max-w-[180px] flex justify-center font-bold'>
              {feature.plans[plan]}
            </span>
          ))}
        </div>
      ))}
    </div>
  );
};
