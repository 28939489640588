import { getStoragePublicFileUrl } from '../../services/api-cloud-storage';
import { useTranslationContext } from '../../../services/translation';

import { Modal } from '../../../components';

import { CloseIcon, PlaceholderSmallIcon } from '../../assets/icons';

interface PreferenceTestImageModalProps {
  image: { id: string; index: number } | undefined;
  isOpen: boolean;
  closeModal: () => void;
}

export const PreferenceTestImageModal = ({
  image,
  isOpen,
  closeModal,
}: PreferenceTestImageModalProps) => {
  const { t } = useTranslationContext.useContext();

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      variant='full-page'
      closeModalComponent={<CloseIcon className='icon-hover-main-contrast' />}
      className='h-screen max-h-screen'
      headerTitleComponent={<h5 className='w-full'>{`${t('common.image')} ${image?.index}`}</h5>}
      headerClassName='container-with-margin items-center'>
      <div className='flex-1 flex-col box-border p-3 items-center justify-center mx-auto rounded-lg overflow-hidden'>
        {image ? (
          <img src={getStoragePublicFileUrl(image.id)} className='h-full w-full object-contain' />
        ) : (
          <div className='flex justify-center items-center bg-main-10 p-10 rounded-lg'>
            <PlaceholderSmallIcon className='w-10 h-10' />
          </div>
        )}
      </div>
    </Modal>
  );
};
