import { useTranslationContext } from '../../../services/translation';
import { useRouter } from '../../../services/router';
import { useCurrentUserTestVersionContext, useEditUserTestContext } from '../../services/userTest';
import { useActionInProgressContext } from '../../../services/progress';

import { Modal } from '../../../components/modal';

import { CloseIcon } from '../../assets/icons';
import { dynamicRoutes } from '../../pages/routing/routes';
import { ViewUserTestFlow } from '../views';
import { EditProjectNavbarActions } from './EditProjectNavbarActions';

interface EditUserTestNavbarActionsProps {
  isOpenPreviewModal: boolean;
  openPreviewModal: () => void;
  closePreviewModal: () => void;
  onClickRename: (name: string) => void;
  onClickDelete: () => void;
}

export const EditUserTestNavbarActions = ({
  isOpenPreviewModal,
  openPreviewModal,
  closePreviewModal,
  onClickDelete,
  onClickRename,
}: EditUserTestNavbarActionsProps) => {
  const router = useRouter();
  const { t } = useTranslationContext.useContext();
  const { hasActionsInProgress } = useActionInProgressContext.useContext();
  const {
    userTestId,
    userTestVersionId: versionId,
    userTestVersion,
  } = useCurrentUserTestVersionContext.useContext();
  const { invalidUserTest, changeStatus } = useEditUserTestContext.useContext();

  const goToStartTesting = () => {
    if (!versionId) return;

    router.push(
      dynamicRoutes.start_testing.userTest(
        userTestId,
        versionId,
        userTestVersion?.sections.length || 0
      )
    );
  };

  return (
    <>
      <EditProjectNavbarActions
        disabledTest={invalidUserTest || hasActionsInProgress}
        changeStatus={changeStatus}
        onClickPreview={openPreviewModal}
        onClickStartTesting={goToStartTesting}
        onClickDelete={onClickDelete}
        onClickRename={onClickRename}
        projectType='userTest'
        status={userTestVersion?.status}
      />
      {userTestId && versionId && (
        <Modal
          isOpen={isOpenPreviewModal}
          onClose={closePreviewModal}
          headerClassName='bg-main-contrast max-w-none h-auto items-center'
          headerTitleComponent={
            <p className='text-white'>{t('components.projects.usability-testing.in-preview')}</p>
          }
          variant='no-scroll-full-page'
          closeModalComponent={<CloseIcon className='icon-hover-main-contrast' />}>
          <div className='relative flex flex-1'>
            <ViewUserTestFlow inPreviewVersion={userTestVersion} {...{ userTestId, versionId }} />
          </div>
        </Modal>
      )}
    </>
  );
};
