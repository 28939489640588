import { useEffect, useState } from 'react';

import { allClasses } from '../../../../services/utilities/array';
import {
  Collaborator,
  CollaboratorRole,
  OwnerAsCollaborator,
  roleDropdownPrefix,
} from '../../../services/api-iteration1/collaborators/api-models';
import { useTranslationContext } from '../../../../services/translation';

import { Button } from '../../../../components';
import { Tooltip } from '../../../../components/tooltip/Tooltip';

import { CollaboratorRolesDropdown } from './CollaboratorRolesDropdown';
import { CollaboratorItem } from './CollaboratorItem';
import { InfoIcon } from '../../../assets/icons';
import { CollaboratorRolesDropdownTrigger } from './CollaboratorRolesDropdownTrigger';

import styles from './CollaboratorRolesDropdown.module.css';

interface CollaboratorCardProps {
  collaborator: Collaborator | OwnerAsCollaborator;
  isLast?: boolean;
  isOwner?: boolean;
  isAssignedToProject?: boolean;
  collaboratorRole?: CollaboratorRole;
  allowAddNewCollaborators?: boolean;
  index: number;
  currentDropdownIndex?: number;
  updateCollaboratorRole?: (id: string, email: string, role: CollaboratorRole) => void;
  removeCollaborator?: (email: string) => void;
  cancelInvitation?: (email: string) => void;
  addCollaborator?: ((email: string, role: CollaboratorRole) => void) | undefined;
  resendInvitation?: (collaborator: Collaborator) => void;
  setCurrentDropdownIndex?: (index: number) => void;
}

export const CollaboratorCard = ({
  collaborator,
  isLast,
  isOwner,
  isAssignedToProject,
  collaboratorRole,
  allowAddNewCollaborators,
  index,
  currentDropdownIndex,
  updateCollaboratorRole,
  addCollaborator,
  removeCollaborator,
  cancelInvitation,
  resendInvitation,
  setCurrentDropdownIndex,
}: CollaboratorCardProps) => {
  const { t } = useTranslationContext.useContext();
  const [rolesDropdown, setRolesDropdown] = useState<
    { x: number; y: number; height: number; triggerer: HTMLElement; index: number } | undefined
  >(undefined);

  const closeRolesDropdown = () => {
    setRolesDropdown(undefined);
  };

  useEffect(() => {
    window.addEventListener('scroll', closeRolesDropdown, true);
    return () => {
      window.removeEventListener('scroll', closeRolesDropdown, true);
    };
  }, []);

  const isDropdownOpen = rolesDropdown && currentDropdownIndex === rolesDropdown.index;

  const actionsDropdown = [
    {
      label: t('components.collaborators.roles-dropdown.admin'),
      value: CollaboratorRole.Admin,
      subLabel: t('components.collaborators.roles-dropdown.admin-label'),
      onSelectNewOption: () => {
        updateCollaboratorRole?.(collaborator.id, collaborator.email, CollaboratorRole.Admin);
      },
      isSelected: collaboratorRole === CollaboratorRole.Admin,
    },
    {
      label: t('components.collaborators.roles-dropdown.editor'),
      value: CollaboratorRole.Editor,
      subLabel: t('components.collaborators.roles-dropdown.editor-label'),
      onSelectNewOption: () => {
        updateCollaboratorRole?.(collaborator.id, collaborator.email, CollaboratorRole.Editor);
      },
      isSelected: collaboratorRole === CollaboratorRole.Editor,
    },
    {
      label: t('components.collaborators.roles-dropdown.viewer'),
      value: CollaboratorRole.Viewer,
      subLabel: t('components.collaborators.roles-dropdown.viewer-label'),
      onSelectNewOption: () => {
        updateCollaboratorRole?.(collaborator.id, collaborator.email, CollaboratorRole.Viewer);
      },
      isSelected: collaboratorRole === CollaboratorRole.Viewer,
    },
    {
      label: t('components.collaborators.roles-dropdown.remove-collaborator'),
      value: 'remove',
      onSelectNewOption: () => {
        removeCollaborator?.(collaborator.email);
      },
      className: styles.dropdown_item_actions_remove,
      hasSeparatorLine: true,
    },
  ];

  const invitationDropdown = [
    {
      label: t('components.collaborators.roles-dropdown.resend-invite'),
      value: 'resend',
      ...(allowAddNewCollaborators
        ? {
            onSelectNewOption: () => {
              resendInvitation?.(collaborator);
            },
          }
        : {}),
      ...(!allowAddNewCollaborators ? { disabled: true } : {}),
    },
    {
      label: t('components.collaborators.roles-dropdown.cancel-invite'),
      value: 'cancel',
      onSelectNewOption: () => {
        cancelInvitation?.(collaborator.email);
      },
      className: styles.dropdown_item_invitation_cancel,
    },
  ];

  return (
    <div
      className={allClasses(
        'flex flex-row justify-between items-center py-4',
        !isLast && 'border-b-2 border-solid border-main-90'
      )}>
      <CollaboratorItem {...{ collaborator }} />
      <div
        className={allClasses(
          'min-w-[6rem] flex flex-row justify-center items-center text-base leading-5 text-neutral-20 font-medium',
          !isOwner && 'cursor-pointer'
        )}>
        {isOwner ? (
          t('components.collaborators.roles-dropdown.owner')
        ) : isAssignedToProject ? (
          <>
            <CollaboratorRolesDropdownTrigger
              {...{
                collaboratorRole,
                isDropdownOpen,
                currentDropdownIndex,
                setCurrentDropdownIndex,
                setRolesDropdown,
                rolesDropdown,
              }}
              dropdownIndex={index}
            />
            {isDropdownOpen && (
              <CollaboratorRolesDropdown
                isPending={collaborator.isPending}
                key={`${roleDropdownPrefix}_${collaborator.email}_${currentDropdownIndex}`}
                options={collaborator.isPending ? invitationDropdown : actionsDropdown}
                close={closeRolesDropdown}
                {...{ rolesDropdown }}
              />
            )}
          </>
        ) : (
          <>
            <Button
              className='py-2.5 px-10 border-2 border-solid border-main-90 rounded-lg text-base leading-5 text-main-contrast font-medium hover:enabled:bg-main-90'
              disabled={!allowAddNewCollaborators}
              onClick={() =>
                allowAddNewCollaborators
                  ? addCollaborator?.(
                      collaborator.email,
                      collaboratorRole || CollaboratorRole.Viewer
                    )
                  : undefined
              }>
              {t('components.collaborators.roles-dropdown.add')}
            </Button>
            {!allowAddNewCollaborators && (
              <Tooltip
                direction='left'
                content={
                  <>
                    <p>{t('components.collaborators.limits-info.title')}.</p>
                    <p>{t('components.collaborators.limits-info.subtitle')}.</p>
                  </>
                }>
                <InfoIcon />
              </Tooltip>
            )}
          </>
        )}
      </div>
    </div>
  );
};
