import { useState } from 'react';

import { useTranslationContext } from '../../services/translation';
import { useErrorHandlerContext } from '../../services/error-handling';
import { sendResetPasswordEmail } from '../services/api-iteration1';
import { validateEmail } from '../services/validation/inputs';
import { useCurrentUserContext } from '../services/current-user';

import { NoFooterLayout } from '../components';
import { Button, createFormSubmitHandler, Form, FormField, Navlink } from '../../components';

import { CheckEmailIllustration, Iteration1FullLogo } from '../assets/icons';
import { routes } from './routing/routes';

const initialValues = {
  email: '',
};

export const ForgotPassword = () => {
  const { t } = useTranslationContext.useContext();
  const { translateErrorKeys } = useErrorHandlerContext.useContext();
  const { isAuthenticated } = useCurrentUserContext.useContext();

  const [emailSent, setEmailSent] = useState(false);
  const [emailAddress, setEmailAddress] = useState<string>('');

  const sendEmail = createFormSubmitHandler<typeof initialValues>({
    tryAction: (values) => {
      sendResetPasswordEmail(values.email);
      setEmailAddress(values.email);
    },
    onSuccess: () => setEmailSent(true),
    onError: (err, formActions) =>
      formActions.setFieldError('email', translateErrorKeys(err.code)[0]),
  });

  return (
    <NoFooterLayout
      analyticsName='Forgot Password'
      title={t('pages.forgot-password.title')}
      canAccessPage={!isAuthenticated}
      availableOnMobile={false}>
      <div className='flex flex-col w-full max-w-sm mb-16 mx-4'>
        <div className='mb-10'>
          <Iteration1FullLogo />
        </div>
        {emailSent ? (
          <div>
            <div className='w-32 h-32 mb-6 flex flex-col items-center justify-center rounded-lg'>
              <CheckEmailIllustration />
            </div>
            <h3 className='mb-1 text-2xl text-main-contrast font-bold leading-8'>
              {t('pages.forgot-password.check-email')}
            </h3>
            <p className='mb-10 text-base text-neutral-65 leading-5'>
              <span>{t('pages.forgot-password.check-email-instructions.part1')}</span>
              <span className='font-bold'>{emailAddress}</span>
              <span>{t('pages.forgot-password.check-email-instructions.part2')}</span>
            </p>
            <div className='mt-10'>
              <Navlink
                to={routes.signIn}
                className='text-info border-b-default border-main-70 border-solid hover:text-info-70'>
                {t('pages.forgot-password.return-to-sign-in')}
              </Navlink>
            </div>
          </div>
        ) : (
          <>
            <h3 className='mb-1 text-2xl text-main-contrast font-bold leading-8'>
              {t('pages.forgot-password.forgot')}
            </h3>
            <p className='mb-10 text-base text-neutral-65 leading-5'>
              {t('pages.forgot-password.instructions')}
            </p>
            <Form initialValues={initialValues} onSubmit={sendEmail}>
              {({ isValidForSubmit, isSubmitting }) => (
                <>
                  <FormField
                    name='email'
                    type='email'
                    placeholder={t('form.fields.email-address')}
                    label={t('form.fields.email-address')}
                    validate={validateEmail}
                    fieldClassName='mb-6'
                    inputClassName='py-3 px-4 text-lg font-normal leading-normal text-main-contrast border-main-10 border-2 border-solid placeholder-neutral rounded-lg cursor-pointer hover:border-main-highlight focus:border-main-highlight focus:outline-none'
                    labelClassName='text-sm text-main-contrast font-bold'
                  />

                  <Button
                    loading={isSubmitting}
                    className='button__filled w-full justify-center h-12'
                    type='submit'
                    disabled={!isValidForSubmit}>
                    {t('pages.forgot-password.action')}
                  </Button>
                </>
              )}
            </Form>
            <div className='mt-10 text-center'>
              <Navlink
                to={routes.signIn}
                className='text-info border-b-default border-main-70 border-solid hover:text-info-70'>
                {t('pages.forgot-password.return-to-sign-in')}
              </Navlink>
            </div>
          </>
        )}
      </div>
    </NoFooterLayout>
  );
};
