import { allClasses } from '../../../../../../services/utilities/array';
import { DiscoveryRatingScaleAnswer } from '../../../../../services/api-iteration1';

import { Icon } from '../../../../../../components';

import {
  EmotionIconFive,
  EmotionIconFour,
  EmotionIconOne,
  EmotionIconThree,
  EmotionIconTwo,
} from '../../../../../assets/icons';
import { Label } from '../../../sections/view/ViewRatingScale/Label';

interface AnalyticsEmotionsAnswerProps {
  answer?: DiscoveryRatingScaleAnswer;
  labels?: {
    left: string;
    middle: string;
    right: string;
  };
}

interface EmotionItemProps {
  isSelected: boolean;
  selectedClassName: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  isLast?: boolean;
  label?: React.ReactNode;
}

const AnalyticsEmotionsAnswer = ({ answer, labels }: AnalyticsEmotionsAnswerProps) => {
  return (
    <>
      <EmotionsItem
        isSelected={answer?.answer === 1}
        selectedClassName='icon-fill-circle-danger icon-circle-danger icon-main'
        icon={EmotionIconOne}
        label={labels && <Label text={labels.left} alignment='left' />}
      />
      <EmotionsItem
        isSelected={answer?.answer === 2}
        selectedClassName='icon-fill-circle-warning-10 icon-circle-warning-10 icon-main'
        icon={EmotionIconTwo}
      />
      <EmotionsItem
        isSelected={answer?.answer === 3}
        selectedClassName='icon-fill-circle-info icon-circle-info icon-main'
        icon={EmotionIconThree}
        label={labels && <Label text={labels.middle} alignment='center' />}
      />
      <EmotionsItem
        isSelected={answer?.answer === 4}
        selectedClassName='icon-fill-circle-main-highlight-95 icon-circle-main-highlight-95 icon-main'
        icon={EmotionIconFour}
      />
      <EmotionsItem
        isSelected={answer?.answer === 5}
        selectedClassName='icon-fill-circle-success-20 icon-circle-success-20 icon-main icon-main-fill'
        icon={EmotionIconFive}
        isLast
        label={labels && <Label text={labels.right} alignment='right' />}
      />
    </>
  );
};

const EmotionsItem = ({ isSelected, selectedClassName, icon, isLast, label }: EmotionItemProps) => {
  return (
    <div className='w-full'>
      <div
        className={allClasses(
          'relative flex flex-1 flex-col justify-center items-center border-2 border-solid p-3 rounded-lg',
          isSelected ? 'border-main-highlight' : 'border-main-10',
          !isLast ? 'mr-2' : undefined
        )}>
        {isSelected && <div className='absolute inset-0 bg-main-highlight opacity-10 z-0' />}
        <Icon
          Svg={icon}
          className={allClasses('h-20 z-10 cursor-default', isSelected && selectedClassName)}
        />
      </div>
      {label}
    </div>
  );
};

export default AnalyticsEmotionsAnswer;
