import { useEffect, useState } from 'react';
import { PermissionPlans } from '@iteration1/permission-validators';

import { useBillingPlans } from '../../services/api-iteration1/billing';
import { BillingOutput, BillingPeriod } from '../../services/api-iteration1/billing/api-models';
import { useCurrentUserContext } from '../../services/current-user';

import { Modal, useMultiStep } from '../../../components';

import { BackIcon, CloseIcon } from '../../assets/icons';
import { PaymentPlans } from '../views/payment/PaymentPlans';
import { Checkout } from '../views/payment/Checkout';
import { CheckoutSteps } from '../config';

interface SelectedPlan {
  plan: PermissionPlans;
  billingPeriod: BillingPeriod;
}

interface CheckoutModalProps {
  isOpen: boolean;
  closeModal: () => void;
  initialStep?: {
    step: CheckoutSteps;
    context?: { clientSecret: string; invoiceId: string } | undefined;
  };
  initialPlan?: SelectedPlan;
  billingRecord: BillingOutput | undefined;
  onChangePaymentMethod?: (newPaymentMethod: BillingOutput['paymentMethod']) => void;
}

export const CheckoutModal = ({
  isOpen,
  closeModal,
  initialStep = { step: 'plans' },
  initialPlan,
  billingRecord,
  onChangePaymentMethod,
}: CheckoutModalProps) => {
  const { currentUser } = useCurrentUserContext.useContext();

  const [selectedPlan, setSelectedPlan] = useState<SelectedPlan | undefined>(initialPlan);

  const { stepTo, isStep, context } = useMultiStep<
    CheckoutSteps,
    { clientSecret: string; invoiceId: string } | undefined
  >(initialStep.step, initialStep.context);

  const { data: paymentPlans } = useBillingPlans();

  const { plan, billingPeriod: selectedBillingPeriod } =
    initialStep.step === 'payment'
      ? {
          plan: currentUser?.subscription?.plan.name,
          billingPeriod: currentUser?.subscription?.price.recurring?.interval,
        }
      : {
          plan: selectedPlan?.plan,
          billingPeriod: selectedPlan?.billingPeriod,
        };

  const selectedPaymentPlan = paymentPlans?.find((p) => p.name === plan);

  const selectPlan = (selectedPlan: SelectedPlan) => {
    setSelectedPlan(selectedPlan);
    stepTo('billing');
  };

  const goBack = () => {
    stepTo('plans');
    setSelectedPlan(undefined);
  };

  useEffect(() => {
    if (isStep('exit')) closeModal();
  }, [closeModal, isStep]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      variant='no-scroll-full-page'
      backButton={
        isStep('billing') && (
          <BackIcon
            onClick={goBack}
            className='icon__small cursor-pointer icon-hover-main-contrast'
          />
        )
      }
      closeModalComponent={<CloseIcon className='icon-hover-main-contrast' />}
      closeIconClassName='absolute top-5 right-5'
      containerClassName='overflow-y-hidden'>
      {isStep('plans') ? (
        <PaymentPlans
          onSelectNewPlan={selectPlan}
          onExit={() => stepTo('exit')}
          {...{ paymentPlans }}
        />
      ) : (
        selectedPaymentPlan &&
        selectedBillingPeriod && (
          <Checkout
            plan={selectedPaymentPlan}
            billingPeriod={selectedBillingPeriod}
            onChangePaymentMethod={onChangePaymentMethod}
            stepContext={context}
            {...{
              isStep,
              billingRecord,
              stepTo,
            }}
          />
        )
      )}
    </Modal>
  );
};
