import React from 'react';

import { DraggableItem, useSortItemsOnDrag } from '../../services/drag-sort';
import {
  DefaultSectionThankYouOutput,
  DefaultSectionWelcomeOutput,
  DiscoverySectionInput,
  SectionFileRemovalDetails,
  SectionFileUploadDetails,
} from '../../services/api-iteration1';

import { DropArea } from '../../../components';

import { SelectSectionList } from './SelectSectionList';
import { SectionThankYou, SectionWelcomeScreen } from '../views/sections/edit';

interface SectionEditProps<T extends DraggableItem> {
  sections: T[];
  welcomeSection: DefaultSectionWelcomeOutput | undefined;
  thankYouSection: DefaultSectionThankYouOutput | undefined;
  newSectionsList: React.ReactNode;
  invalidSectionsIds: string[];
  onSort: (sortedSections: T[]) => void;
  children: (sortedSections: T[]) => React.ReactNode;
  dropId: string;
  editSection: (
    sectionId: string,
    updates: DiscoverySectionInput,
    shouldUpdate?: boolean
  ) => Promise<void> | undefined;
  uploadSectionFile: (sectionId: string, fileDetails: SectionFileUploadDetails) => Promise<void>;
  removeSectionFile: (sectionId: string, fileDetails: SectionFileRemovalDetails) => Promise<void>;
}

export const SectionEdit = <T extends DraggableItem>({
  sections,
  welcomeSection,
  thankYouSection,
  newSectionsList,
  invalidSectionsIds,
  onSort,
  children,
  dropId,
  editSection,
  removeSectionFile,
  uploadSectionFile,
}: SectionEditProps<T>) => {
  const { onDragEnd, sortedItems: sortedSections } = useSortItemsOnDrag<T>({
    items: sections,
    onSort,
  });

  const hasSortableSections = !!sortedSections.length;

  return (
    <div className='scroll-container max-w-22 w-1/2 min-w-min overflow-y-auto mr-5 pr-2'>
      <div className='flex flex-col justify-start items-center w-full'>
        <SectionWelcomeScreen
          section={welcomeSection as DefaultSectionWelcomeOutput}
          editSection={editSection}
          removeSectionFile={removeSectionFile}
          uploadSectionFile={uploadSectionFile}
          isInvalid={invalidSectionsIds.includes(welcomeSection?._id as string)}
        />
        {hasSortableSections && (
          <DropArea id={dropId} className='w-full' {...{ onDragEnd }}>
            {children(sortedSections)}
          </DropArea>
        )}
      </div>
      <SelectSectionList sectionsList={newSectionsList} />
      <SectionThankYou
        section={thankYouSection as DefaultSectionThankYouOutput}
        editSection={editSection}
        removeSectionFile={removeSectionFile}
        uploadSectionFile={uploadSectionFile}
        isInvalid={invalidSectionsIds.includes(thankYouSection?._id as string)}
      />
    </div>
  );
};
