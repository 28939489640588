import { isSafari } from '../../../../services/device';
import { getStoragePublicFileUrl } from '../../../services/api-cloud-storage';

/*
 * Chrome throws CORS errors if same image is cached once with flag `crossOrigin='anonymous'` and once without
 * so we use a different url (we add &`) which sets different images caches for live prototype
 */
export const getCrossOriginAnonymousImageSource = (imageId: string) => {
  return getStoragePublicFileUrl(imageId) + (isSafari() ? '' : '&');
};
