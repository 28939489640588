import { ReactEventHandler, ReactNode, useCallback, useState } from 'react';

import { isSafari } from '../../../../services/device';
import { allClasses } from '../../../../services/utilities/array';

import { getCrossOriginAnonymousImageSource } from './utils';

interface FigmaPrototypeImageProps {
  imgRef?: React.RefObject<HTMLImageElement>;
  imgFileId: string;
  width?: number;
  onClick?: React.MouseEventHandler<HTMLImageElement>;
  containerId?: string;
  children?: ReactNode;
  isEndFrame?: boolean;
  autoAspectRatio?: boolean;
}

export const FigmaPrototypeImage = ({
  imgRef,
  imgFileId,
  onClick,
  width,
  containerId,
  isEndFrame,
  children,
  autoAspectRatio = false,
}: FigmaPrototypeImageProps) => {
  const [imgSize, setImgSize] = useState<{ width: number; height: number }>();

  const aspectRatio = imgSize ? imgSize.width / imgSize.height : 1;

  const saveImageDimensions: ReactEventHandler<HTMLImageElement> = useCallback(
    (e) => {
      if (!autoAspectRatio) return;
      setImgSize({ width: e.currentTarget.width, height: e.currentTarget.height });
    },
    [autoAspectRatio]
  );

  return (
    <div
      id={containerId}
      className={allClasses(
        'relative',
        'w-fit h-fit', // DO NOT change width/height - the container must always fit child image perfectly so the clicks are displayed appropriately
        'border-2 border-main-10 border-solid'
      )}>
      <img
        ref={imgRef}
        key={imgFileId}
        src={getCrossOriginAnonymousImageSource(imgFileId)}
        style={{ width }}
        crossOrigin={isSafari() ? undefined : 'anonymous'}
        className={allClasses(
          'max-w-full max-h-auto w-screen object-cover sm:w-auto sm:max-h-full sm:object-contain h-min-[100%]',
          { aspectRatio }
        )}
        onClick={onClick}
        onLoad={saveImageDimensions}
      />
      {isEndFrame && (
        <div
          className='absolute inset-0 z-10 flex items-center justify-center'
          style={{ backgroundColor: 'rgba(0,0,0,0.6)' }}>
          <p className='font-medium text-6xl text-main'>Success!</p>
        </div>
      )}
      {children}
    </div>
  );
};
