import { useTranslationContext } from '../../../../services/translation';
import { allClasses } from '../../../../services/utilities/array';

import { Icon } from '../../../../components';
import { Tooltip } from '../../../../components/tooltip/Tooltip';

import { InfoIcon } from '../../../assets/icons';

interface InvalidResponsesCardProps {
  invalidResponsesCount: number;
}

export const InvalidResponsesCard = ({ invalidResponsesCount }: InvalidResponsesCardProps) => {
  const { t } = useTranslationContext.useContext();

  if (!invalidResponsesCount) return null;

  return (
    <div className='flex flex-row bg-main-10 border border-neutral-30 py-5 pl-5 rounded-lg mb-5'>
      <Icon
        Svg={InfoIcon}
        className={allClasses('icon__small ml-2 icon-stroke-circle-neutral-80')}
      />
      <div className='ml-5'>
        <p className='text-main-contrast text-base font-bold'>
          {t('components.projects.analytics.responses.invalid.title', {
            count: invalidResponsesCount,
          })}
        </p>
        <div className='mt-2'>
          <p className='inline text-neutral-80 text-base'>
            {t('components.projects.analytics.responses.invalid.subtitle')}
          </p>{' '}
          <Tooltip
            containerClassName='static' // Necessary so there's no hidden overflow of the tooltip content
            className='bg-white text-main-contrast border-2 border-main-10 w-90 p-5 flex-wrap whitespace-pre-wrap left-[28rem]'
            content={
              <p className='text-base text-left text-main-contrast inline-block'>
                {t('components.projects.analytics.responses.invalid.tooltip1')}
                <span className='font-bold'>
                  {t('components.projects.analytics.responses.invalid.tooltip2')}
                </span>
                {t('components.projects.analytics.responses.invalid.tooltip3')}
              </p>
            }>
            <p className='inline text-neutral-80 text-base border-b border-b-neutral-30'>
              {t('components.projects.analytics.responses.invalid.info')}
            </p>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
