import { DesignPlatform } from '../../../../design-platforms';
import {
  UsabilitySectionFirstClick,
  UsabilitySectionFiveSeconds,
  UsabilitySectionFreePlay,
  UsabilitySectionMultipleGoals,
  UsabilitySectionSingleGoal,
  UsabilitySectionType,
} from '../usability';
import { SectionFactory } from '../utils';

type DynamicParams = {
  designPlatform: DesignPlatform;
};

export const initialUsabilitySections: {
  [UsabilitySectionType.SingleGoal]: SectionFactory<
    UsabilitySectionSingleGoal,
    DynamicParams & { initialFrameId: string }
  >;
  [UsabilitySectionType.MultipleGoals]: SectionFactory<
    UsabilitySectionMultipleGoals,
    DynamicParams
  >;
  [UsabilitySectionType.FreePlay]: SectionFactory<UsabilitySectionFreePlay, DynamicParams>;
  [UsabilitySectionType.FirstClick]: SectionFactory<UsabilitySectionFirstClick, DynamicParams>;
  [UsabilitySectionType.FiveSeconds]: SectionFactory<UsabilitySectionFiveSeconds, DynamicParams>;
} = {
  [UsabilitySectionType.SingleGoal]: (index: number, { designPlatform, initialFrameId }) => ({
    index,
    type: UsabilitySectionType.SingleGoal,
    designPlatform,
    files: [],
    others: {
      title: '',
      paths: [
        {
          index: 0,
          frames: [
            {
              id: initialFrameId,
              index: 0,
            },
          ],
        },
      ],
    },
    required: undefined,
  }),
  [UsabilitySectionType.MultipleGoals]: (index: number, { designPlatform }) => ({
    index,
    type: UsabilitySectionType.MultipleGoals,
    designPlatform,
    files: [],
    others: {
      goals: [
        {
          title: '',
          paths: [],
        },
      ],
    },
    required: undefined,
  }),
  [UsabilitySectionType.FreePlay]: (index: number, { designPlatform }) => ({
    index,
    title: '',
    type: UsabilitySectionType.FreePlay,
    designPlatform,
    files: [],
    others: {},
    required: undefined,
  }),
  [UsabilitySectionType.FirstClick]: (index: number, { designPlatform }) => ({
    index,
    title: '',
    type: UsabilitySectionType.FirstClick,
    designPlatform,
    files: [],
    others: {
      type: designPlatform ? 'prototype' : 'image',
    },
    required: undefined,
  }),
  [UsabilitySectionType.FiveSeconds]: (index: number, { designPlatform }) => ({
    index,
    title: '',
    type: UsabilitySectionType.FiveSeconds,
    designPlatform,
    files: [],
    others: {
      type: 'image',
      title: '',
      seconds: 5,
    },
    required: undefined,
  }),
};
