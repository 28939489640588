import { useTranslationContext } from '../../../services/translation';

import { Button, Modal } from '../../../components';

import { CloseIcon } from '../../assets/icons';

interface PausedTestModalProps {
  isOpen: boolean;
  onClose: () => void;
  onGiveUp: () => void;
}

export const PausedTestModal = ({ isOpen, onClose, onGiveUp }: PausedTestModalProps) => {
  const { t } = useTranslationContext.useContext();

  return (
    <Modal
      title={t('components.projects.folder.modal-title')}
      isOpen={isOpen}
      onClose={onClose}
      variant='regular'
      closeModalComponent={<CloseIcon className='icon-hover-main-contrast' />}
      closeIconClassName='absolute top-5 right-5'>
      <div className='pt-10 pb-20 text-center'>
        <h2 className='my-10 text-main-contrast leading-8 text-2xl font-bold'>
          {t('components.projects.testing.pause-test-modal.title')}
        </h2>
        <div className='mb-16 text-main-contrast text-lg leading-6 font-medium'>
          <p className='mb-1'>
            {t('components.projects.testing.pause-test-modal.description-part-one')}
          </p>
          <p>{t('components.projects.testing.pause-test-modal.description-part-two')}</p>
        </div>
        <div className='flex flex-col sm:flex-row items-center justify-center'>
          <Button
            className='button__filled sm:mr-4 py-2.5 justify-center bg-main border-2 border-solid border-main-10 font-medium hover:enabled:bg-main-10 px-10'
            type='button'
            onClick={onGiveUp}>
            {t('components.projects.testing.pause-test-modal.give-up')}
          </Button>
          <Button
            className='button__filled py-2.5 justify-center text-main-contrast font-medium px-10 mt-4 sm:mt-0'
            type='button'
            onClick={onClose}>
            {t('components.projects.testing.pause-test-modal.resume')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
