import { allClasses } from '../../services/utilities/array';

interface OptionsMenuItemProps {
  text: string;
  onClick: () => void;
  className?: string;
}

export const OptionsMenuItem = ({ text, onClick, className }: OptionsMenuItemProps) => {
  return (
    <span
      className={allClasses(
        'w-full py-2.5 text-base text-main-contrast capitalize font-medium leading-5 rounded hover:bg-main-90 transition duration-150 ease-in-out cursor-pointer',
        className
      )}
      onClick={onClick}>
      {text}
    </span>
  );
};
