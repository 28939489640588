import { useTranslationContext } from '../../../services/translation';
import { useUpgradeModalToggle } from '../../services/billing/useUpgradeModalToggle';

import { AlertIcon } from '../../assets/icons';
import { SelectSectionItem } from '../containers';

interface ResponseLimitReachedCardProps {
  responseCount: number;
  limit: number;
}

export const ResponseLimitReachedCard = ({
  responseCount,
  limit,
}: ResponseLimitReachedCardProps) => {
  const { t } = useTranslationContext.useContext();
  const { openUpgradeModal } = useUpgradeModalToggle.useContext();
  const hiddenResponsesCount = responseCount - limit;

  return (
    <SelectSectionItem
      alreadyAdded
      description={
        <span className='block text-base text-billing leading-5'>
          <span
            className='border-b-default border-billing border-solid cursor-pointer'
            onClick={() => openUpgradeModal()}>
            {t('plan-permissions-check.responses.card-description-part-one')}
          </span>
          {!!hiddenResponsesCount ? (
            <span>{t('plan-permissions-check.responses.card-description-part-two')}</span>
          ) : (
            <span>{t('plan-permissions-check.responses.card-description-part-two-more')}</span>
          )}
        </span>
      }
      title={
        hiddenResponsesCount
          ? `${hiddenResponsesCount} ${t('plan-permissions-check.responses.card-title')}`
          : t('plan-permissions-check.responses.title')
      }
      icon={<AlertIcon />}
      onClick={() => ({})}
      isExpanded={false}
      containerClassName='mb-5 border-2 border-solid border-billing rounded-lg bg-billing-60 cursor-default hover:border-billing-20'
    />
  );
};
