import { useTranslationContext } from '../../../services/translation';
import { allClasses } from '../../../services/utilities/array';
import { getKeyForCondition } from '../../../services/utilities/object';

import { ScreensFiltersTypes } from '../config';
import { Tag } from '../tags/Tag';

interface AnalyticsScreensFilterProps {
  filterClass: string;
  onSelect: (filter: ScreensFiltersTypes) => void;
  selectedFilter: string;
}

export const AnalyticsScreensFilter = ({
  filterClass,
  onSelect,
  selectedFilter,
}: AnalyticsScreensFilterProps) => {
  const { t } = useTranslationContext.useContext();

  const filters = {
    all: t('components.projects.usability-testing.results.single-goal.screens.filters.all'),
    great: t('components.projects.usability-testing.results.single-goal.screens.filters.great'),
    check: t('components.projects.usability-testing.results.single-goal.screens.filters.check'),
    rework: t('components.projects.usability-testing.results.single-goal.screens.filters.rework'),
  };

  return (
    <>
      {Object.entries(filters).map(([key, value]) => (
        <Tag
          key={key}
          id={key}
          className={allClasses(
            filterClass,
            selectedFilter === key ? 'bg-main-highlight' : 'bg-main-10',
            'hover:bg-main-highlight'
          )}
          name={
            <>
              {key !== 'all' && (
                <div
                  className={allClasses(
                    'w-2 h-2 inline-block rounded-full mr-2',
                    getKeyForCondition({
                      'bg-success': key === 'great',
                      'bg-warning': key === 'check',
                      'bg-danger': key === 'rework',
                    })
                  )}
                />
              )}
              {value}
            </>
          }
          onClick={(filter) => onSelect(filter as ScreensFiltersTypes)}
        />
      ))}
    </>
  );
};
