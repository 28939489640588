import React from 'react';
import { MyProjectVersionSectionPermission } from '@iteration1/permission-validators';

import { useTranslationContext } from '../../services/translation';
import { useCurrentDiscoveryVersionContext } from '../services/discovery/useCurrentDiscoveryVersion';
import { useSectionsListContext } from '../services/sections/useSectionsList';
import {
  DiscoverySection,
  DiscoverySectionLegalOutput,
  DiscoverySectionMultipleChoiceOutput,
  DiscoverySectionOpenWordChoiceOutput,
  DiscoverySectionPreferenceOutput,
  DiscoverySectionRatingScaleOutput,
  DiscoverySectionType,
  DiscoverySectionYesNoOutput,
  sortDiscoverySections,
  UsabilitySectionFirstClick,
  UsabilitySectionFiveSeconds,
  UsabilitySectionType,
} from '../services/api-iteration1';
import { useErrorHandlerContext } from '../../services/error-handling';
import { usePreviewModalContext } from '../services/preview';
import { useActionInProgressContext } from '../../services/progress';
import { usePermissionsContext } from '../services/authorization';
import { useUpgradeModalToggle } from '../services/billing/useUpgradeModalToggle';
import { useShowInstructionsContext } from '../services/userTest';

import { MainLayout, SectionFirstClick, withAuth } from '../components';
import { Breadcrumbs } from '../../components';
import {
  SectionMultipleChoice,
  SectionOpenWordChoice,
  SectionRatingScale,
  SectionYesNo,
  SectionPreferenceTest,
} from '../components/views/sections/edit';
import { NewDiscoverySections } from '../components/lists/NewDiscoverySections';
import { SectionEdit } from '../components/lists/SectionEdit';
import { SectionView } from '../components/views/sections/view/SectionView';
import { EditDiscoveryNavbarActions } from '../components/navbars/EditDiscoveryNavbarActions';
import { ProjectVersionEmptyState } from '../components/empty-states/ProjectVersionEmptyState';
import { SectionFiveSeconds } from '../components/views/sections/edit/SectionFiveSeconds';
import { SectionLegal } from '../components/views/sections/edit/SectionLegal';

import { MoreSectionsIllustration, ProjectEmptyStateIllustration } from '../assets/icons';
import { dynamicRoutes, routes } from './routing/routes';

import { useEditDiscoveryContext } from '../../domain/services/discovery/useEditDiscovery';

export const EditDiscovery = withAuth(() => (
  <useSectionsListContext.ProvideContext>
    <useActionInProgressContext.ProvideContext>
      <useEditDiscoveryContext.ProvideContext>
        <useShowInstructionsContext.ProvideContext>
          <usePreviewModalContext.ProvideContext>
            <EditDiscoveryPage />
          </usePreviewModalContext.ProvideContext>
        </useShowInstructionsContext.ProvideContext>
      </useEditDiscoveryContext.ProvideContext>
    </useActionInProgressContext.ProvideContext>
  </useSectionsListContext.ProvideContext>
));

const EditDiscoveryPage = () => {
  const { t } = useTranslationContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();

  const {
    discoveryId,
    discoveryVersionId,
    discoveryVersion,
    sortedSections,
    welcomeSection,
    thankYouSection,
    parents,
    isLoadingDiscovery,
    error,
    revalidate,
    removeDiscovery,
    renameCurrentDiscovery,
  } = useCurrentDiscoveryVersionContext.useContext();

  const { editSection, uploadSectionFile, removeSectionFile, removeSection, duplicateSection } =
    useEditDiscoveryContext.useContext();

  const { invalidSectionsIds, addNewSection } = useEditDiscoveryContext.useContext();
  const { inFocusSectionId } = useSectionsListContext.useContext();
  const { checkPermission } = usePermissionsContext.useContext();
  const { openUpgradeModal } = useUpgradeModalToggle.useContext();

  const breadcrumbs = discoveryVersion
    ? [
        { label: t('pages.projects.breadcrumb'), url: routes.projects },
        ...parents
          .sort((a, b) => a.index - b.index)
          .map((parent) => ({
            label: parent.name,
            url: dynamicRoutes.folder(parent._id),
          })),
        {
          label: discoveryVersion.name,
          url: discoveryVersion.id,
        },
      ]
    : [];

  const saveNewSorting = async (sortedSections: DiscoverySection[]) => {
    if (!discoveryVersionId || !sortedSections) return;

    const sortedSectionIds = sortedSections.map((section) => section._id);
    try {
      await sortDiscoverySections(discoveryId, discoveryVersionId, sortedSectionIds);
    } catch (err) {
      handleError(err);
    }
    revalidate();
  };

  const allowCreateSection = checkPermission(MyProjectVersionSectionPermission.create, {
    count: discoveryVersion?.sections.length || 0,
  });

  return (
    <MainLayout
      analyticsName='Edit - Discovery'
      title={t('pages.discovery.edit', {
        projectName: discoveryVersion?.name,
      })}
      navbarLeftComponent={breadcrumbs ? <Breadcrumbs items={breadcrumbs} /> : undefined}
      navbarRightComponent={
        <EditDiscoveryNavbarActions
          onClickDelete={removeDiscovery}
          onClickRename={renameCurrentDiscovery}
        />
      }
      isLoading={isLoadingDiscovery}
      error={error}
      isPlanLimitReached={!allowCreateSection}
      availableOnMobile={false}>
      <div className='overflow-hidden mx-auto w-full flex flex-row'>
        <SectionEdit<DiscoverySection>
          onSort={saveNewSorting}
          newSectionsList={
            <NewDiscoverySections
              onClick={(section) =>
                allowCreateSection ? addNewSection(section) : openUpgradeModal()
              }
            />
          }
          sections={sortedSections}
          {...{ welcomeSection, thankYouSection, invalidSectionsIds }}
          dropId='discovery-section-edit'
          editSection={editSection}
          uploadSectionFile={uploadSectionFile}
          removeSectionFile={removeSectionFile}>
          {(sortedSections) =>
            sortedSections.map((section, index) => (
              <React.Fragment key={section._id}>
                {section.type === DiscoverySectionType.YesNo && (
                  <SectionYesNo
                    section={section as DiscoverySectionYesNoOutput}
                    isInvalid={invalidSectionsIds.includes(section._id)}
                    editSection={editSection}
                    removeSectionFile={removeSectionFile}
                    uploadSectionFile={uploadSectionFile}
                    removeSection={removeSection}
                    duplicateSection={allowCreateSection ? duplicateSection : openUpgradeModal}
                    {...{ index }}
                  />
                )}
                {section.type === DiscoverySectionType.RatingScale && (
                  <SectionRatingScale
                    section={section as DiscoverySectionRatingScaleOutput}
                    isInvalid={invalidSectionsIds.includes(section._id)}
                    editSection={editSection}
                    removeSectionFile={removeSectionFile}
                    uploadSectionFile={uploadSectionFile}
                    removeSection={removeSection}
                    duplicateSection={allowCreateSection ? duplicateSection : openUpgradeModal}
                    {...{ index }}
                  />
                )}
                {section.type === DiscoverySectionType.MultipleChoice && (
                  <SectionMultipleChoice
                    section={section as DiscoverySectionMultipleChoiceOutput}
                    isInvalid={invalidSectionsIds.includes(section._id)}
                    editSection={editSection}
                    removeSectionFile={removeSectionFile}
                    uploadSectionFile={uploadSectionFile}
                    removeSection={removeSection}
                    duplicateSection={allowCreateSection ? duplicateSection : openUpgradeModal}
                    {...{ index }}
                  />
                )}
                {section.type === DiscoverySectionType.OpenWordChoice && (
                  <SectionOpenWordChoice
                    section={section as DiscoverySectionOpenWordChoiceOutput}
                    isInvalid={invalidSectionsIds.includes(section._id)}
                    editSection={editSection}
                    removeSectionFile={removeSectionFile}
                    uploadSectionFile={uploadSectionFile}
                    removeSection={removeSection}
                    duplicateSection={allowCreateSection ? duplicateSection : openUpgradeModal}
                    {...{ index }}
                  />
                )}
                {section.type === UsabilitySectionType.FiveSeconds && (
                  <SectionFiveSeconds
                    section={section as unknown as UsabilitySectionFiveSeconds}
                    isInvalid={invalidSectionsIds.includes(section._id)}
                    editSection={editSection}
                    removeSectionFile={removeSectionFile}
                    uploadSectionFile={uploadSectionFile}
                    removeSection={removeSection}
                    duplicateSection={allowCreateSection ? duplicateSection : openUpgradeModal}
                    projectType='discovery'
                    {...{ index }}
                  />
                )}
                {section.type === UsabilitySectionType.FirstClick && (
                  <SectionFirstClick
                    section={section as unknown as UsabilitySectionFirstClick}
                    isInvalid={invalidSectionsIds.includes(section._id)}
                    editSection={editSection}
                    removeSectionFile={removeSectionFile}
                    uploadSectionFile={uploadSectionFile}
                    removeSection={removeSection}
                    duplicateSection={allowCreateSection ? duplicateSection : openUpgradeModal}
                    {...{ index }}
                  />
                )}
                {section.type === DiscoverySectionType.Preference && (
                  <SectionPreferenceTest
                    section={section as DiscoverySectionPreferenceOutput}
                    isInvalid={invalidSectionsIds.includes(section._id)}
                    editSection={editSection}
                    removeSectionFile={removeSectionFile}
                    uploadSectionFile={uploadSectionFile}
                    removeSection={removeSection}
                    duplicateSection={allowCreateSection ? duplicateSection : openUpgradeModal}
                    {...{ index }}
                  />
                )}
                {section.type === DiscoverySectionType.Legal && (
                  <SectionLegal
                    section={section as DiscoverySectionLegalOutput}
                    isInvalid={invalidSectionsIds.includes(section._id)}
                    editSection={editSection}
                    removeSectionFile={removeSectionFile}
                    uploadSectionFile={uploadSectionFile}
                    removeSection={removeSection}
                    duplicateSection={allowCreateSection ? duplicateSection : openUpgradeModal}
                    {...{ index }}
                  />
                )}
              </React.Fragment>
            ))
          }
        </SectionEdit>
        <div className='scroll-container pt-16 overflow-y-auto w-full'>
          {inFocusSectionId ? (
            <SectionView
              section={discoveryVersion?.sections.find(
                (sectionItem) => sectionItem._id === inFocusSectionId
              )}
            />
          ) : !sortedSections.length ? (
            <ProjectVersionEmptyState
              title={t('empty-states.project-item.title')}
              description={t('empty-states.project-item.description')}
              icon={<ProjectEmptyStateIllustration />}
            />
          ) : (
            <ProjectVersionEmptyState
              title={t('empty-states.next-sections.title')}
              description={t('empty-states.next-sections.description')}
              icon={<MoreSectionsIllustration />}
            />
          )}
        </div>
      </div>
    </MainLayout>
  );
};
