import { initializeApp, FirebaseError } from 'firebase/app';
import {
  ActionCodeSettings,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  getAdditionalUserInfo,
  getAuth,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updatePassword,
  User,
  UserCredential,
  UserInfo,
  verifyPasswordResetCode,
  reauthenticateWithCredential,
  EmailAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import { getAnalytics, logEvent, setUserId } from 'firebase/analytics';

export interface AnalyticsEvent {
  eventName: string;
  eventContent?: Record<string, unknown>;
}

const initFirebase = () => {
  const app = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  });

  const analytics = getAnalytics(app);

  return {
    analytics: {
      logEvent: ({ eventName, eventContent }: AnalyticsEvent) =>
        logEvent(analytics, eventName, eventContent),
      logSignIn: (method: string) => logEvent(analytics, 'login', { method }),
      setUserId: (userId: string) => setUserId(analytics, userId),
      setCurrentScreen: (screenName: string) =>
        logEvent(analytics, 'screen_view', {
          firebase_screen: screenName,
          firebase_screen_class: '',
        }),
    },
    auth: getAuth(),
  };
};

export const { analytics: firebaseAnalytics, auth } = initFirebase();

export enum AuthProviders {
  Password = 'password',
  Google = 'google.com',
}

const Firebase = {
  getUserAdditionalInfo: async (userCredential: FirebaseUserCredential) => {
    return getAdditionalUserInfo(userCredential);
  },
  reloadUser: async () => {
    await auth.currentUser?.reload();
    return auth.currentUser;
  },
  getSignInMethods: async (email: string) => {
    return fetchSignInMethodsForEmail(auth, email);
  },
  signUpWithEmail: async (email: string, password: string) => {
    return createUserWithEmailAndPassword(auth, email, password);
  },
  signInWithEmail: async (email: string, password: string) => {
    firebaseAnalytics.logSignIn('password');
    return signInWithEmailAndPassword(auth, email, password);
  },
  signInWithGoogle: async () => {
    firebaseAnalytics.logSignIn('google');
    const userCred = await signInWithPopup(auth, new GoogleAuthProvider());
    return userCred;
  },
  signOut: () => {
    return auth.signOut();
  },
  onTokenChanged: (
    callback: (a: FirebaseAuthUser | null) => any,
    errCallback?: (a: Error) => any
  ) => auth.onIdTokenChanged(callback, errCallback),
  sendPasswordResetEmail: (email: string, actionCodeSettings?: ActionCodeSettings | null) =>
    sendPasswordResetEmail(auth, email, actionCodeSettings || undefined),
  verifyPasswordResetCode: (code: string): Promise<string> => verifyPasswordResetCode(auth, code),
  confirmPasswordReset: (code: string, newPassword: string): Promise<void> =>
    confirmPasswordReset(auth, code, newPassword),
  updatePassword: async (password: string): Promise<void> => {
    if (auth.currentUser) {
      await updatePassword(auth.currentUser, password);
    }
  },
  reauthenticateWithEmail: async (password: string): Promise<FirebaseUserCredential | void> => {
    if (auth.currentUser && auth.currentUser.email) {
      await reauthenticateWithCredential(
        auth.currentUser,
        EmailAuthProvider.credential(auth.currentUser.email, password)
      );
    }
  },
};

export type FirebaseAuthUser = User;
export type FirebaseAuthUserInfo = UserInfo;
export type FirebaseUserCredential = UserCredential;
export type FirebaseAuthError = FirebaseError;
export default Firebase;
