import { allClasses } from '../../services/utilities/array';

import styles from './Spinner.module.css';

interface SpinnerProps {
  className: string;
  color?: 'highlight' | 'danger';
}

export const Spinner = ({ className, color = 'highlight' }: SpinnerProps) => (
  <div className='flex justify-center items-center'>
    <div
      className={allClasses(
        styles.loader,
        color === 'highlight' || color === 'danger' ? styles[color] : undefined,
        className
      )}></div>
  </div>
);
