import { useCallback, useMemo, useState } from 'react';

import { createContextService } from '../../../services/context';
import { convertNullsToValue, PartialNullable } from '../../../services/utilities/object';
import { useErrorHandlerContext } from '../../../services/error-handling';
import { useRouter } from '../../../services/router';

import { getSortedSections } from './utils';
import { FolderParentOutput } from '../api-iteration1';
import { dynamicRoutes, routes } from '../../pages/routing/routes';

import {
  DefaultSectionThankYouOutput,
  DefaultSectionWelcomeOutput,
  deleteDiscovery,
  DiscoveryOutput,
  DiscoverySection,
  DiscoveryVersionOutput,
  renameDiscovery,
  useDiscovery,
  useDiscoveryVersion,
} from '../api-iteration1/projects';
import { DiscoveryProjectSection } from '../api-iteration1/projects/discovery/sections/api-models';

interface CurrentDiscoveryVersionService {
  discoveryId: string;
  discoveryVersionId: string | undefined;
  parents: FolderParentOutput[];
  discovery: DiscoveryOutput | undefined;
  discoveryVersion: DiscoveryVersionOutput | undefined;
  isLoadingDiscovery: boolean;
  sortedSections: DiscoverySection[];
  thankYouSection: DefaultSectionThankYouOutput | undefined;
  welcomeSection: DefaultSectionWelcomeOutput | undefined;
  revalidate: () => void;
  revalidateProject: () => void;
  mutateSectionLocally: (
    sectionId: string,
    updates: PartialNullable<DiscoveryProjectSection> | null
  ) => void;
  error?: any;
  removeDiscovery: () => Promise<void> | undefined;
  renameCurrentDiscovery: (name: string) => Promise<void> | undefined;
  setShouldRevalidateOnFocus: (value: boolean) => void;
}

interface useCurrentDiscoveryVersionProps {
  discoveryId: string;
}

export const useCurrentDiscoveryVersion = ({
  discoveryId,
}: useCurrentDiscoveryVersionProps): CurrentDiscoveryVersionService => {
  const router = useRouter();
  const { handleError } = useErrorHandlerContext.useContext();

  const [shouldRevalidateOnFocus, setShouldRevalidateOnFocus] = useState<boolean>(true);

  const {
    data: discovery,
    error: discoveryError,
    revalidate: revalidateProject,
  } = useDiscovery(discoveryId);

  const {
    data: discoveryVersion,
    isLoading: isLoadingDiscovery,
    revalidate,
    mutate,
    error: discoveryVersionError,
  } = useDiscoveryVersion(discoveryId, undefined, { revalidateOnFocus: shouldRevalidateOnFocus });

  const { sortableSections, welcomeSection, thankYouSection } = useMemo(
    () => getSortedSections(discoveryVersion?.sections),
    [discoveryVersion?.sections]
  );

  const mutateSectionLocally = useCallback(
    async (sectionId: string, updates: PartialNullable<DiscoveryProjectSection> | null) => {
      mutate((prev) => {
        if (!prev) return;

        const data: DiscoveryVersionOutput = (prev as any).data;

        const shouldRemoveSection = !updates;
        if (shouldRemoveSection) {
          const updatedSections = data.sections.filter((section) => section._id !== sectionId);

          return {
            ...prev,
            data: {
              ...data,
              sections: updatedSections,
            },
          };
        }
        const sectionToUpdate = data.sections.find((section) => section._id === sectionId);

        if (!sectionToUpdate) {
          const versionWithNewSection = {
            ...data,
            sections: [...data.sections, updates],
          } as DiscoveryVersionOutput;

          return { ...prev, data: versionWithNewSection };
        } else {
          const updatedSections = data.sections.map((section) => {
            return section._id === sectionId
              ? {
                  ...section,
                  ...updates,
                  others: {
                    ...section.others,
                    ...(convertNullsToValue(
                      updates?.others || {},
                      undefined
                    ) as DiscoveryProjectSection['others']),
                  },
                }
              : section;
          });

          const updatedVersion = { ...data, sections: updatedSections };

          return { ...prev, data: updatedVersion as DiscoveryVersionOutput };
        }
      }, false);
    },
    [mutate]
  );

  const removeDiscovery = async () => {
    if (!discoveryVersion) return;

    try {
      const directParent = discovery?.parents[discovery.parents.length - 1];
      await deleteDiscovery(discoveryId);
      revalidate();

      const redirectRoute = directParent ? dynamicRoutes.folder(directParent._id) : routes.projects;
      router.replace(redirectRoute);
    } catch (err) {
      handleError(err);
    }
  };

  const renameCurrentDiscovery = async (name: string) => {
    if (!discoveryVersion) return;

    try {
      await renameDiscovery(discoveryId, discoveryVersion.id, { name });
      revalidate();
    } catch (err) {
      handleError(err);
    }
  };

  return {
    discoveryId,
    discoveryVersionId: discoveryVersion?.id,
    parents: discovery?.parents || [],
    discovery,
    discoveryVersion: discoveryVersion || undefined,
    isLoadingDiscovery,
    sortedSections: sortableSections,
    thankYouSection,
    welcomeSection,
    revalidate,
    revalidateProject,
    mutateSectionLocally,
    error: discoveryError || discoveryVersionError,
    removeDiscovery,
    renameCurrentDiscovery,
    setShouldRevalidateOnFocus,
  };
};

export const useCurrentDiscoveryVersionContext = createContextService(useCurrentDiscoveryVersion);
