import { useTranslationContext } from '../../services/translation';
import { useCurrentUserContext } from '../services/current-user';
import { resendEmailVerification } from '../services/api-iteration1';
import { useErrorHandlerContext } from '../../services/error-handling';
import { useAlertsContext } from '../../services/alerts';

import { NoFooterLayout } from '../components';
import { Button } from '../../components';

import { CheckEmailIllustration, Iteration1FullLogo } from '../assets/icons';
import { CONFIG_SUPPORT_EMAIL } from '../config';

export const VerifyAccountEmail = () => {
  const { t } = useTranslationContext.useContext();
  const { currentUser, signOut } = useCurrentUserContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();
  const { addSuccessAlert } = useAlertsContext.useContext();

  const resendConfirmationEmail = async () => {
    try {
      await resendEmailVerification();
      addSuccessAlert(
        t('pages.verify-account.success.title'),
        t('pages.verify-account.success.description')
      );
    } catch (err) {
      handleError(err, t('pages.verify-account.error'));
    }
  };

  return (
    <NoFooterLayout
      analyticsName='Verify account email'
      requiresAuthentication
      canAccessPage={currentUser?.requiresEmailConfirmation}
      title={t('pages.verify-account.title')}
      availableOnMobile={false}>
      <div className='flex flex-col w-full items-center mx-4'>
        <Iteration1FullLogo />
        <CheckEmailIllustration className='w-72 h-72 mt-36' />

        <p className='font-bold text-xl text-main-contrast mt-10'>
          {t('pages.verify-account.header')}
        </p>
        <p className='text-lg text-main-contrast text-center max-w-2xl mt-3'>
          {t('pages.verify-account.description1')}
          <span className='font-bold'>{currentUser?.email}</span>
          {t('pages.verify-account.description2')}
        </p>
        <Button
          className='font-medium text-base text-info border-b border-info mt-10'
          onClick={resendConfirmationEmail}>
          {t('pages.verify-account.actions.resend')}
        </Button>
        <a
          className='font-medium text-base text-info border-b border-info mt-5'
          href={`mailto:${CONFIG_SUPPORT_EMAIL}`}>
          {t('pages.verify-account.actions.contact')}
        </a>
        <Button className='font-medium text-sm text-danger mt-14' onClick={signOut}>
          {t('common.auth.sign-out')}
        </Button>
      </div>
    </NoFooterLayout>
  );
};
