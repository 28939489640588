import { allClasses } from '../../../services/utilities/array';

import { renderAnalyticsIcon } from '../icons';

type FrameItem = {
  icon: 'users' | 'sections' | 'time' | 'checks' | 'first-click' | 'missclick';
  title: string;
  value: string | number;
  color: string;
};

interface ScreenAnalyticsCardItemProps {
  index: number;
  item: FrameItem;
  analyticsLength: number;
  isIndividualResponse: boolean;
}

export const ScreenAnalyticsCardItem = ({
  index,
  item,
  analyticsLength,
  isIndividualResponse,
}: ScreenAnalyticsCardItemProps) => {
  return (
    <div className={allClasses('flex flex-col', index === 2 || index === 3 ? 'pt-2.5' : undefined)}>
      <div
        className={allClasses(
          'flex mb-2.5',
          index % 2 === 0 ? 'justify-between pl-5' : 'justify-center',
          index === analyticsLength - 1 ? 'pr-0' : undefined
        )}>
        <div className='flex flex-col items-start flex-nowrap'>
          <p className='text-sm text-main-contrast leading-5 mb-2'>{item.title}</p>
          <>
            <div className='flex flex-row items-center'>
              <>{renderAnalyticsIcon(item.color, item.icon, 'w-5 h-5 mr-5')}</>
              <span className={allClasses('text-2xl font-bold leading-8', item.color)}>
                {item.value}
              </span>
            </div>
          </>
        </div>
        {index % 2 === 0 && (
          <div className='flex justify-center w-auto h-14'>
            <div className='h-full bg-main-10 w-0.5' />
          </div>
        )}
      </div>
      {(index === 0 || index === 1) && !isIndividualResponse && (
        <div className='w-32 h-0.5 mx-auto'>
          <div className='h-full bg-main-10 w-full' />
        </div>
      )}
    </div>
  );
};
