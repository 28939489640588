import { AnalyticsEvent, firebaseAnalytics } from '../auth/firebase';

export const sendAnalyticsEvent = (eventDetails: AnalyticsEvent) => {
  firebaseAnalytics.logEvent(eventDetails);
};

export const setAnalyticsScreenName = (screenName: string) => {
  firebaseAnalytics.setCurrentScreen(screenName);
};

export const connectAnalyticsToUser = (userId: string) => {
  firebaseAnalytics.setUserId(userId);
};
