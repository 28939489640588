import { useState } from 'react';

import { useTranslationContext } from '../../../services/translation';
import { usePaymentIntent } from '../../services/api-iteration1/billing';
import { allClasses } from '../../../services/utilities/array';
import { BillingOutput } from '../../services/api-iteration1/billing/api-models';

import {
  Button,
  LoadingOrContent,
  Modal,
  PaymentContext,
  usePaymentContext,
  usePaymentInstance,
} from '../../../components';

import { CloseIcon } from '../../assets/icons';
import { PaymentMethodForm } from '../forms/PaymentMethodForm';
import { routes } from '../../pages/routing/routes';

interface AddCardModalProps {
  isOpen: boolean;
  closeModal: () => void;
  onSuccess?: (paymentMethod: BillingOutput['paymentMethod']) => void;
}

export const AddCardModal = ({ isOpen, closeModal, onSuccess }: AddCardModalProps) => {
  const { t } = useTranslationContext.useContext();

  const { paymentsContext, cancelPaymentIntent } = usePaymentIntent();

  const exitPaymentChange = async () => {
    closeModal();
    cancelPaymentIntent();
  };

  const finishPaymentSetup = (paymentMethod: BillingOutput['paymentMethod']) => {
    closeModal();
    onSuccess?.(paymentMethod);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={exitPaymentChange}
      variant='regular'
      closeModalComponent={<CloseIcon className='icon-hover-main-contrast' />}
      closeIconClassName='absolute top-5 right-5'>
      <div className='flex flex-1 flex-col items-center'>
        <div className='my-10 text-center'>
          <h5 className='mb-2'>
            {t('pages.account.tabs.plan-billing.payment-method.modal.title')}
          </h5>
        </div>
        <div className={allClasses('w-96', !paymentsContext && 'mb-10')}>
          <LoadingOrContent isLoading={!paymentsContext}>
            <PaymentContext options={paymentsContext}>
              <AddCardForm onSuccess={finishPaymentSetup} />
            </PaymentContext>
          </LoadingOrContent>
        </div>
      </div>
    </Modal>
  );
};

const AddCardForm = ({
  onSuccess,
}: {
  onSuccess: (paymentMethod: BillingOutput['paymentMethod']) => void;
}) => {
  const { t } = useTranslationContext.useContext();

  const paymentContext = usePaymentContext();
  const paymentInstance = usePaymentInstance();

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const setupPaymentMethod = async () => {
    if (!paymentContext || !paymentInstance) return;

    const { error, setupIntent } = await paymentInstance.confirmSetup({
      elements: paymentContext,
      confirmParams: {
        return_url: window.location.origin + routes.billing,
        expand: ['payment_method'],
      },
      redirect: 'if_required',
    });

    if (error) {
      const paymentErrorMessage = t(`errors.payment.${error.type}`);
      const errorMessage =
        error.type === 'card_error' ? error.message || paymentErrorMessage : paymentErrorMessage;
      return setErrorMessage(errorMessage);
    }

    const paymentMethod = setupIntent?.payment_method as Exclude<
      typeof setupIntent.payment_method,
      string | null
    >;

    onSuccess({
      id: paymentMethod.id,
      type: 'card',
      cardType: paymentMethod.card?.brand,
      lastDigits: paymentMethod.card?.last4,
      expMonth: paymentMethod.card?.exp_month,
      expYear: paymentMethod.card?.exp_year,
    });
  };

  return (
    <PaymentMethodForm
      className='mb-12'
      button={(canSubmit) => (
        <Button
          className='button__filled px-10 my-10 mx-auto'
          type='button'
          disabled={!canSubmit}
          onClick={setupPaymentMethod}>
          {t('common.save')}
        </Button>
      )}
      {...{ errorMessage }}
    />
  );
};
