import { ApiServerError } from './errors/ApiServerError';
import { ApiValidationError } from './errors/ApiValidationError';
import { AuthenticationError } from './errors/AuthenticationError';
import { ClientError } from './errors/ClientError';

export const mapErrorToTranslationKeys = (err: any): string[] => {
  if (!err) return [];

  const errors = (() => {
    if (ClientError.contains(err)) return [ClientError.getError(err)];
    if (ApiServerError.contains(err)) return [ApiServerError.getError(err)];
    if (ApiValidationError.contains(err)) return ApiValidationError.getErrorList(err);
    if (AuthenticationError.contains(err)) return [AuthenticationError.getError(err)];
    return [];
  })().filter(Boolean);

  return errors.length ? errors : ['errors.default'];
};
