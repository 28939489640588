import { useTranslationContext } from '../../services/translation';

import { Navlink } from '..';

import { AccessForbiddenIllustration } from '../../domain/assets/icons';
import { routes } from '../../domain/pages/routing/routes';

export const AccessForbidden = () => {
  const { t } = useTranslationContext.useContext();

  return (
    <div className='flex w-full h-full flex-col justify-center items-center text-center mt-32'>
      <div className='flex justify-center items-center w-full max-w-2xl py-14'>
        <AccessForbiddenIllustration />
      </div>
      <p className='text-xl leading-30 text-main-contrast font-bold mt-10 mb-2.5'>
        {t('empty-states.access-forbidden.title')}
      </p>
      <div className='w-full max-w-2xl m-0 text-lg text-main-contrast leading-6'>
        <p>{t('empty-states.access-forbidden.description-part-one')}</p>
        <p>{t('empty-states.access-forbidden.description-part-two')}</p>
      </div>
      <div className='mt-10'>
        <Navlink
          to={routes.home}
          className='text-info border-b-default border-main-70 border-solid cursor-pointer hover:text-info-70'>
          {t('empty-states.access-forbidden.go-to-homepage')}
        </Navlink>
      </div>
    </div>
  );
};
