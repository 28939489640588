import { useTranslationContext } from '../../../../services/translation';

import { NoTestIllustration } from '../../../assets/icons';

export const UnavailableDiscoveryFlow = () => {
  const { t } = useTranslationContext.useContext();

  return (
    <div className='flex w-full h-full flex-col justify-center items-center text-center'>
      <div className='flex justify-center items-center w-full max-w-2xl mb-10'>
        <NoTestIllustration />
      </div>
      <p className='h5 text-main-contrast mb-2.5'>
        {t('components.projects.testing.unavailable.title')}
      </p>
      <p className='body-l text-main-contrast'>
        {t('components.projects.testing.unavailable.subtitle')}
      </p>
    </div>
  );
};
