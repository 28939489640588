import { allClasses } from '../../../../../../services/utilities/array';
import {
  DiscoveryRatingScaleAnswer,
  DiscoverySectionType,
} from '../../../../../services/api-iteration1';
import { getKeyForCondition } from '../../../../../../services/utilities/object';

import { StarRatingIcon } from '../../../../../assets/icons';
import { Label } from './Label';

interface ViewStarsProps {
  answer: DiscoveryRatingScaleAnswer | undefined;
  onSetAnswer?: (answer: Omit<DiscoveryRatingScaleAnswer, 'duration'>) => void;
  labels?: {
    left: string;
    middle: string;
    right: string;
  };
}

const ViewStars = ({ answer, onSetAnswer, labels }: ViewStarsProps) => {
  return (
    <>
      {[...Array(5)].map((_, index) => {
        const value = index + 1;
        const isLast = value === 5;
        const isActive = answer ? value <= answer.answer : false;

        return (
          <div
            key={value}
            className={allClasses(
              'w-full flex flex-row sm:flex-col',
              getKeyForCondition({
                'items-start': index === 0,
                'items-start sm:items-center': value === 3,
                'items-end': isLast,
              }),
              isLast ? 'mb-0' : 'mb-2'
            )}>
            <div
              onClick={() =>
                onSetAnswer?.({
                  type: DiscoverySectionType.RatingScale,
                  answer: value,
                })
              }
              className={allClasses(
                'relative flex flex-1 flex-col justify-center items-center border-2 border-solid p-3 rounded-lg order-2',
                !isLast && 'mr-0 sm:mr-2',
                isActive ? 'border-main-highlight' : 'border-main-10',
                onSetAnswer && 'cursor-pointer',
                'mb-2 sm:mb-0'
              )}>
              {isActive && <div className='absolute inset-0 bg-main-highlight opacity-10 z-0' />}
              <StarRatingIcon
                className={allClasses(
                  'h-14 w-14 z-10 sm:w-20 sm:h-20',
                  isActive && 'icon-fill-polygon-warning'
                )}
              />
            </div>
            {value === 1 ? (
              labels && (
                <span className='mr-2 order-1 [writing-mode:vertical-lr] w-10 h-20 sm:[writing-mode:horizontal-tb] sm:order-2 sm:h-full sm:w-full'>
                  {labels?.left && <Label text={labels.left} alignment='left' />}
                </span>
              )
            ) : value === 3 ? (
              labels && (
                <span className='mr-2 order-1 [writing-mode:vertical-lr] w-10 h-20 sm:[writing-mode:horizontal-tb] sm:order-2 sm:h-full sm:w-full'>
                  {labels.middle && <Label text={labels.middle} alignment='center' />}
                </span>
              )
            ) : value === 5 ? (
              labels && (
                <span className='mr-2 order-1 [writing-mode:vertical-lr] w-10 h-20 sm:[writing-mode:horizontal-tb] sm:order-2 sm:h-full sm:w-full'>
                  {labels.right && <Label text={labels.right} alignment='right' />}
                </span>
              )
            ) : (
              <span
                className={allClasses(
                  'h-[4.5rem] order-1 sm:order-2',
                  labels ? 'w-12' : 'w-0'
                )}></span>
            )}
          </div>
        );
      })}
    </>
  );
};

export default ViewStars;
