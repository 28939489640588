import { FolderChildType } from '../../services/api-iteration1';
import { allClasses } from '../../../services/utilities/array';
import { useUpgradeModalToggle } from '../../services/billing/useUpgradeModalToggle';
import { useCurrentUserContext } from '../../services/current-user';

import { Button, useToggle } from '../../../components';

import { CollaboratorsIcon } from '../../assets/icons';
import { CollaboratorsModal } from '../modals/CollaboratorsModal';

import styles from './ResourceCollaboratorsButton.module.css';

interface ResourceCollaboratorsButtonProps {
  projectDetails: {
    type: FolderChildType;
    id: string;
    collaboratorsCount: number | undefined;
  };
  displayCount?: boolean;
  revalidateResource: () => void;
}

export const ResourceCollaboratorsButton = ({
  projectDetails,
  displayCount,
  revalidateResource,
}: ResourceCollaboratorsButtonProps) => {
  const { currentUser } = useCurrentUserContext.useContext();

  const {
    isOpen: isCollaboratorsModalVisible,
    open: openCollaboratorsModal,
    close: closeCollaboratorsModal,
  } = useToggle();

  const { openUpgradeModal } = useUpgradeModalToggle.useContext();

  return (
    <>
      <span className='flex flex-row items-center'>
        {displayCount && (projectDetails.collaboratorsCount || 0)}
        <Button
          onClick={(e) => {
            e.preventDefault();
            openCollaboratorsModal();
          }}
          className={allClasses(styles.collaborators_button, 'p-2.5 ml-2 rounded-lg')}>
          <CollaboratorsIcon
            className={
              projectDetails.collaboratorsCount && displayCount
                ? 'icon-circle-main-contrast icon-main-contrast'
                : ''
            }
          />
        </Button>
      </span>
      {isCollaboratorsModalVisible && (
        <CollaboratorsModal
          isOpen={isCollaboratorsModalVisible}
          closeModal={closeCollaboratorsModal}
          {...{
            currentUser,
            projectDetails,
            revalidateResource,
            openUpgradeModal,
          }}
        />
      )}
    </>
  );
};
