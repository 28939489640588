import {
  DiscoveryLegalAnswer,
  DiscoverySectionLegalOutput,
} from '../../../../services/api-iteration1';
import { StorageFileType } from '../../../../services/api-cloud-storage/api-models';

import { AnalyticsSectionAnswerEmptyState } from '../../../empty-states';
import { AnalyticsLegalAnswerCard } from '../../../cards/AnalyticsLegalAnswerCard';

interface ViewAnalyticsLegalAnswerProps {
  answer?: DiscoveryLegalAnswer;
  legalSection: DiscoverySectionLegalOutput;
}

export const ViewAnalyticsLegalAnswer = ({
  answer,
  legalSection,
}: ViewAnalyticsLegalAnswerProps) => {
  const sectionDocument = legalSection.files.find((f) => f.type === StorageFileType.LegalDocument);

  return answer ? (
    <div className='flex flex-col w-full items-start'>
      <AnalyticsLegalAnswerCard {...{ sectionDocument }} inSummary={false} />
    </div>
  ) : (
    <AnalyticsSectionAnswerEmptyState />
  );
};
