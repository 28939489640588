import { useEffect, useRef } from 'react';

import { confirmEmailVerification } from '../services/api-iteration1';
import { useQueryParams, useRouter } from '../../services/router';
import { createQueryUrl, UrlParams } from '../../services/api/utils';

import { routes } from './routing/routes';

export const ConfirmEmail = () => {
  const { push } = useRouter();
  const confirmationInProgress = useRef(false);

  const {
    params: { email, confirmationToken },
  } = useQueryParams<{
    email: string;
    confirmationToken: string;
  }>();

  useEffect(() => {
    (async () => {
      if (confirmationInProgress.current) return;

      const goToHome = (params?: UrlParams) => push(createQueryUrl(routes.home, params));

      if (!email || !confirmationToken) return goToHome();

      try {
        confirmationInProgress.current = true;
        await confirmEmailVerification({ email, emailConfirmCode: confirmationToken });
        goToHome({ emailConfirmationStatus: 'success' });
      } catch (err) {
        goToHome({ emailConfirmationStatus: 'failure' });
      }
    })();
  }, [confirmationToken, email, push]);

  return null;
};
