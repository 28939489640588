import { useState } from 'react';

import { useTranslationContext } from '../../../services/translation';

import {
  LoadingIndicator,
  PaymentMethod,
  PaymentMethodChangeEvent,
  useToggle,
} from '../../../components';

interface PaymentMethodFormProps {
  issueActive?: boolean;
  className?: string;
  onStatusChange?: (isValidForSubmit: boolean) => void;
  errorMessage?: string;
  button?: (canSubmit: boolean) => React.ReactNode;
}

export const PaymentMethodForm = ({
  issueActive,
  className,
  button,
  errorMessage,
  onStatusChange,
}: PaymentMethodFormProps) => {
  const { t } = useTranslationContext.useContext();

  const [canSubmit, setCanSubmit] = useState<boolean>(false);

  const { isOpen: isInteractivePayment, open: showPaymentElement } = useToggle();

  const checkPaymentValidity = (event: PaymentMethodChangeEvent) => {
    const isValidForSubmit = !!event.complete;

    onStatusChange?.(isValidForSubmit);
    setCanSubmit(isValidForSubmit);
  };

  return (
    <form id='payment-form' {...{ className }}>
      {issueActive && (
        <div className='text-base mb-6 text-danger-20'>
          <p>{t('pages.account.tabs.plan-billing.payment-method.current-card-issue.part1')}</p>
          <p>{t('pages.account.tabs.plan-billing.payment-method.current-card-issue.part2')}</p>
        </div>
      )}
      {!isInteractivePayment && (
        <div className='flex justify-center items-center min-h-10 w-full min-w-[280px]'>
          <LoadingIndicator />
        </div>
      )}
      <div id='payment-element'>
        <PaymentMethod onChange={checkPaymentValidity} onReady={() => showPaymentElement()} />
      </div>
      {errorMessage && (
        <div
          id='error-message'
          className='text-danger-60 font-bold py-2 mt-4 text-xs bg-danger-10 rounded-md'>
          <p className='mx-2'>{errorMessage}</p>
        </div>
      )}
      {button?.(canSubmit)}
    </form>
  );
};
