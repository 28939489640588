import {
  Draggable,
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';

export interface DraggableViewProps extends DraggableProvidedDraggableProps {
  ref(element?: HTMLElement | null): any;
}

export type DraggableHandleProps = DraggableProvidedDragHandleProps;

interface DraggableItemProps {
  id: string;
  index: number;
  children(
    dragViewProps: DraggableViewProps,
    dragHandleProps?: DraggableHandleProps,
    snapshot?: DraggableStateSnapshot
  ): JSX.Element;
  type?: string;
}

export const DraggableItem = ({ id, index, children }: DraggableItemProps) => {
  return (
    <Draggable draggableId={id} {...{ index }}>
      {(provided, snapshot) =>
        children(
          {
            ref: provided.innerRef,
            ...provided.draggableProps,
          },
          provided.dragHandleProps,
          snapshot
        )
      }
    </Draggable>
  );
};
