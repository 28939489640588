import { useTranslationContext } from '../../../services/translation';

import { Button, Modal } from '../../../components';

import { CloseIcon } from '../../assets/icons';

interface EndTestModalProps {
  onEndTest?: () => void;
  isOpen: boolean;
  closeEndTestModal: () => void;
}

export const EndTestModal = ({ isOpen, closeEndTestModal, onEndTest }: EndTestModalProps) => {
  const { t } = useTranslationContext.useContext();

  const endTest = () => {
    onEndTest?.();
    closeEndTestModal();
  };

  return (
    <Modal
      title={t('components.projects.end-test.title')}
      isOpen={isOpen}
      onClose={closeEndTestModal}
      variant='regular'
      closeModalComponent={<CloseIcon className='icon-hover-main-contrast' />}
      closeIconClassName='absolute top-5 right-5'>
      <div className='pt-10 pb-20 text-center'>
        <h2 className='my-10 text-main-contrast leading-8 text-2xl font-bold'>
          {t('components.projects.end-test.title')}
        </h2>
        <div className='max-w-xs mx-auto mb-10 text-main-contrast text-lg leading-6 font-medium'>
          {t('components.projects.end-test.description-part-one')}
          <p className='mb-0 text-danger-60'>
            {t('components.projects.end-test.description-part-two')}
          </p>
        </div>
        <div className='flex flex-row items-center justify-center'>
          <Button
            className='button__filled mr-4 py-2.5 px-10 justify-center bg-main border-2 border-solid border-main-10 hover:enabled:bg-main-10'
            type='button'
            onClick={closeEndTestModal}>
            {t('components.projects.end-test.cancel')}
          </Button>
          <Button
            className='button__filled py-2.5 px-10 justify-center bg-danger-60 text-main hover:enabled:bg-danger'
            type='submit'
            onClick={endTest}
            loadingIconType='light'
            loadingOpacity='50'>
            {t('components.projects.end-test.submit')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
