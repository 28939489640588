import { apiIteration1, useIteration1Resource } from '../../config';
import {
  NewUserTestInput,
  UserTestOutput,
  UserTestUpdate,
  UserTestVersionUpdate,
} from './api-models';
import { endpoints } from './endpoints';

export * from './api-models';

export const createNewUserTest = async (newUserTest: NewUserTestInput) => {
  return apiIteration1.post(endpoints.v1.userTests, newUserTest);
};

export const useUserTest = (userTestId: string) => {
  return useIteration1Resource<UserTestOutput>(endpoints.v1.userTest(userTestId));
};

export const updateUserTest = async (userTestId: string, updates: UserTestUpdate) => {
  return apiIteration1.patch(endpoints.v1.userTest(userTestId), updates);
};

export const renameUserTest = async (
  userTestId: string,
  versionId: string,
  payload: Pick<Required<UserTestUpdate>, 'name'>
) => {
  return apiIteration1.patch(endpoints.v1.userTestVersion(userTestId, versionId), payload);
};

export const deleteUserTest = async (userTestId: string) => {
  return apiIteration1.destroy(endpoints.v1.userTest(userTestId));
};

export const createNewUserTestVersion = async (userTestId: string, name: string) => {
  return apiIteration1.post(endpoints.v1.versions(userTestId), { name });
};

export const renameUserTestVersion = async (
  userTestId: string,
  versionId: string,
  payload: Pick<Required<UserTestVersionUpdate>, 'name'>
) => {
  return apiIteration1.patch(endpoints.v1.userTestVersion(userTestId, versionId), payload);
};

export const deleteUserTestVersion = async (userTestId: string, versionId: string) => {
  return apiIteration1.destroy(endpoints.v1.userTestVersion(userTestId, versionId));
};

export const endUserTest = async (userTestId: string, versionId: string) => {
  return apiIteration1.patch(endpoints.v1.endUserTest(userTestId, versionId));
};
