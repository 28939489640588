import { useRef, useState } from 'react';

import { useTranslationContext } from '../../../../../services/translation';
import { allClasses } from '../../../../../services/utilities/array';
import { StorageFileType } from '../../../../services/api-cloud-storage/api-models';
import {
  DiscoveryOpenWordAnswer,
  DiscoverySectionOpenWordChoiceOutput,
  DiscoverySectionType,
} from '../../../../services/api-iteration1';

import { SectionImage } from './SectionImage';
import { SectionTypeLabel } from './SectionTypeLabel';

import styles from './ViewSection.module.css';

interface ViewOpenWordChoiceProps {
  openWordChoiceSection: DiscoverySectionOpenWordChoiceOutput;
  answer: DiscoveryOpenWordAnswer | undefined;
  onSetAnswer?: (answer: Omit<DiscoveryOpenWordAnswer, 'duration'>) => void;
  interactive?: boolean;
}

export const ViewOpenWordChoice = ({
  openWordChoiceSection,
  answer,
  onSetAnswer,
  interactive,
}: ViewOpenWordChoiceProps) => {
  const { t } = useTranslationContext.useContext();

  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const { title, files, others } = openWordChoiceSection;
  const { description } = others;

  const sectionImage = files.find((f) => f.type === StorageFileType.SectionImage);

  return (
    <div className={styles.view_section_wrapper}>
      {sectionImage && <SectionImage image={sectionImage} />}
      {interactive && (
        <SectionTypeLabel label={t('components.projects.sections.question')} className='w-full' />
      )}
      <h6 className='inline-block w-full text-xl text-main-contrast font-bold leading-30 mb-2.5 break-all'>
        {title}
      </h6>
      {description && (
        <p className='inline-block w-full text-lg text-main-contrast font-normal leading-normal'>
          {description}
        </p>
      )}
      <div className='flex flex-row justify-start mt-7 w-full'>
        <div
          onClick={() => inputRef.current?.focus()}
          className={allClasses(
            'relative flex flex-col w-full justify-center items-center border-2 border-solid p-3 rounded-lg',
            isFocused ? 'border-main-highlight' : 'border-main-10',
            onSetAnswer && 'cursor-pointer'
          )}>
          <input
            autoFocus
            maxLength={200}
            ref={inputRef}
            disabled={!onSetAnswer}
            value={answer?.answer || ''}
            onChange={(e) =>
              onSetAnswer?.({
                type: DiscoverySectionType.OpenWordChoice,
                answer: e.target.value,
              })
            }
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholder={t('components.projects.sections.open-word-choice.placeholder')}
            className='text-xl text-main-contrast placeholder-neutral-20 bg-transparent outline-none font-bold p-1 w-full'
          />
        </div>
      </div>
    </div>
  );
};
