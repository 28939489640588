import { useCallback, useState } from 'react';

import { createContextService } from '../context';
import { Alert, errorAlert, successAlert, warningAlert } from './alerts';

const alertDisplayTime = 3000;

interface AlertsService {
  alerts: Alert[];
  addErrorAlert(message: string, settings?: { timeout: number }): void;
  removeErrorAlert(timestamp: number): void;
  addWarningAlert(message: string): void;
  removeWarningAlert(timestamp: number, automaticCloseAfterDuration?: number): void;
  addSuccessAlert(title: string, message: string): void;
  removeSuccessAlert(timestamp: number, automaticCloseAfterDuration?: number): void;
}

const useAlerts = (): AlertsService => {
  const [alerts, setAlerts] = useState<Alert[]>([]);

  const removeErrorAlert = useCallback(
    (timestamp: number) =>
      setAlerts((alerts) =>
        alerts.filter((alert) => alert.timestamp !== timestamp || alert.type !== 'error')
      ),
    []
  );

  const removeWarningAlert = useCallback(
    (timestamp: number) =>
      setAlerts((alerts) =>
        alerts.filter((alert) => alert.timestamp !== timestamp || alert.type !== 'warning')
      ),
    []
  );

  const removeSuccessAlert = useCallback(
    (timestamp: number) =>
      setAlerts((alerts) =>
        alerts.filter((alert) => alert.timestamp !== timestamp || alert.type !== 'success')
      ),
    []
  );

  const addErrorAlert = useCallback(
    (message: string, settings?: { timeout?: number }) => {
      const newAlert = errorAlert(message);
      setAlerts((alerts) => [newAlert, ...alerts]);
      setTimeout(() => removeErrorAlert(newAlert.timestamp), settings?.timeout || alertDisplayTime);
    },
    [removeErrorAlert]
  );

  const addWarningAlert = useCallback(
    (message: string, automaticCloseAfterDuration?: number) => {
      const newAlert = warningAlert(message);
      setAlerts((alerts) => [newAlert, ...alerts]);

      if (automaticCloseAfterDuration)
        setTimeout(() => removeWarningAlert(newAlert.timestamp), alertDisplayTime);
    },
    [removeWarningAlert]
  );

  const addSuccessAlert = useCallback(
    (title: string, message: string, automaticCloseAfterDuration?: number) => {
      const newAlert = successAlert(title, message);
      setAlerts((alerts) => [newAlert, ...alerts]);

      if (automaticCloseAfterDuration)
        setTimeout(() => removeSuccessAlert(newAlert.timestamp), alertDisplayTime);
    },
    [removeSuccessAlert]
  );

  return {
    alerts,
    addErrorAlert,
    removeErrorAlert,
    addWarningAlert,
    removeWarningAlert,
    addSuccessAlert,
    removeSuccessAlert,
  };
};

export const useAlertsContext = createContextService(useAlerts);
