import { FolderChildType, FolderOutput } from '../../services/api-iteration1';
import { useTranslationContext } from '../../../services/translation';

import { Button } from '../../../components';

import { PlusSignIcon } from '../../assets/icons';
import { AddFolderButton, FolderActionsButton } from '../buttons';
import { ResourceCollaboratorsButton } from '../buttons/ResourceCollaboratorsButton';

interface EditFolderNavbarActionsProps {
  folder: FolderOutput;
  onClickRename: (name: string) => void;
  onClickDelete: () => void;
  openCreateFolderModal: () => void;
  openCreateProjectModal: () => void;
  revalidateResource: () => void;
}

export const EditFolderNavbarActions = ({
  folder,
  onClickDelete,
  onClickRename,
  openCreateFolderModal,
  openCreateProjectModal,
  revalidateResource,
}: EditFolderNavbarActionsProps) => {
  const { t } = useTranslationContext.useContext();

  return (
    <>
      <ResourceCollaboratorsButton
        displayCount={folder.collaborators?.length > 0}
        projectDetails={{
          id: folder.id,
          type: FolderChildType.Folder,
          collaboratorsCount: folder.collaborators?.length,
        }}
        {...{
          revalidateResource,
        }}
      />
      {folder?.parents.length < 1 && (
        <AddFolderButton className='ml-5' onClick={openCreateFolderModal} />
      )}
      {folder?.children?.length >= 1 && (
        <Button className='button__filled' onClick={openCreateProjectModal}>
          <PlusSignIcon />
          <span className='px-5'>{t('common.create')}</span>
        </Button>
      )}
      <FolderActionsButton onClickRename={onClickRename} onClickDelete={onClickDelete} />
    </>
  );
};
