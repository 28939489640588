export enum UiEvents {
  GiveUpTest = 'GiveUpTest',
}

export const uiEvents = {
  giveUpTest: {
    emit: () => {
      const event = new CustomEvent(UiEvents.GiveUpTest);

      dispatchEvent(event);
    },
    listen: (callback: (e: CustomEvent) => void) => {
      const handler = (e: any) => callback(e);

      addEventListener(UiEvents.GiveUpTest, handler);

      return () => removeEventListener(UiEvents.GiveUpTest as any, handler);
    },
  },
};
