import { allClasses } from '../../../services/utilities/array';

import { Button, Icon } from '../../../components';

import { DotsIcon } from '../../assets/icons';

interface DotsButtonProps {
  isMenuOpen: boolean;
  hasBorder?: boolean;
  onClick?: () => void;
}

export const DotsButton = ({ isMenuOpen, hasBorder, onClick }: DotsButtonProps) => {
  return (
    <Button
      className={allClasses(
        'p-2.5 rounded-lg hover:enabled:bg-main-10',
        hasBorder && 'border-2 border-solid border-main-10',
        isMenuOpen
          ? 'bg-main-90 icon-circle-main-contrast icon-circle-fill-main-contrast'
          : 'icon-circle-neutral-80 icon-circle-fill-neutral-80'
      )}
      {...{ onClick }}>
      <Icon Svg={DotsIcon} className='icon__small' />
    </Button>
  );
};
