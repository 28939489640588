import { allClasses } from '../../../services/utilities/array';

interface ProjectTemplateCardProps {
  thumbnail?: React.ReactNode;
  title: string;
  checkedClassName?: string;
  onClick: () => void;
}

export const ProjectTemplateCard = ({
  thumbnail,
  title,
  checkedClassName,
  onClick,
}: ProjectTemplateCardProps) => {
  return (
    <div
      className={allClasses(
        'border-2 border-main-10 border-solid rounded-lg mr-5 mb-5 pt-10 pb-6 relative',
        checkedClassName,
        'min-w-[12rem] max-w-[13rem] min-h-[13rem] max-h-[14rem]'
      )}
      onClick={onClick}>
      <div className='px-13'>
        {thumbnail || <div className='mx-auto bg-main-10 rounded-lg w-[6.45rem] h-[6.45rem]' />}
      </div>
      <p className='mt-6 mb-0 font-normal text-main-contrast text-lg leading-snug'>{title}</p>
    </div>
  );
};
