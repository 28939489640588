import { useState } from 'react';
import { PermissionPlans } from '@iteration1/permission-validators';

import { useTranslationContext } from '../../../services/translation';
import { useErrorHandlerContext } from '../../../services/error-handling';
import { removeCurrentUser } from '../../services/api-iteration1';
import { sendAnalyticsEvent } from '../../../services/app-analytics';
import { analyticsEvents } from '../../services/app-analytics/events';
import { useCurrentUserContext } from '../../services/current-user';

import { Button, Modal } from '../../../components';

import { CloseIcon } from '../../assets/icons';

interface DeleteAccountModalProps {
  isOpen: boolean;
  close: () => void;
  onSuccess?: () => void;
}

export const DeleteAccountModal = ({ isOpen, close }: DeleteAccountModalProps) => {
  const { t } = useTranslationContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();
  const { currentUser } = useCurrentUserContext.useContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [reason, setReason] = useState<string>('');

  const deleteAccount = async () => {
    if (!currentUser) return;

    try {
      setIsLoading(true);
      await removeCurrentUser();
      sendAnalyticsEvent(
        analyticsEvents.deleteAccount({
          user_id: currentUser.id,
          plan_name: currentUser.subscription?.plan.name || PermissionPlans.IndividualFree,
          billing_type: currentUser.subscription?.price.recurring?.interval,
          reason,
          // TODO: These empty email input should send the email as soon as we have a checkbox that allows saving this data piece
          email: undefined,
        })
      );
      window.location.reload();
    } catch (err) {
      setIsLoading(false);
      handleError(err);
    }
  };

  return (
    <Modal
      title={t('pages.account.tabs.profile.delete-account-modal.title')}
      isOpen={isOpen}
      onClose={close}
      variant='regular'
      closeModalComponent={<CloseIcon className='icon-hover-main-contrast' />}
      closeIconClassName='absolute top-5 right-5'>
      <div className='pt-10 pb-20 text-center'>
        <h2 className='my-10 text-main-contrast leading-8 text-2xl font-bold'>
          {t('pages.account.tabs.profile.delete-account-modal.title')}
        </h2>
        <div className='mx-auto max-w-22 mb-10 text-main-contrast text-lg leading-6 font-medium'>
          {t('pages.account.tabs.profile.delete-account-modal.description-part-one')}
          <p className='mt-5 mb-0 text-danger-60'>
            {t('pages.account.tabs.profile.delete-account-modal.description-part-two')}
          </p>
        </div>
        <div className='mb-10 mx-16'>
          <textarea
            autoFocus
            placeholder={t('pages.account.tabs.profile.delete-account-modal.placeholder')}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            className='py-3 px-5 w-full h-24 bg-main border-2 rounded-lg border-solid border-main-10 text-lg placeholder-neutral hover:border-main-highlight focus:border-main-highlight focus:outline-none'
          />
        </div>
        <div className='flex flex-row items-center justify-center'>
          <Button
            className='button__filled mr-4 py-2.5 px-10 justify-center bg-main border-2 border-solid border-main-10 hover:enabled:bg-main-10'
            type='button'
            onClick={close}
            disabled={isLoading}>
            {t('pages.account.tabs.profile.delete-account-modal.cancel')}
          </Button>
          <Button
            className='button__filled py-2.5 px-10 justify-center bg-danger-60  text-main hover:enabled:bg-danger'
            type='submit'
            onClick={deleteAccount}
            loading={isLoading}
            loadingIconType='light'
            loadingOpacity='50'>
            {t('pages.account.tabs.profile.delete-account-modal.submit')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
