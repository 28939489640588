import { ReactNode } from 'react';

import { AnalyticsSectionHeader } from '../headers/AnalyticsSectionHeader';

interface AnalyticsSectionContainerProps {
  description?: string;
  icon: React.ReactNode;
  rightSideInformation?: React.ReactNode;
  title: string;
  contentTitle?: string;
  children?: ReactNode;
}

export const AnalyticsSectionContainer = ({
  children,
  description,
  icon,
  rightSideInformation,
  title,
  contentTitle,
}: AnalyticsSectionContainerProps) => {
  return (
    <div className='w-full flex flex-col px-5 border-2 border-main-10 border-solid rounded-lg'>
      <AnalyticsSectionHeader
        title={title}
        icon={icon}
        rightSideInformation={rightSideInformation}
      />
      <div className='p-10 pb-5'>
        {contentTitle && (
          <p className='mb-2.5 text-xl text-main-contrast font-bold leading-30'>{contentTitle}</p>
        )}
        {description && <p className='text-lg text-neutral-60 leading-tight'>{description}</p>}
        {children}
      </div>
    </div>
  );
};
