import { allClasses } from '../../services/utilities/array';

import { ChevronDownIcon, DeleteIcon } from '../../domain/assets/icons';

import styles from './Collapsible.module.css';

interface CollapsibleProps {
  missingValue?: boolean;
  children: React.ReactNode;
  isExpanded: boolean;
  title: string;
  allowDelete?: boolean;
  onDelete?: () => void;
  onClick?: () => void;
}

export const Collapsible = ({
  missingValue,
  children,
  title,
  isExpanded,
  allowDelete,
  onDelete,
  onClick,
}: CollapsibleProps) => {
  return (
    <div
      className={allClasses(
        'mb-2 border-2 border-solid rounded-lg cursor-default hover:border-main-highlight',
        isExpanded ? 'pt-3 pb-5' : 'py-3',
        missingValue ? 'border-danger' : 'border-main-10'
      )}>
      <div className={styles.collapsible_header} onClick={onClick}>
        <div>{title}</div>
        <ChevronDownIcon className={allClasses(isExpanded ? 'rotate-180' : undefined)} />
        {allowDelete && (
          <span className={styles.item_actions}>
            <DeleteIcon
              className='icon__small mr-4 cursor-pointer icon-hover-danger-20'
              onClick={(e) => {
                e.stopPropagation();
                onDelete?.();
              }}
            />
          </span>
        )}
      </div>
      {isExpanded && <div className='px-5'>{children}</div>}
    </div>
  );
};
