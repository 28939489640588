import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import debounce from 'just-debounce-it';

import { useErrorHandlerContext } from '../../../services/error-handling';
import { useLocalStorage } from '../../../services/local-storage';
import { getDeviceDetails } from '../../../services/device';
import { isUndefined } from '../../../services/utilities/value';
import { useTestSectionDuration } from '../../../services/record';
import { useTestPauseModalContext } from '../../../services/record/useTestPauseModal';

import { getSortedSections } from './utils';
import {
  addDiscoveryResponseAnswer,
  DefaultSectionType,
  createNewDiscoveryResponse,
  VersionStatus,
  QuestionAnswer,
  DiscoveryVersionOutput,
  giveUpDiscoveryResponseAnswer,
} from '../api-iteration1';
import { useCurrentUserContext } from '../current-user';
import { localStorageKeys } from '../local-storage';
import { validateSectionAnswers } from '../validation';
import {
  defaultSectionScreens,
  getSectionsScreens,
  SectionScreenGroup,
} from '../sections/getSectionsScreens';

import {
  DiscoveryProjectSection,
  useDiscoveryVersionSections,
} from '../api-iteration1/projects/discovery/sections';

interface ViewDiscoveryService {
  isLoading: boolean;
  isFirst: boolean;
  isLast: boolean;
  isLive: boolean;
  isDraft: boolean;
  isDone: boolean;
  canContinue: boolean;
  canSkip: boolean;
  isLastScreen: boolean;
  isAnswerableScreen: boolean;
  availableInstructions: boolean;
  canSeeInstructions: boolean;
  currentSection: DiscoveryProjectSection | undefined;
  sectionAnswer: Omit<QuestionAnswer, 'duration'> | undefined;
  startDiscoveryFlow: () => Promise<void>;
  setSectionAnswer: (answer: Omit<QuestionAnswer, 'duration'>) => void;
  submitAndContinueFlow: () => Promise<void>;
  nextSection: () => void;
  nextSectionScreen?: () => void;
  giveUpTesting: (reason: string) => void;
  continueFlow: () => Promise<void>;
  submitSelectedSectionAnswer: () => Promise<void>;
  debouncedSubmitAnswer: (sectionId: string, answer: Omit<QuestionAnswer, 'duration'>) => void;
  immediatelySubmitAnswer: (
    sectionId: string,
    answer: Omit<QuestionAnswer, 'duration'>
  ) => Promise<void>;
  error?: string;
  totalAnswerableSections: number;
  currentSectionScreenIndex: number;
  skippingSectionInProgress: boolean;
}

interface ViewDiscoveryServiceProps {
  discoveryId: string;
  versionId: string;
  inPreviewVersion?: DiscoveryVersionOutput;
}

export const useViewDiscovery = ({
  discoveryId,
  versionId,
  inPreviewVersion,
}: ViewDiscoveryServiceProps): ViewDiscoveryService => {
  const { currentUser } = useCurrentUserContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();
  const { closePauseModal } = useTestPauseModalContext.useContext();

  const { getLocalCopy, saveLocalCopy, deleteLocalCopy } = useLocalStorage<{
    index: number;
    responseId: string | undefined;
  }>(localStorageKeys.discoveryResponse(versionId));

  const inPreview = !!inPreviewVersion;

  const [currentSectionIndex, setCurrentSectionIndex] = useState<number>(() => {
    return (!inPreview && getLocalCopy()?.index) || 0;
  });
  const [responseId, setResponseId] = useState<string | undefined>(
    () => (!inPreview && getLocalCopy()?.responseId) || undefined
  );
  const [sectionAnswer, setSectionAnswer] = useState<Omit<QuestionAnswer, 'duration'>>();
  const [currentSectionScreens, setCurrentSectionScreens] = useState<
    SectionScreenGroup & { sectionId: string }
  >({ ...defaultSectionScreens, sectionId: '' });

  const { data, isLoading, error } = useDiscoveryVersionSections(discoveryId, versionId, {
    waitForFetch: inPreview,
    placeholderDataWhileWait: inPreviewVersion,
  });

  const { sections = [], status } = data || {};

  const { sortableSections, welcomeSection, thankYouSection } = getSortedSections(sections);

  const orderedSections = [welcomeSection, ...sortableSections, thankYouSection];

  const currentSection = orderedSections.length ? orderedSections[currentSectionIndex] : undefined;
  const currentSectionScreenIndex = currentSectionScreens.index;
  const lastSectionIndex = orderedSections.length - 1;
  const isLast = currentSectionIndex === lastSectionIndex;
  const isLastScreen = currentSectionScreens.index + 1 === currentSectionScreens.totalCount;
  const isFirst = currentSectionIndex === 0;
  const shouldSkipWelcome = isFirst && !!welcomeSection?.others.disabled;
  const totalAnswerableSections = sortableSections.length;
  const isValidAnswer = validateSectionAnswers(currentSection?.type, sectionAnswer);

  const isLive = status === VersionStatus.Live;
  const isDraft = status === VersionStatus.Draft;
  const isDone = status === VersionStatus.Done;

  const hasSectionNextScreen = () =>
    currentSectionScreens.index + 1 < currentSectionScreens.totalCount;

  const isAnswerableSectionScreen = ({ answerScreenIndexes, index }: SectionScreenGroup) =>
    answerScreenIndexes.includes(index);

  const currentScreenIndexIsAnswerable = isAnswerableSectionScreen(currentSectionScreens);

  const hasInstructionsScreen = !isUndefined(currentSectionScreens.instructionScreenIndex);
  const isInstructionsScreen =
    currentSectionScreens.instructionScreenIndex === currentSectionScreenIndex;
  const availableInstructions =
    hasInstructionsScreen && (isInstructionsScreen || currentScreenIndexIsAnswerable);

  const canSeeInstructions = hasInstructionsScreen && currentScreenIndexIsAnswerable;
  const canContinue =
    (currentSection?.type !== DefaultSectionType.Welcome && !isLast && isValidAnswer) ||
    !currentSectionScreens.answerScreenIndexes.includes(currentSectionScreens.index);

  const sectionDurationRecorder = useTestSectionDuration({
    isAnswerableView: currentScreenIndexIsAnswerable,
    sectionId: currentSection?._id,
  });

  const nextSection = useCallback(
    (newResponseId?: string) => {
      if (isLast) return;

      setSectionAnswer(undefined);
      setCurrentSectionIndex((prev) => {
        const next = prev + 1;

        if (!inPreview) saveLocalCopy({ index: next, responseId: responseId || newResponseId });
        return next;
      });
    },
    [inPreview, isLast, responseId, saveLocalCopy]
  );

  const nextSectionScreen = useCallback(() => {
    setCurrentSectionScreens((prev) =>
      prev.index + 1 < prev.totalCount ? { ...prev, index: prev.index + 1 } : prev
    );
  }, []);

  const submitAnswer = useCallback(
    async (sectionId: string, answer: Omit<QuestionAnswer, 'duration'>) => {
      try {
        const newAnswer = {
          sectionId,
          answer: { ...answer, duration: sectionDurationRecorder.current.getTotalDuration() },
        };

        if (responseId) {
          await addDiscoveryResponseAnswer(discoveryId, versionId, responseId, newAnswer);
        }
      } catch (err) {
        handleError(err);
      }
    },
    [discoveryId, handleError, responseId, sectionDurationRecorder, versionId]
  );

  const submitSelectedSectionAnswer = useCallback(async () => {
    if (!currentSection || !sectionAnswer) return;

    submitAnswer(currentSection._id, sectionAnswer);
  }, [currentSection, sectionAnswer, submitAnswer]);

  const debouncedSubmitAnswer = useMemo(() => debounce(submitAnswer, 1000), [submitAnswer]);

  const immediatelySubmitAnswer = useCallback(
    async (sectionId: string, answer: Omit<QuestionAnswer, 'duration'>) => {
      try {
        await Promise.all([
          debouncedSubmitAnswer(sectionId, answer),
          debouncedSubmitAnswer.flush(), // Immediately invoke the debounced function
        ]);
      } catch (err) {
        handleError(err);
      }
    },
    [handleError, debouncedSubmitAnswer]
  );

  const createNewResponse = useCallback(async () => {
    if (inPreview || !totalAnswerableSections) return;

    const device = getDeviceDetails();
    const newDiscoveryResponse = currentUser?.id
      ? {
          userId: currentUser.id,
          totalQuestions: totalAnswerableSections,
          device,
        }
      : {
          totalQuestions: totalAnswerableSections,
          device,
        };

    const discoveryResponseDetails = await createNewDiscoveryResponse(
      discoveryId,
      versionId,
      newDiscoveryResponse
    );
    setResponseId(discoveryResponseDetails.id);

    return discoveryResponseDetails.id;
  }, [currentUser?.id, discoveryId, inPreview, totalAnswerableSections, versionId]);

  const startDiscoveryFlow = useCallback(async () => {
    if (inPreview) return nextSection();

    try {
      const responseId = await createNewResponse();
      nextSection(responseId);
    } catch (err) {
      handleError(err);
    }
  }, [createNewResponse, handleError, inPreview, nextSection]);

  const continueFlow = async () => {
    hasSectionNextScreen() ? nextSectionScreen() : nextSection();
  };

  const submitAndContinueFlow = async () => {
    if (!inPreview && currentScreenIndexIsAnswerable) await submitSelectedSectionAnswer();

    continueFlow();
  };

  const giveUpTesting = async (reason: string) => {
    if (!inPreview && responseId)
      await giveUpDiscoveryResponseAnswer(discoveryId, versionId, responseId, reason);

    deleteLocalCopy();
    setCurrentSectionIndex(lastSectionIndex);
    closePauseModal();
  };

  useLayoutEffect(() => {
    (async () => {
      if (shouldSkipWelcome) {
        try {
          await createNewResponse();
          setCurrentSectionIndex(1);
        } catch (err) {
          handleError(err);
        }
      }
    })();
  }, [createNewResponse, handleError, shouldSkipWelcome]);

  useEffect(() => {
    if (!isDone) return;

    setCurrentSectionIndex(0);
    if (!inPreview) deleteLocalCopy();
  }, [deleteLocalCopy, inPreview, isDone]);

  useEffect(() => {
    if (!currentSection?._id) return;

    const sectionsScreens = getSectionsScreens(sections);
    const initialCurrentSectionScreens = currentSection._id
      ? sectionsScreens[currentSection._id]
      : defaultSectionScreens;

    setCurrentSectionScreens((prev) => {
      const sectionHasChanged = prev.sectionId !== currentSection._id;

      return sectionHasChanged
        ? { ...initialCurrentSectionScreens, sectionId: currentSection._id }
        : prev;
    });
  }, [currentSection?._id, sections]);

  return {
    isLoading,
    isFirst,
    isLast,
    isLive,
    isDraft,
    isDone,
    isAnswerableScreen: currentScreenIndexIsAnswerable,
    availableInstructions,
    canSeeInstructions,
    canContinue,
    canSkip: !currentSection?.required && !isFirst && !isLast,
    currentSection,
    sectionAnswer,
    startDiscoveryFlow,
    setSectionAnswer,
    nextSection,
    giveUpTesting,
    error,
    totalAnswerableSections,
    currentSectionScreenIndex,
    isLastScreen,
    nextSectionScreen,
    continueFlow,
    submitAndContinueFlow,
    submitSelectedSectionAnswer,
    debouncedSubmitAnswer,
    immediatelySubmitAnswer,
    skippingSectionInProgress: shouldSkipWelcome,
  };
};
