import { useTranslationContext } from '../../../services/translation';
import { allClasses } from '../../../services/utilities/array';
import { useCopyToClipboard } from '../../services/copy-to-clipboard/useCopyToClipboard';

import { Button, Icon, Modal, useHover, useMultiStep, useToggle } from '../../../components';

import { CheckIconOn, CloseIcon, DuplicateIcon, EmbedIcon, InfoIcon } from '../../assets/icons';
import { CONFIG_SUPPORT_EMAIL } from '../../config';

interface EmbedTestButtonProps {
  className?: string;
  testUrl: string;
  testId: string;
  testName: string | undefined;
}

export const EmbedTestButton = ({ className, testUrl, testId, testName }: EmbedTestButtonProps) => {
  const { t } = useTranslationContext.useContext();
  const { isOpen, open, close } = useToggle();
  const { step, stepTo } = useMultiStep<'step_1' | 'step_2'>('step_1');
  const { isHover, onHover, onLeave } = useHover();
  const { copied, copiedValue, copyToClipboard } = useCopyToClipboard();

  const iframeCodeText = `<iframe
  id="iteration1_${testId}"
  title="${testName || 'Iteration1 User Test'}"
  width="800"
  height="500"
  src="${testUrl}?embedded=true"
  allowfullscreen />`;

  const closeModal = () => {
    close();
    stepTo('step_1');
  };

  return (
    <>
      <Button
        className={allClasses(
          'button__ghost border-main-10 hover:enabled:bg-main-10 px-4',
          className
        )}
        onClick={() => open()}>
        <EmbedIcon />
        <span className='px-2'>{t('components.projects.project-actions.embed.button_short')}</span>
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        variant='regular'
        headerTitleComponent={
          <div className='flex flex-1 p-2.5 mt-2 justify-start items-center'>
            <a
              className='flex group text-neutral-30'
              href={`mailto:${CONFIG_SUPPORT_EMAIL}`}
              onMouseOver={() => onHover(undefined)}
              onMouseLeave={() => onLeave()}>
              <Icon
                Svg={InfoIcon}
                className={allClasses(
                  'icon__small ml-2 mr-2',
                  isHover ? 'icon-stroke-circle-main-contrast' : 'icon-stroke-circle-neutral-30'
                )}
              />
              <span className='group-hover:text-main-contrast'>
                {t('components.projects.project-actions.embed.support')}
              </span>
            </a>
          </div>
        }
        className='h-[603px]'
        closeModalComponent={<CloseIcon className='icon-hover-main-contrast' />}
        closeIconClassName='absolute top-5 right-5'>
        <div className='flex flex-1 flex-col items-center'>
          <div className='my-10 text-center'>
            <h5 className=''>{t('components.projects.project-actions.embed.modal.title')}</h5>
          </div>
          <div className='flex flex-col flex-1 px-10'>
            <p className='font-medium mx-20 text-center'>
              {t(
                step === 'step_1'
                  ? 'components.projects.project-actions.embed.modal.step_1.description'
                  : 'components.projects.project-actions.embed.modal.step_2.description'
              )}
            </p>
            {step === 'step_1' ? (
              <div className='flex flex-col flex-1 mb-5'>
                <code className='block bg-gray-100 p-4 rounded-lg mt-10 break-all'>
                  {iframeCodeText}
                </code>

                <div className='flex flex-1 justify-between items-end mt-6'>
                  <Button
                    className='button__filled w-44'
                    onClick={() => copyToClipboard(iframeCodeText)}>
                    {copied && copiedValue === iframeCodeText ? (
                      <>
                        <Icon
                          Svg={CheckIconOn}
                          className='w-5 h-5 icon-main-contrast cursor-pointer mr-5'
                        />
                        <span className='font-medium'>
                          {t(
                            'components.projects.project-actions.embed.modal.step_1.buttons.copied'
                          )}
                        </span>
                      </>
                    ) : (
                      <>
                        <Icon
                          Svg={DuplicateIcon}
                          className='w-5 h-5 icon-main-contrast cursor-pointer mr-5'
                        />
                        <span className='font-medium'>
                          {t('components.projects.project-actions.embed.modal.step_1.buttons.copy')}
                        </span>
                      </>
                    )}
                  </Button>
                  <Button
                    className='button__ghost w-44 justify-center border-main-10 hover:enabled:bg-main-10'
                    onClick={() => stepTo('step_2')}>
                    <span className='font-medium'>
                      {t('components.projects.project-actions.embed.modal.step_1.buttons.next')}
                    </span>
                  </Button>
                </div>
              </div>
            ) : (
              <div className='flex flex-col flex-1 mb-5'>
                <div className='mt-10'>
                  <div className='flex relative font-medium items-center justify-center h-12 w-full bg-main-10 rounded-lg'>
                    {window.location.hostname}
                    <Button
                      className='absolute right-0 mr-5'
                      onClick={() => copyToClipboard(window.location.hostname)}>
                      {copied && copiedValue === window.location.hostname ? (
                        <Icon
                          Svg={CheckIconOn}
                          className='w-5 h-5 icon-main-contrast cursor-pointer'
                        />
                      ) : (
                        <Icon
                          Svg={DuplicateIcon}
                          className='w-5 h-5 icon-main-contrast cursor-pointer'
                        />
                      )}
                    </Button>
                  </div>

                  <div className='mt-2 text-center rounded-lg py-4 px-7 bg-info-10 text-info'>
                    <span className='font-medium'>
                      {t('components.projects.project-actions.embed.modal.step_2.note.title')}
                    </span>
                    {'  '}
                    <span className=''>
                      {t('components.projects.project-actions.embed.modal.step_2.note.content')}
                    </span>
                    <div className='flex items-center justify-between w-56 mt-2 mx-auto'>
                      <a
                        className='font-medium underline'
                        target='_blank'
                        href='https://wordpress.com/plugins/browse/content-security-policy'
                        rel='noreferrer'>
                        WordPress
                      </a>
                      <a
                        className='font-medium underline'
                        target='_blank'
                        href='https://university.webflow.com/lesson/custom-security-headers'
                        rel='noreferrer'>
                        Webflow
                      </a>
                      <a
                        className='font-medium underline'
                        target='_blank'
                        href='https://www.framer.com/learn/how-to-add-a-content-security-policy/'
                        rel='noreferrer'>
                        Framer
                      </a>
                    </div>
                  </div>

                  <div className='mt-2 text-center rounded-lg py-4 px-6 bg-danger-10 text-danger'>
                    <span className='font-medium'>
                      {t('components.projects.project-actions.embed.modal.step_2.important.title')}
                    </span>
                    {'  '}
                    <span className=''>
                      {t(
                        'components.projects.project-actions.embed.modal.step_2.important.content'
                      )}
                    </span>
                  </div>
                </div>
                <div className='flex flex-1 justify-between items-end mt-6'>
                  <Button
                    className='button__ghost w-44 justify-center border-main-10 hover:enabled:bg-main-10'
                    onClick={() => stepTo('step_1')}>
                    <span className='font-medium'>{t('common.back')}</span>
                  </Button>
                  <Button
                    className='button__filled w-44 justify-center border-main-10 hover:enabled:bg-main-10'
                    onClick={closeModal}>
                    <span className='font-medium'>{t('common.done')}</span>
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};
