import { formatDate } from '../../../services/date';

type FormattedDateProps = { precision: string } & (
  | {
      seconds?: number;
      milliseconds?: never;
    }
  | {
      seconds?: never;
      milliseconds?: number;
    }
);

export const FormattedDate = ({ precision, seconds, milliseconds }: FormattedDateProps) => {
  const dateTime = seconds ? seconds : milliseconds ? milliseconds * 1000 : undefined;

  if (typeof dateTime === 'undefined') return <>-</>;

  return <>{formatDate(new Date(dateTime), precision)}</>;
};
