import { useCallback, useEffect, useState } from 'react';

import { createContextService } from '../context';
import { useLocalStorage } from '../local-storage';
import { changeRootThemeClass } from './change-root-class';
import { Theme } from './themes';

const useTheme = () => {
  const { getLocalCopy, saveLocalCopy } = useLocalStorage('color-theme');

  const [theme, setTheme] = useState(Theme.Light);

  const loadThemeInApp = useCallback(
    (nextTheme: Theme) => {
      changeRootThemeClass(nextTheme, theme);
      saveLocalCopy(nextTheme);
      setTheme(nextTheme);
    },
    [saveLocalCopy, theme]
  );

  const changeTheme = useCallback(() => {
    const nextTheme = theme === Theme.Dark ? Theme.Light : Theme.Dark;
    loadThemeInApp(nextTheme);
  }, [loadThemeInApp, theme]);

  useEffect(() => {
    const localStorageTheme = getLocalCopy();
    //  TODO: use if dark theme is added
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const browserPrefferedTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
      ? Theme.Dark
      : undefined;

    const initialTheme = localStorageTheme || Theme.Light;
    loadThemeInApp(initialTheme);
  }, [getLocalCopy, loadThemeInApp]);

  return { isDark: theme === Theme.Dark, changeTheme };
};

export const useThemeContext = createContextService(useTheme);
