import { allClasses } from '../../../services/utilities/array';
import { useTranslationContext } from '../../../services/translation';
import { FolderChildType, ProjectType } from '../../services/api-iteration1';
import { isRequired } from '../../services/validation';

import { Button, Form, FormError, FormField, VoidAsyncFormFunction } from '../../../components';

import {
  CheckIconOn,
  DiscoveryProjectIllustration,
  UsabilityProjectIllustration,
} from '../../assets/icons';

import styles from './Modals.module.css';

interface ProjectTypeModalStep2Props {
  projectType: ProjectType;
  initialValues: { name: string };
  onClickSubmit: VoidAsyncFormFunction<{ name: string }>;
}

export const ProjectTypeModalStep2 = ({
  projectType,
  onClickSubmit,
  initialValues,
}: ProjectTypeModalStep2Props) => {
  const { t } = useTranslationContext.useContext();

  return (
    <div className='text-center mt-32 container-with-margin'>
      <div className={styles.project_type_thumbnail}>
        {projectType === 'discovery' ? (
          <DiscoveryProjectIllustration />
        ) : (
          <UsabilityProjectIllustration />
        )}
      </div>
      <h2 className={allClasses(styles.title, 'mt-6 mb-10')}>
        {t(`components.projects.project-type-modal.create-project-type.${projectType}`)}
      </h2>
      <Form
        className='flex flex-col items-center'
        initialValues={initialValues}
        onSubmit={onClickSubmit}>
        {({ status, isValidForSubmit, errors, touched, isSubmitting }) => {
          return (
            <>
              <FormError {...{ status }} />
              <FormField
                autoFocus
                type='text'
                name='name'
                placeholder={t('components.projects.project-type-modal.placeholder-project-name')}
                validate={isRequired}
                fieldClassName='w-full max-w-sm mb-10'
                inputClassName={allClasses(
                  styles.project_input,
                  errors.name && touched.name && styles.project_input__invalid
                )}
              />
              {projectType !== FolderChildType.Discovery ? (
                <Button className='button__filled px-10' disabled={!isValidForSubmit} type='submit'>
                  <CheckIconOn />
                </Button>
              ) : (
                <Button
                  className={allClasses('button__filled px-10', isSubmitting && 'cursor-default')}
                  disabled={!isValidForSubmit}
                  type='submit'
                  loading={isSubmitting}>
                  {t('common.create')}
                </Button>
              )}
            </>
          );
        }}
      </Form>
    </div>
  );
};
