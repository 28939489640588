import { allClasses } from '../../services/utilities/array';

export const LoadingTextIndicator = () => {
  const dotClassName = 'w-1 h-1 bg-main-highlight rounded-full mr-1';

  return (
    <span className='flex' aria-label='Loading'>
      <span className={allClasses(dotClassName, 'animate-bounce')} />
      <span className={allClasses(dotClassName, 'animate-bounce200')} />
      <span className={allClasses(dotClassName, 'animate-bounce400')} />
    </span>
  );
};
