import { useCallback, useEffect, useState } from 'react';

import { FetchOptions } from '../../../../services/api/resource-fetch-factory';

import { apiIteration1, useIteration1Resource } from '../config';
import {
  BillingOutput,
  BillingUpdateInput,
  InvoiceOutput,
  PaymentIntentStatus,
  PaymentPlanOutput,
  PromoCodeOutput,
} from './api-models';
import { endpoints } from './endpoints';

export * from './utils';
export * from './tax-codes';

export const useRecentInvoices = () => {
  return useIteration1Resource<{ hasMore: boolean; invoices: InvoiceOutput[] }>(
    endpoints.v1.invoicesRecent,
    {},
    { revalidateOnFocus: false }
  );
};

export const useBillingPlans = () => {
  return useIteration1Resource<PaymentPlanOutput[]>(endpoints.v1.paymentPlans);
};

export const useUserBillingRecord = () => {
  return useIteration1Resource<BillingOutput>(endpoints.v1.billingRecord);
};

export const useCurrentUserSubscription = (options: FetchOptions) => {
  return useIteration1Resource<{
    subscriptionId: string;
    latestInvoicePaymentIntentStatus: PaymentIntentStatus | null;
    latestInvoiceId: string | null;
    clientSecret: string | undefined;
  }>(endpoints.v1.currentSubscription, options, { revalidateOnFocus: false });
};

export const usePreviewSubscriptionChange = (priceId?: string, options?: FetchOptions) => {
  return useIteration1Resource<InvoiceOutput>(
    endpoints.v1.previewSubscriptionPriceChange(priceId as string),
    { ...options, waitForFetch: !priceId || options?.waitForFetch },
    { revalidateOnFocus: false }
  );
};

export const useInvoice = (invoiceId?: string, options?: FetchOptions) => {
  return useIteration1Resource<InvoiceOutput>(endpoints.v1.invoice(invoiceId as string), options, {
    revalidateOnFocus: false,
  });
};

export const updateBillingRecord = async (updates: BillingUpdateInput) => {
  return apiIteration1.put<Pick<BillingOutput, 'address' | 'company'>>(
    endpoints.v1.billingRecord,
    updates
  );
};

export const createPaymentSetupIntent = async () => {
  return apiIteration1.post<{
    id: string;
    clientSecret: string | null;
  }>(endpoints.v1.paymentSetupIntents);
};

export const cancelPaymentSetupIntent = async (paymentSetupIntentId: string) => {
  return apiIteration1.destroy(endpoints.v1.paymentSetupIntent(paymentSetupIntentId));
};

export const payInvoice = async (invoiceId: string) => {
  return apiIteration1.post(endpoints.v1.invoice(invoiceId));
};

export const createSubscription = async (
  subscription:
    | {
        priceId: string;
      }
    | {
        priceId: string;
        company: NonNullable<BillingOutput['company']>;
        address: NonNullable<Required<BillingOutput['address']>>;
      }
) => {
  const result = await apiIteration1.post<{
    subscriptionId: string;
    latestInvoicePaymentIntentStatus: PaymentIntentStatus | null;
    clientSecret: string | undefined;
    latestInvoiceId: string | null;
  }>(endpoints.v1.currentSubscription, subscription);
  return result.data;
};

export const updateSubscription = async (
  updates:
    | {
        priceId: string;
      }
    | {
        priceId: string;
        company: NonNullable<BillingOutput['company']>;
        address: NonNullable<Required<BillingOutput['address']>>;
      }
) => {
  const result = await apiIteration1.patch<{
    subscriptionId: string;
    latestInvoicePaymentIntentStatus: PaymentIntentStatus | null;
    clientSecret: string | undefined;
    latestInvoiceId: string | null;
  }>(endpoints.v1.currentSubscription, updates);
  return result.data;
};

export const cancelSubscription = async () => {
  return apiIteration1.destroy(endpoints.v1.currentSubscription);
};

export const reactivateSubscription = async () => {
  return apiIteration1.patch(endpoints.v1.reactivateCurrentSubscription);
};

export const usePaymentIntent = (): {
  paymentsContext: { clientSecret: string } | undefined;
  cancelPaymentIntent: () => Promise<void>;
} => {
  const [paymentSetupIntent, setPaymentSetupIntent] = useState<{
    id: string;
    clientSecret: string | null;
  }>();

  const intentId = paymentSetupIntent?.id;

  const paymentsContext = paymentSetupIntent?.clientSecret
    ? {
        clientSecret: paymentSetupIntent.clientSecret,
      }
    : undefined;

  const cancelPaymentIntent = useCallback(async () => {
    if (intentId) await cancelPaymentSetupIntent(intentId);
  }, [intentId]);

  useEffect(() => {
    (async () => {
      const { data: paymentSetupIntent } = await createPaymentSetupIntent();
      setPaymentSetupIntent(paymentSetupIntent);
    })();
  }, []);

  return {
    paymentsContext,
    cancelPaymentIntent,
  };
};

export const getPromoCode = async (name: string, amount: number) => {
  const response = await apiIteration1.get<PromoCodeOutput>(endpoints.v1.promoCode(name), {
    params: { amount },
  });
  return response.data;
};
