import { useCallback, useEffect, useReducer } from 'react';

import { createContextService } from '../../../services/context';
import { handleErrorSilently } from '../../../services/error-handling';
import {
  FirebaseAuthError,
  FirebaseAuthUser,
  FirebaseUserCredential,
} from '../../../services/auth/firebase';
import { useAuthentication } from '../../../services/auth';
import { connectAnalyticsToUser } from '../../../services/app-analytics';
import { getFromStorage } from '../../../services/local-storage';

import { AuthenticatedUser } from './AuthenticatedUser';
import { extractAuthenticationData, extractNewUserDetails, OtherNewAccountInfo } from './utils';
import { CurrentUserAuthActions, currentUserReducer, CurrentUserReducer } from './reducer';
import { localStorageKeys } from '../local-storage';

import { createNewCurrentUser, getCurrentUser } from '../api-iteration1/users';
import { apiIteration1 } from '../api-iteration1/config';
import { CurrentUserOutput } from '../api-iteration1/users/api-models';

interface useCurrentUser {
  currentUser: AuthenticatedUser | undefined;
  isAuthenticated: boolean;
  isLoadingUser: boolean;
  socialAuthError: FirebaseAuthError | unknown | undefined;
  apiAuthError: unknown | undefined;
  clearSocialAuthError(): void;
  reloadUser(): Promise<void>;
  signInWithEmail(email: string, password: string): Promise<void>;
  signUpWithEmail(email: string, password: string, otherProfileInfo: any): Promise<void>;
  signInWithGoogle(): Promise<void>;
  signOut(): Promise<void>;
  updatePassword(password: string): Promise<void>;
  reauthenticateWithEmail(password: string): Promise<FirebaseUserCredential | void>;
}

const useCurrentUser = (): useCurrentUser => {
  const [{ currentUser, isLoading, error }, dispatch] = useReducer<CurrentUserReducer>(
    currentUserReducer,
    {
      isLoading: true,
      currentUser: undefined,
      error: undefined,
    }
  );

  const loadUser = useCallback((account: FirebaseAuthUser, user: CurrentUserOutput) => {
    const accountData = extractAuthenticationData(account);

    connectAnalyticsToUser(accountData.uid);
    dispatch({
      type: CurrentUserAuthActions.Success,
      user: new AuthenticatedUser(accountData, user),
    });
  }, []);

  const clearUser = useCallback(
    (error?: unknown) =>
      dispatch({
        type: CurrentUserAuthActions.Fail,
        error,
      }),
    []
  );

  const createNewAccount = useCallback(
    async (userCredential: FirebaseUserCredential, otherProfileInfo?: OtherNewAccountInfo) => {
      if (!userCredential.user) throw new Error('Empty firebase user');

      const newUserDetails = extractNewUserDetails(userCredential.user, otherProfileInfo);
      const userData = await createNewCurrentUser(newUserDetails);

      loadUser(userCredential.user, userData);
    },
    [loadUser]
  );

  const auth = useAuthentication({
    setToken: apiIteration1.setTokenInHeader,
    tryOnNewAccount: createNewAccount,
  });

  const reloadUser = useCallback(async () => {
    try {
      const account = await auth.reloadAccount();
      if (!account) return auth.clearAuthSession();

      const authToken = await account.getIdToken();
      apiIteration1.setTokenInHeader(authToken);

      const userData = await getCurrentUser();

      loadUser(account, userData);
    } catch (err) {
      handleErrorSilently(err);
      auth.clearAuthSession();
    }
  }, [auth, loadUser]);

  useEffect(() => {
    (async () => {
      try {
        if (auth.isLoadingUser) return;
        if (!auth.currentAccount) return clearUser();

        const isSignUpInProgress = getFromStorage<boolean>(localStorageKeys.signUpInProgress);
        if (isSignUpInProgress) return;

        const userData = await getCurrentUser();

        loadUser(auth.currentAccount, userData);
      } catch (err) {
        clearUser(err);
      }
    })();
  }, [clearUser, auth.currentAccount, loadUser, auth.isLoadingUser]);

  return {
    currentUser,
    isAuthenticated: !!currentUser,
    isLoadingUser: isLoading,
    socialAuthError: auth.error,
    apiAuthError: error,
    reloadUser,
    signInWithEmail: auth.signInWithEmail,
    signUpWithEmail: auth.signUpWithEmail,
    signInWithGoogle: auth.signInWithGoogle,
    signOut: auth.signOut,
    clearSocialAuthError: auth.clearSocialAuthError,
    updatePassword: auth.updatePassword,
    reauthenticateWithEmail: auth.reauthenticateWithEmail,
  };
};

export const useCurrentUserContext = createContextService(useCurrentUser);
