import { useEffect, useState } from 'react';

interface CopyToClipboardService {
  copied: boolean;
  copiedValue: string;
  copyToClipboard: (content: string) => void;
}

export const useCopyToClipboard = (): CopyToClipboardService => {
  const [copied, setCopied] = useState<string>('');

  const copyToClipboard = (content: string) => {
    navigator.clipboard.writeText(content);
    setCopied(content);
  };

  useEffect(() => {
    if (copied) setTimeout(() => setCopied(''), 2000);
  }, [copied]);

  return {
    copied: !!copied,
    copiedValue: copied,
    copyToClipboard,
  };
};
