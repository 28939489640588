import { useMemo } from 'react';

import { useTranslationContext } from '../../../../../services/translation';
import {
  DiscoveryMultipleChoiceAnswer,
  DiscoverySectionMultipleChoiceOutput,
} from '../../../../services/api-iteration1';
import { allClasses } from '../../../../../services/utilities/array';

import { CheckboxIcon, RadioButtonIcon } from '../../../../assets/icons';
import { AnalyticsSectionAnswerEmptyState } from '../../../empty-states';

interface AnalyticsMultipleChoiceAnswerProps {
  answer?: DiscoveryMultipleChoiceAnswer;
  multipleChoiceSection: DiscoverySectionMultipleChoiceOutput;
}

const customChoice = 'user-custom-choice';

export const AnalyticsMultipleChoiceAnswer = ({
  answer,
  multipleChoiceSection,
}: AnalyticsMultipleChoiceAnswerProps) => {
  const { t } = useTranslationContext.useContext();

  const { others } = multipleChoiceSection;
  const { choices, customEnabled, type } = others;

  const isMultipleChoice = type === 'multiple';
  const availableChoices = useMemo(
    () => (customEnabled && choices ? [...choices, customChoice] : choices),
    [choices, customEnabled]
  );

  return answer ? (
    <div className='flex flex-col w-full items-center'>
      <div className='flex flex-col justify-start w-full'>
        {availableChoices?.map((choice, index) => {
          const isCustomChoice = choice === customChoice;
          const value = index;
          const isActive = isCustomChoice
            ? (isMultipleChoice && !!answer?.choice) || typeof answer?.choice === 'string'
            : isMultipleChoice
            ? answer?.choices && answer.choices.includes(value)
            : answer.choice === value;

          const Icon = isMultipleChoice ? CheckboxIcon : RadioButtonIcon;

          return (
            <div
              key={index}
              className={allClasses(
                'relative flex items-center p-3 mb-3 border-2 border-solid rounded-lg w-full cursor-default',
                isActive ? 'border-main-highlight' : 'border-main-10'
              )}>
              {isActive && <div className='absolute inset-0 bg-main-highlight opacity-10 z-0' />}
              <Icon
                className={allClasses(
                  'z-10 mr-4',
                  isMultipleChoice
                    ? isActive && 'icon-main-contrast icon-fill-main-contrast'
                    : isActive && 'icon-stroke-circle-main-contrast icon-fill-circle-main-contrast'
                )}
              />
              <p
                className={allClasses(
                  'text-xl font-bold',
                  isCustomChoice && !answer.choice ? 'text-neutral-20' : 'text-main-contrast'
                )}>
                {isCustomChoice
                  ? answer.choice ||
                    t('components.projects.sections.multiple-choice.custom-answer-placeholder')
                  : choice || '...'}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    <AnalyticsSectionAnswerEmptyState />
  );
};
