import { ReactNode } from 'react';

import { useTranslationContext } from '../../../../../services/translation';
import { allClasses } from '../../../../../services/utilities/array';

interface AnalyticsPreferenceTestBlockProps {
  index: number;
  isLast: boolean;
  choice?: string;
  children?: ReactNode;
}

export const AnalyticsPreferenceTestBlock = ({
  choice,
  index,
  isLast,
  children,
}: AnalyticsPreferenceTestBlockProps) => {
  const { t } = useTranslationContext.useContext();

  return (
    <div
      key={`${choice}-${index}`}
      className={allClasses('w-full text-left mt-2', isLast ? 'mb-0' : 'mb-5')}>
      <p className='text-xl leading-30 font-bold text-main-contrast mb-2.5'>
        {t('components.projects.analytics.responses.preference-test.choice')} {index + 1}
      </p>
      <span className='text-base leading-5 text-main-contrast mb-2.5 flex'>{choice}</span>
      <div className='w-full flex flex-row flex-1 items-center justify-between'>{children}</div>
    </div>
  );
};
