import { useTranslationContext } from '../../../services/translation';

import { LogoIcon } from '../../assets/icons';

export const InfoCreatedWith = () => {
  const { t } = useTranslationContext.useContext();

  return (
    <div className='flex items-center'>
      <LogoIcon className='box-content w-5 h-3' />
      <p className='ml-3 font-bold text-main-contrast'>{t('components.info.created-with')}</p>
    </div>
  );
};
