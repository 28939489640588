import { useCallback, useEffect, useRef } from 'react';
import useSWR, { KeyedMutator, SWRConfiguration, SWRResponse } from 'swr';

import { useRouteParams, useRouter } from '../router';
import { onlyMatchingKeys } from '../utilities/object';
import { ApiResponse } from './api-factory';
import { createQueryUrl, UrlParams } from './utils';

export interface FetchOptions<Data = any> {
  waitForFetch?: boolean;
  initialQueryParams?: UrlParams;
  fetchOnlyParams?: UrlParams;
  placeholderDataWhileWait?: Data;
}

interface FetchResultData<Data = any> {
  data: Data;
}

export type FetchMutator<Data = any> = KeyedMutator<FetchResultData<Data>>;

export type ResourceServiceOptions<Data, Error> = SWRConfiguration<Data, Error>;

export interface ResourceService<Data, Meta, Error>
  extends Omit<SWRResponse<FetchResultData<Data>, Error>, 'data'> {
  data: Data | undefined;
  isLoading: boolean;
  meta: Meta;
  revalidate: () => void;
  updateQueryParams(params: UrlParams): void;
}

export type UseResource = <Data = any, Meta = any, Error = any>(
  url: string,
  fetchOptions?: FetchOptions,
  config?: ResourceServiceOptions<Data, Error>
) => ResourceService<Data, Meta, Error>;

interface ResourceFetcherOptions {
  get<T = unknown>(endpoint: string, config?: any): Promise<ApiResponse<T>>;
  isReadyForRequests?(): boolean;
}

export const createResourceFetcher = ({
  get,
  isReadyForRequests = () => true,
}: ResourceFetcherOptions) => {
  const useResource: UseResource = (url, fetchOptions = {}, config) => {
    const { push } = useRouter();
    const query = useRouteParams();
    const { waitForFetch, initialQueryParams, fetchOnlyParams, placeholderDataWhileWait } =
      fetchOptions;

    const initialQueryParamsRef = useRef(initialQueryParams || {});

    const isPaused = useCallback(() => !isReadyForRequests(), []);

    const getFinalUrl = useCallback(
      (url: string, otherUrlParams?: UrlParams) => {
        const finalUrl = createQueryUrl(url, {
          ...initialQueryParamsRef.current,
          ...onlyMatchingKeys(query, initialQueryParamsRef.current),
          ...otherUrlParams,
        });

        return finalUrl;
      },
      [query]
    );

    const updateQueryParams = useCallback(
      (params: UrlParams) => push(getFinalUrl(window.location.pathname, params), undefined),
      [getFinalUrl, push]
    );

    const fetcher = useCallback(async (url: string): Promise<FetchResultData> => {
      const result = await get(url);

      return { data: result.data };
    }, []);

    const fetchResult = useSWR<any>(
      () => (waitForFetch || isPaused() ? null : getFinalUrl(url, fetchOnlyParams)),
      fetcher,
      {
        shouldRetryOnError: false,
        isPaused,
        ...config,
      }
    );

    const { data: fetchResultData, ...otherFetchResults } = fetchResult;
    const data = waitForFetch ? placeholderDataWhileWait : fetchResultData?.data;
    const { mutate: revalidate } = otherFetchResults;

    useEffect(() => {
      const resumePausedRequestsWhenReady = () => {
        if (isPaused()) {
          const checkIfReadyLoop = setInterval(() => {
            if (isReadyForRequests()) {
              revalidate();
              // Exit checking loop
              clearInterval(checkIfReadyLoop);
            }
          }, 100);
        }
      };

      resumePausedRequestsWhenReady();

      window.addEventListener('focus', resumePausedRequestsWhenReady, true);

      return () => {
        window.removeEventListener('focus', resumePausedRequestsWhenReady);
      };
    }, [isPaused, revalidate]);

    return {
      ...fetchResultData,
      ...otherFetchResults,
      data,
      revalidate,
      isLoading: !fetchResult.data && fetchResult.isValidating,
      updateQueryParams,
    };
  };

  return {
    useResource,
  };
};
