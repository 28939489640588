import { useMemo, useState } from 'react';

import {
  Frame,
  isTemporarySection,
  SectionFileRemovalDetails,
  SectionFileUploadDetails,
  UsabilitySectionFirstClick,
  UsabilitySectionInput,
  UsabilitySectionType,
} from '../../../../services/api-iteration1';
import { FigmaDesignPrototypeVersion } from '../../../../services/api-iteration1/design-platforms';
import { allClasses } from '../../../../../services/utilities/array';
import { useTranslationContext } from '../../../../../services/translation';
import { useSectionsListContext } from '../../../../services/sections/useSectionsList';
import {
  getStoragePublicFileUrl,
  imageFileSizeLimit,
} from '../../../../services/api-cloud-storage';
import { isRequired } from '../../../../services/validation';
import { StorageFileType } from '../../../../services/api-cloud-storage/api-models';
import { useErrorHandlerContext } from '../../../../../services/error-handling';
import { useActionInProgressContext } from '../../../../../services/progress';

import { Form, FormError, FormField, UploadImage, useToggle } from '../../../../../components';

import { ChangeStartingFrameModal, SelectSectionItem } from '../../..';
import { EditIcon, FirstClickIcon } from '../../../../assets/icons';

import styles from './Section.module.css';

interface SectionFirstClickProps {
  section: UsabilitySectionFirstClick;
  index: number;
  isInvalid: boolean;
  editSection: (
    sectionId: string,
    updates: UsabilitySectionInput,
    shouldUpdate?: boolean
  ) => Promise<void> | undefined;
  removeSection: (sectionId: string) => Promise<void> | undefined;
  uploadSectionFile: (sectionId: string, fileDetails: SectionFileUploadDetails) => Promise<void>;
  removeSectionFile: (sectionId: string, fileDetails: SectionFileRemovalDetails) => Promise<void>;
  duplicateSection: ((sectionId: string) => Promise<void> | undefined) | (() => void);
  designPrototype?: FigmaDesignPrototypeVersion;
}

interface SectionFormValues {
  title: string;
  description?: string;
  frame?: Frame;
  hasImage: boolean;
}

export const SectionFirstClick = ({
  section,
  editSection,
  removeSection,
  duplicateSection,
  designPrototype,
  isInvalid,
  index,
  removeSectionFile,
  uploadSectionFile,
}: SectionFirstClickProps) => {
  const { t } = useTranslationContext.useContext();
  const { handleError } = useErrorHandlerContext.useContext();
  const { reflectUploadingStatus } = useActionInProgressContext.useContext();
  const {
    inFocusSectionId,
    setSectionInFocus,
    resetSectionInFocus: collapseSection,
  } = useSectionsListContext.useContext();

  const { _id, title, files, others } = section;
  const { frame, description, type } = others;
  const currentFrame =
    designPrototype?.frames.find((item) => item.id === frame?.id) || designPrototype?.frames[0];
  const image = files.find((f) => f.type === StorageFileType.FirstClickImage);
  const imageUrl = image && getStoragePublicFileUrl(image.id);

  const [hasImage, setHasImage] = useState(!!image);
  const {
    isOpen: isOpenChangeFrameModal,
    open: openChangeFrameModal,
    close: closeChangeFrameModal,
  } = useToggle();

  const showUploadImage = type === 'image';
  const showPrototypeScreen = type === 'prototype';
  const isLoading = isTemporarySection(_id);
  const isExpanded = inFocusSectionId === _id && !isLoading;
  const expandSection = () => setSectionInFocus(_id);

  const updateSectionValues = (values: SectionFormValues) => {
    if (!_id) return;

    const shouldRemoveSectionImage = !values.hasImage && image;
    if (shouldRemoveSectionImage)
      removeSectionFile(_id, { id: image.id, type: StorageFileType.FirstClickImage }).catch(
        handleError
      );

    const updates = {
      title: values.title,
      others: {
        ...(values.frame
          ? { frame: values.frame }
          : {
              frame: {
                id: designPrototype?.frames[0].id || '',
                index: designPrototype?.frames[0].index || 0,
              },
            }),
        ...(values.description ? { description: values.description } : {}),

        description: values.description,
      },
      ...(shouldRemoveSectionImage ? { files: files.filter((f) => f.id !== image.id) } : {}),
    };

    editSection(_id, updates, !isTemporarySection(_id));
  };

  const initialValues = useMemo(
    () => ({
      title: title,
      description: description || '',
      frame: frame,
      hasImage: !!image || hasImage,
    }),
    [description, frame, hasImage, image, title]
  );

  return (
    <SelectSectionItem
      alreadyAdded
      draggableItemId={_id}
      title={title || t('components.projects.sections.section-title-placeholder')}
      description={t('components.projects.sections.field-types.firstClick')}
      icon={<FirstClickIcon className='icon__small' />}
      onClick={isExpanded ? collapseSection : expandSection}
      type={UsabilitySectionType.FirstClick}
      onDelete={(e) => {
        e.stopPropagation();
        removeSection(_id);
      }}
      onDuplicate={(e) => {
        e.stopPropagation();
        duplicateSection?.(_id);
      }}
      {...{ isExpanded, isInvalid, isLoading, index }}>
      <>
        <hr className={allClasses(styles.section_hr, 'w-11/12')} />
        <Form
          validateOnMount
          enableReinitialize
          initialValues={initialValues}
          onChange={updateSectionValues}
          className='px-5'>
          {({ status, errors, touched, values, submitChange }) => (
            <>
              <FormError {...{ status }} />
              <FormField
                autoFocus
                type='text'
                name='title'
                label={`${t('components.projects.sections.first-click.edit.title.label')}*`}
                placeholder={t('components.projects.sections.first-click.edit.title.placeholder')}
                validate={isRequired}
                fieldClassName='mb-5'
                inputClassName={allClasses(
                  styles.section_input,
                  errors.title && touched.title && styles.section_input__invalid
                )}
                labelClassName={styles.section_label}
              />
              <FormField
                type='text'
                name='description'
                label={t('components.projects.sections.first-click.edit.description.label')}
                placeholder={t(
                  'components.projects.sections.first-click.edit.description.placeholder'
                )}
                fieldClassName='mb-5'
                inputClassName={styles.section_input}
                labelClassName={styles.section_label}
              />
              <>
                {showUploadImage && (
                  <>
                    <p className='text-main-contrast leading-5'>
                      {t('components.projects.sections.first-click.edit.image.title')}*
                    </p>
                    <span className='mb-[8px] text-xs text-neutral-70'>
                      {t('components.projects.sections.first-click.edit.image.detail')}
                    </span>
                    <UploadImage
                      excludeFromForm
                      fileSizeLimit={imageFileSizeLimit}
                      title={t('components.upload.image.title')}
                      subtitle={t('components.upload.image.subtitle')}
                      placeholderImgSrc={imageUrl}
                      upload={(image) =>
                        uploadSectionFile(_id, {
                          file: image,
                          type: StorageFileType.FirstClickImage,
                        })
                      }
                      onUploadError={handleError}
                      remove={async () => {
                        if (!image) return;
                        await removeSectionFile(_id, {
                          id: image.id,
                          type: StorageFileType.FirstClickImage,
                        });
                      }}
                      onRemoveSuccess={() => setHasImage(false)}
                      onRemoveError={handleError}
                      onLoadingStatusChange={reflectUploadingStatus}
                      className='mb-4'
                      fileName={image?.fileName}
                    />
                  </>
                )}
                {showPrototypeScreen && (
                  <>
                    <div className='mb-4'>
                      <p className='mb-[8px]'>{t('common.screen')}*</p>
                      <div className='relative p-5 border-2 border-solid rounded-lg mb-5 border-main-10'>
                        <div className='w-full flex flex-row items-center justify-start flex-wrap'>
                          <div
                            className={allClasses(
                              'mr-1.5 relative cursor-pointer m-1',
                              styles.screen_selected_frame
                            )}>
                            <img
                              src={
                                currentFrame?.image &&
                                getStoragePublicFileUrl(currentFrame.image.id)
                              }
                              className='h-full w-full border-2 border-main-10 border-solid rounded-sm'
                            />
                          </div>
                          <span className='ml-5'>{currentFrame?.name}</span>
                        </div>
                        <div className='absolute top-1/2 right-5 -translate-y-1/2 cursor-pointer'>
                          <EditIcon
                            onClick={() => openChangeFrameModal()}
                            className='icon__small ml-2 cursor-pointer icon-hover-main-contrast'
                          />
                        </div>
                      </div>
                    </div>
                    {isOpenChangeFrameModal && (
                      <ChangeStartingFrameModal
                        isOpen={isOpenChangeFrameModal}
                        designPrototype={designPrototype}
                        startingPointNodeId={currentFrame?.id}
                        pathIndex={currentFrame?.index || 0}
                        onClose={closeChangeFrameModal}
                        onSelect={(_, frameId: string) => {
                          closeChangeFrameModal();
                          setTimeout(
                            () =>
                              submitChange({
                                ...values,
                                frame: {
                                  id: frameId,
                                  index:
                                    designPrototype?.frames.find((item) => item.id === frameId)
                                      ?.index || 0,
                                },
                              }),
                            1000
                          );
                        }}
                        modalTitle={t(
                          'components.projects.sections.first-click.edit.prototype.modal-title'
                        )}
                      />
                    )}
                  </>
                )}
              </>
            </>
          )}
        </Form>
      </>
    </SelectSectionItem>
  );
};
