import { FunctionComponent, SVGProps } from 'react';

import { useTranslationContext } from '../../../services/translation';
import { ScreenAnalyticsFilter } from '../../services/analytics/useScreenAnalyticsFilter';
import { allClasses } from '../../../services/utilities/array';
import { useAnalyticsQueryParams } from '../../services/analytics';

import { renderAnalyticsIcon } from '../icons';

type MetricsDataItem = {
  icon: 'users' | 'time';
  title: string;
  value: string | number;
  color: string;
};

type OptionItem = {
  id: ScreenAnalyticsFilter['inFocusFilterId'];
  iconSelectedClass: string;
  text: string;
  containerClassName: string;
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
};

interface UsabilityMetricsCardProps {
  screenName: string;
  imageUrl?: string;
  frameUrl?: string;
  data: MetricsDataItem[];
  frameOptions: OptionItem[];
}

export const UsabilityMetricsCard = ({
  screenName,
  imageUrl,
  frameUrl,
  data,
  frameOptions,
}: UsabilityMetricsCardProps) => {
  const { t } = useTranslationContext.useContext();

  const { queryParams, setQueryParams } = useAnalyticsQueryParams();

  const selectOption = (option: 'all-clicks' | 'first-click') => {
    setQueryParams((prev) => ({
      ...prev,
      screenFilter: prev.screenFilter !== option ? option : undefined,
    }));
  };

  return (
    <div className='cursor-pointer w-90 bg-main mb-5 border-2 border-solid border-main-highlight pb-5 rounded-lg'>
      <div className='flex flex-row relative items-center py-5 px-[1.125rem]'>
        {imageUrl ? (
          <span className='w-[85px] h-full'>
            <img
              src={imageUrl}
              className='border-default border-main-10 border-solid w-[85px]S h-14 rounded'
            />
          </span>
        ) : (
          <span className='w-5 h-full'>
            <img src={frameUrl} className='w-[22px] h-12' />
          </span>
        )}
        <div className='ml-5'>
          <p className='text-base text-main-contrast leading-5 font-bold pr-4 overflow-hidden w-60 text-ellipsis break-all whitespace-nowrap'>
            {screenName}
          </p>
        </div>
      </div>
      <div className='w-11/12 mx-auto'>
        <hr className='mb-5 border-default border-main-10 border-solid rounded-lg' />
        <div className='grid grid-cols-2 h-auto'>
          {data.map((item: MetricsDataItem, index: number) => (
            <div className='flex flex-col' key={index}>
              <div
                className={allClasses(
                  'flex mb-2.5 justify-between',
                  index === 1 ? 'pl-10' : index === 0 ? 'pl-0' : 'pl-5'
                )}>
                <div className='flex flex-col items-start flex-nowrap'>
                  <p className='text-sm text-main-contrast leading-5 mb-2'>{item.title}</p>
                  <>
                    <div className='flex flex-row items-center'>
                      <>{renderAnalyticsIcon(item.color, item.icon, 'w-5 h-5 mr-4')}</>
                      <span className={allClasses('text-2xl font-bold leading-8', item.color)}>
                        {item.value}
                      </span>
                    </div>
                  </>
                </div>
                {index % 2 === 0 && (
                  <div className='flex justify-center w-auto h-14'>
                    <div className='h-full bg-main-10 w-0.5' />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className='mt-5'>
          <p className='mb-2'>
            {t('components.projects.usability-testing.results.single-goal.screens.options.title')}
          </p>
          <div className='flex flex-row items-start justify-between'>
            {frameOptions.map(
              ({ id, Icon, text, iconSelectedClass, containerClassName }, index) =>
                id && (
                  <div
                    key={id}
                    className={allClasses(
                      containerClassName,
                      index === 0 && 'mr-5',
                      queryParams.screenFilter === id
                        ? 'border-main-highlight text-main-contrast font-medium'
                        : 'border-main-10 text-neutral'
                    )}
                    onClick={() => selectOption(id)}>
                    <Icon
                      className={allClasses(
                        'mr-2.5',
                        queryParams.screenFilter === id && iconSelectedClass
                      )}
                    />
                    <span>{text}</span>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
