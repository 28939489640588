export const routes = {
  home: '/',
  profile: '/profile',
  password: '/profile/password',
  billing: '/profile/billing',
  collaborators: '/profile/collaborators',

  signIn: '/signin',
  signUp: '/signup',
  finishSignUp: '/signup/finish',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  verifyAccountEmail: '/check-email-and-confirm-account',
  confirmEmail: '/confirm-email',

  projects: '/projects',
  folder: '/projects/:folderId',
  discovery: '/projects/discovery/:discoveryId',
  userTest: '/projects/usabilityTesting/:userTestId',
  userTestVersion: '/projects/usabilityTesting/:userTestId/versions/:versionId',

  start_testing: {
    discovery: '/start-testing/discovery/:discoveryId/versions/:versionId',
    userTest: '/start-testing/usabilityTesting/:userTestId/versions/:versionId',
  },
  testing: {
    discovery: '/testing/discovery/:discoveryId/versions/:versionId',
    userTest: '/testing/usabilityTesting/:userTestId/versions/:versionId',
  },
};

export const dynamicRoutes = {
  discovery: (discoveryId: string) => `/projects/discovery/${discoveryId}`,
  discoverySummary: (discoveryId: string) => `/projects/discovery/${discoveryId}/summary`,
  discoveryResponses: (discoveryId: string) => `/projects/discovery/${discoveryId}/responses`,
  start_testing: {
    discovery: (discoveryId: string, versionId: string, sectionCount: number) =>
      `/start-testing/discovery/${discoveryId}/versions/${versionId}?sectionCount=${sectionCount}`,
    userTest: (userTestId: string, versionId: string, sectionCount: number) =>
      `/start-testing/usabilityTesting/${userTestId}/versions/${versionId}?sectionCount=${sectionCount}`,
  },
  testing: {
    discovery: (discoveryId: string, versionId: string) =>
      `/testing/discovery/${discoveryId}/versions/${versionId}`,
    userTest: (userTestId: string, versionId: string) =>
      `/testing/usabilityTesting/${userTestId}/versions/${versionId}`,
  },
  folder: (folderId: string) => `/projects/${folderId}`,
  userTest: (userTestId: string) => `/projects/usabilityTesting/${userTestId}`,
  userTestVersion: (userTestId: string, versionId: string) =>
    `/projects/usabilityTesting/${userTestId}/versions/${versionId}`,
  userTestVersionSummary: (userTestId: string, versionId: string) =>
    `/projects/usabilityTesting/${userTestId}/versions/${versionId}/summary`,
  userTestVersionResponses: (userTestId: string, versionId: string) =>
    `/projects/usabilityTesting/${userTestId}/versions/${versionId}/responses`,
};

export const websiteRoutes = {
  privacy: `${process.env.REACT_APP_ITERATION1_UI_URL}/privacy-policy`,
  terms: `${process.env.REACT_APP_ITERATION1_UI_URL}/terms-and-conditions`,
};
