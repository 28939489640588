import { forwardRef, useEffect } from 'react';

import { ApiResponse, http } from '../../services/api';
import { logToConsole } from '../../services/logger';
import { allClasses } from '../../services/utilities/array';

interface VideoPlayerProps {
  url: string;
  autoPlay?: boolean;
  className?: string;
  width?: string | number;
  height?: string | number;
}

export const VideoPlayer = forwardRef<HTMLVideoElement, VideoPlayerProps>(
  ({ url, autoPlay, className, width, height }, ref) => {
    useEffect(() => {
      let ignore = false;
      http.get(url, { responseType: 'arraybuffer' }).then((res: ApiResponse<any>) => {
        const video: HTMLVideoElement | null = (ref as any)
          ?.current as unknown as HTMLVideoElement | null;

        if (!video || ignore) return;

        const blob = new Blob([res.data]);
        video.src = URL.createObjectURL(blob);

        video.onloadedmetadata = function () {
          // Fix infinite duration bug for video players in Chrome based browsers
          if (video.duration === Infinity) {
            video.currentTime = 1e101; // set it to bigger duration than the actual one

            video.ontimeupdate = function () {
              this.ontimeupdate = () => {
                return; // exit from infinite update loop;
              };

              video.currentTime = 0;
              video.autoplay = !!autoPlay;
            };
          }
        };
      });

      return () => {
        ignore = true;
      };
    }, [autoPlay, ref, url]);

    return (
      <div className={allClasses('flex flex-col items-center', className)}>
        <video
          ref={ref}
          controls
          controlsList='nodownload'
          disablePictureInPicture
          playsInline
          autoPlay={autoPlay}
          preload='metadata'
          width={width || 'auto'}
          height={height || 'auto'}
          onError={(err) => logToConsole('err', err)}
          onErrorCapture={(err) => logToConsole('capture', err)}
        />
      </div>
    );
  }
);
